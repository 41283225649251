import React, { useState, forwardRef } from "react";
import workflow from "../../common/Assets/Icons/Work Flow Wrench.svg";
import del from "../../common/Assets/Icons/del.svg";
import galleryicon from "../../common/Assets/Icons/galleryicon.svg";
import DataTable from "react-data-table-component";
import Add_Main from "../../common/Assets/Icons/add_main.svg";
import documenticon from "../../common/Assets/Icons/documenticon.svg";
import WorkOrderIcon from "../../common/Assets/Icons/work order albums.svg";
import { CiSearch } from "react-icons/ci";
import WfEquipment from "../../common/Assets/Icons/Equipmentwf.svg";
import Furnace_outline from "../../common/Assets/Icons/Furnace_outline.svg";
import Oven from "../../common/Assets/Icons/Oven.svg";
import info from "../../common/Assets/Icons/Subtract.svg";
import Add_round from "../../common/Assets/Icons/Add_round.svg";
import Log from "../../common/Assets/Icons/Logs.svg";
import Info1 from "../../common/Assets/Icons/Info1.svg";
import eye_closer from "../../common/Assets/Icons/eye_closer.svg";
import DatePicker from "react-datepicker";
import "./CreateEstCard.css";
import Sort2 from "../../common/Assets/Icons/Sort2.svg";
import { TABLE_CUSTOMSTYLES } from "../../common/components/workordertable/Table";
import { WORK_ORDER_CONSTANTS } from "../../common/components/Constants";

const CreateEstCard = () => {
  const [isInfoOpen, setIsInfoOpen] = useState(true);
  const [isWorkflowsOpen, setIsWorkflowsOpen] = useState(true);
  const [isFurnaceRepairOpen, setIsFurnaceRepairOpen] = useState(true);
  const [isOvenRepairOpen, setIsOvenRepairOpen] = useState(true);
  const [isMediaCenterOpen, setIsMediaCenterOpen] = useState(true);
  const [isGallerySectionOpen, setIsGallerySectionOpen] = useState(true);
  const [isDocumentSectionOpen, setIsDocumentSectionOpen] = useState(true);
  const [isWorkOrdersOpen, setIsWorkOrdersOpen] = useState(true);
  const [IsLogsOpen, setIsLogsOpen] = useState(true);
  const [code, setCode] = useState("");
  const [status, setStatus] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [OpenCust, setOpenCust] = useState(false);
  const [details, setDetails] = useState({
    descption: "",
    note: "",
  });

  const toggleInfoSection = () => setIsInfoOpen((prev) => !prev);
  const toggleWorkflowsSection = () => setIsWorkflowsOpen((prev) => !prev);
  const toggleFurnaceRepairSection = () =>
    setIsFurnaceRepairOpen((prev) => !prev);
  const toggleOvenRepairSection = () => setIsOvenRepairOpen((prev) => !prev);
  const toggleMediaCenterSection = () => setIsMediaCenterOpen((prev) => !prev);
  const toggleGallerySection = () => setIsGallerySectionOpen((prev) => !prev);
  const toggleDocumentSection = () => setIsDocumentSectionOpen((prev) => !prev);
  const toggleWorkOrdersSection = () => setIsWorkOrdersOpen((prev) => !prev);
  const toggleLogsSection = () => setIsLogsOpen((prev) => !prev);

  const tags = Array.from({ length: 17 }, (_, index) => index + 1);
  const [initialData, setInitialData] = useState([
    {
      type: "Document",
      image: <img src={documenticon} width={20} alt="docicon" />,
      nameCode: "Pre Authorization Form 2340-104",
      description:
        "Form for pre-authorization of the tech to work on the equipment.",
      unitPrice: "$0",
      quantity: 1,
      totalPrice: "$0",
      addOn: true,
    },
    {
      type: "Equipment",
      image: <img src={WfEquipment} alt="WfWrench" width={28} />,
      nameCode: "Gasket 2340-104",
      description: "Gasket for Refrigeration Seal.",
      unitPrice: "$1.07",
      quantity: 1,
      totalPrice: "$3.21",
      addOn: false,
    },
    {
      type: "Service",
      image: "Image C",
      nameCode: "Repair Equipment 2340-104",
      description: "Equipment needs fixing. Get it done.",
      unitPrice: "$85",
      quantity: 1,
      totalPrice: "$170",
      addOn: true,
    },
    {
      type: "Part",
      image: "Image D",
      nameCode: "Gasket 2340-104",
      description: "Gasket for Refrigeration Seal.",
      unitPrice: "$1.07",
      quantity: 1,
      totalPrice: "$1.07",
      addOn: false,
    },
    {
      type: "Product E",
      image: "Image E",
      nameCode: "Trip Charge 2340-104",
      description: "Trip Charge for customers outside radius of 50 miles.",
      unitPrice: "$1.07",
      quantity: 1,
      totalPrice: "$4.28",
      addOn: true,
    },
    {
      type: "Additional Charge",
      image: "Image F",
      nameCode: "General Estimation Workflow 7889364",
      description: "A technician is sent out to check the issue.",
      unitPrice: "$0",
      quantity: 1,
      totalPrice: "$0",
      addOn: true,
    },
    {
      type: "Workflow",
      image: <img src={workflow} width={30} alt="Workflow Icon" />,
      nameCode: "Gold Star Customer 2340-104",
      description:
        "Customer subscribed to gold star subscription and received 20% discount.",
      unitPrice: "",
      totalPrice: "$56",
      addOn: false,
    },
    {
      type: "Discount",
      image: "Image H",
      nameCode: "Taxes 2340-104",
      description: "Government Mandated Taxes.",
      unitPrice: "",
      totalPrice: "$+8%",
      addOn: true,
    },
  ]);

  const [initialOvenData, setIntialOvenData] = useState([
    {
      type: "Document",
      image: <img src={documenticon} width={20} alt="docicon" />,
      nameCode: "Pre Authorization Form 2340-104",
      description:
        "Form for pre-authorization of the tech to work on the equipment.",
      unitPrice: "$0",
      quantity: 1,
      totalPrice: "$0",
      addOn: true,
    },
    {
      type: "Equipment",
      image: <img src={WfEquipment} alt="WfWrench" width={28} />,
      nameCode: "Gasket 2340-104",
      description: "Gasket for Refrigeration Seal.",
      unitPrice: "$1.07",
      quantity: 1,
      totalPrice: "$3.21",
      addOn: false,
    },
    {
      type: "Service",
      image: "Image C",
      nameCode: "Repair Equipment 2340-104",
      description: "Equipment needs fixing. Get it done.",
      unitPrice: "$85",
      quantity: 1,
      totalPrice: "$170",
      addOn: true,
    },
    {
      type: "Part",
      image: "Image D",
      nameCode: "Gasket 2340-104",
      description: "Gasket for Refrigeration Seal.",
      unitPrice: "$1.07",
      quantity: 1,
      totalPrice: "$1.07",
      addOn: false,
    },
    {
      type: "Product E",
      image: "Image E",
      nameCode: "Trip Charge 2340-104",
      description: "Trip Charge for customers outside radius of 50 miles.",
      unitPrice: "$1.07",
      quantity: 1,
      totalPrice: "$4.28",
      addOn: true,
    },
    {
      type: "Additional Charge",
      image: "Image F",
      nameCode: "General Estimation Workflow 7889364",
      description: "A technician is sent out to check the issue.",
      unitPrice: "$0",
      quantity: 1,
      totalPrice: "$0",
      addOn: true,
    },
    {
      type: "Workflow",
      image: <img src={workflow} width={30} alt="Workflow Icon" />,
      nameCode: "Gold Star Customer 2340-104",
      description:
        "Customer subscribed to gold star subscription and received 20% discount.",
      unitPrice: "",
      totalPrice: "$56",
      addOn: false,
    },
    {
      type: "Discount",
      image: "Image H",
      nameCode: "Taxes 2340-104",
      description: "Government Mandated Taxes.",
      unitPrice: "",
      totalPrice: "$+8%",
      addOn: true,
    },
  ]);

  const initialLogData = [
    {
      logNo: "0087",
      date: "10 Jul 2022 07:40:15 AM",
      action: "Equipment steps",
      author: "Michael Chan",
      details: "Completed the Step",
    },
    {
      logNo: "0086",
      date: "09 Jul 2022 07:40:15 AM",
      action: "Phone Call",
      author: "Michael Chan",
      details: "Called Customer",
    },
    {
      logNo: "0085",
      date: "08 Jul 2022 07:40:15 AM",
      action: "Chat",
      author: "Michael Chan",
      details: "Messaged Customer",
    },
    {
      logNo: "0084",
      date: "08 Jul 2022 07:20:21 AM",
      action: "Email",
      author: "Michael Chan",
      details: "Emailed Customer",
    },
    {
      logNo: "0083",
      date: "07 Jul 2022 12:40:15 AM",
      action: "File Upload",
      author: "Michael Chan",
      details: "Attached a PDF file to General Steps",
    },
    {
      logNo: "0082",
      date: "06 Jul 2022 09:40:15 AM",
      action: "Document Upload",
      author: "Michael Chan",
      details: "Uploaded a document to Equipment Steps",
    },
    {
      logNo: "0081",
      date: "05 Jul 2022 07:40:15 AM",
      action: "Photo Upload",
      author: "Michael Chan",
      details: "Uploaded a Photo to Equipment Steps",
    },
    {
      logNo: "0080",
      date: "04 Jul 2022 10:20:26 AM",
      action: "Document Upload",
      author: "Michael Chan",
      details: "Uploaded a document to Equipment Steps",
    },
    {
      logNo: "0080",
      date: "04 Jul 2022 10:20:26 AM",
      action: "Document Upload",
      author: "Michael Chan",
      details: "Uploaded a document to Equipment Steps",
    },
    {
      logNo: "0080",
      date: "04 Jul 2022 10:20:26 AM",
      action: "Document Upload",
      author: "Michael Chan",
      details: "Uploaded a document to Equipment Steps",
    },
    {
      logNo: "0080",
      date: "04 Jul 2022 10:20:26 AM",
      action: "Document Upload",
      author: "Michael Chan",
      details: "Uploaded a document to Equipment Steps",
    },
    {
      logNo: "0080",
      date: "04 Jul 2022 10:20:26 AM",
      action: "Document Upload",
      author: "Michael Chan",
      details: "Uploaded a document to Equipment Steps",
    },
  ];

  const [data, setData] = useState(initialData);
  const [ovenData, setOvenData] = useState(initialOvenData);
  const [logData, setLogData] = useState(initialLogData);
  const [isCustomerOpen, setIsCustomerOpen] = useState(false);

  const HandleInputsWo = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleQuantityChange = (index, change) => {
    setData((prevData) => {
      const newData = [...prevData];
      const newQuantity = Math.max(0, newData[index].quantity + change);
      newData[index].quantity = newQuantity;
      return newData;
    });
  };

  const handleOvenQuantityChange = (index, change) => {
    setOvenData((prevOvenData) => {
      const newOvenData = [...prevOvenData];
      newOvenData[index].quantity = Math.max(
        0,
        newOvenData[index].quantity + change
      );
      return newOvenData;
    });
  };

  const handleDelete = (index) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleOvenDelete = (index) => {
    setOvenData((prevOvenData) => prevOvenData.filter((_, i) => i !== index));
  };

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input mt-1"
      placeholder="Select Date"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ));

  const [startDate, setStartDate] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [logOrderName, setLogOrderName] = useState("");
  const [logOrderNumber, setLogOrderNumber] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [workOrderType, setWorkOrderType] = useState("");

  const columns = [
    // {
    //   name: (
    //     <div>
    //       <img src={Sort2} width={20} height={20} alt="open srt" />
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <img className="cursor-p" src={MemoryIcon} alt="MemoryIcon" />
    //   ),
    //   width: "60px",
    // },

    {
      name: (
        <div className="table-head-cells-estimates">
          {/* <img
            src={galleryicon}
            width={20}
            height={20}
            alt="open srt"
            className="imagebottom"
          /> */}
        </div>
      ),
      selector: (row) => (
        <div className="table-head-cells-estimates">
          <input className="cursor-p WoFldsCheckbox mt-1" type="checkbox" />
        </div>
      ),
      // omit: isTabCol?.length > 0 && isTabCol?.includes("WO Number"),
      width: "60px",
    },

    {
      name: (
        <div className="table-head-cells-estimates">
          Type <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-type">{row.type}</div>,
      // omit: isTabCol?.length > 0 && isTabCol?.includes("WO Number"),
      width: "130px",
    },
    {
      name: <div className="table-head-cells-estimates">Image</div>,
      selector: (row) => <div className="table-date">{row.image}</div>,
      // omit: isTabCol?.length > 0 && isTabCol?.includes("Customer"),
      width: "130px",
    },

    {
      name: (
        <div className="table-head-cells-estimates">
          Name & Code
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.nameCode}</div>,
      // omit: isTabCol?.length > 0 && isTabCol?.includes("Status"),
      width: "280px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Description
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.description}</div>,
      // omit: isTabCol?.length > 0 && isTabCol?.includes("Address"),
      width: "280px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Unit Price
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.unitPrice}</div>,
      // omit: isTabCol?.length > 0 && isTabCol?.includes("Contacts"),
      width: "120px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Quantity
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row, index) =>
        row.quantity >= 0 && (
          <div className="table-quantity">
            <button
              onClick={() => handleQuantityChange(index, -1)}
              className="quantity-btn"
              disabled={row.quantity <= 0}
            >
              -
            </button>
            <span className="quantity">{row.quantity}</span>
            <button
              onClick={() => handleQuantityChange(index, 1)}
              className="quantity-btn"
            >
              +
            </button>
          </div>
        ),
      width: "110px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Total Price
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.totalPrice}</div>,
      width: "120px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Add-On?
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => (
        <div className="table-date">
          <input className="cursor-p WoFldsCheckbox mt-1" type="checkbox" />
        </div>
      ),
      width: "110px",
    },
    {
      name: <div className="table-head-cells-estimates"></div>,
      selector: (row) => (
        <div className="table-date">
          <img src={del} />
        </div>
      ),
      width: "90px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          <img src={Info1} />
        </div>
      ),
      selector: (row) => (
        <div className="table-date">
          {" "}
          <img src={eye_closer} />
        </div>
      ),
      width: "90px",
    },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_COLUMNS_NAME.TABLE_LEAD_TECH}
    //       <img
    //         src={galleryicon}
    //         width={20}
    //         height={20}
    //         alt="open srt"
    //         className="imagebottom"
    //       />
    //     </div>
    //   ),
    //   selector: (row) => row?.additional_tech,
    //   width: "130px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_COLUMNS_NAME.TABLE_ADDIONAL_TECHS}
    //       <img
    //         src={Sort2}
    //         width={20}
    //         height={20}
    //         alt="open srt"
    //         className="imagebottom"
    //       />
    //     </div>
    //   ),
    //   selector: (row) => row.title,
    //   width: "185px",
    // },

    // {
    //   name: (
    //     <div className="table-head-cells2">
    //       {TABLE_COLUMNS_NAME.TABLE_CITY}
    //       <img
    //         src={Sort2}
    //         width={20}
    //         height={20}
    //         alt="open srt"
    //         className="imageTop"
    //       />
    //     </div>
    //   ),
    //   selector: (row) => <div className="table-date">{row.city}</div>,
    //   width: "123px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_COLUMNS_NAME.TABLE_STATE}
    //       <img
    //         src={Sort2}
    //         width={20}
    //         height={20}
    //         alt="open srt"
    //         className="imagebottom"
    //       />
    //     </div>
    //   ),
    //   selector: (row) => <div className="table-date">{row.state}</div>,
    //   width: "105px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_COLUMNS_NAME.TABLE_TAGS}
    //       <img
    //         src={Sort2}
    //         width={20}
    //         height={20}
    //         alt="open srt"
    //         className="imagebottom"
    //       />
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <div className="d-flex gap-1">
    //       {row.tags?.map((i, index) => (
    //         <div key={index} className="table-tags">
    //           {i.tag_data}
    //         </div>
    //       ))}
    //     </div>
    //   ),
    //   omit: isTabCol?.length > 0 && isTabCol?.includes("Tags"),
    //   // maxWidth: "190px",
    // },
  ];

  const columns2 = [
    {
      name: <div className="table-head-cells-estimates"></div>,
      selector: (row) => (
        <div className="table-head-cells-estimates">
          {" "}
          <input className="cursor-p WoFldsCheckbox mt-1" type="checkbox" />
        </div>
      ),
      width: "60px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Type
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-type">{row.type}</div>,
      width: "130px",
    },
    {
      name: <div className="table-head-cells-estimates">Image</div>,
      selector: (row) => <div className="table-date">{row.image}</div>,
      width: "130px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Name & Code
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.nameCode}</div>,
      width: "280px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Description
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.description}</div>,
      width: "280px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Unit Price
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.unitPrice}</div>,
      width: "120px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Quantity
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row, index) =>
        row.quantity >= 0 && (
          <div className="table-quantity">
            <button
              onClick={() => handleOvenQuantityChange(index, -1)}
              className="quantity-btn"
              disabled={row.quantity <= 0}
            >
              -
            </button>
            <span className="quantity">{row.quantity}</span>
            <button
              onClick={() => handleOvenQuantityChange(index, 1)}
              className="quantity-btn"
            >
              +
            </button>
          </div>
        ),
      width: "110px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Total Price
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.totalPrice}</div>,
      width: "120px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          Add-On?
          <img src={Sort2} alt="Sort2" width={20} />
        </div>
      ),
      selector: (row) => (
        <div className="table-date">
          <input className="cursor-p WoFldsCheckbox mt-1" type="checkbox" />
        </div>
      ),
      width: "110px",
    },
    {
      name: <div className="table-head-cells-estimates"></div>,
      selector: (row) => (
        <div className="table-date">
          <img src={del} alt="Delete" />
        </div>
      ),
      width: "90px",
    },
    {
      name: (
        <div className="table-head-cells-estimates">
          <img src={Info1} />
        </div>
      ),
      selector: (row) => (
        <div className="table-date">
          <img src={eye_closer} />
        </div>
      ),
      width: "90px",
    },
  ];

  const columns3 = [
    {
      name: <div className="table-head-cells-estimates">Log No.</div>,
      selector: (row) => <div className="table-date">{row.logNo}</div>,
      width: "200px",
    },
    {
      name: <div className="table-head-cells-estimates">Date</div>,
      selector: (row) => <div>{row.date}</div>,
      width: "270px",
    },
    {
      name: <div className="table-head-cells-estimates">Action</div>,
      selector: (row) => <div>{row.action}</div>,
      width: "300px",
    },
    {
      name: <div className="table-head-cells-estimates">Author</div>,
      selector: (row) => <div>{row.author}</div>,
      width: "270px",
    },
    {
      name: <div className="table-head-cells-estimates">Details</div>,
      selector: (row) => <div>{row.details}</div>,
      width: "400px",
    },
  ];

  const customStyles = TABLE_CUSTOMSTYLES;
  return (
    <div className="estimate m-1 w-100">
      {/* Info Section */}
      <div className="info-section">
        <div
          className="header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            onClick={toggleInfoSection}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <img
              className="cursor-p"
              src={info}
              width={18}
              alt="Info Icon"
              style={{ marginRight: "8px" }}
            />
            <span className="Info-header">Info</span>
          </div>
        </div>
        {isInfoOpen && (
          <>
            <div className="estimate-row">
              <div className="">
                <label className="name-lbl-est">Estimate Name:</label>
                <input
                  type="text"
                  id="estimateName"
                  placeholder="Input Name"
                  className="inpt-address-est ml-2 inpt-hover-est"
                  value={estimateName}
                  onChange={(e) => setEstimateName(e.target.value)}
                  style={{ height: "30px", width: "300px", cursor: "pointer"}}
                />
              </div>

              <div className="">
                <label className="name-lbl-est">Customer Name:</label>
                <input
                  type="text"
                  id="customerName"
                  placeholder="Hillsville Work Order"
                  className="inpt-address-est ml-2 inpt-hover-est"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>

              <div className="">
                <label className="name-lbl-est">Work Order Type:</label>
                <input
                  type="text"
                  id="workOrderType"
                  placeholder="Input Type"
                  className="inpt-address-est ml-2 inpt-hover-est"
                  value={workOrderType}
                  onChange={(e) => setWorkOrderType(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>
            </div>

            <div className="estimate-row">
              {/* Code Field */}
              <div
                className=""
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">Code:</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="code"
                  placeholder="Input Code"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "code")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2 inpt-hover-est"
                  }
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>

              {/* Status Field */}
              <div
                className=""
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">Status:</label>
                <select
                  id="status"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "status")
                      ? "cond-style-inpt-det-status" 
                      : "inpt-address-est-status ml-2" 
                  }
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                >
                  <option>Select status</option>
                </select>
              </div>

              {/* Telephone Field */}
              <div
                className=""
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">Telephone:</label>
                <input
                  type="text"
                  id="telephone"
                  placeholder="1-555-555-5555"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "telephone")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2 inpt-hover-est"
                  }
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>
            </div>

            <div className="estimate-row">
              {/* Email Field */}
              <div
                className=""
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">Email:</label>
                <input
                  type="email"
                  id="email"
                  placeholder="customeremail@customeremail.cc"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "email")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2 inpt-hover-est"
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>

              {/* Address Field */}
              <div
                className=""
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">Address:</label>
                <input
                  type="text"
                  id="address"
                  placeholder="Enter Address"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "address")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2 inpt-hover-est"
                  }
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>

              {/* City Field */}
              <div
                className=""
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">City:</label>
                <input
                  type="text"
                  id="city"
                  placeholder="Enter City"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "city")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2 inpt-hover-est"
                  }
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>
            </div>

            <div className="estimate-row">
              {/* State Field */}
              <div
                className="half-width"
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">State:</label>
                <input
                  type="text"
                  id="state"
                  placeholder="Enter State"
                  className={
                    error?.length > 0 &&
                    error.some((err) => err.name === "state")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2 inpt-hover-est"
                  }
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>

              {/* Zip Field */}
              <div
                className="half-width"
                style={{ pointerEvents: OpenCust ? "none" : "" }}
              >
                <label className="name-lbl-est">Zip:</label>
                <input
                  type="text"
                  id="zip"
                  placeholder="Enter Zip"
                  className={
                    error?.length > 0 && error.some((err) => err.name === "zip")
                      ? "cond-style-inpt-det inpt-hover-est"
                      : "inpt-address-est ml-2  inpt-hover-est"
                  }
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  style={{ height: "30px", width: "300px" }}
                />
              </div>
            </div>

            {/* Note Field */}
            <div style={{ pointerEvents: OpenCust ? "none" : "" }}>
              <label className="name-lbl-est mt-2">Note:</label>
              <div>
                <input
                  autoComplete="off"
                  placeholder="Input Description"
                  id="edit-inp-text"
                  className={
                    error?.length > 0 &&
                    error?.map((err) => err?.name === "descption") &&
                    details?.descption?.length <= 0
                      ? "cond-style-desc-ipt-wkord-reason  inpt-hover-est"
                      : "desc-ipt-wkord-reason-info ml-2  inpt-hover-est"
                  }
                  name="descption"
                  value={details?.note}
                  onChange={(e) => HandleInputsWo(e)}
                />
              </div>
            </div>

            {/* Tags Section */}
            <div className="tag-section">
              <button className="tag-button mx-2 mt-1 tag-btn">
                {WORK_ORDER_CONSTANTS.TAG}{" "}
                <img src={Add_round} width={13} alt="Add Round" />
              </button>
              <div>
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className="tag-styles ml-1"
                    style={{
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "BLACK",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    {WORK_ORDER_CONSTANTS.TAG}
                    {`${tag}`}
                  </span>
                ))}
              </div>
              <div className="tag-text">
                Created by John Hancock at 8:56:00 A.M. on 9/12/23
              </div>
            </div>
          </>
        )}
      </div>

      {/* Workflows Section */}
      <div className="workflows-section">
        <div className="workflow-header">
          <div
            onClick={toggleWorkflowsSection}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={workflow}
              width={28}
              alt="Workflow Icon"
              style={{ marginRight: "6px" }}
            />
            <span className="workflow-title">Workflows</span>
          </div>
          <div className="add-icon-container-workflow">
            <img src={Add_Main} width={24} alt="Add Main" />
          </div>
        </div>
        {isWorkflowsOpen && (
          <p className="workflow-instruction">
            Please select a workflow to continue
          </p>
        )}
      </div>

      {/* Furnace Repair Section */}
      <div className="furnace-repair-section">
        <div className="furnace-header">
          <div
            onClick={toggleFurnaceRepairSection}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={Furnace_outline}
              width={26}
              alt="Furnace Outline"
              style={{ marginRight: "8px" }}
            />
            <span className="furnace-title">Furnace Repair</span>
          </div>
          <div className="add-icon-container-furnace mr-1">
            <div className="cost" style={{ marginRight: "10px" }}>
              <span>Total cost:</span>
              <p>$185,482</p>
            </div>
            <img
              className="mb-3"
              width={18}
              src={del}
              alt="Delete"
              style={{ marginRight: "7px" }}
              onClick={() => handleDelete(0)}
            />
            <img
              className="mb-3"
              src={galleryicon}
              alt="Gallery"
              style={{ marginRight: "7px" }}
            />
            <img className="mb-3" src={Add_Main} alt="Add Main" />
          </div>
        </div>
        {isFurnaceRepairOpen && (
          <>
            <div
              style={{
                pointerEvents: OpenCust ? "none" : "",
              }}
            >
              <div>
                <input
                  autoComplete="off"
                  placeholder="Input Description"
                  id="edit-inp-text"
                  className={
                    error?.length > 0 &&
                    error?.map((err) => err?.name === "descption") &&
                    details?.descption?.length <= 0
                      ? "cond-style-desc-ipt-wkord-reason  inpt-hover-est "
                      : "desc-ipt-wkord-reason-furnace  inpt-hover-est"
                  }
                  name="descption"
                  value={details?.note}
                  onChange={(e) => HandleInputsWo(e)}
                />
              </div>
            </div>

            <div className="furnace-table" style={{ border: "1px solid gray" }}>
              <DataTable
                data={initialData}
                columns={columns}
                customStyles={customStyles}
              />
            </div>

            <div className="button1">
              <button>
                Add Service(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Parts(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Document(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Equipment(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Work Flow(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Miscellaneous(s) <img src={Add_Main} alt="Add Main" />
              </button>
            </div>
          </>
        )}
      </div>

      {/* Oven Repair Section */}
      <div className="oven-repair-section">
        <div className="oven-header">
          <div
            onClick={toggleOvenRepairSection}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={Oven}
              width={26}
              alt="Oven"
              style={{ marginRight: "8px" }}
            />
            <span className="oven-title">Oven Repair</span>
          </div>
          <div className="add-icon-container-oven mr-1">
            <div className="cost" style={{ marginRight: "10px" }}>
              <span>Total cost:</span>
              <p>$185,482</p>
            </div>
            <img
              className="mb-3"
              src={del}
              width={17}
              alt="Delete"
              style={{ marginRight: "7px" }}
              onClick={() => handleOvenDelete(0)}
            />
            <img
              className="mb-3"
              src={galleryicon}
              alt="Gallery"
              style={{ marginRight: "7px" }}
            />
            <img className="mb-3" src={Add_Main} alt="Add Main" />
          </div>
        </div>
        {isOvenRepairOpen && (
          <>
            <div
              style={{
                pointerEvents: OpenCust ? "none" : "",
              }}
            >
              <div>
                <input
                  autoComplete="off"
                  placeholder="Input Description"
                  id="edit-inp-text"
                  className={
                    error?.length > 0 &&
                    error?.map((err) => err?.name === "descption") &&
                    details?.descption?.length <= 0
                      ? "cond-style-desc-ipt-wkord-reason  inpt-hover-est "
                      : "desc-ipt-wkord-reason-oven inpt-hover-est"
                  }
                  name="descption"
                  value={details?.note}
                  onChange={(e) => HandleInputsWo(e)}
                />
              </div>
            </div>

            <div className="oven-table" style={{ border: "1px solid gray" }}>
              <DataTable
                data={initialOvenData}
                columns={columns2}
                customStyles={customStyles}
              />
            </div>

            <div className="button1">
              <button>
                Add Service(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Parts(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Document(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Equipment(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Work Flow(s) <img src={Add_Main} alt="Add Main" />
              </button>
              <button>
                Add Miscellaneous(s) <img src={Add_Main} alt="Add Main" />
              </button>
            </div>
          </>
        )}
      </div>

      {/* Media Center Section */}
      <div className="media-center">
        <div className="media-header">
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <img
              className="mb-1"
              src={galleryicon}
              alt="Media Center Icon"
              style={{ marginRight: "8px" }}
              onClick={toggleMediaCenterSection}
            />
            <span className="media-title" onClick={toggleMediaCenterSection}>
              Media Center
            </span>
          </div>
        </div>
        {isMediaCenterOpen && (
          <>
            <div className="media-gallery">
              <div className="media-header">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    className="mb-1 mx-1"
                    width={20}
                    src={galleryicon}
                    alt="Gallery Icon"
                    style={{ marginRight: "8px" }}
                    onClick={toggleGallerySection}
                  />
                  <span
                    className="gallery-title mb-1"
                    onClick={toggleGallerySection}
                  >
                    Gallery (11)
                  </span>
                </div>
              </div>
              {isGallerySectionOpen && (
                <div className="media-gallery-items">
                  {[...Array(4)].map((_, index) => (
                    <div key={index} className="media-gallery-item">
                      <video controls width="100%">
                        <source src="video-placeholder.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="media-documents">
              <div className="media-header">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={documenticon}
                    width={17}
                    alt="Document Icon"
                    style={{ marginRight: "5px" }}
                    onClick={toggleDocumentSection}
                  />
                  <span
                    className="document-title"
                    onClick={toggleDocumentSection}
                  >
                    Documents (17)
                  </span>
                </div>
              </div>
              {isDocumentSectionOpen && (
                <div className="media-documents-items">
                  {[...Array(4)].map((_, index) => (
                    <div key={index} className="media-document-item">
                      <video controls width="100%">
                        <source src="video-placeholder.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* Work Orders Section */}
      <div className="workorders-section">
        <div className="workorders-header">
          <div
            onClick={toggleWorkOrdersSection}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              className="mb-1"
              src={WorkOrderIcon}
              width={27}
              alt="Workflow Icon"
              style={{ marginRight: "6px" }}
            />
            <span className="workorders-title">Work Orders</span>
          </div>
        </div>
        {isWorkOrdersOpen && (
          <p className="workorders">
            Previous work orders will show up here once they have been tied up.
          </p>
        )}
      </div>

      {/* Logs Section at the end */}
      <div className="Logs-section p-1">
        <div className="Logs-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              className="mb-1"
              src={Log}
              width={28}
              alt="Logs"
              style={{ marginRight: "8px" }}
              onClick={toggleLogsSection}
            />
            <span className="Logs-title mb-1" onClick={toggleLogsSection}>
              Logs
            </span>
          </div>
        </div>

        {IsLogsOpen && (
          <div className="date-picker-search-container">
            <div className="date-picker-group">
              <span className="side-filter-text">From Date</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>

            <div className="date-picker-group">
              <span className="side-filter-text">To Date</span>
              <DatePicker
                selected={completedDate}
                onChange={(date) => setCompletedDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>

            <div className="search-group">
              <span className="side-filter-text">General Action</span>
              <div>
                <CiSearch className="inp-inside-icon" />
                <input
                  className="sidebar-search"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setLogOrderName(e.target.value)}
                  value={logOrderName}
                />
              </div>
            </div>

            <div className="search-group">
              <span className="side-filter-text">Author</span>
              <div>
                <CiSearch className="inp-inside-icon" />
                <input
                  className="sidebar-search"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setLogOrderNumber(e.target.value)}
                  value={logOrderNumber}
                />
              </div>
            </div>

            {/* logs-table */}
            <div className="logs-table">
              <DataTable
                data={initialLogData}
                columns={columns3}
                pagination={true}
                customStyles={customStyles}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateEstCard;
