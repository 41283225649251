import React, { useEffect, useState } from "react";
import "./CommonFooter.css";
function CommonFooter(props) {
  let [resData, setResData] = useState({});
  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("CompanyDetails"));
    setResData(Data);
  }, []);
  const ScrollFooter = (value) => {
    if (value === undefined) {
      return "botDiv mt-2 p-1 px-2 footer-fix flx-wrp";
    } else if (value !== undefined && value?.length < 10) {
      return "botDiv mt-2 p-1 px-2 footer-fix flx-wrp ";
    } else {
      return "botDiv mt-2 p-1 px-2 footer-fix2 flx-wrp";
    }
  };
  return (
    <>
      <div className={ScrollFooter(props?.data)}>
        <div className="botOne">
          <span className="comp-foot">
            <b>{resData?.company_name}</b>
          </span>
          <span>Address: {resData?.address} </span>
          <span> {resData?.address2} , </span>
          <span> E-mail: {resData?.email} </span>
        </div>

        <div className="botTwo">
          <span>Home | About Us | Contact Us</span>
          <span> Powered by 9Pack Software (version 1.0.0) </span>
        </div>
      </div>
    </>
  );
}

export default CommonFooter;
