import React, { forwardRef, useCallback, useEffect, useState } from "react";
import "./WoDetailsCommunication.css";
import { Client } from "@twilio/conversations";
import plusIcon from "../../common/Assets/Icons/add.svg";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import { authTokenAsynk } from "../../services/CommunicationsService";
import { useNavigate } from "react-router-dom";
import { WORK_ORDER_CONSTANTS } from "../../common/components/Constants";
import vcn from "../../common/Assets/Icons/Communication.svg";

const WoDetailsCommunication = forwardRef(function WoDetailsCommunication(
  props,
  ref
) {
  // const [convData, setConvData] = useState([]);
  const [LocStrId, setLocStrId] = useState(0);
  const [isCommunicationToggle, setIsCommunicationToggle] = useState(true);
  const HandleCommunicationToggle = () => {
    setIsCommunicationToggle(!isCommunicationToggle);
  };
  const TwiloFunct = useCallback((usertoken) => {
    const client = new Client(usertoken);

    client?.on("conversationJoined", async (conversation) => {
      let msges = await conversation.getMessages();
      const resMsges = msges.items;
      if (resMsges.length) {
        conversation.lastmessage = resMsges[resMsges.length - 1].body;

        conversation.author = resMsges[resMsges.length - 1].author;
        // setConvData((conversaionList) => [...conversaionList, conversation]);
      }
    });
  }, []);
  const GetAuthToken = useCallback(async () => {
    let user =
      JSON.parse(localStorage.getItem("userName")) !== null
        ? JSON.parse(localStorage.getItem("userName"))
        : props?.User;

    let serviceSid = "ISc3df9f655e83415e9bf7ef48599575bd";
    let inpdata = {
      serviceSid: serviceSid,
      identity: user,
    };
    let res = await authTokenAsynk(inpdata);

    if (res.status === 200) {
      localStorage.setItem("token", JSON.stringify(res.data.content.token));
      TwiloFunct(res.data.content.token);
    }
  }, [TwiloFunct, props?.User]);

  useEffect(() => {
    GetAuthToken();
    setLocStrId(localStorage.getItem("userId"));
  }, [GetAuthToken]);

  const navigation = useNavigate();

  const [comTgl, setComTgl] = useState("");

  const [toggleData, setToggledData] = useState([]);
  const HandleToggle = (value) => {
    let data = [...toggleData];
    if (data.includes(value)) {
      data = toggleData.filter((it) => it !== value);
    } else {
      data = [...data, value];
    }
    setToggledData(data);
  };
  const HandleNav = () => {
    NavigateFunction({
      to: `Communications`,
      navigate: navigation,
    });
  };
  const loc = window.location.href.split("/")[5]
  return (
    <div>
      <div className=" det-txt-Wo cn-card-header " ref={ref}>
        <div className="communication-card cn-card-header clp_header">
          <div className="myDiv ">
            <img
             onClick={() => HandleCommunicationToggle()}
              src={vcn}
              className=" chat-img"
              alt="details"
            />
            <span
              className=" cursor-p mr-2"
              onClick={() => HandleCommunicationToggle()}
            >
              {WORK_ORDER_CONSTANTS.COMMU}
            </span>
          </div>
          <div className="myDiv1">
          {loc > 0 && (
            <img
              style={{pointerEvents: (LocStrId ? "" : "none") || (window?.location?.href?.split("/")[10] === "1" ? "none" : "auto")}}
              src={plusIcon}
              className="img-pointer plusIcon-modal-cmn"
              alt="rite"
              onClick={() =>{if(props.OpenCust === false || props.OpenCust ===undefined){ HandleNav()}}}
            />
          )}
          </div>
        </div>
         {isCommunicationToggle ? (
          <div className="clp_body">
          {comTgl === "comtg" ? (
            ""
          // ) :
          //  convData?.length > 0 ? (
          //   <div style={{ pointerEvents: LocStrId ? "" : "none" }}>
          //     <div>
          //       {convData?.slice(0, 4).map((item, index) => (
          //         <div key={index}>
          //           <div className="communication-card">
          //             <div className="chat-name-img">
          //               <div>
          //                 {" "}
          //                 <img
          //                   src={acc}
          //                   alt="account note"
          //                   onClick={() => HandleToggle(index)}
          //                 />
          //               </div>
          //               <div className="mx-1 chat-name-text">
          //                 {item.friendlyName}
          //               </div>
          //             </div>
          //             <div
          //               className="d-flex"
          //               style={{ flexDirection: "row" }}
          //             >
          //               <div className="date-styles">
          //                 {item.lastMessage === undefined
          //                   ? moment(item.dateCreated)
          //                       .utc()
          //                       .format("ddd . H:mma")
          //                   : moment(item.lastMessage.dateCreated)
          //                       .utc()
          //                       .format("ddd . H:mma")}
          //               </div> 
          //               <span style={{ fontSize: "10px" }}>RECIEVED</span> 
          //                <img src={chat} alt="chat" />
          //            </div>
          //           </div>
          //           {toggleData.includes(index) ? (
          //             ""
          //           ) : (
          //             <div style={{ fontSize: "10px" }}> 
          //              <span> {item.author ? item.author : null}</span> 
          //                <span className="chat-content">
          //                 {" "}
          //                 {item.lastmessage
          //                   ? item.lastmessage
          //                   : "there are no msges"}
          //               </span>
          //             </div>
          //           )}
          //         </div>
          //       ))}
          //     </div>
          //   </div>
          ) : (
            <div className="">
              <span className="communication-card-dscrptn">
                {WORK_ORDER_CONSTANTS.COMMUNCTN_CARD}
              </span>
            </div>
          )}
        </div>
        ) : (
          ""
        )
        } 
      </div>
    </div>
  );
});
export default WoDetailsCommunication;
