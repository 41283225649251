import React from "react";
import CommonHeader from "../../../../common/framework/header/HeaderCommon/CommonHeader";
import Close from "../../../../common/Assets/Images/close_ring.svg";
import Save from "../../../../common/Assets/Images/Unionsave.svg";
import add from "../../../../common/Assets/Icons/add.svg";
import notepadIcon from "../../../../common/Assets/Icons/notepad.svg";
import threeDots from "../../../../common/Assets/Icons/three_lines.svg";
import Circlert from "../../../../common/Assets/Icons/circle_right_alt.svg";
import FormLight from "../../../../common/Assets/Icons/Form_light.svg";
import Illustration from "../../../../common/Assets/Icons/Ilustrations.svg";
import { MdOutlineEdit } from "react-icons/md";
import del from "../../../../common/Assets/Icons/del.svg";
import delW from "../../../../common/Assets/Icons/delW.svg";
import "./CreateWorkflow.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { WORKFLOW_VALUES } from "../../../../common/components/Values";
import {
  AddChoice,
  ChoiceDelete,
  CreateWorkFlow,
  DeleteStepAsync,
  GetAttchMentByIDMaintanance,
  MsterForms,
  WFById,
  WorkFlowAddStep,
  WorkflowFetch,
  WorkflowNotification,
  WorkflowTeams,
} from "../../../../services/SettingsServices";
import { useEffect } from "react";
import Select, { components } from "react-select";
import DeleteModal from "../../../../common/components/Alerts/DeleteModal";
import { useCallback } from "react";
import { NavigateFunction } from "../../../../common/navigation/NavigationChild";
import { UniType_Data, Workflow_Table_Category } from "../../../../common/components/Constants";

export default function CreateWorkflow() {
  useEffect(() => {
    GetImages();
    getMasterForms();
  }, []);

  const [masterData, setmasterData] = useState([]);
  const getMasterForms = async () => {
    let Res = await MsterForms();
    setmasterData(Res?.data);
  };
  const [Images, setImages] = useState([]);
  const GetImages = () => {
    function importAll(r) {
      let imgArr = [];
      let Obj = {
        filename: "",
        baseCode: "",
      };
      r.keys().map((item) => {
        Obj = { filename: item.replace("./", ""), baseCode: r(item) };
        imgArr.push(Obj);
        return imgArr;
      });
      return imgArr;
    }
    const images = importAll(
      require.context(
        "../../../../common/Assets/MaintenanceIcons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    let arrImagres = [];
    arrImagres.push(images);
    setImages(arrImagres[0]);
  };

  const navigation = useNavigate();
  const location = useLocation();
  const [isStepData, setIsStepdata] = useState([]);
  const [isStepId, setIsStepId] = useState(0);
  const [isTeams, setIsTems] = useState([]);
  const [isNotification, setIsNotification] = useState([]);
  const [WfName, setWfName] = useState("");
  const UpdateWFById = useCallback(
    async (id) => {
      let Res = await WFById(id);
      if (Res?.success) {
        HandleSeriesSubArr(Res?.data?.workflow_steps);
        let newObj = {
          name: Res?.data?.workflow?.name,
          description: Res?.data?.workflow?.description,
          category: Res?.data?.workflow?.category,
          AutoRule: "",
          AutoAttached: ""
        }
        setInpObj(newObj);
        setSelectedNotify(
          Res?.data?.workflow?.notification?.map((it) => ({
            label: it,
            value: it,
          }))
        )
        // setSelectedStepNotify(
        //   Res?.data?.workflow?.notification?.map((it) => ({
        //     label: `${it?.email} ${it?.email}`,
        //     value: it?.email,
        //   }))
        // );
        setSelectedOptions(
          Res?.data?.workflow?.team?.map((it) => ({
            label: isTeams
              ?.filter((i) => i?.id === it)
              ?.map((item) => item?.name),
            value: it,
          }))
        );
      }
    },
    [isTeams]
  );
  useEffect(() => {
    if (location?.state?.props) {
      UpdateWFById(location?.state?.props?.workflow_id);
      setWfName(location?.state?.props?.name);
      setIsStepId(location?.state?.props?.workflow_id);
    }
  }, [UpdateWFById, location?.state]);

  // const UpdateWfDataStep = async (id) => {
  //   let Res = await UpdateWfStep(id);
  //   setIsStepdata(Res?.data);
  // };
  useEffect(() => {
    DropDownData();
  }, []);
  const [isToggle, setIsToggle] = useState(false);
  const [isStep, setIsStep] = useState(false);
  const [isVal, setIsVal] = useState("");
  const HandleToggle = (item) => {
    setIsToggle(!isToggle);
    setIsVal(item);
  };
  const [isSeries, setIsSeries] = useState("");
  const HandleAddStep = (type) => {
    setIsSeries(type);
    setSelectedStepNotify([]);
    setSelectedStepNotify2([]);
    setIsNoData([]);
    setIsYesData([]);
    setYSelect("");
    setNSelect("");
    setSelectedIcons([]);
    setDropDownArr([]);
    setIsStep(true);
    setStepEid(0);
    setAddInpObj({ field_type: "", unit_type: "" });
    // setCompleteFormObj({
    //   complete_form: false,
    //   editable_form: false,
    //   form: [],
    // });
    setIsNoteObj({
      step_type: "",
      name: "",
      step_instructions: "",
      notification_message: "",
      master_form_field: "",

      notification_user: [],
      step_required: false,
      allow_note: false,
      image_required: false,
      allow_customer_to_view: false,
    });
    if (type === "Series") {
      setIsSeriesOptionSeries([]);
      setIsNoteObj({
        step_type: "Create Series",
        name: "",
        step_instructions: "",
        notification_message: "",
        master_form_field: "",
        notification_user: [],
        step_required: false,
        allow_note: false,
        image_required: false,
        allow_customer_to_view: false,
      });
    }
  };

  const DropDownData = async () => {
    let Res = await WorkflowTeams();
    if (Res?.success) {
      setIsTems(Res?.data);
    }
    let Response = await WorkflowNotification();
    if (Response?.success) {
      setIsNotification(Response?.data);
    }
  };
  const DropdownForm = (
    <div className="mx-2 dropdown-styles">
      <div>
        <input className="dropdown-styles-inputs" placeholder="Sort A to Z" />
        <input className="dropdown-styles-inputs" placeholder="Sort Z to A" />
        <input className="dropdown-styles-inputs" placeholder="Enter Search" />
      </div>
      <div
        className="d-flex "
        style={{
          flexDirection: "column",
          height: "230px",
          overflow: "auto",
        }}
      >
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">Technicians</span>
        </div>
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">Dispatchers</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">HVAC Techs</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">Plumbing Techs</span>
        </div>
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>{" "}
        <div className="mx-1 mt-1 d-flex ">
          <input type="checkbox" className="check-box-styles" />
          <span className="mx-2 span-dropdown2">For the best UI drop down</span>
        </div>
      </div>
    </div>
  );

  const handleChooseFile = () => {
    document.getElementById("add-content").click();
  };

  const [inpObj, setInpObj] = useState({
    name: "",
    description: "",
    category: "",
    AutoRule: "",
    AutoAttached: ""
  });
  const HandleInputChanges = (e) => {
    const { name, value } = e?.target;
    setInpObj({ ...inpObj, [name]: value });
  };
  const [isErrFields, setIsErrFields] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const HandleSave = async () => {
    let filterOne = [];
    let filArr = Object.keys(inpObj);
    filArr.map((it) => {
      if (inpObj[it]?.length <= 0 && it !== "AutoRule" && it !== "AutoAttached" && it !== "category") {
        filterOne.push(it);
      }
      return filterOne;
    });
    if (
      filterOne?.length > 0
      // ||
      // selectedNotify?.length <= 0 ||
      // selectedOptions?.length <= 0
    ) {
      setIsErrFields(filterOne);
    }

    else {

      let resObj = {
        workflow: isStepId,
        name: inpObj?.name,
        description: inpObj?.description,
        team: selectedOptions.map((it) => it.value),
        notification: selectedNotify.map((it) => it.value),
        category: +inpObj?.category,
      };
      let Res = await CreateWorkFlow(resObj);
      if (Res?.success) {
        setIsSave(true);
        setTimeout(() => {
          setIsSave(false);
        }, 2000);
        setIsStepId(Res?.data?.workflow_id);
      }
    }
  };
  const [isDiscard, setIsDiscard] = useState(false);
  const NavBack = () => {
    // setIsDiscard(true);
    NavigateFunction({
      to: `Settings`,
      params: "WorkFlowSetUp",
      navigate: navigation,
    });
  };
  const HandleSeriesSubArr = (data) => {
    const groupedData = data.reduce(
      (acc, item) => {
        if (item.series_number === null) {
          acc.nullSeries.push(item);
        } else {
          if (!acc.grouped[item.series_number]) {
            acc.grouped[item.series_number] = [];
          }
          acc.grouped[item.series_number].push(item);
        }
        return acc;
      },
      { grouped: {}, nullSeries: [] }
    );

    const groupedResult = Object.values(groupedData.grouped).map((group) => {
      const parent = group.find((item) => item?.step_type === "Create Series");
      const children = group.filter(
        (item) =>
          item?.step_type !== "Create Series" &&
          item?.series_number === parent?.series_number
      );

      if (parent) {
        return {
          ...parent,
          children,
        };
      }

      return group;
    });

    const nullSeriesResult = groupedData.nullSeries.map((item) => ({
      ...item,
      children: [],
    }));

    const result = [...groupedResult, ...nullSeriesResult];
    setIsStepdata(result);
  };

  const DiscardFun = async () => {
    if (isStepId) {
      let Res = await WFById(isStepId);
      if (Res?.success) {
        HandleSeriesSubArr(Res?.data?.workflow_steps);
        setInpObj(Res?.data?.workflow);
        setSelectedNotify(
          Res?.data?.workflow?.notification?.map((it) => ({
            label: it,
            value: it,
          }))
        );
        setSelectedOptions(
          Res?.data?.workflow?.team?.map((it) => ({
            label: isTeams
              ?.filter((i) => i?.id === it)
              ?.map((item) => item?.name),
            value: it,
          }))
        );
      }
    } else {
      setSelectedNotify([]);
      setInpObj({ name: "", category: "" });
      setIsStepdata([]);
      setSelectedOptions([]);
    }
    setIsDiscard(false);
  };

  const GetImgs = Images?.slice(0, 50).map((it) => {
    return {
      value: it?.filename,
      label: it?.filename,
      icon: it?.baseCode,
    };
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedNotify, setSelectedNotify] = useState([]);
  const [selectedStepNotify, setSelectedStepNotify] = useState([]);
  const [selectedStepNotify2, setSelectedStepNotify2] = useState([]);
  const [selectedStepNotify3, setSelectedStepNotify3] = useState([]);
  const teamsStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "gray",
        fontSize: "11px",
        fontFamily: "SofiaProBold",
      };
    },
    option: (base) => ({
      ...base,

      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
    control: (base) => ({
      ...base,
      "&:hover": {
        backgroundColor: "#d8d9db",
        cursor: "pointer",
        border: 0,
        // backgroundColor:var(--neutral-200, #d8d9db),
      },
      maxHeight: 500,
      minHeight: 32,
      boxShadow: "none",
      border: "1px solid gray",
      alignContent: "center",
    }),

    menu: (base) => ({
      ...base,
      width: "400px",
      backgroundColor: "white",
    }),
    singleValue: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
  };
  const GetTeams = isTeams?.map((it) => {
    return {
      value: it?.id,
      label: it?.name,
    };
  });
  const HandleSelctChange = (it) => {
    setSelectedOptions(it);
  };
  const GetNotify = isNotification?.map((it) => {
    return {
      value: it?.email,
      label: `${it?.first_name} ${it?.last_name}`,
    };
  });
  const GetNotifyStep = isNotification?.map((it) => {
    return {
      value: it?.email,
      label: it?.email,
    };
  });

  const GetStepSeries = isStepData
    ?.filter((item) => item?.step_type !== "Create Series")
    ?.map((it) => {
      return {
        value: it?.step_id,
        label: it?.name,
      };
    });

  const [isStepSeriesOptions, setIsSeriesOptionSeries] = useState([]);
  const HandleStepSeries = (it) => {
    setIsSeriesOptionSeries(it);
  };
  const HamdleNotify = (it) => {
    setSelectedNotify(it);
  };
  const HandleNotifyStep = (it) => {
    setSelectedStepNotify(it);
  };
  // const [isYesNoArr, setIsYesNoArr] = useState([]);
  const HandleNotifyStep2 = (it) => {
    setSelectedStepNotify2(it);
    let x = [];
    it?.map((item) => item && x.push(item?.label));
    isyesName === "NO" && setIsNoData(x);
    isyesName === "YES" && setIsYesData(x);
  };
  const HandleNotifyStep3 = (it) => {
    let x = [];
    it?.map((item) => item && x.push(item?.label));
    setFieldObj({ ...fieldObj, email: x });
    setSelectedStepNotify3(it);
  };
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  const Option = (props) => (
    <components.Option {...props}>
      <img src={props.data.icon} alt="logo" />
      {props.data.label}
    </components.Option>
  );
  const [selectedIcons, setSelectedIcons] = useState(Images[0]);
  const [selctedMaster, setSelectedMaster] = useState([]);
  let getmasterData = masterData.map((i) => {
    return {
      label: i?.name,
      value: i?.master_form_id,
    };
  });
  const HandleMaster = (it) => {
    setSelectedMaster(it);
  };
  const [iconType, setIconType] = useState("");
  const [iconBase, setIconBase] = useState("");
  const handleChange = async (value) => {
    setIconType(value?.label);
    let base64 = value?.icon.split(",");
    setIconBase(base64[1]);

    setSelectedIcons(value);
  };
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={selectedIcons.icon} alt="s-logo" />
      {children}
    </components.SingleValue>
  );
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#cfcbca",
    },
  });

  const [isNoteObj, setIsNoteObj] = useState({
    step_type: "",
    name: "",
    step_instructions: "",
    notification_message: "",
    master_form_field: "",
    notification_user: [],
    step_required: false,
    allow_note: false,
    image_required: false,
    allow_customer_to_view: false,
  });
  const HandleStepInputs = (e) => {
    const { name, value, type, checked } = e.target;
    setIsNoteObj({
      ...isNoteObj,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [stepInstrtype, setStepInstrtype] = useState("");
  const [stepInstruBase, setStepInstruBase] = useState("");
  const [imgPreview, setImgPreview] = useState("");
  const HandleFIleInput = (e) => {
    setStepInstrtype(e.target.files[0].type);
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setImgPreview(reader.result);
      let base = reader.result.split(",");
      setStepInstruBase(base[1]);
    };
    reader.readAsDataURL(file);
  };

  const [ComplteFormObj, setCompleteFormObj] = useState({
    complete_form: false,
    editable_form: false,
    form: [],
  });
  const HandleCompleteObj = (e) => {
    const { name, value, checked, type } = e.target;
    setCompleteFormObj({
      ...ComplteFormObj,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [AddInpObj, setAddInpObj] = useState({
    field_type: "",
    unit_type: "",
  });
  const [UniTypeRes, setUniTyperes] = useState([]);
  const HandleInpFieldObj = (e, typeUnit) => {
    e.preventDefault();
    if (typeUnit === "Field Type") {
      setUniTyperes(
        UniType_Data?.filter((unit) => unit.type === e.target.value)
      );
    }
    const { name, value } = e.target;
    setAddInpObj({
      ...AddInpObj,
      [name]: value,
    });
  };
  const HandleCloseStepModal = () => {
    setIsStep(false);
    setSelectedMaster([]);
    setAddInpObj({ field_type: "", unit_type: "" });
    setUniTyperes([]);
    setChoiceIdNo(0);
    setChoiceIdYes(0);
    setErrSteps([]);
  };
  const [yselect, setYSelect] = useState(0);
  const [nselect, setNSelect] = useState(0);
  const handleYnSelect = (e) => {
    isyesName === "NO" && setNSelect(e.target.value);
    isyesName === "YES" && setYSelect(e.target.value);
  };

  const [StepEid, setStepEid] = useState(0);
  const [isStepname, setIsStepName] = useState("");
  const [isNoData, setIsNoData] = useState([]);
  const [isYesData, setIsYesData] = useState([]);
  const HandleFieldYESNOSave = () => {
    // isyesName === "NO" && setIsNoData(isYesNoArr);
    // isyesName === "YES" && setIsYesData(isYesNoArr);
    setIsYesModal(false);
  };
  const [errSteps, setErrSteps] = useState([]);
  const HandleStepSave = async () => {
    let seriesData = isStepSeriesOptions?.map((it) => it?.value);
    let FilterOne = [];
    let ObjArr = Object.keys(isNoteObj);
    ObjArr.map((it) => {
      if (isNoteObj[it]?.length <= 0) {
        FilterOne.push(it);
      }
      if (selectedStepNotify.length > 0) {
        FilterOne = FilterOne.filter((i) => i !== "notification_user");
      }
      return FilterOne;
    });
    if (FilterOne?.length <= 0) {
      setErrSteps([]);
      let inpstepObj = {
        workflow_id: isStepId,
        step_id: StepEid,
        step_type: isNoteObj.step_type,
        step: {
          name: isNoteObj.name,
          icon_content: iconBase,
          icon_file_type: iconType,
          icon_name: iconType,
          notification_message: isNoteObj.notification_message,
          master_form_field: isNoteObj.master_form_field,
          master_form:
            selctedMaster?.length > 0
              ? selctedMaster?.map((it) => ({ master_form_id: it?.value }))
              : [],
          notification_user:
            selectedStepNotify.length > 0
              ? selectedStepNotify?.map((it) => it?.value)
              : selectedStepNotify[0]?.value,
          step_instructions: isNoteObj.step_instructions,
          attachment_content: stepInstruBase,
          attachment_file_type: stepInstrtype,
          attachment_name: stepInstrtype,
          "Complete Form":
            isNoteObj.step_type === "Complete Form"
              ? {
                complete_form: ComplteFormObj.complete_form,
                editable_form: ComplteFormObj.editable_form,
                form: ComplteFormObj.form,
              }
              : {},
          "Add Input Field":
            isNoteObj.step_type === "Add Input Field"
              ? {
                field_type: AddInpObj.field_type,
                unit_type: AddInpObj.unit_type,
              }
              : {},

          "Fill out table":
            isNoteObj.step_type === "Fill out Table"
              ? {
                attachment_content: isFileTableContent,
                attachment_file_type: isFileTableFileType,
                attachment_name: isFileTableName,
              }
              : {},
        },
        step_rules: {
          step_required: isNoteObj.step_required,
          allow_note: isNoteObj.allow_note,
          image_required: isNoteObj.image_required,
          allow_customer_to_view: isNoteObj.allow_customer_to_view,
        },
        series_step_ids: seriesData,
      };
      if (isNoteObj.step_type === "Create Drop Down") {
        if (DropDownArr?.length === 0) {
          alert("Please Add fields for Create Drop Down");
          return;
        }
      } else if (isNoteObj.step_type === "Yes/No Selection") {
        if (isYesData?.length === 0 || isNoData?.length === 0) {
          alert("Please Add Yes or No fields for Yes/No Selection");
          return;
        }
        
      }
      else if(isNoteObj?.step_type === "Create Series"){
        if( seriesData?.length <= 0)
          {
            alert("Please Add Steps");
            return;
          }
      }
      let Res = await WorkFlowAddStep(inpstepObj);
      if (Res?.success) {
        setImgPreview("");
        let newStepId = Res?.data?.workflow_step_id;
        if (isNoteObj.step_type === "Create Drop Down") {
          let x = DropDownArr.map((it) => ({
            workflow_id: isStepId,
            step_id: newStepId,
            choice_id: it?.choice_id ? it?.choice_id : 0,
            response: it?.name,
            contact_list: it?.email?.toString(),
            response_workflow_id: it?.dropSelect ? it?.dropSelect : 0,
            // workflow_id: isStepId,
            // step_id: newStepId,
            // choice_id: it?.choice_id ? it?.choice_id : 0,
            // response: it?.name,
            // contact_list: it?.email?.toString(),
            // response_workflow_id: it?.dropSelect ? it?.dropSelect : 0,
          }));
          x?.map(async (k) => k.choice_id === 0 && (await AddChoice(k)));
        }

        if (isNoteObj.step_type === "Yes/No Selection") {
          let Arr = [];
          let obj1 = {
            workflow_id: isStepId,
            step_id: newStepId,
            choice_id: choiceIdYes,
            response: "Yes",
            contact_list: isYesData?.toString(),
            response_workflow_id: +yselect,
          };
          let obj2 = {
            workflow_id: isStepId,
            step_id: newStepId,
            choice_id: choiceIdNo,
            response: "No",
            contact_list: isNoData?.toString(),
            response_workflow_id: +nselect,
          };
          Arr.push(obj1, obj2);
          Arr.map(async (j) => await AddChoice(j));
        }
        setSelectedStepNotify([]);
        setStepInstruBase("");
        setStepInstrtype("");
        setIsStep(false);
        UpdateWFById(isStepId);
      } else {
        setErrSteps(FilterOne);
      }
    } else {
      setErrSteps(FilterOne);
    }
  };
  const [choiceIdNo, setChoiceIdNo] = useState(0);
  const [choiceIdYes, setChoiceIdYes] = useState(0);

  const HandleEditStep = (item) => {
    let resseriesData =
      item?.children?.length > 0 ?
      item?.children?.map((it) => ({
        label: it?.name,
        value: it?.step_id,
      })) : []
    setIsSeriesOptionSeries(resseriesData);

    if (item?.series_number !== null) {
      setIsSeries("Series");
    } else {
      setIsSeries("Step");
    }

    //setUniTyperes
    setUniTyperes(
      UniType_Data?.filter(
        (unit) => unit.type === item?.step?.AddInputField?.field_type
      )
    );
    setChoiceIdYes(
      item?.step_rules?.step_choice[0]
        ? item?.step_rules?.step_choice[0]?.choice_id
        : 0
    );
    setChoiceIdNo(
      item?.step_rules?.step_choice[1]
        ? item?.step_rules?.step_choice[1]?.choice_id
        : 0
    );
    let x = item?.step_rules?.step_choice.map((i) => ({
      workflow_id: isStepId,
      step_id: i?.step_id,
      choice_id: i?.choice_id ? i?.choice_id : 0,
      name: i?.response,
      email: i?.contact_list,
      dropSelect: i?.response_workflow_id,
    }));
    setDropDownArr(x);
    // setSelectedStepNotify({
    //   label: item?.step_rules?.step_choice[0]?.contact_list,
    //   value: item?.step_rules?.step_choice[0]?.contact_list,
    // });
    let data =
      item?.notificaction_user?.length > 0
        ? item?.notificaction_user?.split(",").map((i) => ({
          label: i,
          value: i,
        }))
        : [];
    setSelectedStepNotify(data);
    setAddInpObj({
      field_type: item?.step?.AddInputField?.field_type,
      unit_type: item?.step?.AddInputField?.unit_type,
    });
    let newmaster = masterData
      ?.filter((i) =>
        item?.master_form?.some((j) => j?.master_form_id === i?.master_form_id)
      )
      ?.map((k) => {
        return {
          label: k?.name,
          value: k?.master_form_id,
        };
      });

    setSelectedMaster(newmaster);
    setSelectedStepNotify2(data);
    setYSelect(item?.step_rules?.step_choice[0]?.response_workflow_id);
    setNSelect(item?.step_rules?.step_choice[1]?.response_workflow_id);
    setSelectedStepNotify3(item?.notificaction_user);
    setIsYesData(item?.step_rules?.step_choice[1]?.contact_list);
    setIsNoData(item?.step_rules?.step_choice[0]?.contact_list);
    // setDropDownArr(x);
    setIconBase(item?.icon_content);
    setIsNoteObj({
      ...isNoteObj,
      name: item?.name,
      step_type: item?.step_type,
      notification_message: item?.notification_message,
      master_form_field: item?.master_form_field,
      step_instructions: item?.step_instructions,
      step_required: item?.step_rules?.step_required,
      allow_note: item?.step_rules?.allow_note,
      image_required:
        item?.step_rules?.allow_attachment === null
          ? false
          : item?.step_rules?.allow_attachment,
      allow_customer_to_view: item?.step_rules?.allow_customer_to_view,
      // icon_content: item?.icon_content,
    });
    setSelectedIcons({ label: "", value: item?.icon_content });
    setCompleteFormObj({
      ...ComplteFormObj,
      complete_form: item?.step?.CompleteForm?.complete_form,
      editable_form: item?.step?.CompleteForm?.editable_form,
      form: item?.step?.CompleteForm?.form,
    });
    setIsStep(true);
    // setIsNoteObj(item);
    setStepEid(item?.step_id);
    setIsStepName(item?.name);
  };
  const [isDelModal, setIsDelModal] = useState(false);
  const [isDelId, setIsDelId] = useState(0);
  const [isDelWf, setIsDelWf] = useState(0);
  const [isStepDetails, setIsStepDetails] = useState({});
  const HandleStepDelete = (item) => {
    setIsDelWf(item?.workflow_id);
    setIsStepDetails(item);
    setIsDelId(item?.step_id);
    setIsDelModal(true);
  };
  const DeleteStep = async () => {
    let res = await DeleteStepAsync(isDelId, isDelWf);
    if (res?.success) {
      alert(res?.reason);
      setIsDelModal(false);
      UpdateWFById(isStepId);
    }
  };
  const [DropDownArr, setDropDownArr] = useState([]);

  const [fieldObj, setFieldObj] = useState({
    name: "",
    email: [],
    dropSelect: 0,
  });
  const handleFieldInputs = (e) => {
    const { name, value } = e.target;
    setFieldObj({
      ...fieldObj,
      [name]: value,
    });
  };
  const HandleFieldSave = async () => {
    if (isFtag === "Add") {
      setDropDownArr([...DropDownArr, fieldObj]);
      setIsField(false);
      setFieldObj({});
      setSelectedStepNotify3([]);
    } else {
      let newObj = DropDownArr.filter((key, val) => val !== isKey);
      setDropDownArr([...newObj, fieldObj]);
      setIsField(false);
      setFieldObj({});
      setSelectedStepNotify3([]);
    }

    if (isChoiceObj?.choice_id !== 0) {
      let objChoice = {
        workflow_id: isChoiceObj?.workflow_id,
        step_id: isChoiceObj?.step_id,
        choice_id: isChoiceObj?.choice_id,
        response: fieldObj?.name,
        contact_list: fieldObj?.email?.toString(),
        response_workflow_id: fieldObj?.dropSelect,
      };
      let Res = await AddChoice(objChoice);

      if (Res?.success) {
        setIsChoiceObj({ choice_id: 0 });
      }
    }
  };
  const [isKey, setIsKey] = useState("");
  const [isChoiceObj, setIsChoiceObj] = useState({});
  const HandleEditField = (val, it, key) => {
    setIsChoiceObj(val);
    setIsKey(key);
    let DropData =
      typeof val?.email === "string"
        ? val?.email?.split(",").map((i) => ({ label: i, value: i }))
        : val?.email?.map((i) => ({ label: i, value: i }));
    setSelectedStepNotify3(DropData);
    setIsFtag(it);
    setIsField(true);
    setFieldObj(val);
  };
  const [isChoicedelModal, setIsChoiceDelModal] = useState(false);
  const [isChoiceVal, setIsChoiceVal] = useState({});
  const HandleDel = (val) => {
    setIsChoiceVal(val);
    setIsChoiceDelModal(true);
  };
  const DeleteChoice = async () => {
    let newObj = DropDownArr.filter((key) => key !== isChoiceVal);
    setDropDownArr(newObj);
    let Res = await ChoiceDelete(
      isChoiceVal?.choice_id,
      isChoiceVal?.step_id,
      isChoiceVal?.workflow_id
    );
    if (Res?.success) {
      alert(Res?.reason);
    }
    setIsChoiceDelModal(false);
  };
  const [isField, setIsField] = useState(false);
  const [isFtag, setIsFtag] = useState("");
  const HandleFieldModal = (it) => {
    setIsFtag(it);
    setIsField(true);
    setFieldObj({});
    setSelectedStepNotify3([]);
  };
  const HandleInputFile = () => {
    document.getElementById("Add-Input").click();
  };

  const [isFileTableContent, setIsFileTableContent] = useState("");
  const [isFileTableFileType, setIsFileTableFileType] = useState("");
  const [isFileTableName, setIsFIleTableName] = useState("");
  const handleFileTable = (e) => {
    var file = e.target.files[0];
    setIsFileTableFileType(file.type);
    setIsFIleTableName(file.name);
    var reader = new FileReader();
    reader.onloadend = function () {
      setIsFileTableContent(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [isYesWfFetch, setIsYesWfFetch] = useState([]);
  useEffect(() => {
    async function FetchData() {
      let Res = await WorkflowFetch();
      setIsYesWfFetch(Res?.data);
    }
    FetchData();
  }, []);
  const [isYesModal, setIsYesModal] = useState(false);
  const [isyesName, setIsyesName] = useState("");

  const HadnleYesNoModal = (val, item) => {
    setYSelect(yselect);
    setNSelect(nselect);
    if (typeof item === "string") {
      setSelectedStepNotify2(
        item?.split(",").map((i) => ({
          label: i,
          value: i,
        }))
      );
    } else {
      setSelectedStepNotify2(
        item?.map((i) => ({
          label: i,
          value: i,
        }))
      );
    }

    setIsYesModal(true);
    setIsyesName(val);
    if (val === "YES") {
      // setSelectedStepNotify3(
      //   isYesData?.map((i) => ({
      //     label: i,
      //     value: i,
      //   }))
      // );
    }
    if (val === "NO") {
      setSelectedStepNotify3(isNoData);
    }
  };
  const [isAttachList, setIsAttachList] = useState([]);
  const [isAttachModal, setIsAttachModal] = useState(false);
  const HandleImageView = async (it, val) => {
    let techid = JSON.parse(localStorage.getItem("techpin"));
    let Res = await GetAttchMentByIDMaintanance(
      it?.id,
      val?.step_id,
      val?.workflow_id,
      0,
      0,
      0,
      techid,
      1001
    );
    if (Res?.success) {
      setIsAttachModal(true);
      setIsAttachList(Res?.data[0]);
    }
  };
  const newArray = [
    {
      id: "1",
      name: "Pre-Signature Required for Technician?",
    },
    {
      id: "1",
      name: "Pre-Signature Required for Customer?",
    },
    {
      id: "1",
      name: "Pre-Signature Required for Technician?",
    },
    {
      id: "2",
      name: "Flatten PDF Form",
    },
    {
      id: "1",
      name: "Post-Signature Required for Customer?",
    },
    {
      id: "3",
      name: "Instruction Required for every step?",
    },
    {
      id: "4",
      name: "Require Work Order Type and Call Type?",
    },
    {
      id: "2",
      name: "Cannot Combine PDF Forms",
    },
  ];
  const CloseYesNoModal = () => {
    setIsYesModal(false);
    // setYSelect(0);
    // setNSelect(0);
  };
  return (
    <>
      <div className="pagescroll2">
        <div className="head-stick">
          <CommonHeader />
          <div className="line-align-bet  mt-2 w-100 top_header" style={{ paddingBottom: "10px" }}>
            <div className="Create-workflow-text mx-2">
              {location?.state?.item === "file"
                ? "Duplicate Workflow"
                : location?.state?.props !== undefined &&
                  location?.state?.to === "CreateWorkflow"
                  ? "Edit Workflow"
                  : WORKFLOW_VALUES.CREATE_WORKFLOW}{" "}
              &nbsp;
              {location?.state?.props?.name ? location?.state?.props?.name : ""}
            </div>
            {isSave && (
              <div className="save-wf">
                <span>Successfully Saved</span>
              </div>
            )}
            <div className="d-flex mx-2">
              <div
                className="discard-btn-styles mx-1"
                onClick={() => NavBack()}
              >
                {WORKFLOW_VALUES.DISCARD}
                <img src={delW} alt="close" width={12} />
              </div>
              <div
                className="discard-btn-styles mx-1"
                onClick={() => HandleSave()}
              >
                {WORKFLOW_VALUES.SAVE}
                <img src={Save} alt="close" width={10} />
              </div>
            </div>
          </div>
        </div>
        <div className="page_main_box">
          <Row className=" px-2">
            <Col className=" mt-2 " lg={3}>
              <div className="span-titles"> {WORKFLOW_VALUES.WF_NAME}</div>
              <input
                placeholder="input a Workflow Name."
                name="name"
                type="text"
                value={inpObj?.name}
                className="workflow-inputs w-100"
                onChange={(e) => HandleInputChanges(e)}
              />
              <div>
                {inpObj?.name?.length <= 0 &&
                  isErrFields.map(
                    (it) =>
                      it === "name" && (
                        <span className="err-warnings-wf">Please Enter Workflow Name</span>
                      )
                  )}
              </div>
            </Col>
            <Col className="mt-2 " lg={3}>
              <div className="span-titles">Workflow Description:</div>
              <input
                placeholder="input a Workflow Note."
                name="description"
                type="text"
                value={inpObj?.description}
                className="workflow-inputs w-100"
                onChange={(e) => HandleInputChanges(e)}
              />
              {inpObj?.description?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "description" && (
                      <span className="err-warnings-wf">Please Enter Workflow Description</span>
                    )
                )}
            </Col>
            <Col className="mt-2 " lg={4}>
              <div className="span-titles">{WORKFLOW_VALUES.WF_CATEGORY}</div>
              <select
                className="workflow-inputs-select w-100"
                onChange={(e) => HandleInputChanges(e)}
                value={inpObj?.category}
                name="category"
              >
                <option selected>input Workflow Category.</option>
                {Workflow_Table_Category.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div>
                {/* {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "category" && (
                      <span className="err-warnings-wf">Please Select Workflow Category</span>
                    )
                )} */}
              </div>
            </Col>
          </Row>

          <Row className="px-2">
            <Col lg={3} className=" mt-2">
              <div className="span-titles"> {WORKFLOW_VALUES.WF_TEAMS}</div>
              <Select
                className="w-100"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={teamsStyles}
                theme={theme}
                onChange={(options) => {
                  HandleSelctChange(options);
                }}
                value={selectedOptions}
                options={GetTeams}
                isMulti
                placeholder={"input Workflow team."}
                components={{
                  Option: InputOption,
                }}
              />
              {/* {selectedOptions?.length <= 0 && isErrFields?.length > 0 && (
              <span className="err-warnings-wf">Please Select Workflow Teams</span>
            )} */}
            </Col>
            {/* <Col className="mt-2 " lg={3}>
            <div className="span-titles">PDF Selection:</div>
            <select
              className="workflow-inputs-select w-100"
              onChange={(e) => HandleInputChanges(e)}
              value={inpObj?.category}
              name="category"
            >
              <option selected>if there is pdf attach here?</option>
              <option value={10}>PDF 1</option>
              <option value={20}>PDF 2</option>
              <option value={30}>PDF 3</option>
            </select>
            <div>
              {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "category" && (
                      <span className="err-warnings-wf">Please Enter {it}</span>
                    )
                )}
            </div>
          </Col>
          <Col lg={4} className="mt-2">
            <div className="span-titles">Content Selection:</div>
            <select
              className="workflow-inputs-select w-100"
              onChange={(e) => HandleInputChanges(e)}
              value={inpObj?.category}
              name="category"
            >
              <option selected>if there is pdf attach here?</option>
              <option value={10}>PDF 1</option>
              <option value={20}>PDF 2</option>
              <option value={30}>PDF 3</option>
            </select>
            <div>
              {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "category" && (
                      <span className="err-warnings-wf">Please Enter {it}</span>
                    )
                )}
            </div>
          </Col> */}
            <Col className="mt-2 " lg={3}>
              <div className="span-titles">Automation Rule:</div>
              <select
                className="workflow-inputs-select w-100"
                onChange={(e) => HandleInputChanges(e)}
                value={inpObj?.AutoRule}
                name="AutoRule"
              >
                <option selected>Select Rule</option>
                <option value={10}>Initial</option>
                <option value={20}>General</option>
                <option value={30}>Completion</option>
              </select>
              <div>
                {/* {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "AutoRule" && (
                      <span className="err-warnings-wf">Please Enter {it}</span>
                    )
                )} */}
              </div>
            </Col>
            <Col className=" mt-2 " lg={3}>
              <div className="span-titles">Automation(s) Attached:</div>
              <select
                className="workflow-inputs-select w-100"
                onChange={(e) => HandleInputChanges(e)}
                value={inpObj?.AutoAttached}
                name="AutoAttached"
              >
                <option selected>Attach Automation(S)</option>
                <option value={10}>Initial</option>
                <option value={20}>General</option>
                <option value={30}>Completion</option>
              </select>
              <div>
                {/* {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "AutoAttached" && (
                      <span className="err-warnings-wf">Please Enter {it}</span>
                    )
                )} */}
              </div>
            </Col>
          </Row>
          {/* <Row className=" px-2">
          <Col className=" mt-2 " lg={3}>
            <div className="span-titles">Automation(s) Attached:</div>
            <select
              className="workflow-inputs-select w-100"
              onChange={(e) => HandleInputChanges(e)}
              value={inpObj?.category}
              name="category"
            >
              <option selected>What Category is this workflow?</option>
              <option value={10}>Initial</option>
              <option value={20}>General</option>
              <option value={30}>Completion</option>
            </select>
            <div>
              {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "category" && (
                      <span className="err-warnings-wf">Please Enter {it}</span>
                    )
                )}
            </div>
          </Col>
          <Col className="mt-2 " lg={3}>
            <div className="span-titles">Automation Rule:</div>
            <select
              className="workflow-inputs-select w-100"
              onChange={(e) => HandleInputChanges(e)}
              value={inpObj?.category}
              name="category"
            >
              <option selected>What Category is this workflow?</option>
              <option value={10}>Initial</option>
              <option value={20}>General</option>
              <option value={30}>Completion</option>
            </select>
            <div>
              {inpObj?.category?.length <= 0 &&
                isErrFields.map(
                  (it) =>
                    it === "category" && (
                      <span className="err-warnings-wf">Please Enter {it}</span>
                    )
                )}
            </div>
          </Col>
          <Col className="mt-2 " lg={4}></Col>
        </Row> */}
          <div className="mt-2 px-2 span-titles">Workflow Rules: </div>
          <Row className="w-100 px-2">
            {newArray?.map((i) => i?.name === "Cannot Combine PDF Forms" && (
              <Col lg={3} className="mt-2">
                <div className="sign-btns-step px-2 py-1 d-flex">
                  <div className="gap-2 line-align-bet  w-100">
                    <div>
                      {i?.id === "2" ? (
                        <img
                          src={FormLight}
                          alt="FormLight"
                          width={16}
                          className=""
                        />
                      ) : i?.id === "3" ? (
                        <img
                          src={threeDots}
                          alt="threeDots"
                          width={16}
                          className=""
                        />
                      ) : i?.id === "4" ? (
                        <img
                          src={Circlert}
                          alt="Circlert"
                          width={18}
                          className=""
                        />
                      ) : (
                        <img
                          src={notepadIcon}
                          alt="notepadIcon"
                          width={16}
                          className=""
                        />
                      )}

                      <span className="sign-name-wofl mx-1">{i?.name}</span>
                    </div>
                    <div className="align-rt" style={{ alignItems: "center" }}>
                      <input type="checkbox" className="WoFldsCheckbox " />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className=" px-2">
            <Col lg={3} className=" mt-2">
              <div className="span-titles">{WORKFLOW_VALUES.WF_NOTIFY}</div>
              <Select
                className="w-100"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={teamsStyles}
                theme={theme}
                onChange={(options) => {
                  HamdleNotify(options);
                }}
                value={selectedNotify}
                options={GetNotify}
                isMulti
                placeholder={"input notification email(s)."}
                components={{
                  Option: InputOption,
                }}
              />
              {/* {selectedNotify?.length <= 0 && isErrFields.length > 0 && (
              <span className="err-warnings-wf">Please Select Notification Email </span>
            )} */}
            </Col>
            <Col className="mt-2 " lg={3}>
              <div className="sign-btns-step w-100 p-2 mt-3">
                <span className="sign-name-wofl mx-1">
                  {" "}
                  input notification messsage
                </span>
              </div>
            </Col>
            <Col className="mt-2 " lg={3}>
              <div className="sign-btns-step w-100 p-1 mt-3">
                <div className="gap-2 line-align-bet  w-100">
                  <div>
                    <img
                      src={Illustration}
                      alt="Illustration"
                      width={18}
                      className=""
                    />

                    <span className="sign-name-wofl mx-1">
                      Attach PDF(s) Upon Completion?
                    </span>
                  </div>
                  <div className="align-rt" style={{ alignItems: "center" }}>
                    <input type="checkbox" className="WoFldsCheckbox " />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* <div className="mx-2 mt-2">
          <div className="span-titles">{WORKFLOW_VALUES.WF_CATEGORY}</div>
          <select
            className="w-50 workflow-inputs-select"
            onChange={(e) => HandleInputChanges(e)}
            value={inpObj?.category}
            name="category"
          >
            <option selected>What Category is this workflow?</option>

            <option value={10}>Initial</option>
            <option value={20}>General</option>
            <option value={30}>Completion</option>
          </select>
          <div>
            {inpObj?.category?.length <= 0 &&
              isErrFields.map(
                (it) =>
                  it === "category" && (
                    <span className="err-warnings-wf">Plese Enter {it}</span>
                  )
              )}
          </div>
        </div> */}
          <div className="span-titles mx-2 mt-2">
            {isStepId === 0 ? "Step Rules:" : "Workflow Steps:"}
          </div>

          <div className="w-100 p-2 cust-sign">
            <div className="">
              <input
                type="search"
                style={{ outline: "none" }}
                className="add-step-workflow"
                placeholder="Search added steps"
              />
            </div>
            <div
              className=""
              style={{
                pointerEvents: isStepId === 0 ? "none" : "",
                opacity: isStepId === 0 ? "0.4" : "1",
              }}
            >
              {" "}
              <button
                className="add-step-workflow"
                onClick={() => HandleAddStep("Step")}
              >
                {WORKFLOW_VALUES.ADD_STEP}
                <img
                  src={add}
                  alt="add"
                  width={10}
                  style={{ position: "relative", bottom: "1px", left: "5px" }}
                />
              </button>
            </div>

            <div
              className=""
              style={{
                pointerEvents: isStepId === 0 ? "none" : "",
                opacity: isStepId === 0 ? "0.4" : "1",
              }}
            >
              {" "}
              <button
                className="add-step-workflow"
                onClick={() => HandleAddStep("Series")}
              >
                Add Series
                <img
                  src={add}
                  alt="add"
                  width={10}
                  style={{ position: "relative", bottom: "1px", left: "5px" }}
                />
              </button>
            </div>
            <div className="" style={{ pointerEvents: "none", opacity: "0.4" }}>
              <button
                className="add-step-workflow"
                onClick={() => HandleToggle("Workflow")}
              >
                {WORKFLOW_VALUES.ADD_STEP_TEMPLATE}
                <img
                  src={add}
                  alt="add"
                  width={10}
                  style={{ position: "relative", bottom: "1px", left: "5px" }}
                />
              </button>
              <div className="w-100">
                {isToggle && isVal === "Workflow" && DropdownForm}
              </div>
            </div>
          </div>

          <div className="p-1">
            {isStepId !== 0 && isStepData?.length > 0 ? (
              <div
                className="line-align-bet w-100"
                style={{ whiteSpace: "nowrap", overflow: "auto" }}
              >
                <div className="w-50 line-align-bet">
                  <div className="step-list-one mx-2">Step Name</div>
                  <div className="step-list-one mx-2">Type</div>
                  <div className="step-list-one mx-5">Content Included</div>
                </div>
                <div className="w-50 line-align-bet">
                  <div className="step-list-one mx-4">Required</div>
                  {/* <div className="step-list-one">Series?</div> */}
                  <div className="step-list-one">Customer Viewable</div>
                  <div className="step-list-one mx-2">Edit</div>
                  <div className="step-list-one mx-3">Delete</div>
                </div>
              </div>
            ) : (
              <div className="alert-creste-step">
                Please add steps in order to create the workflow
              </div>
            )}
            {isStepId !== 0 && (
              <div
                className="w-100"
                style={{
                  border: "1px solid gray",
                  borderBottom: "none",
                  borderRadius: "15px",
                }}
              >
                {location?.state && isStepData?.length <= 0 ? (
                  <div className="err-steps mt-2">There is no Data Found</div>
                ) : (
                  isStepData?.map((it) => (
                    <>
                      <div
                        className="line-align-bet mt-1 w-100 p-2"
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <div className="w-50 line-align-bet">
                          <div className="step-list-one-val">{it?.name}</div>
                          <div className="step-list-one-val">{it?.step_type}</div>
                          <div
                            className="step-list-one-val mx-5 d-flex gap-2"
                            style={{
                              width: "100px",
                            }}
                          >
                            {it?.attachment?.map((item) => (
                              <div>
                                <img
                                  src={item?.thumbnail}
                                  alt="thumbnail"
                                  width={20}
                                  onClick={() => HandleImageView(item, it)}
                                />
                              </div>
                            ))}{" "}
                            {/* {it?.step_name} */}
                          </div>
                        </div>
                        <div className="w-50  line-align-bet">
                          <div className="step-list-one-val mx-4">
                            <input
                              type="checkbox"
                              checked={
                                it?.step_rules?.step_required ? true : false
                              }
                            />
                          </div>
                          {/* <div className="step-list-one-val">
                      <input type="checkbox" />
                    </div> */}
                          <div className="step-list-one-val">
                            <input
                              type="checkbox"
                              checked={
                                it?.step_rules?.allow_customer_to_view
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div
                            className="step-list-one-val"
                            style={{ position: "relative", left: "20px" }}
                            onClick={() => HandleEditStep(it)}
                          >
                            <MdOutlineEdit />
                          </div>
                          <div
                            className="step-list-one-val mx-2"
                            onClick={() => HandleStepDelete(it)}
                          >
                            <img src={del} alt="delete" />
                          </div>
                        </div>
                      </div>
                      {it?.children?.length > 0 && (
                        <div
                          className="mt-1"
                          style={{
                            borderRadius: "10px ",
                            border: "2px solid gray",
                            borderBottom: "none",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        >
                          {it?.children?.map((it) => (
                            <div
                              style={{
                                borderBottom: "1px solid gray",
                              }}
                            >
                              <div
                                className="line-align-bet mt-1 w-100"
                                style={{
                                  padding: "10px 20px 10px 20px",
                                }}
                              >
                                <div className="w-50 line-align-bet">
                                  <div className="step-list-one-val">
                                    {it?.name}
                                  </div>
                                  <div
                                    className="step-list-one-val mx-5 d-flex gap-2"
                                    style={{
                                      width: "100px",
                                    }}
                                  >
                                    {it?.attachment.map((item) => (
                                      <div>
                                        <img
                                          src={item?.thumbnail}
                                          alt="thumbnail"
                                          width={20}
                                          onClick={() =>
                                            HandleImageView(item, it)
                                          }
                                        />
                                      </div>
                                    ))}{" "}
                                    {/* {it?.step_name} */}
                                  </div>
                                </div>
                                <div className="w-50  line-align-bet">
                                  <div className="step-list-one-val mx-4">
                                    <input
                                      type="checkbox"
                                      checked={
                                        it?.step_rules?.step_required
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  {/* <div className="step-list-one-val">
                        <input type="checkbox" />
                      </div> */}
                                  <div className="step-list-one-val">
                                    <input
                                      type="checkbox"
                                      checked={
                                        it?.step_rules?.allow_customer_to_view
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  <div
                                    className="step-list-one-val"
                                    style={{ position: "relative", left: "20px" }}
                                    onClick={() => HandleEditStep(it)}
                                  >
                                    <MdOutlineEdit />
                                  </div>
                                  <div
                                    className="step-list-one-val mx-2"
                                    onClick={() => HandleStepDelete(it)}
                                  >
                                    <img src={del} alt="delete" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
        <Modal
          show={isStep}
          size="lg"
          centered
          onHide={() => HandleCloseStepModal()}
        >
          <Modal.Header className="modal-bg-color modal-header-height px-2">
            <div className="header-sub-title">
              {WfName}-
              {isSeries === "Step"
                ? StepEid
                  ? isStepname
                  : `${WORKFLOW_VALUES.CREATE_STEP}`
                : "Create Series"}
            </div>
            <img
              src={Close}
              alt="Close"
              className="modal-header-icon"
              onClick={() => HandleCloseStepModal()}
            />
          </Modal.Header>
          <Modal.Body style={{ height: "400px", overflow: "auto" }}>
            <div className="span-titles">{WORKFLOW_VALUES.STEP_DISC}</div>
            <div style={{ pointerEvents: isSeries !== "Step" && "none" }}>
              <select
                className="w-25 workflow-inputs-select32"
                style={{ height: "28px" }}
                name="step_type"
                value={isNoteObj.step_type}
                onChange={(e) => HandleStepInputs(e)}
              >
                <option selected>Select Step Action</option>
                <option>Attach Content</option>
                <option>Create Series</option>
                <option>Complete Form</option> <option>Add Input Field</option>{" "}
                <option>Yes/No Selection</option>{" "}
                <option>Create Drop Down</option>{" "}
                <option>Fill out Table</option>{" "}
                <option>Create Equipment</option>
              </select>
            </div>
            <span>
              {errSteps?.map(
                (i) =>
                  i === "step_type" &&
                  isNoteObj.step_type?.length <= 0 && (
                    <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                  )
              )}
            </span>
            <div className="mt-2">
              <div className="span-titles">{WORKFLOW_VALUES.STEP_NAME}</div>
              <input
                placeholder="What is the Name of the Step?"
                className="w-100 workflow-inputs"
                type="text"
                name="name"
                value={isNoteObj.name}
                onChange={(e) => HandleStepInputs(e)}
              />
              <span>
                {errSteps?.map(
                  (i) =>
                    i === "name" &&
                    isNoteObj?.name?.length <= 0 && (
                      <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                    )
                )}
              </span>
              <div className="w-100 mt-2">
                <Select
                  value={selectedIcons}
                  defaultValue={iconType}
                  options={GetImgs}
                  placeholder={"Select Step Icon"}
                  onChange={handleChange}
                  styles={teamsStyles}
                  theme={theme}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
              </div>
            </div>
            {isNoteObj.step_type === "Complete Form" && (
              <div className="mt-2">
                <div className="w-100 mt-2">
                  <Select
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    styles={teamsStyles}
                    theme={theme}
                    onChange={(options) => {
                      HandleMaster(options);
                    }}
                    value={selctedMaster}
                    options={getmasterData}
                    isMulti
                    placeholder={"Select Form"}
                    components={{
                      Option: InputOption,
                    }}
                  />
                </div>
              </div>
            )}
            {/* MasterForm */}
            <div className="mt-2">
              <div className="span-titles">
                {WORKFLOW_VALUES.NOTIFY_CONTENT}
              </div>

              <>
                <input
                  placeholder="Input Notification Message"
                  className="w-100 workflow-inputs mt-1"
                  name="notification_message"
                  type="text"
                  value={isNoteObj.notification_message}
                  onChange={(e) => HandleStepInputs(e)}
                />
                <span>
                  {errSteps?.map(
                    (i) =>
                      i === "notification_message" &&
                      isNoteObj.notification_message?.length <= 0 && (
                        <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                      )
                  )}
                </span>
                <div className="mt-2">
                  <div className="span-titles">Master Form Field Name</div>
                  <input
                    placeholder="Master Form Field Name"
                    className="w-100 workflow-inputs mt-1"
                    name="master_form_field"
                    type="text"
                    value={isNoteObj.master_form_field}
                    onChange={(e) => HandleStepInputs(e)}
                  />
                  <span>
                    {errSteps?.map(
                      (i) =>
                        i === "master_form_field" &&
                        isNoteObj.master_form_field?.length <= 0 && (
                          <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                        )
                    )}
                  </span>
                </div>
                <div className="mt-2">
                  <Select
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    styles={teamsStyles}
                    theme={theme}
                    onChange={(options) => {
                      HandleNotifyStep(options);
                    }}
                    value={selectedStepNotify}
                    options={GetNotifyStep}
                    isMulti
                    placeholder={"Select Notification"}
                    components={{
                      Option: InputOption,
                    }}
                  />
                </div>
                <span>
                  {errSteps?.map(
                    (i) =>
                      i === "notification_user" &&
                      selectedStepNotify.length <= 0 && (
                        <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                      )
                  )}
                </span>
              </>
            </div>

            {isNoteObj.step_type === "Yes/No Selection" && (
              <div>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="span-titles">FieldName</div>
                  <div className="span-titles">Notification User</div>{" "}
                  <div className="span-titles">Add Workflow</div>
                </div>
                <div className="card">
                  {/* {YesNoArr?.map((items) => ( */}
                  <div className="p-1 yes-no-borders">
                    <span className="yes-no-spans">{"YES"}</span>
                    <span className="yes-no-emails">{isYesData}</span>
                    <div className="d-flex gap-2">
                      <div>
                        <input type="checkbox" checked={yselect > 0 && true} />
                      </div>
                      <div
                        onClick={() => HadnleYesNoModal("YES", isYesData)}
                        style={{ cursor: "pointer" }}
                      >
                        <MdOutlineEdit size={12} className="mb-1" />
                      </div>
                    </div>
                  </div>
                  <div className="p-1 yes-no-borders">
                    <span className="yes-no-spans">{"NO"}</span>
                    <span className="yes-no-emails">{isNoData}</span>
                    <div className="d-flex gap-2">
                      <div>
                        <input type="checkbox" checked={nselect > 0 && true} />
                      </div>
                      <div
                        onClick={() => HadnleYesNoModal("NO", isNoData)}
                        style={{ cursor: "pointer" }}
                      >
                        <MdOutlineEdit size={12} className="mb-1" />
                      </div>
                    </div>
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {isNoteObj.step_type === "Complete Form" && (
              <div className="mt-2">
                <div className="span-titles">Action Rules</div>
                <div className="d-flex gap-2">
                  <input
                    type="checkbox"
                    name="complete_form"
                    value={ComplteFormObj.complete_form}
                    onChange={(e) => HandleCompleteObj(e)}
                  />{" "}
                  <span className="span-check-vals">complete_form</span>
                </div>
                <div className="d-flex gap-2 mt-1">
                  <input
                    type="checkbox"
                    name="editable_form"
                    value={ComplteFormObj.editable_form}
                    onChange={(e) => HandleCompleteObj(e)}
                  />{" "}
                  <span className="span-check-vals">
                    editable_form online only
                  </span>
                </div>
                <select
                  className="workflow-inputs-select w-25 mt-2"
                  name="form"
                  value={ComplteFormObj?.form}
                  onChange={(e) => HandleCompleteObj(e)}
                >
                  <option selected>Input Form</option>
                  <option value="Form1">Form1</option>
                  <option value="Form2">Form2</option>
                  <option value="Form3">Form3</option>
                </select>
              </div>
            )}
            {isNoteObj.step_type === "Add Input Field" && (
              <div className="mt-2">
                <div className="span-titles">Field Type:</div>
                <select
                  className="workflow-inputs-select w-25"
                  name="field_type"
                  value={AddInpObj.field_type}
                  onChange={(e) => HandleInpFieldObj(e, "Field Type")}
                // Clear the value if clicking the same option again
                >
                  <option selected>Select Field Type</option>
                  {UniType_Data?.map((i) => (
                    <option value={i?.type}>{i?.type}</option>
                  ))}
                </select>
                <div className="span-titles">Unit Type:</div>
                <select
                  className="workflow-inputs-select w-25"
                  name="unit_type"
                  value={AddInpObj.unit_type}
                  onChange={(e) => HandleInpFieldObj(e, "Unit Type")}
                >
                  <option selected>Select Unit Type</option>
                  {UniTypeRes[0]?.Values?.length > 0 ? (
                    UniTypeRes[0]?.Values?.map((i) => (
                      <option value={i?.name}>{i?.name}</option>
                    ))
                  ) : (
                    <option value={AddInpObj?.unit_type}>
                      {AddInpObj?.unit_type !== null &&
                        AddInpObj?.unit_type.length > 0
                        ? AddInpObj?.unit_type
                        : "Select Unit Type"}
                    </option>
                  )}
                  {/* <option value="type1">type1</option>
                  <option value="type2">type2</option>
                  <option value="type3">type3</option>
                  <option value="type4">type4</option>
                  <option value="type5"> type5</option>
                  <option value="type6">type6</option>
                  <option value="type7">type7</option>
                  <option value="type8">type8</option>
                  <option value="type9">type9</option> */}
                </select>
              </div>
            )}
            {isNoteObj.step_type === "Fill out Table" && (
              <div className="mt-2">
                <div className="span-titles">Action Rules:</div>

                <div className="mt-1">
                  <div
                    className="teams-drop"
                    style={{ width: "100px" }}
                    onClick={() => HandleInputFile()}
                  >
                    <span className="span-dropdown"> Input Table +</span>
                  </div>
                </div>
                <input
                  onChange={(e) => handleFileTable(e)}
                  id="Add-Input"
                  type="file"
                  style={{ display: "none" }}
                />
                {isFileTableContent && (
                  <div>
                    <embed src={isFileTableContent} />
                  </div>
                )}
              </div>
            )}

            {isNoteObj.step_type === "Create Drop Down" && (
              <div className="mt-2 ">
                {DropDownArr?.length > 0 && (
                  <div
                    className="w-100 d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="d-flex" style={{ width: "80%" }}>
                      <div className="span-titles" style={{ width: "40%" }}>
                        FieldName
                      </div>
                      <div className="span-titles " style={{ width: "40%" }}>
                        Notification User
                      </div>{" "}
                    </div>
                    <div
                      className="d-flex"
                      style={{ width: "20%", overflow: "auto" }}
                    >
                      <div className="span-titles">Add Workflow</div>
                      <div className="span-titles mx-3">Edit</div>
                      <div className="span-titles mx-1">Delete</div>
                    </div>
                  </div>
                )}
                {DropDownArr.length > 0 && (
                  <div
                    className="card"
                    style={{ maxHeight: "200px", overflow: "auto" }}
                  >
                    {DropDownArr.map((it, index) => (
                      <div
                        key={index}
                        className="d-flex w-100 p-2"
                        style={{
                          justifyContent: "space-between",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        <div className="d-flex" style={{ width: "80%" }}>
                          <div
                            style={{ width: "40%", whiteSpace: "nowrap" }}
                            className="user-info"
                          >
                            {it.name}
                          </div>
                          <div className="" style={{ width: "40%" }}>
                            {Array.isArray(it?.email) === true ? (
                              it?.email?.map((i) => (
                                <div
                                  className="user-info2 mt-1"
                                  style={{
                                    width: "max-content",
                                  }}
                                >
                                  {i}
                                </div>
                              ))
                            ) : (
                              <div style={{ width: "40%" }}>
                                {typeof it?.email === "string" &&
                                  it?.email?.split(",")?.map((i) => (
                                    <div
                                      className="user-info2 mt-1"
                                      style={{
                                        width: "max-content",
                                      }}
                                    >
                                      {i}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="d-flex"
                          style={{
                            width: "20%",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            className="mx-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <input
                              type="checkbox"
                              checked={
                                it?.dropSelect && it?.dropSelect !== 0
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div
                            className="mx-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => HandleEditField(it, "Edit", index)}
                          >
                            <MdOutlineEdit style={{ alignSelf: "center" }} />
                          </div>
                          <div
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => HandleDel(it)}
                          >
                            <img
                              src={del}
                              alt="delete"
                              style={{ alignSelf: "center" }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="mt-1">
                  <div
                    className="teams-drop"
                    style={{ width: "100px" }}
                    onClick={() => HandleFieldModal("Add")}
                  >
                    <span className="span-dropdown">+ Add Field</span>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-2">
              <div className="span-titles">
                {WORKFLOW_VALUES.STEP_INSTRUCTION}
              </div>
              <input
                placeholder="Input Step Instructions"
                className="w-100 workflow-inputs"
                name="step_instructions"
                type="text"
                value={isNoteObj.step_instructions}
                onChange={(e) => HandleStepInputs(e)}
              />
              <span>
                {errSteps?.map(
                  (i) =>
                    i === "step_instructions" &&
                    isNoteObj?.step_instructions?.length <= 0 && (
                      <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                    )
                )}
              </span>
              <input
                type="file"
                style={{ display: "none" }}
                id="add-content"
                onChange={(e) => HandleFIleInput(e)}
              />
              <div className="d-flex gap-4">
                <div
                  className="teams-drop  mt-2"
                  style={{ width: "100px", cursor: "pointer" }}
                  onClick={() => handleChooseFile()}
                >
                  <span className="span-dropdown">
                    {WORKFLOW_VALUES.ADD_CONTENT}+
                  </span>
                </div>
                {imgPreview?.length > 0 && (
                  <div className="mt-1">
                    <img src={imgPreview} alt="content" width={30} />
                  </div>
                )}
              </div>

              <div className="mt-2">
                <div className="span-titles">Step Rules</div>
                <div className="d-flex gap-2">
                  <input
                    type="checkbox"
                    name="step_required"
                    checked={isNoteObj?.step_required}
                    onChange={(e) => HandleStepInputs(e)}
                  />{" "}
                  <span className="span-check-vals">Required?</span>
                </div>
                <div className="d-flex gap-2">
                  <input
                    type="checkbox"
                    name="allow_note"
                    checked={isNoteObj?.allow_note}
                    onChange={(e) => HandleStepInputs(e)}
                  />{" "}
                  <span className="span-check-vals">Allow Note?</span>
                </div>
                <div className="d-flex gap-2">
                  <input
                    type="checkbox"
                    name="image_required"
                    checked={isNoteObj?.image_required}
                    onChange={(e) => HandleStepInputs(e)}
                  />{" "}
                  <span className="span-check-vals">Allow Attachment?</span>
                </div>
                <div className="d-flex gap-2">
                  <input
                    type="checkbox"
                    name="allow_customer_to_view"
                    checked={isNoteObj?.allow_customer_to_view}
                    onChange={(e) => HandleStepInputs(e)}
                  />{" "}
                  <span className="span-check-vals">
                    Allow Customer to view?
                  </span>
                </div>
              </div>
            </div>
            {isNoteObj?.step_type === "Create Series" && (
              <div className="mt-2">
                <div className="span-titles">Steps:</div>
                <Select
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={teamsStyles}
                  theme={theme}
                  onChange={(options) => {
                    HandleStepSeries(options);
                  }}
                  value={isStepSeriesOptions}
                  options={GetStepSeries}
                  isMulti
                  placeholder={"Select Step"}
                  components={{
                    Option: InputOption,
                  }}
                />
                {/* {errSteps?.map(
                  (i) =>
                    i === "name" &&
                    isNoteObj?.name?.length <= 0 && (
                      <div className="err-warnings-wf">{`Please Enter ${i}`}</div>
                    )
                )} */}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="modal-footer-styles">
            <div
              className="modal-footer-save-cancel-btns"
              onClick={() => HandleStepSave()}
            >
              {WORKFLOW_VALUES.SAVE}
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              style={{ margin: 0 }}
              onClick={() => HandleCloseStepModal()}
            >
              {WORKFLOW_VALUES.CANCEL}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={isField} centered>
          <Modal.Header className="modal-bg-color modal-header-height px-2">
            <div className="header-sub-title">Selection Editor</div>
            <img
              src={Close}
              alt="Close"
              className="modal-header-icon"
              onClick={() => setIsField(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <div>
              <input
                className="w-100 workflow-inputs"
                placeholder="Input Type Name"
                name="name"
                type="text"
                value={fieldObj.name}
                onChange={(e) => handleFieldInputs(e)}
              />
            </div>
            {/* <div className="mt-2">
            <div className="span-titles"> Notification Content</div>
            <input
              className="w-100 workflow-inputs"
              placeholder="Input Notification User"
              name="email"
              type="text"
              value={fieldObj.email}
              onChange={(e) => handleFieldInputs(e)}
            />
          </div> */}
            <div className="mt-2">
              <Select
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={teamsStyles}
                theme={theme}
                onChange={(options) => {
                  HandleNotifyStep3(options);
                }}
                value={selectedStepNotify3}
                options={GetNotifyStep}
                isMulti
                placeholder={"Select Notification"}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="mt-2">
              <select
                className="workflow-inputs-select w-100"
                onChange={(e) => handleFieldInputs(e)}
                name="dropSelect"
                value={fieldObj?.dropSelect}
              >
                <option>Please Select</option>
                {isYesWfFetch?.map((it) => (
                  <option value={it?.workflow_id}>
                    {it?.workflow_id} - {it?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer-styles mt-2 gap-2 model_footer">
              <div
                className="modal-footer-save-cancel-btns"
                onClick={() => HandleFieldSave()}
              >
                {WORKFLOW_VALUES.SAVE}
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                style={{ margin: 0 }}
                onClick={() => setIsField(false)}
              >
                {WORKFLOW_VALUES.CANCEL}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <DeleteModal
          Open={isDelModal}
          DelDiscription={"Are you sure, you want to delete "}
          Close={() => setIsDelModal(false)}
          HandleDelete={() => DeleteStep()}
          Data={isStepDetails}
        />
        <Modal show={isYesModal} centered>
          <Modal.Header className="modal-bg-color modal-header-height px-2">
            <div className="header-sub-title">{isyesName} &nbsp;Selection</div>
            <img
              src={Close}
              alt="Close"
              className="modal-header-icon"
              onClick={() => CloseYesNoModal()}
            />
          </Modal.Header>
          <Modal.Body>
            <div>
              <input
                placeholder="Input Notification Message"
                className="w-100 workflow-inputs mt-1"
                // name="notification_message"
                type="text"
                value={isyesName === "NO" ? "No" : "Yes"}
              // onChange={(e) => HandleStepInputs(e)}
              />
            </div>
            <div className="mt-2">
              <Select
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={teamsStyles}
                theme={theme}
                onChange={(options) => {
                  HandleNotifyStep2(options);
                }}
                value={selectedStepNotify2}
                options={GetNotifyStep}
                isMulti
                placeholder={"Select Notification"}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="mt-2">
              <select
                className="workflow-inputs-select w-100"
                name={isyesName === "NO" ? "nselect" : "yselect"}
                value={isyesName === "NO" ? nselect : yselect}
                onChange={(e) => handleYnSelect(e)}
              >
                <option>Please Select</option>
                {isYesWfFetch?.map((it) => (
                  <option value={it?.workflow_id}>
                    {it?.workflow_id} - {it?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer-styles mt-2 gap-2 model_footer">
              <div
                className="modal-footer-save-cancel-btns"
                onClick={() => HandleFieldYESNOSave()}
              >
                {WORKFLOW_VALUES.SAVE}
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                style={{ margin: 0 }}
                onClick={() => CloseYesNoModal()}
              >
                {WORKFLOW_VALUES.CANCEL}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <DeleteModal
          Open={isChoicedelModal}
          DelDiscription={"Are you sure, you want to delete "}
          Close={() => setIsChoiceDelModal(false)}
          HandleDelete={() => DeleteChoice()}
        // Data={isStepDetails}
        />
        <Modal show={isDiscard} centered>
          <Modal.Header className="modal-bg-color modal-header-height px-2">
            <div className="header-sub-title">Discard All Changes</div>
            <img
              src={Close}
              alt="Close"
              className="modal-header-icon"
              onClick={() => setIsDiscard(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="yes-no-notify">
              Are you sure you would like to discard all changes made on this
              page? All current changes will be lost.
            </div>
            <div className="modal-footer-styles mt-2 gap-2 model_footer">
              <div
                className="modal-footer-save-cancel-btns"
                onClick={() => DiscardFun()}
              >
                {WORKFLOW_VALUES.DISCARD}
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                style={{ margin: 0 }}
                onClick={() => setIsDiscard(false)}
              >
                {WORKFLOW_VALUES.CANCEL}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={isAttachModal}
          onHide={() => setIsAttachModal(false)}
          centered
        >
          <Modal.Header>image</Modal.Header>
          <Modal.Body>
            <img src={isAttachList?.thumbnail} alt="isAttachList" width={200} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
