import React, { useState } from "react";
import Sort2 from "../../Assets/Icons/Sort2.svg";

export default function SideTable(props) {
  const [showValidation, setShowValidation] = useState(false);
  const [validName,setValidName] = useState("")
  const handleClearAll = () => {
    setShowValidation(false);
    props?.HandleClearAll();
  };
  const hadleCheckboxChange = (e, name) => {
    if ((name === "Customer Name" && !e.target.checked)){
      setShowValidation(true);
      setValidName(name);
    }else{
      setShowValidation(false);
      props?.HandleCheckWoType(e, name);
    }
  };
  return (
    <div className="">
      <div className="rt-table-head-text p-0 m-0">Select Columns</div>
      <div
        className="rtside-filter-text cursor-p"
        onClick={() => handleClearAll()}
      >
        Clear All
      </div>
      <div className="rt-sidebar-scroll">
        {props?.rightData?.map((i, index) => (
          <div key={index} className="line-align-bet ">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <img src={Sort2} alt="Sort2" width={17}/>
              <div className="rtside-filter-text2">{i?.name}</div>
            </div>
            <div>
              <input
                className="cursor-p"
                type="checkbox"
                checked={
                  !props?.isTabCol?.includes(i?.name) && props?.isSelectCheck
                }
                onChange={(e) => hadleCheckboxChange(e, i?.name)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}