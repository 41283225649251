import React, { useState, useEffect, useCallback, useContext } from "react";
import WorkOrderCard from "../../../workorders/WorkOrderCard";
import { useNavigate } from "react-router-dom";
import LeftIcon from "../../../../common/Assets/Icons/LeftIcon.svg"
import {
  EquipmentsListData,
  GetAttchMentByID,
  addTags,
  deleteTags,
  updateWorkOrderStatus,
} from "../../../../services/WorkOrdersService";
import { AppGlobalContextUpdate } from "../../../../common/context/AppGlobalContext";
import { NavigateFunction } from "../../../../common/navigation/NavigationChild";
import DeleteTags from "../../../workorders/addtag/DeleteTags";
import PopUp from "../../../workorders/WorkOrderDetails/Pop-Up/PopUp";
import {
  STATUS_TODO,
  STATUS_INPROGRESS,
  TAGS_MODAL_VALUES,
} from "../../../../common/components/Constants";
import AddTag from "../../../workorders/addtag/AddTag";
import CustomerModal from "../../../../common/components/customermodal/CustomerModal";
import AttachmentModal from "../../../workorders/AttachmentModal";
import { ReferenceId } from "../../../../common/components/EnumRefType";
import ShareWoModal from "../../../../common/components/sharemodal/ShareWoModal";
function PreviousWorkOrdersEquipments({ item, props, eqpwoorders }) {
  const navigation = useNavigate();
  const [techId, setTechId] = useState("");
  const [techPin, setTechPin] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [workOrderList, setWorkOrderList] = useState([]);

  const [idToMoveInProgress, setidToMoveInProgress] = useState(0);
  const [open, setOpen] = useState(false);

  const updateAppState = useContext(AppGlobalContextUpdate);
  const PreviousEqupmetsList = useCallback(async () => {
    if (item?.data?.equipment_id !== "") {
      let Res = await EquipmentsListData(+item?.data?.equipment_id);
      if (Res?.success) {
      }
    }
  }, [item]);
  useEffect(() => {
    PreviousEqupmetsList();
  }, [PreviousEqupmetsList]);
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    setTechId(userId);
    const userPass = JSON.parse(localStorage.getItem("techpin"));
    setTechPin(userPass);
    const Company = JSON.parse(localStorage.getItem("CompanyCode"));
    setCompanyCode(Company);
  }, []);
  const navToWoDetls2 = (e, to, wdObj, isVal) => {
    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: {wdObj, isVal,woid:wdObj?.WorkOrderId,custid:wdObj?.Customer?.id},
      navigate: navigation,
    });
    e.stopPropagation();
  };
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(null);
  const [delTagData, setDelTagData] = useState({});
  const [isWorkId, setIsWorkId] = useState(null);
  const [isWoOrderObj, setIsWoOrderObj] = useState({})
  const handleDeleteModal = (e, data, workid) => {
    setIsWoOrderObj(workid)
    e.stopPropagation();
    setIsWorkId(workid?.WorkOrderId);
    setDeleteTag(data?.id);
    setDelTagData(data);
    setShowDeleteTag(true);
  };
  const handleDeleteTag = async (it) => {
    const response = await deleteTags(deleteTag);
    if (response?.success) {
     let tagData = isWoOrderObj.tags?.filter((i)=>i?.id !== deleteTag)
    let newData1 = eqpwoorders?.filter(
      (i) => i?.WorkOrderId === isWorkId
    );
    newData1[0].tags = tagData
    setShowDeleteTag(false);
    setDeleteTag(null);
      };
      setDeleteTag(null);
  };
  const [updateStatusWork, setUpdateStatusWork] = useState(null);
  const HandleShow = (e, workOrd) => {
    setidToMoveInProgress(workOrd.id);
    e.stopPropagation();
    if (workOrd.status === STATUS_TODO) {
      setUpdateStatusWork(workOrd);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    let workOrderStatus = "";
    switch (updateStatusWork.status) {
      case STATUS_TODO:
        workOrderStatus = STATUS_INPROGRESS;
        break;
      default:
        workOrderStatus = STATUS_TODO;
        break;
    }
    const workOrderId = +updateStatusWork.id;
    const res = await updateWorkOrderStatus(workOrderId, workOrderStatus);

    if (res?.data?.success) {
      setOpen(false);
      alert(`${TAGS_MODAL_VALUES.ALERT_STATUS_UPDATE_IP}`);
      setWorkOrderList(
        workOrderList.filter((it) => it.id !== idToMoveInProgress)
      );
    }
  };
  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.equipment,
    uuid: "",
  });
  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };

  const [tempWorkOrder, setTempWorkOrder] = useState(null);
  const [isTopen, setIsTopen] = useState(false);
  const [isTagData, setIsTagData] = useState([]);
  const [eqpWotag, setEqpWotag] = useState({});
  let [loading, setLoading] = useState(false);

  const HandleAddtag = (e, workorder) => {
    setLoading(false);
    e.stopPropagation();
    setTempWorkOrder(workorder?.WorkOrderId);
    setIsTagData(workorder);
    setTagInpObj({ ...tagInpObj });
    setIsTopen(true);
    setTagInpObj({
      name: "",
      color: "#CCCCCC",
      referenceType: ReferenceId.work_order,
      uuid: "",
});
  };
  const HandleWoTagSubmit = async (it) => {
    let newTag = {
      referenceType: tagInpObj?.referenceType,
      tag_id: 0,
      referenceId:tempWorkOrder,
      name: tagInpObj.name,
      color: tagInpObj?.color,
      uuid_id: "",
      work_order_id: tempWorkOrder,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newTag);
    if (Res?.success) {
      let newOne = {
        referenceType: Res?.data?.tag?.reference_type,
        color: Res?.data?.tag?.color,
        id: Res?.data?.tag?.tag_id,
        name: Res?.data?.tag?.tag_data,
      };
      let newData1 =  eqpwoorders?.filter((i)=>i?.WorkOrderId === isTagData?.WorkOrderId)
    newData1[0].tags = [...newData1[0].tags, newOne]
    
      // updateEquiptags(newOne);
      setEqpWotag(Res?.data)
      setIsTopen(false)
      setTagInpObj({
        name: "",
        color: "#60666C",
        referenceType: ReferenceId.equipment,
        uuid: "",
      });
    }
  };
  const [isCustomer, setIsCustomer] = useState(false);
  const [customerObj, setCustomerObj] = useState({});
  const HandleCutomer = (e, value) => {
    e.stopPropagation();
    setIsCustomer(true);
    setCustomerObj(value);
  };
  const [workOrderItem, setWorkOrdrItem] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const HandleShare = (e, value) => {
    e.stopPropagation();
    setWorkOrdrItem(value);
    setIsOpen(true);
  };
  const [isAttach, setIsAttach] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [isWoid, setIsWoId] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const HandleAttch = async (e, value) => {
    e.stopPropagation();
    setIsWoId(value.workorder_id);
    setIsAttach(true);
    setIsLoad(false);
    let attchId = value.id;
    let custId = value.customer_id;
    let WorkId = value.workorder_id;
    let eqId = value.equipment_id;
    let techId = value.technician_id;
    let step = value.workorder_step_id;
    let workflow = value.workorder_workflow_id;
    const response = await GetAttchMentByID(
      attchId,
      step,
      workflow,
      custId,
      WorkId,
      eqId,
      techId
    );

    if (response.success) {
      setIsLoad(true);
      setAttachmentData(response.data);
    }
  };
  const [workorderopen, setWorkorderopen] = useState(true)

  const handleworkorder = () => {
    setWorkorderopen(!workorderopen)
  }
  return (
    <div>
      <div className="sett-crd-hdr-txt clp_header">
        {" "}
        <img onClick={() => handleworkorder()} className=" mb-1 cursor-p" alt="" src={LeftIcon} />{" "}
        <span className=" cursor-p" onClick={() => handleworkorder()}>Work Orders({eqpwoorders?.filter(item => item !== null)?.length?eqpwoorders?.filter(item => item !== null)?.length:0})</span>{" "}
      </div>
      {workorderopen &&  (
        <>
        
          <div className="clp_body">
            {eqpwoorders && eqpwoorders?.length > 0 ? (
              eqpwoorders?.filter(item => item !== null)?.map((item,index) => (
                <WorkOrderCard
                  key={index} 
                  eachWorkOrder={item}
                  navToWoDetls={navToWoDetls2}
                  techId={techId}
                  techPin={techPin} 
                  eqpWotag={eqpWotag}
                  isTagData={isTagData}
                  companyId={companyCode}
                  handleDeleteModal={handleDeleteModal}
                  HandleShow={HandleShow}
                  HandleAddtag={HandleAddtag}
                  HandleCutomer={HandleCutomer}
                  HandleShare={HandleShare}
                  HandleAttch={HandleAttch}

                  // key={index}
                  // eachWorkOrder={item}
                  // techId={techId}
                  // techPin={techPin}
                  // companyId={companyId}
                  // navToWoDetls={navToWoDetls}
                  // HandleCutomer={HandleCutomer}
                  // HandleMemory={HandleMemory}
                  // HandleAddtag={HandleAddtag}
                  // HandleShare={HandleShare}
                  // HandleShow={HandleShow}
                  // handleDeleteModal={handleDeleteModal}
                  // HandleToggleTags={HandleToggleTags}
                  // isvalue={isvalue}
                  // HandleAttch={HandleAttch}
                  // HandleForDots={HandleForDots}
                />
              ))
            ) : (
              <div className="ml-2">
                Previous Work Orders will show up here once they have been tied up.
              </div>
            )}
          </div>
          

        </>
      )}


      <AddTag
        Open={isTopen}
        name={tagInpObj.name}
        color={tagInpObj.color}
        workOrderId={"Work Order #" + tempWorkOrder}
        HandleTagInputs={changeTagEvent}
        isClose={() => setIsTopen(false)}
        isLoading={loading}
        isSubmit={() => HandleWoTagSubmit(isTagData)}
      />
      <DeleteTags
        deleteTag={() => handleDeleteTag(delTagData)}
        handleClose={() => setShowDeleteTag(false)}
        data={delTagData}
        name={"Work Order"}
        headid={isWorkId}
        isOpen={showDeleteTag}
      />
      <PopUp
        open={open}
        workOrder={updateStatusWork}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        HandleShow={HandleShow}
      />
      <CustomerModal
        Open={isCustomer}
        Close={() => setIsCustomer(false)}
        data={customerObj}
        custId ={customerObj?.Customer?.id}
        contactname={customerObj?.contact?.name}
        note ={customerObj?.contact?.note}
      />
      <ShareWoModal
        Open={isOpen}
        Close={() => setIsOpen(false)}
        data={workOrderItem}
      />
      <AttachmentModal
        Open={isAttach}
        Close={() => setIsAttach(false)}
        isloading={isLoad}
        isloadingClose={() => setIsLoad(false)}
        eachWorkOrder={attachmentData}
        WorkId={isWoid}
      />
    </div>
  );
}

export default PreviousWorkOrdersEquipments;
