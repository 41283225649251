import React, { forwardRef } from "react";
import { Modal, Dropdown } from "react-bootstrap"
import DatePicker from "react-datepicker";
import close from "../../common/Assets/Icons/Close_round_main.svg";
import mail from "../../common/Assets/Icons/mail.svg";
import phone from "../../common/Assets/Icons/phones_call_Filled_main.svg";
import chat from "../../common/Assets/Icons/chatbubble_Filled_main.svg";
import './ComFilter.css';
import wheel from "../../common/Assets/Icons/DownDir.svg";
import sortA from "../../common/Assets/Icons/sortAz.svg";
import sortZ from "../../common/Assets/Icons/sortZa.svg";
import { BiSearch } from "react-icons/bi";
import inlight from "../../common/Assets/Icons/In.svg";
import outlight from "../../common/Assets/Icons/Out.svg";
import message from "../../common/Assets/Icons/Message_open_light.svg";

const CommunicationFilter = (props) => {
    const [startDate, setStartDate] = React.useState();
    const [completedDate, setCompletedDate] = React.useState();


    const [array, setarray] = React.useState([
        {
            name: "Tech 1",
            type: "checkbox"
        },
        {
            name: "Tech 2",
            type: "checkbox"
        },
        {
            name: "Tech 3",
            type: "checkbox"
        },
        {
            name: "Tech 4",
            type: "checkbox"
        },
        {
            name: "Tech 5",
            type: "checkbox"
        },
    ])

    const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="example-custom-input2 mt-1"
            placeholder="Select Date"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));

    return (
        <Modal centered show={props.isOpen} onHide={props.isClose}>
            <Modal.Header className="px-2 modal-bg-color modal-header-height note-edit-text">
                <div className="modalName">Communication filter</div>
                <img
                    src={close}
                    alt=""
                    onClick={props.isClose}
                    className="modal-header-icon"
                />
            </Modal.Header>
            <div className="modalbody1">Date Range</div>
            <div className="line-align-bet ml-2">
                <div className=" lbl-fltr-adj">
                    <span className="txt-fltr-adj">Start Date</span>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        customInput={<ExampleCustomInput />}
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                    />
                </div>
                <div className=" lbl-fltr-adj ">
                    <span className="txt-fltr-adj">End Date</span>
                    <DatePicker
                        selected={completedDate}
                        onChange={(date) => setCompletedDate(date)}
                        customInput={<ExampleCustomInput />}
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                    />
                </div>
            </div>
            <div className="modalbody1 mt-2">Communication Type</div>
            <div className="modalFooter">
                <button className="footerButtons"><img src={phone} alt="" width={14} className="buttonImage" />Calls</button>
                <button className="footerButtons"><img src={chat} alt="" width={14} className="buttonImage" />Messages</button>
                <button className="footerButtons"><img src={mail} alt="" width={14} className="buttonImage" />Emails</button>
            </div>

            <div className="modalbody1">Message Status</div>
            <div className="modalFooter">
                <button className="footerButtons"><img src={message} alt="" width={14} className="buttonImage" />Read</button>
                <button className="footerButtons"><img src={mail} alt="" width={14} className="buttonImage" />Unread</button>
            </div>

            <div className="modalbody1">Message Type</div>
            <div className="modalFooter">
                <button className="footerButtons"><img src={inlight} alt="" width={14} className="buttonImage" />Incoming</button>
                <button className="footerButtons"><img src={outlight} alt="" width={14} className="buttonImage" />Outgoing</button>
            </div>
            <div className="line-align-bet mt-2">
                <div className="lbl-fltr-adj">
                    <div>
                        <Dropdown>
                            <div className="txt-fltr-adj">Select Department(s)</div>
                            <Dropdown.Toggle
                                className="my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                                style={{ width: "97%" }}
                            >
                                Select Department(s)
                                <img
                                    alt=""
                                    width={16}
                                    height={16}
                                    style={{ float: 'right' }}
                                    src={wheel}
                                />{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-0">
                                <Dropdown.Item className="p-1">
                                    <img alt="" src={sortA} />{" "}
                                    <span className="mx-2">Sort A to Z</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="p-1">
                                    <img alt="" src={sortZ} />{" "}
                                    <span className="mx-2">Sort Z to A</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="p-1">
                                    <div
                                        className=" lbl-fltr-adj w-100"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="close-icon-set">
                                            <BiSearch
                                                height={16}
                                                width={16}
                                                style={{ marginTop: 0 }}
                                            />
                                        </div>
                                        <input
                                            className="ipt-fltr-adj-itm "
                                            placeholder="Enter Tech"
                                        />
                                    </div>
                                </Dropdown.Item>
                                {
                                    array.map((data, index) => (
                                        <Dropdown.Item>
                                            <input type={data.type} />
                                            <span style={{ paddingLeft: 10 }}>{data.name}</span>
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="lbl-fltr-adj">
                    <div>
                        <Dropdown>
                            <div className="txt-fltr-adj">Select User(s)</div>
                            <Dropdown.Toggle
                                className=" my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                                style={{ width: "97%" }}
                            >Select User(s)
                                <img
                                    alt=""
                                    width={16}
                                    height={16}
                                    style={{ float: 'right' }}
                                    src={wheel}
                                />{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-0">
                                <Dropdown.Item className="p-1">
                                    <img alt="" src={sortA} />{" "}
                                    <span className="mx-2">Sort A to Z</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="p-1">
                                    <img alt="" src={sortZ} />{" "}
                                    <span className="mx-2">Sort Z to A</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="p-1">
                                    <div
                                        className=" lbl-fltr-adj w-100"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="close-icon-set">
                                            <BiSearch
                                                height={16}
                                                width={16}
                                                style={{ marginTop: 0 }}
                                            />
                                        </div>
                                        <input
                                            className="ipt-fltr-adj-itm "
                                            placeholder="Enter Tech"
                                        />
                                    </div>
                                </Dropdown.Item>
                                {
                                    array.map((data, index) => (
                                        <Dropdown.Item>
                                            <input type={data.type} />
                                            <span style={{ paddingLeft: 10 }}>{data.name}</span>
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <Modal.Body>
                <div className='modal-footer-styles mt-2 gap-2 model_footer'>
                    <div className="modal-footer-save-cancel-btns" >
                        Save
                    </div>
                    <div onClick={props.isClose}
                        className="modal-footer-save-cancel-btns">
                        Cancel
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CommunicationFilter;
