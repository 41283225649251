import React from "react";
import { Modal } from "react-bootstrap";
import "./CustomerModalStyles.css";
import PersonImg from "../../Assets/Images/personIcon.svg";
import callImg from "../../Assets/Images/Vector.svg";
import mesgImg from "../../Assets/Images/Vectormsg.svg";
import Vectorlogout from "../../Assets/Images/Vectorlogout.svg";
import OverlayClose from "../../Assets/Icons/OverlayClose.svg";
import {
  CUSTOMER_CALL,
  CUSTOMER_MESSAGE,
  CUSTOMER_DIRECTION,
} from "../Constants";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "../../navigation/NavigationChild";

export default function CustomerModal(props) {
  const navigation = useNavigate();
  const HandleCustPrfl = () => {
    props.Close();
  };
  let userId = localStorage.getItem("userId");
  let TechPin = JSON.parse(localStorage.getItem("techpin"));
  let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));
  const handleNavCust = (key, item) => {
    NavigateFunction({
      to:`CreateNewcustomer/${0}/${props?.custId}/${userId}/${TechPin}/${CompanyId}/${0}`,
      params: {
        key:props?.custId,
      },
      navigate: navigation,
    });
    props.Close();
  };
  return (
    <div>
      <Modal  show={props?.Open} onHide={props?.Close} size="sm" centered>
      <Modal.Header className="modal-bg-color " style={{ height: "40px" }}>
      <div className="customer-modal-body">
            <div className="d-flex">
              <div>
                <img
                  className="customer-image"
                  src={PersonImg}
                  alt="cust-img"
                />
              </div>
              <div className="mx-2  customer-modal-name">
                 {props?.contactname}
              </div>
            </div>
            <img
              src={OverlayClose}
              alt="close"
              className="close-cust-mod"
              onClick={HandleCustPrfl}
            />
          </div>
          </Modal.Header>
        <Modal.Body>
          
          <div className="customer-modal-discription">
            {props?.note}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-styles">
        <div className="customer-modal-footer">
            <div className=" contactOptions ">
              <span>{CUSTOMER_CALL}</span>
              <img src={callImg} alt="call" width={12} className="mx-1" />
            </div>
            <div className=" contactOptions ">
              <span>{CUSTOMER_MESSAGE}</span>
              <img src={mesgImg} alt="mesgImg" width={12} className="mx-1" />
            </div>
            <div
              className="contactOptions "
              onClick={() => handleNavCust("edit", props?.data)}
            >
              <span>{CUSTOMER_DIRECTION}</span>
              <img
                src={Vectorlogout}
                alt="Vectorlogout"
                width={12}
                className="mx-1"
              />
            </div>
          </div>
          </Modal.Footer>
      </Modal>
    </div>
  );
}
