import React, { useEffect, useRef, useState } from "react";
import "./Integra.css";
import save from "../../../../common/Assets/Icons/save.svg";
import WoAddField from "../../../../common/Assets/Icons/Left Iconwoadd.svg";
import {
  INTEGRATIONS_DET,
  SETTINGS_PAGES,
  TAGS_MODAL_VALUES,
} from "../../../../common/components/Constants";
import { Col, Container, Row } from "react-bootstrap";
import delw from "../../../../common/Assets/Icons/delW.svg";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import AddFieldsIntegrations from "./AddFieldsIntegrations";
import {
  IntegAddFields,
  IntegGetFields,
} from "../../../../services/SettingsServices";
import { FaCheck } from "react-icons/fa6";
import AltInteg from "./AltInteg";
import ProgressBar from "../../../../common/navigation/ProgressBar";
function Integrations(props) {
  // WO Fields Int
  const contentRefs = useRef([]);
  useEffect(() => {
    scrollToDiv(props?.isInt);
  }, [props?.isInt]);
  const scrollToDiv = (index) => {
    const contentDiv = contentRefs?.current[index];
    contentDiv?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  useEffect(() => {
    getAllIntegFields();
  }, []);
  const [ErpWoFields, setErpWoFields] = useState([]);
  const [ErpCustFields, setErpCustFields] = useState([]);
  const [ErpEqpFields, setErpEqpFields] = useState([]);
  const [ErpContFields, setErpContFields] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const getAllIntegFields = async () => {
    const response = await IntegGetFields();
    if (response) {
      setIsProcess(false);
      const compntData = response;
      // WoFields
      compntData?.wo_mapping?.length > 0 &&
        setWoIntegFields(compntData?.wo_mapping);

      // CustFields
      compntData?.customer_mapping?.length > 0 &&
        setCustIntegFields(compntData?.customer_mapping);

      // EqupFields
      compntData?.equipment_mapping?.length > 0 &&
        setEquipIntegFields(compntData?.equipment_mapping);

      // ContactFields
      compntData?.contact_mapping?.length > 0 &&
        setConIntegFields(compntData?.contact_mapping);

      setErpWoFields(compntData?.ErpWoMapping);
      setErpCustFields(compntData?.ErpCustomerMapping);
      setErpEqpFields(compntData?.ErpEquipmentMapping);
      setErpContFields(compntData?.ErpContactMapping);

      // if (compntData?.wo_mapping && compntData?.wo_mapping?.length > 0) {
      //   setSavedInteg(false);
      //   setWoIntegFields(compntData?.wo_mapping?.slice(0, 30));
      // }

      // if (
      //   compntData?.customer_mapping &&
      //   compntData?.customer_mapping.length > 0
      // ) {
      //   setCustIntegFields(compntData?.customer_mapping?.slice(0, 30));
      // }

      // if (
      //   compntData?.contact_mapping &&
      //   compntData?.contact_mapping.length > 0
      // ) {
      //   setConIntegFields(compntData?.contact_mapping);
      // }

      // if (
      //   compntData?.equipment_mapping &&
      //   compntData?.equipment_mapping.length > 0
      // ) {
      //   setEquipIntegFields(compntData?.equipment_mapping?.slice(0, 30));
      // }
    }
  };

  const [workOdrFldSett, setWorkOdrFldSett] = useState(false);
  const HandleWorkOdrFldSett = () => {
    setWorkOdrFldSett(!workOdrFldSett);
  };

  // const initialWoFields = Array.from(
  //   { length: initialWoFields.length },
  //   (v, i) => ({
  //     field_id: i + 1,
  //     control_type: "",
  //     erpcolumn: "",
  //     packcolumn: "",
  //     characterlength: 0,
  //     required: false,
  //     active: false,
  //     order: i + 1,
  //   })
  // );
  const [woIntegFields, setWoIntegFields] = useState([]);

  // Cust Fields Int
  const [custFldSett, setCustFldSett] = useState();
  const HandleCustFldSett = () => {
    setCustFldSett(!custFldSett);
  };

  const [custIntegFields, setCustIntegFields] = useState([]);

  // Contact Fields Int
  const [contFlds, setContFlds] = useState(false);
  const HandleContFlds = () => {
    setContFlds(!contFlds);
  };

  const [contIntegFields, setConIntegFields] = useState([]);
  // contact_mapping

  // Equipment Fields Int

  const [eqpFldSett, setEqpFldSett] = useState(false);
  const HandleEqpFldSett = (item) => {
    setEqpFldSett(!eqpFldSett);
  };

  const [equipIntegFields, setEquipIntegFields] = useState([]);

  // Gett Call

  // Post Call

  const [errorData, setErrorData] = useState({});
  const pushErrors = (data) => {
    let newObj = {};
    data.forEach((i) => {
      let tempList = [];
      if (i.packcolumn?.length !== 0) {
        if (i.erpcolumn?.length === 0) {
          tempList.push("erpcolumn");
        }
        if (i.characterlength === 0) {
          tempList.push("characterlength");
        }
        if (i.control_type?.length === 0) {
          tempList.push("control_type");
        }
      }
      // if (i.name?.trim()?.length === 0) {
      //   tempList.push("name");
      // }
      if (tempList.length > 0) {
        newObj = { ...newObj, [i.field_id]: tempList };
      }
    });
    return newObj;
  };
  const prepareErrorData = () => {
    const newObj = {
      wo_mapping: pushErrors(woIntegFields),
      contact_mapping: pushErrors(contIntegFields),
      customer_mapping: pushErrors(custIntegFields),
      equipment_mapping: pushErrors(equipIntegFields),
    };
    let errCount = 0;

    setErrorData(newObj);

    Object.keys(newObj).forEach((i) => {
      if (Object.keys(newObj[i]).length > 0) {
        errCount += 1;
      }
    });
    return { errorCount: errCount, errorObj: newObj };
  };

  const [savedInteg, setSavedInteg] = useState(false);
  const onDiscardInteg = () => {
    setErrorData({});
  };

  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isFinalSave, setIsFinalSave] = useState(false);

  const HandleIntegSave = async () => {
    // let DupData = DuplicateData();

    let { errorCount } = prepareErrorData();

    if (errorCount > 0) {
      setIsFinalSave(true);
      setIsErrorModal(true);
    }

    if (errorCount === 0) {
      const payload = {
        wo_mapping: woIntegFields,
        customer_mapping: custIntegFields,
        equipment_mapping: equipIntegFields,
        contact_mapping: contIntegFields,
      };

      const response = await IntegAddFields(payload);
      if (response && response?.success) {
        setSavedInteg(true);
        getAllIntegFields();
      }
    }
  };
  const onWOIntegChangeHandler = (key, value, id) => {
    const newList = [...woIntegFields]?.map((i) => {
      let temp = i;
      if (i.field_id === id) {
        temp[key] = value;
      }
      return temp;
    });
    setWoIntegFields(newList);
  };

  const onEquipIntegChangeHandler = (key, value, id) => {
    const newList = [...equipIntegFields]?.map((i) => {
      let temp = i;
      if (i.field_id === id) {
        temp[key] = value;
      }
      return temp;
    });
    setEquipIntegFields(newList);
  };

  const onCustIntegChangeHandler = (key, value, id) => {
    const newList = [...custIntegFields]?.map((i) => {
      let temp = i;
      if (i.field_id === id) {
        temp[key] = value;
      }
      return temp;
    });
    setCustIntegFields(newList);
  };

  const onContIntegChangeHandler = (key, value, id) => {
    const newList = [...contIntegFields]?.map((i) => {
      let temp = i;
      if (i.field_id === id) {
        temp[key] = value;
      }
      return temp;
    });
    setConIntegFields(newList);
  };

  // need to move up
  const HandleIncreaseRow = (data, type) => {
    const maxId = data.reduce(
      (max, obj) => (obj.field_id > max ? obj.field_id : max),
      0
    );

    let InpObj = {
      active: false,
      characterlength: "",
      control_type: "",
      erpcolumn: "",
      field_id: maxId + 1,
      erpname: "",
      name: "",
      packcolumn: "",
      required: false,
    };
    const lastIndexObj = data.find((i) => i?.field_id === maxId);
    lastIndexObj?.name?.length > 0 &&
      (type === "WorkOrders"
        ? setWoIntegFields([...data, InpObj])
        : type === "Contacts"
        ? setConIntegFields([...data, InpObj])
        : type === "Customers"
        ? setCustIntegFields([...data, InpObj])
        : setEquipIntegFields([...data, InpObj]));
  };
  return (
    <>
      <div
        className="line-align-bet"
        style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
      >
        <span className="sub-hdr-sett mx-1">{SETTINGS_PAGES.INTEG}</span>

        {savedInteg && (
          <span className="pop-up-field-stp  ">
            {" "}
            Fields Saved Successfully... <FaCheck />{" "}
          </span>
        )}
        <span className="d-flex mb-1 mt-1 ">
          <button className="sett-save-btn" onClick={() => onDiscardInteg()}>
            {" "}
            <span className="ml-2">{TAGS_MODAL_VALUES.DISCARD} </span>
            <img
              alt="delw"
              className="ml-2 mb-1"
              src={delw}
              width={15}
              height={15}
            />{" "}
          </button>
          <button
            onClick={() => HandleIntegSave()}
            className="sett-save-btn mx-2"
          >
            {" "}
            <span>{TAGS_MODAL_VALUES.SAVE} </span>
            <img
              alt="save"
              className="ml-2 "
              src={save}
              width={15}
              height={15}
            />{" "}
          </button>
        </span>
      </div>
      <div className="pagescroll">
        <div className="head-stick">
          {/* <CommonHeader />
          <SettingsHeader data={"Integrations"} />
          <hr style={{ margin: "0px" }} /> */}
        </div>
        {isProcess ? (
          <div className="pro-bar-style">
            <ProgressBar />
          </div>
        ) : (
          <>
            {/* <span className="hd-sett-sal mx-1">
                {INTEGRATIONS_DET.SERVICE_FUSION_INTEG}
              </span> */}
            <div style={{ marginBottom: "120px" }}>
              {/* WO Fields Sett */}
              <div
                className="m-1"
                ref={(ref) => (contentRefs.current[0] = ref)}
              >
                <div className="line-align-bet clp_header mt-2">
                  <div>
                    <img
                      alt="WoAddField"
                      className="mb-1 mx-1"
                      src={WoAddField}
                    />
                    <span
                      className="setField-header cursor-p"
                      onClick={() => HandleWorkOdrFldSett()}
                    >
                      {INTEGRATIONS_DET.WORK_ODR_FLD_SETT}{" "}
                    </span>
                  </div>
                  <div className="cursor-p">
                    {" "}
                    <img
                      src={Add_Main}
                      alt="Add_Main"
                      onClick={() =>
                        HandleIncreaseRow(woIntegFields, "WorkOrders")
                      }
                    />
                  </div>
                </div>
                {workOdrFldSett ? (
                  ""
                ) : (
                  <div className="p-1">
                    <div className=" ">
                      <Container fluid className="p-2" style={{border: "solid 1px #dedede"}}>
                        <Row className="hdr-tbl-fld-sett mx-1">
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span> <span>Label Name:</span>
                            </span>
                          </Col>
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span>{" "}
                              <span>Column Name:</span>
                            </span>
                          </Col>
                          <Col xs={2}>ERP Get:</Col>
                          <Col xs={2}>ERP Post:</Col>
                          <Col xs={1} className="cntr-hr-line ">
                            Type input:
                          </Col>
                          <Col xs={1} className="">
                            Browse/Type Manager:
                          </Col>
                          <Col xs={1} className="column-center">
                            Active:
                          </Col>
                          <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Required:
                          </Col>
                          {/* <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Show On Grid
                          </Col> */}
                        </Row>
                        <div className="inp-int-one-rw p-0 ">
                          {woIntegFields?.map((item, index) => (
                            <AddFieldsIntegrations
                              emptyField={errorData["wo_mapping"]}
                              item={item}
                              onFieldChangeHandler={onWOIntegChangeHandler}
                              ErpData={ErpWoFields}
                              // PackColumn={PackColumn}
                            />
                          ))}
                        </div>
                      </Container>
                    </div>
                  </div>
                )}
              </div>
              {/* Contact Fields Sett */}
              <div
                className="m-1 mt-2"
                ref={(ref) => (contentRefs.current[3] = ref)}
              >
                <div className="line-align-bet clp_header">
                  <div>
                    <img
                      alt="WoAddField"
                      className="mb-1 mx-1"
                      src={WoAddField}
                    />
                    <span
                      className="setField-header cursor-p"
                      onClick={() => HandleContFlds()}
                    >
                      Contact Field Settings
                    </span>
                  </div>
                  <div className="cursor-p">
                    {" "}
                    <img
                      src={Add_Main}
                      alt="Add_Main"
                      onClick={() =>
                        HandleIncreaseRow(contIntegFields, "Contacts")
                      }
                    />
                  </div>
                </div>
                {contFlds ? (
                  ""
                ) : (
                  <div className="p-1">
                    <div className=" ">
                      <Container fluid className="p-0">
                        <Row className="hdr-tbl-fld-sett mx-1">
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span> <span>Label Name:</span>
                            </span>
                          </Col>
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span>{" "}
                              <span>Column Name:</span>
                            </span>
                          </Col>
                          <Col xs={2}>ERP Get:</Col>
                          <Col xs={2}>ERP Post:</Col>
                          <Col xs={1} className="cntr-hr-line ">
                            Type input:
                          </Col>
                          <Col xs={1} className="">
                            Browse/Type Manager:
                          </Col>
                          <Col xs={1} className="column-center">
                            Active:
                          </Col>
                          <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Required:
                          </Col>
                          {/* <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Show On Grid
                          </Col> */}
                        </Row>
                        <div className="inp-int-one-rw p-0 ">
                          {contIntegFields?.map((item, index) => (
                            <AddFieldsIntegrations
                              emptyField={errorData["contact_mapping"]}
                              item={item}
                              onFieldChangeHandler={onContIntegChangeHandler}
                              ErpData={ErpContFields}
                              // PackColumn={PackColumn}
                            />
                          ))}
                        </div>
                      </Container>
                    </div>
                  </div>
                )}
              </div>
              {/* customer Fields Sett */}
              <div
                className="m-1 mt-2"
                ref={(ref) => (contentRefs.current[1] = ref)}
              >
                <div className="line-align-bet clp_header">
                  <div>
                    <img
                      alt="WoAddField"
                      className="mb-1 mx-1"
                      src={WoAddField}
                    />
                    <span
                      className="setField-header cursor-p"
                      onClick={() => HandleCustFldSett()}
                    >
                      {INTEGRATIONS_DET.CUST_FLD_SETT}
                    </span>
                  </div>
                  <div className="cursor-p">
                    {" "}
                    <img
                      src={Add_Main}
                      alt="Add_Main"
                      onClick={() =>
                        HandleIncreaseRow(custIntegFields, "Customers")
                      }
                    />
                  </div>
                </div>

                {custFldSett ? (
                  ""
                ) : (
                  <div className="p-1">
                    <div className=" ">
                      <Container fluid className="p-0">
                        <Row className="hdr-tbl-fld-sett mx-1">
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span> <span>Label Name:</span>
                            </span>
                          </Col>
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span>{" "}
                              <span>Column Name:</span>
                            </span>
                          </Col>
                          <Col xs={2}>ERP Get:</Col>
                          <Col xs={2}>ERP Post:</Col>
                          <Col xs={1} className="cntr-hr-line ">
                            Type input:
                          </Col>
                          <Col xs={1} className="">
                            Browse/Type Manager:
                          </Col>
                          <Col xs={1} className="column-center">
                            Active:
                          </Col>
                          <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Required:
                          </Col>
                          {/* <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Show On Grid
                          </Col> */}
                        </Row>
                        <div className="inp-int-one-rw p-0 ">
                          {custIntegFields?.map((item, index) => (
                            <AddFieldsIntegrations
                              emptyField={errorData["wo_mapping"]}
                              item={item}
                              onFieldChangeHandler={onCustIntegChangeHandler}
                              ErpData={ErpCustFields}
                              // PackColumn={PackColumn}
                            />
                          ))}
                        </div>
                      </Container>
                    </div>
                  </div>
                )}
              </div>
              {/*  Eqp Fields Sett */}
              <div
                className="m-1 mt-2"
                ref={(ref) => (contentRefs.current[2] = ref)}
              >
                <div className="line-align-bet">
                  <div>
                    <img
                      alt="WoAddField"
                      className="mb-1 mx-1"
                      src={WoAddField}
                    />
                    <span
                      className="setField-header cursor-p"
                      onClick={() => HandleEqpFldSett()}
                    >
                      {INTEGRATIONS_DET.EQP_FLD_SETT}
                    </span>
                  </div>
                  <div className="cursor-p">
                    {" "}
                    <img
                      src={Add_Main}
                      alt="Add_Main"
                      onClick={() =>
                        HandleIncreaseRow(equipIntegFields, "Equipments")
                      }
                    />
                  </div>
                </div>
                {eqpFldSett ? (
                  ""
                ) : (
                  <div className="p-1">
                    <div className=" ">
                      <Container fluid className="p-0">
                        <Row className="hdr-tbl-fld-sett mx-1">
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span> <span>Label Name:</span>
                            </span>
                          </Col>
                          <Col xs={2} className="">
                            <span className=" d-flex flex-column">
                              <span> Visual Tech </span>{" "}
                              <span>Column Name:</span>
                            </span>
                          </Col>
                          <Col xs={2}>ERP Get:</Col>
                          <Col xs={2}>ERP Post:</Col>
                          <Col xs={1} className="cntr-hr-line ">
                            Type input:
                          </Col>
                          <Col xs={1} className="">
                            Browse/Type Manager:
                          </Col>
                          <Col xs={1} className="column-center">
                            Active:
                          </Col>
                          <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Required:
                          </Col>
                          {/* <Col
                            xs={1}
                            className="column-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Show On Grid
                          </Col> */}
                        </Row>
                        <div className="inp-int-one-rw p-0 ">
                          {equipIntegFields?.map((item, index) => (
                            <AddFieldsIntegrations
                              emptyField={errorData["wo_mapping"]}
                              item={item}
                              onFieldChangeHandler={onEquipIntegChangeHandler}
                              ErpData={ErpEqpFields}
                              // PackColumn={PackColumn}
                            />
                          ))}
                        </div>
                      </Container>
                    </div>
                  </div>
                )}
              </div>

              {isErrorModal && (
                <AltInteg
                  isOpen={isErrorModal}
                  isFinalSave={isFinalSave}
                  data={errorData}
                  actualData={{
                    wo_mapping: woIntegFields,
                    equipment_mapping: equipIntegFields,
                    customer_mapping: custIntegFields,
                    contact_mapping: contIntegFields,
                  }}
                  isClose={() => setIsErrorModal(false)}
                />
              )}
            </div>
          </>
        )}
        {/* <CommonFooter /> */}
      </div>
    </>
  );
}

export default Integrations;
