  import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import "./Alerts.css";
import { MOD_TEXT_FIELD_SETUP } from "../Values";
export default function Alerts(props) {
  const additionalFields = props.data
  ? props.data.filter((data) => data.packcolumn)
  : [];
const extradetails = props.data
  ? props.data.filter((data) => !data.packcolumn)
  : [];
  return (
    <Modal show={props.isOpen} onHide={props.isClose} centered size="sm">
      <Modal.Header className="modal-bg-color modal-header-height px-2">
        <div className="attach-media-note line-align-bet">
          <div>{MOD_TEXT_FIELD_SETUP.ERR_MSG} </div>
          <img
            src={close}
            alt="Close"
            className="modal-header-icon"
            onClick={props.isClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
        <div className="err-mesge">{MOD_TEXT_FIELD_SETUP.ETR} :</div>
        {extradetails?.map((it) => {
          const errorMessage = it?.message?.trim();
          return (
            <div>
              <div className="err-mesge-small mx-2" key={errorMessage}>
              {errorMessage === "customer_name"
                ? "Customer Name"
                 : errorMessage === "first_name"
                ? "First Name"
                : errorMessage === "telephone" && it?.value?.length <= 10
                ? "Phone Number (must be 10 digits)"
                : errorMessage === "telephone"
                ? "Phone Number"
                : errorMessage === "address"
                ? "Address"
                : errorMessage === "equipment_type"
                ? "Equipment Type"
                : errorMessage === "location"
                ? "Location"
                : errorMessage === "manufacturer_code"
                ? "Manufacturer"
                : errorMessage === "model_number"
                ? "Model Number"
                : errorMessage === "serial_number"
                ? "Serial Number"
                : errorMessage === "install_date"
                ? "Install Date"
                  : errorMessage === "warranty_date"
                ? "Warranty Date"
                : errorMessage === "note"
                ? "Description"
                // : errorMessage === "extra_field_1"
                // ? "Extra field 1"
                // : errorMessage === "extra_field_2"
                // ? "Extra field 2"
                // : errorMessage === "extra_field_3"
                // ? "Extra field 3"
                : errorMessage === "last_name"
                ? "Last Name"
                : errorMessage === "email_id"
                ? "Email id"
                : errorMessage === "user_name"
                ? "User Name"
                : errorMessage === "technician_code"
                ? "Technician"
                : errorMessage === "password"
                ? "Password"
                : errorMessage === "package"
                ? "Package"
                : errorMessage === "division"
                ? "Division"
                : errorMessage}
            </div>
            </div>
          );
        })}
         {additionalFields?.length > 0 && (
              <>
                <h5 className="err-mesge mt-2 mb-0 cntr-hr-line">
                  Additional Fields :
                </h5>
                {additionalFields?.map((data, index) => (
                  <div className="txt-ctr-line" key={index}>
                    <span className="err-txt mx-2">{data.packcolumn}</span>
                  </div>
                ))}
              </>
            )}

        {/* {props?.addFields?.length > 0 && (
          <>
          <h5 className="err-mesge mt-1 cntr-hr-line">
            Additional Fields:
          </h5>
          {additionalFields?.map((data, index) => (
            <div className="txt-ctr-line" key={index}>
              <span className="err-txt mx-2">{data.packcolumn}</span>
            </div>
          ))}
        </>
        )}    */}
        </>
      </Modal.Body>
    </Modal>
  );
}
