import React, { forwardRef, useState, useEffect, useCallback } from "react";
import "./Library.css";
import VectorEqpBlue from "../../common/Assets/Images/VectorEqpBlue.svg";
import blackfile from "../../common/Assets/Icons/blackfile.svg"
import workflowfr from "../../common/Assets/Icons/workflowfr.svg"
import PDF from "../../common/Assets/Icons/PDF.svg";
import mpeople from "../../common/Assets/Icons/mpeople.svg"
import galleryicon from "../../common/Assets/Icons/galleryicon.svg"
import documenticon from "../../common/Assets/Icons/documenticon.svg"
import fileroomeqpicon from '../../common/Assets/Icons/fileroomeqpicon.svg'
import timeR from "../../common/Assets/Icons/timeR.svg"
import Filemodal from "../../common/components/filemodal/Filemodal";
import { getLibraryRecentItems, getFiles, Getmemorydata,  AttchmentsByTech, MediaAttachments } from "../../services/WorkOrdersService";
import AttachmentModal from "../workorders/AttachmentModal";
import moment from "moment";
import ShareModal from "../../common/components/sharemodal/ShareModal";
import CustomerModal from "../../common/components/customermodal/CustomerModal";
import { useContext } from "react";
import { AppGlobalContext, AppGlobalContextUpdate } from "../../common/context/AppGlobalContext";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import CommonFooter from "../../common/framework/footer/CommonFooter";
import ProgressBar from "../../common/navigation/ProgressBar";



const Library = forwardRef(function Library(props, ref) {
  const appState = useContext(AppGlobalContext);
  const setRecentItems = useState({})
  const updateAppState = useContext(AppGlobalContextUpdate)
  const getAllWOFiles = useCallback(async () => {
    const WOId = appState?.currentWorkOrder?.id
    const custId = appState?.currentWorkOrder?.Customer?.id
    const techId = appState?.currentWorkOrder?.Technician?.id
    const response = await getFiles(WOId, techId, custId);
    if (response && response?.responseOk && response?.responseOk?.files) {
      updateAppState({ currentWOFiles: response?.responseOk?.files })
    }
  },[appState?.currentWorkOrder?.Customer?.id, appState?.currentWorkOrder?.Technician?.id, appState?.currentWorkOrder?.id, updateAppState])
  const getLibraryRecentList = useCallback(async () => {
    const date = moment().format('MM-DD-YYYY-HH:mm').toString();
    const techId = JSON.parse(localStorage.getItem("techpin"));
    const response = await getLibraryRecentItems(techId, date)
    if (response?.data) {
      setRecentItems(response?.data)
    }
  },[setRecentItems])
  useEffect(() => {
    getLibraryRecentList()
    getAllWOFiles()
  }, [getAllWOFiles, getLibraryRecentList])

  const [mryWorkorders, setMryworkorders] = useState([])
  const [mrycustomers, setMrycustomers] = useState([])
  const [mryeqps, setMryeqps] = useState([])
  const [mryWorkflws, setMryworkflws] = useState([])
  const handlegetallmemory = async () => {
    setIsprogress(true)
    try {
      const Result = await Getmemorydata();
      if (Result?.success) {
        setIsprogress(false)
      }
      setMryworkorders(Result?.data?.Memory_Board?.workorder)
      setMryeqps(Result?.data?.Memory_Board?.equipment)
      setMrycustomers(Result?.data?.Memory_Board?.customer)
      setMryworkflws(Result?.data?.Memory_Board?.workflow)
    } catch (error) { }
  }
  useEffect(() => {
    handlegetallmemory()
  }, []);
  const [isAttach, setIsAttach] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
 

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moddata, setModdata] = useState({});
  const [itemkey, setItemKey] = useState("")
  const handlemrywod = (e, val, item) => {
    setIsModalOpen(true)
    setItemKey(val)
    setModdata(item)
  }
  const [deleteitem, setDeleteitem] = useState({})
  const MryBoardupdate = useCallback(() => {
    setMryworkorders((mryWorkorders) =>
      mryWorkorders?.filter(mryitem => mryitem?.recent_view_id !== deleteitem)
    );
    setMryeqps((mryeqps) =>
      mryeqps?.filter(mryitem => mryitem?.recent_view_id !== deleteitem)
    );
    setMrycustomers((mrycustomers) =>
      mrycustomers?.filter(mryitem => mryitem?.recent_view_id !== deleteitem)
    );
    setMryworkflws((mryWorkflws) =>
      mryWorkflws?.filter(mryitem => mryitem?.recent_view_id !== deleteitem)
    );
  },[deleteitem]);
  useEffect(() => {
    if (deleteitem) {
      MryBoardupdate();
    }
  }, [MryBoardupdate, deleteitem]);

  const [memwo, setMwo] = useState(true)
  const [memgall, setMgall] = useState(true)
  const [memdocs, setMdocs] = useState(true)
  const [memrecview, setMrecview] = useState(true)
  const handlewotoggle = (e, val) => {
    e.stopPropagation()
    if (val === "memorydetails") {
      setMwo(!memwo)
    }
    if (val === "gallery") {
      setMgall(!memgall)
    }
    if (val === "docs") {
      setMdocs(!memdocs)
    }
    if (val === "recview") {
      setMrecview(!memrecview)
    }
  }
  const [isgallProgress, setIsGallprogress] = useState(false)
  const [isdoclProgress, setIsDocprogress] = useState(false)
  const [gallerydata,setGallerydata] = useState([])
  const [docdata,setDocdata] = useState([])
  const itemsPerPage = 10;
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsGallprogress(true)
      setIsDocprogress(true)
      const res = await AttchmentsByTech(1);
      if(res?.success)
      {
        setIsGallprogress(false)
         setIsDocprogress(false)
         const allAttachments = res?.data?.attachments?.workOrderAttachments || [];
         const images = allAttachments.filter(item => item.isImage=== true);
         const documents = allAttachments.filter(item => !item.isImage === true);
         setDocdata(documents)
        setGallerydata(images);
      }
      
    };
    fetchInitialData();
  }, []);
  const totalItems = 50;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPageGallery, setCurrentPageGallery] = useState(1);
  const [currentPageDocument, setCurrentPageDocument] = useState(1);
  const handlePageChange = async(newPage, section) => {
    if (section === "gallery") {
      setCurrentPageGallery(newPage);
      setIsGallprogress(true)
      const GallryAttchs = await AttchmentsByTech(newPage);
      const allAttachments = GallryAttchs?.data?.attachments?.workOrderAttachments || [];
      const images = allAttachments.filter(item => item.isImage);
      if(GallryAttchs?.data)
      {
        setIsGallprogress(false)
        setGallerydata(images)
      }
    } else if (section === "document"){
      setCurrentPageDocument(newPage);
      setIsDocprogress(true)
      const Docattachs = await AttchmentsByTech(newPage)
      const allAttachments = Docattachs?.data?.attachments?.workOrderAttachments || [];
      const documents = allAttachments.filter(item => !item.isImage);
      if(Docattachs?.data)
        {
          setIsDocprogress(false)
          setDocdata(documents)
        }
    }
  };
  const startIndexGallery = (currentPageGallery - 1) * itemsPerPage;
  const endIndexGallery = startIndexGallery + itemsPerPage;
  const startIndexDocument = (currentPageDocument - 1) * itemsPerPage;
  const endIndexDocument = startIndexDocument + itemsPerPage;
  const [isProgress, setIsprogress] = useState(false)
  const openImageInNewTab = async (document) => {
    let id = document?.id ? document?.id : document?.attachment_id;
    const output = await MediaAttachments(id);
    const base64PDF = output?.data?.content;
    const fileType = output?.data?.file_type;
    const blob = base64ToBlob(base64PDF, fileType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
    URL.revokeObjectURL(blobUrl);
  };
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters?.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };
  return (
    <>
      <div className="sticky-headers">
        <CommonHeader />
      </div>
      {isProgress ?
        <div className="progress-bar">
          <ProgressBar />
        </div>
        :
        <div className="file-room pagescroll">
          <div>
            {/* Memory Board Section */}
            <div className="memory-board">
              <h5 className=" cursor-p ml-2" onClick={(e) => handlewotoggle(e, 'memorydetails')}> <span><img className=" mb-1  mx-1" src={VectorEqpBlue} alt="" />
              </span>Memory Board ({(mryWorkorders?.length || 0) + (mryeqps?.length || 0) + (mryWorkflws?.length || 0) + (mrycustomers?.length || 0)})
              </h5>
              {memwo && (
                <>
                  {mryWorkorders?.filter(item => item)?.map((item) => (
                    <div className="memodiv cursor-p ml-3" key={item?.work_order_id}
                      onClick={(e) => handlemrywod(e, 'workorders', item)}
                    >
                      <span className=" d-flex">
                        <span>
                          <img className="mb-1 " src={blackfile} alt="" />
                        </span>
                        <h6 className=" mt-1" > {item?.name} #{item?.work_order_id}</h6>
                      </span>

                      <span className=" d-flex flex-column">
                        <span className=" ml-2">
                          <span>Customer Name:</span> <span className=" fltxt">{item?.customerName}</span>
                        </span>
                        <span className=" ml-2">
                          <span>Note:</span>
                          <span className=" fltxt"> {item?.note}</span>
                        </span>
                      </span>

                    </div>
                  ))}
                  {mrycustomers?.filter(item => item)?.map((item) => (
                    <div className="memodiv cursor-p ml-3" key={item?.work_order_id}
                      onClick={(e) => handlemrywod(e, 'customers', item)}
                    >
                      <span className=" d-flex">
                        <span>
                          <img className="mb-1 " src={mpeople} alt="" />
                        </span>
                        <h6 className=" mt-1  mx-1" >{item?.name}</h6>
                      </span>

                      <span className=" d-flex flex-column">
                        <span className=" ml-2">
                          <span>Customer:</span> <span className=" fltxt">{item?.customer_id} {item?.city} {item?.address} {item?.address_2} {item?.zip_code}</span>
                        </span>
                        <span className=" ml-2">
                          <span>Note:</span><span className=" fltxt">{item?.note}</span>
                        </span>
                      </span>

                    </div>
                  ))}
                  {mryeqps?.filter(item => item)?.map((item) => (
                    <div className="memodiv cursor-p ml-3" key={item?.work_order_id}
                      onClick={(e) => handlemrywod(e, 'eqps', item)}
                    >
                      <span className=" d-flex">
                        <span>
                          <img className="mb-1  mx-1" src={fileroomeqpicon} alt="" />
                        </span>
                        <h6 className=" mt-1" >{item?.equipment_type} by {item?.manufacturer_code}</h6>
                      </span>

                      <span className=" d-flex flex-column">
                        <span className=" ml-2">
                          <span>Model no:</span> <span className=" fltxt">{item?.model_number}</span> <span>Serial no:</span> <span className=" fltxt">{item?.serial_number}</span>
                        </span>
                        <span className=" ml-2">
                          <span>Note:</span><span className=" fltxt">{item?.note}</span>
                        </span>
                      </span>

                    </div>
                  ))}
                  {mryWorkflws?.filter(item => item)?.map((item) => (
                    <div className="memodiv cursor-p ml-3" key={item?.work_order_id}
                      onClick={(e) => handlemrywod(e, "workflws", item)}
                    >
                      <span className=" d-flex">
                        <span>
                          <img className="mb-1 mx-1" src={workflowfr} alt="" />
                        </span>
                        <h6 className=" mt-1" >{item?.name}</h6>
                      </span>

                      <span className=" d-flex flex-column">
                        <span className=" ml-2">
                          <span>Description:</span> <span className=" fltxt">Description</span>
                        </span>
                        <span className=" ml-2">
                          <span>Note:</span><span className=" fltxt">{item?.note}</span>
                        </span>
                      </span>

                    </div>
                  ))}
                </>
              )}

            </div>
          </div>
          {/* Gallery Section */}
          <div className="">
            <h6 className="cursor-p ml-2" onClick={(e) => handlewotoggle(e, "gallery")}>
              <span>
                <img className="mb-1 mx-1" src={galleryicon} alt="" />
              </span>
              <span className="ml-1">Gallery ({totalItems})</span>
            </h6>
            {memgall && (
              <>
                <div className="pagination-controls ml-3">
                  <button
                    onClick={() => handlePageChange(currentPageGallery - 1, "gallery")}
                    disabled={currentPageGallery === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1, "gallery")}
                      className={currentPageGallery === index + 1 ? "active" : ""}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPageGallery + 1, "gallery")}
                    disabled={currentPageGallery === totalPages}
                  >
                    Next
                  </button>
                </div>
                <div className="gallery-items ml-3">
                {isgallProgress === true ? (
                    <div className="progress-bar align-content-center justify-content-between" 
                    style={{
                      height: "100%",
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "center", 
                      marginLeft:"899px"
                    }}
                     >
                      <ProgressBar />
                    </div>
                  ) : (
                    gallerydata?.map((item, index) => (
                      <div key={index}  className="img-lib-grid d-flex flex-column text-decoration-underline">
                        {/* <span className=" text-center mb-1">{item?.attachment_name?.slice(0,10)}</span> */}
                        <img
                         className="img-sty cursor-p"
                            // style={{height:"80px",border: "1px solid black",cursor:"pointer",transition: "transform 0.3s ease",  }}
                          src={item?.attachment} alt="images" />
                      </div>
                    ))
                  )}
                </div>
              </>
            )}
          </div>
          {/* Document Section */}
          <div className=" mt-2">
            <h6 className="cursor-p ml-2" onClick={(e) => handlewotoggle(e, "docs")}>
              <span>
                <img className="mb-1 mx-1" src={documenticon} alt="" />
              </span>
              <span className="ml-1">Document ({totalItems})</span>
            </h6>
            {memdocs && (
              <>
                <div className="pagination-controls ml-3">
                  <button
                    onClick={() => handlePageChange(currentPageDocument - 1, "document")}
                    disabled={currentPageDocument === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1, "document")}
                      className={currentPageDocument === index + 1 ? "active" : ""}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPageDocument + 1, "document")}
                    disabled={currentPageDocument === totalPages}
                  >
                    Next
                  </button>
                </div>
                <div className="gallery-items ml-3">
                {isdoclProgress === true ? (
                    <div className="progress-bar" 
                    style={{
                      height: "100%",
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "center", 
                      marginLeft:"899px"
                    }}
                     >
                      <ProgressBar />
                    </div>
                  ) : (
                    docdata?.map((item, index) => (
                     <div key={index} className="img-lib-grid d-flex flex-column text-decoration-underline">
                      {/* <span  className=" text-center mb-1">{item?.attachment_name?.slice(0,10)}</span> */}
                     <img
                      onClick={() => openImageInNewTab(item)}
                        className="img-sty  cursor-p"
                        // style={{ height: "100px", cursor:"pointer",transition: "transform 0.3s ease",  }}
                       src={PDF} alt="docs" />
                   </div>
                  ))
                )}
                </div>
              </>
            )}
          </div>
          <div className="memory-board mt-2">
            <h6 className=" cursor-p ml-2" onClick={(e) => handlewotoggle(e, "recview")}> <span><img className=" mx-1" src={timeR} alt="" /></span><span>Recently Viewed Work Orders ({mryWorkorders?.length || 0})</span></h6>
            {memrecview && (
              <>
                {mryWorkorders?.map((item) => (
                  <div className="memodiv cursor-p ml-2" key={item?.work_order_id}
                    onClick={(e) => handlemrywod(e, item)}
                  >
                    <span className=" d-flex">
                      <span>
                        <img className="mb-1 " src={blackfile} alt="" />
                      </span>
                      <h6 className=" mt-1"> {item?.name} #{item?.work_order_id}</h6>
                    </span>
                    <span className=" d-flex flex-column">
                      <span className=" ml-2">
                        <span>Customer Name:</span> <span>{item?.customer_id}</span>
                      </span>
                      <span className=" ml-2">
                        <span>Note:</span><span> {item?.description}</span>
                      </span>
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      }
      <CommonFooter />
      <AttachmentModal
        Open={isAttach}
        Close={() => setIsAttach(false)}
        isloading={isLoad}
        isloadingClose={() => setIsLoad(false)}
        // data={attachmentData}
      />
      <ShareModal
        Open={isOpen}
        Close={() => setIsOpen(false)}

      />
      <CustomerModal
        Open={isCustomer}
        Close={() => setIsCustomer(false)}

      />
      <Filemodal
        isOpen={isModalOpen}
        isClose={() => setIsModalOpen(false)}
        moddata={moddata}
        itemkey={itemkey}
        MryBoardupdate={MryBoardupdate}
        setDeleteitem={setDeleteitem}
        openName={itemkey === "workorders" ? "Work Order" : itemkey === "eqps" ? "Equipment" : itemkey === "customers" ? "Customer" : "Workflow"}
        custId={moddata?.customer_id}
        note={moddata?.note}
        custaddrs={`${moddata?.city} ${moddata?.address} ${moddata?.address_2} ${moddata?.zip_code}`}
        woName={moddata?.name ? moddata?.name : moddata?.equipment_type ? `${moddata?.equipment_type} / ${moddata?.manufacturer_code}` : moddata?.name}
        woId={itemkey === "workflws" ? moddata?.work_order_workflow_id : itemkey === "eqps" ? moddata?.equipment_id : itemkey === "customers" ? moddata?.customer_id : moddata?.work_order_id}

      />
    </>
  );
});

export default Library;
