import React, { useState, useEffect, useContext } from "react";
import "./CommonHeader.css";
import EqpmntIcon from "../../../Assets/Icons/EqpmntBold.svg";
import notepad from "../../../Assets/Icons/notepad.svg";
import persons_main from "../../../Assets/Icons/persons_main.svg"
import User_main_fill1 from "../../../Assets/Icons/User_main_fill1.svg"
import clipboard from "../../../Assets/Icons/clipboard.svg";
import phone from "../../../Assets/Icons/Vectorphone.svg";
import ctx from "../../../Assets/Icons/ctx.svg";
import Log from "../../../Assets/Icons/Logs.svg";
import signout from "../../../Assets/Icons/ShareMove.svg";
import prsn from "../../../Assets/Icons/Person.svg";
import setting from "../../../Assets/Icons/Settings.svg";
import header_search from "../../../Assets/Icons/header_search.svg";
import dkm from "../../../Assets/Icons/dmode.svg";
import compIcon from "../../../Assets/Icons/favicon.ico";
import { NavigateFunction } from "../../../navigation/NavigationChild";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AppGlobalContextUpdate } from "../../../context/AppGlobalContext";
import {
  GlobalSearch,
  RollECard,
  getTechList,
} from "../../../../services/WorkOrdersService";
import fileRoom from "../../../Assets/Icons/Fileroom.svg";
import gsearch from "../../../Assets/Icons/gsearch.svg";
import arrow_main from "../../../Assets/Icons/arrow_main.svg";
import Filter_main from "../../../Assets/Icons/Filter_main.svg";
import { Modal } from "react-bootstrap";
import Dashboard from "../../../Assets/Icons/Dashboard.svg";
import Close from "../../../Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import moment from "moment";
import dolarsign from "../../../Assets/Icons/dolarsign.svg";
import ProgressBar from "../../../navigation/ProgressBar";

function CommonHeader(
  //   {theme={
  //   color_primary:'#4189CD'
  // }
  // },props
  props
) {
  const navigation = useNavigate();
  const [clickedNav, setClickedNav] = useState("");
  const [searchModal, setSearchModal] = useState(false);

  const location = useLocation();

  const [hideSearch, sethideSearch] = useState(true);

  const naviGatetoList = (to, params) => {
    sessionStorage.setItem('load', "Details")
    localStorage.setItem("headerStatus", JSON.stringify(to));
    setClickedNav(to);
    NavigateFunction({
      to: to,
      params: params,
      navigate: navigation,
    });
  };
  const onLogOut = () => {
    navigation("/");
  };

  const [userDetails, setUserDetails] = useState({});
  const getUserDetails = async () => {
    const userId = JSON.parse(localStorage.getItem("technicianid"));
    const response = await getTechList();
    const userObj = response?.data?.find((i) => i?.id === userId);
    setUserDetails(userObj);
  };

  useEffect(() => {
    getUserDetails();
    let status = JSON.parse(localStorage.getItem("headerStatus"));
    setClickedNav(location?.state === "login" ? "Dashboard" : status);

    if (location?.pathname === "/SearchTo") {
      sethideSearch(false);
    }

  }, [location]);

  const setTheFlag = () => {
    if (searchModal === false) {
      setSearchModal(true);
    } else if (searchModal === true) {
      setSearchModal(false);
    }
  };
  const [isRollModal, setIsRollModal] = useState(false);
  const HandleCloseModal = () => {
    setIsRollModal(false);
    setisResSuccess(false);
    setIsDateObj({});
    setIsResData([]);
  };
  const [isResSuccess, setisResSuccess] = useState(false);
  const [isResData, setIsResData] = useState([]);
  const [isDateObj, setIsDateObj] = useState({
    fromDate: "",
    toDate: "",
  });
  const HandleFromDate = (e) => {
    setisResSuccess(false);
    const { name, value } = e.target;
    setIsDateObj({ ...isDateObj, [name]: value });
  };
  const HandleSave = async () => {
    if (
      moment(isDateObj?.fromDate).format("MM-DD-yyyy") <
      moment(isDateObj?.toDate).format("MM-DD-yyyy")
    ) {
      let Res = await RollECard(
        moment(isDateObj?.fromDate).format("MM-DD-yyyy"),
        moment(isDateObj?.toDate).format("MM-DD-yyyy")
      );
      if (Res?.success) {
        setIsResData(Res?.data);
        setisResSuccess(true);
      }
    } else {
      alert("Please select correct Date Format");
    }
  };
  const navigateToChangePassword = () => {
    navigation("/changePassword");
  };
  const [colorPrimary, setColorPrimary] = useState("");
  const [companylogo, setcompanyLogo] = useState("");

  useEffect(() => {
    const storedColor = JSON.parse(localStorage.getItem("color_primary"));
    const storedImage = localStorage.getItem("companyLogo");
    setcompanyLogo(storedImage);
    const defaultColor = "#4189CD";
    const colorToSet = storedColor ? storedColor : defaultColor;
    setColorPrimary(colorToSet);
  }, []);
  // const handleMouseEnter = async () => {
  //   let Res = await GlobalSearch(isSearch);
  //   if (Res?.status === 200) {
  //     setDropdownVisible(true);
  //     setSearchData(Res?.data);
  //   }
  // };

  // const HandleGlobalSearch = (e) => {
  //   setIsSearch(e.target.value);
  // };

  // const handleMouseLeave = () => {
  //   setDropdownVisible(false);
  //   setIsSearch("");
  // };
  // const handleBlur = (event) => {
  //   if (!event.currentTarget.contains(event.relatedTarget)) {
  //     setDropdownVisible(false);
  //   }
  // };
  let userId = localStorage.getItem("userId");
  let TechPin = JSON.parse(localStorage.getItem("techpin"));
  let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));

  const HandleCNC = (to, key, item) => {
    NavigateFunction({
      to: to,
      params: {
        key,
        item,
      },
      navigate: navigation,
    })
  };

  const HandleNavTo = (to, params, data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params, data },
      navigate: navigation,
    });
  };
  const [isSearch, setIsSearch] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isProgress, setIsProgress] = useState(false)

  const handleInputChange = (e) => {
    setDropdownVisible(true);
    const value = e.target.value;
    setIsSearch(value);
    if (value?.length <= 0) {
      setSearchData({})
    }

    // if (debounceTimeout) clearTimeout(debounceTimeout);

    // const timeout = setTimeout(async () => {
    //   setIsProgress(true);
    //   if (value.trim() !== "") {
    //     let Res = await GlobalSearch(value);
    //     if (Res?.status === 200) {
    //       setSearchData(Res?.data);
    //       setDropdownVisible(true);
    //     }
    //   } else {
    //     setSearchData({});
    //   }
    //   setIsProgress(false);
    // }, 300);

    // setDebounceTimeout(timeout);
  };
  const [updatedState, setUpdatedState] = useState([])
  const handleKeyDown = async (e) => {
    if (e.key === "Tab") { 
      setDropdownVisible(true);

      if (debounceTimeout) clearTimeout(debounceTimeout);

      const timeout = setTimeout(async () => {
        setIsProgress(true);
        if (isSearch.trim() !== "") {
           let previoussearch = []
           const storedSearches = JSON.parse(localStorage.getItem("previoussearch")) || []
           if(!storedSearches?.includes(isSearch)){
            previoussearch?.push(isSearch)
           }
           let newOne = [...storedSearches,...previoussearch]
           setUpdatedState(newOne)
           localStorage.setItem("previoussearch", JSON.stringify(newOne));

          let Res = await GlobalSearch(isSearch);
          if (Res?.status === 200) {
            setSearchData(Res?.data);
            setDropdownVisible(true);
          }
        } else {
          setSearchData({});
        }
        setIsProgress(false);
      }, 300);

      setDebounceTimeout(timeout);
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".srch-container")) {
      setDropdownVisible(false);
      setFilterMenu(false)
      setSelectedFilters([])
      setIsSearch("")
      setSearchData({})
      // if(filterMenu === true){
      //   setFilterMenu(!filterMenu)
      // }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);
  // const navToWoDetls = (e, to, wdObj, isVal,woid,custid) => {

  //   updateAppState({ currentWorkOrder: wdObj });
  //   NavigateFunction({
  //     to: to,
  //     params: { wdObj, isVal,woid:wdObj?.work_order_id,custid:wdObj?.customer_id},
  //     navigate: navigation,
  //   });
  //   e.stopPropagation();
  // };
  // const [previoussrchArray,setPrevioussrchArray] = useState([])
  useEffect(() => {
    const storedSearches = JSON.parse(localStorage.getItem("previoussearch")) || [];
    setUpdatedState(storedSearches); 
  }, []);

  const [filterMenu, setFilterMenu] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([]);
  const HandleFilter = () => {
    setFilterMenu(!filterMenu)

  }
  const [filteredData, setFilteredData] = useState({});
  useEffect(() => {
    if (selectedFilters.length === 0) {
      setFilteredData(searchData);
    } else {
      const filteredDataObj = selectedFilters.reduce((acc, filter) => {
        acc[filter] = searchData[filter];
        return acc;
      }, {});
      setFilteredData(filteredDataObj);
    }
  }, [searchData, selectedFilters]);

  const toCamelCase = (str) => {
    return str.split(" ").map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
      ).join("");
  };

  const updateAppState = useContext(AppGlobalContextUpdate);
  const navToWoDetls = (e, to, i, isVal) => {
    updateAppState({ currentWorkOrder: i });
    NavigateFunction({
      to: to,
      params: { i, isVal, woid: i?.work_order_id, custid: i?.customer_id },
      navigate: navigation,
    });
    e.stopPropagation();
  };

  const HandleSearch = async (i) => {
    setIsSearch(i); 
    // setIsProgress(true); 
    const Res = await GlobalSearch(i); 
    if (Res?.status === 200) {
      setSearchData(Res?.data); 
      setDropdownVisible(true); 
    }
    // setIsProgress(false);
  }
   
  return (
    <>
      <Navbar
        className="nav-main "
        style={{
          backgroundColor: colorPrimary,
        }}
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand
            className="navLogo-L navLogo-L-adjusted"
            style={{ marginRight: "100px" }}
          >
            <span>
              <img
                className="comp-icon"
                src={companylogo ? companylogo : compIcon}
                alt="company icon"
              />
            </span>
          </Navbar.Brand>

          {/* <Navbar.Brand className="navText-L-ch">
            <div className="company-name-ch"> {HEADER_DETAILS.COMP_NAME} </div>
            <div className="company-info-ch mt-1">
              {HEADER_DETAILS.COMP_INFO}
            </div>
          </Navbar.Brand> */}

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto align-items-center w-100" navbarScroll>
              <Nav.Link
                className={`menu-txt ml-1 mx-2 ${clickedNav === "Dashboard" || clickedNav === null
                  ? "active-nav-button"
                  : ""
                  }`}
                style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                onClick={() => naviGatetoList("Dashboard", "")}
              >
                <div className="d-flex">
                  <div>
                    {" "}
                    <img height={20} src={Dashboard} alt="files" />
                  </div>
                  <div>
                    {" "}
                    <span className="header-ment-txt ">DashBoard</span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                className={`menu-txt ml-1  mx-2 ${`${clickedNav === "WorkOrders" ? "active-nav-button" : ""
                  }`}`}
                style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                // className={` ${
                //   [
                //     "/WorkOrders",
                //     "/WoDetails",
                //     `/WoDetails/${workOrderUrl}`,
                //     "/ViewEquipments",
                //     "/AddEquipments",
                //     "/NotesDetails",
                //     "/WorkOrderTable",
                //   ].includes(clickedNav)
                //     ? "active-nav-button"
                //     : ""
                // }`}
                onClick={() => naviGatetoList("WorkOrders", "")}
              >
                <div className="d-flex">
                  <div>
                    <img
                      className=" mb-1"
                      height={18}
                      src={clipboard}
                      alt="files"
                    />
                  </div>
                  <div>
                    <span className="header-ment-txt ">Work Orders</span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                className={`menu-txt ml-1  mx-2 ${`${clickedNav === "Estimate" ? "active-nav-button" : ""
                  }`}`}
                style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                onClick={() => naviGatetoList("Estimate", "")}
              >
                <div className="d-flex">
                  <div>
                    {" "}
                    <img height={18} src={dolarsign} alt="files" />
                  </div>
                  <div>
                    {" "}
                    <span className="header-ment-txt ">Estimates</span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                className={`menu-txt ml-1  mx-2 ${clickedNav === "Communications" ? "active-nav-button" : ""
                  }`}
                style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                onClick={() => naviGatetoList("Communications", "")}
              >
                <div className="d-flex">
                  <div>
                    {" "}
                    <img height={16} src={phone} alt="files" />
                  </div>
                  <div>
                    {" "}
                    <span className="header-ment-txt ">Communications</span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                className={`menu-txt ml-1  mx-2 ${clickedNav === "Library" ? "active-nav-button" : ""
                  }`}
                style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                onClick={() => naviGatetoList("Library", "")}
              >
                <div className="d-flex">
                  <div>
                    <img src={fileRoom} alt="files" />
                  </div>
                  <div>
                    <span className="header-ment-txt">File Room</span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                className={`menu-txt ml-1  mx-2 nav-link  ${clickedNav === "Customers" ? "active-nav-button" : ""
                  }`}
                style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                onClick={() => naviGatetoList("Customers", "")}
              >
                <div className="d-flex">
                  <div>
                    <img src={ctx} alt="files" />
                  </div>
                  <div>
                    {" "}
                    <span className="header-ment-txt">Customers</span>
                  </div>
                </div>
              </Nav.Link>
            </Nav>
            <div className="d-flex">
              {hideSearch && (
                <div
                  className="srch-container "
                  tabIndex={-1}
                  // onBlur={handleMouseLeave}
                  style={{ position: "relative", top: "12px", pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                  onClick={() => setTheFlag()}
                >
                  {isDropdownVisible ? (
                    <div className="close-icon-set cursor-p">
                      <img
                        src={header_search}
                        style={{ background: "white" }}
                        alt="gsearch"
                        width={15}
                      />
                    </div>
                  ) : (
                    <div className="close-icon-set cursor-p">
                      <img src={gsearch} alt="gsearch" width={15} />
                    </div>
                  )}
                  {isDropdownVisible ? (
                    <input
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="hdr-srch mt-1 form-control srch-bar"
                      placeholder="Name"
                      value={isSearch}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  ) : (
                    <input
                      onKeyDown={handleKeyDown}
                      onClick={() => { setDropdownVisible(true) }}
                      style={{
                        backgroundColor: colorPrimary,
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="hdr-srch mt-1 form-control srch-bar input-hover"
                      placeholder="Name"
                      value={isSearch}
                      onChange={handleInputChange}
                    />
                  )}
                  {isDropdownVisible && (
                    <div
                      className="drpdown-menu cursor-p"
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                    >
                      <div className=" d-flex flex-column">
                        <div className=" d-flex">
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <input
                              type="text"
                              value="Go to Tag Search"
                              onClick={() => naviGatetoList("SearchTo")}
                              style={{
                                paddingRight: "30px", // Space for the icon
                                textAlign: "left",
                                cursor: "pointer",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                height: "40px",
                                width: "200px",
                                paddingLeft: "10px",
                              }}
                              readOnly
                            />
                            <img
                              src={arrow_main}
                              alt="arrow"
                              style={{
                                position: "absolute",
                                right: "10px",
                                pointerEvents: "none", // Prevent clicking on the icon instead of input
                                height: "20px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </div>
                          <span
                            style={{ marginLeft: "10px", marginTop: "5px" }}
                          >
                            <img src={Filter_main} alt=""
                              onClick={HandleFilter}
                            />
                          </span>

                          {/* <span className="" style={{ marginLeft: "40px" }}><img src={arrow_main} alt="" />
                            <span style={{ marginLeft: "20px" }}><img src={Filter_main} alt="" />
                            </span>
                            </span> */}
                        </div>
                        {/* <span className="drp-tit">Previous Search</span>
                        <span className="link">Unit</span> */}
                      </div>
                      <div>
                        {filterMenu && (
                          <div
                            style={{
                              zIndex: 99900,
                              position: "absolute",
                              top: "50px",
                              right: "0px",
                              overflow: "hidden",
                              display: "flex",
                              flexDirection: "column",
                              gap: "3px",
                            }}
                          >
                            {["Previous Search", "Customers", "Contacts", "Equipment", "Work Orders"].map((type) => {
                              const camelCaseType = toCamelCase(type);
                              const isSelected = selectedFilters.includes(camelCaseType);

                              return (
                                <div
                                  key={type}
                                  style={{
                                    backgroundColor: isSelected ? "#808080" : "#f9f9f9",
                                    padding: "2px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: "1px solid #ddd",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (isSelected) {
                                      setSelectedFilters(selectedFilters.filter((item) => item !== camelCaseType)); // Remove from selection
                                    } else {
                                      setSelectedFilters([...selectedFilters, camelCaseType]); // Add to selection
                                    }
                                  }}
                                >
                                  <span>{type}</span>
                                  <span className="p-1">
                                    <img
                                      src={
                                        camelCaseType === "previousSearch"
                                          ? header_search
                                          : camelCaseType === "customers"
                                            ? persons_main
                                            : camelCaseType === "contacts"
                                              ? User_main_fill1
                                              : camelCaseType === "equipment"
                                                ? EqpmntIcon
                                                : camelCaseType === "workOrders"
                                                  ? notepad
                                                  : ""
                                      }
                                      alt="gsearch"
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>

                      {isProgress === true ? (
                        <ProgressBar />) :
                        (
                          <div>
                           {updatedState?.length > 0 && (
                                <div className=" d-flex flex-column">
                                   <span className="drp-tit">Previous Search</span>
                                   {
                                    updatedState?.map((i) => (
                                      <span className="link">
                                        <span 
                                          onClick={()=>HandleSearch(i)}
                                        >{i}</span>
                                      </span>
                                    ))
                                   }
                                </div>
                              )
                            }
                            {filteredData?.customers?.length > 0 && (
                              <div className=" d-flex flex-column">
                                <span className="drp-tit">Customers</span>
                                {filteredData?.customers?.map((i) => (
                                  <span
                                    className="link">
                                    <span
                                      onClick={() =>
                                        HandleCNC(
                                          `CreateNewcustomer/${0}/${i?.customer_id}/${userId}/${TechPin}/${CompanyId}/${0}`,
                                          i?.customer_id
                                        )
                                      }>{i?.name}</span>
                                  </span>
                                ))}
                              </div>
                            )}

                            {filteredData?.contacts?.length > 0 && (
                              <div className=" d-flex flex-column">
                                <span className="drp-tit">Contacts</span>
                                {filteredData?.contacts?.map((i) => (
                                  <span
                                    className="link">
                                    <span
                                      onClick={() =>
                                        HandleNavTo(
                                          `CreateNewcustomer/${i?.customer_id}`,
                                          i?.contact_id
                                        )
                                      }>{i?.name}</span>
                                  </span>
                                ))}
                              </div>
                            )}
                            {filteredData?.equipment?.length > 0 && (
                              <div className=" d-flex flex-column">
                                <span className="drp-tit">equipment</span>
                                {filteredData?.equipment?.map((i) => (
                                  <span
                                    className="link">
                                    <span
                                      onClick={() =>
                                        HandleNavTo(
                                          `AddEquipments/${i?.work_order_id}/${0}/${userId}/${TechPin}/${CompanyId}/0`, "equipdetails",
                                          i?.equipment_id
                                        )
                                      }>{i?.equipment_type}</span>
                                  </span>
                                ))}
                              </div>
                            )}
                            {filteredData?.workOrders?.length > 0 && (
                              <div className=" d-flex flex-column">
                                <span className="drp-tit">WorkOrders</span>
                                {filteredData?.workOrders?.map((i) => (
                                  <span
                                    className="link">
                                    <span
                                      onClick={(e) =>
                                        // HandleNavTo( `WoDetails/${i?.work_order_id}/${i?.customer_id}/${userId}/${TechPin}/${CompanyId}/0`,
                                        //   i?.work_order_id,"edit"
                                        // )
                                        navToWoDetls(
                                          e,
                                          `WoDetails/${i?.work_order_id}/${i?.customer_id}/${userId}/${TechPin}/${CompanyId}/0`,
                                          i,
                                          "details",

                                        )
                                      }>{i?.name}</span>
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}
              <div className="profile-main ml-2">
                <div className="profile-items ">
                  <Dropdown
                    align="end"
                    className=""
                    style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                    onClick={() => setSearchModal(false)}
                  // style={{
                  //   pointerEvents: searchModal === true ? "none" : "",
                  // }}
                  >
                    <Dropdown.Toggle
                      className="my-dropdown-toggle style-A"
                      style={{
                        backgroundColor: colorPrimary,
                      }}
                    >

                      <img src={(userDetails?.avatar !== undefined) ? userDetails?.avatar : compIcon} alt="profile pic" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="styles-B p-0">
                      <div className="styles-C">
                        <img
                          className="img-profile"
                          src={(userDetails?.avatar !== undefined) ? userDetails?.avatar : compIcon}
                          alt="profile pic"
                        />
                        <div className="modal-in-txt txt-align-c">
                          <span className="username">
                            {userDetails?.name &&
                              userDetails.name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                          </span>
                          <span className="context-email-t">Technician</span>
                          <span className="context-email">
                            {userDetails?.email}
                          </span>
                        </div>
                      </div>
                      <Dropdown.ItemText
                        className="hov"
                        onClick={navigateToChangePassword}
                      >
                        <div className="d-flex  styles-D ">
                          <div className="font-S-13 center-line-adj">
                            {" "}
                            <img
                              className="img-dd"
                              src={prsn}
                              alt="account details"
                            />
                          </div>
                          <div className=" mx-2 menunames">Change Password</div>
                        </div>
                      </Dropdown.ItemText>
                      <Dropdown.ItemText className="hov">
                        <div
                          className="d-flex styles-D "
                          onClick={() => naviGatetoList("Settings")}
                        >
                          <div className="font-S-13 center-line-adj">
                            <img
                              className="img-dd"
                              src={setting}
                              alt="setting"
                            />{" "}
                          </div>
                          <span className="mx-2 menunames center-line-adj">
                            {" "}
                            Settings{" "}
                          </span>
                        </div>
                      </Dropdown.ItemText>
                      <Dropdown.ItemText className="hov ">
                        <div className="d-flex styles-D">
                          <div className="center-line-adj">
                            <img
                              className="img-dd  "
                              src={Log}
                              alt="Dark Mode"
                            />{" "}
                          </div>

                          <span className="mx-2 menunames"> Logs</span>
                        </div>
                      </Dropdown.ItemText>
                      <Dropdown.ItemText className="hov ">
                        <div className="d-flex styles-D">
                          <div className="center-line-adj">
                            <img
                              className="img-dd  "
                              src={dkm}
                              alt="Dark Mode"
                            />{" "}
                          </div>

                          <span className="mx-2 menunames">
                            {" "}
                            Enable Dark Mode
                          </span>
                        </div>
                      </Dropdown.ItemText>
                      {/* <Dropdown.ItemText
                        className="hov "
                        onClick={() => HandleRollModal()}
                      >
                        <div className="d-flex styles-D ">
                          <div className="center-line-adj">
                            {" "}
                            <img
                              className="img-dd"
                              src={dkm}
                              alt="Dark Mode"
                            />{" "}
                          </div>
                          <span className="mx-2  menunames">
                            {" "}
                            Roll E-Cards
                          </span>
                        </div>
                      </Dropdown.ItemText> */}
                      <Dropdown.ItemText className="hov ">
                        <div
                          className="d-flex styles-D"
                          onClick={() => onLogOut()}
                        >
                          <div>
                            {" "}
                            <img
                              className="img-dd"
                              src={signout}
                              alt="sign out"
                            />{" "}
                          </div>
                          <span className="mx-2 mt-1 menunames">
                            {" "}
                            Sign Out{" "}
                          </span>
                        </div>
                      </Dropdown.ItemText>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={isRollModal} onHide={() => HandleCloseModal()} centered>
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <span className="note-add-text"> Roll E-Cards</span>
          <img
            src={Close}
            alt="close"
            className="modal-header-icon"
            onClick={() => HandleCloseModal()}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3">
            <div>
              <span>From Date</span>
              <input
                className="mx-1"
                type="date"
                id="from-date1"
                name="fromDate"
                value={isDateObj?.fromDate}
                onChange={(e) => HandleFromDate(e)}
                max={new Date()?.toISOString()?.slice(0, 10)}
              />
            </div>
            <div>
              <span>To Date</span>
              <input
                className="mx-1"
                type="date"
                name="toDate"
                value={isDateObj?.toDate}
                onChange={(e) => HandleFromDate(e)}
                max={new Date()?.toISOString()?.slice(0, 10)}
              />
            </div>
          </div>
          {isResData?.length > 0 && (
            <div className="mt-2">
              <spn style={{ color: "#03942a" }}>
                Below Work orders rolled successfully
              </spn>
            </div>
          )}
          <div className="Work-order-rolls-body">
            {isResData?.length > 0
              ? isResData?.map((it) => <div>Work order # {it}</div>)
              : isResSuccess && (
                <div className="centerings err-data mt-3">No Data Found</div>
              )}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-styles">
          <div
            className="modal-footer-save-cancel-btns"
            // onClick={props.isSubmit}
            style={{
              margin: 0,
              padding: "0px 50px 0px 50px",
              pointerEvents:
                isDateObj?.fromDate?.length > 0 && isDateObj?.toDate?.length > 0
                  ? ""
                  : "none",
            }}
            onClick={() => HandleSave()}
          >
            Roll-E-Cards
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => HandleCloseModal()}
          >
            Cancel
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommonHeader;
