import React, { forwardRef, useContext, useEffect, useState } from "react";
import "./Equipments.css";
import { Card } from "react-bootstrap";
import add from "../../common/Assets/Icons/add.svg";
import AttachmentModal from "../workorders/AttachmentModal";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import { useLocation, useNavigate } from "react-router-dom";
import { ReferenceId } from "../../common/components/EnumRefType";
import {
  AppGlobalContextUpdate,
} from "../../common/context/AppGlobalContext";
import {
  getWorkOrderById,
  GetAttchMentByID,
  addTags,
  deleteTags,
} from "../../services/WorkOrdersService";
import { WORK_ORDER_CONSTANTS } from "../../common/components/Constants";
import AddTag from "../workorders/addtag/AddTag";
import DeleteTags from "../workorders/addtag/DeleteTags";
import EquipmentInfoModal from "./Equipment Info Modal/EquipmentInfoModal";
import EqpmntIcon from "../../common/Assets/Icons/EqpmntBold.svg";
import EquipmentCard from "./EquipmentCard";

const Equipments = forwardRef(function Equipments(props, ref) {
  const cardEquipments = props?.Alleqpdata
  const woid = 0
  const techPin = JSON.parse(localStorage.getItem("techpin"));
  const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
  const navigation = useNavigate();
  const location = useLocation();
  const setrForTags = useState([]);
  const [LocStrId, setLocStrId] = useState(0);
  const [isEqpmntToggle, setIsEqpmntToggle] = useState(true);
  const handleEqpmntToggle = () => {
    setIsEqpmntToggle(!isEqpmntToggle);
  };
  const [eqptgl, setEqptgl] = useState("");

  const navToPage = (to, params) => {
    let WoNo = props?.isWoid
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("WorkId", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: params,
      navigate: navigation,
    });
  };

  const updateAppState = useContext(AppGlobalContextUpdate);

  useEffect(() => {
    setLocStrId(localStorage.getItem("userId"));


    getAllEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.tokenData]);
  const getAllEquipments = async () => {
    let TOKEN =
      JSON.parse(localStorage.getItem("authToken")) === null
        ? props?.tokenData
        : JSON.parse(localStorage.getItem("authToken"));
    // let LocData = window.location.href.split("/");
    const data = await getWorkOrderById(
      +props?.isWoid,
      props?.custid,
      +props?.techid,
      TOKEN
    );
    if (data && data?.WorkOrderDetails && data?.WorkOrderDetails?.equipments) {
      updateAppState({
        currentEquipments: data?.WorkOrderDetails?.equipments,
        currentWorkOrder: data?.WorkOrderDetails,
      });
      setrForTags(data?.WorkOrderDetails?.tags);
    }
  };

  const [workOrder] = useState(location?.state);
  const [IsAttach, setIsAttach] = useState(false);
  const [currentTagEqp, setCurrentTagEqp] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [isData, setIsData] = useState([]);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  // const [cardEquipments, setcardEquipments] = useState([])
  const [eqpObj, setEqpObj] = useState(null);
  
  const HandleInfoModal = (cardEquipments, e) => {
    e.stopPropagation();
     setEqpObj(cardEquipments);
    setInfoModal(true);
  };
 

  const HandleViewImg = async (value) => {
    setIsAttach(true);
    setIsLoad(false);
    // let path = window.location.href.split("/");
    let attchId = value.id;
    let custId = value.customer_id;
    let WorkId = +props?.isWoid;
    let eqId = value.equipment_id;
    let techId = +props?.techid;
    let companyId = value.company_id;

    const response = await GetAttchMentByID(
      attchId,
      custId,
      WorkId,
      eqId,
      techId,
      companyId
    );
    if (response.success) {
      setIsLoad(true);
      setIsData(response.data);
    }
  };
  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.equipment,
    uuid: "",
  });

  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };

  const addEquTag = (data, e) => {
    setTagInpObj({
      name: "",
      color: "#CCCCCC",
      referenceType: ReferenceId.equipment,
      uuid: "",
});
    e.stopPropagation();
    setCurrentTagEqp(data);
    setIsTagOpen(true);
  };

  const closeAddTags = () => {
    setCurrentTagEqp({});
    setIsTagOpen(false);
  };
  const[eqpcdtags,setEqpcdtags]= useState([])
  const HandleTagSubmit = async () => {
    // let Work_order_id = window.location.href.split('/')
    let equip_id = currentTagEqp?.equipment_id
    let newTag = {
      referenceType: tagInpObj?.referenceType,
      tag_id: 0,
      referenceId: equip_id,
      name: tagInpObj?.name,
      color: tagInpObj?.color,
      uuid_id: "",
      work_order_id:+props?.isWoid,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newTag);
    setEqpcdtags(Res?.data)
    if (Res?.success) {
      let newOne = {
        referenceType: Res?.data?.tag?.reference_type,
        color: Res?.data?.tag?.color,
        id: Res?.data?.tag?.tag_id,
        name: Res?.data?.tag?.tag_data,
      }
      HandleTagUpdat(newOne)
      setIsTagOpen(false);
      setTagInpObj({
        name: "",
        color: "#CCCCCC",
        referenceType: ReferenceId.work_order,
        uuid: "",
  });
    }
  }
  const  HandleTagUpdat = (newOne)=> {
    let newArr = cardEquipments?.filter((eqpItem) => 
      eqpItem?.equipment_id === currentTagEqp?.equipment_id
    );
    newArr[0]?.tags?.push(newOne)
  }
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(null);
  const handleDeleteModal = ( data,e,key) => {
    e.stopPropagation();
    // setIsTagObj(obj);
    setCurrentTagEqp(key)
    setDeleteTag(data);
    setShowDeleteTag(true);

  };
  const handleDeleteTag = async (tagToDelete) => {
    const response = await deleteTags(tagToDelete?.id);
   
    if(response.success)
    {
      HandleTagDel()
      setShowDeleteTag(false)
      // setDeleteTag(null)
      
    }
   
  };
  const  HandleTagDel = ()=> {
    let newArr = cardEquipments?.filter((eqpItem) => 
      eqpItem?.equipment_id === currentTagEqp?.equipment_id
    );
    let newTagData =newArr[0]?.tags?.filter((i)=>i?.id !==deleteTag?.id)
    newArr[0].tags = newTagData
   

  }
  const getEquipmentStatus = (status) => {
    if (status === "1" || status === "true" || status === true) {
      return "active";
    } else {
      return "inactive";
    }
  };
  const loc1 = +props?.isWoid
  return (
    <>
      <Card className="det-txt-eq " ref={ref}>
        <div className="clp_header">
          <img
          onClick={() =>{if(props.OpenCust === false || props.OpenCust ===undefined) navToPage(`ViewEquipments/${props?.isWoid}/${props?.custid}/${props?.techid}/${props?.techid1}/${props?.compid}`,cardEquipments)}}
            src={EqpmntIcon}
            className="flt-l ml-2 mb-1 mr-1 cursor-p"
            style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
            width={22}
            alt="details"
          />
          <span className="mx-1 cursor-p " onClick={() => handleEqpmntToggle()}>
            {WORK_ORDER_CONSTANTS.EQUIPMENTS} ({cardEquipments?.length?cardEquipments?.length:0})
          </span>

          <div className="myDiv1">
            <span className={LocStrId ? "normalimg" : "pointr-events blurimg"}>
              {(props?.custid > 0 || loc1 > 0) && (
                <img
                  style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
                  className="cursor-p center-line-ad "
                  onClick={() =>{if(props.OpenCust === false || props.OpenCust ===undefined) navToPage(`EquipmentListPage/${props.isWoid}/${props.custid}/${props.techid}/${props.techid1}/${props.compid}`,)}}
                  src={add}
                  width={14}
                  alt="add note"
                />
               )} 
            </span>
          </div>
        </div>
        {isEqpmntToggle ? (
          <div className="clp_body">
            {eqptgl === "eqptl" ? (
              ""
            ) : cardEquipments?.length > 0 ? (
              <EquipmentCard
                cardEquipments={cardEquipments}
                workOrder={workOrder}
                eqpcdtags={eqpcdtags}
                userId={techPin}
                techPin={techPin}
                woid={woid}
                companyCode={companyCode}
                // isLoading={isLoading}
                navToPage={navToPage}
                HandleInfoModal={HandleInfoModal}
                getEquipmentStatus={getEquipmentStatus}
                addEquTag={addEquTag}
                handleDeleteModal={handleDeleteModal}
                HandleViewImg={HandleViewImg}
                LocStrId={LocStrId}
                OpenCust={props.OpenCust}
              />
            ) : (
              <div>
                <span className="notes-card-dscrptn ">
                  {WORK_ORDER_CONSTANTS.EQPMNT_CARD}
                </span>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </Card>
      <AddTag
        Open={isTagOpen}
        name={tagInpObj.name}
        color={tagInpObj.color}
        HandleTagInputs={changeTagEvent}
        workOrderId={"Equipment #"+currentTagEqp?.equipment_id}
        isClose={() => closeAddTags()}
        isSubmit={() => HandleTagSubmit()}
      />
      <DeleteTags
        headid={currentTagEqp?.equipment_id}
        deleteTag={() => handleDeleteTag(deleteTag)}
        handleClose={() => setShowDeleteTag(false)}
        data={deleteTag}
        name={"Equipment"}
        isOpen={showDeleteTag}
      />
      <AttachmentModal
        Open={IsAttach}
        Close={() => setIsAttach(false)}
        data={isData}
        isloading={isLoad}
        isloadingClose={() => setIsLoad(false)}
      />
      <EquipmentInfoModal
        open={infoModal}
        close={() => setInfoModal(false)}
        equipment={eqpObj}
        navToPage={navToPage}
      />
    </>
  );
});

export default Equipments;
