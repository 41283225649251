import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./ShareModalStyles.css";
import CryptoJS from "crypto-js";
import { AiOutlineClose } from "react-icons/ai";
import Whatsapp from "../../Assets/Images/whatsapp.png";
import Facebook from "../../Assets/Images/facebook.jpg";
import Instagram from "../../Assets/Images/insta.jpg";
import Twitter from "../../Assets/Images/twitter.png";
import Mail from "../../Assets/Images/mail.png";
import { SHARE_MODAL_VALUES } from "../Constants";
import { SHARE_MODAL_VAL } from "../Values";
import QRCode from "react-qr-code";
import Scannar from "./Scannar";
export default function ShareModal(props) {
  const [LocPath, setLocPath] = useState(0);
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    let userId = localStorage.getItem("userId");

    let TechPin = JSON.parse(localStorage.getItem("techpin"));
    let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));
    const secretKeyforPin = SHARE_MODAL_VAL.secretKey;

    const encryptedPin = CryptoJS.AES.encrypt(
      TechPin,
      secretKeyforPin
    ).toString();
    const encodedPass = encryptedPin.replace(/\//g, "_").replace(/\+/g, "-");
    setLocPath(
      `${window.location.protocol + "//" + window.location.host}/#/WoDetails/${
        props?.data?.id
      }/${props?.data?.Customer?.id}/${userId}/${encodedPass}/${CompanyId}`
    );
  }, [props]);

  const HandleCopyText = (textValue) => {
    navigator.clipboard.writeText(textValue);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  };
  return (
    <div>
      <Modal show={props.Open} onHide={props.Close} centered>
        <Modal.Header className="Sahre-modal-header">
          <div>{SHARE_MODAL_VALUES.SHARE_VALUE}</div>
          <div>
            <AiOutlineClose onClick={props.Close} className="Close-icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="share-modal-body">
            <div>
              <img src={Whatsapp} alt="Wahtsapp" />
              <span>{SHARE_MODAL_VALUES.SAHRE_EMBED}</span>
            </div>
            <div>
              <img src={Mail} alt="Mail" />
              <span>&nbsp;&nbsp;&nbsp;{SHARE_MODAL_VALUES.SHARE_MAIL}</span>
            </div>
            <div>
              <img src={Whatsapp} alt="Wahtsapp" />
              <span>{SHARE_MODAL_VALUES.SHARE_WHATSAPP}</span>
            </div>
            <div>
              <img src={Facebook} alt="facebook" />
              <span>{SHARE_MODAL_VALUES.SHARE_FACEBOOK}</span>
            </div>
            <div>
              <img src={Instagram} alt="instagram" />
              <span>{SHARE_MODAL_VALUES.SHARE_TWITTER}</span>
            </div>
            <div>
              <img src={Twitter} alt="twitter" />
              <span>{SHARE_MODAL_VALUES.SHARE_KAKO}</span>
            </div>
          </div>
          <div
            className="mt-4 mx-4 p-2 line-align-bet"
            style={{ border: "1px solid gray", borderRadius: "5px" }}
          >
            <div
              style={{
                color: "#37393C",
                fontSize: "12px",
                fontFamily: "SofiaProMedium",
                width: "80%",
                height: "max-height",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {LocPath}
            </div>
            <div>
              {" "}
              <button id="clear" onClick={() => HandleCopyText(LocPath)}>
                <span>{isCopy ? "Copied" : SHARE_MODAL_VALUES.SHARE_COPY}</span>
              </button>
            </div>
          </div>
          <div>
            <Scannar ScanData={LocPath} />
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <input type="checkbox" />
          <span>{SHARE_MODAL_VALUES.SHARE_START_AT}</span>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
