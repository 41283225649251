import React, { useEffect, useRef, useState, useCallback } from "react";
import "./WoDetails.css";
import VectorEqpBlue from "../../../common/Assets/Images/VectorEqpBlue.svg";
import MemoryIcon from "../../../common/Assets/Icons/Memory Board Icon.svg";
import CommonHeader from "../../../common/framework/header/HeaderCommon/CommonHeader";
import { Container, Card, Row, Col } from "react-bootstrap";
import ps from "../../../common/Assets/Icons/project_status.svg";
import searchicon from "../../../common/Assets/Icons/searchicon.svg";
import Save from "../../../common/Assets/Images/Unionsave.svg";
import discard from "../../../common/Assets/Icons/discord.svg";
import Add_round from "../../../common/Assets/Icons/Add_round.svg";
import info from "../../../common/Assets/Icons/Subtract.svg";
import WorkFlow from "../workflow/WorkFlow";
import close from "../../../common/Assets/Icons/Close_round_main.svg";
import plusIcon from "../../../common/Assets/Icons/add.svg";
import saveIcon from "../../../common/Assets/Icons/saveB.svg";
import prflPic from "../../../common/Assets/Images/profile.png";
import { useLocation, useNavigate } from "react-router-dom";
import Equipments from "../../equipments/Equipments";
import WoDetailsCommunication from "../../communications/WoDetailsCommunication";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Logs from "../../Logs/Logs";
import CryptoJS from "crypto-js";
import ShareIcon from "../../../common/Assets/Icons/shareIcon.svg";
import MemoryModal from "../../../common/components/memorymodal/MemoryModal";
import edit from "../../../common/Assets/Icons/pencil_filled.svg";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import {
  addTags,
  deleteTags,
  getWorkOrderById,
  getCustomers,
  createWorkOrder,
  getCreateAdditionalFields,
  HandleWorkFlowComplete,
  GetMemory,
  WoContacts,
} from "../../../services/WorkOrdersService";
import {
  CallTypesFetch,
  woGtFldsTypeSett,
  woStsSettFldStpGt,
} from "../../../services/SettingsServices";
import AddTag from "../addtag/AddTag";
import DeleteTags from "../addtag/DeleteTags";
import {
  TABLE_COLUMNS_NAME,
  WORK_ORDER_CONSTANTS,
  TAGS_MODAL_VALUES,
  CUST_PRTL,
  Wo_Workflow,
  LOGIN_CONST,
} from "../../../common/components/Constants";
import moment from "moment/moment";
import ExtraFields from "../../Extra Fields/ExtraFields";
import MediaCenter from "../../mediacenter/MediaCenter";
import { updateWorkOrderStatus } from "../../../services/WorkOrdersService";
import AlrtAddnlFieldsVal from "./AlrtAddnlFieldsVal";
import WODetailsSideBar from "./WODetailsSideBar";
import delIcon from "../../../common/Assets/Icons/trash_main.svg";
import WORightSideBar from "./WORightSideBar";
import ReactDatePicker from "react-datepicker";
import AddTechList from "./AddTechList";
import { ReferenceId } from "../../../common/components/EnumRefType";
import DeleteWOModal from "./DeleteWOModal";
import AIIcon from "../../../common/Assets/Images/AI.png";
import AIModal from "../../../common/components/AIModal/AiModal";
import { WorkOrderComplete } from "../workflow/WokOrderComplete";
import ReqWfAndStepModal from "../workflow/ReqWfAndStepModal";
import ProgressBar from "../../../common/navigation/ProgressBar";
import MemoryblueModal from "../../../common/components/memorymodal/MemoryblueModal";
import ShareWoModal from "../../../common/components/sharemodal/ShareWoModal";
import { ExtraFieldsTypeValids } from "../../equipments/AddEquipment/PreviousWorkOrdersEquipments/ExtraFieldsTypeValids";
import { GetCustExtraDetailsById } from "../../../services/AddCusEqService";
import { SHARE_MODAL_VAL } from "../../../common/components/Values";
import { authLogin } from "../../../services/AuthService";

function WODetails(props) {
  const navigation = useNavigate();
  const location = useLocation();
  const [workOrder, setWorkOrder] = useState(null);
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(null);
  const [extraErrorList, setExtraErrorList] = useState([]);
  const [woDetT, setWoDetT] = useState("");
  const [customerdata, setCustomerdata] = useState([]);
  const [filteredCustData, setFilteredCustData] = useState([]);
  const [workOrderType, setworkOrderType] = useState([]);
  const [selectedcustAddress, setselectedcustAddress] = useState({});
  const [error, setError] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isInfo, setIsInfo] = useState(true);

  const [objectValues, setobjectValues] = useState([
    {
      packcolumn: "",
      control_type: "",
      required: true,
      order: 0,
      field_id: 0,
      value: "",
      characterlength: "",
      active: false,
    },
  ]);
  const [creatingFields, setcreatingFields] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updatedStatusName, setupdateStatusName] = useState("");
  const [addlnFlds, setAddlnFlds] = useState(false);
  const [isAddTechOpen, setIsAddTechOpen] = useState(false);
  const [delWOmodal, setDelWOmodal] = useState(false);
  const [shareWOModal, setShareWOModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [custName, setCustName] = useState({});
  const [custId, setCustId] = useState(0);
  const HandleCloseMemory = () => {
    setIsMemory();
  };
  const woid = window.location.href.split("/")[5];
  const [getmemory, setgetmemory] = useState("");
  const [mrydlt, setMrydlt] = useState(null);
  const descvalue = getmemory?.note;
  const [isMemory, setIsMemory] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [memobj, setMemobj] = useState({});
  const [mryblue, setMryblue] = useState(false);
  const [itemkey, setItemkey] = useState("");
  const updateMemoryBoardStatus = (workOrderId) => {
    workOrder.MemoryBoard = workOrderId;
  };
  const HandleMemory = async (type, e, item) => {
    e.stopPropagation();
    if (type === "black") {
      setIsMemory(true);
      setIsMemoryObj(item);
      e.stopPropagation();
      setIsMemory(true);
      let items = { viewtype: 10, Reftype: 100, RefId: item?.id };
      setIsMemoryObj(items);
    } else {
      setMryblue(true);
      setItemkey(type);
      let items = { viewtype: 10, Reftype: 100, RefId: item?.id };
      setIsMemoryObj(items);
      setMemobj(item);
      setIsMemProgress(true);
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
      );
      if (Result?.success) {
        setIsMemProgress(false);
      }
      setgetmemory(Result?.data);
      setMrydlt(Result?.data?.recent_view_id);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [selectedAMPM, setSelectedAMPM] = useState("AM");
  const [contacId, setContacId] = useState(0);
  const wotime = `${selectedHour}:${selectedMinute}:${selectedAMPM}`;
  const [details, setDetails] = useState({
    custId: "",
    won: "",
    wot: "",
    contid: "",
    wos: "",
    calltype: "",
    crmdate: startDate,
    time: "",
    descption: "",
    reason: "",
    techie: [],
  });

  const handleModalSave = (responseLabel, responseValue) => {
    for (let index = 0; index < responseLabel.length; index++) {
      if (responseLabel[index] === "reason" && responseValue[index] !== "") {
        details.reason = responseValue[index];
      }
      if (
        responseLabel[index] === "description" &&
        responseValue[index] !== ""
      ) {
        details.descption = responseValue[index];
      }
      if (
        responseLabel[index] === "work order name" &&
        responseValue[index] !== ""
      ) {
        details.won = responseValue[index];
      }
    }
  };
  const handleModalDiscard = () => {};
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const HandleInputsWo = (e) => {
    const { name, value } = e.target;
    if (name === "custId") {
      const getcustomerObject = customerdata.find(
        (u) => u?.id === parseInt(value)
      );
      setselectedcustAddress(getcustomerObject ? getcustomerObject : "");
    }
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const [delTagData, setDelTagData] = useState({});
  const handleDeleteModal = (e, data, item) => {
    e.stopPropagation();
    setDeleteTag(item);
    setDelTagData(data);
    setShowDeleteTag(true);
  };
  const handleDeleteTag = async (it, item) => {
    const response = await deleteTags(it?.id);
    if (response?.success) {
      // getWorkOrders();
      let tagdata = item.tags.filter((i) => i?.id !== it?.id);
      item.tags = tagdata;
      // it.workOrderId.tags = it?.workOrderId?.tags?.filter(
      //   (i) => i?.id !== it?.id
      // );

      setShowDeleteTag(false);
      setDeleteTag({});
    }
  };

  const contentRefs = useRef([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.dataset.index);
          setActiveTab(index);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    contentRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [activeTab]);
  const [NotesArray, setNotesArray] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
  const [ismemProgress, setIsMemProgress] = useState(false);
  let shareid = window?.location?.href?.split("/")[10]
  const [isWoid, setIsWoID] = useState(0);
  const [wftoken, setWfToken] = useState("");
  const [custid, setCustid] = useState(null);
  const [prContactName, setPrContactName] = useState("");
  let techid = JSON?.parse(localStorage.getItem("techpin"));
  let techid1 = JSON?.parse(localStorage.getItem("techpin"));
  let compid = JSON.parse(localStorage.getItem("CompanyCode"))
  // const WorkOderDetails = useCallback(async (workId, custId) => {
  //   let work_order_id
  //   let customer_id
  //   let token
  //   let sharetechid
  //   if (+window?.location?.href?.split("/")[10] === 1) {
  //     work_order_id = window?.location?.href?.split("/")[5]
  //     customer_id = window?.location?.href?.split("/")[6]
  //     sharetechid = window?.location?.href?.split("/")[7];
  //     setIsWoID(work_order_id)
  //     setCustid(customer_id)
  //       const secretKeyforPin = SHARE_MODAL_VAL.secretKey;
  //       let locadata = window?.location?.href?.split("/");
  //       let decryptwoslashPin = locadata[8]
  //         ?.replace(/_/g, "/")
  //         .replace(/-/g, "+");
  //       const decryptedPin = CryptoJS.AES.decrypt(
  //         decryptwoslashPin,
  //         secretKeyforPin
  //       ).toString(CryptoJS.enc.Utf8);
       
  //       const data = await authLogin(
  //         locadata[7],
  //         decryptedPin,
  //         locadata[9],
  //         1,
  //         1,
  //         1,
  //         1,
  //         LOGIN_CONST.APP_TYPE
  //       );
  //       if (data?.success) {
         
  //         token = data?.data?.token;
  //         setWfToken(data?.data?.token);
  //       }
  //   }
  //   else {
  //     work_order_id = (workId ? workId :localStorage?.getItem("reswoid")?localStorage?.getItem("reswoid"): location?.state?.woid)
  //     customer_id =  (custId ? custId :  localStorage?.getItem("rescustid")? localStorage?.getItem("rescustid"): location?.state?.custid)
  //     token = null;
  //     sharetechid = JSON.parse(localStorage.getItem("techpin"));
  //     setIsWoID(work_order_id)
  //     setCustid(customer_id)
  //   }
  //   if (work_order_id <= 0 && customer_id <= 0) {
  //     addflds()
  //     setIsVal("create");
  //     setIsProgress(false);
  //   }
  //   try {
  //     setIsProgress(true)
  //     const Result = await getWorkOrderById(work_order_id, sharetechid, customer_id, token);
  //     if (Result?.success) {
  //       setIsProgress(false)
  //       setSelectedwoTechs(Result?.WorkOrderDetails?.TechniciansData)  
  //       setPrContactName(Result?.WorkOrderDetails?.contact)
  //       setCustid(workOrder?.Customer?.id)
  //       setSelectedCust(workOrder?.Customer?.id)
  //       setNotesArray(Result?.notes || []);
  //       setWorkOrder(Result?.WorkOrderDetails);
  //       setupdateStatusName(Result?.WorkOrderDetails?.status);
  //       setWoeqpdet(Result?.WorkOrderDetails?.equipments);
  //       if (Result?.WorkOrderDetails?.additional_fields?.length > 0) {
  //         setAdditionalFields(Result?.WorkOrderDetails?.additional_fields);
  //       }
  //     }
  //   }
  //   catch {
  //   }
  // }, [location?.state?.custid, location?.state?.woid, workOrder?.Customer?.id])
  const WorkOderDetails = useCallback(async (workId, custId) => {
    setIsLoading(true); 
    try {
      let work_order_id, customer_id, token, sharetechid;
      if (+window?.location?.href?.split("/")[10] === 1) {
        work_order_id = window?.location?.href?.split("/")[5];
        customer_id = window?.location?.href?.split("/")[6];
        sharetechid = window?.location?.href?.split("/")[7];
        setIsWoID(work_order_id);
        setCustid(customer_id);
        const secretKeyforPin = SHARE_MODAL_VAL.secretKey;
        const locadata = window?.location?.href?.split("/");
        const decryptwoslashPin = locadata[8]
          ?.replace(/_/g, "/")
          .replace(/-/g, "+");
        const decryptedPin = CryptoJS.AES.decrypt(
          decryptwoslashPin,
          secretKeyforPin
        ).toString(CryptoJS.enc.Utf8);
  
        const data = await authLogin(
          locadata[7],
          decryptedPin,
          locadata[9],
          1,
          1,
          1,
          1,
          LOGIN_CONST.APP_TYPE
        );
        if (data?.success) {
          token = data?.data?.token;
          setWfToken(data?.data?.token);
        }
      } else {
        // Logic for local data
        work_order_id = workId ?? localStorage?.getItem("reswoid") ?? location?.state?.woid;
        customer_id = custId ?? localStorage?.getItem("rescustid") ?? location?.state?.custid;
        token = null;
        sharetechid = JSON.parse(localStorage.getItem("techpin"));
        setIsWoID(work_order_id);
        setCustid(customer_id);
      }
  
      if (work_order_id <= 0 && customer_id <= 0) {
        addflds();
        setIsVal("create");
        setIsProgress(false);
        setIsLoading(false); 
        return;
      }
  
      const Result = await getWorkOrderById(work_order_id, sharetechid, customer_id, token);
      if (Result?.success) {
        setSelectedwoTechs(Result?.WorkOrderDetails?.TechniciansData);
        setPrContactName(Result?.WorkOrderDetails?.contact);
        setCustid(Result?.WorkOrderDetails?.Customer?.id);
        setSelectedCust(Result?.WorkOrderDetails?.Customer?.id);
        setNotesArray(Result?.notes || []);
        setWorkOrder(Result?.WorkOrderDetails);
        setupdateStatusName(Result?.WorkOrderDetails?.status);
        setWoeqpdet(Result?.WorkOrderDetails?.equipments);
        if (Result?.WorkOrderDetails?.additional_fields?.length > 0) {
          setAdditionalFields(Result?.WorkOrderDetails?.additional_fields);
        }
      }
    } catch (error) {
      console.error("Error fetching work order details:", error);
    } finally {
      setIsLoading(false); 
    }
  }, [location?.state?.custid, location?.state?.woid]);
  useEffect(() => {
    WorkOderDetails();
    localStorage.removeItem("eqpid")
  }, [WorkOderDetails]);

  const addflds = async () => {
    let addfields = await getCreateAdditionalFields();
    setAdditionalFields(addfields?.data);
  };
  const [allstatus, setAllstatus] = useState([]);
  const [allcalltypes, setAllcalltypes] = useState([]);
  let TechId = localStorage.getItem("userId");

  const [woeqpdet, setWoeqpdet] = useState([]);
  const [isVal, setIsVal] = useState("");
  const getcustomerIdsAndWorkOrderTypes = useCallback(async () => {
    let customersIds = await getCustomers();
    let status = await woStsSettFldStpGt();
    let calltype = await CallTypesFetch();
    let Wotypes = await woGtFldsTypeSett();

    setCustomerdata(customersIds?.data[0]?.objects);
    setFilteredCustData(customersIds?.data[0]?.objects);
    setAllstatus(status?.data);
    setAllcalltypes(calltype?.data);
    setworkOrderType(Wotypes?.data?.workOrderType);
    // setcreatingFields(addfields?.data);
    // if (isVal === "create") {
    //   setAdditionalFields(addfields?.data);
    // }
  }, []);

  useEffect(() => {
    switch (location?.state?.component) {
      case "workflow":
        scrollToDiv(1);
        break;
      case "extra":
        scrollToDiv(2);
        break;
      case "equipments":
        scrollToDiv(3);
        break;
      case "details":
        scrollToDiv(4);
        break;
      case "library":
        scrollToDiv(5);
        break;

      default:
        scrollToDiv(0);
        break;
    }
    getcustomerIdsAndWorkOrderTypes();
  }, [
    getcustomerIdsAndWorkOrderTypes,
    location.pathname,
    location?.state?.component,
  ]);

  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.work_order,
    uuid: "",
  });

  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };
  const HandleTagSubmit = async () => {
    let newObj = {
      referenceType: tagInpObj?.referenceType,
      tag_id: 0,
      referenceId: workOrder?.work_order_id,
      name: tagInpObj?.name,
      color: tagInpObj?.color,
      uuid_id: "",
      work_order_id: workOrder?.work_order_id,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newObj);
    if (Res?.success) {
      let newOne = {
        referenceType: Res?.data?.tag?.referenceType,
        color: Res?.data?.tag?.color,
        id: Res?.data?.tag?.tag_id,
        name: Res?.data?.tag?.tag_data,
      };
      setWorkOrder({ ...workOrder, tags: [...workOrder.tags, newOne] });
      setIsTagOpen(false);
      setTagInpObj({
        name: "",
        color: "#CCCCCC",
        referenceType: ReferenceId.work_order,
        uuid: "",
      });
    }
  };

  const scrollToDiv = (index) => {
    const contentDiv = contentRefs.current[index];
    contentDiv?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const extractTimeComponents = (timeStr) => {
    const timeParts = timeStr?.split(":");
    let hour = parseInt(timeParts[0]?.padStart(2, "0"), 10);
    const minute = timeParts[1]?.padStart(2, "0");
    const period = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour = hour % 12;
    } else if (hour === 0) {
      hour = 12;
    }
    return { hour: hour.toString().padStart(2, "0"), minute, period };
  };

  const HandleEdit = () => {
    const { hour, minute, period } = extractTimeComponents(
      workOrder?.schedule_time ?? "00:00:00+00"
    );
    setDetails({
      ...details,
      custId: workOrder?.Customer?.id,
      won: workOrder?.name,
      wot: workOrder?.type,
      wos: workOrder?.status,
      calltype: workOrder?.call_type_code,
      contName: workOrder?.contact?.name,
      contid: workOrder?.contact?.contact_id,
      crmdate: workOrder?.schedule_date
  ? moment(workOrder.schedule_date).format("MM/DD/YYYY")
  : "",
      time: workOrder?.schedule_time,
      descption: workOrder?.description,
      reason: workOrder?.problem_code ?? "",
      techie: workOrder?.TechniciansData?.map(
        (technician) => technician?.technician_code
      ),
    });
    setPrContactName(workOrder?.contact);
    setCustId(details?.custId);
    setSelectedCust(workOrder?.Customer?.id)
    setCustid(workOrder?.Customer?.id)
    setSelectedHour(hour);
    setSelectedMinute(minute);
    setSelectedAMPM(period);
    setAdditionalFields(workOrder?.additional_fields);
    // setcreatingFields(additionalFields);
    setCustName({
      ...workOrder?.Customer,
      address: `${workOrder?.Customer?.address?.street_1} ${workOrder?.Customer?.address?.street_2} ${workOrder?.Customer?.address?.city} ${workOrder?.Customer?.address?.state}, ${workOrder?.Customer?.address?.zip}`,
    });
    setIsVal("edit");
    setStartDate(new Date(workOrder?.request_date));
  };
  const formatTo12Hour = (time) => {
    if (!time) {
      return "";
    }
    const [hour, minute] = time.split(":");
    if (hour === undefined || minute === undefined) {
      return "";
    }
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return date.toLocaleTimeString([], options);
  };
  const formattedTime = formatTo12Hour(workOrder?.schedule_time);

  const HandleCancel = async (value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      custId: workOrder?.Customer?.id,
    }));
    setAdditionalFields(workOrder?.additional_fields);
    setSelectedCust(null);
    setIsVal(value);
    if (isVal === "create") {
      navigation(-1);
    } else {
      let customerdata = await getCustomers();
      const getcustomerObject = customerdata?.data.find(
        (u) => u?.id === parseInt(workOrder?.Customer?.id)
      );
      setselectedcustAddress(getcustomerObject ? getcustomerObject : "");
    }
  };

  const [isTagOpen, setIsTagOpen] = useState(false);
  const AddTagModal = () => {
    setTagInpObj({
      name: "",
      color: "#CCCCCC",
      referenceType: ReferenceId.work_order,
      uuid: "",
    });
    setIsTagOpen(true);
  };

  const [extraErr] = useState([]);
  const HandleDateChange = (date, workOrder) => {
    setStartDate(date);
    if (workOrder) {
      workOrder.schedule_date = moment(date)?.format("MM/DD/YYYY");
    }
    setDetails({
      ...details,
      crmdate: moment(date)?.format("MM/DD/YYYY"),
    });
    setError(
      error?.map((err) =>
        err.name === "crmdate" ? { ...err, name: "value" } : err
      )
    );
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleconctid = (item) => {
    setContacId(item?.contact_id);
    setDetails((prevDetails) => ({
      ...prevDetails,
      contid: item?.contact_id,
      contName: item?.name,
    }));
  };
  const [currentDate, setCurrentDate] = useState(() => formatDate(new Date()));
  const [ErrrAdd, setErrAdd] = useState([]);
  const createOrder = async (e) => {
    // setIsProgress(true)
    let Arr = [];
    let x = Object.keys(details);
    let checkFields = additionalFields;
    const errorArray = checkFields?.filter(
      (i) => i?.required === true && i?.value?.length === 0
    );
    if (errorArray?.length > 0) {
      setExtraErrorList(errorArray);
      setAddlnFlds(true);
    }
    x.forEach((it) => {
      if (
        (it === "time" && selectedHour === "00") ||
        (it !== "time" &&
          it !== undefined &&
          (details[it] === null ||
            details[it]?.length <= 0 ||
            details[it] === "Select Call Type" ||
            details[it] === "Select Status" ||
            details[it] === "Select Work Order Type"))
      ) {
        Arr?.push({ name: `${it}` });
      }
    });
    if (Arr?.length > 0 || errorArray?.length > 0) {
      setExtraErrorList([...Arr, ...errorArray]);
      setAddlnFlds(true);
      setError(Arr);
      setErrAdd(errorArray);
    } else {
      let data = {
        customer_id: details?.custId,
        technician_code: details?.techie,
        work_order_id: isWoid,
        contact_id: details?.contid,
        work_order_type: details?.wot,
        scheduled: details?.crmdate,
        status: details?.wos,
        name: details?.won,
        call_type_code: details?.calltype,
        address: selectedcustAddress,
        description: details?.descption,
        reason: details?.reason,
        note: "",
        scheduled_time: wotime,
        created_date: currentDate,
        extra_field_1: additionalFields ? additionalFields[0].value : "",
        extra_field_2:
          additionalFields.length > 1 ? additionalFields[1].value : "",
        extra_field_3:
          additionalFields.length > 2 ? additionalFields[2].value : "",
        extra_field_4:
          additionalFields.length > 3 ? additionalFields[3].value : "",
        extra_field_5:
          additionalFields.length > 4 ? additionalFields[4].value : "",
        extra_field_6:
          additionalFields.length > 5 ? additionalFields[5].value : "",
        extra_field_7:
          additionalFields.length > 6 ? additionalFields[6].value : "",
        extra_field_8:
          additionalFields.length > 7 ? additionalFields[7].value : "",
        extra_field_9:
          additionalFields.length > 8 ? additionalFields[8].value : "",
        extra_field_10:
          additionalFields.length > 9 ? additionalFields[9].value : "",
        extra_field_11:
          additionalFields.length > 10 ? additionalFields[10].value : "",
        extra_field_12:
          additionalFields.length > 11 ? additionalFields[11].value : "",
        extra_field_13:
          additionalFields.length > 12 ? additionalFields[12].value : "",
        extra_field_14:
          additionalFields.length > 13 ? additionalFields[13].value : "",
        extra_field_15:
          additionalFields.length > 14 ? additionalFields[14].value : "",
        extra_field_16:
          additionalFields.length > 15 ? additionalFields[15].value : "",
        extra_field_17:
          additionalFields.length > 16 ? additionalFields[16].value : "",
        extra_field_18:
          additionalFields.length > 17 ? additionalFields[17].value : "",
        extra_field_19:
          additionalFields.length > 18 ? additionalFields[18].value : "",
        extra_field_20:
          additionalFields.length > 19 ? additionalFields[19].value : "",
        extra_field_21:
          additionalFields.length > 20 ? additionalFields[20].value : "",
        extra_field_22:
          additionalFields.length > 21 ? additionalFields[21].value : "",
        extra_field_23:
          additionalFields.length > 22 ? additionalFields[22].value : "",
        extra_field_24:
          additionalFields.length > 23 ? additionalFields[23].value : "",
        extra_field_25:
          additionalFields.length > 24 ? additionalFields[24].value : "",
        extra_field_26:
          additionalFields.length > 25 ? additionalFields[25].value : "",
        extra_field_27:
          additionalFields.length > 26 ? additionalFields[26].value : "",
        extra_field_28:
          additionalFields.length > 27 ? additionalFields[27].value : "",
        extra_field_29:
          additionalFields.length > 28 ? additionalFields[28].value : "",
        extra_field_30:
          additionalFields.length > 29 ? additionalFields[29].value : "",
        show_on_grid: true,
      };
      setIsProgress(true);
      setIsLoading(true)
      let output = await createWorkOrder(data);
      if (output?.reason === "Created Successfully") {
        setIsVal("");
        WorkOderDetails(output?.data?.work_order_id, output?.data?.customer_id);
        setWorkOrder(output?.data);
        localStorage?.setItem("reswoid", output?.data?.work_order_id);
        localStorage?.setItem("rescustid", output?.data?.customer_id);
        setTimeout(() => {
          setIsProgress(false);
          setIsLoading(false)
        }, 10000);
      } else if (output.reason === "Updated Successfully") {
        setIsVal("")
        localStorage?.setItem("reswoid", output?.data?.work_order_id)
        localStorage?.setItem("rescustid", output?.data?.customer_id)
        WorkOderDetails(output?.data?.work_order_id, output?.data?.customer_id)
        setWorkOrder(output?.data)
        setIsProgress(false)
        setIsLoading(false)
      }
    }
  };
  const updateStatusAndWonumber = async (statusname) => {
    let result = await updateWorkOrderStatus(
      parseInt(workOrder?.id),
      statusname
    );
    if (result?.data?.success) {
      setStatusModal(false);
      setUpdateStatus(true);
      setupdateStatusName(result?.data?.data?.status);
    }
  };
  const handleInfo = () => {};
  const [isExpand, setIsExpand] = useState("");
  const [rtExpand, setRtExpand] = useState("");

  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  const HandleRtExpand = (val) => {
    setRtExpand(val);
  };
  const [OpenCust, setOpenCust] = useState(false);
  const [PrevSelectedCust, setPrevSelectedCust] = useState(null);
  const handleCustAddress = () => {
    setOpenCust(!OpenCust);
    setSelectedCust(PrevSelectedCust);
  };

  const [selectedCust, setSelectedCust] = useState(null);
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const [custmername, setCustmername] = useState("");
  const handleAddress = async (i) => {
    handleCustAddress();
    setDetails({
      custId: i?.customer_id,
      won: details?.won ?? "",
      wot: details?.wot ? details?.wot : "",
      wos: details?.wos ? details?.wos : "",
      calltype: details?.calltype ? details?.calltype : "",
      contid: "",
      contName: "",
      // assignwo: "",
      crmdate:
        details?.crmdate !== null
          ? moment(details?.crmdate).format("MM/DD/YYYY")
          : "",
      time: details.time ? details.time : "",
      descption: details?.descption ? details?.descption : "",
      reason: details?.reason ? details?.reason : "",
      techie: details?.techie ? details?.techie : "",
    });
    setCustName(i);
    setCustmername(i);
    setCustId(i?.customer_id);
    setCustid(i?.customer_id)
    setSelectedCust(localStorage?.getItem("rescustid") ? localStorage?.getItem("rescustid") : location?.state?.custid ? location?.state?.custid : i?.customer_id)
    setSearchQuery("");
    const prmyconty = await GetCustExtraDetailsById(i?.customer_id);
    const contactsResult = await WoContacts(prmyconty?.data[0]?.customer_id);
    let primarycontact = contactsResult?.data?.find((contact) => contact?.contact_id === prmyconty?.data?.[0]?.contact_id);
    setPrContactName(primarycontact)
    setDetails({
      custId: i?.customer_id,
      won: details?.won ?? "",
      wot: details?.wot ? details?.wot : "",
      wos: details?.wos ? details?.wos : "",
      calltype: details?.calltype ? details?.calltype : "",
      contid: primarycontact?.contact_id,
      contName: primarycontact?.name,
      // assignwo: "",
      crmdate:
        details?.crmdate !== null
          ? moment(details?.crmdate).format("MM/DD/YYYY")
          : "",
      time: details.time ? details.time : "",
      descption: details?.descption ? details?.descption : "",
      reason: details?.reason ? details?.reason : "",
      techie: details?.techie ? details?.techie : "",
    });
  };

  const onCustSave = async () => {
    if (!selectedCust) {
      return;
    }
    handleAddress(selectedCust);
    setIsCustomerSelected(true);
    // setcustomrid(selectedCust?.customer_id);
    setPrevSelectedCust(selectedCust);
  };
  const handleTechnician = (val) => {
    setIsAddTechOpen(true);
  };
  const [Techie, setTechie] = useState({});

  useEffect(() => {
    setTechie(workOrder?.TechniciansData);
  }, [workOrder?.Technician, workOrder?.TechniciansData]);

  const [selectedwoTechs, setSelectedwoTechs] = useState([]);
  const HandlCustData = (selectedTechs) => {
    if (woid.length > 0) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        techie: selectedTechs.map((tech) => tech.id),
      }));
      setTechie(selectedTechs);
      setIsAddTechOpen(false);
    }
  };

  const handleInfoToggle = () => {
    setIsInfo(!isInfo);
  };
  const HandleShareWOmodal = () => {
    setShareWOModal(true);
  };
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const HandleCustFilter = () => {
      if (!searchQuery) {
        setFilteredCustData(customerdata);
        return;
      }
      const filtered = customerdata.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
      setFilteredCustData(filtered);
    };
    HandleCustFilter();
  }, [searchQuery, customerdata]);

  const handleHourChange = (event) => {
    const newHour = event.target.value;
    if (newHour === "00") {
      setDetails({
        ...details,
        time: "",
      });
    } else {
      setDetails({
        ...details,
        time: "time",
      });
    }
    setSelectedHour(newHour);
    updateDetailsTime(newHour, selectedMinute, selectedAMPM);
  };

  const handleMinuteChange = (event) => {
    const newMinute = event.target.value;
    setSelectedMinute(newMinute);
    updateDetailsTime(selectedHour, newMinute, selectedAMPM);
  };

  const handleAMPMChange = (event) => {
    const newAMPM = event.target.value;
    setSelectedAMPM(newAMPM);
    updateDetailsTime(selectedHour, selectedMinute, newAMPM);
  };

  const updateDetailsTime = (hour, minute, period) => {
    let hour24 = hour;
    if (period === "PM" && hour < 12) {
      hour24 += 12;
    } else if (period === "AM" && hour === 12) {
      hour24 = 0;
    }
    const formattedHour = hour24?.toString().padStart(2, "0");
    const formattedMinute = minute?.padStart(2, "0");
    const formattedTime = `${formattedHour}:${formattedMinute}`;
    setDetails((prevDetails) => ({
      ...prevDetails,
      time: formattedTime,
    }));
  };

  const [CompleteWfData, setCompleteWfData] = useState([]);
  const WfData = (data) => {
    data?.length > 0 && setCompleteWfData(data);
  };
  const [isStepVal, setIsStepval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReq, setIsReq] = useState("");
  const [isreqWf, setIsreqWf] = useState("");
  const [RequiredWf, setRequiredWf] = useState([]);
  const HandleCompleteWo = async () => {
    const result = WorkOrderComplete(CompleteWfData);
    if (result?.NewData?.length > 0) {
      setIsreqWf("WfValidation");
      setIsStepval(true);
      setIsLoading(false);
      setRequiredWf(result?.NewData);
      if (result?.NewData[0]?.type === "required") {
        setIsReq(Wo_Workflow?.Req_step);
      } else {
        setIsReq(Wo_Workflow?.OpenReq_steps);
      }
    } else {
      const res = await updateWorkOrderStatus(
        parseInt(workOrder?.id),
        "Completed"
      );
      if (res?.status === 200) {
        alert("Workorder completed successfully");
        window.location.reload();
      }
    }
  };
  const [isLoadData, setIsLoadData] = useState(false);
  const HandleRemainWf = () => {
    setIsreqWf("");
    setRequiredWf(RequiredWf);
    setIsLoadData(true);
    setIsStepval(false);
    setStatusModal(false);
  };
  const HandleWfConfirm = async () => {
    let newApiArr = CompleteWfData?.map((i) => ({
      WoWfId: i?.work_order_workflow_id,
      WfStepId: 0,
      Type: "1",
    }));
    (async () => {
      await Promise.all(
        newApiArr?.map(async (it) => {
          await HandleWorkFlowComplete(it?.WoWfId, it?.WfStepId, it?.Type);
        })
      );
    })();

    const res = await updateWorkOrderStatus(
      parseInt(workOrder?.id),
      "Completed"
    );
    if (res?.status === 200) {
      alert("Workorder completed successfully");
      window.location.reload();
    }
    setIsStepval(false);
  };
  const wodAddtionalFieldsHandler = (
    key,
    e,
    field_id,
    controlType,
    value,
    additionalFields1
  ) => {
    let Res = ExtraFieldsTypeValids(
      key,
      e,
      field_id,
      controlType,
      value,
      additionalFields1
    );
    // setobjectValues(Res)
    setAdditionalFields(Res);
    // setcreatingFields(Res);
  };

  return (
    <div
      className={OpenCust ? "wod-opcity07" : ""}
      style={{
        pointerEvents: shareid?.length > 0 ? "auto" : "none",
        height: "100%",
        "overflow-y": "auto",
        "overflow-x": "hidden",
      }}
    >
      <div className="details-header-sticky">
        <div
        // className={
        //   location?.state
        //     ? "w-100"
        //     : "w-100  pointr-events" || statusModal === true
        //       ? "wod-opcity05"
        //       : "wod-opcity1"
        // }
        >
          <CommonHeader />
        </div>
        {isLoading !== true && (
          <Container fluid>
            <div
              className={
                location?.state
                  ? "work-details-name mt-2"
                  : "work-details-name mt-2 pointr-events"
              }
            >
              <div className="d-flex ">
                {/* <div
                  onClick={() => navigateToComponent()}
                  className={
                    statusModal === true
                      ? "wod-opcity05 pointr-events cursor-pointer"
                      : "wod-opcity1 cursor-pointer"
                  }
                >
                  {" "}
                  <img src={leftAr} className=" " alt="leftAr" />
                </div> */}

                {isVal === "create" ? (
                  <div
                    className={
                      statusModal === true
                        ? "wod-opcity05 pointr-events txt-fnt mx-3"
                        : "wod-opcity1 txt-fnt mx-3"
                    }
                  >
                    <span className="wo-create-workorder-span">
                      Create A Work Order{" "}
                    </span>
                    <span className="wo-create-create-span">Creating</span>
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        statusModal === true
                          ? "wod-opcity05 pointr-events txt-fnt"
                          : "wod-opcity1 txt-fnt "
                      }
                    >
                      {workOrder?.branch} {TABLE_COLUMNS_NAME.TABLE_WORK_ORDER}
                      &nbsp; #
                      {workOrder?.work_order_id
                        ? workOrder?.work_order_id
                        : +localStorage?.getItem("reswoid")}{" "}
                      | {workOrder?.Customer?.name} &nbsp;
                    </div>
                    {isVal === "edit" && (
                      <span className="wo-create-create-span-edit">
                        Editing
                      </span>
                    )}
                  </>
                )}

                {isVal === "edit" || isVal === "create" ? (
                  <span className="display-none">Hii</span>
                ) : (
                  // <div className="mx-1">
                  //   <div>
                  //     <button
                  //       className="staatus-btn mx-2 mt-1"
                  //       style={{
                  //         pointerEvents:
                  //           workOrder?.status === "Completed" ? "none" : "",
                  //         cursor: isVal === "edit" ? "pointer" : "pointer",
                  //         backgroundColor:
                  //           updatedStatusName === "To Do"
                  //             ? "#CCD2FF"
                  //             : updatedStatusName === "In Progress"
                  //             ? "#FEF2DA"
                  //             : updatedStatusName === "Hold"
                  //             ? "#FBE0E0"
                  //             : updatedStatusName === "Completed"
                  //             ? "#DEF2ED"
                  //             : "none",
                  //         color:
                  //           updatedStatusName === "To Do"
                  //             ? "#1A60A3"
                  //             : updatedStatusName === "In Progress"
                  //             ? "#F6A609"
                  //             : updatedStatusName === "Hold"
                  //             ? "#E43333"
                  //             : updatedStatusName === "Completed"
                  //             ? "#26A688"
                  //             : "none",
                  //       }}
                  //       onClick={() => {
                  //         isVal !== "edit" && setStatusModal(true);
                  //       }}
                  //     >
                  //       {updateStatus === true
                  //         ? updatedStatusName
                  //         : workOrder?.status}
                  //     </button>
                  //   </div>
                  //   {statusModal && (
                  //     <div className="txt-align-c statusModal">
                  //       <button
                  //         className="bodyDivs"
                  //         onClick={() => setStatusModal(false)}
                  //       >
                  //         {CUST_PRTL.CANCEL}
                  //       </button>
                  //       <button
                  //         className="bodyDivs-todo"
                  //         onClick={() => updateStatusAndWonumber("To Do")}
                  //       >
                  //         {CUST_PRTL.TO_DO}
                  //       </button>
                  //       <button
                  //         className="bodyDivs-inprogress"
                  //         onClick={() => updateStatusAndWonumber("In Progress")}
                  //       >
                  //         {CUST_PRTL.IN_PROGRESS}
                  //       </button>
                  //       <button
                  //         className="bodyDivs-hold"
                  //         onClick={() => updateStatusAndWonumber("Hold")}
                  //       >
                  //         {CUST_PRTL.HOLD}
                  //       </button>
                  //       <button
                  //         className="bodyDivs-completed"
                  //         onClick={() => HandleCompleteWo()}
                  //       >
                  //         {CUST_PRTL.COMPLETED}
                  //       </button>
                  //     </div>
                  //   )}
                  // </div>
                  <div className="mx-1">
                    <div>
                      <button
                        className="staatus-btn mx-2 mt-1"
                        // style={{
                        //   pointerEvents: workOrder?.status === "Completed" ? "none" : "",
                        //   cursor: isVal === "edit" ? "pointer" : "pointer",
                        //   backgroundColor:
                        //     updatedStatusName === "To Do"
                        //       ? "#CCD2FF"
                        //       : updatedStatusName === "In Progress"
                        //         ? "#FEF2DA"
                        //         : updatedStatusName === "Hold"
                        //           ? "#FBE0E0"
                        //           : updatedStatusName === "Completed"
                        //             ? "#DEF2ED"
                        //             : "none",
                        //   color:
                        //     updatedStatusName === "To Do"
                        //       ? "#1A60A3"
                        //       : updatedStatusName === "In Progress"
                        //         ? "#F6A609"
                        //         : updatedStatusName === "Hold"
                        //           ? "#E43333"
                        //           : updatedStatusName === "Completed"
                        //             ? "#26A688"
                        //             : "none",
                        // }}
                        onClick={() => {
                          setOpenCust(!OpenCust);
                          isVal !== "edit" && setStatusModal(true);
                        }}
                      >
                        {updateStatus === true
                          ? updatedStatusName
                          : workOrder?.status}
                      </button>
                    </div>

                    {statusModal && (
                      <div className="txt-align-c statusModal mt-5">
                        <button
                          className="bodyDivs bg-warning"
                          onClick={() => {
                            setStatusModal(false);
                            setOpenCust(!OpenCust);
                          }}
                        >
                          {CUST_PRTL.CANCEL}
                        </button>

                        {allstatus?.map((item) => (
                          <button
                            key={item}
                            className="bodyDivs"
                            // className={`bodyDivs-${status?.toLowerCase()}`}
                            onClick={() => {
                              setOpenCust(!OpenCust);
                              item === "Completed"
                                ? HandleCompleteWo()
                                : updateStatusAndWonumber(item?.status);
                            }}
                          >
                            {/* {CUST_PRTL[status.toUpperCase()]} */}
                            {item?.status}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                <div
                  className={
                    statusModal === true
                      ? "d-flex w-100 flx-wrp wod-opcity05 pointr-events"
                      : "wod-opcity1 d-flex w-100 flx-wrp"
                  }
                >
                  {isVal !== "edit" && isVal !== "create" ? (
                    <>
                      <span className="mx-1 cursor-p woCard-icons-align mb-2">
                        {workOrder?.MemoryBoard === 1 ? (
                          <img
                            onClick={(e) =>
                              HandleMemory("workorderView", e, workOrder)
                            }
                            src={VectorEqpBlue}
                            alt="memoryIconBlue"
                          />
                        ) : (
                          <img
                            onClick={(e) => HandleMemory("black", e, workOrder)}
                            width={22}
                            src={MemoryIcon}
                            alt="MemoryIcon"
                          />
                        )}
                      </span>
                      {/* <img
                      src={memoryIcon}
                      alt="memory"
                      className="mx-1 cursor-p"
                      onClick={(e) => HandleMemory(e,workOrder)}
                    /> */}
                      <img
                        src={ShareIcon}
                        alt="DelIcon"
                        className="share-img mx-1"
                        onClick={() => HandleShareWOmodal()}
                      />
                      <img
                        src={delIcon}
                        alt="DelIcon"
                        className="del-img mx-1"
                        // onClick={() => HandleDelWOmodal()}
                      />
                      <div
                        className="complete-btn-styles  mx-2"
                        onClick={() => {
                          if (additionalFields?.length > 0) {
                            HandleEdit();
                          }
                        }}
                      >
                        Edit
                        <img
                          src={edit}
                          alt="file"
                          width={12}
                          style={{ filter: "white(100%)" }}
                        />
                      </div>
                      <div
                        style={{
                          pointerEvents:
                            workOrder?.status === "Completed" && "none",
                        }}
                        className="complete-btn-styles"
                        onClick={() => {
                          setOpenCust(!OpenCust);
                          isVal !== "edit" && setStatusModal(true);
                          // setOpenCust(!OpenCust)
                        }}
                      >
                        {WORK_ORDER_CONSTANTS.COMPLETE_WORK_ORDER}
                        <img src={ps} alt="file" width={12} />
                      </div>{" "}
                    </>
                  ) : (
                    <div className=" d-flex">
                      <AIModal
                        Open={isModalOpen}
                        Close={closeModal}
                        onSave={handleModalSave}
                        onDiscard={handleModalDiscard}
                        data={{
                          details,
                          creatingFields,
                        }}
                      />
                      <img
                        src={AIIcon}
                        alt="memory"
                        className="mx-2"
                        onClick={openModal}
                      />
                      {/* <img
                      src={memoryIcon}
                      alt="memory"
                      className="mx-1"
                    onClick={HandleMemory}
                    /> */}
                      <div
                        className="complete-btn-styles "
                        onClick={() => HandleCancel("")}
                      >
                        {TAGS_MODAL_VALUES.DISCARD}
                        <img
                          className=" ml-1"
                          src={discard}
                          alt="discard"
                          width={14}
                        />
                      </div>
                      <div
                        className="complete-btn-styles mx-1"
                        onClick={() => createOrder("")}
                      >
                        {TAGS_MODAL_VALUES.SAVE}
                        <img
                          className=" ml-1"
                          src={Save}
                          alt="save"
                          width={13}
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    {" "}
                    {/* <img
                    className="mx-1 mb-1"
                    src={adj}
                    alt="adjusting logo"
                    width={18}
                  /> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                statusModal === true
                  ? "wod-opcity05 pointr-events"
                  : "wod-opcity1"
              }
            >
              {/* <WoHeaders
              scrollCallback={scrollToDiv}
              HandleColor={location?.state?.component}
              tabval={0}
            /> */}
            </div>
          </Container>
        )}
      </div>
      {isLoading ? (
        <div className="progress-bar">
          <ProgressBar />
        </div>
      ) : (
        <Row className="w-100 mx-2">
          {isExpand === "expand" ? (
            " "
          ) : (
            <Col
              className="left_panel mt-2"
              style={{ pointerEvents: OpenCust ? "none" : "" }}
              lg={
                isExpand === "expand" && rtExpand === "rt-expand"
                  ? 0
                  : rtExpand === "rt-expand"
                  ? 2
                  : isExpand === "expand"
                  ? 0
                  : 2
              }
            >
              <WODetailsSideBar
                handleInfo={handleInfo}
                scrollCallback={scrollToDiv}
              />
            </Col>
          )}

          <Col
            lg={
              isExpand === "expand" && rtExpand === "rt-expand"
                ? 12
                : isExpand === "expand"
                ? 9
                : rtExpand === "rt-expand"
                ? 10
                : 7
            }
          >
            {isExpand === "expand" ? (
              <span
                className={
                  isExpand === "expand"
                    ? "backbtn-hvr2 "
                    : "backbtn-hvr p-0 m-0"
                }
              >
                <MdChevronRight
                  className="arro-right-icon"
                  size={25}
                  onClick={() => HandleExpand("")}
                />
              </span>
            ) : (
              <span>
                <MdChevronLeft
                  size={25}
                  onClick={() => HandleExpand("expand")}
                  className="leftbar-leftarrow "
                />
              </span>
            )}
            {rtExpand === "rt-expand" ? (
              <MdChevronLeft
                size={25}
                className="wod-expand-icon-right mx-2 "
                onClick={() => HandleRtExpand("")}
              />
            ) : (
              <MdChevronRight
                size={25}
                className="wod-expand-icon-img-right "
                onClick={() => HandleRtExpand("rt-expand")}
              />
            )}
            <Card className="mt-2 mx-2 ">
              <div
                className={
                  statusModal === true
                    ? "wod-opcity05 pointr-events scrollable-container"
                    : "wod-opcity1 scrollable-container"
                }
              >
                <div className="">
                  <div
                    ref={(ref) => (contentRefs.current[0] = ref)}
                    data-index={0}
                    className="content p-1 "
                  >
                    <Card className="ml-1  det-txt-Wo wod-card-header">
                      {woDetT === "detailsT" ? (
                        ""
                      ) : (
                        <div>
                          {isVal === "edit" || isVal === "create" ? (
                            <>
                              <img
                                onClick={() => handleInfoToggle()}
                                src={info}
                                className="flt-l ml-2 mr-1 mb-1 cursor-p"
                                width={15}
                                alt="details"
                              />
                              <span
                                className="mx-2 cursor-p"
                                onClick={() => handleInfoToggle()}
                              >
                                {WORK_ORDER_CONSTANTS.DETAILS}
                              </span>
                              {isInfo ? (
                                <div>
                                  <div className="tech-btn-end-cust ">
                                    <button
                                      onClick={() => handleCustAddress()}
                                      className={
                                        location?.state
                                          ? "btn-T-techinician"
                                          : "btn-T-techinician blurimg"
                                      }
                                    >
                                      {isCustomerSelected || isVal === "edit"
                                        ? CUST_PRTL.CHANGE_CUST
                                        : CUST_PRTL.SELECT_CUST}
                                      <img src={Add_round} alt="btn" />{" "}
                                    </button>
                                  </div>
                                  <div className=" info-inpfields mt-2">
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.CUST_NAME}
                                      </label>
                                      <input
                                        disabled
                                        style={{ backgroundColor: "#e5e6e7" }}
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "custId"
                                          ) &&
                                          details?.custId?.length <= 0
                                            ? "cond-style-inpt-det inpt-hover"
                                            : "inpt-address-wo inpt-hover"
                                        }
                                        value={
                                          workOrder?.customer?.id
                                            ? custmername?.name?.length > 0
                                              ? custmername?.name
                                              : custName?.name
                                            : custName?.name
                                        }
                                      />
                                    </div>
                                    <div
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <span className="wo-star-styles">
                                          *
                                        </span>
                                        {WORK_ORDER_CONSTANTS.WORK_ORDER_NAME}:
                                      </label>
                                      <input
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        onChange={(e) => HandleInputsWo(e)}
                                        name="won"
                                        value={details?.won}
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "won"
                                          ) &&
                                          details?.won?.length <= 0
                                            ? "cond-style-inpt-det inpt-hover"
                                            : "inpt-det inpt-hover"
                                        }
                                      />
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det ">
                                        {" "}
                                        <span className="wo-star-styles ">
                                          *
                                        </span>
                                        {WORK_ORDER_CONSTANTS.WORK_ORDER_TYPE}
                                      </label>
                                      <select
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "wot"
                                          ) &&
                                          (details?.wot?.length <= 0 ||
                                            details?.wot ===
                                              CUST_PRTL.SELECT_WO_TYPE)
                                            ? "cond-style-selectDropdown inpt-hover"
                                            : "selectDropdown inpt-hover"
                                        }
                                        onChange={(e) => HandleInputsWo(e)}
                                        name="wot"
                                        value={details?.wot}
                                        style={{
                                          width: "290px",
                                          border:
                                            error?.length > 0 &&
                                            error?.map(
                                              (err) => err?.name === "wot"
                                            ) &&
                                            details?.wot?.length <= 0
                                              ? "1px solid red"
                                              : "",
                                        }}
                                      >
                                        <option>
                                          {CUST_PRTL.SELECT_WO_TYPE}
                                        </option>
                                        {workOrderType?.map((data) => (
                                          <option
                                            value={data.work_order_type_code}
                                            key={data.work_order_type_code}
                                          >
                                            {typeof data.work_order_type_code !==
                                              "object" &&
                                              data.work_order_type_code}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {OpenCust ? (
                                      <div
                                        style={{
                                          pointerEvents: !OpenCust
                                            ? "none"
                                            : "",
                                        }}
                                      >
                                        <div className="cust-customdiv-addressandname">
                                          <div
                                            className=" click-to-close-div"
                                            onClick={() => handleCustAddress()}
                                          >
                                            <span className="click-close ">
                                              Click here to close
                                            </span>
                                            <img
                                              // src={down}
                                              src={Close}
                                              alt="Click "
                                              className="arrow-icon"
                                            />
                                          </div>
                                          <div className="d-flex search-inp-container">
                                            <img
                                              src={searchicon}
                                              alt="Click "
                                              className="srch-icon"
                                            />
                                            <input
                                              // style={{marginLeft:"20px"}}
                                              className="m-1 wo-cust-filtr"
                                              value={searchQuery}
                                              onChange={(e) =>
                                                setSearchQuery(e.target.value)
                                              }
                                              name="customerFilter"
                                              placeholder='"Search for the name,address or city of any customer"'
                                            />
                                          </div>
                                          <div className="cust-list">
                                            {filteredCustData?.map((item) => (
                                              <div
                                                className="d-flex mx-2"
                                                onClick={() =>
                                                  setSelectedCust(item)
                                                }
                                              >
                                                <input
                                                  type="radio"
                                                  className="radio-btn"
                                                  name="customerData"
                                                  id={item?.custId}
                                                  checked={
                                                    item?.customer_id === (selectedCust?.customer_id ? selectedCust?.customer_id : custid)
                                                  }
                                                  // checked={
                                                  //   isVal === "edit"
                                                  //     ? item?.customer_id === (selectedCust?.customer_idworkOrder?.Customer?.id)
                                                  //     : false
                                                  // }
                                                />
                                                <div className=" mx-2 cust-mapping-div">
                                                  <span className="cust-name-modal ">
                                                    {item?.name?.length > 0
                                                      ? item?.name
                                                      : "no name"}
                                                  </span>
                                                  <span className="cust-name-address cursor-p">
                                                    {item?.address?.length > 0
                                                      ? item?.address
                                                      : "no address"}
                                                  </span>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                          <div
                                            style={{
                                              pointerEvents: !OpenCust
                                                ? "none"
                                                : "",
                                            }}
                                            className="cust-modal-save-cancel-buttons d-flex cursor-p"
                                          >
                                            <div
                                              style={{ marginLeft: 10 }}
                                              className="wo-modal-createNew "
                                            >
                                              Create New &nbsp;
                                              <img
                                                src={plusIcon}
                                                alt="plusIcon"
                                                className="plusIcon-modal"
                                              />
                                            </div>
                                            <div
                                              className=" mx-3"
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                width: "65%",
                                              }}
                                            >
                                              <div
                                                className="wo-modal-cancel mx-1"
                                                onClick={() =>
                                                  handleCustAddress()
                                                }
                                              >
                                                Cancel &nbsp;
                                                <img
                                                  src={close}
                                                  alt="closeIcon"
                                                  className="closeIcon-modal"
                                                />
                                              </div>
                                              <div
                                                className="wo-modal-cancel"
                                                onClick={onCustSave}
                                              >
                                                Save &nbsp;
                                                <img
                                                  src={saveIcon}
                                                  alt="saveIcon"
                                                  className="closeIcon-modal"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {
                                          WORK_ORDER_CONSTANTS.WORK_ORDER_ADDRESS
                                        }
                                        :
                                      </label>
                                      <input
                                        disabled
                                        readOnly
                                        style={{ backgroundColor: "#e5e6e7" }}
                                        id="edit-inp-text"
                                        autoComplete="off"
                                        className="inpt-address-wo inpt-hover"
                                        value={
                                          custName?.address ||
                                          (workOrder?.Customer?.address.street_1
                                            ?.length > 0
                                            ? `${workOrder.Customer.address.street_1} ${workOrder.Customer.address.street_2} ${workOrder.Customer.address.country}`
                                            : "")
                                        }
                                      />
                                    </div>

                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.CITY}:
                                      </label>
                                      <input
                                        disabled
                                        readOnly
                                        style={{ backgroundColor: "#e5e6e7" }}
                                        id="edit-inp-text"
                                        autoComplete="off"
                                        className="inpt-address-wo inpt-hover"
                                        value={
                                          custName?.city
                                            ? custName?.city
                                            : workOrder?.Customer?.address?.city
                                        }
                                      />
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.STATE}:
                                      </label>
                                      <input
                                        disabled
                                        readOnly
                                        id="edit-inp-text"
                                        autoComplete="off"
                                        className="inpt-address-wo inpt-hover"
                                        style={{ backgroundColor: "#e5e6e7" }}
                                        value={
                                          custName?.state
                                            ? custName?.state
                                            : workOrder?.Customer?.address
                                                ?.state
                                        }
                                      />
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.ZIP}:
                                      </label>
                                      <input
                                        disabled
                                        readOnly
                                        id="edit-inp-text"
                                        autoComplete="off"
                                        className="inpt-address-wo inpt-hover"
                                        style={{ backgroundColor: "#e5e6e7" }}
                                        value={
                                          custName?.zip_code
                                            ? custName?.zip_code
                                            : workOrder?.Customer?.address?.zip
                                        }
                                      />
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.CONTACT_ID}:
                                      </label>
                                      <input
                                        readOnly
                                        id="edit-inp-text"
                                        autoComplete="off"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "contid"
                                          ) &&
                                          details?.contid?.length <= 0
                                            ? "cond-style-inpt-det inpt-hover"
                                            : "inpt-address-wo inpt-hover"
                                        }
                                        value={
                                          details?.contName
                                            ? details?.contName
                                            : prContactName
                                            ? prContactName?.name
                                            : ""
                                        }
                                      />
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det ">
                                        {" "}
                                        <span className="wo-star-styles ">
                                          *
                                        </span>
                                        {WORK_ORDER_CONSTANTS.STATUS}:
                                      </label>
                                      <select
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "wos"
                                          ) &&
                                          (details?.wos?.length <= 0 ||
                                            details?.wos ===
                                              CUST_PRTL.SELECT_STATUS)
                                            ? "cond-style-selectDropdown inpt-hover"
                                            : "selectDropdown inpt-hover"
                                        }
                                        onChange={(e) => HandleInputsWo(e)}
                                        name="wos"
                                        value={details?.wos}
                                        style={{
                                          width: "290px",
                                          border:
                                            error?.length > 0 &&
                                            error?.map(
                                              (err) => err?.name === "wos"
                                            ) &&
                                            details?.wos?.length <= 0
                                              ? "1px solid red"
                                              : "",
                                        }}
                                      >
                                        <option>
                                          {CUST_PRTL.SELECT_STATUS}
                                        </option>
                                        {allstatus?.map((data) => (
                                          <option
                                            value={data?.status}
                                            key={data?.status}
                                          >
                                            {typeof data.status !== "object" &&
                                              data?.status}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det ">
                                        {" "}
                                        <span className="wo-star-styles ">
                                          *
                                        </span>
                                        {WORK_ORDER_CONSTANTS.CALLTYPE}:
                                      </label>
                                      <select
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "calltype"
                                          ) &&
                                          (details?.calltype?.length <= 0 ||
                                            details?.calltype ===
                                              CUST_PRTL.CALL_TYPE)
                                            ? "cond-style-selectDropdown inpt-hover"
                                            : "selectDropdown inpt-hover"
                                        }
                                        onChange={(e) => HandleInputsWo(e)}
                                        name="calltype"
                                        value={details?.calltype}
                                        style={{
                                          width: "290px",
                                          border:
                                            error?.length > 0 &&
                                            error?.map(
                                              (err) => err?.name === "calltype"
                                            ) &&
                                            details?.calltype?.length <= 0
                                              ? "1px solid red"
                                              : "",
                                        }}
                                      >
                                        <option>{CUST_PRTL.CALL_TYPE}</option>
                                        {allcalltypes?.map((data) => (
                                          <option
                                            value={data.call_type_code}
                                            key={data.call_type_code}
                                          >
                                            {typeof data.call_type_code !==
                                              "object" && data.call_type_code}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div
                                      className="work-tags-btns"
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det ">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.WORK_ORDER_DATE}
                                      </label>
                                      <ReactDatePicker
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.filter(
                                            (err) => err?.name === "crmdate"
                                          )?.length > 0
                                            ? "custom-datepicker2 cursor-p"
                                            : "custom-datepicker cursor-p"
                                        }
                                        selected={startDate}
                                        required
                                        value={
                                          workOrder?.schedule_date
                                          ? moment(workOrder?.schedule_date)?.format("MM/DD/YYYY")
                                          : startDate
                                        }
                                        name="crmdate"
                                        onChange={(date) => {
                                          HandleDateChange(date, workOrder);
                                        }}
                                        placeholderText="Select Date"
                                        autoComplete="off"
                                        onFocus={(e) =>
                                          (e.target.readOnly = true)
                                        }
                                        onKeyDown={(e) => e.preventDefault()}
                                      />
                                    </div>
                                    <div
                                      className="work-tags-btns"
                                      style={{
                                        pointerEvents: OpenCust ? "none" : "",
                                      }}
                                    >
                                      <label className="name-lbl-det">
                                        <text className="wo-star-styles">
                                          *
                                        </text>
                                        {WORK_ORDER_CONSTANTS.WORK_ORDER_TIME}{" "}
                                      </label>
                                      <div>
                                        <select
                                          className={
                                            error?.length > 0 &&
                                            error?.some(
                                              (err) => err?.name === "time"
                                            ) &&
                                            (details?.time?.length <= 0 ||
                                              selectedHour === "00")
                                              ? "time-pick2"
                                              : "time-pick"
                                          }
                                          value={selectedHour}
                                          onChange={handleHourChange}
                                          name="time"
                                        >
                                          <option selected>{"00"}</option>
                                          {[...Array(12).keys()].map((hour) => {
                                            const hourLabel = (hour + 1)
                                              .toString()
                                              .padStart(2, "0");
                                            return (
                                              <>
                                                <option
                                                  key={hourLabel}
                                                  value={hourLabel}
                                                >
                                                  {hourLabel}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span style={{ color: "gray" }}>:</span>
                                        <select
                                          className={
                                            error?.length > 0 &&
                                            error?.some(
                                              (err) => err?.name === "time"
                                            ) &&
                                            (details?.time?.length <= 0 ||
                                              selectedHour === "00")
                                              ? "time-pick2"
                                              : "time-pick"
                                          }
                                          value={selectedMinute}
                                          onChange={handleMinuteChange}
                                          name="time"
                                        >
                                          {[...Array(60).keys()].map(
                                            (minute) => (
                                              <option
                                                key={minute}
                                                value={minute
                                                  .toString()
                                                  .padStart(2, "0")}
                                              >
                                                {minute
                                                  .toString()
                                                  .padStart(2, "0")}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        &nbsp;
                                        <select
                                          className={
                                            error?.length > 0 &&
                                            error?.some(
                                              (err) => err?.name === "time"
                                            ) &&
                                            (details?.time?.length <= 0 ||
                                              selectedHour === "00")
                                              ? "time-pick2"
                                              : "time-pick"
                                          }
                                          value={selectedAMPM}
                                          onChange={handleAMPMChange}
                                          name="time"
                                        >
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                        </select>
                                      </div>

                                      {/* <input
                                      required
                                      type="time"
                                      onClick={handleTimePickerClick}
                                      className="wod-time"
                                    /> */}
                                    </div>
                                    {/* <div className="div-time ">
                                    <label className="name-lbl-det ">
                                      {WORK_ORDER_CONSTANTS.WORK_ORDER_TIME}{" "}
                                    </label>
                                    <ReactTimePicker
                                      value={startTime}
                                      onChange={setStartTime}
                                    />
                                  </div> */}
                                  </div>
                                  {isVal === "edit" && (
                                    <div className="  tags-set-height">
                                      <div className="d-flex gap-1 tag-div-cname"></div>
                                      <div className="tech-btn-end ">
                                        <button
                                          className={
                                            location?.state
                                              ? "btn-T-techinician"
                                              : "btn-T-techinician blurimg"
                                          }
                                          onClick={() => AddTagModal()}
                                        >
                                          {CUST_PRTL.TAG}
                                          <img src={Add_round} alt="btn" />{" "}
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <div className="wo-edit-tag">
                                    <div className="tech-name-style ">
                                      <div className=" d-flex flex-wrap">
                                        {workOrder?.tags?.map((item) => (
                                          <div
                                            key={item}
                                            onClick={(e) => {
                                              handleDeleteModal(
                                                e,
                                                { ...item },
                                                workOrder
                                              );
                                            }}
                                          >
                                            <span
                                              className="tag-styles"
                                              style={{
                                                backgroundColor: "#CCCCCC",
                                                fontSize: "12px",
                                                color: "black",
                                                wordBreak: "break-word",
                                                whiteSpace: "normal",
                                                display: "inline-block",
                                                maxWidth: "100%",
                                              }}
                                            >
                                              {" "}
                                              {item?.name}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      pointerEvents: OpenCust ? "none" : "",
                                    }}
                                  >
                                    <label className="name-lbl-det mt-1 ">
                                      <text className="wo-star-styles ">*</text>
                                      {WORK_ORDER_CONSTANTS.REASON}:
                                    </label>
                                    <div>
                                      <input
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "reason"
                                          ) &&
                                          details?.reason?.length <= 0
                                            ? "cond-style-desc-ipt-wkord inpt-hover  "
                                            : "desc-ipt-wkord inpt-hover w-50 "
                                        }
                                        name="reason"
                                        value={details?.reason}
                                        onChange={(e) => HandleInputsWo(e)}
                                        maxLength={20}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      pointerEvents: OpenCust ? "none" : "",
                                    }}
                                  >
                                    <label className="name-lbl-det mt-2">
                                      <text className="wo-star-styles ">*</text>
                                      {WORK_ORDER_CONSTANTS.DISCRIPTION}:
                                    </label>
                                    <div>
                                      <input
                                        autoComplete="off"
                                        id="edit-inp-text"
                                        className={
                                          error?.length > 0 &&
                                          error?.map(
                                            (err) => err?.name === "descption"
                                          ) &&
                                          details?.descption?.length <= 0
                                            ? "cond-style-desc-ipt-wkord-reason inpt-hover"
                                            : "desc-ipt-wkord-reason inpt-hover"
                                        }
                                        name="descption"
                                        value={details?.descption}
                                        onChange={(e) => HandleInputsWo(e)}
                                      />
                                    </div>
                                  </div>

                                  <div className="tech-btn-end-cust mt-1 justify-content-between">
                                    <div className="tech-text">
                                      Technicians:
                                    </div>
                                    <button
                                      onClick={() => handleTechnician("Add")}
                                      className={
                                        location?.state
                                          ? "btn-T-techinician"
                                          : "btn-T-techinician blurimg"
                                      }
                                    >
                                      {CUST_PRTL.ADD_TECH}
                                      <img src={Add_round} alt="btn" />{" "}
                                    </button>
                                  </div>
                                  <div className=" tech-cont">
                                    {Techie?.length > 0 ? (
                                      <div className="tech-align mt-1">
                                        {Techie.map((tech) => (
                                          <div
                                            className="tech-wod"
                                            key={tech?.id}
                                          >
                                            <Card className="Tech-card">
                                              <div className="techie-div">
                                                <img
                                                  src={prflPic} // Assuming each tech has a unique profile picture
                                                  alt="profilePicture"
                                                  className="prfl-techie-wo"
                                                />
                                                <span className="wo-techie-span">
                                                  {tech?.name}
                                                </span>
                                              </div>
                                            </Card>
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      <div
                                        className="notes-card-dscrptn"
                                        style={{ marginLeft: "32px" }}
                                      >
                                        Please Select Technicians to add to the
                                        Work Order.
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <div>
                              <img
                                src={info}
                                className="flt-l mr-1 ml-2 mb-1 "
                                width={15}
                                alt="details"
                              />
                              <span
                                className="mx-2 cursor-p"
                                onClick={() => handleInfoToggle()}
                              >
                                {WORK_ORDER_CONSTANTS.DETAILS}
                              </span>
                              {isInfo ? (
                                <div>
                                  <div className="wo-edit-card-div">
                                    <div className=" d-flex flex-column">
                                      <span className="wo-edit-name">
                                        {workOrder?.name}
                                      </span>
                                      <span className="wo-edit-name">
                                        {" "}
                                        {workOrder?.Customer?.email}
                                      </span>
                                      <span className="wo-edit-name">
                                        {workOrder?.Customer?.phone}
                                      </span>
                                      <span className="wo-edit-name">
                                        {workOrder?.Customer?.address?.street_1}
                                        ,{" "}
                                        {workOrder?.Customer?.address?.street_2}
                                        {
                                          workOrder?.Customer?.address?.city
                                        }, {workOrder?.Customer?.address?.state}
                                        , {workOrder?.Customer?.address?.zip}
                                      </span>
                                    </div>
                                    <div className="wo-edit-workdate-div">
                                      <span className="wo-edit-name">
                                        {workOrder?.type}
                                      </span>
                                      <span className="wo-edit-name ">
                                        {workOrder?.schedule_date
                                          ? new Intl.DateTimeFormat(
                                              "en-US"
                                            ).format(
                                              new Date(workOrder.schedule_date)
                                            )
                                          : ""}
                                      </span>
                                      <span className="wo-edit-name">
                                        {formattedTime}
                                      </span>
                                      <div classname="">
                                        <button
                                          style={{
                                            pointerEvents:
                                              window?.location?.href?.split(
                                                "/"
                                              )[10] === "1"
                                                ? "none"
                                                : "auto",
                                          }}
                                          className={
                                            location?.state
                                              ? "btn-T"
                                              : "btn-T blurimg"
                                          }
                                          onClick={() => {
                                            if (OpenCust === false) {
                                              AddTagModal();
                                            }
                                          }}
                                        >
                                          {WORK_ORDER_CONSTANTS.TAG}{" "}
                                          <img src={Add_round} alt="btn" />{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wo-edit-tag">
                                    <div className="tech-name-style ">
                                      <div className=" d-flex flex-wrap">
                                        {workOrder?.tags?.map((item) => (
                                          <div
                                            style={{
                                              pointerEvents:
                                                window?.location?.href?.split(
                                                  "/"
                                                )[10] === "1"
                                                  ? "none"
                                                  : "auto",
                                            }}
                                            key={item}
                                            onClick={(e) => {
                                              if (OpenCust === false) {
                                                handleDeleteModal(
                                                  e,
                                                  {
                                                    ...item,
                                                  },
                                                  workOrder
                                                );
                                              }
                                            }}
                                          >
                                            <span
                                              className="tag-styles"
                                              style={{
                                                backgroundColor: "#CCCCCC",
                                                fontSize: "12px",
                                                color: "black",
                                                wordBreak: "break-word",
                                                whiteSpace: "normal",
                                                display: "inline-block",
                                                maxWidth: "100%",
                                              }}
                                            >
                                              {" "}
                                              {item?.name}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="wo-edit-descrptn">
                                      {WORK_ORDER_CONSTANTS.REASON} :
                                    </span>
                                    <span className="wo-edit-name">
                                      {workOrder?.problem_code}
                                    </span>
                                  </div>
                                  <span className="wo-edit-descrptn">
                                    {WORK_ORDER_CONSTANTS.DISCRIPTION} :
                                  </span>
                                  <span className="wo-edit-name">
                                    {workOrder?.description}
                                  </span>
                                  <div className="tech-btn-end-cust mt-1">
                                    <button
                                      className={
                                        location?.state
                                          ? "btn-T-techinician"
                                          : "btn-T-techinician blurimg"
                                      }
                                      // onClick={() => handleTechnician("View")}
                                    >
                                      {CUST_PRTL.ADD_TECH}
                                      <img src={Add_round} alt="btn" />{" "}
                                    </button>
                                  </div>
                                  {Techie?.length > 0 ? (
                                    <div className="tech-align mt-1">
                                      {Techie.map((tech) => (
                                        <div
                                          className="tech-wod"
                                          key={tech?.id}
                                        >
                                          <Card className="Tech-card">
                                            <div className="techie-div">
                                              <img
                                                src={prflPic} // Assuming each tech has a unique profile picture
                                                alt="profilePicture"
                                                className="prfl-techie-wo"
                                              />
                                              <span className="wo-techie-span">
                                                {tech?.name}
                                              </span>
                                            </div>
                                          </Card>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div
                                      className="notes-card-dscrptn"
                                      style={{ marginLeft: "32px" }}
                                    >
                                      Please add a tech to continue
                                    </div>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </Card>
                  </div>
                  <div
                    ref={(ref) => (contentRefs.current[1] = ref)}
                    data-index={1}
                    className="content pb-1"
                    style={{ pointerEvents: OpenCust ? "none" : "auto" }}
                  >
                    <WorkFlow
                      woOrder={workOrder?.work_order_id}
                      style={{ pointerEvents: OpenCust ? "none" : "auto" }}
                      isVal={isVal}
                      WfData={WfData}
                      RequiredWf={RequiredWf}
                      isLoadData={isLoadData}
                      isWoid={isWoid}
                      custid={custid}
                      wftoken={wftoken}
                      OpenCust={OpenCust}
                    />
                  </div>

                  {/* <div
                    ref={(ref) => (contentRefs.current[2] = ref)}
                    data-index={2}
                    className="content p-1"
                  >
                    <Notes
                      data={NotesArray}
                      setNotesArray={setNotesArray}
                      apiData={{
                        id: workOrder?.id,
                        TechId: TechId,
                        customerId: workOrder?.Customer?.id,
                      }}
                      isVal={isVal}
                    />
                  </div> */}
                  {/* <div
                    ref={(ref) => (contentRefs.current[3] = ref)}
                    data-index={3}
                    className="content p-1"
                  ></div> */}
                  <div
                    style={{
                      marginLeft: "0px",
                      pointerEvents: OpenCust ? "none" : "auto",
                    }}
                    ref={(ref) => (contentRefs.current[2] = ref)}
                    data-index={2}
                    className="content pb-1"
                  >
                    <WoDetailsCommunication isVal={isVal} />
                  </div>

                  <div
                    style={{ pointerEvents: OpenCust ? "none" : "auto" }}
                    ref={(ref) => (contentRefs.current[3] = ref)}
                    data-index={3}
                    className="content pb-1"
                  >
                    <Equipments
                      Alleqpdata={woeqpdet}
                      isVal={isVal}
                      custid={custid}
                      isWoid={isWoid}
                      techid={techid}
                      techid1={techid1}
                      compid={compid}
                      OpenCust={OpenCust}
                    />
                  </div>
                  <div
                    style={{ pointerEvents: OpenCust ? "none" : "auto" }}
                    ref={(ref) => (contentRefs.current[4] = ref)}
                    className="content pb-1 card_maibody"
                    data-index={4}
                  >
                    <MediaCenter
                      isVal={isVal}
                      attachments={workOrder?.attachments}
                      custId={workOrder?.work_order_id}
                      OpenCust={OpenCust}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "0px",
                      pointerEvents: OpenCust ? "none" : "auto",
                    }}
                    ref={(ref) => (contentRefs.current[5] = ref)}
                    data-index={5}
                    className={
                      location?.state
                        ? "content pb-1"
                        : "content pb-1 pointr-events"
                    }
                  >
                    <Logs isVal={isVal} />
                  </div>
                  <div className="div-custom-p"></div>
                </div>
              </div>
            </Card>
          </Col>
          {rtExpand === "rt-expand" ? (
            ""
          ) : (
            <Col
              className="right_panel"
              style={{ width: "25%", pointerEvents: OpenCust ? "none" : "" }}
              lg={
                rtExpand === "rt-expand" && isExpand === "expand"
                  ? 0
                  : rtExpand === "rt-expand"
                  ? 0
                  : isExpand === "expand"
                  ? 2
                  : 0
              }
            >
              <WORightSideBar
                data={NotesArray}
                handleconctid={handleconctid}
                contacId={contacId}
                prContactName={prContactName}
                setNotesArray={setNotesArray}
                custId={custid?custid:custId}
                apiData={{
                  id: workOrder?.work_order_id,
                  TechId: TechId,
                  customerId: workOrder?.Customer?.id,
                }}
                isVal={isVal}
                additionalFields={additionalFields}
                extraFieldComponent={
                  <ExtraFields
                    wodAddtionalFieldsHandler={wodAddtionalFieldsHandler}
                    isVal={isVal}
                    setcreatingFields={setcreatingFields}
                    isFinalSave={addlnFlds}
                    creatingFields={creatingFields}
                    additionalFields={additionalFields}
                    setobjectValues={setobjectValues}
                    ErrorData={extraErr?.length > 0 ? extraErr : []}
                    objectValues={objectValues}
                    ErrrAdd={ErrrAdd}
                  />
                }
              />
            </Col>
          )}
        </Row>
      )}
      <AddTechList
        Open={isAddTechOpen}
        Close={() => setIsAddTechOpen(false)}
        HandlCustData={HandlCustData}
        istechkey={isVal}
        woid={woid}
        selectedwoTechs={selectedwoTechs}
      />
      <DeleteWOModal Open={delWOmodal} Close={() => setDelWOmodal(false)} />
      <ShareWoModal
        Open={shareWOModal}
        Close={() => setShareWOModal(false)}
        data={workOrder}
        path={"WoDetails"}
        woid={workOrder?.work_order_id}
        custid={workOrder?.Customer?.id}
      />
      <AddTag
        workOrderId={"WorkOrder #" + workOrder?.work_order_id}
        Open={isTagOpen}
        name={tagInpObj.name}
        color={tagInpObj.color}
        HandleTagInputs={changeTagEvent}
        isClose={() => setIsTagOpen(false)}
        isSubmit={() => HandleTagSubmit()}
      />{" "}
      <DeleteTags
        deleteTag={() => handleDeleteTag(delTagData, deleteTag)}
        handleClose={() => setShowDeleteTag(false)}
        data={delTagData}
        name={"Work Order"}
        isOpen={showDeleteTag}
        headid={workOrder?.work_order_id}
      />
      <AlrtAddnlFieldsVal
        open={addlnFlds}
        data={extraErrorList}
        name={"Work Order"}
        isClose={() => {
          setAddlnFlds(false);
          setExtraErrorList([]);
          // setIsNull(false)
        }}
      />
      <MemoryModal
        isOpen={isMemory}
        isClose={() => HandleCloseMemory()}
        id={ismemoryObj?.RefId ? ismemoryObj?.RefId : workOrder?.work_order_id}
        ismemoryObj={ismemoryObj}
        name={"Work Order"}
        woId={ismemoryObj?.RefId}
        // womemoryopt={womemoryopt}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
      />
      <ReqWfAndStepModal
        isStepVal={isStepVal}
        isClose={() => {
          setIsStepval(false);
          setIsLoadData(false);
        }}
        isLoading={isLoading}
        isReq={isReq}
        isreqWf={isreqWf}
        HandleRemainWf={HandleRemainWf}
        HandleWfConfirm={HandleWfConfirm}
      />
      <MemoryblueModal
        Open={mryblue}
        Close={() => setMryblue(false)}
        ismemoryObj={ismemoryObj}
        custname={memobj?.Customer?.name}
        memobj={memobj}
        mrydlt={mrydlt}
        itemkey={itemkey}
        isProgress={ismemProgress}
        descvalue={descvalue}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        headname={itemkey === "workorderView" ? memobj?.name : ""}
        headid={itemkey === "workorderView" ? Number(memobj?.id) : ""}
        openName={itemkey === "workorderView" ? "Workorder" : ""}
      />
      {/* <div className="wo-footer">
        <LoginFooter />
      </div> */}
    </div>
  );
}
export default WODetails;
