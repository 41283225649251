import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { MOD_TEXT_FIELD_SETUP } from "../../../../common/components/Values";

function AltInteg(props) {
  const getName = (id, key) => {
    const newData = props?.actualData[key];
    const errorTemp = newData?.find((i) => i.field_id === id);
    return errorTemp?.name || "";
  };
  return (
    <div>
      <Modal show={props.isOpen} onHide={props.isClose} centered size="sm">
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="attach-media-note line-align-bet">
            <div style={{ color: "white" }}> Error Messages</div>
            <img
              src={close}
              alt="Close"
              className="modal-header-icon"
              onClick={props.isClose}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 ">
          {
            <span className="err-mesge ml-1 cntr-hr-line ">
              {" "}
              {MOD_TEXT_FIELD_SETUP.ETR}
            </span>
          }
          <div className="ml-2 err-mod-htnr">
            {props.isFinalSave &&
              Object.keys(props?.data?.wo_mapping)?.length > 0 && (
                <>
                  <span className="err-txt-mod ">
                    {" "}
                    {MOD_TEXT_FIELD_SETUP.WRK_ODR_ADD_FLDS}:
                  </span>
                  {Object.keys(props?.data?.wo_mapping)?.map((i) => {
                    return (
                      <div>
                        {/* <span className="err-txt mx-2 ">
                          Select Type In : '{getName(i, "wo_mapping")}'
                        </span> */}
                        <ul className="mb-0">
                          {props?.data?.wo_mapping[i]?.map((j) => {
                            return (
                              <li className="err-mesge-small">
                                '{j}' is missing
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </>
              )}

            {/* Customer */}

            {(props?.isFinalSave || props?.errorKey === "customer_mapping") &&
              Object.keys(props?.data?.customer_mapping)?.length > 0 && (
                <>
                  <span className="err-txt-mod">
                    {MOD_TEXT_FIELD_SETUP.CUST_ADD_FLDS}
                  </span>
                  {Object.keys(props?.data?.customer_mapping)?.map((i) => {
                    return (
                      <div>
                        {/* <span className="err-txt mx-2">
                          Select Type In : '{getName(i, "customer_mapping")}'
                        </span> */}
                        <ul className="mb-0">
                          {props?.data?.customer_mapping[i]?.map((j) => {
                            return (
                              <li className="err-mesge-small">
                                '{j}' is missing
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </>
              )}

            {/* Contact */}
            {(props?.isFinalSave || props?.errorKey === "contact_mapping") &&
              Object.keys(props?.data?.contact_mapping)?.length > 0 && (
                <>
                  <span className="err-txt-mod">
                    {MOD_TEXT_FIELD_SETUP.CONT_ADD_FLDS}
                  </span>
                  {Object.keys(props?.data?.contact_mapping)?.map((i) => {
                    return (
                      <div>
                        {/* <span className="err-txt mx-2">
                          Select Type In : '{getName(i, "contact_mapping")}'
                        </span> */}
                        <ul className="mb-0">
                          {props?.data?.contact_mapping[i]?.map((j) => {
                            return (
                              <li className="err-mesge-small">
                                '{j}' is missing
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </>
              )}

            {/* Equipment */}
            {(props?.isFinalSave || props?.errorKey === "equipment_mapping") &&
              Object.keys(props?.data?.equipment_mapping)?.length > 0 && (
                <>
                  <span className="err-txt-mod">
                    {MOD_TEXT_FIELD_SETUP.EQUP_ADD_FLDS}
                  </span>
                  {Object.keys(props?.data?.equipment_mapping)?.map((i) => {
                    return (
                      <div>
                        <span className="err-txt mx-2">
                          Select Type In : '{getName(i, "equipment_mapping")}'
                        </span>
                        {/* <ul className="mb-0">
                        {props?.data?.equipment_mapping[i]?.map((j) => {
                          return (
                            <li className="err-mesge-small">
                              '{j}' is missing
                            </li>
                          );
                        })}
                      </ul> */}
                      </div>
                    );
                  })}
                </>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AltInteg;
