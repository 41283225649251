import React, { useContext } from "react";
import CommonHeader from "./CommonHeader";
import leftArrow from "../../../Assets/Icons/leftAr.svg";
import filterIcon from "../../../Assets/Icons/Adjt.svg";
import "./SearchStyles.css";
import { NavigateFunction } from "../../../navigation/NavigationChild";
import { Link, useNavigate } from "react-router-dom";
import SearchFilter from "./SearchFilter";
import WoIcon from "../../../Assets/Icons/work order albums.svg"
// import { AppGlobalContextUpdate } from "../../common/context/AppGlobalContext";

import { AppGlobalContext } from "../../../../common/context/AppGlobalContext";
import yesm from "../../../Assets/Icons/Yes_m.svg";
// import { workOrdrList } from "../../../../services/WorkOrdersService";
// import { useEffect } from "react";
// import WorkOrderCard from "../../../../features/workorders/WorkOrderCard";
import { getSearchResult } from "../../../../services/WorkOrdersService";

const SearchScreen = (props) => {
  // const [data, setData] = React.useState([
  //     {
  //         name: "Equipments"
  //     },
  //     {
  //         name: "Workorders"
  //     },
  //     {
  //         name: "Communications"
  //     },
  //     {
  //         name: "Customers"
  //     },

  // ]);
  const [searchvalue, setsearchValue] = React.useState("");
  const navigation = useNavigate();
  const [openSrchFilter, setopenSrchFilter] = React.useState(false);
  const [workorderslist, setwolist] = React.useState([]);
  // const [isvalue, setIsValue] = React.useState([]);

  const userId = JSON.parse(localStorage.getItem("userId"));
  const techPin = JSON.parse(localStorage.getItem("techpin"));
  const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
  const searchFilter = async (e) => {
    let characters = e.target.value;
    setsearchValue(characters);
    let searchResults = await getSearchResult(characters);
    setwolist(searchResults?.data);
  };

  //   const highlightText = (text, search) => {
  //     // if(text){
  //     const regex = new RegExp(`(${search})`, "gi");
  //     return text?.split(regex).map((part, index) =>
  //       regex.test(part) ? (
  //         <span key={index} className="highlighted">
  //           {part}
  //         </span>
  //       ) : (
  //         part
  //       )
  //     );
  //     // }
  //   };

  // useEffect(() => {
  //     getworkorders();
  // }, [])

  // const getworkorders = async () => {
  // const userId = JSON.parse(localStorage.getItem("userId"));
  // let wolist = await workOrdrList(userId);
  // setwolist(wolist.data.WorkOrderList);
  // }

  // const navToWoDetls = (e, to, params, component) => {
  // updateAppState({ currentWorkOrder: params });
  // NavigateFunction({
  //         to: to,
  //         params: { params, component },
  //         navigate: navigation,
  //     });
  //     e.stopPropagation();
  // };

  // const HandleCutomer = (e, value) => {
  // e.stopPropagation();
  // setIsCustomer(true);
  // setCustomerObj(value);
  // };

  // const HandleAddtag = (e, workorder) => {
  // e.stopPropagation();
  // setTempWorkOrder(workorder);
  // setTagItnpObjWo({ ...tagItnpObjWo });
  // setIsTopen(true);
  // };

  // const HandleShare = (e, value) => {
  // e.stopPropagation();
  // setWorkOrdrItem(value);
  // setIsOpen(true);
  // };

  // const HandleShow = (e, workOrd) => {
  // setidToMoveInProgress(workOrd.id);
  // e.stopPropagation();
  // if (workOrd.status === STATUS_TODO) {
  //   setUpdateStatusWork(workOrd);
  //   setOpen(true);
  // }
  // };

  // const handleDeleteModal = (e, data) => {
  // e.stopPropagation();
  // setDeleteTag(data);
  // setShowDeleteTag(true);
  // };

  // const HandleToggleTags = (e, value) => {
  // e.stopPropagation();
  // let Data = [...isvalue];
  // if (Data.includes(value.id)) {
  //   Data = isvalue.filter((it) => it !== value.id);
  // } else {
  //   Data = [...Data, value.id];
  // }
  // setIsValue(Data);
  // };

  // const HandleAttch = async (e, value) => {
  // saveRecentlyViewedItems(value.workorder_id, value);
  // e.stopPropagation();
  // setIsWoId(value.workorder_id);
  // setIsAttach(true);
  // setIsLoad(false);
  // let attchId = value.id;
  // let custId = value.customer_id;
  // let WorkId = value.workorder_id;
  // let eqId = value.equipment_id;
  // let techId = value.technician_id;
  // let step = value.workorder_step_id;
  // let workflow = value.workorder_workflow_id;
  // const response = await GetAttchMentByID(
  //   attchId,
  //   step,
  //   workflow,
  //   custId,
  //   WorkId,
  //   eqId,
  //   techId
  // );

  // if (response.success) {
  //   setIsLoad(true);
  //   setAttachmentData(response.data);
  // }
  // };

  const appState = useContext(AppGlobalContext);
  const handlenav = (to) => {
    NavigateFunction({
      to: to,
      params: appState?.currentWorkOrder,
      navigate: navigation,
    });
  };
  return (
    <>
      <CommonHeader />
      <div className="searchBodyheader">
        <img
          src={leftArrow}
          alt=""
          className="backArrow"
          onClick={() =>
            NavigateFunction({ to: "WorkOrders", navigate: navigation })
          }
        />
        <div className="titleSearch">Search</div>
        <input
          className="inputSearch"
          value={searchvalue}
          onChange={searchFilter}
        />
        <img
          src={filterIcon}
          alt=""
          className="searchfilterIcon"
          onClick={() => setopenSrchFilter(true)}
        />
      </div>
      <span> Exact Matches ({workorderslist?.length})</span>
 
      {searchvalue?.length > 0 &&
        workorderslist?.map((it, index) => (
          <div key={index} className="listdata">
            {it !== null && (
              <div className="listdefault">
                <div
                  className="mx-3"
                  onClick={() =>
                    handlenav(
                      `WoDetails/${it?.interface_number}/${it?.customer_id}/${userId}/${techPin}/${companyCode}/0`
                    )
                  }
                >
                  {/* {highlightText( */}
                  <Link>
                  <img src={WoIcon} alt="worder"/>
                    {" "}
                    {it?.name} # {it?.interface_number}-({it?.status})
                  </Link>
                  {/* ) */}
                </div>

                <img src={yesm} alt="" width={16} />
              </div>
            )}
          </div>
        ))}
      {searchvalue.length <= 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Please enter search to find an item.
        </div>
      )}
      {/* {searchvalue.length > 0 && workorderslist?.map((it, index) => (
                    <div key={index} className="listdata">
                        <div className="listdefault">
                            { it.tags.map((tg,index)=>(
                              <div>
                            <div className="searchedResult">{highlightText(tg.name,searchvalue)}</div>
                            <img src={yesm} alt="" width={16} />
                        </div>
                           )) }
                        </div>

                    </div>
                ))
               } */}
      {/* } */}
      {/* {
                        workorderslist?.map((data,index)=>(
                            <div key={index}>
                     <div>{data?.note + data?.name}</div>
                     </div>

                        ))
                    } */}
      {/* {searchvalue.length > 0 ? */}
      {/* (<div className="mx-2"> */}
      {/* {workorderslist.length > 0 ? (
                        workorderslist.map((item, index) => (
                            <div key={index}>

                                <WorkOrderCard
                                    eachWorkOrder={item}  
                                    techId={userId}
                                    techPin={techPin}
                                    companyId={CompanyCode}
                                    navToWoDetls={navToWoDetls}
                                    HandleCutomer={HandleCutomer}
                                    HandleAddtag={HandleAddtag}
                                    HandleShare={HandleShare}
                                    HandleShow={HandleShow}
                                    handleDeleteModal={handleDeleteModal}
                                    HandleToggleTags={HandleToggleTags}
                                    isvalue={isvalue}
                                    HandleAttch={HandleAttch}
                                />


                            </div>

                        ))
                    ) : (
                        <div className="Error-status">error</div>
                    )} */}

      {/* </div> */}

      {/* ) */}

      <SearchFilter
        isOpen={openSrchFilter}
        isClose={() => setopenSrchFilter(false)}
      />
    </>
  );
};

export default SearchScreen;
