import React, { useCallback, useEffect, useState } from "react";
import PersonImg from "../../common/Assets/Images/Buttonsperson.png";
import LocationImg from "../../common/Assets/Images/Vectorlocation.png";
import { CUSTOMER } from "../../common/components/Values";
import {
  deleteTags,
  GetMemory,
  MediaAttachments,
} from "../../services/WorkOrdersService";
import ShareImg from "../../common/Assets/Images/Vectorshare.png";
import MemoryIcon from "../../common/Assets/Icons/Memory Board Icon.svg";
import WoFile from "../../common/Assets/Images/clipboard_outline.svg";
import WoFileHistory from "../../common/Assets/Icons/CompletedWO.svg";
import EquipImg from "../../common/Assets/Images/Equipment.svg";
import { Card } from "react-bootstrap";
import "./CustomerCard.css";
import Dots3 from "../../common/Assets/Images/3x3 Dot Grid_Outlinedots.svg";
import MemoryModal from "../../common/components/memorymodal/MemoryModal";
import VectorEqpBlue from "../../common/Assets/Images/VectorEqpBlue.svg";
import CustomerModal from "../../common/components/customermodal/CustomerModal";
import DeleteTags from "../workorders/addtag/DeleteTags";
import MemoryblueModal from "../../common/components/memorymodal/MemoryblueModal";
import { SlArrowUp } from "react-icons/sl";
import { GetCustExtraDetailsById } from "../../services/AddCusEqService";
import AttachmentModal from "../workorders/AttachmentModal";
function CustomerCard(props) {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setTags(props?.data?.tags);
  }, [props?.data?.tags]);
  const [isMemory, setIsMemory] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [customerObj, setCustomerObj] = useState({});
  const [isCustomer, setIsCustomer] = useState(false);
  const [deleteCustId, setDeleteCustId] = useState(null);
 
   const HandleCutomer = (e, value) => {
    e.stopPropagation();
    setIsCustomer(true);
    setCustomerObj(value);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const [getmemory, setgetmemory] = useState("");
  const [mrydlt, setMrydlt] = useState(null);
  const descvalue = getmemory?.note;
  const [mryblue, setMryblue] = useState(false);
  const [memobj, setMemobj] = useState({});
  const [itemkey, setItemkey] = useState("");
  const HandleMemory = async(type, e, item) => {
    e.stopPropagation();
    if (type === "black") {
      setIsMemory(true);
      setIsMemoryObj(item);
      e.stopPropagation();
      setIsMemory(true);
      let items = { viewtype: 10, Reftype: 10, RefId: item?.customer_id };
      setIsMemoryObj(items);
    } else {
      setMryblue(true);
      setItemkey(type);
      let items = { viewtype: 10, Reftype: 10, RefId: item?.customer_id };
      setIsMemoryObj(items);
      setMemobj(item);
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
      );
      if (Result?.success) {
      }
      setgetmemory(Result?.data);
      setMrydlt(Result?.data?.recent_view_id);
    }
  };
  const HandleCloseMemory = () => {
    setIsMemory();
    // setMemoryDiscription("");
  };
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [delTagData, setDelTagData] = useState({});
  const handleDeleteTag = (it, tag) => {
    it?.stopPropagation();
    setDelTagData(tag);
    setDeleteCustId(tag?.reference_id);
    setShowDeleteTag(true);
  };
  const confirmDeleteTag = async (deleteTag) => {
    const response = await deleteTags(deleteTag?.tag_id);
    if (response?.success) {
      setShowDeleteTag(false);
      setDelTagData(null);
      setTags(tags.filter((tag) => tag?.tag_id !== deleteTag?.tag_id));
    }
  };
  const updateMemoryBoardStatus = (workOrderId) => {
    props.data.memoryBoard = workOrderId;
  };
  const [cardattachment,setCardAttachments] = useState([])
  const attachdata = useCallback(async()=>
  {
    const output = await GetCustExtraDetailsById(props?.data?.customer_id)
    setCardAttachments(output?.data[0]?.attachments)
  },[props?.data?.customer_id])
  useEffect(()=>
  {
    attachdata()
  },[attachdata])


  let filesObj = {};
  cardattachment?.forEach((i) => {
      if (i.file_type.includes("image")) {
        filesObj["attachments"] = [...(filesObj?.attachments || []), i];
      }
      if (
        i.file_type.includes("text") ||
        i.file_type.includes("pdf") || i.file_type.includes("application")) {
        filesObj["docs"] = [...(filesObj?.docs || []), i];
      }
    });
    const openImageInNewTab = async (document) => {
      let id = document?.id ? document?.id : document?.attachment_id;
      const output = await MediaAttachments(id);
      const base64PDF = output?.data?.content;
      const fileType = output?.data?.file_type;
      const blob = base64ToBlob(base64PDF, fileType);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      URL.revokeObjectURL(blobUrl);
    };
    const base64ToBlob = (base64, contentType) => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters?.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    };
    const [isZoomed, setIsZoomed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [zoomMessage, setZoomMessage] = useState("");
    const [imageViewExpanded, setImageViewExpanded] = React.useState(false);
    const handleImageClick = (e, index) => {
      e?.stopPropagation();
      setSelectedImageIndex(index);
      setShowModal(true);
      setZoomLevel(1);
      setZoomMessage("");
    };
    const HandleImgTgl = (e, val) => {
      e.stopPropagation();
      if (val === "dots") {
        setImageViewExpanded(true);
      } else {
        setImageViewExpanded(false);
      }
    };
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    useEffect(() => {
      setZoomLevel(1);
      setIsZoomed(false);
      setZoomMessage("");
    }, [selectedImageIndex]);
    const handleCarouselSelect = (selectedIndex) => {
      setSelectedImageIndex(selectedIndex);
    };
    const zoomIn = () => {
      if (zoomLevel < 3) {
        setZoomLevel(zoomLevel + 1);
        setZoomMessage("");
      } else {
        setZoomMessage("Can't zoom in further.");
        setIsZoomed(true);
      }
    };
  
    const zoomOut = () => {
      if (zoomLevel > 1) {
        setZoomLevel(zoomLevel - 1);
        setZoomMessage("");
      } else {
        setZoomMessage("");
        setIsZoomed(false);
      }
    };
    const filteredImages = cardattachment?.filter(
      attachment => attachment?.file_type?.startsWith("image/")
    );
  return (
    <>
      <Card>
        <div className=" mt-1 cust-div card_hovering ">
          <div
            className="  mt-2 "
            onClick={() => props?.HandleNav( props?.data?.customer_id)}
            style={{ padding: "0px 10px 0px 10px" }}
          >
            <div className="cust-cards ">
              <div className=" cust-cards-row">
                <div className="cust-name-id">
                  {props?.data?.name} #{" "}
                  {props?.data?.interface_number === null
                    ? props?.data?.customer_id
                    : props?.data?.interface_number}
                </div>
                <div className="sub-name-date">{props?.data?.subname}</div>
                <div className="sub-name-date ">
                  {props?.data?.created_date
                    ? formatDate(props.data.created_date)
                    : ""}
                </div>
              </div>

              <div>
                {props?.data?.memoryBoard === 1 ? (
                  <img
                  src={VectorEqpBlue}
                  alt="Memory"
                  width={22}
                  className="mx-2 "
                  onClick={(e) => HandleMemory("customers", e, props.data)}
                />
                ) : (
                  <img
                  src={MemoryIcon}
                  alt="Memory"
                  width={22}
                  className="mx-2 "
                  onClick={(e) => HandleMemory("black", e, props.data)}
                />
                )}

                <img
                  onClick={(e) => HandleCutomer(e, props?.data)}
                  src={PersonImg}
                  alt="PersonImg"
                  width={22}
                  className="mx-2"
                />
                <img
                  onClick={(e) => {
                    if (props?.data?.latitude && props?.data?.longitude) {
                      window.open(
                        "https://maps.google.com?q=" +
                          props?.data?.latitude +
                          "," +
                          props?.data?.longitude
                      );
                    }
                    e.stopPropagation();
                  }}
                  src={LocationImg}
                  alt="LocationImg"
                  width={12}
                  className="mx-2"
                  style={{
                    opacity:
                      props?.data?.latitude && props?.data?.longitude ? 1 : 0.5,
                    cursor:
                      props?.data?.latitude && props?.data?.longitude
                        ? "pointer"
                        : "not-allowed",
                  }}
                />

                <img
                  onClick={(e) => props?.handleCustShare(e, props?.data)}
                  src={ShareImg}
                  alt="ShareImg"
                  width={18}
                  className="mx-2 "
                />
              </div>
            </div>
            {/* <div className="mt-1 d-flex gap-2">
              {TagsArr.map((item) => (
                <div className="tag-style">{item.name}</div>
              ))}
            </div> */}

            {/* {props?.data?.tags?.map((it) => {
                return (
                  <div className=" cust-cards-row">
                    <div className="cust-name-id">{it?.tags?.color}</div>
                    <div className="sub-name-date">{it?.tag_id}</div>
                    <div className="sub-name-date ">{it?.tag_data}</div>
                  </div>
                );
              })} */}
            <div className="mt-1 d-flex flex-wrap">
              {tags?.map((tag) => (
                <span
                  key={tag?.tag_data}
                  style={{
                    backgroundColor: tag.color,
                    fontSize: "12px",
                    color: "black",
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                    display: "inline-block",
                    maxWidth: "100%",
                  }}
                  className="tag-styles"
                  onClick={(e) => handleDeleteTag(e, tag)}
                >
                  {tag.tag_data}
                </span>
              ))}
            </div>

            <div className="mt-1 d-flex gap-3 ">
              <div className="open-components">
                <img src={WoFile} width={20} height={16} alt="WoFile" />
                <span
                  onClick={(e) => {
                    props?.HandleOpenWO(props?.data);
                    e.stopPropagation();
                  }}
                >
                  {CUSTOMER.CUSTOPEN_W_O} ({props?.data?.open_work_orders})
                </span>
              </div>
              <div className="open-components">
                <img src={WoFileHistory} alt="WoFileHistory" />
                <span
                  onClick={(e) => {
                    props?.HandleClosedWO(props?.data);
                    e.stopPropagation();
                  }}
                >
                  {CUSTOMER.CUST_CMPLT_W_O} (
                  {props?.data?.completed_work_orders})
                </span>
              </div>
              <div className="open-components">
                <img src={EquipImg} width={22} height={20} alt="EquipImg" />
                <span>
                  {CUSTOMER.CUST_EQPMNTS} ({props?.data?.equipment_count})
                </span>
               
              </div>
             
            </div>
          </div>
          <div className="parent-tags">
                {cardattachment?.length > 0 &&
                  cardattachment?.slice(0, 6).map((it, index) => (
                    <img
                      key={index}
                      src={
                        it?.thumbnail.includes("base64")
                          ? it?.thumbnail
                          : `data:${it?.file_type};base64,${it?.thumbnail}`
                      }
                      className="mx-2 wo-images"
                      alt="table"
                      // onDoubleClick={(e) => openImageInNewTab(it)}
                      onClick={(e) =>{openImageInNewTab(it);
                        if (it?.file_type.startsWith("image/")) { handleImageClick(e, index)}}}
                    // onClick={(e) => HandleAttch(e, it)}
                    />
                  ))}
                <span>
                  {cardattachment?.length > 6 &&
                    !imageViewExpanded && (
                      <div onClick={(e) => HandleImgTgl(e, "dots")}>
                        <img src={Dots3} alt="Dot3" />
                      </div>
                    )}
                </span>
                <span>
                  {imageViewExpanded &&
                    cardattachment?.length > 1 &&
                    cardattachment?.slice(6).map((it, index) => (
                      <img
                        key={index}
                        src={
                          it?.thumbnail.includes("base64")
                            ? it?.thumbnail
                            : `data:${it?.file_type};base64,${it?.thumbnail}`
                        }
                        className="mx-2 wo-images"
                        alt="table"
                        onClick={(e) => handleImageClick(e,index + 6)}
                      // onClick={(e) => HandleAttch(e, it)}
                      />
                    ))}
                </span>
                {imageViewExpanded && (
                  <div onClick={() => setImageViewExpanded(false)}>
                    <SlArrowUp />
                  </div>
                )}
              </div>
        </div>
      </Card>
      <AttachmentModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedImageIndex={selectedImageIndex}
        handleCarouselSelect={handleCarouselSelect}
        zoomLevel={zoomLevel}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomMessage={zoomMessage}
        openImageInNewTab={openImageInNewTab}
        eachWorkOrder={filteredImages}
      />
      {/* <MemoryModal
       
       
        name={"Cust"}
       
        memoryDiscription={memoryDiscription}
        SaveDisc={SaveDisc}
        ismemoryObj={ismemoryObj}
        HandleMemoryDiscInputs={HandleMemoryDiscInputs}
        HandleMemory={HandleMemory}
      /> */}
      <MemoryModal
        isOpen={isMemory}
        isClose={() => HandleCloseMemory()}
        id={props?.data?.customer_id}
        ismemoryObj={ismemoryObj}
        name={"Customer"}
        woId={0}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
      />
      <MemoryblueModal
        Open={mryblue}
        Close={() => setMryblue(false)}
        custId={memobj?.customer_id}
        ismemoryObj={ismemoryObj}
        custaddrs={`${memobj?.city} ${memobj?.address !== undefined ? memobj?.address:""}${memobj?.address_2 !== undefined ? memobj?.address_2:""} ${memobj?.country} ${memobj?.zip}`}
        memobj={memobj}
        custname={memobj?.name}
        mrydlt={mrydlt}
        itemkey={itemkey}
        descvalue={descvalue}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        headname={memobj?.name}
        headid={memobj?.customer_id}
        openName={"Customer"}
      />
      <CustomerModal
        Open={isCustomer}
        Close={() => setIsCustomer(false)}
        data={customerObj}
        custId={props?.data?.customer_id}
        CustNameData={props}  
        contactname={props?.data?.contact[0]?.name}
        note ={props?.data?.contact[0]?.note}
      />
      <DeleteTags
        headid={deleteCustId}
        name={"Customer"}
        deleteTag={() => confirmDeleteTag(delTagData)}
        handleClose={() => setShowDeleteTag(false)}
        data={{ name: delTagData?.tag_data }}
        isOpen={showDeleteTag}
      />
    </>
  );
}

export default CustomerCard;
