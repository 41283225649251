import React from "react";
import { Modal } from "react-bootstrap";
import { useContext } from "react";
import wcross from "../../Assets/Icons/wcross.svg";
import Close from "../../Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import { AppGlobalContextUpdate } from "../../context/AppGlobalContext";
import { MemoryDelete } from "../../../services/WorkOrdersService";
import MemoryIcon from "../../Assets/Icons/Memory Board Icon.svg";
import blackfile from "../../Assets/Icons/blackfile.svg";
import equipmentfilled from "../../Assets/Icons/equipmentfilled.svg";
import fileroomcust from "../../Assets/Icons/fileroomcust.svg";
import ProgressBar from "../../navigation/ProgressBar";

const MemoryblueModal = (props) => {
  const navigation = useNavigate();

  const updateAppState = useContext(AppGlobalContextUpdate);
  const navToPage = (to, params, data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params, data },
      navigate: navigation,
    });
  };
  const navToWoDetls = (e, to, wdObj, isVal,woid,custid) => {
    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: { wdObj, isVal,woid:wdObj?.WorkOrderId?wdObj?.WorkOrderId:wdObj?.work_order_id,custid:wdObj?.Customer?.id?wdObj?.Customer?.id:wdObj?.custid},
      navigate: navigation,
    });
    e.stopPropagation();
  };
  const checkBeforeNavigation = (e, value) => {
    const workOrderId = props?.memobj?.id
      ? props?.memobj?.id
      : window.location.href.split("/")[5];
    const customerId = props?.memobj?.Customer?.id
      ? props?.memobj?.Customer?.id
      : props?.memobj?.customer_id;
    const userId = JSON.parse(localStorage.getItem("userId"));
    const techPin = JSON.parse(localStorage.getItem("techpin"));
    const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
    let flag = value;
    if (flag === 1 && props?.itemkey === "workorder") {
      navToWoDetls(
        e,
        `WoDetails/${workOrderId}/${customerId}/${userId}/${techPin}/${companyCode}/0`,
        props?.memobj,
        "details"
      );
    }
    if (flag === 1 && props?.itemkey === "eqps") {
      navToPage(
        `AddEquipments/${workOrderId}/${customerId}/${userId}/${techPin}/${companyCode}/0`,
        "equipdetails",
        props?.memobj
      );
    }
    if (flag === 1 && props?.itemkey === "customers") {
      NavigateFunction({
        to:`CreateNewcustomer/${0}/${props?.memobj?.customer_id}/${userId}/${techPin}/${companyCode}/${0}`,
        params: {
          key:props?.memobj?.customer_id,
        },
        navigate: navigation,
      });
    }
  };
  const memoryDelete = async () => {
    let recentid = props?.mrydlt;
    let Id = props?.headid;
    // if(props?.itemkey === "workorderView")
     await MemoryDelete(recentid, Id);
    props?.Close();
    props?.updateMemoryBoardStatus("delete");
    props?.setMryvalue(0);
    props?.setMryval(0);
  };
  return (
    <div>
      <Modal show={props?.Open} onHide={props?.Close} centered>
        <Modal.Header className="modal-bg-color  " style={{ height: "40px" }}>
          <span className=" text-white">
            {props?.headname} #{props?.headid}
          </span>
          <div className="icon-container">
            <img
              className="modal-header-icon"
              onClick={props?.Close}
              src={Close}
              alt="Close"
            />
            <span className="overlay-text">Close</span>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            minHeight: "200px",
          }}
        >
          <div className=" d-flex flex-column">
            {props?.itemkey === "eqps" || props?.itemkey === "eqpsView" ? (
              <>
                <span>
                  Model #{" "}
                  <span className="fltxt">{props?.memobj?.model_number}</span>
                </span>
                <span>
                  Serial #{" "}
                  <span className="fltxt">{props?.memobj?.serial_number}</span>
                </span>
              </>
            ) :( props?.itemkey === "customers" || props?.itemkey === "customersview") ? (
              <>
                <span>
                  Customer # <span className="fltxt">{props?.custId}</span>
                </span>
                <span>
                  Address: <span className="fltxt">{props?.custaddrs}</span>
                </span>
              </>
            ) : (
              <span>
                Description:{" "}
                <span className="fltxt">
                  {/* {props?.memobj?.Customer?.name} */}
                  {props?.custname}
                </span>
              </span>
            )}
            <span>Note:</span>
            {props?.isProgress === true ? (
              <div className="progress-bar" style={{ height: "250px" }}>
                <ProgressBar />
              </div>
            ) : (
              <textarea
                readOnly
                placeholder="Please Enter Discription"
                className="w-100 cursor-p inpt-add-eqp fltxt"
                name="memoryDiscription"
                value={props?.descvalue}
                style={{ height: "250px", outline: "none" }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-styles">
          {props?.itemkey === "eqps" ||
          props?.itemkey === "workorder" ||
          props?.itemkey === "customers" ? (
            <div
              className=""
              style={{ marginRight: "0" }}
            >
              
              <div
                className="d-flex justify-content-between"
                style={{ width: "100%" }}
              >
                <div
                style={{ width: "auto", margin: "0 auto" }}
                className="modal-footer-save-cancel-btns text-center  p-3"
                onClick={props?.isClose}
              >
                <span>
                  <img className=" mb-1" src={MemoryIcon} width={18} alt="" />
                  <span
                    style={{ fontSize: "16px" }}
                    onClick={() => memoryDelete()}
                  >
                    {" "}
                    Remove from memory Board
                  </span>
                </span>
              </div>
                <div
                  className="modal-footer-save-cancel-btns mx-1  p-3"
                  onClick={props?.Close}
                  style={{ width: "auto" }}
                >
                  <span>
                    <img className=" " src={wcross} width={16} alt="" />
                    <span style={{ fontSize: "16px" }}> Close </span>
                  </span>
                </div>

                <div
                  className="modal-footer-save-cancel-btns mx-1 p-3"
                  onClick={props?.Close}
                  style={{ width: "auto" }}
                >
                  <span onClick={(e) => checkBeforeNavigation(e, 1)}>
                    <img
                      className="mb-1 mx-1"
                      src={
                        props?.itemkey === "workorder"
                          ? blackfile
                          : props?.itemkey === "eqps"
                          ? equipmentfilled
                          : props?.itemkey === "customers"
                          ? fileroomcust
                          : blackfile
                      }
                      width={16}
                      alt=""
                    />
                    <span style={{ fontSize: "16px" }}>
                      Open {props?.openName}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="d-flex flex-column "
              style={{ marginRight: "80px" }}
            >
              <div
                style={{ width: "300px" }}
                className="modal-footer-save-cancel-btns m-2  p-3"
                onClick={props?.isClose}
              >
                <span>
                  <img
                    className="mb-1 mx-1"
                    src={MemoryIcon}
                    width={18}
                    alt=""
                  />
                  <span
                    style={{ fontSize: "16px" }}
                    onClick={() => memoryDelete()}
                  >
                    Remove from memory Board
                  </span>
                </span>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <div
                  className="modal-footer-save-cancel-btns mx-1 p-3 text-center"
                  onClick={props?.Close}
                  style={{ width: "200px" }}
                >
                  <span>
                    <img className="" src={wcross} width={16} alt="" />
                    <span style={{ fontSize: "16px" }}> Close </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MemoryblueModal;
