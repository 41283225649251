import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommonHeader from '../../common/framework/header/HeaderCommon/CommonHeader';
import { EquipById } from '../../services/WorkOrdersService';
import wcross from "../../common/Assets/Icons/wcross.svg";
import plus from "../../common/Assets/Icons/plusvector.svg";
import EqpSuFooter from "../../common/framework/footer/EqpSuFooter";
import "./EquipmentListPage.css";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import EQPupModal from './EQPupModal';
function EquipmentListPage(props) {

  const {isWoid} = useParams()
  const [sortedEquipments, setSortedEquipments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
const location = useLocation()
const work_flow_id = location?.state;
  const fetchEquipments = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await EquipById(isWoid);
      setSortedEquipments(response?.data)
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  },[isWoid])

  useEffect(() => {
    localStorage?.removeItem("eqpid");
    if(isWoid) fetchEquipments();
  }, [fetchEquipments, isWoid]);
  const handleCancel = () => {
    navigate(-1);
  };
  const navigation = useNavigate();
  const navToPage = (to, params,data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }

    NavigateFunction({
      to: to,
      params: { params: params, data, wf_id:work_flow_id},
      navigate: navigation,
    });
  };
  // const navToPage = () => {
  //   navToPage(`AddEquipments/${props.isWoid}/${props.custid}/${props.techid}/${props.techid1}/${props.compid}`)
  // };

  //  const handleCardClick = (equipment) => {
  //    navigate(`AddEquipments/${props.isWoid}/${props.custid}/${props.techid}/${props.techid1}/${props.compid}`, { state: equipment });
  //  };
  const [selectedeqp, setSelectedeqp] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const clickHandler = (item) => {
    setSelectedeqp(item);
    setIsOpen(true);
  };
  return (
    <>
      <div className="sticky-header">
        <CommonHeader />
      </div>
      <div className="sticky-header-content">
        <div className="add-equipment-heading">
          <span className="mheading">Add Equipment</span>
        </div>
        <div className="buttons-container">
          <div className="mheading1">Select a piece of equipment to use on this work order.</div>
          <button className="cancl-btn" onClick={handleCancel}>
            <span>Cancel</span>&nbsp;
            <img src={wcross} alt="Close" className="closeic1" />
          </button>
          <button className="cancl-btn" onClick={() => navToPage(`AddEquipments/${params.isWoid}/${params.custid}/${params.techid}/${params.techid1}/${params.compid}/0`,"equipcreate",0)}>
            <span>Create New</span>&nbsp;
            <img  src={plus} alt="Create" className="closeic1" />
          </button>
        </div>
      </div>
      <div className="library-content-wrapper">
        {isLoading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <div className="equipment-list">
            {Array.isArray(sortedEquipments) && sortedEquipments.map((equipment) => (
              <div className="cad1" key={equipment.equipment_id}  onClick={() => clickHandler(equipment)}>
               {/* onClick={() => handleCardClick(equipment)}  */}
                <div className="sub_cnt">
                  <p>Air Conditioning <b>Unit</b></p>
                  <div className="sub_cnt1">
                    <span className="hedngstyl">Model:</span>
                    <span className="tri"> {equipment.model_number}</span>
                    <span className="hedngstyl">Serial No:</span>
                    <span className="tri">{equipment.serial_number}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <EQPupModal
        Open= {isOpen}
        Close={() => setIsOpen(false)}
         data ={selectedeqp}
         work_flow_id={work_flow_id}
        />
      </div>
      <EqpSuFooter />
    </>
  );
}

export default EquipmentListPage;
