import React, { useState } from "react";
import infoIcon from "../../../common/Assets/Icons/Desk_alt.svg";
import iconizerSubtract from "../../../common/Assets/Icons/iconizerSubtract.svg";
import docfilled from "../../../common/Assets/Icons/docfilled.svg";
import filledlogs from "../../../common/Assets/Icons/filledlogs.svg";
import logsIcon from "../../../common/Assets/Icons/Logs_outline.svg";
import bookshelffilled from "../../../common/Assets/Icons/bookshelffilled.svg";
import "../../workorders/WorkOrderDetails/WODetailsSideBar.css";
import bookshelf from "../../../common/Assets/Icons/bookshelf.svg"
import clipboardoutline from "../../../common/Assets/Icons/clipboardoutline.svg"
import "./AddEquipmentHeader.css"

function AddEquipmentHeader(props) {
  const items = [
    { prevIcon: infoIcon, HoverIcon: iconizerSubtract, text: 'Info' },
    { prevIcon: clipboardoutline, HoverIcon: docfilled, text: 'Work Orders' },
    { prevIcon: bookshelf, HoverIcon: bookshelffilled, text: 'Library' },
    { prevIcon: logsIcon, HoverIcon: filledlogs, text: 'Logs' },
   
  ];
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleItemClick = (index) => {
    props?.scrollCallback(index); 
  };
  return (
    // <div className="eqp-side-bar-left">
    //   <div
      
    //     className="eqp-sidebar-div1"
    //     onClick={() => {
    //       props.scrollCallback(0);
    //     }}
    //   >
    //     <img src={infoIcon} alt="infoIcon"  />
    //     &nbsp;<span className="eqp-text-sidebar">Info</span>
    //   </div>
    //   <div
    //   id="workflow"
    //     className="eqp-sidebar-div2"
    //     onClick={() => {
    //       props.scrollCallback(1);
    //     }}
    //   >
    //     <img src={clipboardoutline} alt="workflowIcon"  />
    //     <span className="eqp-text-sidebar">Work Orders</span>
    //   </div>

    //   <div
    //   id="mediacenter"
    //     className="eqp-sidebar-div3"
    //     onClick={() => {
    //       props.scrollCallback(2);
    //     }}
    //   >
    //     <img src={bookshelf} alt="MediaCenter" />
    //     <span className="eqp-text-sidebar">Library </span>
    //   </div>
    //   <div 
    //   id="logs"
    //     className="eqp-sidebar-div4"
    //     onClick={() => {
    //       props.scrollCallback(3);
    //     }}
    //   >
    //     <img src={logsIcon} alt="Logs" />
    //     <span className="eqp-text-sidebar">Logs</span>
    //   </div>
    // </div>
    <div className="sidebar mt-3">
 {items?.map((item, index) => 
      <span
        className="sidebar sdbar d-flex cursor-p"
        onClick={() => handleItemClick(index)}
        key={index}
        button
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <span >
          <img 
            src={hoveredIndex === index ? item?.HoverIcon : item?.prevIcon} 
            alt="icon"
            style={{ width: '24px', height: '24px' }}
          />
        </span>
        <span className="sidebarText">{item?.text}</span>
        {/* <ListItemText  primary={item?.text} /> */}
      </span>
    )}
    </div>
  );
}

export default AddEquipmentHeader;
