import React, { useState } from "react";
import DataTable from "react-data-table-component";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import Table from "../../common/Assets/Images/tableStk.png";
import { Col, Row } from "react-bootstrap";
import add from "../../common/Assets/Icons/addw.svg";
import Sort2 from "../../common/Assets/Icons/Sort2.svg";
import "./CustomerTable.css";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import {
  CUSTOMER_TABLE,
} from "../../common/components/Values";
import { TABLE_CUSTOMER_CUSTOMSTYLES } from "../../features/customers/TableCustomer";
// import { useLocation } from "react-router-dom";
import Sort_down from "../../common/Assets/Icons/Sort_down.svg";
import Sort_up from "../../common/Assets/Icons/Sort_up.svg";
import { useEffect } from "react";
import SideTable from "../../common/components/workordertable/SideTable";
import { CustomerSearch } from "./CustomerSearch";
import CustomerSideBar from "./CustomerSideBar";
import { FilterCustData } from "../../common/components/Constants";
import memoryIcon from "../../common/Assets/Icons/Memory Board Icon.svg"
import VectorEqpBlue from "../../common/Assets/Images/VectorEqpBlue.svg"
import { GetMemory } from "../../services/WorkOrdersService";
import MemoryblueModal from "../../common/components/memorymodal/MemoryblueModal";
import MemoryModal from "../../common/components/memorymodal/MemoryModal";
import { CustomerListGet } from "../../services/SettingsServices";
import ProgressBar from "../../common/navigation/ProgressBar";
import CustomerModal from "../../common/components/customermodal/CustomerModal";
function CustomerTable() {
  const [isNavData, setIsNavData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rightData] = useState(FilterCustData);
  const [isTabCol, setIsTabCol] = useState(FilterCustData);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [mrydlt, setMrydlt] = useState(null);
  const [getmemory , setgetmemory] = useState([])
  const [isMemoryModalOpen, setIsMemoryModalOpen] = useState(false);
  const [womemoryopt, setWomemoryopt] = useState({});
  const [isProgress, setIsProgress] = useState(false);
  const [ismemProgress, setIsMemProgress] = useState(false);
  const [itemkey, setItemkey] = useState("");
  const [mryblue, setMryblue] = useState(false);
  const [memobj, setMemobj] = useState({});
  // const [mryval, setMryval] = useState(null);

  const [isSortedCustomerNameAsc, setIsSortedCustomerNameAsc] = useState("true");
  const [sortCustomerIcon, setSortCustomerIcon] = useState(Sort_down);
  const [sortOrdercustnum, setSortOrdercustnum] = useState("desc");
  const [sortOrder1primary, setSortOrder1primary] = useState("true");
  const [sort_WO_Order, setSort_WO_Order] = useState("desc");
  const [sortzip, setSortzip] = useState("desc");
  const [sortcustnumIcon, setSortcustnumIcon] = useState(Sort_down);
  const [sortprimaryIcon, setSortprimaryIcon] = useState(Sort_down);
  const [sortphoneIcon, setSortphoneIcon] = useState(Sort_down); 
  const [sort_WO_Icon, setSort_WO_Icon] = useState(Sort_down);
  const [sortzipIcon, setSortzipIcon] = useState(Sort_down);
  const [isSortedAddressAsc, setIsSortedAddressAsc] = useState(true);
  const [sortAddressIcon, setSortAddressIcon] = useState(Sort_down);
  
  const customStyles = TABLE_CUSTOMER_CUSTOMSTYLES;
  // const location = useLocation();
  const custSummary = async () => {
    setIsProgress(true)
    const output = await CustomerListGet();
    setIsProgress(false)
    setIsNavData(output?.data[0]?.objects)
    setTableData(output?.data[0]?.objects)
  };
  useEffect(() => {
    custSummary();
  }, []);
  const descvalue = getmemory?.note;
  const HandleGetmemory = async () => {
    try {
      const Result = await GetMemory(
        ismemoryObj?.viewtype,
        ismemoryObj?.Reftype,
        ismemoryObj?.RefId
      );
      setgetmemory(Result?.data);
      setMrydlt(Result?.data?.recent_view_id);
    } catch (error) {}
  };
  
// const [actid,setActid] = useState(null)
  const updateMemoryBoardStatus = (custId,data) => {
    // setActid(data?.message?.reference_id)
    let filterNewWo = isNavData?.filter((item)=> item?.customer_id === womemoryopt?.customer_id)
    if(filterNewWo?.length > 0)
    {
      if(custId === "delete"){
        filterNewWo[0].memoryBoard = 0
      }else{
        filterNewWo[0].memoryBoard = 1
      }
    }
    else{
    }
    // setIsNavData([...isNavData,filterNewWo[0]])
  };
  const HandleMemoryIconClick = async(type, e, item) => {
    e.stopPropagation();
    HandleGetmemory()
    if (type === "black") {
      setIsMemoryModalOpen(true);
      setWomemoryopt(item);
      let items = { viewtype: 10, Reftype: 10, RefId: +item?.customer_id };
      setIsMemoryObj(items);
    } else {
      setWomemoryopt(item);
      setItemkey(type);
      setMryblue(true);
      let items = { viewtype: 10, Reftype: 10, RefId: +item?.customer_id };
      setIsMemoryObj(items);
      setMemobj(item);
      setIsMemProgress(true)
        const Result = await GetMemory(
          items?.viewtype,
          items?.Reftype,
          items?.RefId
        );
        if(Result?.success)
        {
          setIsMemProgress(false)
          setgetmemory(Result?.data);
        setMrydlt(Result?.data?.recent_view_id);
        }
    }
  };
  const handleSortCustomerName = ()=>{
    setIsSortedCustomerNameAsc((prev) => !prev);
    setSortCustomerIcon(isSortedCustomerNameAsc ? Sort_up : Sort_down);
    const sortedData = [...isNavData]?.sort((a, b) => {
      const nameA = a?.name ? a?.name?.toLowerCase() : null;
      const nameB = b?.name ? b?.name?.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return isSortedCustomerNameAsc
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setIsNavData(sortedData);
  }

  const handlenumbersort = () => {
    const newSortOrder = sortOrdercustnum === "desc" ? "asc" : "desc";
    setSortOrdercustnum(newSortOrder);
    setSortcustnumIcon(newSortOrder === "asc" ? Sort_up : Sort_down);
    const sortedData = [...isNavData]?.sort((a, b) => {
      if (!a?.customer_id && !b?.customer_id) return 0;
      if (!a?.customer_id) return 1;
      if (!b?.customer_id) return -1;
      return newSortOrder === "asc"
        ? a?.customer_id - b?.customer_id
        : b?.customer_id - a?.customer_id;
    });
    setIsNavData(sortedData);  
  };

  const handleprimarycntcsort = () => {
    setSortOrder1primary((prev) => !prev);
    setSortprimaryIcon(sortOrder1primary ? Sort_up : Sort_down);
    const sortedData = [...isNavData]?.sort((a, b) => {
      const nameA = a?.primaryContact[0]?.name ? a?.primaryContact[0]?.name?.toLowerCase() : null;
      const nameB = b?.primaryContact[0]?.name ? b?.primaryContact[0]?.name?.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return sortOrder1primary
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setIsNavData(sortedData);
  }

  const handleSortAddress = () => {
    const newSortOrder = isSortedAddressAsc ? "desc" : "asc";
    setIsSortedAddressAsc(!isSortedAddressAsc);
    setSortAddressIcon(isSortedAddressAsc ? Sort_up : Sort_down);
    const sortedData = [...isNavData]?.sort((a, b) => {
      const addressA = a?.city ? a?.city.toLowerCase() : null;
      const addressB = b?.city ? b?.city.toLowerCase() : null;
      if (!addressA && !addressB) return 0;
      if (!addressA) return 1;
      if (!addressB) return -1;
      if (!isNaN(addressA) && !isNaN(addressB)) {
        return newSortOrder === "asc" ? addressA - addressB : addressB - addressA;
      }
      return newSortOrder === "asc"
        ? addressA.localeCompare(addressB, undefined, { numeric: true })
        : addressB.localeCompare(addressA, undefined, { numeric: true });
    });
    setIsNavData(sortedData);
  };

  const handleworkorders =()=>{
    const newSortOrder = sort_WO_Order === "desc" ? "asc" : "desc";
    setSort_WO_Order(newSortOrder);
    setSort_WO_Icon(newSortOrder === "asc" ? Sort_up : Sort_down);
    const sortedData = [...isNavData]?.sort((a, b) => {
      if (!a?.total_work_orders && !b?.total_work_orders) return 0;
      if (!a?.total_work_orders) return 1;
      if (!b?.total_work_orders) return -1;
      return newSortOrder === "asc"
        ? a?.total_work_orders - b?.total_work_orders
        : b?.total_work_orders - a?.total_work_orders;
    });
    setIsNavData(sortedData);
  }

  const handlezip = () =>{
    const newSortOrder = sortzip === "desc" ? "asc" : "desc";
    setSortzip(newSortOrder);
    setSortzipIcon(newSortOrder === "asc" ? Sort_up : Sort_down);
    const sortedData = [...isNavData]?.sort((a, b) => {
      if (!a?.zip && !b?.zip) return 0;
      if (!a?.zip) return 1;
      if (!b?.zip) return -1;
      return newSortOrder === "asc"
        ? a?.zip - b?.zip
        : b?.zip - a?.zip;
    });
    setIsNavData(sortedData);
  }
const [customerid,setcustomerid] = useState()
const [primarycntcmodal,setprimarycntcmodal] = useState(false)
const [primarycntctobj,setprimarycntcnobj] = useState({})
  const handleprimarycntct = (row) =>{
      setprimarycntcmodal(true)
      setprimarycntcnobj(row?.primaryContact[0])
      setcustomerid(row.customer_id)
  }

  let userId = localStorage.getItem("userId");
  const CustomersData = [
    {
      name: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
             <img className="ml-3 cursor-p" src={Sort2} width={20} height={20} alt="open srt" />
        </div>
      ),
      selector: (row) => (
        <span className="table-date">
        {row?.memoryBoard  > 0 ? (
          <img
            onClick={(e) =>  HandleMemoryIconClick("customers", e, row)}
            src={VectorEqpBlue}
            alt="memoryIconBlue"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img
            onClick={(e) => HandleMemoryIconClick("black", e, row)}
            width={22}
            src={memoryIcon}
            alt="MemoryIcon"
            style={{ cursor: "pointer" }}
          />
        )}
      </span>
      ),
      width: "60px",
    },
  
    {
      name: (
        <div className="c-table-head-cells cursor-p"
        onClick={handleSortCustomerName}
        >
          {CUSTOMER_TABLE?.CUSTOMER}
          <img src={sortCustomerIcon} width={20} height={20} alt="sort icon" />
        </div>
        
      ),
      cell: (row) => <div
      className="table-cust"
      style={{
        cursor: "pointer",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() =>HandleCNC(row.customer_id,"edit")
      }>{row.name}</div>,
      width: "170px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Customer Name"),
    },
    {
      name: (
        <div className="c-table-head-cells cursor-p"
        onClick={handlenumbersort}
        >
          {CUSTOMER_TABLE?.CUST_NUMBER}
          <img src={sortcustnumIcon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      cell: (row) => <div 
      className="table-cust "
      style={{
        cursor: "pointer",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() =>HandleCNC(row.customer_id,"edit")
      }
      ><span className="">{row.customer_id}</span></div>,
      width: "170px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Customer Number"),
    },

    {
      name: (
        <div className="c-table-head-cells cursor-p"
        onClick={handleprimarycntcsort}
        >
          {CUSTOMER_TABLE?.PRIM_CONTACT}
          <img src={sortprimaryIcon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      cell: (row) => <div
      className="table-cust "
      style={{
        cursor: "pointer",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={()=>handleprimarycntct(row)}
      >{row?.primaryContact[0]?.name}</div>,
      width: "170px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Primary Contact"),
    },
    {
      name: (
        <div className="c-table-head-cells cursor-p">
          {CUSTOMER_TABLE?.PH_NUMBER}
          <img src={sortphoneIcon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      selector: (row) => <div>{row.telephone}</div>,
      right: true,
      width: "150px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Phone Number"),
    },
    // {
    //   name: (
    //     <div className="c-table-head-cells">
    //       {CUSTOMER_TABLE?.PR_BRANCH}
    //       <img src={Sort2} width={20} height={20} alt="open srt" />
    //     </div>
    //   ),
    //   selector: (row) => <div>{row.primaryNumber}</div>,
    //   width: "170px",
    // },
    {
      name: (
        <div className="c-table-head-cells cursor-p"
        onClick={handleSortAddress}
        >
          {CUSTOMER_TABLE?.CITY}
          <img src={sortAddressIcon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      selector: (row) => <div>{row.city}</div>,
      width: "150px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Address"),
    },

    {
      name: (
        <div className="c-table-head-cells cursor-p"
        onClick={handlezip}
        >
          {CUSTOMER_TABLE?.ZIP}
          <img src={sortzipIcon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      selector: (row) => <div className=" text-center mr-3">{row.zip}</div>,
      right: true,
      width: "100px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Zip"),
    },
    // {
    //   name: (
    //     <div className="c-table-head-cells">
    //       {CUSTOMER_TABLE?.LAST_SERV}
    //       <img src={Sort2} width={20} height={20} alt="open srt" />
    //     </div>
    //   ),
    //   selector: (row) => <div>{row.primaryNumber}</div>,
    //   width: "140px",
    // },
    // {
    //   name: (
    //     <div className="c-table-head-cells">
    //       {CUSTOMER_TABLE?.TOT_REVENUE}
    //       <img src={Sort2} width={20} height={20} alt="open srt" />
    //     </div>
    //   ),
    //   selector: (row) => <div>{row.primaryNumber}</div>,
    //   width: "170px",
    // },
    {
      name: (
        <div className="c-table-head-cells cursor-p"
        onClick={handleworkorders}
        >
          {CUSTOMER_TABLE?.TOT_WO}
          <img src={sort_WO_Icon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      selector: (row) => <div>{row.total_work_orders}</div>,
      width: "170px",
      omit: isTabCol?.length > 0 && isTabCol?.includes("Total Work Orders"),
    },
  ];
  
  const [isSelectCheck, setIsSelectCheck] = useState(true);
  const [FromDate, setFromDate] = React.useState();
  // const [ToDate, setToDate] = React.useState();
  // const [tempTableData, setTempTableData] = useState([]);
  // const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input-2 m-1"
  //     placeholder="Select Date"
  //     onClick={onClick}
  //     onChange={onChange}
  //     ref={ref}
  //   ></input>
  // ));
  // const DateFilter = (jsonData, todate) => {
  //   let firstDate = moment(FromDate).format("YYYY-MM-DD");
  //   let lastDate = moment(todate).format("YYYY-MM-DD");
  //   // setToDate(todate);
  //   if (FromDate && todate) {
  //     let filteredData = jsonData.filter((data) => {
  //       const dateFormat = moment(data.request_date).format("YYYY-MM-DD");
  //       return dateFormat >= firstDate && dateFormat <= lastDate;
  //     });
  //     if (filteredData) {
  //       setTempTableData(filteredData);
  //     }
  //   }
  // };
  const navigation = useNavigate();
  const navToCustomers = (to, item) => {
  
    NavigateFunction({
      to: to,
      params: {item},
      navigate: navigation,
    });
  };
  const techPin = JSON.parse(localStorage.getItem("techpin"));
  const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
  const HandleCNC = (key,item) => {
    NavigateFunction({
      to:`CreateNewcustomer/${0}/${key}/${userId}/${techPin}/${companyCode}/${0}`,
      params: {
        key,
        item,
      },
      navigate: navigation,
  })
  };
  const [isExpand, setIsExpand] = useState("");
  const [rtExpand, setRtExpand] = useState("");

  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  const HandleRtExpand = (val) => {
    setRtExpand(val);
  };
  const [over, setOver] = useState(false);
  const [SearchInputs, setSearchInputs] = useState({
    isCustName: "",
    isTags: "",
    isCity: "",
    isContacts:""
  });
  const HandleBtnStatus = (value) => {
    setSearchInputs({
      isCustName: "",
      isTags: "",
      isCity: "",
      isContacts:""
    });
    // CustomerGet(value);
  };
  const HandleInputs = (e) => {
    const { name, value } = e.target;
    setSearchInputs({
      ...SearchInputs,
      [name]: value,
    });
  };
  const HandleCheckWoType = (e, tableCol) => {
    if (e.target.checked) {
      setIsTabCol(isTabCol?.filter((i) => i !== tableCol));
      setIsSelectCheck(e.target.checked);
    } else {
      setIsTabCol([...isTabCol, tableCol]);
    }
  };
  const HandleClearAll = () => {
    setIsTabCol(FilterCustData);
    setIsSelectCheck(true);
  };
  // const HandleClearAll = () => {
  //   setSearchInputs({
  //     isCustName: "",
  //     isTags: "",
  //     isCity: "",
  //   });
  //   setIsTabCol(FilterCustData);
  //   setIsSelectCheck(true);
  //   setIsNavData(CustomerListCopy);
  
  //   // If there are other filter-related states, reset them here
  //   // Example: setSomeOtherFilter(defaultState);
  // };
  const HandleBlur = () => {
    let Res = CustomerSearch(SearchInputs,tableData,"Table");
    setIsNavData(Res);
  };
  const HandleClearText = (key) => {
    if (key === "isCustName") {
      setSearchInputs({
        isCustName: "",
        isTags: SearchInputs?.isTags,
        isCity: SearchInputs?.isCity,
        isContacts:SearchInputs?.isContacts
      });
      let Res = CustomerSearch(
        {
          isCustName: "",
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isContacts:SearchInputs.isContacts
        },tableData,
        "Table"
      );
      setIsNavData(Res);
    }

    if (key === "isCity") {
      setSearchInputs({
        isCustName: SearchInputs?.isCustName,
        isTags: SearchInputs?.isTags,
        isCity: "",
        isContacts:SearchInputs?.isContacts
      });
      let Res = CustomerSearch(
        {
          isCustName: SearchInputs?.isCustName,
          isTags: SearchInputs?.isTags,
          isCity: "",
          isContacts:SearchInputs.isContacts
        },tableData,
        "Table"
      );
      setIsNavData(Res);
    }
    if (key === "isTags") {
      setSearchInputs({
        isCustName:SearchInputs?.isCustName ,
        isTags: "",
        isCity: SearchInputs?.isCity,
        isContacts:SearchInputs?.isContacts
      });
      let Res = CustomerSearch(
        {
          isCustName: SearchInputs?.isCustName,
          isTags:"",
          isCity: SearchInputs.isCity,
          isContacts:SearchInputs.isContacts
        },tableData,
        "Table"
      );
      setIsNavData(Res);
    }
    if (key === "isContacts") {
      setSearchInputs({
        isCustName: SearchInputs?.isCustName,
        isTags: SearchInputs?.isTags,
        isCity: SearchInputs?.isCity,
        isContacts:""
      });
      let Res = CustomerSearch(
        {
          isCustName: SearchInputs?.isCustName,
          isTags: SearchInputs?.isTags,
          isCity: SearchInputs?.isCity,
          isContacts:""
        },tableData,
        "Table"
      );
      setIsNavData(Res);
    }
  };
  const HandleClearInputs = (key) => {
    if (key === "clearAll") {
      setSearchInputs({
        isCustName: "",
        isTags: "",
        isCity: "",
        isContacts:""
      });
      
      setIsNavData(tableData);
    }
  };

  return (
    <div>
      <div className="top-header-cust-Table">
        <CommonHeader />
      </div>
      {isProgress === true ? (
        <div className="progress-bar">
          <ProgressBar />
        </div>
      ) : (
        <>
      <div className="mx-2">
        <div className=" customerdata-table-styles flx-wrp m-2">
          <div>
            <img
              src={Table}
              alt="table"
              className="cursor-p mt-1"
              onClick={() => navToCustomers("Customers")}
            />
             <span className="customer-text">Customers</span>
          </div>
          <div className="line-align">
            <div className="d-flex flx-wrp m-1 ">
              <button className="customer-btn  " onClick={() => HandleCNC(0)}>
                <span className="c-buttonName ">Create New Customer</span>
                <img alt="" src={add} className="add-c-btn " />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Row className="w-100">
        {isExpand === "expand" ? (
          ""
        ) : (
          <Col
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            // style={{
            //   pointerEvents: isProgress === true && "none",
            // }}
            sm={isExpand === "expand" ? 10 : 2}
            xs={isExpand === "expand" ? 10 : 2}
            md={isExpand === "expand" ? 10 : 2}
            lg={isExpand === "expand" ? 0 : 2}
            className="workOrderTable-col1"
          >
            < CustomerSideBar
            HandleBtnStatus={HandleBtnStatus}
            HandleInputs={HandleInputs}
            HandleBlur={HandleBlur}
            SearchInputs={SearchInputs}
            HandleClearInputs={HandleClearInputs}
            HandleClearText={HandleClearText}
           
            />
          </Col>
        )}
        <Col
          sm={
            isExpand === "expand" && rtExpand === "rt-expand"
              ? 12
              : isExpand === "expand"
              ? 11
              : rtExpand === "rt-expand"
              ? 10
              : 9
          }
          xs={
            isExpand === "expand" && rtExpand === "rt-expand"
              ? 12
              : isExpand === "expand"
              ? 11
              : rtExpand === "rt-expand"
              ? 10
              : 9
          }
          md={
            isExpand === "expand" && rtExpand === "rt-expand"
              ? 12
              : isExpand === "expand"
              ? 11
              : rtExpand === "rt-expand"
              ? 10
              : 9
          }
          lg={
            isExpand === "expand" && rtExpand === "rt-expand"
              ? 12
              : isExpand === "expand"
              ? 11
              : rtExpand === "rt-expand"
              ? 10
              : 9
          }
          className="workOrderTable-col2"
        >
         
            <>
              <span
                className={
                  isExpand === "expand" || over
                    ? "backbtn-hvr2  p-0 m-0"
                    : "backbtn-hvr  p-0 m-0"
                }
              >
                {isExpand === "expand" ? (
                  <MdChevronRight size={25} onClick={() => HandleExpand("")} />
                ) : (
                  <MdChevronLeft
                    size={25}
                    onClick={() => HandleExpand("expand")}
                  />
                )}
              </span>

              <span
                className={
                  rtExpand === "rt-expand" ? "backbtntable2" : "backbtntable"
                }
              >
                {rtExpand === "rt-expand" ? (
                  <MdChevronLeft size={25} onClick={() => HandleRtExpand("")} />
                ) : (
                  <MdChevronRight 
                    size={25}
                    onClick={() => HandleRtExpand("rt-expand")}
                  />
                )}
              </span>
              <div className="card p-2">
                <div
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <DataTable
                  className="cust-data-table-fix-height"
                    columns={CustomersData}
                    data={isNavData}
                    fixedHeader
                    selectableRows={false}
                    customStyles={customStyles}
                    
                  />
                </div>
              </div>
            </>
        </Col>
        {rtExpand === "rt-expand" ? (
          ""
        ) : (
          <Col
            className="workOrderTable-col3"
            lg={rtExpand === "rt-expand" ? 0 : 1}
            md={rtExpand === "rt-expand" ? 0 : 1}
            sm={rtExpand === "rt-expand" ? 0 : 1}
            xs={rtExpand === "rt-expand" ? 0 : 1}
          >
            <SideTable
              rightData={rightData}
              isSelectCheck={isSelectCheck}
              isTabCol={isTabCol}
              HandleCheckWoType={HandleCheckWoType}
              HandleClearAll={HandleClearAll}
            />
          </Col>
        )}
      </Row>
      </>
      )}
      {/* <div className="test-style">
        <CommonFooter />
      </div> */}
       <MemoryModal
        isOpen={isMemoryModalOpen}
        id={womemoryopt?.customer_id}
        name={"Customer"}
        descvalue={descvalue}
        isClose={() => setIsMemoryModalOpen(false)}
        ismemoryObj={ismemoryObj}
        womemoryopt={womemoryopt}
        woId={0}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
      />
       <MemoryblueModal
        Open={mryblue}
        Close={() => setMryblue(false)}
        ismemoryObj={ismemoryObj}
        custaddrs={`${memobj?.city} ${memobj?.address} ${memobj?.address_2} ${memobj?.country} ${memobj?.zip}`}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        memobj={memobj}
        mrydlt={mrydlt}
        custname={memobj?.name}
        custId={memobj?.customer_id}
        itemkey={itemkey}
        isProgress={ismemProgress}
        descvalue={descvalue}
        headname={itemkey === "customers" ? memobj?.name : ""}
        headid={itemkey === "customers" ? Number(memobj?.customer_id) : ""}
        openName={itemkey === "customers" ? "customer" : ""}
      />
      <CustomerModal
      Open={primarycntcmodal}
      Close={()=>setprimarycntcmodal(false)}
      data={primarycntctobj}
      custId={customerid}
      contactname={primarycntctobj?.name}
      note={primarycntctobj?.note}
      />
    </div>
  );
}

export default CustomerTable;
