import React, { useState, useEffect } from "react";
import "./MasterForm.css";
import { Card, Modal } from "react-bootstrap";
import "../Settings.css";
import {
  COMPANY_DETAILS_SETTINGS,
  // SETTINGS_PAGES,
  // TAGS_MODAL_VALUES,
} from "../../../../common/components/Constants";

import Close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
// import rite from "../../../../common/Assets/Icons/riteDir.svg";
// import uparrow from "../../../../common/Assets/Icons/uparrow.svg";
import add from "../../../../common/Assets/Icons/add.svg";
import del from "../../../../common/Assets/Icons/del.svg";
import Form_fill_black from "../../../../common/Assets/Icons/Form_fill_black.svg";
// import down from "../../../../common/Assets/Icons/DownDir.svg";
// import save from "../../../../common/Assets/Icons/save.svg";
// import delw from "../../../../common/Assets/Icons/delW.svg";
// import document_Filled from "../../../../common/Assets/Icons/document_Filled.svg";
// import CommonFooter from "../../../../common/framework/footer/CommonFooter";
import ProgressBar from "../../../../common/navigation/ProgressBar";
import {
  DelFile,
  GetMasterForm,
  PostMasterForm,
} from "../../../../services/SettingsServices";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "../../../../common/navigation/NavigationChild";
import { HandlePdfJson } from "./HandlePdfJson";

function MasterForm() {
  const [mstrFrmCat, setMstrFrmCat] = useState(false);
  const HandleMstrFrmCat = () => {
    setMstrFrmCat(!mstrFrmCat);
  };
  const [MasterData, setMasterData] = useState([]);
  const [MasterDataCopy, setMasterDataCopy] = useState([]);
  const [isFileModal, setisFileModal] = useState(false);
  const [attachItem, setAttachItem] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [delVal, setDelVal] = useState("");
  useEffect(() => {
    HandleMasterGet();
  }, []);

  const HandleMasterGet = async () => {
    setIsLoad(true);
    let Master_form_id = 0;
    let Res = await GetMasterForm(Master_form_id);
    if (Res?.success) {
      setIsLoad(false);
      setMasterData(Res?.data);
      setMasterDataCopy(Res?.data);
    }
  };
  const HandleMasterFile = () => {
    document.getElementById("master-id").click();
  };
  // const HandleAddMasterFile = (e) => {
  //   let contType = e.target.files[0].type;
  //   let fileName = e.target.files[0].name;
  //   var reader = new FileReader();
  //   reader.readAsDataURL(e.target.files[0]);
  //   reader.onloadend = async function () {
  //     let baseFile = reader.result;
  //     baseFile = baseFile.split(",");
  //     let inpObj = {
  //       name: fileName,
  //       content: baseFile[1],
  //       comment: contType,
  //       form_id:
  //         MasterData?.length > 0
  //           ? MasterData?.filter((i) => i?.form_id < 0)?.length > 0
  //             ? Math.min(...MasterData?.map((i) => i?.form_id)) + -1
  //             : -1
  //           : -1,
  //     };
  //     setMasterData([...MasterData, inpObj]);
  //     e.target.value = null;
  //   };
  // };
  const handleFileSave = async (e) => {
    let contType = e.target.files[0].type;
    let fileName = e.target.files[0].name;
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = async function () {
      let baseFile = reader.result;
      baseFile = baseFile.split(",");
      let pdfJson = [];
      if (baseFile?.length > 1) {
        pdfJson = await HandlePdfJson(baseFile[1]);
      }
      let inpObj = {
        original_file: contType,
        name: fileName,
        combine_form: true,
        flatten_pdf: true,
        third_party_data: true,
        pdf_mapping_fields: 0,
        pdf_original_fields: pdfJson,
        pdf: baseFile[1],
      };
      setIsLoad(true);
     await PostMasterForm(inpObj);
      setIsLoad(false);
      // setIsDiscard(false);
      HandleMasterGet();

      e.target.value = null;
    };
  };

  // const [isDiscard, setIsDiscard] = useState(false);
  // const HandleDiscard = () => {
  //   let form_Data = MasterData.filter((i) => i?.form_id < 0);
  //   // form_Data.length > 0 && setIsDiscard(true);
  // };
  // const HandleModalDiscard = () => {
  //   let form_Data = MasterData.filter((i) => i?.form_id < 0);
  //   form_Data.length > 0 && HandleMasterGet();
  //   // setIsDiscard(false);
  // };
  const navigation = useNavigate();
  const HandleGetFile = (item) => {
    setisFileModal(true);
    setDelVal("");
    setAttachItem(item);
    NavigateFunction({
      to: "PDFViewer",
      params: { item },
      navigate: navigation,
    });
  };
  const HandleDelFile = async (item, val) => {
    setAttachItem(item);
    setisFileModal(true);
    setDelVal(val);
  };
  const HandleDelete = async () => {
    let Res = await DelFile(attachItem?.master_form_id);
    if (Res?.data?.success) {
      setMasterData(
        MasterData?.filter(
          (i) => i?.master_form_id !== attachItem?.master_form_id
        )
      );
      setMasterDataCopy(
        MasterDataCopy?.filter(
          (i) => i?.master_form_id !== attachItem?.master_form_id
        )
      );
      setMasterData(
        MasterDataCopy?.filter(
          (i) => i?.master_form_id !== attachItem?.master_form_id
        )
      );
      setisFileModal(false);
      setAttachItem({});
    }
  };
  const handleCloseModal = () => {
    setisFileModal(false);
    setAttachItem({});
  };
  // const HandleSelectName = (e) => {
  //   let InpVal = isLoad === true ? "Please Select" : e.target.value;
  //   if (InpVal === "Please Select") {
  //     setMasterData(MasterDataCopy);
  //   } else {
  //     setMasterData(MasterDataCopy.filter((i) => i?.name === InpVal));
  //   }
  // };
  return (
    <div className="pagescroll"> 
      <div className="head-stick"></div>
      <div className="line-align-bet">
      
        {/* <span className="d-flex ">
          <button className="sett-save-btn" onClick={() => HandleDiscard()}>
            {" "}
            <img alt="" src={delw} width={15} height={15} />{" "}
            <span className="ml-2 ">{TAGS_MODAL_VALUES.DISCARD} </span>
          </button>
          <button
            className="sett-save-btn mx-2"
            onClick={() => handleFileSave()}
          >
            {" "}
            <img alt="" src={save} width={15} height={15} />{" "}
            <span className="ml-2">{TAGS_MODAL_VALUES.SAVE} </span>
          </button>
        </span> */}
      </div>
      <div>
        <Card className="mt-2 " style={{ border: "none" }}>
          <div
            className="clp_header"
            style={{
              border: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span
              className="sett-crd-hdr-txt ml-3 mt-1 cursor-p"
              style={{ fontSize: "17px" }}
              onClick={() => HandleMstrFrmCat()}
            >
              <img
                className="text-MASTER"
                alt="open"
                src={Form_fill_black}
                height="20px"
                style={{paddingRight:"3px"}}
              />
              {COMPANY_DETAILS_SETTINGS.MASTER_FORM_CATALOG}
            </span>
           <div  onClick={() => HandleMasterFile()}>
           <input
                  type="file"
                  style={{ display: "none" }}
                  id="master-id"
                  onChange={(e) => handleFileSave(e)}
                />
            <div >
               <img src={add} className="mx-1 mb-2 cursor-p" alt="add"  />
            </div>
           </div>
          </div>
          {mstrFrmCat ? (
            ""
          ) : (
            <Card.Body>
              {/* <div className="line-align flx-wrp">
                <span className="lbl-ipt-CD slkt-mstr">
                  {" "}
                  Select Master Form :{" "}
                </span>

                <select
                  className="inpt-add-eqp  lbl-ipt-add-eqp w-25"
                  name="condition"
                  onChange={(e) => HandleSelectName(e)}
                >
                  <option selected> Please Select</option>
                  {MasterDataCopy?.length > 0 &&
                    MasterDataCopy?.map((i) => (
                      <option value={i?.name}>{i?.name}</option>
                    ))}
                </select>
              </div> */}
              {/* <div className="wf-set-ini-mf hover-class mt-2"  onClick={() => HandleMasterFile()}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="master-id"
                  onChange={(e) => handleFileSave(e)}
                />
                <div className="wf-set-txt">
                  <img src={add} className="mx-1" alt="add" />
                  {COMPANY_DETAILS_SETTINGS.ADD_FILE}
                </div>
              </div> */}
              {isLoad ? (
                <div className="progress-styles-mf mt-2">
                  <ProgressBar />
                </div>
              ) : (
                <div className="overflow-file">
                  {MasterData?.length > 0 ? (
                    MasterData?.map((item) => (
                      <div className="wf-set-ini-mf line-align-bet mt-2 "
                           onClick={() => HandleGetFile(item)}
                      >
                        <div
                          className="inp-sett-md mx-2"
                          // onClick={() => HandleGetFile(item)}
                        >
                          {item?.name}
                        </div>
                      
                        <div
                          className="d-flex p-2"
                          onClick={(e) =>{ e.stopPropagation();HandleDelFile(item, "del-file")}}
                        >
                          <img src={del} alt="delete " className="icon-sz" />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="progress-styles-mf mt-2"
                      style={{ color: "red", fontFamily: "SofiaProRegular" }}
                    >
                      There is no data found
                    </div>
                  )}
                </div>
              )}
            </Card.Body>
          )}
        </Card>
      </div>
      {/* <CommonFooter /> */}
      <Modal show={isFileModal} centered onHide={() => handleCloseModal()}>
        <Modal.Header className="line-align-even  w-100 modal-bg-color modal-header-height">
          <span style={{ color: "white", fontSize: "13px" }}>
            {attachItem?.name}
          </span>
          <img
            onClick={() => handleCloseModal()}
            className="modal-header-icon"
            src={Close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body>
          {delVal === "del-file" ? (
            <div>
              <span className="delform-mf">
                {`Are you sure you want to delete the ${attachItem?.name}`}
              </span>
            </div>
          ) : attachItem?.comment === "this is a sample comment" ? (
            <img
              src={`data:${attachItem?.comment};base64,${attachItem?.content}`}
              alt="file"
              width="460px"
              height="380px"
            />
          ) : (
            <embed
              src={`data:${attachItem?.comment};base64,${attachItem?.content}`}
              alt="file"
              width="460px"
              height="380px"
            />
          )}
        </Modal.Body>
        {delVal === "del-file" && (
          <Modal.Footer className="modal-footer-styles">
            <div
              className="modal-footer-save-cancel-btns"
              onClick={() => HandleDelete()}
              style={{ margin: 0 }}
            >
              Delete
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              onClick={() => handleCloseModal()}
            >
              Cancel
            </div>
          </Modal.Footer>
        )}
      </Modal>
      {/* <Modal show={isDiscard} onHide={() => setIsDiscard(false)} centered>
        <Modal.Header className="line-align-even  w-100 modal-bg-color modal-header-height">
          <span style={{ color: "white", fontSize: "13px" }}>Discard</span>
          <img
            onClick={() => setIsDiscard(false)}
            className="modal-header-icon"
            src={Close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="delform-mf">
              {`Are you sure you want to Discard the below Data`}
            </span>
            <div>
              {MasterData.filter((i) => i?.form_id < 0).map((item) => (
                <div className="delform-mf" style={{ color: "red" }}>
                  {item?.name}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-styles">
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => HandleModalDiscard()}
            style={{ margin: 0 }}
          >
            Discard
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => handleFileSave()}
          >
            Save
          </div>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default MasterForm;
