import React from "react";
import infoIcon from "../../common/Assets/Icons/Desk_alt.svg";
import mediaCenterIcon from "../../common/Assets/Icons/book_outline.svg";
import logsIcon from "../../common/Assets/Icons/Logs_outline.svg";
import SettingsImg from "../../common/Assets/Images/Work Flow Wrench_outlinesetting.png";
import Furnace_outline from "../../common/Assets/Icons/Furnace_outline.svg";
import Oven from "../../common/Assets/Icons/Oven.svg";
import clipboard_outline from "../../common/Assets/Icons/clipboard_outline.svg";
import "./CrtNewSidebar.css";

function CrtNewSidebar(props) {
  return (

    <div className="side-bar-left-new">
    <div
      className="CE-sidebar-new-div1"
      // onClick={() => {
      //   props.scrollCallback(0);
      // }}
    >
      <img src={infoIcon} alt="Info Icon" />
      <span className="CE-text-sidebar-new">Info</span>
    </div>
    <div
    id="workflow"
      className="CE-sidebar-new-div2"
      // onClick={() => {
      //   props.scrollCallback(1);
      // }}
    >
      <img src={SettingsImg} alt="Settings" />
      <span className="CE-text-sidebar-new">Workflows</span>
    </div>
    <div
    id="notes"
      className="CE-sidebar-new-div3"
      // onClick={() => {
      //   props.scrollCallback(2);
      // }}
    >
     <img src={Furnace_outline} alt="Furnace Repair"/>
      <span className="CE-text-sidebar-new">Furnace Repair</span>
    </div>
    <div
    id="communications"
      className="CE-sidebar-new-div4"
      // onClick={() => {
      //   props.scrollCallback(3);
      // }}
    >
     <img src={Oven} alt="Oven Repair"/>
      <span className="CE-text-sidebar-new">Oven Repair</span>
    </div>
    <div
    id="equipment"
      className="CE-sidebar-new-div5"
      // onClick={() => {
      //   props.scrollCallback(4);
      // }}
    >
      <img src={mediaCenterIcon} alt="Media Center"  />
      <span className="CE-text-sidebar-new">Media Center</span>
    </div>

    <div
    id="mediacenter"
       className="CE-sidebar-new-div6"
      // onClick={() => {
      //   props.scrollCallback(5);
      // }}
    >
       <img src={clipboard_outline} alt="Work Orders"/>
      <span className="CE-text-sidebar-new">Work Orders</span>
    </div>
    <div 
    id="logs"
    className="CE-sidebar-new-div7"
     
    >
        <img src={logsIcon} alt="Logs"/>
      <span className="CE-text-sidebar-new">Logs</span>
    </div>
  </div>
  );
}

export default CrtNewSidebar;
