export const HandleStepComplete = (item) => {
  const hasAttachmentsAndNotes =
    item?.attachment_list?.length > 0 && item?.notes?.length > 0;
  if (!hasAttachmentsAndNotes || item?.step_complete === "1") {
    return false;
  }
  switch (item.type) {
    case "Add Input Field":
      return item?.content_note?.length > 0;

    case "Create Drop Down":
    case "Yes/No Selection":
      return item?.choice_result != null && item?.choice_result?.length > 0;

    default:
      return hasAttachmentsAndNotes;
  }
};
