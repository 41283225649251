import { NINEPACK_URL } from "./Url";
import axios from "axios";
import { APP_JSON } from "../common/components/Constants";

// field setup- Post
export const AddFiledsUS = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const answer = await axios.post(
      `${NINEPACK_URL}/maintenance/additional/fields/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return answer?.data;
  } catch (err) {}
};

// field setup- Get
export const fetchAddFieldsUS = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const getAnswer = await axios.get(
      `${NINEPACK_URL}/maintenance/additional/fields/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return {
      equipment_extra_fields:
        getAnswer?.data?.data?.equipment_extra_mapping || [],
      work_order_extra_fields:
        getAnswer?.data?.data?.wo_extra_mapping || [],
      contact_extra_fields: getAnswer?.data?.data?.contact_extra_fields || [],
      customer_extra_fields: getAnswer?.data?.data?.customer_extra_mapping || [],
      wo_extra_interface: getAnswer?.data?.data?.wo_extra_interface || [],
      ErpWoadditionalfields: getAnswer?.data?.data?.ErpWoadditionalfields || [],
      ErpCustomeradditionalfields:
        getAnswer?.data?.data?.ErpCustomeradditionalfields || [],
      ErpEquipmentadditionalfields:
        getAnswer?.data?.data?.ErpEquipmentadditionalfields || [],
    };
  } catch (error) {}
};

export const CustSettCompDet = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/company/details/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const getCustSettCompDet = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/company/details/fetch`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const WFById = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));

    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/workflow/${id}/fetch/info/setup`,

      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const StepChoiceData = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));

    const Responce = await axios.get(
      `${NINEPACK_URL}//workorder_workflow/getChoicesbyStepId/${id}`,

      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const WorkflowTeams = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));

    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/team/setup/fetch`,

      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );

    return Responce?.data;
  } catch (err) {}
};
// ***  wo status post
export const woStsSettFldStPst = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/workorder/status/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

// ***  wo status get
export const woStsSettFldStpGt = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Res = await axios.get(
      `${NINEPACK_URL}/maintenance/workorder/status/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Res?.data;
  } catch (errors) {}
};

// wo status delete

export const GetEqpMan = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Res = await axios.get(`${NINEPACK_URL}/maintenance/getmanufacturer`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return Res?.data;
  } catch (errors) {}
};

export const EqpManAdd = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Res = await axios.post(
      `${NINEPACK_URL}/maintenance/postManufacturer`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Res?.data;
  } catch (errors) {}
};

export const DelEqpMan = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const answer = await axios.delete(
      `${NINEPACK_URL}/maintenance/deleteManufacturer/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return answer?.data;
  } catch (errors) {}
};
export const woDelStsSettFldStp = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const answer = await axios.delete(
      `${NINEPACK_URL}/maintenance/status/${id}/delete`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return answer?.data;
  } catch (errors) {}
};

// *** wo Type post
export const woPtFldsTypeSett = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.post(
      `${NINEPACK_URL}/maintenance/field/setup/workorder/types/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};

// *** wo Type get
export const woGtFldsTypeSett = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const resp = await axios.get(
      `${NINEPACK_URL}/maintenance/getworkoder_types`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return resp?.data;
  } catch (error) {}
};

// *** wo Type delete
export const woDelFldSTypSett = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const derived = await axios.delete(
      `${NINEPACK_URL}/maintenance/workorder/types/${id}/delete`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return derived.data;
  } catch (miscue) {}
};

// miscue=error

export const userSetUp = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/user/setup/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const DivisionByID = async (id) => {
  const token = JSON.parse(localStorage.getItem("authToken"));
  try {
    const Responce = await axios.get(
      `${NINEPACK_URL}/company/userDetails/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const UsersPost = async (token, inpObj) => {
  try {
    const Responce = await axios.post(
      `${NINEPACK_URL}/maintenance/user/setup`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const WorkflowFetch = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/workflow/setup/fetch`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const EqpTypesFetch = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/getEquipmentTypes`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const CallTypesFetch = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/maintenance/call/type`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {}
};

export const CallTypesWorkflowsFetch = async () => {
  try{
    const token = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder_workflow/workflow`,
      {
        headers: {
          Authorization: `Bearer ${token}` ,
          Accept: 'application/json',
          "Content-Type": 'application/json',
        },
      }
    );
    return response?.data;
  }catch(err) {
  }
};

export const EqpTypesAdd = async (inpObj) => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.post(
      `${NINEPACK_URL}/maintenance/AddEquipmentType`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const CallTypesAdd = async (Obj) => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/add/call/type`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const DelEqupTypes = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.delete(
      `${NINEPACK_URL}/maintenance/deleteEquipmentType/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const DelCallTypes = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/maintenance/delete/call/type/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const GetWfCatFetch = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/getworkFlowCategory`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const getWorkflowAutoData = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/getworkflowAuto`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const WfCatAdd = async (inpObj) => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.post(
      `${NINEPACK_URL}/maintenance/postworkFlowCategory`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const DelWfCat = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.delete(
      `${NINEPACK_URL}/maintenance/deleteworkFlowCategory/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const DeleteWoflow = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.delete(
      `${NINEPACK_URL}/maintenance/workflow/${id}/step/setup/delete`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const CreateWorkFlow = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.post(
      `${NINEPACK_URL}/maintenance/workflow/setup`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const CreateTeams = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/team/setup/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const WorkflowNotification = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/notification/setup/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const WorkFlowAddStep = async (obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.post(
      `${NINEPACK_URL}/maintenance/workflow/step/setup`,
      obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const CreateUserTeams = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.post(
      `${NINEPACK_URL}/maintenance/team/add`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const UsersByID = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.get(
      `${NINEPACK_URL}/maintenance/team/${id}/user/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};
export const DeleteStepAsync = async (id, wfId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const Responce = await axios.delete(
      `${NINEPACK_URL}/maintenance/step/${id}/${wfId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Responce?.data;
  } catch (err) {}
};

export const DelTeamID = async (teamId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/maintenance/team/${teamId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response.data;
  } catch (err) {}
};
export const AddChoice = async (obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/workflow/step/choice/add`,
      obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response.data;
  } catch (err) {}
};
export const CustomerGet = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/customer`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response.data;
  } catch (err) {}
};
export const CustomerListGet = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/customer/fetch/list`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response.data;
  } catch (err) {}
};
export const CustAddfieldsGet = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/customer/customeradditionalfields/info`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response.data;
  } catch (err) {}
};

export const ChoiceDelete = async (choice, step, workflow) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/maintenance/step_choice/${choice}/step/${step}/workflow/${workflow}/delete`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response.data;
  } catch (err) {}
};

export const DuplicateWorkFlow = async (workflow) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/duplicate/workflow`,
      workflow,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response;
  } catch (err) {}
};
export const GetAttchMentByIDMaintanance = async (
  attchId,
  step,
  workflow,
  custId,
  WorkId,
  eqId,
  techId,
  company
) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/attachment?step=${step}&workflow=${workflow}&customer=${custId}&workorder=${WorkId}&equipment=${eqId}&technician=${techId}&company=${company}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

// ----------------  Integrations -------------- St ------ v

export const IntegAddFields = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const ans = await axios.post(
      `${NINEPACK_URL}/maintenance/additional/integrationfields/add`,

      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return ans?.data;
  } catch (errors) {}
};

export const IntegGetFields = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));

    const reply = await axios.get(
      `${NINEPACK_URL}/maintenance/additional/Integrationfields/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return reply?.data?.data;
  } catch (error) {}
};

// ----------------  Integrations -------------- End ----- ^
export const GetMasterForm = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/pdf/field/mapping/${id}`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const PostMasterForm = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.post(`${NINEPACK_URL}/pdf/upload`, inpObj, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};

export const UpdateMappings = async (id, inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.post(
      `${NINEPACK_URL}/pdf/updatePdfMappingFields/${id}`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};
export const DelFile = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.delete(
      `${NINEPACK_URL}/pdf/masterformdelete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res;
  } catch (error) {}
};
export const GetWfAllTags = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(
      `${NINEPACK_URL}/maintenance/getTagsbasedonSponsor`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};

export const GetSalesPersons = async (inpObj) => {
  try {
    const res = await axios.post(
      `https://vt-script-middleware.azurewebsites.net/api/CommonData/GetSalesPerson`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDTGllbnRDb2RlIjoiQU5EMTAwMCIsIkludGVncmF0aW9uQ29kZSI6IkFORCIsImV4cCI6MTcyMTEzNDA3MywiaXNzIjoiOXBhY2siLCJhdWQiOiI5cGFjayJ9.6hH75bLGRYyjdiYtuSIPZPHj9VCIvxysRiwytMg2CZo`,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};
// get technicians dropdown data
export const GetTechnicians = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(
      `${NINEPACK_URL}/maintenance/user/setup/getTechDetails`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};
export const GetUserTechnicians = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(
      `${NINEPACK_URL}/maintenance/getTeamsbyUser`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};
export const PackageGet = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/maintenance/packageGet`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const GetDivision = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/company/division/fetch`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const GetDivById = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/company/division/${id}`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
//
export const GetFieldsData = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(
      `${NINEPACK_URL}/maintenance/columns/by/table/${data}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};

export const getDataByName = async (val) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/pdf/master_fields/${val}`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const PostMappedFields = async (obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.post(`${NINEPACK_URL}/pdf/postMaster_Fields`, obj, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const GetPackages = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/maintenance/packageGet`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
export const AddPackages = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/packageAdd`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const DelPackages = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.delete(
      `${NINEPACK_URL}/maintenance/packageDelete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res;
  } catch (error) {}
};
export const woTypedelete = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const answer = await axios.delete(
      `${NINEPACK_URL}/maintenance/deleteworkorder_type/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return answer?.data;
  } catch (errors) {}
};
export const GetThirdprty = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(
      `${NINEPACK_URL}/maintenance/GetintegrationThirdParty`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data;
  } catch (error) {}
};
export const AddThrdprtys = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/integrationAdd`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const DelThrdprtys = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.delete(
      `${NINEPACK_URL}/maintenance/deleteThirdParty/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res;
  } catch (error) {}
};

export const HandlePostWfAuto = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/maintenance/AddWorkflowAuto`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const MsterForms = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/pdf/masterform_fields`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res?.data;
  } catch (error) {}
};
