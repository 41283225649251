import React from "react";
import { Modal } from "react-bootstrap";
import { TAGS_MODAL_VALUES } from "../../../common/components/Constants";
import closeB from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";

const DeleteTags = (props) => {
  return (
    <Modal show={props.isOpen} onHide={props.handleClose} centered>
      <Modal.Header className=" line-align-bet w-100 modal-bg-color modal-header-height px-2">
        <span className="note-edit-text">
          {TAGS_MODAL_VALUES.TAG_DELETE} to {props?.name} #
          {/* {props?.tagData?.equipment_id
            ? props?.tagData?.equipment_id
            : props?.custId
            ? props?.custId
            : props?.workOrderId} */}
            {props?.headid}
          {/* {props?.data?.workorderId?.id} */}
        </span>
        <img
          src={closeB}
          className="modal-header-icon  img-cursor"
          onClick={props.handleClose}
          alt="close"
        />
      </Modal.Header>
      <Modal.Body className="p-1 delete-area-styles ">
        <div>
          {TAGS_MODAL_VALUES.TXT_POP_UP} to delete "
          <span>{props?.data?.name?.slice(0, 15)}</span>" Tag ?
        </div>
        <div className="mt-2 modal-bottom-styles-edit-notes model_footer">
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => props.deleteTag()}
          >
            {TAGS_MODAL_VALUES.DELETE}
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            onClick={props.handleClose}
          >
            {TAGS_MODAL_VALUES.CANCEL}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteTags;
