import React, { useEffect } from "react";
import "./AddTag.css";
import { Modal } from "react-bootstrap";
import { useRef } from "react";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { useState } from "react";
import { TAGS_MODAL_VALUES } from "../../../common/components/Constants";
import Spinners from "./Spinners";

export default function AddTag(props) {
  const [forTagValidate, setForTagValidate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const validateTag = () => {
    const valMsg = props?.name?.trim();
    if (valMsg?.length === 0) {
      setForTagValidate({
        name: (
          <span style={{ color: "red", fontSize: "10px" }}>
            {"Enter Tag Name "}
          </span>
        ),
      });
      return false;
    }
    setForTagValidate(null);
    return true;
  };

  useEffect(() => {
    if (props.isClose) {
      setForTagValidate(null);
      
    }
  }, [props.isClose]);
  const onSave = async() => {
    if ( validateTag()) {
      setIsLoading(true)
     await props.isSubmit();
      setIsLoading(false)
    }

  };
  const textareaRef = useRef(null);
  const adjustHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    adjustHeight();
  }, [props?.name]);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div>
      <Modal
        centered
        show={props.Open}
        onHide={props.isClose}
        dialogClassName="my-modal"
      >
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="attach-media-note flx-wrp ">
            {TAGS_MODAL_VALUES.ADD_TAG_BELOW} to  {props?.workOrderId}
          </div>
          <img
            src={Close}
            alt="Close"
            className="modal-header-icon"
            onClick={() => {
              props.isClose();
              setForTagValidate(null);
            }}
          />
        </Modal.Header>
        <Modal.Body className="p-2 " >
          {isLoading ? (
            <div className=""
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}>
              {" "}
              <Spinners className="" />{" "}
            </div>
          ) : (
            <>
              <div className="">
                <div>
                  <div className="tag-lables">
                    {TAGS_MODAL_VALUES.CREATING_TAG_NEW}
                  </div>

                  <textarea
                  value= {props?.name}
                    ref={textareaRef}
                    type="text"
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                    placeholder={isFocused ? "" : "Input Tag Name"}
                    className="inp-tag-style"
                    autocomplete="off"
                    onChange={(e) =>
                      props.HandleTagInputs("name", e.target.value)
                    }
                  />
                  {forTagValidate?.name}
                </div>
                {/* <div>
                  <div className="tag-lables mt-1">
                    {TAGS_MODAL_VALUES.NEW_TAG_COLOR}
                  </div>
                  <div
                    className="color-inp-style"
                    style={{ background: props?.color }}
                  >
                    <img
                      src={ColorImage}
                      alt="ColorImage"
                      className="m-1"
                      width={20}
                      onClick={() => setShowColorPicker(!showColorPicker)}
                    />
                    <span style={{ color: "white" }} className="mx-2">
                      {props?.color}{" "}
                    </span>
                    <Modal size="sm" centered show={showColorPicker}>
                      <Modal.Body centered>
                        <SketchPicker
                          color={props?.color}
                          className="ml-4"
                          onChange={(updatedColor) =>
                            HandleInput(updatedColor.hex)
                          }
                        />
                        <hr />
                        <div>
                          <div className="row line-align-even flx-wrp">
                            <button
                              className="col-5  modal-footer-save-cancel-btns"
                              style={{}}
                              onClick={() => setShowColorPicker(false)}
                            >
                              <img src={plus} className="skw-45" alt="cancel" />{" "}
                              Cancel
                            </button>
                            <button
                              className="col-5  modal-footer-save-cancel-btns"
                              onClick={() => setShowColorPicker(false)}
                            >
                              {" "}
                              <img src={save} alt="save" /> Save
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div> */}
              </div>
            </>
          )}
          {/* <hr style={{ margin: "10px 0px" }} /> */}
          <div
            className="d-flex model_footer mt-2"
             style={{ flexDirection: "row", justifyContent: "center" }}
            >
            <button
              onClick={() => onSave()}
              className="modal-footer-save-cancel-btns"
            >
              Save
            </button>

            <button
              onClick={() => {
                props.isClose();
                setForTagValidate();
              }}
              className="modal-footer-save-cancel-btns"
            >
              Cancel
            </button>
          </div>
          </Modal.Body>
      </Modal>
    </div>
  );
}
