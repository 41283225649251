export const CustomerSearch = (item, data, type) => {
  let resdata = data;
  if (type === "Table") {
    if (item?.isCustName?.length > 0) {
        resdata = resdata?.filter((i) =>
          i?.name?.toUpperCase().includes(item?.isCustName?.toUpperCase())
        );
      }
      if (item?.isCity?.length > 0) {
        resdata = resdata?.filter((i) =>
          i?.city?.toUpperCase().includes(item?.isCity?.toUpperCase())
        );
      }

    if (item?.isTags?.length > 0) {
      resdata = resdata?.filter((i) =>
        i?.tags?.some((tag) =>
          tag?.tag_data?.toUpperCase().includes(item?.isTags?.toUpperCase())
        )
      );
    }
    if (item?.isContacts?.length > 0) {
      resdata = resdata?.filter((i) =>
        i?.contact?.some((contact) =>
          contact?.name?.toUpperCase().includes(item?.isContacts?.toUpperCase())
        )
      );
    }
    return resdata;
  } else {
    if (item?.isCustName?.length > 0) {
      resdata = resdata?.filter((i) =>
        i?.name?.toUpperCase().includes(item?.isCustName?.toUpperCase())
       
      );
    }

    if (item?.isCity?.length > 0) {
      resdata = resdata?.filter((i) =>
        i?.city?.toUpperCase().includes(item?.isCity?.toUpperCase())
      );
    }

    if (item?.isTags?.length > 0) {
      resdata = resdata?.filter((i) =>
        i?.tags?.some((tag) =>
          tag?.tag_data?.toUpperCase().includes(item?.isTags?.toUpperCase())
        )
      );
    }
    if (item?.isContacts?.length > 0) {
         resdata = resdata?.filter((i) =>
          i?.contact?.some((contact) =>
            contact?.name?.toUpperCase().includes(item?.isContacts?.toUpperCase())
           )
         );
       }
    return resdata;
  }
};
