import moment from "moment";
import React, { useState, useEffect } from "react";
import MemoryIcon from "../../common/Assets/Icons/Memory Board Icon.svg";
import VectorEqpBlue from "../../common/Assets/Images/VectorEqpBlue.svg";
import Add_round from "../../common/Assets/Icons/Add_round.svg";
import Dots3 from "../../common/Assets/Images/3x3 Dot Grid_Outlinedots.svg";
import del from "../../common/Assets/Icons/delW.svg";
import AttachmentModal from "./AttachmentModal";
import {
  STATUS_COMPLETED,
  STATUS_TODO,
  STATUS_INPROGRESS,
  WORK_ORDER_CONSTANTS,
} from "../../common/components/Constants";
import { SlArrowUp } from "react-icons/sl";
import "./WorkOrdersStyles.css";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../Notes/Notes.css";
import MemoryModal from "../../common/components/memorymodal/MemoryModal";
import MemoryblueModal from "../../common/components/memorymodal/MemoryblueModal";
import { GetMemory, MediaAttachments } from "../../services/WorkOrdersService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear,faScrewdriverWrench,faBook,faUser,faLocationDot,faShareNodes } from '@fortawesome/free-solid-svg-icons'
import ShareWoModal from "../../common/components/sharemodal/ShareWoModal";



const WorkOrderCard = ({
  eachWorkOrder,
  techId,
  techPin,
  companyId,
  navToWoDetls,
  HandleShow,
  HandleCutomer,
  HandleAddtag,
  handleDeleteModal,
  HandleToggleTags,
  isvalue,
  HandleAttch,
  HandleForDots,
}) => {
  const [ismemProgress, setIsMemProgress] = useState(false);
  const [imageViewExpanded, setImageViewExpanded] = React.useState(false);
  const HandleImgTgl = (e, val) => {
    e.stopPropagation();
    if (val === "dots") {
      setImageViewExpanded(true);
    } else {
      setImageViewExpanded(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const HandleShare = (e, value) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const [isCardToggle, setIsCardToggle] = useState(false);

  const HandleToggleCard = (e) => {
    e.stopPropagation();
    setIsCardToggle(!isCardToggle);
  };

  const tooltip = (name) => {
    return (
      <Tooltip id="tooltip">
        <span className="hvr-styles-tags">{name}</span>
      </Tooltip>
    );
  };

  const checkBeforeNavigation = (e, value,eachWorkOrder) => {
    let flag = value;
    if (flag === 1) {
      navToWoDetls(
        e,
        `WoDetails/${eachWorkOrder?.WorkOrderId}/${eachWorkOrder?.Customer?.id}/${techId}/${techPin}/${companyId}/0`,
        eachWorkOrder,
        "details",
      );
    }
  };
  const [isZoomed, setIsZoomed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomMessage, setZoomMessage] = useState("");

  const handleImageClick = (e, index) => {
    e?.stopPropagation();
    setSelectedImageIndex(index);
    setShowModal(true);
    setZoomLevel(1);
    setZoomMessage("");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setZoomLevel(1);
    setIsZoomed(false);
    setZoomMessage("");
  }, [selectedImageIndex]);
  const handleCarouselSelect = (selectedIndex) => {
    setSelectedImageIndex(selectedIndex);
  };

  const zoomIn = () => {
    if (zoomLevel < 3) {
      setZoomLevel(zoomLevel + 1);
      setZoomMessage("");
    } else {
      setZoomMessage("Can't zoom in further.");
      setIsZoomed(true);
    }
  };

  const zoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel(zoomLevel - 1);
      setZoomMessage("");
    } else {
      setZoomMessage("");
      setIsZoomed(false);
    }
  };
  const updateMemoryBoardStatus = (workOrderId) => {
    eachWorkOrder.MemoryBorad = workOrderId
  };
  const [isMemory, setIsMemory] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [womemoryopt, setWomemoryopt] = useState({});
  const [memobj,setMemobj] = useState({})
  const [mryblue,setMryblue] = useState(false)
  const [itemkey,setItemkey] = useState("")
  const [mrydlt,setMrydlt] = useState(null)
  const [getmemory, setgetmemory] = useState("");
  const descvalue = getmemory?.note;
  const HandleMemory = async(type,e, item) => {
   
    e.stopPropagation();
    if(type === "black"){
    setWomemoryopt(item);
    setIsMemory(true);
    let items = {  "viewtype": 10, "Reftype": 100, "RefId": item?.WorkOrderId  };
    setIsMemoryObj(items);
  }
  else{
    setMryblue(true)
    setMemobj(item)
    setItemkey(type)
    let items = { "viewtype": 10, "Reftype": 100, "RefId": item?.WorkOrderId };
    setIsMemoryObj(items);
    setIsMemProgress(true)
    const Result = await GetMemory(
      items?.viewtype,
      items?.Reftype,
      items?.RefId
      );
      if (Result?.success) {
        setIsMemProgress(false)
      }
      setgetmemory(Result?.data);
      setMrydlt(Result?.data?.recent_view_id)
    }
  };

  let filesObj = {};
  eachWorkOrder?.attachments?.forEach((i) => {
      if (i.file_type.includes("image")) {
        filesObj["attachments"] = [...(filesObj?.attachments || []), i];
      }
      if (
        i.file_type.includes("text") ||
        i.file_type.includes("pdf") || i.file_type.includes("application")) {
        filesObj["docs"] = [...(filesObj?.docs || []), i];
      }
    });
    const openImageInNewTab = async (document) => {
      let id = document?.id ? document?.id : document?.attachment_id;
      const output = await MediaAttachments(id);
      const base64PDF = output?.data?.content;
      const fileType = output?.data?.file_type;
      const blob = base64ToBlob(base64PDF, fileType);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      URL.revokeObjectURL(blobUrl);
    };
    const base64ToBlob = (base64, contentType) => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters?.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    };
    const filteredImages = eachWorkOrder?.attachments?.filter(
      attachment => attachment?.file_type?.startsWith("image/")
    );
  return (
    <>
      <Card
      style={{width:"100%",pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
        key={eachWorkOrder?.id}
        className="card card-hover p-2 mb-2"
        onClick={(e) => checkBeforeNavigation(e, 1,eachWorkOrder)}
      >
        <div>
          <div className="card-style" onClick={(e) => HandleToggleCard(e)}>
            <div>

              <span className="work-order-font">
                {eachWorkOrder?.name}#{eachWorkOrder?.WorkOrderId}

              </span>{" "}
              &nbsp;
              <span className="work-order-name">
                {eachWorkOrder?.Customer?.name}
              </span>
              <span className="work-order-date">
                {" "}
                &nbsp;
                {moment(eachWorkOrder?.request_date).format("MM/DD/YYYY")}
              </span>
            </div>
            <div className=" wo-card-rt-side-icons">
              <span
                className={
                  eachWorkOrder?.status === STATUS_COMPLETED
                    ? "status-style"
                    : eachWorkOrder?.status === STATUS_TODO
                      ? "status-todo"
                      : eachWorkOrder?.status === STATUS_INPROGRESS
                        ? "status-style2"
                        : "status-style1"
                }
              >
                <span
                  className="status-font mx-1"
                  onClick={(e) => HandleShow(e, eachWorkOrder)}
                >
                  {eachWorkOrder?.status}
                </span>
              </span>

              <button
                className="mx-2 btn-T-2"
                onClick={(e) => HandleAddtag(e, eachWorkOrder)}
              >
                {WORK_ORDER_CONSTANTS.TAG} <img src={Add_round} alt="btn" />{" "}
              </button>
               <span
                      className="mx-1 cursor-p woCard-icons-align"
                     
                    >
                      {(eachWorkOrder?.MemoryBorad || eachWorkOrder?.memoryBoard) === 1 ?
                        <img  onClick={(e) => HandleMemory("workorder",e, eachWorkOrder)} src={VectorEqpBlue} alt="memoryIconBlue" />
                        :
                        <img  onClick={(e) => HandleMemory("black",e, eachWorkOrder)} width={22} src={MemoryIcon} alt="MemoryIcon" />
                      }
                    </span>
              <span
                className="mx-1  icons-hover woCard-icons-align"
                onClick={(e) => HandleCutomer(e, eachWorkOrder)}
              >
                {/* <img width={22} src={PersonImg} alt="personImg" /> */}

                <FontAwesomeIcon icon={faUser} />
              
              </span>

              <span
                className={
                  eachWorkOrder?.Customer?.latitude &&
                    eachWorkOrder?.Customer?.longitude
                    ? "mx-2 icons-hover woCard-icons-align"
                    : "mx-2 icons-hover woCard-icons-align"
                  // mx-2 Img-blur
                }
                onClick={(e) => {
                  window.open(
                    "https://maps.google.com?q=" +
                    eachWorkOrder?.Customer?.latitude +
                    "," +
                    eachWorkOrder?.Customer?.longitude
                  );
                  e.stopPropagation();
                }}
              >
                {/* <img width={12} src={LocationImg} alt="LocationImg" /> */}
                <FontAwesomeIcon icon={faLocationDot} />
              </span>
              <span
                className="mx-2 icons-hover woCard-icons-align"
                onClick={(e) => HandleShare(e, eachWorkOrder)}
              >
                {/* <img width={18} className="" src={ShareImg} alt="ShareImg" /> */}
                <FontAwesomeIcon icon={faShareNodes} />
              </span>
            </div>
          </div>
          <div className=" parent-tags flex-wrap">
            {/* {eachWorkOrder?.tags
              ?.sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((it, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  className="tag-styles"
                  overlay={tooltip(it?.name)}
                >
                  <div
                    className="tag-styles"
                    style={{ background: it?.color }}
                    onClick={(e) =>
                      handleDeleteModal(
                        e,
                        {
                          ...it,
                        },
                        eachWorkOrder
                      )
                    }
                  >
                    <span className="tag-data-style ">
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        {it?.name?.length > 7
                          ? `${it?.name?.slice(0, 6)}..`
                          : `${it?.name}`}
                       {" "}
                      </span>
                    </span>
                  </div>
                </OverlayTrigger>
              ))} */}
            <div className=" d-flex flex-wrap">
              {eachWorkOrder?.tags?.map((item) => (
                <div key={item} onClick={(e) =>handleDeleteModal(e,{...item},eachWorkOrder)}>
                  <span
                    className="tag-styles"
                    style={{
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "black",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: '100%',
                    }}
                  >
                    {" "}
                    {item?.name}
                  </span>
                </div>
              ))}
            </div>
            {eachWorkOrder?.tags?.length > 9 &&
              isvalue?.includes(eachWorkOrder?.id) === false && (
                <div onClick={(e) => HandleToggleTags(e, eachWorkOrder)}>
                  <img src={Dots3} alt="Dots3" />
                </div>
              )}
          </div>
          {isvalue?.includes(eachWorkOrder?.id) && (
            <div className="parent-tags flex-wrap" >
              {eachWorkOrder?.tags
                .slice(9)
                ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                .map((it, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    className="tag-styles"
                    overlay={tooltip(it?.name)}
                  >
                    <div
                      className="tag-styles"
                      style={{ background: it.color }}
                    >
                      <span
                        className="tag-data-style "
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {it?.name?.length > 7
                          ? `${it?.name?.slice(0, 6)}..`
                          : `${it?.name}`}
                        <img
                          src={del}
                          alt=""
                          className="ml-1 del-icon-tags "
                          onClick={(e) =>
                            handleDeleteModal(
                              e,
                              {
                                ...it,
                              },
                              eachWorkOrder
                            )
                          }
                        />
                      </span>
                    </div>
                  </OverlayTrigger>
                ))}
              <span onClick={(e) => HandleToggleTags(e, eachWorkOrder)}>
                <SlArrowUp />
              </span>
            </div>
          )}
          <div className="sub-pages">
            <div className="mx-2 halls">
              {" "}
              <span className="mx-1">
                {/* <img src={SettingsImg} alt="SettingsImg" /> */}
                <FontAwesomeIcon icon={faGear} />
                
              </span>
              <span
                className="sub-page-style"
                onClick={(e) =>
                  navToWoDetls(
                    e,
                    `WoDetails/${eachWorkOrder?.WorkOrderId}/${eachWorkOrder?.Customer?.id}/${techId}/${techPin}/${companyId}/0`,
                    eachWorkOrder,
                    "workflow"
                  )
                }
              >
                {WORK_ORDER_CONSTANTS.WORK_FLOWS}(
                {eachWorkOrder?.workflow_count})
              </span>
            </div>
            <div className="mx-3 halls">
              <span className="mx-1">
                {/* <img src={DrillerImg} alt="DrillerImg" /> */}
               
                <FontAwesomeIcon icon={faScrewdriverWrench} />
              </span>
              <span
                className="sub-page-style"
                onClick={(e) =>
                  navToWoDetls(
                    e,
                    `WoDetails/${eachWorkOrder?.WorkOrderId}/${eachWorkOrder?.Customer?.id}/${techId}/${techPin}/${companyId}/0`,
                    eachWorkOrder,
                    "equipments"
                  )
                }
              >
                {WORK_ORDER_CONSTANTS.EQUIPMENTS}(
                {eachWorkOrder?.equipment_count})
              </span>
            </div>
            <div className="mx-3 halls">
              {" "}
              <span className="mx-1">
                {/* <img src={BookImg} alt="BookImg" /> */}
                <FontAwesomeIcon icon={faBook} />
              </span>
              <span
                className="sub-page-style"
                onClick={(e) =>
                  navToWoDetls(
                    e,
                    `WoDetails/${eachWorkOrder?.WorkOrderId}/${eachWorkOrder?.Customer?.id}/${techId}/${techPin}/${companyId}/0`,
                    eachWorkOrder,
                    "library"
                  )
                }
              >
                {WORK_ORDER_CONSTANTS.LIBRARY}({eachWorkOrder?.library_count})
              </span>
            </div>
          </div>
        </div>
        {!isCardToggle && (
          <div>
            <div className="work-order-text mx-2">
              {eachWorkOrder?.description}
            </div>

            <div className="mt-2 ">
              <div className="parent-tags">
                {eachWorkOrder?.attachments?.length > 0 &&
                  eachWorkOrder?.attachments?.slice(0, 6).map((it, index) => (
                    <img
                      key={index}
                      src={
                        it?.thumbnail.includes("base64")
                          ? it?.thumbnail
                          : `data:${it?.file_type};base64,${it?.thumbnail}`
                      }
                      className="mx-2 wo-images"
                      alt="table"
                      // onDoubleClick={(e) => openImageInNewTab(it)}
                      onClick={(e) =>{openImageInNewTab(it); e.stopPropagation(); if (it?.file_type?.startsWith("image/")) {handleImageClick(e, index)}}}
                    // onClick={(e) => HandleAttch(e, it)}
                    />
                  ))}
                <span>
                  {eachWorkOrder?.attachments?.length > 6 &&
                    !imageViewExpanded && (
                      <div onClick={(e) => HandleImgTgl(e, "dots")}>
                        <img src={Dots3} alt="Dot3" />
                      </div>
                    )}
                </span>
                <span>
                  {imageViewExpanded &&
                    eachWorkOrder?.attachments?.length > 1 &&
                    eachWorkOrder?.attachments?.slice(6).map((it, index) => (
                      <img
                        key={index}
                        src={
                          it?.thumbnail.includes("base64")
                            ? it?.thumbnail
                            : `data:${it?.file_type};base64,${it?.thumbnail}`
                        }
                        className="mx-2 wo-images"
                        alt="table"
                        onClick={() => handleImageClick(index + 6)}
                      // onClick={(e) => HandleAttch(e, it)}
                      />
                    ))}
                </span>
                {imageViewExpanded && (
                  <div onClick={() => setImageViewExpanded(false)}>
                    <SlArrowUp />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Card>
      <AttachmentModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedImageIndex={selectedImageIndex}
        handleCarouselSelect={handleCarouselSelect}
        zoomLevel={zoomLevel}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomMessage={zoomMessage}
        openImageInNewTab={openImageInNewTab}
        eachWorkOrder={filteredImages}
      />
      <ShareWoModal
          Open={isOpen}
          Close={() => setIsOpen(false)}
          data={eachWorkOrder}
          path = {"WoDetails"}
        woid = {eachWorkOrder?.WorkOrderId}
        custid ={eachWorkOrder?.Customer?.id}
        />
        <MemoryModal
          isOpen={isMemory}
          id={eachWorkOrder?.WorkOrderId}
          name={"Work Order"}
          descvalue={descvalue}
           isClose={() => setIsMemory(false)}
           ismemoryObj={ismemoryObj}
           womemoryopt={womemoryopt}
           woId = {eachWorkOrder?.WorkOrderId}
           updateMemoryBoardStatus={updateMemoryBoardStatus}
        />
         <MemoryblueModal 
          Open = {mryblue}
          Close= {()=>setMryblue(false)}
          custname={memobj?.Customer?.name}
          ismemoryObj={ismemoryObj}
           updateMemoryBoardStatus={updateMemoryBoardStatus}
          memobj= {memobj}
          mrydlt= {mrydlt}
          itemkey= {itemkey}
          isProgress={ismemProgress}
          descvalue={descvalue}
          headname ={itemkey === "workorder"?memobj?.name:""}
          headid={itemkey === "workorder"?memobj?.WorkOrderId:""}
          openName={itemkey === "workorder"? "Work Order":""}
          />
    </>
  );
};

export default WorkOrderCard;
