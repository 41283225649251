import React, { useState, useEffect } from "react";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import WfEquipment from "../../../../common/Assets/Icons/Equipmentwf.svg";
import del from "../../../../common/Assets/Icons/del.svg";
import { Modal } from "react-bootstrap";
import Close2 from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import Select from "react-select";
import {
  CallTypesAdd,
  CallTypesFetch,
  DelCallTypes
} from "../../../../services/SettingsServices";
import { WORK_ORDER_CONSTANTS } from "../../../../common/components/Constants";
import ProgressBar from "../../../../common/navigation/ProgressBar";

export default function CallType(props) {
  const [modalType, setModalType] = useState(null);
  const [inputValues, setInputValues] = useState({
    call_type_id: 0,
    code: "",
    description: "",
    workflow: "",
    workflow_estimate: "",
  });

  const CheckboxOption = (props) => {
    return (
      <div {...props.innerProps} className="custom-checkbox-option m-3">
        <input className="" type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <label className="">{props.label}</label>
      </div>
    );
  };

  const [oldCallTypeCode, setOldCallTypeCode] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [isDataVisible, setIsDataVisible] = useState(true);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [items, setItems] = useState([]);
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [dropdownOptions,] = useState({
    estimatedWorkflowsAttached: [],
  });

  const openModal = (type, item) => {
    setModalType(type);
    setSelectedItem(item);
    if (type === "Edit") {
      setOldCallTypeCode(item.call_type_code); 
      setInputValues({
        call_type_id: item.call_type_id,
        code: item.call_type_code,
        description: item.call_type_description,
      });
    } else if (type === "Delete") {
      setItemToDelete(item);
    }
    setValidationError("");
  };
  const closeModal = () => {
    setModalType(null);
    setSelectedItem(null);
    setValidationError("");
    setInputValues({
      call_type_id: 0,
      code: "",
      description: "",
      workflow: "",
      workflow_estimate: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value.trim(),
    }));
  };

  const handleSelectChange = (selectedOptions) => {
    setInputValues(prevValues => ({
      ...prevValues,
      workflowsAttached: selectedOptions
    }));
  };

  const validateInputs = () => {
    const {
      code,
      description,
    } = inputValues;

    const trimmedcode = code.trim();
    const trimmeddescription = description.trim();

    if (code !== trimmedcode) {
      setValidationError("Leading or trailing spaces for Call Type Code not allowed.");
      return false;
    }

    if (description !== trimmeddescription) {
      setValidationError("Leading or trailing spaces for Call Type Description not allowed.");
      return false;
    }

    if (code.length > 15) {
      setValidationError("Call Type Code cannot exceed 15 characters.");
      return false;
    }

    if (description.length > 150) {
      setValidationError("Call Type Description cannot exceed 150 characters.");
      return false;
    }

    if (!trimmedcode || !trimmeddescription) {
      setValidationError("Please enter all input fields.");
      return false;
    }
    setValidationError("");
    return true;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [isSaving, setIsSaving] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const handleSave = async () => {
    if (isSaving) return;
    const { code } = inputValues;
    if(modalType === "Save"){
    const isDuplicate = allcalldata.some(
      (item) => item.call_type_code.toLowerCase() === code.toLowerCase()
    );
    if (isDuplicate) {
      setValidationError("Call Type Code already exists. Please enter a unique Code.");
      return;
    }
  };
  
    if (validateInputs()) {
      setIsSaving(true);
      setIsProgress(true);
      try {
        let res = await CallTypesAdd(inputValues);
        fetchData();
        setIsProgress(false);
        closeModal();
      } catch (error) {
        console.error("Error occurred while saving:", error);
      } finally {
        setIsSaving(false);
      }
    } else {
    }
  };
  
  const [allcalldata, setAllcalldata] = useState([]);
  const fetchData = async () => {
    const result = await CallTypesFetch();
    if (result?.data) {
      setAllcalldata(result.data.sort((a, b) => a.call_type_id - b.call_type_id));
    }
  };

  // const handleEdit = async () => {
  //   alert("edit")
  //   if (checkIfCodeExists(inputValues.code.trim(), selectedItem.call_type_id)) {
  //     alert("Call Type Code already exists. Please use a different code.");
  //     return;
  // }
  //   if (validateInputs()) {
  //     if (
  //       inputValues.code === selectedItem.call_type_code &&
  //       inputValues.description === selectedItem.call_type_description
  //     ) {
  //       closeModal();
  //       return;
  //     }
  //     try {
  //       const updatedItem = {
  //         call_type_id: inputValues.call_type_id,
  //         code: inputValues.code?.trim().slice(0, 15),
  //         description: inputValues.description?.trim().slice(0, 150),
  //       };
  //       const response = await CallTypesAdd(updatedItem, true);
  //       if (response?.success) {
  //         setOldCallTypeCode(inputValues.code);
  //         setIsProgress(false);
  //         fetchData();
  //         closeModal();
  //       }
  //     } catch (error) {
  //       alert("An error occurred while editing. Please try again.");
  //     }
  //   }
  // };

  const handleDelete = async () => {
    let Res = await DelCallTypes(itemToDelete.call_type_id);
    if (Res?.success) {
      const updatedItems = allcalldata.filter((item) => item.call_type_id !== itemToDelete.call_type_id);
      setAllcalldata(updatedItems);
      closeModal();
    }
  };

  const renderModalHeader = () => {
    let headerText = "";
    switch (modalType) {
      case "Save":
        headerText = "Create Call Type";
        break;
      case "Edit":
        headerText = `Edit Call Type - ${oldCallTypeCode}`;
        break;
      case "Delete":
        headerText = "Are you sure you want to delete";
        break;
      default:
        headerText = "";
    }
    if (headerText?.length > 0) {
      return (
        <Modal.Header className="modal-bg-color modal-header-height">
          <div className="line-align-bet w-100">
            <span className="wftags-sub-header2">{headerText}</span>
            <span className="cursor-p" onClick={closeModal}>
              <img className="modal-header-icon" src={Close2} alt="Close" />
            </span>
          </div>
        </Modal.Header>
      );
    }
  };

  const toggleDataVisibility = () => {
    setIsDataVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className="line-align-bet w-100 clp_header">
        <div
          style={{ fontFamily: "SofiaProBold" }}
          onClick={toggleDataVisibility}
        >
          <img
            src={WfEquipment}
            alt="WfWrench"
            width={20}
            className="cursor-p mx-1 mb-1"
          />
          <span className="mx-1 wftags-sub-header cursor-p">Call Types</span>
        </div>
        <div className="wf-plus">
          <div onClick={() => openModal("Save")}>
            <img src={Add_Main} alt="Add-Main" />
          </div>
        </div>
      </div>

      {isDataVisible && (
        <div>
          <div className="card">
            {allcalldata?.length > 0 ? (
              allcalldata?.map((item) => (
                <div
                  key={item?.call_type_id}
                  className="line-align-bet field-hover-eff w-100 p-2"
                  style={{
                    borderBottom: "1px solid rgb(209, 202, 202)",
                  }}
                  onClick={() => openModal("Edit", item)}
                >
                  <span className="w-100 sett-fs-wo-inp center-line-adj cursor-p">
                    {item?.call_type_code}
                  </span>
                  <img
                    src={del}
                    alt="del"
                    className="icon-sz cursor-p"
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal("Delete", item);
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="align-rt w-100 p-2">
                <span className="err-data-wfsetup">There is no data</span>
              </div>
            )}
          </div>
        </div>
      )}

      {modalType !== null && (
        <Modal centered show={modalType !== null} onHide={closeModal}>
          {renderModalHeader()}

          <Modal.Body className="caltype_model">
            {modalType === "Delete" ? (
              <div className="sub-titles-names">
                <p className="mb-1">
                {WORK_ORDER_CONSTANTS.CALL_DEL}
                </p>
                <div className="d-flex justify-content-center mt-2">
                  <div className="modal-footer-save-cancel-btns mx-2">
                    <span onClick={handleDelete}>Delete</span>
                  </div>
                  <div className="modal-footer-save-cancel-btns mx-2">
                    <span onClick={closeModal}>Cancel</span>
                  </div>
                </div>
              </div>
            ) : modalType === "Edit" || modalType === "Save" ? (
              <div>
                {isProgress === true ? (
                  <div style={{
                    height: "295px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <ProgressBar/>
                  </div>
                ):(
              <div>
                <div>
                <div className="mt-1">{props?.isType} Code:</div>
                <input
                  className="w-100 wfs-type-input"
                  placeholder="Input Code"
                  name="code"
                  value={inputValues.code}
                  onChange={handleInputChange}
                  maxLength={15}
                  autoComplete="off"
                />
                <div className="mt-1">{props?.isType} Description:</div>
                <input
                  className="w-100 wfs-type-input"
                  placeholder="Input Description"
                  name="description"
                  value={inputValues.description}
                  onChange={handleInputChange}
                  maxLength={150}
                  autoComplete="off"
                />

                <div className="mt-1">Workflows Attached</div>
                <Select
                  className="w-100"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isMulti
                  options={workflowOptions}
                  onChange={handleSelectChange}
                  value={inputValues.workflow}
                  placeholder="Input all Workflows you want attached to this type."
                  components={{ Option: CheckboxOption }}
                />

                <div className="mt-1">Estimated Workflows Attached</div>
                <select
                  className="w-100 wfs-type-input cursor-p"
                  name="workflow_estimate"
                  value={inputValues.workflow_estimate}
                  onChange={handleInputChange}
                >
                  <option value="">
                    Input all Workflows you want attached to this type.
                  </option>
                  {dropdownOptions.estimatedWorkflowsAttached.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                </div>
                {validationError && (
                  <div
                    className="text-danger mt-2"
                    style={{ fontSize: "0.75rem" }}
                  >
                    {validationError}
                  </div>
                )}               
              </div>
              )}
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          {( modalType === "Edit" || modalType === "Save") && (
          <div className="d-flex mt-2 model_footer">
                  <div onClick={handleSave} className="modal-footer-save-cancel-btns">
                    <span>Save</span>
                  </div>
                  <div className="modal-footer-save-cancel-btns">
                    <span onClick={closeModal}>Cancel</span>
                  </div>
                </div>
                )}
        </Modal>
      )}
    </>
  );
}
