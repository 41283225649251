export const WorkOrderComplete = (WorkFlowData) => {
  // let NewData = WorkFlowData.map((workflow) => {
  //   const stepsMissing = workflow.Workflows_steps.filter(
  //     (step) => step?.step_required && step?.step_complete !== "1"
  //   ).map((step) => step.name);
  //   return {
  //     work_order_workflow_id: workflow.work_order_workflow_id,
  //     workflow_name: workflow.workflow_name,
  //     stepsMissing,
  //   };
  // });
  let NewData = WorkFlowData.map((workflow) => {
    let filteredSteps = workflow.Workflows_steps.filter((step) => {
      return step?.step_required && step?.step_complete !== "1";
    });
    return {
      ...workflow,
      Workflows_steps: filteredSteps,
      type: "required",
    };
  }).filter((item) => item.Workflows_steps.length > 0);

  if (NewData.length === 0) {
    NewData = WorkFlowData.map((workflow) => {
      let filteredSteps = workflow.Workflows_steps.filter((step) => {
        return !step?.step_required && step?.step_complete !== "1";
      });

      return {
        ...workflow,
        Workflows_steps: filteredSteps,
        type: "not required",
      };
    }).filter((item) => item.Workflows_steps.length > 0);
  }
  return {
    NewData,
  };
};
