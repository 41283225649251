import moment from "moment";
import React, { useState, useEffect } from "react";
import person from "../../common/Assets/Images/Unionperson.svg";
import { Modal } from "react-bootstrap";
import Close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import PdfImg from "../../common/Assets/Images/pdf-1.png";
export default function Message(props) {
  const [User, setUser] = useState("");
  const [mediaUrl, setMediaUrl] = useState({ url: "", type: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [MediaData, setMediaData] = useState({});
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userName")));

    if (props?.data?.media) {
      if (
        props?.data?.media?.contentType === "image/jpeg" ||
        props?.data?.media?.contentType === "image/png" ||
        props?.data?.media?.contentType === "image/jpg"
      ) {
        props?.data?.media
          .getContentTemporaryUrl()
          .then((url) => {
            setMediaUrl({ url: url, type: "image" });
          })
          .catch((e) => {
            return e;
          });
      } else if (props?.data?.media?.contentType === "video/mp4") {
        props?.data?.media
          .getContentTemporaryUrl()
          .then((url) => {
            setMediaUrl({ url: url, type: "video" });
          })
          .catch((e) => {
            return e;
          });
      } else if (props?.data?.media?.contentType === "application/pdf") {
        props?.data?.media
          .getContentTemporaryUrl()
          .then((url) => {
            setMediaUrl({ url: url, type: "pdf" });
          })
          .catch((e) => {
            return e;
          });
      }
    }
  }, [props?.data?.media]);
  const HandleMediaModal = (data) => {
    setIsOpen(true);
    setMediaData(data);
  };

  return (
    <div>
      {" "}
      {props?.data?.author === User ? (
        <div
          className="d-flex mt-2 mx-3"
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="p-2 mx-1"
            style={{
              backgroundColor: "#1A60A3",
              minWidth: "170px",
              maxWidth: "500px",
              color: "white",
              borderRadius: "10px",
              fontSize: "12px",
              wordBreak: "break-word",
            }}
          >
            {props?.data?.type === "media" ? (
              mediaUrl.type === "image" ? (
                <img
                  src={mediaUrl.url}
                  alt="img"
                  width={100}
                  onClick={() => HandleMediaModal(mediaUrl)}
                />
              ) : mediaUrl.type === "video" ? (
                <video controls src={mediaUrl.url} width={200} />
              ) : (
                <img
                  src={PdfImg}
                  width={100}
                  onClick={() => HandleMediaModal(mediaUrl)}
                  alt="PdfImg"
                />
              )
            ) : (
              <div> {props?.data?.body}</div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ fontSize: "8px" }}>
                {" "}
                {moment(props?.data?.dateCreated).format("hh:mm A")}
              </span>
            </div>
          </div>
          <div className="mx-1">
            <img
              src={person}
              alt="person"
              className="cursor-pointer"
              width={15}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex mx-3 mt-2">
          <div className="mx-1">
            <img
              src={person}
              alt="person"
              className="cursor-pointer"
              width={15}
            />
          </div>
          <div
            className="p-2 mx-1"
            style={{
              backgroundColor: "#F2F2F2",
              minWidth: "170px",
              maxWidth: "500px",
              borderRadius: "10px",
              fontSize: "12px",
              wordBreak: "break-word",
            }}
          >
            <div
              style={{
                color: "#1A60A3",
                fontFamily: "SofiaProBold",
                fontSize: "14px",
              }}
            >
              <span>{props?.data?.author}</span> &nbsp;
            </div>

            {props?.data?.type === "media" ? (
              mediaUrl.type === "image" ? (
                <img
                  src={mediaUrl.url}
                  alt="img"
                  width={100}
                  onClick={() => HandleMediaModal(mediaUrl)}
                />
              ) : mediaUrl.type === "video" ? (
                <video controls src={mediaUrl.url} width={200} />
              ) : (
                <img
                  src={PdfImg}
                  width={100}
                  onClick={() => HandleMediaModal(mediaUrl)}
                  alt="PdfImg"
                />
              )
            ) : (
              <div> {props?.data?.body}</div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ fontSize: "8px" }}>
                {" "}
                {moment(props?.data?.dateCreated).format("hh:mm A")}
              </span>
            </div>
          </div>
        </div>
      )}
      <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <span style={{ color: "white" }}>{MediaData?.type}</span>
          <img
            className="modal-header-icon"
            onClick={() => setIsOpen(false)}
            src={Close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body style={{ height: "500px" }}>
          {MediaData?.type === "pdf" ? (
            <embed src={MediaData.url} width="100%" height="100%" />
          ) : (
            <img
              src={MediaData.url}
              width="100%"
              height="100%"
              alt="img"
              style={{ objectFit: "contain" }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
