import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import CommonHeader from '../../common/framework/header/HeaderCommon/CommonHeader';
import TableImg from "../../common/Assets/Images/tableStk.png";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import EstimateSidebar from './EstimateSidebar';
import { Col, Row } from 'react-bootstrap';
import del from "../../common/Assets/Icons/del.svg";
import Sort2 from "../../common/Assets/Icons/Sort2.svg";
import './EstimatesTable.css'; 

const EstimatesTable = () => {
    const [isExpand, setIsExpand] = useState("");
    const [isProgress, setIsProgress] = useState(true);
    const [over, setOver] = useState(false);

    const handleExpand = (val) => {
        setIsExpand(val);
    };

    const tableData = [
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
      {
        customerName: 'Commercial Equipment Repair',
        customerLink: '#',
        name: 'Existing Furnace',
        code: '2340-104',
        description: 'CONCORDIA BEVERAGE SYSTEMS 2340-104 GENUINE OEM REPLACEMENT PART...',
        category: 'Repair',
        totalPrice: '$0',
      },
    ];

    return (
      <div>
        <CommonHeader />
        <div className="line-align-bet mt-2 w-100">
          <div className="d-flex">
            <Link to="/Estimate">
              <img
              className='ml-1'
                src={TableImg}
                alt="table"
                style={{ cursor: "pointer", height: 34, width: 34 }}
              />
            </Link>
            &nbsp;
            <span className="TableText">Estimates</span>
          </div>

          <div className='d-flex'>
            <button className="crt-est-table mx-3 p-4">
              Create New Estimate
              <AiOutlinePlus size={12} style={{ marginLeft: 8, marginBottom: 2 }}  />
            </button>
          </div>
        </div>
        <Row className="w-100 layout-container">
          {isExpand === "expand" ? null : (
            <Col
              onMouseOver={() => setOver(true)}
              onMouseOut={() => setOver(false)}
              style={{ pointerEvents: isProgress === true ? "none" : "auto" }}
              xs={isExpand === "expand" ? 12 : 2}
              md={isExpand === "expand" ? 12 : 2}
              lg={isExpand === "expand" ? 0 : 2}
              className="estimates-col1"
            >
              <EstimateSidebar />
            </Col>
          )}

          <Col
            xs={isExpand === "expand" ? 12 : 10}
            md={isExpand === "expand" ? 12 : 10}
            lg={isExpand === "expand" ? 12 : 10}
            className="workOrder-col2"
          >
            <>
              <span
                className={isExpand === "expand" || over ? "backbtn-hvr2 p-0 m-0" : "backbtn-hvr p-0 m-0"}
              >
                {isExpand === "expand" ? (
                  <MdChevronRight size={25} onClick={() => handleExpand("")} />
                ) : (
                  <MdChevronLeft size={25} onClick={() => handleExpand("expand")} />
                )}
              </span>
              <div className="mx-2 mt-2 card p-1 w-100">
                {/* Table Section */}
                <div className="table-container">
                  <table className="responsive-table">
                    <thead>
                      <tr className='tabletrdata'>
                        <th>Customer Name<img src={Sort2} alt="Sort2" width={22}/></th>
                        <th>Name & Code<img src={Sort2} alt="Sort2" width={22}/></th>
                        <th>Description<img src={Sort2} alt="Sort2" width={22}/></th>
                        <th>Category<img src={Sort2} alt="Sort2" width={22}/></th>
                        <th>Total Price<img src={Sort2} alt="Sort2" width={22}/></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index} className='tabletrdata'>
                         <td>
                           <a href="#" onClick={(e) => e.preventDefault()}>{row.customerName}</a>
                        </td>

                          <td>
                            <div>{row.name}</div>
                            <div>{row.code}</div>
                          </td>
                          <td>{row.description}</td>
                          <td>{row.category}</td>
                          <td>{row.totalPrice}</td>
                          <td>
                            <button className="delete-btn">
                            <img src={del} alt='delete'/>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </Col>
        </Row>
      </div>
    );
};

export default EstimatesTable;
