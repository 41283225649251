import React, { useCallback, useEffect, useState } from "react";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import CloseIcon from "../../common/Assets/Icons/Close_round_main.svg";
import CreateIcon from "../../common/Assets/Icons/add.svg";
import {WOListGet} from "../../services/WorkOrdersService"
import "./WOrdersList.css";
import { Card } from "react-bootstrap";
import WODupModal from "./WODupModal";
import {  useNavigate } from "react-router-dom";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import ProgressBar from "../../common/navigation/ProgressBar";
function WOrdersList() {
  const navigation = useNavigate();
  const [isProgress, setIsProgress] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [ workOrderList , setWorkorderList] = useState([]);
  let TechId = JSON.parse(localStorage.getItem("technicianid"));
   let techId = JSON.parse(TechId)
  const HandleWOList = useCallback(async () => { 
    setIsProgress(true)
      let Resp = await WOListGet(techId);
      setIsProgress(false)
      setWorkorderList(Resp?.WorkOrderList);
  }, [techId])
 
  useEffect(() => {
    localStorage?.removeItem("reswoid");
    localStorage?.removeItem("rescustid");
    HandleWOList()
  },[HandleWOList])

  const clickHandler = (item) => {
    setSelectedWorkOrder(item);
    setIsOpen(true);
  };
  const handleCancel = () => {
    NavigateFunction({
      to: "WorkOrders",
      navigate: navigation,
    });
  };
  const handleCreateNew = () => {
    let userId = localStorage.getItem("userId");
    let userPin = parseInt(JSON.parse(localStorage.getItem("techpin")));
    let companyCode = parseInt(JSON.parse(localStorage.getItem("CompanyCode")));
    NavigateFunction({
      to: `WoDetails/0/0/${userId}/${userPin}/${companyCode}/0`,
      params: {isVal:"",woid:0,custid:0 },
      navigate: navigation,
    });
  };
  return (
    <div>
      <div className="sticky-headers-wol">
        <CommonHeader />
      </div>
      
      <div className="title-div line-align-bet mt-2 w-100 sticky-header-content">
        <span className="Wolist-title">Create Work Order</span>
        <div className="d-flex mx-2">
          <div className="cnc-discard-btn-styles mx-1 crt-wrk-order" onClick={handleCancel} >
            Cancel
            <img
              className=" ml-1 img-bg-color"
              src={CloseIcon}
              alt="delete"
              width={12}
            />
          </div>
          <div
            className="cnc-discard-btn-styles mx-1 crt-wrk-order"
            onClick={handleCreateNew}
          >
            <span> Create New </span>
            <img
              // style={{ marginLeft: 2 }}
              className=" ml-1 img-bg-cn "
              src={CreateIcon}
              alt="close"
              width={10}
            />
          </div>
        </div>
      </div>

      <div className="descrptn-div">
        <span className="wrkflw-txt">
          Select a workflow from below to duplicate a current work order
        </span>
      </div>

      {/* Scrollable container for the list */}
      {isProgress === true ? (
        <div className="progress-bar">
          <ProgressBar />
        </div>
      ) : (
       <div className="pd-10">
      <div className="wocard-div">
        {workOrderList
          ?.sort((a, b) => (b?.WorkOrderId > a?.WorkOrderId ? 1 : -1))
          .map((item) => {
            return (
              (
                <Card
                  key={item?.id} // Add a unique key for each Card component
                  className="mt-1 wo-card-styles"
                  onClick={() => clickHandler(item)}
                >
                  <span className="wo-name-txt">
                    {item?.name} #{item?.WorkOrderId}
                  </span>
                  <div className="line-align">
                    <span className="wo-cust-txt">Customer Name:</span>
                    <span className="wo-cust-space">
                      {item?.Customer?.name}
                    </span>
                  </div>
                   <div className="line-align">
                  <span className="wo-cust-txt">Description:</span>
                  <span className="wo-cust-space">
                      {item?.description}
                    </span>
                    </div>
                </Card>
              )
            );
          })}
      </div>
      </div>
      )}
      
      <WODupModal
        Open={isOpen}
        Close={() => setIsOpen(false)}
        data={selectedWorkOrder}
       
      />
    </div>
  );
}
export default WOrdersList;
