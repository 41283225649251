import React, { useState, useContext, useEffect } from "react";
import "./WorkFlow.css";
import Pen from "../../../common/Assets/Icons/Pen.svg";
import delBlack from "../../../common/Assets/Icons/del.svg";
import YesNo from "../../../common/Assets/Images/YesNo.png";
import fill_out_table from "../../../common/Assets/Icons/fill_out_table.svg";
import CrtDropDown from "../../../common/Assets/Images/formData.png";
import StepMedia2 from "../../../common/Assets/Images/stepMedia2.png";
import StepMediaRed from "../../../common/Assets/Images/RedAttach.svg";
import CrteEqup from "../../../common/Assets/Images/VectorEqp.svg";
import StepMedia from "../../../common/Assets/Images/stepMedia.png";
import AddInput from "../../../common/Assets/Images/AddInput.png";
import pin from "../../../common/Assets/Icons/AttachPin.svg";
import plus from "../../../common/Assets/Icons/Add_round.svg";
import { Modal } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";

import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import FillTable from "../../../common/Assets/Images/Table_lighttable.png";
import CrtEqup from "../../../common/Assets/Images/Equipmentdrill.png";
import FormMaster from "../../../common/Assets/Images/FormMaster.png";
import NotePlus from "../../../common/Assets/Images/PencilAdd.svg";
import RedNote from "../../../common/Assets/Images/PencilRed.svg";
import { TABLE_ÍCON_SIZES } from "../../../common/components/Values";
import {
  WorkflowAttachment,
  AddNotesSteps,
  NoteDeleteFunction,
  WorkflowStepupdate,
  AddNotesSteps2,
  HandleWorkFlowComplete,
  addTags,
  deleteTags,
} from "../../../services/WorkOrdersService";
import { AppGlobalContext } from "../../../common/context/AppGlobalContext";
import NoteAddModal from "../../Notes/NoteAddModal";
import StepsAttachments from "./StepsAttachments";
import ProgressBar from "../../../common/navigation/ProgressBar";
import moment from "moment";
import { StepRulesCheck } from "./StepRulesCheck";
import { ReferenceId } from "../../../common/components/EnumRefType";
import { HandleStepComplete } from "./StepCheckUpdate";
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import EquipmentCard from "../../equipments/EquipmentCard";
import EquipmentInfoModal from "../../equipments/Equipment Info Modal/EquipmentInfoModal";
import DeleteTags from "../addtag/DeleteTags";
import AddTag from "../addtag/AddTag";

export default function Steps(props) {
  const [isattach, setIsAttach] = useState(false);
  const [wowfId, setWoWfId] = useState(0);
  const [workFlow_Id, setWorkFlow_Id] = useState(0);
  const [isnoteAtt, setIsNoteAtt] = useState(false);
  const [inpval2, setInpVal2] = useState("");
  const appState = useContext(AppGlobalContext);
  const HandleRadio = async (val, item) => {
    const updatedItem = { ...item, choice_result: val?.response_workflow_id };
    let inpObj = {
      workorder_step_id: updatedItem?.work_order_workflow_step_id,
      workorder_id: props?.WorkNo,
      workorder_workflow_id: updatedItem?.work_order_workflow_id,
      content_note: updatedItem?.content_note,
      choice_result: val?.response_workflow_id,
    };
    let res = await WorkflowStepupdate(inpObj);
    if (res?.success) {
      updateItemState(updatedItem);
      item.choice_result = res?.data?.choice_result;
      if (HandleStepComplete(item)) {
        let WoWfId = 0;
        let WfStepId = item?.work_order_workflow_step_id;
        let Type = "1";
        let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
        if (Res?.success) {
          item.step_complete = "1";
          setWoWfId(wowfId);
        }
      }
    }
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props?.data?.Workflows_steps);
  }, [props?.data?.Workflows_steps]);

  const updateItemState = (updatedItem) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.work_order_workflow_step_id ===
        updatedItem.work_order_workflow_step_id
          ? updatedItem
          : item
      )
    );
  };

  const HandleRadio2 = async (val, item) => {
    const updatedItem = { ...item, choice_result: val?.response_workflow_id };
    const inpObj = {
      workorder_step_id: updatedItem.work_order_workflow_step_id,
      workorder_id: props?.WorkNo,
      workorder_workflow_id: updatedItem.work_order_workflow_id,
      content_note: updatedItem.content_note,
      choice_result: val?.response_workflow_id,
    };

    let res = await WorkflowStepupdate(inpObj);
    updateItemState(updatedItem);
    if (res?.success) {
      updatedItem.choice_result = res?.data?.choice_result;

      if (HandleStepComplete(updatedItem)) {
        let WoWfId = 0;
        let WfStepId = updatedItem.work_order_workflow_step_id;
        let Type = "1";
        let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
        if (Res?.success) {
          updatedItem.step_complete = "1";
          setWoWfId(WoWfId);
        }
      }
    }
  };

  const HandleInputChange = (event, item, id) => {
    const newValue = event?.target?.value;
    if (item) {
      // item.content_note =     
      // setInpVal2(event?.target?.value);
      const updatedItem = { ...item, content_note: newValue };
      setItems((prevItems) => 
        prevItems.map((i) =>
          i.work_order_workflow_step_id === id ? updatedItem : i
        )
      );
      setInpVal2(newValue);
    } else {
    }
  };
  const HandleStepInputUpdate = async (item) => {

    let inpObj = {
      workorder_step_id: item?.work_order_workflow_step_id,
      workorder_id: props?.WorkNo,
      workorder_workflow_id: item?.work_order_workflow_id,
      content_note: inpval2,
      choice_result: "",
    };
    let res = await WorkflowStepupdate(inpObj);
    if (res?.success) {
      item.content_note = inpval2;
      if (inpval2?.length <= 0) {
        let WoWfId = 0;
        let WfStepId = item?.work_order_workflow_step_id;
        let Type = "0";
        let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
        if (Res?.success) {
          item.step_complete = "0";
        }
      }
      if (HandleStepComplete(item)) {
        let WoWfId = 0;
        let WfStepId = item?.work_order_workflow_step_id;
        let Type = "1";
        let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
        if (Res?.success) {
          item.step_complete = "1";
        }
      }
    }
  };
  const [attachItem, setAttachItem] = useState({});
  const HadlePicker = (val) => {
    setAttachItem(val);
    setWorkFlow_Id(val.work_order_workflow_step_id);
    document.getElementById("inp-id").click();
  };

  const [isUpload, setIsUpload] = useState(false);
  const HandleFilePicker = (e, i) => {
    setIsUpload(true);
    let WorkPath = window.location.href.split("/");
    let contType = e.target.files[0].type;
    let fileName = e.target.files[0].name;
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = async function () {
      let baseFile = reader.result.split(",")[1];
      let inpObj = {
        file_type: contType,
        filename: fileName,
        content: baseFile,
        work_order_id: WorkPath[5],
        reference_id: workFlow_Id,
        reference_type: ReferenceId?.work_order_workflow_step,
        uuid_uuid: 0,
      };
      let res = await WorkflowAttachment(inpObj);
      if (res?.success) {
        setIsUpload(false);
        i = attachItem;
        setAttachItem(
          i?.attachment_list?.push(
            res?.data?.attachment?.attachResponses
          )
        );
        if (HandleStepComplete(i)) {
          let WoWfId = 0;
          let WfStepId = i?.work_order_workflow_step_id;
          let Type = "1";
          let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
          if (Res?.success) {
            i.step_complete = "1";
            setAttachItem(i);
          } else {
            setAttachItem(
              i?.attachment_list?.push(
                res?.data?.attachment?.attachResponses?.thumbnail_content
              )
            );
          }
        }
      }
      e.target.value = null;
    };
  };

  const [noteInpObj, setNoteInpObj] = useState({
    note: "",
    title: "",
  });
  const [isNid, setIsNid] = useState(0);
  const [NoteItem, setNoteItem] = useState({});
  const [Notehedr, setNoteHedr] = useState("");
  const HandleNoteModal = (val) => {
    if(val?.notes[0]?.note_id > 0)
    {
      setNoteHedr("Edit Note")
    }
    else{
      setNoteHedr("Add Note")
    }
    // setNoteInpObj({});
    setNoteInpObj({
      note: val?.notes[0]?.notes,
      title: val?.notes[0]?.title,
    });
    setNoteItem(val);
    setIsNid(val?.notes[0]?.note_id ? val?.notes[0]?.note_id : 0);
    setWoWfId(val.work_order_workflow_step_id);

    setIsAttach(true);
  };

  const ChangeEvents = (e) => {
    const { name, value } = e.target;
    setNoteInpObj({ ...noteInpObj, [name]: value });
    setIsNoteErr("");
  };
  const [isNote, setIsNote] = useState(false);
  const [IsNoteErr, setIsNoteErr] = useState("");
  const HandleSubmit = async (item) => {
    if (noteInpObj.note?.length > 0) {
      setIsNote(true);
      let arrayIndex = window.location.href.split("/");
      let InpObj = {
        content: noteInpObj.note,
        title: "",
      };

      let Response = await AddNotesSteps2(
        arrayIndex[5],
        isNid,
        wowfId,
        ReferenceId?.work_order_workflow_step,
        0,
        InpObj
      );
      if (Response?.success) {
        setIsNote(false);
        setIsAttach(false);
        item.notes[0] = Response?.data;
        setIsNid(Response?.data?.note_id);
        if (HandleStepComplete(item)) {
          let WoWfId = 0;
          let WfStepId = item?.work_order_workflow_step_id;
          let Type = "1";
          let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
          if (Res?.success) {
            item.step_complete = "1";
          }
        }

        setNoteItem({});
      }
    } else {
      setIsNoteErr("Please Add description");
    }
  };
  const [attwoWfId] = useState(0);
  const [attWfSid] = useState(0);
  const HandlePicker = () => {
    document.getElementById("inp-pick").click();
  };
  const [baseImg, setBaseImg] = useState([]);
  const [filesName, setFilesName] = useState([]);

  const FilePick = (e, val) => {
    setFilesName([...filesName, e.target.files[0]]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = async function () {
      setBaseImg([...baseImg, reader.result]);
    };
  };
  const [noteattObj, setNoteAttObj] = useState({
    content: "",
    title: "",
  });
  const HandleInputNotes = (e) => {
    const { name, value } = e.target;
    setNoteAttObj({ ...noteattObj, [name]: value });
  };
  const [isAttNote, setIsAttNote] = useState(false);
  const HandleNoteAttchAdd = async () => {
    let WorkPath = window.location.href.split("/");
    if (
      baseImg.length === 0 ||
      filesName.length === 0 ||
      noteattObj?.content.length === 0 ||
      noteattObj?.title.length === 0
    ) {
      alert("Required fields must not be Empty");
    } else {
      if (baseImg.length > 0 && filesName.length > 0) {
        let concatArr = baseImg.map((it) => it.split(",")[1]);
        let FinalArr = filesName.map((item, i) =>
          Object.assign(item, { content: concatArr[i] })
        );
        const newAr = FinalArr?.map((it) => ({
          file_type: it.type,
          filename: it.name,
          content: it.content,
          technician: WorkPath[7],
          customer: WorkPath[6],
          workorder: WorkPath[5],
          equipment: 0,
          workorder_workflow: attwoWfId,
          workorder_step: attWfSid,
        }));
        let res = newAr.map((it) => {
          WorkflowAttachment(it)
            .then((item) => {
              return item;
            })
            .catch((err) => {
              return err;
            });
          return it;
        });
        if (newAr?.length > 0 && newAr?.length === res?.length) {
          setBaseImg([]);
          setFilesName([]);
        }
      }
      if (Object.keys(noteattObj || {}).length > 0) {
        setIsAttNote(true);
        let InpObj = {
          content: noteattObj.content,
          title: noteattObj.title,
          technician: 124,
        };
        let Response = await AddNotesSteps(
          WorkPath[5],
          attwoWfId,
          attWfSid,
          InpObj
        );
        if (Response.success) {
        }
      }
      if (Object.keys(noteattObj).length > 0 || baseImg.length > 0) {
        // UpadateWorkFlow();
      }
    }
  };

  const [isCheckId, setisCheckId] = useState([]);
  const [isCheckModal, setIsCheckModal] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState([]);

  const handleCheckEvents = (item) => {
    let filterdId = [...isCheckId];
    if (isCheckId?.includes(item?.workflow_step_id)) {
      filterdId = isCheckId.filter((i) => i !== item?.workflow_step_id);
    } else {
      filterdId = [...isCheckId, item?.workflow_step_id];
    }
    setisCheckId(filterdId);
    let AttachContentRules = [];
    if (item?.notes?.length === 0) {
      setIsCheckModal(true);
      AttachContentRules.push("Note Required");
    }

    if (item?.attachment_list?.length === 0) {
      setIsCheckModal(true);
      AttachContentRules.push("Attachment Required");
    }
    // if (item.step_required === true && item.resallowCustomerView === false) {
    //   setIsCheckModal(true);
    //   AttachContentRules.push('Customer Share Required');
    // }
    // if (item.step_required === true) {
    if (item.type === "Add Input Field" && item.content_note === "") {
      setIsCheckModal(true);
      AttachContentRules.push("Value Required");
    } else if (item.type === "Create Equipment" && item.content_note === "") {
      setIsCheckModal(true);
      AttachContentRules.push("Value Required");
    } else if (
      item.type === "Create Drop Down" &&
      (item.choice_result === "" || item.choice_result === null)
    ) {
      setIsCheckModal(true);
      AttachContentRules.push("Choice Required");
    } else if (
      item.type === "Yes/No Selection" &&
      (item.choice_result === "" || item.choice_result === null)
    ) {
      setIsCheckModal(true);
      AttachContentRules.push("Choice Required");
    }
    // }
    setErrorMsg(AttachContentRules);
  };
  const HandleNoteDelete = async (value, items) => {
    let DeleteRes = await NoteDeleteFunction(value);
    if (DeleteRes?.success) {
      items.notes = [];
      let WoWfId = 0;
      let WfStepId = items?.work_order_workflow_step_id;
      let Type = "0";
      await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
      items.step_complete = "0";
      setNoteAttObj({
        content: "",
        title: "",
      });
      props?.HandleWfCheck(items);
    }
  };

  const [isTagOpen, setIsTagOpen] = useState(false);
  const [currentTagEqp, setCurrentTagEqp] = useState({});
  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.equipment,
    uuid: "",
  });
  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };
  const addEquTag = (data, e) => {
    setTagInpObj({
      name: "",
      color: "#CCCCCC",
      referenceType: ReferenceId.equipment,
      uuid: "",
});
    e.stopPropagation();
    setCurrentTagEqp(data);
    setIsTagOpen(true);
  };

  const closeAddTags = () => {
    setCurrentTagEqp({});
    setIsTagOpen(false);
  };
  const HandleTagSubmit = async () => {
    // let Work_order_id = window.location.href.split('/')
    let equip_id = currentTagEqp?.equipment_id
    let newTag = {
      referenceType: tagInpObj?.referenceType,
      tag_id: 0,
      referenceId: equip_id,
      name: tagInpObj?.name,
      color: tagInpObj?.color,
      uuid_id: "",
      work_order_id:+props?.WorkNo,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newTag);
    if (Res?.success) {
      let newOne = {
        referenceType: Res?.data?.tag?.reference_type,
        color: Res?.data?.tag?.color,
        id: Res?.data?.tag?.tag_id,
        name: Res?.data?.tag?.tag_data,
      }
      HandleTagUpdat(newOne)
      setIsTagOpen(false);
      setTagInpObj({
        name: "",
        color: "#CCCCCC",
        referenceType: ReferenceId.work_order,
        uuid: "",
  });
    }
  }
  const  HandleTagUpdat = (newOne)=> {
    let newArr = props?.data?.Equipments?.filter((eqpItem) => 
      eqpItem?.equipment_id === currentTagEqp?.equipment_id
    );
    newArr[0]?.tags?.push(newOne)
  }
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(null);
  const handleDeleteModal = ( data,e,key) => {
    e.stopPropagation();
    setCurrentTagEqp(key)
    setDeleteTag(data);
    setShowDeleteTag(true);

  };
  const handleDeleteTag = async (tagToDelete) => {
    const response = await deleteTags(tagToDelete?.id);
   
    if(response.success)
    {
      HandleTagDel()
      setShowDeleteTag(false)
      // setDeleteTag(null)
      
    }
   
  };
  const  HandleTagDel = ()=> {
    let newArr = props?.data?.Equipments?.filter((eqpItem) => 
      eqpItem?.equipment_id === currentTagEqp?.equipment_id
    );
    let newTagData =newArr[0]?.tags?.filter((i)=>i?.id !==deleteTag?.id)
    newArr[0].tags = newTagData
  }
  const [infoModal, setInfoModal] = useState(false);
  const [eqpObj, setEqpObj] = useState({});
  const HandleInfoModal = (cardEquipments, e) => {
    e.stopPropagation();
     setEqpObj(cardEquipments);
    setInfoModal(true);
  };
  const navigation = useNavigate()
  const navToPage = (to, params) => {
    let WoNo = props?.isWoid
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("WorkId", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: params,
      navigate: navigation,
    });
  };
  const [viewformmodal,setViewFormModal]=useState(false)
  const [viewformitem,setViewFormItem]=useState([])
  const ViewModal = (item)=>
  {
    setViewFormItem(item)
    setViewFormModal(true)
  }
  const HandleOpenPdf = async (item) => {
    let Woid = +window?.location.href.split("/")[5];
    const url = `${window.location.origin}/#/PDFView/${Woid}/${item?.workflow_step_id}/${item?.work_order_workflow_id}/${item?.workflow_id}`;
    window.open(url, "_blank");
      setViewFormModal(false)
  };
  return (
    <>
    <div>
    <EquipmentCard
      cardEquipments={props?.data?.Equipments}
      navToPage={navToPage}
      HandleInfoModal={HandleInfoModal}
      // getEquipmentStatus={getEquipmentStatus}
      addEquTag={addEquTag}
      handleDeleteModal={handleDeleteModal}
      // HandleViewImg={HandleViewImg}
      LocStrId={localStorage.getItem("userId")}
    />
  </div>
    <div>
      {items?.map((item) => (
        <div
          className={
            appState?.currentWorkOrder?.status !== "Completed"
              ? "steps-hov bg-white mt-2 "
              : "steps-hov bg-white mt-2 steps-hov-events"
          }
        >
          <div className="line-align-bet w-100 ">
            <div
              className="d-flex gap-2 "
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.type === "Attach Content" ? (
                <div>
                  <img
                    src={pin}
                    alt="steps"
                    // onClick={() => HadlePicker(item)}
                    width={15}
                  />
                </div>
              ) : item.type === "Add Input Field" ? (
                <div>
                  {" "}
                  <img src={AddInput} alt="AddInput" />
                </div>
              ) : item.type === "Complete Form" ? (
                <div>
                  <img src={FormMaster} className="" alt="FormMaster" />
                </div>
              ) : item.type === "Yes/No Selection" ? (
                <div>
                  {/* <input type="checkbox" className="largerCheckbox" /> */}
                  <img src={YesNo} className="" alt="YesNo" />
                </div>
              ) : item.type === "Create Drop Down" ? (
                <div>
                  <img src={CrtDropDown} className="" alt="CrtDropDown" />
                </div>
              ) : item.type === "Fill out Table" ? (
                <div>
                  {" "}
                  <img
                    src={FillTable}
                    className=""
                    alt="FillTable"
                    width={20}
                  />
                </div>
              ) : item.type === "Create Equipment" ? (
                <div>
                  {" "}
                  <img src={CrtEqup} className="" alt="CrtEqup" />
                </div>
              ) : (
                ""
              )}

              <div>
                <div className="d-flex steps-m-txt">
                  {item.name}
                  {item?.step_required && (
                    <span
                      className="mx-2"
                      style={{ fontSize: "20px", color: "red" }}
                    >
                      *
                    </span>
                  )}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    color: "gray",
                    position: "relative",
                    bottom: "5px",
                  }}
                >
                  {item?.type}
                </div>
              </div>
            </div>
            <div
              className="w-50"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div
                className="d-flex"
                style={{
                  flexDirection: "row",
                  justifyContent: "centre",
                }}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="inp-id"
                  onChange={(e) => HandleFilePicker(e, item)}
                />
                {/* {item?.image_required && item?.attachment_list.length <= 0 ? ( */}
                <img
                  src={
                    item?.image_required && item?.attachment_list.length <= 0
                      ? StepMediaRed
                      : item?.attachment_list?.length > 0
                      ? StepMedia2
                      : StepMedia
                  }
                  alt="StepMedia2"
                  className="mx-1 "
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => HadlePicker(item)}
                />

                <img
                  src={
                    item?.note_required && item?.notes?.length <= 0
                      ? RedNote
                      : item?.notes?.length > 0
                      ? Pen
                      : NotePlus
                  }
                  className="mx-1"
                  alt="NotePlus"
                  style={{
                    height: "15px",
                    width: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => HandleNoteModal(item)}
                />
                <input
                  style={{
                    pointerEvents:
                      !StepRulesCheck(item, props?.data?.workflow_complete) ===
                        false && "none",
                    height: "20px",
                    width: "15px",
                    cursor: "pointer",
                  }}
                  type="checkbox"
                  className="largerCheckbox mx-2 "
                  onClick={() => handleCheckEvents(item)}
                  checked={StepRulesCheck(item, props?.data?.workflow_complete)}
                />
              </div>
            </div>
          </div>

          <div className="mt-2 mx-2">
            {props?.isInstruct !== "instructionOn" && (
              <>
                <div className="step-instruction-2">Instructions:</div>

                <div className="step-instruction">
                  {item?.step_instructions}
                </div>
              </>
            )}
            {isUpload &&
            attachItem?.work_order_workflow_step_id ===
              item?.work_order_workflow_step_id ? (
              <div className="progress-position">
                <ProgressBar />
              </div>
            ) : (
              <div>
                {item?.attachment_list?.length > 0 && (
                  <div className="p-2" style={{ cursor: "pointer" }}>
                    <StepsAttachments data={item?.attachment_list} />
                  </div>
                )}
              </div>
            )}
            <div>
              {item.type === "Create Drop Down" && (
                <div className="mt-1">
                  <span className="step-instruction">Content:</span>
                  {item?.step_choice?.map((i) => (
                    <div className="d-flex mx-1 gap-2">
                      <input
                        className="cursor-p"
                        type="radio"
                        value={i.response}
                        checked={
                          i?.response_workflow_id === item?.choice_result
                        }
                        onClick={() => HandleRadio(i, item)}
                      />

                      <span className="step-instruction">{i?.response}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              {item.type === "Attach Content" && (
                <div className="mt-1">
                  <span className="step-instruction">Content:</span>
                  <div>
                    <div
                      className="ViewFormBtn"
                    >
                      <span>Attach Content</span>
                      <img src={plus} alt="AddInput" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {item.type === "Create Equipment" && (
                <div className="mt-1">
                  <span className="step-instruction">Content:</span>
                  <div>
                    <div
                      className="ViewFormBtn"
                    >
                      <span>Create Equipment</span>
                      <img src={CrteEqup} alt="AddInput" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {item.type === "Fill out Table" && (
                <div className="mt-1">
                  <span className="step-instruction">Content:</span>
                  <div className=" d-flex ">
                    <div
                      className="ViewFormBtn"
                    >
                      <span>View table</span>
                      <AiFillEye />
                    </div>
                    <div className="mx-1">
                    <div
                      className="ViewFormBtn"
                    >
                      <span>Fill out table</span>
                      <img src={fill_out_table} alt="AddInput" />
                    </div>
                  </div>
                  </div>
                 
                </div>
              )}
            </div>
            <div>
              {item.type === "Yes/No Selection" && (
                <div className="mt-1">
                  <span className="step-instruction">Content:</span>
                  <div>
                    {item?.step_choice?.map((i) => (
                      <div key={i?.id}>
                        <>
                          <input
                            type="radio"
                            className="cursor-p"
                            value={i.response}
                            checked={
                              i?.response_workflow_id === item?.choice_result
                            }
                            onChange={() => HandleRadio2(i, item)}
                          />
                          <span className="step-instruction">
                            {i?.response}
                          </span>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div>
              {item.type === "Complete Form" && (
                <div className="mt-1">
                  <span className="step-instruction">Content:</span>
                  <div>
                    <div
                      className="ViewFormBtn"
                      onClick={()=>ViewModal(item)}
                    >
                      <span>View Form</span>
                      <AiFillEye />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {item.type === "Add Input Field" ? (
            <div>
              <span className="Note-test">Content:</span>
              <div className="numeric-inp">
                <input
                  type="text"
                  placeholder="Input Amount"
                  // value={inpval2 === "" ? item?.content_note : inpval2}
                  value={item?.content_note}
                  onChange={(e) =>
                    HandleInputChange(
                      e,
                      item,
                      item?.work_order_workflow_step_id
                    )
                  }
                  onBlur={() => HandleStepInputUpdate(item)}
                />

                <button>
                  <span>{item?.numeric_unit}</span>
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {item?.notes?.length > 0 && (
            <div className="">
              <div className=" p-1">
                <div className="line-align-bet w-100">
                  <span className="Note-test d-flex gap-3">
                    <span className="mx-1"> Notes</span>
                  </span>
                  <div>
                    <div className="wfsteps-txt-date">
                      {moment(item?.notes[0].created_date).format(
                        `${TABLE_ÍCON_SIZES.NOTES_DATE_FORMAT}`
                      )}
                      <div
                        className="mx-2 cursor-p"
                        onClick={() =>
                          HandleNoteDelete(item?.notes[0]?.note_id, item)
                        }
                      >
                        <img src={delBlack} alt="delBlack" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="Note-step-content mx-2">
                    {item?.notes[0]?.notes}
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      ))}
      <Modal show={isnoteAtt} centered onHide={() => setIsNoteAtt(false)}>
        <Modal.Header className=" modal-bg-color modal-header-height ">
          <span className="note-add-text" style={{ textAlign: "center" }}>
            AttachMedia Note - Work Order#{props.WorkNo}
          </span>

          <img
            src={Close}
            alt="close"
            className="modal-header-icon "
            onClick={() => setIsNoteAtt(false)}
          />
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "450px", overflow: "auto" }}>
          {isAttNote ? (
            <div className="progress-position">
              <ProgressBar />
            </div>
          ) : (
            <div>
              <div className="mt-2 note-attach-modal-body ">
                <input
                  className="w-100 mt-1"
                  placeholder="Note Title"
                  value={noteattObj.title}
                  name="title"
                  onChange={(e) => HandleInputNotes(e)}
                />
                <textarea
                  className="w-100 mt-2"
                  placeholder="Note Content"
                  value={noteattObj.content}
                  name="content"
                  onChange={(e) => HandleInputNotes(e)}
                />
              </div>
              <div className="mt-2">
                {baseImg.length > 0 &&
                  baseImg.map((it) =>
                    it.startsWith("data:video") ? (
                      <video
                        src={it}
                        alt="baseImg"
                        controls
                        style={{ width: "200px", height: "200px" }}
                      />
                    ) : (
                      <embed
                        src={it}
                        alt="baseImg"
                        style={{ width: "200px" }}
                      />
                    )
                  )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer-styles">
          <div className="photos-file ">
            <input
              type="file"
              id="inp-pick"
              style={{ display: "none" }}
              onChange={(e) => FilePick(e)}
            />
            <div
              className="modal-footer-save-cancel-btns mx-2"
              onClick={() => HandlePicker()}
            >
              <span>Insert File</span>
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              onClick={() => HandlePicker()}
            >
              <span>Take Photo</span>
            </div>
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => HandleNoteAttchAdd()}
            style={{ margin: 0 }}
          >
            Submit
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => setIsNoteAtt(false)}
          >
            Cancel
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={viewformmodal}
        onHide={()=>setViewFormModal(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          Pdf View
        </Modal.Header>
        <Modal.Body className="p-2 " >
          <div>
            Are you sure you want to navigate away from this page ?
          </div>
        <div
            className="d-flex model_footer mt-2"
             style={{ flexDirection: "row", justifyContent: "center" }}
            >
            <button
               onClick={() => HandleOpenPdf(viewformitem)}
              className="modal-footer-save-cancel-btns"
            >
              Open in new tab
            </button>

            <button
              onClick={() => setViewFormModal(false)
              }
              className="modal-footer-save-cancel-btns"
            >
              Cancel
            </button>
          </div>
          </Modal.Body>
      </Modal>
      <NoteAddModal
        isOpen={isattach}
        isClose={() => {
          setIsAttach(false);
          setIsNoteErr("");
        }}
        WorkId={props?.WorkNo}
        noteTitle={noteInpObj.title}
        noteDescription={noteInpObj.note}
        HandleInputs={ChangeEvents}
        isSubmit={() => HandleSubmit(NoteItem)}
        isLoading={isNote}
        isKey={"steps"}
        Notehedr={Notehedr}
        ErrorNote={IsNoteErr}
      />

      <Modal
        show={isCheckModal}
        onHide={() => setIsCheckModal(false)}
        size="sm"
        centered
      >
        <Modal.Header className="line-align-even  w-100 modal-bg-color modal-header-height">
          <span className="note-add-text" style={{ textAlign: "center" }}>
            Error - Work Order#{props?.WorkNo}
          </span>

          <img
            src={Close}
            alt="close"
            className="modal-header-icon "
            onClick={() => setIsCheckModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="err-check">
            {ErrorMsg?.map((it) => (
              <div>**{it}</div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
    <AddTag
        Open={isTagOpen}
        name={tagInpObj.name}
        color={tagInpObj.color}
        HandleTagInputs={changeTagEvent}
        workOrderId={"Equipment #"+currentTagEqp?.equipment_id}
        isClose={() => closeAddTags()}
        isSubmit={() => HandleTagSubmit()}
      />
       <DeleteTags
        headid={currentTagEqp?.equipment_id}
        deleteTag={() => handleDeleteTag(deleteTag)}
        handleClose={() => setShowDeleteTag(false)}
        data={deleteTag}
        name={"Equipment"}
        isOpen={showDeleteTag}
      />
         <EquipmentInfoModal
        open={infoModal}
        close={() => setInfoModal(false)}
        equipment={eqpObj}
        navToPage={navToPage}
      />

    </>
  );
}
