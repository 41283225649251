import React, { useState, useEffect, useRef } from "react";
import "./WorkOrderSetup.css";
import { Col, Container, Modal, Row } from "react-bootstrap";
import WfCategory from "../../../../common/Assets/Icons/Vectorwf.svg";
import WoAddField from "../../../../common/Assets/Icons/Left Iconwoadd.svg";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import EqpManf from "../../../../common/Assets/Icons/Building.svg";
import plus from "../../../../common/Assets/Icons/plusW.svg";
import ColorImage from "../../../../common/Assets/Images/Colorwheel Image.svg";
import CallType from "../Work Flow setup/CallType";
import { FaCheck } from "react-icons/fa6";

import {
  EQPMAN,
  FIELD_SETUP,
  SETTINGS_PAGES,
  TAGS_MODAL_VALUES,
  WO_TYPE_MODAL,
  WORK_ORDER_SETUP,
} from "../../../../common/components/Constants";
import Close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import del from "../../../../common/Assets/Icons/del.svg";
import save from "../../../../common/Assets/Icons/save.svg";
import delw from "../../../../common/Assets/Icons/delW.svg";
import adj from "../../../../common/Assets/Icons/AdjNew.svg";
import WOAdditionalField from "./WOAdditionalField";
import {
  woStsSettFldStPst,
  woStsSettFldStpGt,
  woDelStsSettFldStp,
  woPtFldsTypeSett,
  woGtFldsTypeSett,
  fetchAddFieldsUS,
  AddFiledsUS,
  GetEqpMan,
  EqpManAdd,
  DelEqpMan,
  EqpTypesFetch,
  EqpTypesAdd,
  DelEqupTypes,
  woTypedelete,
  CallTypesWorkflowsFetch,
} from "../../../../services/SettingsServices";
import {
  FIELD_SETUP_STATUS,
  WORKFLOW_VALUES,
} from "../../../../common/components/Values";
import AlertFldStp from "./AlertFldStp";
import ProgressBar from "../../../../common/navigation/ProgressBar";
import EqpManufactModal from "./EqpManufactModal";
import { SketchPicker } from "react-color";
import EquipmentTypes from "../Work Flow setup/EquipmentTypes";

function WorkOrderSetup(props) {
  const [isWorkflw, setIsWorkflw] = useState(false);

  const HandleIsWorkflw = () => {
    setIsWorkflw(!isWorkflw);
  };

  const [woStatus, setWoStatus] = useState([]);
  const [eqpmanList, setEqpManList] = useState([]);
  const [deleteMode, setDeleteMode] = useState("");
  const [deleteContent, setDeleteContent] = useState({});
  const [FldStpSts, setFldStpSts] = useState(false);

  const [EqpMan, setEqpman] = useState(false);
  const [eqpAddFld, setEqpAddFld] = useState(false);
  const [ErpWoFields, setErpWoFields] = useState([]);
  const [ErpCustFields, setErpCustFields] = useState([]);
  const [ErpEqpFields, setErpEqpFields] = useState([]);

  const HandleFldStpSts = () => {
    setFldStpSts(!FldStpSts);
  };

  const HandleEqpMan = () => {
    setEqpman(!EqpMan);
  };

  const HandleEqpAddFld = () => {
    setEqpAddFld(!eqpAddFld);
  };

  const [custFld, setCustFld] = useState(false);
  const HandleCustFld = () => {
    setCustFld(!custFld);
  };
  const [newWOStatus, setNewWOStatus] = useState({ name: "" });
  const [errorData, setErrorData] = useState({});

  const pushErrors = (data) => {
    let newObj = {};
    data.forEach((i) => {
      let tempList = [];
      if (i.packcolumn?.length === 0) {
        tempList.push("packcolumn");
      }
      if (i.erpcolumn?.length === 0) {
        tempList.push("erpcolumn");
      }
      if (i.characterlength?.length === 0) {
        tempList.push("characterlength");
      }
      if (i.control_type?.length === 0) {
        tempList.push("control_type");
      }
      if (tempList.length > 0) {
        newObj = { ...newObj, [i.field_id]: tempList };
      }
    });
    return newObj;
  };

  const [isFinalSave, setIsFinalSave] = useState(false);
  const [errorKey, setErrorKey] = useState("");
  const prepareErrorData = (globalSave = false) => {
    const newObj = {
      work_order_extra_fields: pushErrors(woextrafields),
      contact_extra_fields: pushErrors(contAddfldWO),
      customer_extra_fields: pushErrors(custextrafields),
      equipment_extra_fields: pushErrors(eqpextrafields),
      // wo_extra_interface: pushErrors(woFieldsData),
    };
    let errCount = 0;

    if (globalSave) {
      setErrorData(newObj);
    }

    Object.keys(newObj).forEach((i) => {
      if (Object.keys(newObj[i]).length > 0) {
        errCount += 1;
      }
    });
    return { errCount, errorObj: newObj };
  };
  const pushDups = (data) => {
    const foundDuplicateName = data
      .slice()
      .reverse()
      .find((nnn, index, reversedData) => {
        return reversedData.find(
          (x, ind) => x.packcolumn === nnn.packcolumn && index !== ind
        );
      });
    let OneObj = [];
    if (foundDuplicateName !== undefined) {
      OneObj?.push(foundDuplicateName);
    }

    return OneObj;
  };
  const HandleDuplicate = () => {
    const newDupObj = {
      work_order_extra_fields: pushDups(woAdditionalFields),
      contact_extra_fields: pushDups(contAddfldWO),
      customer_extra_fields: pushDups(custAddfldWO),
      equipment_extra_fields: pushDups(equipAddFildWo),
      // wo_extra_interface: pushDups(woFieldsData),
    };
    // let newDupObj2 = [
    //   ...newDupObj?.contact_extra_fields,
    //   ...newDupObj?.customer_extra_fields,
    //   ...newDupObj?.equipment_extra_fields,
    //   newDupObj?.wo_extra_interface,
    //   ...newDupObj?.work_order_extra_fields,
    // ];
    return newDupObj;
  };
  const [isDup, setIsDup] = useState({});
  const [isDupErr, setIsDupErr] = useState(false);

  const HandleAddadditionalFlds = async () => {
    let { errCount } = prepareErrorData(true);
    let newArr = HandleDuplicate();
    setIsDup(newArr);
    let newDup = [];
    for (const key in newArr) {
      if (Array.isArray(newArr[key])) {
        newDup?.push(newArr[key].length);
      }
    }
    if (errCount !== 0) {
      setIsAllFieldsFilled(true);
      setIsFinalSave(true);
    }
    if (newDup.includes(1)) {
      setIsAllFieldsFilled(true);
      setIsDupErr(true);
    } else {
      if (errCount === 0) {
        const payload = {
          work_order_extra_fields: woextrafields,
          contact_extra_fields: contAddfldWO,
          customer_extra_fields: custextrafields,
          equipment_extra_fields: eqpextrafields,
          wo_extra_interface: woFieldsData,
        };
        const response = await AddFiledsUS(payload);
        if (response) {
          setsaved(true);
          setTimeout(() => {
            setsaved(false);
          }, 1000);
          getAllFieldsData();
        }
      }
    }
  };

  const [saved, setsaved] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [woextrafields, setWoExtrafields] = useState([
    {
      name: "",
      // control_type: "",
      // required: true,
      // order: orderNumber + 1,
      // field_id: orderNumber + 1,
      // visible: false,
      // showonGrid: false,
      field_id: 1,
      control_type: "",
      erpcolumn: "",
      packcolumn: "",
      characterlength: 0,
      required: false,
      active: false,
      order: 1,
    },
  ]);
  const [eqpextrafields, setEqpExtrafields] = useState([]);
  const [custdupextrafields, setCustDupExtrafields] = useState([]);
  const [eqpdupextrafields, setEqpDupExtrafields] = useState([]);
  const [custextrafields, setCustExtrafields] = useState([]);
  const [woAddFldsDup , setWoaddFldsDup]= useState([])
  const getAllFieldsData = async () => {
    setIsProcess(true);
    const response = await fetchAddFieldsUS();
    if (response) {
      setIsProcess(false);
      const actualData = response;
      setEqpExtrafields(actualData?.equipment_extra_fields)
      setEqpDupExtrafields(actualData?.equipment_extra_fields)
      setCustDupExtrafields(actualData?.customer_extra_fields)
      setCustExtrafields(actualData?.customer_extra_fields)
      setWoExtrafields(actualData?.work_order_extra_fields)
      setWoaddFldsDup(actualData?.work_order_extra_fields)
      setErpWoFields(actualData?.ErpWoadditionalfields);
      setErpCustFields(actualData?.ErpCustomeradditionalfields);
      setErpEqpFields(actualData?.ErpEquipmentadditionalfields);
      if (
        actualData?.wo_extra_interface &&
        actualData?.wo_extra_interface.length > 0
      ) {
        setWOFieldsData(actualData?.wo_extra_interface);
      }
      if (
        actualData?.equipment_extra_fields &&
        actualData?.equipment_extra_fields.length > 0
      ) {
        setEquipAddFildWo(actualData?.equipment_extra_fields);
      }

      if (
        actualData?.work_order_extra_fields &&
        actualData?.work_order_extra_fields.length > 0
      ) {
        setWOAdditionalFields(actualData?.work_order_extra_fields);
      }

      if (actualData?.contact_extra_fields) {
        setContAddfldWO(actualData?.contact_extra_fields);
      }
      if (
        actualData?.customer_extra_fields &&
        actualData?.customer_extra_fields.length > 0
      ) {
        setCustAddfldWO(actualData?.customer_extra_fields);
      }
    }
  };

  // Add Fields WO
  const [woFieldsData, setWOFieldsData] = useState([
    {
      // name: "",
      // control_type: "",
      // required: false,
      // order: 1,
      // field_id: 1,
      // visible: false,
      // showonGrid: false,

      field_id: 1,
      control_type: "",
      erpcolumn: "",
      packcolumn: "",
      characterlength: 0,
      required: false,
      active: false,
      order: 1,
    },
  ]);
  // Add Fields WO Additional

  const [woAdditionalFields, setWOAdditionalFields] = useState([
    {
      field_id: 1,
      control_type: "",
      erpcolumn: "",
      packcolumn: "",
      characterlength: 0,
      required: false,
      active: false,
      order: 1,
    },
  ]);


  const onWOFieldChangeHandler = (key, value, id) => {
    const newList = [...woextrafields]?.map((i) => {
      let temp = i;
      // order
      if (i.field_id === id) {
        temp[key] = value;
      }
      return temp;
    });
    setWoExtrafields(newList);
  };

  const addWOAdditionalField = () => {
    setIsFinalSave(false);
    setErrorData({});
    setErrorKey("");
    const orderNumber = woextrafields?.length;
    if (orderNumber < 30) {
      const { errorObj } = prepareErrorData();
      let temp = errorObj.work_order_extra_fields;
      if (Object.keys(temp)?.length > 0) {
        setIsAllFieldsFilled(true);
        setErrorData({ work_order_extra_fields: temp });
        setErrorKey("work_order_extra_fields");
      } else {
        setWoExtrafields((prev) => {
          return [
            ...prev,
            {
              name: "",
              erpname: "",
              // control_type: "",
              // required: true,
              // order: orderNumber + 1,
              // field_id: orderNumber + 1,
              // visible: false,
              // showonGrid: false,
              field_id: orderNumber + 1,
              control_type: "",
              erpcolumn: "",
              packcolumn: "",
              characterlength: 0,
              required: false,
              active: false,
              order: orderNumber + 1,
            },
          ];
        });
      }
    } else {
      setAdditionalFieldsAdd(true);
    }
  };
  const [wflwlist, setWflwList] = useState([])
  const workflowslist = async () => {
    const output = await CallTypesWorkflowsFetch()
    setWflwList(output?.data?.workflow)
  }
  useEffect(() => {
    workflowslist()
  }, [])
  const [woStatusMode, setWOStatusMode] = useState("");
  const [woTypeMode, setWoTypeMode] = useState("");
  const [woType, setWoType] = useState([]);
  const [newWoType, setNewWoType] = useState({
    work_order_type_code: "",
    work_order_type_description: "",
    work_order_type_id: "",
    workflow_list: "",
    workflow_estimate_list: "",
    active: ""
  });
  const [clickedIndex, setIsclickedindex] = useState(null)
  const [hoveredwotypeIndex, sethoveredWotypeIndex] = useState()
  const [isProgress, setIsProgress] = useState(false)
  //post add
  const AddWoType = async () => {
    if (newWoType?.work_order_type_code?.trim()?.length && newWoType?.work_order_type_description?.trim()?.length > 0) {
      let WoObj =
      {
        work_order_type_code: newWoType?.work_order_type_code,
        work_order_type_description: newWoType?.work_order_type_description,
        work_order_type_id: newWoType?.work_order_type_id ? newWoType?.work_order_type_id : 0,
        workflow_list: newWoType?.workflow_list,
        workflow_estimate_list: newWoType?.workflow_estimate_list,
        active: newWoType?.active,
      }
      setIsProgress(true);
      let output = await woPtFldsTypeSett(WoObj)
      if (output?.success === true) {
        setIsProgress(false);
        const newObj = {
          work_order_type_id: output?.data?.work_order_type_id,
          work_order_type_code: output?.data?.work_order_type_code,
          work_order_type_description: output?.data?.work_order_type_description,
          sponsor_id: output?.data?.sponsor_id,
          modified_by: output?.data?.modified_by,
          modified_date: output?.data?.modified_date,
          created_by: output?.data?.created_by,
          created_date: output?.data?.created_date
        };
        if (newObj?.work_order_type_id > 0) {
          setWoType([
            ...woType?.filter((i) => i?.work_order_type_id !== output?.data?.work_order_type_id),
            newObj
          ]);
        } else {
          setWoType([...woType, newObj]);
        }
        setIsWfModal(false);
        // setWoType([...woType, output?.data]);
        // setWoType(...woType,output)
      }
    } else {
      setWoTypeValidate(true);
      setIsProgress(false);
    }
  }


  const addNewWOStatus = async () => { 
    if (newWOStatus?.name?.trim()?.length > 0) {
      let payload = {};
      let FilteredData = [];
      if (newWOStatus?.name?.length > 0) {
        if (woStatusMode === "add") {
          payload = { name: newWOStatus.name, status_id: 0 };
          FilteredData = woStatus?.filter(
            (it) => it?.status?.trim() === newWOStatus?.name?.trim()
          );
        } else {
          payload = {
            name: newWOStatus?.name,
            status_id: newWOStatus?.status_id,
          };
          FilteredData = woStatus
            ?.filter((it) => it?.status_id !== newWOStatus?.status_id)
            ?.filter((i) => i?.status?.trim() === newWOStatus?.name?.trim());
        }
        if (FilteredData?.length > 0) {
          alert("Work Order Type already exist ");
         
        } else {
          setIsProgress(true);
          const response = await woStsSettFldStPst(payload);

          if (response.success) {
            getWoStatus();
            setNewWOStatus({ name: "" });
            setIsProgress(false);
            setEditWoStatusMod(false);
          }
          setWoStatusValidate(false);
        }
      }
    } else {
      setWoStatusValidate(true);
      setIsProgress(false);
    }
  };

  // Add Fields contact
  const [contAddfldWO, setContAddfldWO] = useState([
    {
      // name: "",
      // control_type: "",
      // required: true,
      // order: 1,
      // field_id: 1,
      field_id: 1,
      control_type: "",
      erpcolumn: "",
      packcolumn: "",
      characterlength: 0,
      required: false,
      active: false,
      order: 1,
    },
  ]);

  // Add Fields - cust
  const [custAddfldWO, setCustAddfldWO] = useState([
    {
      // field_id: 1,
      // control_type: "",
      // erpcolumn: "",
      // packcolumn: "",
      // characterlength: 0,
      // required: false,
      // active: false,
      // order: 1,
      field_id: 1,
      control_type: "",
      erpcolumn: "",
      packcolumn: "",
      characterlength: 0,
      required: false,
      active: false,
      order: 1,
    },
  ]);
  const wocustAddFldHandler = (key, value, field_id) => {
    const newList = [...custextrafields]?.map((i) => {
      let temp = i;
      if (i.field_id === field_id) {
        temp[key] = value;
      }
      return temp;
    });
    setCustExtrafields(newList);
  };
  const custAddFldWo = () => {
    setErrorData({});
    setErrorKey("");
    setIsFinalSave(false);
    const orderNumber = custextrafields.length;
    if (orderNumber < 30) {
      const { errorObj } = prepareErrorData();
      let temp = errorObj.customer_extra_fields;
      if (Object.keys(temp)?.length > 0) {
        setIsAllFieldsFilled(true);
        setErrorData({ customer_extra_fields: temp });
        setErrorKey("customer_extra_fields");
      } else {
        setCustExtrafields((prev) => {
          return [
            ...prev,
            {
              name: "",
              erpname: "",
              // control_type: "",
              // required: true,
              // order: orderNumber + 1,
              // field_id: orderNumber + 1,
              field_id: orderNumber + 1,
              control_type: "",
              erpcolumn: "",
              packcolumn: "",
              characterlength: 0,
              required: false,
              active: false,
              order: orderNumber + 1,
            },
          ];
        });
      }
    } else {
      setAdditionalFieldsAdd(true);
    }
  };
  // Add Fields - equipment
  const [equipAddFildWo, setEquipAddFildWo] = useState([
    {
      // name: "",
      // control_type: "",
      // required: true,
      // order: 1,
      // field_id: 1,
      field_id: 1,
      control_type: "",
      erpcolumn: "",
      packcolumn: "",
      characterlength: 0,
      required: false,
      active: false,
      order: 1,
    },
  ]);

  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const HandleEquipAddFildWo = (key, value, field_id) => {
    const newList = [...eqpextrafields]?.map((i) => {
      let temp = i;
      if (i.field_id === field_id) {
        temp[key] = value;
      }
      return temp;
    });
    setEqpExtrafields(newList);
  };
  const HandleDelWoAddFlds = (it,key) => {
    if(it === "discard")
    {
      setWoExtrafields(woAddFldsDup);
      setCustExtrafields(custdupextrafields)
      setEqpExtrafields(eqpdupextrafields)
    }
    else if(key === "woflds"){
      setWoExtrafields(woextrafields.filter((i) => i?.field_id !== it?.field_id));
    }
    else if(key === "eqpflds"){
      setEqpExtrafields(eqpextrafields.filter((i) => i?.field_id !== it?.field_id))
    }
    else if(key === "custflds"){
      setCustExtrafields(custextrafields.filter((i) => i?.field_id !== it?.field_id))
    }
  }
  const eqpAddFild = () => {
    setErrorData({});
    setErrorKey("");
    setIsFinalSave(false);
    const orderNumber = eqpextrafields.length;
    if (orderNumber < 30) {
      const { errorObj } = prepareErrorData();
      let temp = errorObj.equipment_extra_fields;
      if (Object.keys(temp)?.length > 0) {
        setIsAllFieldsFilled(true);
        setErrorData({ equipment_extra_fields: temp });
        setErrorKey("equipment_extra_fields");
      } else {
        setEqpExtrafields((prev) => {
          return [
            ...prev,
            {
              name: "",
              erpname: "",
              // control_type: "",
              // required: true,
              // order: orderNumber + 1,
              // field_id: orderNumber + 1,
              field_id: orderNumber + 1,
              control_type: "",
              erpcolumn: "",
              packcolumn: "",
              characterlength: 0,
              required: false,
              active: false,
              order: orderNumber + 1,
            },
          ];
        });
      }
    } else {
      setAdditionalFieldsAdd(true);
    }
  };

  const [woTypeValidate, setWoTypeValidate] = useState(false);
  const [woStatusValidate, setWoStatusValidate] = useState(false);
  const [EqupTpes, setEqpTypes] = useState([]);
  const onDiscard = () => {
    setErrorData({});
  };
  const contentRefs = useRef([]);
  useEffect(() => {
    const index = props.isField !== undefined ? props.isField : 0;
    scrollToDiv(index);
  }, [props.isField]);

  const scrollToDiv = (index) => {
    if (index < 0 || index >= contentRefs.current.length) {
      return;
    }
    const contentDiv = contentRefs.current[index];

    if (contentDiv) {
      contentDiv.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
    }
  };
  useEffect(() => {
    getWoStatus();
    getWoType();
    getAllFieldsData();
    EqpManuFactGet();
    GetEqpTypes();
  }, []);
  // equipment Types
  const GetEqpTypes = async () => {
    let Res = await EqpTypesFetch();
    setEqpTypes(Res?.data);
  };
  // for status
  const getWoStatus = async () => {
    const response = await woStsSettFldStpGt();
    if (response && response.data && response.data.length > 0) {
      setWoStatus(response.data);
    }
  };
  const HandleDelStatus = async (id) => {
    const response = await woDelStsSettFldStp(id);
    if (response.success) {
      setDeleteTpSh(false);
      getWoStatus();
      alert("Deleted Successfully");
    }
  };
  const EqpManuFactGet = async () => {
    let Res = await GetEqpMan();
    setEqpManList(Res?.data);
  };
  const getWoType = async () => {
    const result = await woGtFldsTypeSett();
    setWoType(result?.data?.workOrderType);

  };
  const HandlewoTypeDel = async (id) => {
    const response = await woTypedelete(id);
    if (response?.success) {
      let FilteredData = woType?.filter((dlitem) => dlitem?.work_order_type_id !== id);
      setWoType(FilteredData)
      setDeleteTpSh(false);
      getWoType();
      // alert("Deleted Successfully");
    }
  };
  const [workOrderAddFild, setWorkOrderAddFild] = useState(false);
  const HandleWorkOrderAddFild = () => {
    setWorkOrderAddFild(!workOrderAddFild);
  };
  const [isWfModal, setIsWfModal] = useState(false);
  const CreateWoType = () => {
    setIsclickedindex(null)
    setIsWfModal(true);
    setWoTypeValidate(false);
    setWoTypeMode("add");
    setNewWoType({ name: "" });
  };

  const HandleEditType = (data, index) => {
    setIsclickedindex(data?.work_order_type_id)
    setWoTypeValidate(false);
    setWoTypeMode("edit");
    setNewWoType({ work_order_type_code: data?.work_order_type_code, work_order_type_description: data?.work_order_type_description, work_order_type_id: data?.work_order_type_id });
    setIsWfModal(true);
  };
  const [editWoStatusMod, setEditWoStatusMod] = useState(false);
  const HandleEditWOstusMod = () => {
    setWOStatusMode("add");
    setNewWOStatus({ name: "" });
    setEditWoStatusMod(true);
  };
  const onWOStatusEditClick = (data) => {
    setWoStatusValidate(false);
    setWOStatusMode("edit");
    setNewWOStatus({ name: data?.status, status_id: data?.status_id });
    setEditWoStatusMod(true);
  };
  //  delete
  const [deleteTpSh, setDeleteTpSh] = useState(false);
  const HandleDeleteTpSh = (mode, data, event) => {
    event.stopPropagation();
    setDeleteTpSh(true);
    setDeleteMode(mode);
    setDeleteContent(data);
  };
  const handledeleteTpClose = () => {
    setDeleteTpSh(false);
    setDeleteMode("");
    setDeleteContent({});
  };
  const [additionalFieldsAdd, setAdditionalFieldsAdd] = useState(false);

  const [crtAddFld, setCrtAddFld] = useState(false);

  const handleDeleteSubmit = () => {
    if (deleteMode === "Type") {
      HandlewoTypeDel(deleteContent?.work_order_type_id);
    }
    if (deleteMode === "Status") {
      HandleDelStatus(deleteContent?.status_id);
    }
  };
  const [isEqpman, setIsEqpMan] = useState(false);
  const [isTypeCrt, setIsTypeCrt] = useState("");
  const [isTypeDel, setIsTypeDel] = useState("");
  const [isTypeId, setIsTypeId] = useState(0);
  const HandleEqpManModal = (event, type, toDo, item) => {
    event.stopPropagation();
    setIsTypeCrt(type);
    setIsTypeDel(toDo);
    if (
      (type === EQPMAN?.EQPMENT_TYPE || type === EQPMAN?.MANUFACTURER) &&
      toDo === EQPMAN?.ADD
    ) {
      setIsTypeId(0);
    }
    if (type === EQPMAN?.MANUFACTURER && toDo === EQPMAN?.DELETE) {
      setIsTypeId(item?.manufacturer_id);
    }
    if (type === EQPMAN?.EQPMENT_TYPE && toDo === EQPMAN?.DELETE) {
      setIsTypeId(item?.equipment_type_id);
    }
    if (type === EQPMAN?.EQPMENT_TYPE && toDo === EQPMAN?.EDIT) {
      setInpEqpMan(
        item?.equipment_description !== undefined
          ? item?.equipment_description
          : ""
      );

      setIsTypeId(
        item?.equipment_type_id !== undefined ? item?.equipment_type_id : 0
      );
      setManFactCode(
        item?.equipment_code !== undefined ? item?.equipment_code : ""
      );
      setInpEqpManName(
        item?.equipment_type_name !== undefined ||
          item?.equipment_type_name !== null
          ? item?.equipment_type_name
          : ""
      );
    }
    if (type === EQPMAN?.MANUFACTURER && toDo === EQPMAN?.EDIT) {
      setIsTypeId(
        item?.manufacturer_id !== undefined ? item?.manufacturer_id : 0
      );
      setInpEqpMan(
        item?.manufacturer_description !== undefined
          ? item?.manufacturer_description
          : ""
      );
      setManFactCode(
        item?.manufacturer_code !== undefined ? item?.manufacturer_code : ""
      );
      setInpEqpManName(
        item?.manufacturer_name !== undefined ||
          item?.manufacturer_name !== null
          ? item?.manufacturer_name
          : ""
      );
    }
    setIsEqpMan(true);
  };
  const HandleEqpManModalClose = () => {
    setIsEqpMan(false);
    setInpEqpMan("");
    setManFactCode("");
    setInpEqpManName("");
    setIsTypeId(0);
    setIsErrEqpMan(false);
  };
  const [inpEqpManName, setInpEqpManName] = useState("");
  const [inpEqpMan, setInpEqpMan] = useState("");
  const [manFactCode, setManFactCode] = useState("");
  const [isErrEqpMan, setIsErrEqpMan] = useState(false);

  const HandleEqpManInputs1 = (event) => {
    setInpEqpManName(event.target.value);
  };
  const HandleEqpManInputs = (event) => {
    setInpEqpMan(event.target.value);
  };
  const HandleEqpManInputs2 = (event) => {
    setManFactCode(event.target.value);
  };
  const HandelSaveEqpMan = async (type, toDo) => {
    setIsProgress(true);
    if (type === EQPMAN?.MANUFACTURER && toDo === EQPMAN?.DELETE) {
      let Res = await DelEqpMan(isTypeId);
      if (Res.success) {
        setEqpManList(
          eqpmanList?.filter((i) => i?.manufacturer_id !== isTypeId)
        );
        setIsEqpMan(false);
      }
    }
    if (type === EQPMAN?.EQPMENT_TYPE && toDo === EQPMAN?.DELETE) {
      let Res = await DelEqupTypes(isTypeId);
      if (Res?.success) {
        setIsEqpMan(false);
        setEqpTypes(EqupTpes?.filter((i) => i?.equipment_type_id !== isTypeId));
      }
    }
    if (
      type === EQPMAN?.EQPMENT_TYPE &&
      (toDo === EQPMAN?.ADD || toDo === EQPMAN?.EDIT)
    ) {
      if (
        manFactCode?.trim()?.length > 0 &&
        inpEqpMan?.trim()?.length > 0 &&
        inpEqpManName?.trim()?.length > 0
      ) {
        let InpObj = {
          equipment_type_id: isTypeId,
          equipment_code: manFactCode,
          equipment_description: inpEqpMan,
          equipment_type_name: inpEqpManName,
        };
        if (
          EqupTpes?.filter((it) => it?.equipment_type_id !== isTypeId).filter(
            (i) => i?.equipment_code?.trim() === manFactCode?.trim()
          )?.length > 0
        ) {
          alert("Equipment Code Type already exist");
        } else {
          let Res = await EqpTypesAdd(InpObj);
          if (Res?.success) {
            setIsEqpMan(false);
            setIsProgress(false);
            setInpEqpMan("");
            setManFactCode("");
            setInpEqpManName("");
          }
          if (isTypeId === 0) {
            setEqpTypes([...EqupTpes, Res?.data]);
          } else {
            setEqpTypes([
              ...EqupTpes?.filter((it) => it?.equipment_type_id !== isTypeId),
              Res?.data,
            ]);
          }
        }
        setIsErrEqpMan(false);
      } else {
        setIsErrEqpMan(true);
      }
    }

    if (
      type === EQPMAN?.MANUFACTURER &&
      (toDo === EQPMAN?.ADD || toDo === EQPMAN?.EDIT)
    ) {
      if (
        manFactCode?.trim()?.length > 0 &&
        inpEqpMan?.trim()?.length > 0 &&
        inpEqpManName?.trim()?.length > 0
      ) {
        let InpObj = {
          manufacturer_id: isTypeId,
          manufacturer_code: manFactCode,
          manufacturer_description: inpEqpMan,
          manufacturer_name: inpEqpManName,
        };
        if (
          eqpmanList
            ?.filter((it) => it?.manufacturer_id !== isTypeId)
            .filter((i) => i?.manufacturer_code?.trim() === manFactCode?.trim())
            ?.length > 0
        ) {
          alert("Equipment Manufacturer Code already exist ");
        } else {
          let Res = await EqpManAdd(InpObj);
          if (Res?.success) {
            setIsProgress(false)
            setIsEqpMan(false);
            setInpEqpMan("");
            setManFactCode("");
            setInpEqpManName("");
            if (isTypeId === 0) {
              setEqpManList([...eqpmanList, Res?.data]);
            } else {
              setEqpManList([
                ...eqpmanList?.filter((i) => i?.manufacturer_id !== isTypeId),
                Res?.data,
              ]);
            }
          }
        }
        setIsErrEqpMan(false);
        setIsProgress(false);
      } else {
        setIsErrEqpMan(true);
      }
    }
  };
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isColor, setIsColor] = useState("");
  const HandleInput = (value) => {
    setIsColor(value);
  };
  const [isTglWfEqp, setIsTglWfEqp] = useState(false);
  const HandleToggle = () => {
    setIsTglWfEqp(!isTglWfEqp);
  };
  return (
    <>
      <div>
        {/* <CommonHeader />
          <SettingsHeader data={"workflow"} />
          <hr className="mx-1" style={{ margin: "0px" }} /> */}
        <div
          className="line-align-bet "
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <span className="sub-hdr-sett ml-1 ctr-hr-line">
            {SETTINGS_PAGES.WORK_ORDERS_SETUP}
          </span>
          {saved && (
            <span className="pop-up-field-stp  ">
              {" "}
              Fields Saved Successfully... <FaCheck />{" "}
            </span>
          )}
          <span className="d-flex mb-1 mt-1 ">
            <button className="sett-save-btn" onClick={() => {HandleDelWoAddFlds("discard");onDiscard()}}>
              {" "}
              <img alt="" src={delw} width={12} height={12} />{" "}
              <span className="ml-1">{TAGS_MODAL_VALUES.DISCARD} </span>
            </button>

            <button
              className="sett-save-btn mx-2"
              onClick={() => HandleAddadditionalFlds()}
            >
              {" "}
              <img alt="" src={save} width={12} height={12} />{" "}
              <span className="ml-1">{TAGS_MODAL_VALUES.SAVE} </span>
            </button>
          </span>
        </div>
      </div>
      <div className="pagescroll">
        {isProcess ? (
          <div className="pro-bar-style">
            <ProgressBar />
          </div>
        ) : (
          <div>
            {/* Work Orders Type  */}
            <div className="m-2" ref={(ref) => (contentRefs.current[0] = ref)}>
              <div className="d-flex line-align-bet clp_header">
                <div className="cursor-p" onClick={() => HandleIsWorkflw()}>
                  <img alt="WfCategory" className="mb-1" src={WfCategory} />
                  <span className="setField-header ml-2">
                    {WORK_ORDER_SETUP.WORK_ORDER_TYPES}
                  </span>
                </div>
                <div className="cursor-p" onClick={() => CreateWoType()}>
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>
              {isWorkflw ? (
                ""
              ) : (
                <div className="p-1">
                  <div className="mt-0 card">
                    {woType?.sort(function (a, b) {
                      return (
                        new Date(b.modified_date) - new Date(a.modified_date)
                      );
                    })
                      ?.map((item, index) => (
                        <div
                          onMouseEnter={() => sethoveredWotypeIndex(item?.work_order_type_id)}
                          onMouseLeave={() => sethoveredWotypeIndex(null)}
                          style={{
                            borderBottom: "1px solid  rgb(209, 202, 202)",
                            backgroundColor: clickedIndex === item?.work_order_type_id ? "#D1CACA" : hoveredwotypeIndex === item?.work_order_type_id ? "var(--neutral-200, #edeff2)"
                              : "initial"
                          }}
                          onDoubleClick={(e) => HandleEditType(item, index, e)}
                          key={index}
                          className="line-align-bet field-hover-eff w-100 p-2"
                        >
                          <span className="w-100  sett-fs-wo-inp center-line-adj cursor-p">
                            {typeof item?.work_order_type_code !== "object" &&
                              item?.work_order_type_code}

                          </span>
                          <img
                            src={del}
                            onClick={(e) => HandleDeleteTpSh("Type", item, e)}
                            alt=""
                            className="icon-sz cursor-p"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>

            {/* Work Order Fields  */}
            {/* <div className="m-2" ref={(ref) => (contentRefs.current[1] = ref)}>
              <div className="p-1  d-flex line-align-bet ">
                <div className="cursor-p" onClick={() => HandleWfStpSts()}>
                  <img alt="FsSAddField" className="mb-1" src={FsSAddField} />
                  <span className="setField-header ml-2">
                    Work Order Fields
                  </span>
                </div>
                <div className="cursor-p" onClick={() => addWOField()}>
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>
              {WoFields ? (
                ""
              ) : (
                <Container fluid className="p-0">
                  <Row className="hdr-tbl-fld-sett mx-1">
                    <Col className="p-0" xs={9}>
                      {FIELD_SETUP.LABEL_NAME}
                    </Col>



                    <Col
                      className="p-0 centerings "
                      xs={1}
                      onClick={() => HandleCrtAddFld()}
                    >
                      Visible
                    </Col>
                    <Col
                      className="p-0 centerings "
                      xs={1}
                      onClick={() => HandleCrtAddFld()}
                    >
                      {FIELD_SETUP.RQRD}
                    </Col>
                    <Col
                      className="p-0 centerings"
                      xs={1}
                      onClick={() => HandleAdditionalFieldsAdd()}
                    >
                  Shown on Grid
                    </Col>
                  </Row>
                  <div className="inp-int-one-rw  ">
                    {woFieldsData?.map((it, index) => (
                      <div className="mt-1">
                        <WOAdditionalField
                          data={it}
                          isDup={isDup?.wo_extra_interface?.filter(
                            (i) => i?.field_id === it?.field_id
                          )}
                          emptyField={
                            (isFinalSave ||
                              errorKey === "wo_extra_interface") &&
                            errorData["wo_extra_interface"]
                          }
                          onFieldChange={(key, value, id) =>
                            onWOFieldChangeHandler2(key, value, id)
                          }
                          ErpFields={ErpWoFields}
                        />
                      </div>
                    ))}
                  </div>
                </Container>
              )}
            </div> */}
            {/*  WO Add Fields */}
            <div className="m-2" ref={(ref) => (contentRefs.current[2] = ref)}>
              <div className="p-1 d-flex line-align-bet clp_header">
                <div
                  className="cursor-p"
                  onClick={() => HandleWorkOrderAddFild()}
                >
                  <img alt="WoAddField" className="mb-1" src={WoAddField} />
                  <span className="setField-header ml-2">
                    {WORK_ORDER_SETUP.WORK_ORDER_ADDITIONAL}
                  </span>
                </div>
                <div
                  className="cursor-p"
                  onClick={() => addWOAdditionalField()}
                >
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>

              {workOrderAddFild ? (
                ""
              ) : (
                <div className="p-1 clp_body">
                  <div>
                  {woextrafields?.length > 0 ?
                    <Container fluid className="p-0">
                      <Row className="hdr-tbl-fld-sett mx-1 ">
                        <Col className="p-0 " xs={2}>
                          Virtual Tech Label name:
                        </Col>
                        <Col className="p-0 " xs={2}>
                          Virtual Tech Column name:
                        </Col>
                        {/* <Col className="p-0 centerings" xs={1}>
                        Visible
                      </Col> */}
                        <Col className="p-0 " xs={2}>
                          ERP Get:
                        </Col>
                        <Col className="p-0 " xs={1}>
                          ERP Post:
                        </Col>
                        <Col className="p-0 " xs={1}>
                          Type Input:
                        </Col>
                        <Col className="p-0 " xs={1}>
                          Browse/Type Manager:
                        </Col>
                        <Col className="p-0 centerings" xs={1}>
                          Active:
                        </Col>
                        <Col className="p-0 centerings " xs={1}>
                          {FIELD_SETUP.RQRD}:
                        </Col>
                        <Col className="p-0 centerings" xs={1}>
                          Delete:
                        </Col>
                      </Row>
                        <div className="inp-int-one-rw  ">
                          {woextrafields?.map((it, index) => (
                            <div key={index} className="mt-1">
                              <WOAdditionalField
                                data={it}
                                isDup={isDup?.work_order_extra_fields?.filter((i) => i?.field_id === it?.field_id)}
                                emptyField={(isFinalSave ||errorKey === "work_order_extra_fields") && errorData["work_order_extra_fields"]}
                                onFieldChange={(key, value, id) => onWOFieldChangeHandler(key, value, id)}
                                ErpFields={ErpWoFields}
                                HandleDelWoAddFlds={()=>HandleDelWoAddFlds(it,"woflds")}
                              />
                            </div>
                          ))}
                        </div>
                        
                    </Container>
                    : <div className="mx-2 mt-2 card p-1 w-100 text-center">
                          <span className="err-data-wfsetup" style={{ color: "red" }}>Please add additional fields in order to display any here.</span>
                        </div>
                      }
                  </div>
                </div>
              )}
            </div>
            {/* Work Orders Status */}
            <div className="m-2" ref={(ref) => (contentRefs.current[3] = ref)}>
              <div className="p-1  d-flex line-align-bet clp_header">
                <div className="cursor-p" onClick={() => HandleFldStpSts()}>
                  <img alt="adj" className="mb-1" src={adj} />
                  <span className="setField-header ml-2">
                    {WORK_ORDER_SETUP.WORK_ORDER_STATUS}
                  </span>
                </div>
                <div className="cursor-p">
                  <img
                    src={Add_Main}
                    alt="Add_Main"
                    onClick={() => HandleEditWOstusMod()}
                  />
                </div>
              </div>
              {FldStpSts ? (
                ""
              ) : (
                <div className="p-1">
                  <div className="mt-1 card">
                    {woStatus
                      ?.sort(
                        (a, b) =>
                          new Date(b.modified_date) - new Date(a.modified_date)
                      )
                      ?.map((item, index) => (
                        <div
                          key={index}
                          className="line-align-bet field-hover-eff w-100 p-2"
                        >
                          <span
                            onClick={() => onWOStatusEditClick(item)}
                            className="w-100  sett-fs-wo-inp center-line-adj cursor-p"
                          >
                            {item.status}
                          </span>
                          <img
                            src={del}
                            alt=""
                            onClick={(e) => HandleDeleteTpSh("Status", item, e)}
                            className="icon-sz cursor-p"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {/* Call Types */}
            <div className="m-2" ref={(ref) => (contentRefs.current[4] = ref)}>
              <div className="">
                <CallType
                  HandleTogglecall={HandleToggle}
                  isType="Call Type"
                />
              </div>
            </div>
            {/* Customer Additional fields  */}
            <div className="m-2" ref={(ref) => (contentRefs.current[5] = ref)}>
              <div className="p-1 d-flex line-align-bet clp_header">
                <div className="cursor-p" onClick={() => HandleCustFld()}>
                  <img alt="WoAddField" className="mb-1" src={WoAddField} />
                  <span className="setField-header ml-2">
                    {FIELD_SETUP.CUST_ADD_FLD}
                  </span>
                </div>
                <div className="cursor-p" onClick={() => custAddFldWo()}>
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>

              {custFld ? (
                ""
              ) : (
                <div className="p-1 clp_body">
                   {custextrafields?.length > 0 ?
                  <Container fluid className="p-0">
                    <Row className="hdr-tbl-fld-sett mx-1 ">
                      <Col className="p-0 " xs={2}>
                        Virtual Tech Label name:
                      </Col>
                      <Col className="p-0 " xs={2}>
                        Virtual Tech Column name:
                      </Col>
                      {/* <Col className="p-0 centerings" xs={1}>
                        Visible
                      </Col> */}
                      <Col className="p-0 " xs={2}>
                        ERP Get:
                      </Col>
                      <Col className="p-0 " xs={2}>
                        ERP Post:
                      </Col>
                      <Col className="p-0 " xs={1}>
                        Type Input:
                      </Col>
                      <Col className="p-0 " xs={1}>
                        Browse/Type Manager:
                      </Col>
                      <Col className="p-0 centerings" xs={1}>
                        Active:
                      </Col>
                      <Col className="p-0 centerings " xs={1}>
                        {FIELD_SETUP.RQRD}:
                      </Col>

                    </Row>
                   
                      <div className="inp-int-one-rw">
                        {custextrafields?.map((it, index) => (
                          <div key={index} className="mt-1">
                            <WOAdditionalField
                              data={it}
                              isDup={isDup?.customer_extra_fields?.filter(
                                (i) => i?.field_id === it?.field_id
                              )}
                              index={index}
                              emptyField={
                                (isFinalSave ||
                                  errorKey === "customer_extra_fields") &&
                                errorData["customer_extra_fields"]
                              }
                              onFieldChange={(key, value, id) =>
                                wocustAddFldHandler(key, value, id)
                              }
                              ErpFields={ErpCustFields}
                              HandleDelWoAddFlds={()=>HandleDelWoAddFlds(it,"custflds")}
                            />
                          </div>
                        ))}
                      </div>
                     
                  </Container>
                  :
                  <div className="mx-2 mt-2 card p-1 w-100 text-center">
                    <span className="err-data-wfsetup" style={{ color: "red" }}>Please add additional fields in order to display any here.</span>
                  </div>
                }
                </div>
              )}
            </div>
            {/* Equipment Types */}
            <div className="m-2" ref={(ref) => (contentRefs.current[6] = ref)}>
              <div className=" ">
                <EquipmentTypes
                  HandleToggle={HandleToggle}
                  type={EQPMAN?.EQPTYPE}
                  HandleCategories={HandleEqpManModal}
                  isTglWfEqp={isTglWfEqp}
                  data={EqupTpes}
                />
              </div>
            </div>
            {/* Equipment Manufac turers */}
            <div className="m-2" ref={(ref) => (contentRefs.current[7] = ref)}>
              <div className="p-1  d-flex line-align-bet clp_header">
                <div className="cursor-p" onClick={() => HandleEqpMan()}>
                  <img
                    alt="EqpManf"
                    className="mb-1"
                    src={EqpManf}
                    width={22}
                  />
                  <span className="setField-header ml-2">
                    Equipment Manufacturers
                  </span>
                </div>
                <div
                  className="cursor-p"
                  onClick={(e) =>
                    HandleEqpManModal(e, EQPMAN?.MANUFACTURER, EQPMAN?.ADD)
                  }
                >
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>
              {EqpMan ? (
                ""
              ) : (
                <div className="p-1">
                  <div className="mt-1 card">
                    {eqpmanList?.length > 0 ? (
                      eqpmanList
                        ?.sort(function (a, b) {
                          return (
                            new Date(b.modified_date) -
                            new Date(a.modified_date)
                          );
                        })
                        ?.map((item, index) => (
                          <div
                            onClick={(e) =>
                              HandleEqpManModal(
                                e,
                                EQPMAN?.MANUFACTURER,
                                EQPMAN?.EDIT,
                                item
                              )
                            }
                            key={index}
                            className="line-align-bet field-hover-eff w-100 p-2"
                            style={{
                              background:
                                isTypeId === item?.manufacturer_id && "#d5d8d8",
                            }}
                          >
                            <span className="w-100  sett-fs-wo-inp center-line-adj cursor-p">
                              {item.manufacturer_name}
                            </span>
                            <img
                              src={del}
                              alt="del"
                              onClick={(e) =>
                                HandleEqpManModal(
                                  e,
                                  EQPMAN?.MANUFACTURER,
                                  EQPMAN?.DELETE,
                                  item
                                )
                              }
                              className="icon-sz cursor-p "
                            />
                          </div>
                        ))
                    ) : (
                      <div className="align-rt w-100 p-2">
                        <span className="err-data-wfsetup">
                          There is no data{" "}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* Contact additional fields */}
            {/* <div className="m-2" ref={(ref) => (contentRefs.current[6] = ref)}>
              <div className="p-1 d-flex line-align-bet">
                <div className="cursor-p" onClick={() => Handlecontfld()}>
                  <img alt="WoAddField" className="mb-1" src={WoAddField} />
                  <span className="setField-header ml-2">
                    {FIELD_SETUP.CNT_ADD_FLD}
                  </span>
                </div>
                <div className="cursor-p" onClick={() => contAddFldWo()}>
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>

              {contfld ? (
                ""
              ) : (
                <div className="p-1">
                  <Container fluid className="p-0">
                    <Row className="hdr-tbl-fld-sett mx-1 ">
                      <Col className="p-0  " xs={9}>
                        {FIELD_SETUP.LABEL_NAME}
                      </Col>
                      <Col className="p-0 centerings" xs={1}>
                       Visible
                      </Col>
                      <Col className="p-0 centerings " xs={1}>
                        {FIELD_SETUP.RQRD}
                      </Col>
                      <Col className="p-0 centerings" xs={1}>
                        Shown on Grid
                      </Col>
                    </Row>
                    <div className="inp-int-one-rw">
                      {contAddfldWO?.map((it, index) => (
                        <div className="mt-1">
                          <WOAdditionalField
                            data={it}
                            isDup={isDup?.contact_extra_fields?.filter(
                              (i) => i?.field_id === it?.field_id
                            )}
                            emptyField={
                              (isFinalSave ||
                                errorKey === "contact_extra_fields") &&
                              errorData["contact_extra_fields"]
                            }
                            onFieldChange={(key, value, id) =>
                              wocontAddFldHandler(key, value, id)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </Container>
                </div>
              )}
            </div> */}

            {/* equipment additional fields */}
            <div className="m-2" ref={(ref) => (contentRefs.current[8] = ref)}>
              <div className="p-1 d-flex line-align-bet clp_header">
                <div className="cursor-p" onClick={() => HandleEqpAddFld()}>
                  <img alt="WoAddField" className="mb-1" src={WoAddField} />
                  <span className="setField-header ml-2">
                    {FIELD_SETUP.EQP_ADD_FLD}
                  </span>
                </div>
                <div className="cursor-p" onClick={() => eqpAddFild()}>
                  <img src={Add_Main} alt="Add_Main" />
                </div>
              </div>

              {eqpAddFld ? (
                ""
              ) : (
                <div className="p-1 clp_body">
                  {eqpextrafields?.length > 0 ?
                  <Container fluid className="p-0">
                    <Row className="hdr-tbl-fld-sett mx-1 ">
                      <Col className="p-0 " xs={2}>
                        Virtual Tech Label name:
                      </Col>
                      <Col className="p-0 " xs={2}>
                        Virtual Tech Column name:
                      </Col>
                      {/* <Col className="p-0 centerings" xs={1}>
                        Visible
                      </Col> */}
                      <Col className="p-0 " xs={2}>
                        ERP Get:
                      </Col>
                      <Col className="p-0 " xs={2}>
                        ERP Post:
                      </Col>
                      <Col className="p-0 " xs={1}>
                        Type Input:
                      </Col>
                      <Col className="p-0 " xs={1}>
                        Browse/Type Manager:
                      </Col>
                      <Col className="p-0 centerings" xs={1}>
                        Active:
                      </Col>
                      <Col className="p-0 centerings " xs={1}>
                        {FIELD_SETUP.RQRD}:
                      </Col>

                    </Row>
                    {/* <Row className="hdr-tbl-fld-sett mx-1 ">
                      <Col className="p-0 " xs={9}>
                        {FIELD_SETUP.LABEL_NAME}
                      </Col>
                      <Col xs={2} className="p-0 centerings ">
                        Field Matching
                      </Col>
                      <Col xs={2} className="p-0 centerings ">
                        Type
                      </Col>
                      <Col xs={2} className="p-0 centerings ">
                        Type Manager
                      </Col>


                      <Col className="p-0 centerings " xs={1}>
                        {FIELD_SETUP.RQRD}
                      </Col>
                      <Col className="p-0 centerings" xs={1}>
                        Shown on Grid
                      </Col>

                    </Row> */}
                   
                      <div className="inp-int-one-rw ">
                        {eqpextrafields?.map((it, index) => (
                          <div key={index} className="mt-1">
                            {" "}
                            <WOAdditionalField
                              data={it}
                              isDup={isDup?.equipment_extra_fields?.filter(
                                (i) => i?.field_id === it?.field_id
                              )}
                              emptyField={
                                (isFinalSave ||
                                  errorKey === "equipment_extra_fields") &&
                                errorData["equipment_extra_fields"]
                              }
                              onFieldChange={(key, value, id) =>
                                HandleEquipAddFildWo(key, value, id)
                              }
                              ErpFields={ErpEqpFields}
                              HandleDelWoAddFlds={()=>HandleDelWoAddFlds(it,"eqpflds")}
                            />
                          </div>
                        ))}
                      </div>
                  </Container>
                  :
                  <div className="mx-2 mt-2 card p-1 w-100 text-center">
                  <span className="err-data-wfsetup" style={{ color: "red" }}>Please add additional fields in order to display any here.</span>
                  </div>
                    }
                </div>
              )}
            </div>
            <br />
            <br />
            <br />
            {/* add Wo type Modal  */}
            <Modal
              show={isWfModal}
              onHide={() => {
                setIsWfModal(false);
                setNewWoType({ name: "" });
                setWoTypeValidate(true);
              }}
              centered
              size="md"
            >
              <Modal.Header className=" modal-bg-color modal-header-height attach-media-note px-2">
                <span style={{ color: "white" }}>
                  {woTypeMode === "add"
                    ? ` ${FIELD_SETUP_STATUS.CRT_N_FST}`
                    : `${FIELD_SETUP_STATUS.EDT_TYP}`}
                </span>
                <img
                  src={Close}
                  alt="close"
                  onClick={() => {
                    setIsWfModal(false);
                    setNewWoType({ name: "" });
                    setWoTypeValidate(true);
                  }}
                  className="modal-header-icon"
                />
              </Modal.Header>
              <Modal.Body className="p-2 workder_type">
                <div>
                  {isProgress === true ? ( 
                    <div 
                    style={{
                      height: "330px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <ProgressBar />
                  </div>
                  ):(
                <div className="">
                  <span className=" ml-2">{WO_TYPE_MODAL?.WO_TYPE_NAME}</span>

                  <input
                    style={{ height: "40px" }}
                    className=" w-100 crt-wo-status mx-1"
                    placeholder="Input Type Name"
                    type="text"

                    value={newWoType?.work_order_type_description}
                  />
                  <span className=" ml-2">{WO_TYPE_MODAL?.WO_TYPE_CODE}</span>
                  <input
                    style={{ height: "40px" }}
                    className=" w-100 crt-wo-status "
                    placeholder="Input Code"
                    type="text"
                    onChange={(e) =>
                      setNewWoType({ ...newWoType, work_order_type_code: e.target.value })
                    }
                    value={newWoType?.work_order_type_code}
                  />
                  <span className=" ml-2">{WO_TYPE_MODAL?.WO_TYPE_DESC}</span>
                  <input
                    style={{ height: "40px" }}
                    className=" w-100 crt-wo-status mx-1"
                    placeholder="Input Description"
                    type="text"
                    onChange={(e) =>
                      setNewWoType({ ...newWoType, work_order_type_description: e.target.value })
                    }
                    value={newWoType?.work_order_type_description}
                  />
                  <span className=" ml-2">{WO_TYPE_MODAL?.WO_TYPE_WFLW}</span>
                  <select
                    style={{ height: "40px", color: "#3A3D41" }}
                    className=" w-100 crt-wo-status mx-1"
                    value={newWoType?.workflow_list || ""}
                    onChange={(e) =>
                      setNewWoType({ ...newWoType, workflow_list: e.target.value })
                    }
                  >
                    <option style={{ color: "#3A3D41" }} value="" disabled selected hidden>
                      Input all Workflows you want attached to this type
                    </option>
                    {wflwlist?.map((item) =>
                      <option>
                        {item?.workflow_name}
                      </option>
                    )}
                  </select>
                  <span className=" ml-2">{WO_TYPE_MODAL?.WO_TYPE_ESTM}</span>
                  <select
                    style={{ height: "40px", color: "#3A3D41" }}
                    className=" w-100 crt-wo-status mx-1">
                    <option style={{ color: "#3A3D41" }} value="" disabled selected hidden>
                      Input all Workflows you want attached to this type
                    </option>
                    <option>

                    </option>
                  </select>
                  {/* <span>{WO_TYPE_MODAL?.WO_TYPE_ID}</span>
                   <input
                    style={{height:"40px"}}
                    className=" w-100 crt-wo-status"
                    placeholder="Input Type Name"
                    type="text"
                    onChange={(e) =>
                      setNewWoType({ ...newWoType, work_order_type_id: e.target.value })
                    }
                    value={newWoType?.work_order_type_id}
                  /> */}
                  {woTypeValidate && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "10px",
                        lineHeight: "1px",
                      }}
                    >
                      {"Enter Type"}
                    </span>
                  )}
                </div>
                  )}
                </div>
              </Modal.Body>
              <div
                  className="mt-2 model_footer"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    
                  }}
                >
                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={() => AddWoType()}
                  >
                    {WORKFLOW_VALUES.SAVE}
                  </div>

                  <div
                    className="modal-footer-save-cancel-btns"
                    style={{ margin: 0 }}
                    onClick={() => {
                      setIsWfModal(false);
                      setNewWoType({ name: "" });
                      setWoTypeValidate(true);
                    }}
                  >
                    {WORKFLOW_VALUES.CANCEL}
                  </div>
                </div>
            </Modal>
            {/* add Wo status Modal  */}
            <Modal
              show={editWoStatusMod}
              onHide={() => {
                setNewWOStatus("");
                setEditWoStatusMod(false);
                setWoStatusValidate(false);
                setIsColor("");
              }}
              centered
            >
              <Modal.Header className=" modal-bg-color modal-header-height attach-media-note px-2 ">
                <span style={{ color: "white" }}>
                  {woStatusMode === "add"
                    ? ` ${FIELD_SETUP_STATUS.CRT_N_FSS}`
                    : `${FIELD_SETUP_STATUS.EDT_STS}`}
                </span>
                <img
                  src={Close}
                  alt="close"
                  onClick={() => {
                    setNewWOStatus("");
                    setEditWoStatusMod(false);
                    setWoStatusValidate(false);
                    setIsColor("");
                  }}
                  className="modal-header-icon "
                />
              </Modal.Header>
              <Modal.Body className="p-2">
              <div>
                  {isProgress === true ? ( 
                    <div 
                    style={{
                      height: "120px",
                      width: "540px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <ProgressBar />
                  </div>
                  ):(
                <div>
                  <input
                    placeholder="Input status name"
                    className="w-100 crt-wo-status  "
                    value={newWOStatus?.name}
                    onChange={(e) =>
                      setNewWOStatus({ ...newWOStatus, name: e.target.value })
                    }
                  />

                  <div>
                    <span>Status Color:</span>
                  </div>
                  <div
                    className="color-inp-style"
                    style={{ background: isColor }}
                    onClick={() => setShowColorPicker(!showColorPicker)}
                  >
                    <img
                      src={ColorImage}
                      alt="ColorImage"
                      className="m-1"
                      width={20}
                    />
                    <span style={{ fontSize: "10px" }}>
                      Click here to select status color
                    </span>
                    {/* TagModal start */}
                    <Modal size="sm" centered show={showColorPicker}>
                      <Modal.Body centered>
                        <SketchPicker
                          color={isColor}
                          className="ml-4"
                          onChange={(updatedColor) =>
                            HandleInput(updatedColor.hex)
                          }
                        />
                        <hr />
                        <div>
                          <div className="row line-align-even flx-wrp">
                            <button
                              className="col-5  modal-footer-save-cancel-btns"
                              style={{}}
                              onClick={() => setShowColorPicker(false)}
                            >
                              <img src={plus} className="skw-45" alt="cancel" />{" "}
                              Cancel
                            </button>
                            <button
                              className="col-5  modal-footer-save-cancel-btns"
                              onClick={() => setShowColorPicker(false)}
                            >
                              {" "}
                              <img src={save} alt="save" /> Save
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {/* TagModal end */}
                  </div>
                  <div className="mt-1 d-flex w-100">
                    <div className="mx-2 status-mdl-ftrwords-Rules">
                      Rules:{" "}
                    </div>
                    <div className="d-flex w-100 mt-1">
                      <span className="status-mdl-ftrwords">
                        Allowed to Edit?
                      </span>
                      <input
                        className="cursor-p WoFldsCheckbox mx-2"
                        type="checkbox"
                      />
                    </div>
                    <div className="d-flex w-100 mt-1">
                      <span className="status-mdl-ftrwords">
                        Marks WorkOrder Complete?
                      </span>
                      <input
                        className="cursor-p WoFldsCheckbox mx-2"
                        type="checkbox"
                      />
                    </div>
                    <div className="d-flex w-100 mt-1">
                      <span className="status-mdl-ftrwords">
                        Post to third party?
                      </span>
                      <input
                        className="cursor-p WoFldsCheckbox mx-2"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  {woStatusValidate && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "10px",
                        fontFamily: "sofiaProMedium",
                        lineHeight: 0,
                      }}
                    >
                      {"Enter Status "}
                    </span>
                  )}
                </div>
                  )}
              </div>
              </Modal.Body>
              <div
                  className="mt-2 model_footer"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                   
                  }}
                >
                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={() => addNewWOStatus()}
                  >
                    {WORKFLOW_VALUES.SAVE}
                  </div>
                  <div
                    className="modal-footer-save-cancel-btns"
                    style={{ margin: 0 }}
                    onClick={() => {
                      setNewWOStatus("");
                      setEditWoStatusMod(false);
                      setWoStatusValidate(false);
                      setIsColor("");
                    }}
                  >
                    {WORKFLOW_VALUES.CANCEL}
                  </div>
                </div>
            </Modal>

            {/* delete */}
            <Modal
              show={deleteTpSh}
              onHide={() => handledeleteTpClose()}
              centered
            >
              <Modal.Header className=" modal-bg-color modal-header-height attach-media-note px-2 ">
                <span style={{ color: "white" }}>
                  {TAGS_MODAL_VALUES.DELETE} {deleteMode}
                </span>
                <img
                  src={Close}
                  alt="close"
                  onClick={() => handledeleteTpClose()}
                  className="modal-header-icon "
                />
              </Modal.Header>
              <Modal.Body className="p-1 delete-area-styles ">
                <div className="sub-titles-names px-2">
                  Once you remove the work order {deleteMode} you cannot restore
                  the {deleteMode}. Are you sure you want to proceed?
                </div>
                {/* <span>
                  "
                  {deleteMode === "Status"
                    ? `${deleteContent?.status}`
                    : `${deleteContent?.field_setup}`}
                  " {deleteMode} ?
                </span> */}

                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={() => handleDeleteSubmit()}
                  >
                    {WORKFLOW_VALUES.DELETE}
                  </div>
                  <div
                    className="modal-footer-save-cancel-btns"
                    style={{ margin: 0 }}
                    onClick={() => handledeleteTpClose()}
                  >
                    {WORKFLOW_VALUES.CANCEL}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {/* add fields text  */}
            <Modal
              show={additionalFieldsAdd}
              onHide={() => setAdditionalFieldsAdd(false)}
              centered
              size="sm"
            >
              <Modal.Header className=" modal-bg-color modal-header-height attach-media-note px-2">
                <span>{FIELD_SETUP.MX_FLD_ADDED}</span>
                <img
                  src={Close}
                  alt="close"
                  onClick={() => setAdditionalFieldsAdd(false)}
                  className="modal-header-icon "
                />
              </Modal.Header>
              <Modal.Body className="p-2">
                <div className="desc-txt-add-fld ">
                  {WORKFLOW_VALUES.STATUS_FLIE_DELETE1}
                </div>
                <div className="line-align-even  mt-1">
                  <button
                    className="btn-add-fld-sett-mdl"
                    onClick={() => setAdditionalFieldsAdd(false)}
                  >
                    {WORKFLOW_VALUES.CANCEL}
                  </button>
                  <button className="btn-add-fld-sett-mdl">
                    {FIELD_SETUP.CONT_SUP}
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={crtAddFld}
              onHide={() => setCrtAddFld(false)}
              centered
              size="sm"
            >
              <Modal.Header className=" modal-bg-color modal-header-height attach-media-note px-2">
                <span>{FIELD_SETUP.MX_FLD_ADDED}</span>
                <img
                  src={Close}
                  alt="close"
                  onClick={() => setCrtAddFld(false)}
                  className="modal-header-icon "
                />
              </Modal.Header>
              <Modal.Body>
                <input
                  className=" w-100 crt-wo-status  "
                  placeholder="Input  Field Setup"
                />
                <select
                  className="inpt-add-eqp lbl-ipt-add-eqp w-100  mt-1  p-1 "
                  style={{ border: "1px solid #CCC" }}
                >
                  <option selected> Select Field Type</option>
                  <option value={0}>Numeric</option>
                  <option value={1}>Integer</option>
                  <option value={2}>Date</option>
                  <option value={3}>String</option>
                </select>
                <span className="line-align-even mt-1">
                  <div className="txt-align-c mb-0">
                    <label className="lbl-mdl-fs-add-fld  mb-0">
                      {" "}
                      {FIELD_SETUP.RQRD}
                    </label>
                    <input type="checkbox" />
                  </div>
                  <div className="txt-align-c mb-0">
                    <label className="lbl-mdl-fs-add-fld  mb-0">
                      {" "}
                      {FIELD_SETUP.ODR}
                    </label>
                    <input type="checkbox" />
                  </div>
                </span>

                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <div className="modal-footer-save-cancel-btns">
                    {WORKFLOW_VALUES.SAVE}
                  </div>
                  <div
                    className="modal-footer-save-cancel-btns"
                    style={{ margin: 0 }}
                    onClick={() => setCrtAddFld(false)}
                  >
                    {WORKFLOW_VALUES.CANCEL}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {isAllFieldsFilled && (
              <AlertFldStp
                errorKey={errorKey}
                isFinalSave={isFinalSave}
                isOpen={isAllFieldsFilled}
                data={errorData}
                isClose={() => {
                  setIsAllFieldsFilled(false);
                  setIsDupErr(false);
                }}
                isDup={isDup}
                isDupErr={isDupErr}
              />
            )}
          </div>
        )}
        {/* <CommonFooter /> */}
      </div>
      <EqpManufactModal
        Open={isEqpman}
        Close={() => HandleEqpManModalClose()}
        inpEqpMan={inpEqpMan}
        manFactCode={manFactCode}
        HandleEqpManInputs1={HandleEqpManInputs1}
        HandleEqpManInputs={HandleEqpManInputs}
        HandleEqpManInputs2={HandleEqpManInputs2}
        HandelSaveEqpMan={HandelSaveEqpMan}
        isType={isTypeCrt}
        isTypeDel={isTypeDel}
        isErrEqpMan={isErrEqpMan}
        inpEqpManName={inpEqpManName}
        isProgress={isProgress}
      />
    </>
  );
}

export default WorkOrderSetup;
