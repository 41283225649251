import { Modal } from "react-bootstrap";
import "./Validation.css";
import del from '../../common/Assets/Icons/del.svg'
import pen from '../../common/Assets/Icons/penb.svg'

const Validation = (props) => {
return(
    <>
    <Modal show={props.isOpen} onHide={props.isClose} centered>
    <Modal.Body>
         <>
         <b className="validationModal-title">Note cannot be saved.</b>
         <br/>
         <b className="validationModal-body">
            This note cannot be saved without entering both a Description and Title. Please enter both or discard the note to continue.
         </b>
         </>
         </Modal.Body>
         <div className="validationModal-footer mb-3">
            <div className="returnButton centerings" onClick={props.isClose}>
              <div>Return to Note</div>
              <img src={pen} className="imagestyle ml-1" alt=""/>
        </div>
        <div className="returnButton centerings" onClick={props.isDiscard}>
         <div>Discard</div> 
         <img src={del} className="imagestyle ml-1" alt=""/>
         </div>
         </div>        
    </Modal>
    </>
)
}

export default Validation;