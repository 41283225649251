import React from "react";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import WfCategory from "../../../../common/Assets/Icons/Vectorwf.svg";
import del from "../../../../common/Assets/Icons/del.svg";
export default function WorkFlowAtomation(props) {
  return (
    <div>
      <div className="line-align-bet w-100 clp_header">
        <div className="mx-2" style={{ fontFamily: "SofiaProBold" }}>
          <img src={WfCategory} alt="WfCategory" width={20} className="mb-1" />
          <span
            className="mx-1 wftags-sub-header cursor-p"
            onClick={() => props?.HandleToggle("Workflows automation")}
          >
            Workflows Automation
          </span>
        </div>
        <div className="wf-plus">
          <div onClick={() => props?.HandleWfAuto("IN")}>
            <img src={Add_Main} alt="Add-Main" width={18} />
          </div>
        </div>
      </div>
      {!props?.isTglWfAuto && (
        <div className="clp_body" style={{ padding: "10px 10px 10px 10px" }}>
          <div className="card">
            {props?.data?.length > 0 ? (
              props?.data
                ?.sort(
                  (a, b) =>
                    new Date(b.modified_date) - new Date(a.modified_date)
                )
                ?.map((it) => (
                  <div
                    onClick={() => props?.HandleWfAuto("UP", it)}
                    className="line-align-bet w-100 p-2 cursor-p"
                    style={{
                      borderBottom: "1px solid  rgb(209, 202, 202)",
                      //   background:
                      //     (it?.workflow_category_id === props?.isEqupId &&
                      //       "#d5d8d8") ||
                      //     (it?.workflow_category_id === props?.isWfTyCatId &&
                      //       "#d5d8d8"),
                    }}
                  >
                    <span className="sub-w-items-text cursor-p">
                      {it?.reference}
                    </span>
                    <img
                      src={del}
                      alt="del"
                      className="cursor-p"
                      onClick={(e) => props?.HandleWfAuto("D", it, e)}
                    />
                  </div>
                ))
            ) : (
              <div className="align-rt w-100 p-2">
                <span className="err-data-wfsetup">There is no data </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
