import React, { forwardRef, useState, useEffect } from "react";
import "./Notes.css";
import add from "../../common/Assets/Icons/add.svg";
import acc from "../../common/Assets/Icons/acct.svg";
import DeleteWOModal from "../workorders/WorkOrderDetails/DeleteWOModal";
import NoteAddModal from "./NoteAddModal";
import del from "../../common/Assets/Icons/del.svg";
import penP from "../../common/Assets/Icons/Pen.svg";
import moment from "moment";
import { WORK_ORDER_CONSTANTS } from "../../common/components/Constants";
import { TABLE_ÍCON_SIZES } from "../../common/components/Values";
import {
  NoteDeleteFunction,
  NoteCreatFunction,
} from "../../services/WorkOrdersService";
import Validation from "./Validations";
import { ReferenceId } from "../../common/components/EnumRefType";

const Notes = forwardRef(function Notes(props, ref) {
  const [NotesList, setNotesList] = useState([]);
  const [isWoid, setIsWoid] = useState(0);
  const [LocStrId, setLocStrId] = useState();
  useEffect(() => {
    // let WorkPath = window.location.href.split("/");
    let ServiceId = props?.apiData?.id?props?.apiData?.id
    :localStorage?.getItem("reswoid")
    // :WorkPath[5]
    setIsWoid(ServiceId);
    setNotesList(props?.data);
    setLocStrId(~~localStorage.getItem("userId"));
  }, [props]);
  
  const [isNoteObj, setIsNoteObj] = useState({});
  const [validationModal, setValidationModal] = useState(false);
  const [isNotesToggle, setIsNoteToggle] = useState(true);
  const handleNotesToggle = () => {
    setIsNoteToggle(!isNotesToggle);
  };
  // const HandleNoteToggle = (value) => {
  //   let ToggledData = [...isNoteArry];
  //   if (ToggledData.includes(value.id)) {
  //     ToggledData = isNoteArry.filter((it) => it !== value.id);
  //   } else {
  //     ToggledData = [...ToggledData, value.id];
  //   }
  //   setIsNoteArry(ToggledData);
  // };
  // const HandleEditNote = (item) => {
  //  setAddnotemodal(true)

  // };
  const [showDeletenote, setShowDeletenote] = useState(false);

  const HandleDelteNote = (it, item) => {
    it?.stopPropagation();
    setIsNoteId(item);
    setShowDeletenote(true);
  };
  const confirmDeletenote = async () => {
    const Responce = await NoteDeleteFunction(isNoteId?.id);
    if (Responce?.success) {
      const updatedNotesList = NotesList.filter(
        (item) => item?.id !== isNoteId?.id
      );
      setNotesList(updatedNotesList);
      props.setNotesArray(updatedNotesList);
      setShowDeletenote(false)
      setIsNoteId(0)
    };
  }

  const handleEditClick = (notekey, item) => {
    setisBtn(notekey);
    setAddnotemodal(true);
    setNoteInpObj({ ...noteInpObj, note: item?.content });

    setIsNoteId(item?.id);
    // Open the modal
  };
  const [noteInpObj, setNoteInpObj] = useState({
    note: "",
    title: "",
  });
  const [isNoteId, setIsNoteId] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const handleNoteSave = async () => {
    setIsLoad(true);
    let woid = props?.apiData?.id?props?.apiData?.id
    :localStorage?.getItem("reswoid");
    let TechPin = JSON.parse(localStorage.getItem("techpin"));
    let noteData = {
      content: noteInpObj?.note,
      title: noteInpObj?.title,
      technician:TechPin,
    };
    try {
      let response;
      // setIsNoteId(0)
      response = await NoteCreatFunction(
        woid,
        isNoteId,
        woid,
        ReferenceId?.work_order,
        noteData
      );
      if (response.success) {
        const newNote = {
          id: response.data.note_id,
          title: response.data.title,
          content: response.data.notes,
          date_created: moment().toISOString(),
          owner: LocStrId,
        };
        if (isNoteId > 0) {
          setNotesList([
            ...NotesList?.filter((i) => i?.id !== response?.data?.note_id),
            newNote,
          ]);
          props.setNotesArray([
            ...NotesList?.filter((i) => i?.id !== response?.data?.note_id),
            newNote,
          ]);
        } else {
          setNotesList([...NotesList, newNote]);
          props.setNotesArray([...NotesList, newNote]);
        }
      }

      setIsLoad(false);
      setNoteInpObj({ note: "", title: "" });
      setAddnotemodal(false);
      // setIsNoteId(0); // Reset note ID after saving
      setIsEditing(false); // Reset editing mode
    } catch (error) {
      setIsLoad(false);
    }
  };
  const closeBothModals = () => {
    setValidationModal(false);
  };
  const [isBtn, setisBtn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [addNoteModal, setAddnotemodal] = useState(false);
  const HandleNoteModal = (val) => {
    setisBtn(val);
    setIsNoteId(0);
    setAddnotemodal(true);
    setNoteInpObj({});
  };
  const closeModal = () => {
    setShowDeletenote(false);
    setAddnotemodal(false);
    setIsNoteObj({});
    setIsEditing(false);
  };
  const ChangeEvents = (e) => {
    const { name, value } = e.target;
    setNoteInpObj({ ...noteInpObj, [name]: value });
  };
  let techname = JSON?.parse(localStorage?.getItem("userName"))
  return (
    <>
      <div className="line-align-bet">
        <img
          src={penP}
          className="flt-l  cursor-p"
          width={16}
          alt="details"
          onClick={() => handleNotesToggle()}
        />
        <div className="line-align-bet w-100">
          <div className="mx-2 notes-span" onClick={() => handleNotesToggle()}>
            {WORK_ORDER_CONSTANTS.NOTES} ({NotesList?.length})
          </div>
        </div>
        <div className="myDiv1"
        style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
        >
          {props?.isVal !== "create"  && (
            <img
              src={add}
              width={14}
              alt="add note"
              className="mx-1 cursor-p"
              onClick={() => HandleNoteModal("Add")}
            />
          )}
        </div>
      </div>
      {isNotesToggle ? (
        <div className=" nts-div"
        style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
        >
          {NotesList && NotesList.length > 0 ? (
            NotesList.slice(0, 3)
              .sort(
                (a, b) => new Date(b.date_created) - new Date(a.date_created)
              ) 
              .map((item, index) =>
                  <div
                    onDoubleClick={() => handleEditClick("Edit", item)}
                    key={index}
                    className="nts-card mb-1"
                  >
                    <div className="d-flex justify-content-between ">
                      <span className="user-span">
                        <img   src={acc} className="acc-img mb-1 cursor-p" alt="account note" />
                        <span>{techname?.toLowerCase()?.split(" ")?.map(
                                  (word) =>
                                    word?.charAt(0)?.toUpperCase() + word?.slice(1)
                                )?.join(" ")}</span>
                      </span>
                      <div className="d-flex notes-txt-date">
                        {moment(item.date_created).format(
                          TABLE_ÍCON_SIZES.NOTES_DATE_FORMAT
                        )}
                        <div className="d-flex line-align cursor-p ml-2">
                          <img
                            src={del}
                            alt="delete"
                            className="opct-p7 mx-1"
                            width={TABLE_ÍCON_SIZES.NOTES_PEN_SIZE}
                            onClick={(e) => HandleDelteNote(e, item, "Delete")}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="text-style">{item?.content}</span>
                  </div>
              )
          ) : (
            <div className="card-descrptn">
              {WORK_ORDER_CONSTANTS.CUST_NOTES_DESC}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      <DeleteWOModal
        data={WORK_ORDER_CONSTANTS.DEL}
        Id={isWoid}
        Open={showDeletenote}
        Close={() => closeModal()}
        HandleDeleteCustomer={confirmDeletenote}
      />
      <NoteAddModal
        isOpen={addNoteModal}
        isNoteId={isNoteId}
        isClose={() => closeModal()}
        noteTitle={isNoteObj.title || noteInpObj.title}
        noteDescription={isNoteObj.content || noteInpObj.note}
        HandleInputs={ChangeEvents}
        isSubmit={() => handleNoteSave()}
        isLoading={isLoad}
        WorkId={ isWoid}
        name={"Work Order"}
        isBtn={isBtn}
        validationModal={validationModal}
        isEditing={isEditing}
      />
      <Validation
        isOpen={validationModal}
        isClose={() => setValidationModal(false)}
        isDiscard={() => closeBothModals()}
      />
    </>
  );
});

export default Notes;
