import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonHeader from "../../../common/framework/header/HeaderCommon/CommonHeader";
import Alerts from "../../../common/components/Alerts/Alerts";
import { CustAddfieldsGet } from "../../../services/SettingsServices";
import { AppGlobalContextUpdate } from "../../../common/context/AppGlobalContext";
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import { NEW_CUSTOMER } from "../../../common/components/Values";
import plus from "../../../common/Assets/Icons/Add_round.svg";
import pen from "../../../common/Assets/Icons/pencil_filled.svg";
import {
  GetAttchMentByID,
  addTags,
  deleteTags,
  CustomerDelete,
  getTechList,
  GetMemory,
} from "../../../services/WorkOrdersService";
import { Card, Col, Row } from "react-bootstrap";
import Logs from "../../../common/Assets/Icons/Logs.svg";
import WorkOrderIcon from "../../../common/Assets/Icons/work order albums.svg";
import Custinfo from "../../../common/Assets/Icons/CustInfo.svg";
import MemoryBoard from "../../../common/Assets/Icons/Memory Board Icon.svg";
import VectorEqpBlue from "../../../common/Assets/Images/VectorEqpBlue.svg";
import delW from "../../../common/Assets/Icons/delW.svg";
import Save from "../../../common/Assets/Images/Unionsave.svg";
import Acclmg from "../../../common/Assets/Images/AccImg.png";
import details from "../../../common/Assets/Icons/Details.jpg";
import Dail from "../../../common/Assets/Icons/dailicon.svg";
import videoCall from "../../../common/Assets/Icons/Vid.svg";
import MailIcon from "../../../common/Assets/Icons/mail.svg";
import share from "../../../common/Assets/Icons/Share.svg";
import deleteIcon from "../../../common/Assets/Icons/deleicon.svg";
import locationIcon from "../../../common/Assets/Icons/location_main.svg";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import WorkOrderCard from "../../../features/workorders/WorkOrderCard";
import {
  AddCusEqService,
  GetCustById,
  GetCustExtraDetailsById,
} from "../../../services/AddCusEqService";
import "./CreateNewcustomer.css";
import CustomerModal from "../../../common/components/customermodal/CustomerModal";
import AttachmentModal from "../../workorders/AttachmentModal";
import AddTag from "../../workorders/addtag/AddTag";
import ProgressBar from "../../../common/navigation/ProgressBar";
import CNCSideBar from "../../customers/CNCSideBar";
import CustExtraFields from "../../customers/CustExtraFields";
import CustomerRightSideBar from "../CustomerRightSideBar";
import WoDetailsCommunication from "../../communications/WoDetailsCommunication";
import MemoryModal from "../../../common/components/memorymodal/MemoryModal";
import DeleteWOModal from "../../workorders/WorkOrderDetails/DeleteWOModal";
import Equipments from "../../equipments/Equipments";
import { CUST_PRTL, DEL_W_O } from "../../../common/components/Constants";
import ReferenceType, {
  ReferenceId,
} from "../../../common/components/EnumRefType";
import DeleteTags from "../../workorders/addtag/DeleteTags";
// import CustMediaCentre from "./CustMediaCentre";
import CustShareModal from "../CustShareModal";
import MemoryblueModal from "../../../common/components/memorymodal/MemoryblueModal";
import { ExtraFieldsTypeValids } from "../../equipments/AddEquipment/PreviousWorkOrdersEquipments/ExtraFieldsTypeValids";
import MediaCenter from "../../mediacenter/MediaCenter";

function CreateNewcustomer(props) {
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const contentRefs = useRef([]);
  const location = useLocation();
  const [error, setError] = useState([]);
  const [isCustomer, setIsCustomer] = useState(false);
  const [customerObj, setCustomerObj] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [infoTgl, setInfoTgl] = useState(true);
  const [woTgl, setWoTgl] = useState(true);
  const [isMemory, setIsMemory] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [delWOmodal, setDelWOmodal] = useState(false);
  const [qrcustid, setqrCustId] = useState(null);
  const [technician, setTechnician] = useState([]);
  const [creatingFields, setcreatingFields] = useState([]);
  useEffect(() => {
    GetTech();
  }, []);
  const GetTech = async () => {
    let Resp = await getTechList();
    setTechnician(Resp);
  };
  // const HandleMemory = (e, item) => {
  //   e.stopPropagation();
  //   setIsMemory(true);

  //   setIsMemoryObj(item);
  // };
  const handleCustShare = (e, item) => {
    // setqrCustId(item?.customer_id)
    e.stopPropagation();
    setIsOpen(true);
  };
  const HandleCloseMemory = () => {
    setIsMemory();
  };
  // const handleWorkorders = (value) => {
  //   setWorkOrders(value);
  // };
  const [isTagOpen, setIsTagOpen] = useState(false);

  const HandleInfoTgl = () => {
    setInfoTgl(!infoTgl);
  };
  const HandleWOTgl = () => {
    setWoTgl(!woTgl);
  };

  const AddCustomerEquipment = (e) => {
    const { name, value } = e.target;
    if(name === "telephone"){
      const cleanedValue = value.replace(/\D/g, "");
      const formattedValue = cleanedValue
        .replace(/(\d{3})(\d{3})(\d{1,4})/, "$1-$2-$3")
        .substring(0, 12);
        setUserInputs({
          ...userInputs,
          [name]: formattedValue,
      });
    }
    else{
    setUserInputs({
      ...userInputs,
      [name]: value,
    })};
  };
  const UpdateList = (
    key,
    e,
    field_id,
    controlType,
    value,
    additionalFields1
  ) => {
    // const newList = [...addFields]?.map((i) => {
    //   let temp = i;
    //   if (i.field_id === field_id && controlType === "integer") {
    //     const regex1 = /[^0-9]/g;
    //     let intValue = e.target.value;
    //     let filteredintvalue = intValue.replace(regex1, "");
    //     temp[key] = filteredintvalue;
    //   } else if (i.field_id === field_id && controlType === "string") {
    //     const regex2 = /^[a-zA-Z0-9]*$/;
    //     let stringValue = e.target.value;
    //     if (regex2.test(stringValue)) {
    //       temp[key] = stringValue;
    //     }
    //   } else if (i.field_id === field_id && controlType === "numeric") {
    //     const regex2 = /[^0-9]+$/;
    //     let numericValue = e.target.value;
    //     let filterednumericValue = numericValue.replace(regex2, "");
    //     temp[key] = filterednumericValue;
    //   } else if (i.field_id === field_id && controlType === "date") {
    //     let dateValue = e;
    //     temp[key] = moment(dateValue).format("DD-MM-YYYY");
    //   }
    //   return temp;
    // });
    let Res = ExtraFieldsTypeValids(
      key,
      e,
      field_id,
      controlType,
      value,
      additionalFields1
    );
    setAddFields(Res);
    setcreatingFields(Res)
  };
  const [isCustVal, setIsCustVal] = useState("");
  const query = new URLSearchParams(useLocation().search);
  const customerId = query?.get("customerId");
  let shareid = window?.location?.href?.split("/")[10];
  let sharecustid = window?.location?.href?.split("/")[6];
  // do something with the customerId
  const getCustExtraDetails = useCallback(
    async (id) => {
      let custid =
        shareid === 1
          ? sharecustid
          : id
            ? id
            : customerId
              ? customerId
              : localStorage.getItem("customer_id");
      setCustId(custid);
      setqrCustId(custid);
      let Res = await GetCustExtraDetailsById(
        // customerId ? customerId : localStorage.getItem("customer_id")
        custid
      );
      if (Res?.success) {
        setCustdet(Res?.data[0]);
        setAttachments(Res?.data[0]?.attachments);
        setCustNotes(Res?.data[0]?.notes ?? []);
        setTags(Res?.data[0]?.tags ?? []);
        setCustEquipments(Res?.data[0]?.equipment ?? []);
      }
    },
    [customerId, sharecustid, shareid]
  );

  useEffect(() => {
    let idd = location?.state?.key !== 0
      ? location?.state?.key
      : localStorage.getItem("customer_id")
    if (idd === null) {
      CustFunAddfieldsGet();
      setIsCustVal("create")
    }
    // else if(idd <= 0 || idd === null)
    // {
    //   CustFunAddfieldsGet();
    // }
    else {
      setIsCustVal("edit");
      ;
    }
    GetCustDetails(
      +shareid === 1
        ? +sharecustid
        : location?.state?.key !== 0
          ? location?.state?.key
          : localStorage.getItem("customer_id")
    );
    // setUserInputs(location?.state?.params?.item)
    getCustExtraDetails(
      +shareid === 1
        ? +sharecustid
        : location?.state?.key !== 0
          ? location?.state?.key
          : localStorage.getItem("customer_id")
    );
  }, [getCustExtraDetails, location, sharecustid, shareid]);
  const saveRecentlyViewedItems = (workId, itemDetails) => {
    const storedItems =
      JSON.parse(localStorage.getItem("recentlyViewed")) || {};
    if (storedItems && storedItems[workId]) {
      const newList = [...storedItems[workId]]?.filter(
        (i) => i.id !== itemDetails.id
      );
      storedItems[workId] = [itemDetails, ...newList];
    } else {
      storedItems[workId] = [itemDetails];
    }
    localStorage.setItem("recentlyViewed", JSON.stringify(storedItems));
  };
  useEffect(() => {
    if (location?.state?.key !== null) {
      // GetCustDetails(location?.state?.customer_id);
      // getCustExtraDetails(location?.state?.customer_id);
      // setCustId(location?.state?.key);
    }
  }, [location.pathname, location?.state]);
  const [custNotes, setCustNotes] = useState([]);
  const [custDetails, setCustDetails] = useState([]);
  const [attachments, setAttachments] = useState([]);
  let [custId, setCustId] = useState(0);
  const [prevCustWO, setPrevCustWO] = useState([]);
  const [custEquipments, setCustEquipments] = useState([]);
  const GetCustDetails = async (id) => {
    setisView2(true);
    let TechId = JSON.parse(localStorage.getItem("userId"));
    let Res = await GetCustById(id, TechId, 0, 10);
    setCustDetails(Res?.data);
    setPrevCustWO(Res?.data?.previous_workorders_cust);
    // setCustId(id);
    if (Res?.success) {
      setIsCustVal("edit");
      setUserInputs({
        customer_id: JSON?.stringify(Res?.data?.customer_id),
        customer_name: Res?.data?.customer_name,
        address: Res?.data?.address,
        // address2: Res?.data?.address2,
        city: Res?.data?.city,
        state: Res?.data?.state,
        // country: Res?.data?.country,
        zip: Res?.data?.zip_code,
        // exCords: "",
        telephone: Res?.data?.customer_phone,
        // telephone2: Res?.data?.contact_phone,
        email: Res?.data?.email,
        // note: "",
      });
      setAddFields(Res?.data?.customer_additional_fields);
    }
  };

  const [custdet, setCustdet] = useState([]);
  const [addFields, setAddFields] = useState([]);
  const [addfldsInput, setAddfldsInput] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const CustFunAddfieldsGet = async () => {
    setIsProcess(true);
    let Res = await CustAddfieldsGet();
    if (Res?.success) {
      setIsProcess(false);
      setAddFields(Res?.data);
      setAddfldsInput(Res?.data[0]);
      setcreatingFields(Res?.data)
    }
  };
  const [userInputs, setUserInputs] = useState({
    customer_id: "0",
    customer_name: "",
    address: "",
    // address2: "",
    city: "",
    state: "",
    // country: "",
    zip: "",
    // exCords: "",
    telephone: "",
    // telephone2: "",
    email: "",
    // note: "",
  });
  const handleDiscard = async () => {
    if (isCustVal === "create") {
      navigate("/Customers");
    } else {
      setUserInputs({
        customer_id: custDetails?.customer_id,
        customer_name: custDetails?.customer_name,
        address: custDetails.address,
        // address2: Res?.data?.address2,
        city: custDetails.city,
        state: custDetails.state,
        // country: Res?.data?.country,
        zip: custDetails?.zip_code,
        // exCords: "",
        telephone: custDetails?.customer_phone,
        // telephone2: Res?.data?.contact_phone,
        email: custDetails?.email,
        // note: "",
      });
      // setUserInputs(userInputs);
      setAddFields(custDetails?.customer_additional_fields);
      setIsCustVal("edit");
      let TechId = JSON.parse(localStorage.getItem("userId"));
      let Res = await GetCustById(custdet?.customer_id, TechId, 0, 10);
      setAddFields(Res?.data?.customer_additional_fields);
    }
  };
  // const handleDiscard = () => {
  //   setIsDiscard(true);
  //   navigate("/Customers");
  // };
  useEffect(() => {
    scrollToDiv(0);
  }, []);

  // const scrollToDiv = (index) => {
  //   const contentDiv = contentRefs.current[index];
  //   contentDiv.style.top = "-180px";
  //   contentDiv.style.position = "relative";
  //   contentDiv.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // };
  const scrollToDiv = (index) => {
    const contentDiv = contentRefs.current[index];
    contentDiv?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const [isView2, setisView2] = useState(false);
  const handleAlert2 = (val) => {
    setIsCustVal(val);
    setCustId(userInputs?.customer_id);
    setisView2(false);
  };
  const validateTelephone = (number) => {
    const phoneRegex =
    /^\d{3}-\d{3}-\d{4}$/;
    return !phoneRegex.test(number);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !emailRegex.test(email);
  };
  const handleAlert = async (item) => {
    let erroMsg = [];
    let objectsfilter = Object.keys(userInputs);
    let checkFields = isCustVal === "create" ? creatingFields : addFields;
    const errorArray = checkFields?.filter(
      (i) => i.required === true && i?.value?.length === 0
    );
    if (errorArray?.length > 0) {
      setError(errorArray);
      setIsModal(true);
    }
    objectsfilter?.map((it) => {
      if (it !== "customer_id") {
        if (
          userInputs[it]?.length <= 0 ||
          userInputs[it]?.length === undefined ||
          userInputs[it] === null
        ) {
          erroMsg.push({
            message: `${it}`,
          });
        } else {
          if (it === "telephone") {
            if (validateTelephone(userInputs[it])) {
              erroMsg.push({
                message: `telephone`,
              });
            }
          } else if (it === "email") {
            if (validateEmail(userInputs[it])) {
              erroMsg.push({
                message: `email`,
              });
            }
          }

          // customer_id
        }
      }

      return erroMsg;
    });

    // addFields?.filter((i) => i.required && (i.value === "" || i.value === null))
    //   ?.length > 0 && erroMsg.push({ message: "Please add Additional Fields" });
    let AddFldsSubmit = addFields?.map((i) => ({
      field_id: i.field_id,
      value: i.value,
    }));

    if (erroMsg?.length > 0 || errorArray?.length > 0) {
      setIsModal(true);
      setError([...erroMsg, ...errorArray]);
    } else {
      let newData = {
        customer_id: userInputs.customer_id,
        customer_name: userInputs.customer_name,
        email: userInputs.email,
        customer_phone: userInputs.telephone,
        contact_phone: "",
        address: userInputs.address,
        address2: "",
        city: userInputs.city,
        state: userInputs.state,
        country: "",
        zip_code: userInputs.zip,
        customer_additional_fields: AddFldsSubmit,
        note: "",
      };
      let res = await AddCusEqService(newData);
      if (res?.data?.success) {
        localStorage.setItem(
          "customer_id",
          JSON.stringify(res?.data?.data?.customer_id)
        );
        getCustExtraDetails(res?.data?.data?.customer_id);
        setError([]);
        localStorage?.setItem("rescustid", res?.data?.data?.customer_id);
        setIsCustVal("edit");
        setCustTagId(res?.data?.data?.customer_id);
        GetCustDetails(res?.data?.data?.customer_id?.toString());
      }
    }
  };

  const [carouselIndex, setCarouselIndex] = useState(0);
  // const [carouselImages, setCarouselImages] = useState([]);
  const [isOpen2, setIsOpen2] = useState(false);
  const HandleDelWOmodal = () => {
    setDelWOmodal(true);
  };
  const updateAppState = useContext(AppGlobalContextUpdate);
  const navigation = useNavigate();
  const navToWoDetls = (e, to, wdObj, isVal, woid, custid) => {
    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: {
        wdObj,
        isVal,
        woid: wdObj?.WorkOrderId,
        custid: wdObj?.Customer?.id,
      },
      navigate: navigation,
    });
    e.stopPropagation();
  };
  const HandleCutomer = (e, value) => {
    e.stopPropagation();
    setIsCustomer(true);
    setCustomerObj(value);
  };
  const HandleShare = (e, value) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const [delTagData, setDelTagData] = useState({});
  const [showDeleteTag, setShowDeleteTag] = useState(false);

  const changeTagEventWo = (key, value) => {
    setTagItnpObjWo({ ...tagItnpObjWo, [key]: value });
  };
  const handleTagClose = () => {
    setIsTagOpen(false);
    setTagItnpObjWo({ name: "" });
  };

  const HandleAttch = async (e, value) => {
    saveRecentlyViewedItems(value.workorder_id, value);
    e.stopPropagation();
    let attchId = value.id;
    // let custId = value.customer_id;
    let WorkId = value.workorder_id;
    let eqId = value.equipment_id;
    let techId = value.technician_id;
    let step = value.workorder_step_id;
    let workflow = value.workorder_workflow_id;
    const response = await GetAttchMentByID(
      attchId,
      step,
      workflow,
      custId,
      WorkId,
      eqId,
      techId
    );
    if (response.success) {
    }
  };
  const [evalue, setEValue] = useState(0);
  const [tagitem, setTagitem] = useState({});
  const [custWorkid, setCustWorkId] = useState(null);
  const AddTagModal = (e, item) => {
    setTagitem(item);
    setIsTagOpen(true);
    if (e === 10) {
      setEValue(e);
      setCustWorkId(item?.customer_id);
    } else {
      setEValue(ReferenceId?.work_order);
      setCustWorkId(item?.WorkOrderId);
    }
  };
  // work_order_id
  const getModalHeader = () => {
    if (tagitem?.WorkOrderId) {
      return `Work Order #` + tagitem?.WorkOrderId;
    } else if (tagitem?.customer_id) {
      return `Customer #` + tagitem?.customer_id;
    } else if (tagitem?.reference_id) {
      return `Customer #` + tagitem?.reference_id;
    } else {
      return "No ID Available";
    }
  };
  const getModalHeadername = () => {
    if (tagitem?.WorkOrderId) {
      return + tagitem?.WorkOrderId;
    } else if (tagitem?.customer_id) {
      return + tagitem?.customer_id;
    } else if (tagitem?.reference_id) {
      return + tagitem?.reference_id;
    } else {
      return "No ID Available";
    }
  };
  const [tagItnpObjWo, setTagItnpObjWo] = useState({
    name: "",
    tag_id: 0,
    color: "#CCCCCC",
    referenceType: evalue === 10 ? evalue : ReferenceId?.work_order,
    uuid: "",
  });

  const [isExpand, setIsExpand] = useState("");
  const [rtExpand, setRtExpand] = useState("");
  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  const HandleRtExpand = (val) => {
    setRtExpand(val);
  };
  const [custTagId, setCustTagId] = useState(0);
  const HandleTagSubmit = async () => {
    let newObj = {
      name: tagItnpObjWo.name,
      tag_id: 0,
      color: tagItnpObjWo?.color,
      referenceType: evalue,
      referenceId: custWorkid,
      work_order_id: 0,
      uuid_id: tagItnpObjWo?.uuid,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newObj);
    setTagitem(null);
    setEValue(null);
    if (Res?.success) {
      setEValue(null);
      setIsTagOpen(false);

      if (evalue !== 10) {
        let newOne = {
          referenceType: Res?.data?.tag?.reference_type,
          color: Res?.data?.tag?.color,
          id: Res?.data?.tag?.tag_id,
          name: Res?.data?.tag?.tag_data,
        };

        let newData1 = prevCustWO?.filter(
          (i) => i?.WorkOrderId === tagitem?.WorkOrderId
        );
        newData1[0].tags = [...newData1[0].tags, newOne];
      } else {
        setTags([...tags, Res?.data?.tag]);
      }

      setTagItnpObjWo({
        name: "",
        color: "#CCCCCC",
        referenceType: ReferenceType.CUSTOMER,
        uuid: "",
      });
    }
    //  else {
    //   let newObj = {
    //     tag_data: tagItnpObjWo?.name,
    //     tag_id: 0,
    //     color: tagItnpObjWo?.color,
    //     referencetype: tagItnpObjWo.referenceType,
    //     referenceId: tagitem?.WorkOrderId,
    //     work_order_id: 0,
    //     uuid_id: tagItnpObjWo?.uuid,
    //     sponsor_id: 0,
    //     edit_allowed: 0,
    //   };
    //   let Res = await addTags(newObj);
    //   if (Res?.success) {
    //     setEValue(null);
    //     setIsTagOpen(false);
    //     setEqpWotag(Res?.data?.tag);
    //     let newOne = {
    //       referenceType: Res?.data?.tag?.reference_type,
    //       color: Res?.data?.tag?.color,
    //       id: Res?.data?.tag?.tag_id,
    //       name: Res?.data?.tag?.tag_data,
    //     };
    //     let newData1 = prevCustWO?.filter(
    //       (i) => i?.WorkOrderId === tagitem?.WorkOrderId
    //     );
    //     newData1[0].tags = [...newData1[0].tags, newOne];
    //     setTagItnpObjWo({
    //       name: "",
    //       color: "#CCCCCC",
    //       referenceType: ReferenceType.CUSTOMER,
    //       uuid: "",
    //     });
    //   }
    // }
  };
  // const HandleNoteModal = (val) => {
  //   setNoteInpObj({});
  //   setNoteItem(val);
  //   setWoWfId(val.work_order_workflow_id);
  //   setWoSId(val.workorder_step_id);
  //   setIsNotesAttach(true);
  // };

  const [tags, setTags] = useState([]);
  const handleDeleteTag = (it, tag, item) => {
    setTagitem(item ? item : tag);
    it?.stopPropagation();
    setDelTagData(tag);
    setShowDeleteTag(true);
  };
  const confirmDeleteTag = async (deleteTag) => {
    const response = await deleteTags(
      delTagData?.tag_id ? delTagData?.tag_id : delTagData?.id
    );
    if (response?.success === true) {
      setShowDeleteTag(false);
      setTags(tags.filter((tag) => tag?.tag_id !== deleteTag?.tag_id));

      let newData1 = prevCustWO?.filter(
        (i) => i?.WorkOrderId === tagitem?.WorkOrderId
      );
      newData1[0].tags = newData1[0]?.tags?.filter(
        (item) => item?.id !== deleteTag?.id
      );
      // let newData1 = prevCustWO?.filter(
      //   (i) => i?.WorkOrderId === tagitem?.WorkOrderId
      // );
      // newData1[0].tags = newData1[0]?.tags?.filter(
      //   (item) => item?.id !== deleteTag?.id
      // );

      // setTags(tags.filter((tag) => tag?.tag_id !== deleteTag?.tag_id));
    }
    setDelTagData(null);
    setTagitem(null);
  };
  const HandleDeleteCustomer = async () => {
    try {
      let customerId = location?.state?.key;

      if (!customerId) {
        alert("Customer ID not found!");
        return;
      }
      const response = await CustomerDelete(customerId);

      if (response?.data?.message === "Customer has existing work orders and cannot be deleted.") {
        alert("Customer has existing work orders and cannot be deleted.")

      }
      else {
        alert("Customer Deleted Successfully.");
        setDelWOmodal(false);
        navigate("/Customers");
      }
    } catch (error) {
      alert("An error occurred while trying to delete the customer.");
    }
  };
  const handleCloseModal = () => {
    setIsOpen2(false);
    setCarouselIndex(0);
  };
  const [getmemory, setgetmemory] = useState("");
  const [mrydlt, setMrydlt] = useState(null);
  const descvalue = getmemory?.note;
  const [mryblue, setMryblue] = useState(false);
  const [memobj, setMemobj] = useState({});
  const [itemkey, setItemkey] = useState("");

  const HandleMemory = async (type, e, item) => {
    e.stopPropagation();
    if (type === "black") {
      setIsMemory(true);
      setIsMemoryObj(item);
      e.stopPropagation();
      setIsMemory(true);
      let items = { viewtype: 10, Reftype: 10, RefId: item?.customer_id };
      setIsMemoryObj(items);
    } else {
      setMryblue(true);
      setItemkey(type);
      let items = { viewtype: 10, Reftype: 10, RefId: item?.customer_id };
      setIsMemoryObj(items);
      setMemobj(item);
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
      );
      if (Result?.success) {
      }
      setgetmemory(Result?.data);
      setMrydlt(Result?.data?.recent_view_id);
    }
  };
  const updateMemoryBoardStatus = (workOrderId) => {
    custdet.memoryBoard = workOrderId;
  };
  // let shareid = window?.location?.href?.split("/")[10]
  let techId = JSON.parse(localStorage.getItem("techpin"))
  let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));
  const handleClick = () => {
    const mailtoLink = `mailto:${custdet?.email}?subject=&body=`;
    window.location.href = mailtoLink;
  };
  return (
    <>
      <div
      //  className={+shareid === 1 ? "wod-opcity07" : ""}
      // style={{
      //   padding: "0px 0px 0px 0px",
      //   overflowY: "auto",
      //   overflowX: "hidden",
      //   height: "100vh",
      //   // maxHeight: "calc(100vh - 80px)",
      // }}
      >
        <div className="head-sticky">
          {" "}
          <CommonHeader />
          <div className="cnc-inp-main ">
            <div className="line-align-bet  mt-2 w-100 ">
              <div className="d-flex mx-2">
                {/* <img
                  src={leftAr}
                  alt=""
                  className="cnc-leftAr"
                  onClick={() => handleLeftArrow()}
                /> */}
                <img src={Acclmg} alt="" className="cnc-prfl " />
                {/* <span className=" cnc-cust-span  ">
                  {NEW_CUSTOMER.INPUT_CUST_NAME}{" "}
                </span> */}
                {isCustVal === "edit" ? (
                  <div>
                    <span className="cust-name-head">
                      {userInputs.customer_name}
                    </span>
                    <img src={Dail} alt="dail" className="dail-icon-cust" />
                    <img
                      src={videoCall}
                      alt="video"
                      className="dail-icon-cust"
                    />
                    <img
                      onClick={handleClick}
                      src={MailIcon}
                      alt="video"
                      className="dail-icon-cust"
                    />
                    <img
                      onClick={(e) => {
                        if (custdet?.latitude && custdet?.longitude) {
                          window.open(
                            "https://maps.google.com?q=" +
                            custdet?.latitude +
                            "," +
                            custdet?.longitude
                          );
                        }
                        e.stopPropagation();
                      }}
                      style={{
                        opacity:
                          custdet?.latitude && custdet?.longitude ? 1 : 0.5,
                        cursor:
                          custdet?.latitude && custdet?.longitude
                            ? "pointer"
                            : "not-allowed",
                      }}
                      src={locationIcon}
                      alt="location"
                      className="dail-icon-cust"
                    />
                  </div>
                ) : isCustVal === "create" ? (
                  <div>
                    <span className="cust-name-head">
                      {userInputs.customer_name}
                    </span>
                    {/* <span className="edit-span">Editing</span> */}
                  </div>
                ) : (
                  <div className="inp-cust-div">
                    <input
                      className="inp-newcust-create"
                      value={userInputs.customer_name}
                      placeholder="Input Customer Name"
                    />
                    {/* <span className="edit-span">Creating</span> */}
                  </div>
                )}
              </div>

              <div className="d-flex mx-2" style={{ alignItems: "center" }}>
                {isCustVal !== "create" ? (
                  <div className=" d-flex align-items-center"
                    style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                  >
                    {/* <div className="mt-1 mx-2">
                    <div
                      className="btn-T-Tag cursor-p"
                      onClick={() => AddTagModal()}
                    >
                      {CUST_PRTL.TAG}
                      <img src={plus} alt="btn" />
                    </div>
                  </div> */}
                    {custdet?.memoryBoard === 1 ? (

                      <img
                        src={VectorEqpBlue}
                        alt="Memory"
                        width={22}
                        className="mx-2 cursor-p"
                        onClick={(e) =>
                          HandleMemory("customersview", e, custdet)
                        }
                      />
                    ) : (
                      <img
                        src={MemoryBoard}
                        alt="Memory"
                        width={22}
                        className="mx-2 cursor-p"
                        onClick={(e) => HandleMemory("black", e, custdet)}
                      />
                    )}
                    <img
                      src={share}
                      alt="share"
                      className="share-cust ml-2"
                      onClick={(e) => handleCustShare(e)}
                    />
                    <img
                      src={deleteIcon}
                      alt="delete"
                      className="del-cust ml-2"
                      onClick={() => HandleDelWOmodal()}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex align-items-center mx-1">
                  {isCustVal !== "edit" && (
                    <div
                      className="cnc-discard-btn-styles"
                      onClick={handleDiscard}
                    >
                      {NEW_CUSTOMER.DISCARD}
                      <img
                        src={delW}
                        alt="delete"
                        className="cust-discard ml-1"
                      />
                    </div>
                  )}
                  {isCustVal !== "create" && isCustVal === "edit" ? (
                    <div
                      className="cnc-discard-btn-styles mx-1"
                      style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                      onClick={() => handleAlert2("AddEdit")}
                    >
                      {NEW_CUSTOMER.EDIT}
                      <img
                        src={pen}
                        alt="edit"
                        className="cust-edit ml-1"
                        style={{ filter: "white(100%)" }}
                      />
                    </div>
                  ) : (
                    <div
                      className="cnc-discard-btn-styles mx-1"
                      onClick={handleAlert}
                    >
                      {NEW_CUSTOMER.SAVE}
                      <img
                        src={Save}
                        alt="save"
                        className="cust-discard ml-1"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <CustomerTabs
            scrollCallback={scrollToDiv}
            HandleColor={location?.state?.component}
          /> */}
        </div>
        <Row>
          {isExpand === "expand" ? (
            " "
          ) : (
            <Col
              lg={
                isExpand === "expand" && rtExpand === "rt-expand"
                  ? 0
                  : rtExpand === "rt-expand"
                    ? 2
                    : isExpand === "expand"
                      ? 0
                      : 2
              }
            >
              <CNCSideBar scrollCallback={scrollToDiv} />
            </Col>
          )}
          <Col
            lg={
              isExpand === "expand" && rtExpand === "rt-expand"
                ? 12
                : isExpand === "expand"
                  ? 9
                  : rtExpand === "rt-expand"
                    ? 10
                    : 7
            }
          >
            <Card className="mt-2">
              {isExpand === "expand" ? (
                <span
                  className={
                    isExpand === "expand"
                      ? "backbtn-hvr2 "
                      : "backbtn-hvr p-0 m-0"
                  }
                >
                  <MdChevronRight
                    className="arro-right-icon "
                    size={25}
                    onClick={() => HandleExpand("")}
                  />
                </span>
              ) : (
                <span
                // className={
                //   isExpand === "expand" ? "backbtn-hvr2 " : "backbtn-hvr p-0 m-0"
                // }
                >
                  <MdChevronLeft
                    size={25}
                    onClick={() => HandleExpand("expand")}
                    className="cnc-leftbar-leftarrow "
                  />
                </span>
              )}
              {rtExpand === "rt-expand" ? (
                <MdChevronLeft
                  size={25}
                  className="wod-expand-icon-right mx-2 "
                  onClick={() => HandleRtExpand("")}
                />
              ) : (
                <MdChevronRight
                  size={25}
                  className="wod-expand-icon-img-right "
                  onClick={() => HandleRtExpand("rt-expand")}
                />
              )}

              {isProcess ? (
                <div className="pro-bar-style">
                  <ProgressBar />
                </div>
              ) : (
                <div style={{ overflow: "auto", height: "80vh" }}>
                  <div
                    ref={(ref) => (contentRefs.current[0] = ref)}
                    data-index={0}
                  ></div>
                  {isCustVal === "create" || isCustVal === "AddEdit" ? (
                    <div>
                      <div className="m-2">
                        {" "}
                        <img
                          src={details}
                          alt=""
                          className="contacts-img"
                          onClick={() => HandleInfoTgl()}
                        />
                        <span
                          className="cust-span "
                          onClick={() => HandleInfoTgl()}
                        >
                          {" "}
                          {NEW_CUSTOMER.CUSTOMER_DETAILS}
                        </span>
                        {infoTgl ? (
                          <div>
                            <div className="input-column ">
                              <span className="cnc-input-text">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_NAME}
                              </span>
                              <input
                                type="text"
                                className={
                                  error.length > 0 &&
                                    userInputs?.customer_name?.length <= 0
                                    ? "input-cnc-cust border-danger inpt-hover"
                                    : "input-cnc-cust card_hovering"
                                }
                                //  className="input-cnc-cust card_hovering"
                                name="customer_name"
                                value={userInputs.customer_name}
                                onChange={AddCustomerEquipment}
                                placeholder="Please enter customer name"
                              />
                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "customer_name" &&
                                   userInputs?.customer_name?.length <= 0 &&
                                   `Please Enter ${err.message}`}
                               </div>
                             ))}
                           </div> */}
                            </div>
                            <div className="input-column ">
                              <span className="cnc-input-text ">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_TELEPHONE}
                              </span>
                              <input
                                type="input"
                                className={
                                  error.length > 0 &&
                                    ((userInputs?.telephone?.length <= 0) || (validateTelephone(userInputs.telephone)))
                                    ? "input-cnc-cust-num border-danger inpt-hover"
                                    : "input-cnc-cust-num card_hovering"
                                }
                                //  className="input-cnc-cust-num card_hovering"
                                name="telephone"
                                value={userInputs.telephone}
                                onChange={AddCustomerEquipment}
                                placeholder="Please enter the phone number"
                              />

                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "telephone" &&
                                   `Please Enter Valid ${err.message}`}
                               </div>
                             ))}
                           </div>  */}
                            </div>
                            <div className="input-column  w-90">
                              <span className="cnc-input-text">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_EMAIL}
                              </span>
                              <input
                                type="text"
                                className={
                                  error.length > 0 &&
                                    ((userInputs?.email?.length <= 0) || (validateEmail(userInputs.email)))
                                    ? "input-cnc-cust border-danger inpt-hover"
                                    : "input-cnc-cust card_hovering"
                                }
                                //  className="input-cnc-cust card_hovering"
                                name="email"
                                value={userInputs.email}
                                onChange={AddCustomerEquipment}
                                placeholder="Please enter email"
                              />
                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "email" &&
                                   `Please Enter Valid ${err.message}`}
                               </div>
                             ))}
                           </div> */}
                            </div>
                            <div className="input-column">
                              <span className="cnc-input-text">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_ADDRESS}
                              </span>
                              <div>
                                <input
                                  type="text"
                                  className={
                                    error.length > 0 &&
                                      userInputs?.address?.length <= 0
                                      ? "input-cnc-cust border-danger inpt-hover"
                                      : "input-cnc-cust card_hovering"
                                  }
                                  //  className="input-cnc-cust card_hovering"
                                  name="address"
                                  value={userInputs.address}
                                  onChange={AddCustomerEquipment}
                                  placeholder="Please enter address"
                                />
                                <img
                                  src={locationIcon}
                                  alt="location"
                                  className="adress-loc"
                                />
                              </div>
                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "address" &&
                                   userInputs?.address?.length <= 0 &&
                                   `Please Enter ${err.message}`}
                               </div>
                             ))}
                           </div> */}
                            </div>
                            <div className="input-column">
                              <span className="cnc-input-text">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_CITY}
                              </span>
                              <div>
                                <input
                                  type="text"
                                  className={
                                    error.length > 0 &&
                                      userInputs?.city?.length <= 0
                                      ? "input-cnc-cust border-danger inpt-hover"
                                      : "input-cnc-cust card_hovering"
                                  }
                                  //  className="input-cnc-cust card_hovering"
                                  name="city"
                                  value={userInputs.city}
                                  onChange={AddCustomerEquipment}
                                  placeholder="Please enter city"
                                />
                                <img
                                  src={locationIcon}
                                  alt="location"
                                  className="adress-loc"
                                />
                              </div>
                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "city" &&
                                   userInputs?.city?.length <= 0 &&
                                   `Please Enter ${err.message}`}
                               </div>
                             ))}
                           </div> */}
                            </div>
                            <div className="input-column">
                              <span className="cnc-input-text">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_STATE}
                              </span>
                              <div>
                                <input
                                  type="text"
                                  className={
                                    error.length > 0 &&
                                      userInputs?.state?.length <= 0
                                      ? "input-cnc-cust border-danger inpt-hover"
                                      : "input-cnc-cust card_hovering"
                                  }
                                  //  className="input-cnc-cust card_hovering"
                                  name="state"
                                  value={userInputs.state}
                                  onChange={AddCustomerEquipment}
                                  placeholder="Please enter state"
                                />
                                <img
                                  src={locationIcon}
                                  alt="location"
                                  className="adress-loc"
                                />
                              </div>
                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "state" &&
                                   userInputs?.state?.length <= 0 &&
                                   `Please Enter ${err.message}`}
                               </div>
                             ))}
                           </div> */}
                            </div>
                            <div className="input-column">
                              <span className="cnc-input-text">
                                <span className="star-styles-cnc ">*</span>
                                {NEW_CUSTOMER.CUST_ZIP}
                              </span>
                              <div>
                                <input
                                  type="text"
                                  className={
                                    error.length > 0 &&
                                      userInputs?.zip?.length <= 0
                                      ? "input-cnc-cust border-danger inpt-hover"
                                      : "input-cnc-cust card_hovering"
                                  }
                                  //  className="input-cnc-cust card_hovering"
                                  name="zip"
                                  value={userInputs.zip}
                                  onChange={AddCustomerEquipment}
                                  placeholder="Please enter zip"
                                />
                                <img
                                  src={locationIcon}
                                  alt="location"
                                  className="adress-loc"
                                />
                              </div>
                              {/* <div>
                             {error?.map((err) => (
                               <div className="err-warnings">
                                 {err?.message === "zip" &&
                                   userInputs?.zip?.length <= 0 &&
                                   `Please Enter ${err.message}`}
                               </div>
                             ))}
                           </div> */}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2 ml-2">
                      <img
                        src={Custinfo}
                        alt=""
                        className="contacts-img"
                        onClick={() => HandleInfoTgl()}
                      />
                      <span
                        className="cust-span "
                        onClick={() => HandleInfoTgl()}
                      >
                        {" "}
                        {NEW_CUSTOMER.CUSTOMER_DETAILS}
                      </span>
                      {infoTgl ? (
                        <div className="wo-edit-card-div ">
                          <div className="wo-edit-nameemial-div ">
                            <span className="cust-edit-name mt-2">
                              {userInputs?.customer_name}
                            </span>
                            <span className="cust-edit-name mt-3">
                              {userInputs?.address}
                            </span>
                          </div>
                          <div className="wo-edit-workdate-div mt-3">
                            <span className="cnc-edit-name ">
                              {userInputs?.telephone}
                            </span>
                            <span className="cnc-edit-name mt-3">
                              {userInputs?.email}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {infoTgl && (
                    <>
                      {isCustVal !== "create" && (
                        <div className="mt-1 mx-2 d-flex justify-content-end">
                          <div
                            className="btn-T-Tag cursor-p"
                            style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                            onClick={() =>
                              AddTagModal(ReferenceId?.customer, custdet)
                            }
                          >
                            {CUST_PRTL.TAG}
                            <img src={plus} alt="btn" />
                          </div>
                        </div>
                      )}
                      <div className="mt-1 d-flex flex-wrap">
                        {tags?.map((tag) => (
                          <span
                            key={tag?.tag_data}
                            style={{
                              pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto",
                              backgroundColor: "#CCCCCC",
                              fontSize: "12px",
                              color: "black",
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                              display: "inline-block",
                              maxWidth: "100%",
                            }}
                            className="tag-styles cursor-p"
                            onClick={(e) => handleDeleteTag(e, tag)}
                          >
                            {tag.tag_data}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                  <div
                    ref={(ref) => (contentRefs.current[1] = ref)}
                    data-index={1}
                    className="cnc-content"
                  ></div>

                  <div className=" communication-cust">
                    <WoDetailsCommunication />
                  </div>
                  <div
                    ref={(ref) => (contentRefs.current[2] = ref)}
                    data-index={2}
                    className="cnc-content"
                  ></div>
                  <div>
                    <div className="m-2">
                      <img
                        src={WorkOrderIcon}
                        alt=""
                        className="contacts-img"
                        onClick={() => HandleWOTgl()}
                      />
                      <span
                        className="cust-span contacts-span  mt-2 "
                        onClick={() => HandleWOTgl()}
                      >
                        {NEW_CUSTOMER.W_O}{" "}
                        {"(" + (prevCustWO?.length || 0) + ")"}
                      </span>

                      <div className=" cursor-p">
                        {woTgl ? (
                          <div>
                            {prevCustWO?.length > 0 ? (
                              prevCustWO?.map((item) => (
                                <WorkOrderCard
                                  key={item.id} // Ensure each item has a unique id
                                  eachWorkOrder={item}
                                  techId={techId}
                                  techPin={techId}
                                  companyId={CompanyId}
                                  navToWoDetls={navToWoDetls}
                                  HandleCutomer={HandleCutomer}
                                  HandleShare={HandleShare}
                                  handleDeleteModal={handleDeleteTag}
                                  HandleAttch={HandleAttch}
                                  HandleAddtag={AddTagModal}
                                />
                              ))
                            ) : (
                              <span className="wo-tgl-text">
                                {NEW_CUSTOMER.W_O_TOGGLE_TEXT}
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    ref={(ref) => (contentRefs.current[3] = ref)}
                    data-index={3}
                    className="cnc-content"
                  ></div>
                  <div className="mx-1">
                    <Equipments
                      Alleqpdata={custEquipments}
                      custid={custId}
                      isWoid={0}
                      custkey={"custkey"}
                      techid={JSON.parse(localStorage.getItem("techpin"))}
                      techid1={JSON.parse(localStorage.getItem("techpin"))}
                      compid={JSON.parse(localStorage.getItem("CompanyCode"))}
                    />
                  </div>
                  <div
                    ref={(ref) => (contentRefs.current[4] = ref)}
                    data-index={4}
                    className="cnc-content mt-2 mx-1"
                  >
                    <MediaCenter
                      custId={custId}
                      attachments={attachments}
                    />
                  </div>
                  <div
                    ref={(ref) => (contentRefs.current[5] = ref)}
                    data-index={5}
                    className="cnc-content"
                  ></div>
                  <div>
                    <div className="m-2">
                      <img src={Logs} alt="" className="contacts-img" />
                      <span className="cust-span contacts-span">
                        {" "}
                        {NEW_CUSTOMER.LOGS}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </Col>
          {rtExpand === "rt-expand" ? (
            ""
          ) : (
            <Col
              style={{ width: "25%" }}
              lg={
                rtExpand === "rt-expand" && isExpand === "expand"
                  ? 0
                  : rtExpand === "rt-expand"
                    ? 0
                    : isExpand === "expand"
                      ? 2
                      : 0
              }
            >
              <CustomerRightSideBar
                data={custNotes}
                custDetails={custDetails}
                custId={custId}
                isCustVal={isCustVal}
                custTagId={custTagId}
                // additionalFields={additionalFields}
                CustData={userInputs}
                extraFieldComponent={
                  <CustExtraFields
                    isView={isView2}
                    isCustVal={isCustVal}
                    setUserInputs={setUserInputs}
                    UpdateList={UpdateList}
                    addFields={addFields}
                    addfldsInput={addfldsInput}
                    error={error}
                  // setcreatingFields={setcreatingFields}
                  // isFinalSave={addlnFlds}
                  // creatingFields={creatingFields}
                  // additionalFields={additionalFields}
                  // setobjectValues={setobjectValues}
                  // ErrorData={extraErr?.length > 0 ? extraErr : []}
                  // objectValues={objectValues}
                  />
                }
              />
            </Col>
          )}
        </Row>
      </div>
      {/* <CommonFooter /> */}
      <Alerts isOpen={isModal} isClose={() => setIsModal(false)} data={error} />
      <CustomerModal
        Open={isCustomer}
        Close={() => setIsCustomer(false)}
        data={customerObj}
        contactname={customerObj?.contact?.name}
        note={customerObj?.contact?.note}
        custId={customerObj?.Customer?.id}
      />
      {/* <ShareModal
        Open={isOpen}
        Close={() => setIsOpen(false)}
        data={workOrderItem}
      /> */}

      <AttachmentModal
        showModal={isOpen2}
        handleCloseModal={handleCloseModal}
        selectedImageIndex={carouselIndex}
        handleCarouselSelect={(index) => setCarouselIndex(index)}
        eachWorkOrder={attachments}
      />
      <AddTag
        Open={isTagOpen}
        // name={getModalHeadername()}
        color={tagItnpObjWo.color}
        workOrderId={getModalHeader()}
        HandleTagInputs={changeTagEventWo}
        isClose={() => handleTagClose()}
        isSubmit={() => HandleTagSubmit()}
      />
      <MemoryblueModal
        Open={mryblue}
        Close={() => setMryblue(false)}
        ismemoryObj={ismemoryObj}
        memobj={memobj}
        mrydlt={mrydlt}
        itemkey={itemkey}
        custaddrs={`${memobj?.city} ${memobj?.address} ${memobj?.address_2} ${memobj?.country} ${memobj?.zip}`}
        custname={memobj?.name}
        custId={memobj?.customer_id}
        descvalue={descvalue}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        headname={memobj?.name}
        headid={memobj?.customer_id}
      />
      <DeleteTags
        headid={getModalHeadername()}
        name={tagitem?.WorkOrderId ? "Work Order" : "Customer"}
        deleteTag={() => confirmDeleteTag(delTagData)}
        handleClose={() => setShowDeleteTag(false)}
        data={{
          name: delTagData?.tag_data ? delTagData?.tag_data : delTagData?.name,
        }}
        isOpen={showDeleteTag}
      />
      <MemoryModal
        isOpen={isMemory}
        isClose={() => HandleCloseMemory()}
        id={ismemoryObj?.RefId}
        ismemoryObj={ismemoryObj}
        name={"Customer"}
        woId={0}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
      />
      <DeleteWOModal
        Open={delWOmodal}
        data={DEL_W_O.DEL_MODAL_BODY}
        custId={"#" + custId}
        name={DEL_W_O.DEL_CUSTR}
        HandleDeleteCustomer={HandleDeleteCustomer}
        Close={() => setDelWOmodal(false)}
      />
      <CustShareModal
        qrcustid={qrcustid}
        isOpen={isOpen}
        isClose={() => setIsOpen(false)}
        techncian={technician}
      />
      {/* <ShareWOModal Open={shareWOModal} Close={() => setShareWOModal(false)} /> */}

      {/* <NoteAddModal
        isOpen={isNotesAttach}
        isClose={() => setIsNotesAttach(false)}
        WorkId={props.WorkNo}
        noteTitle={noteInpObj.title}
        noteDescription={noteInpObj.note}
        HandleInputs={ChangeEvents}
        isSubmit={() => HandleSubmit(NoteItem)}
        isLoading={isNote}
      /> */}
    </>
  );
}
export default CreateNewcustomer;
