import React from 'react';
import './LoginFooter.css'; 
import { COMPANY_DETAILS } from "../../components/Constants";

function LoginFooter() {
  return (
    <div className="loginfooter">
    <div class="footerstyle">
    <span className="powered-text">{COMPANY_DETAILS.LOGIN_FOOTER}</span>
  </div>
  </div>
  );
}

export default LoginFooter;