import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { MOD_TEXT_FIELD_SETUP } from "../../../../common/components/Values";

function AlertFldStp(props) {
  return (
    <div>
      {" "}
      <Modal show={props.isOpen} onHide={props.isClose} centered size="sm">
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="attach-media-note line-align-bet">
            <div style={{ color: "white" }}> Error Messages</div>
            <img
              src={close}
              alt="Close"
              className="modal-header-icon"
              onClick={props.isClose}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 py-0 hide-scroll ">
          {
            // props?.isDupErr === true ? (
            //   <span className="err-mesge  cntr-hr-line ml-1 ">
            //     Diplicates are exists
            //   </span>
            // ) :
            // props.isFinalSave ? (
            //   <span className="err-mesge  cntr-hr-line ml-1 ">
            //     {" "}
            //     {MOD_TEXT_FIELD_SETUP.ETR}
            //   </span>
            // ) : (
            //   <span className="err-mesge  cntr-hr-line  ml-1">
            //     {MOD_TEXT_FIELD_SETUP.TXT_DESC}
            //   </span>
            // )
          }

          <div className="err-mod-htnr">
            <span className="ml-1">
              {(props?.isDup?.work_order_extra_fields?.length > 0 ||
                ((props.isFinalSave ||
                  props?.errorKey === "work_order_extra_fields") &&
                  Object.keys(props?.data?.work_order_extra_fields)?.length >
                    0)) && (
                <>
                  <span className="err-txt-mod">
                    {" "}
                    {MOD_TEXT_FIELD_SETUP.WRK_ODR_ADD_FLDS}:
                  </span>
                  <div>
                    {props?.isDup?.work_order_extra_fields?.length > 0 &&
                      props?.isDup?.work_order_extra_fields?.map((i) => (
                        <span className="err-mesge-small">{`${i?.packcolumn} is already exist`}</span>
                      ))}
                  </div>
                  {Object.keys(props?.data?.work_order_extra_fields)?.map(
                    (i) => {
                      return (
                        <div>
                          <span className="err-txt mx-2">
                            {" "}
                            Check in "{MOD_TEXT_FIELD_SETUP.ODR_ID}: {i}"
                          </span>
                          <ul className="mb-0">
                            {props?.data?.work_order_extra_fields[i]?.map(
                              (j) => {
                                return (
                                  <li className="err-mesge-small">
                                    '{j}' is missing
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </span>

            <span className="ml-1">
              {(props.isFinalSave ||
                props?.errorKey === "contact_extra_fields") &&
                Object.keys(props?.data?.contact_extra_fields)?.length > 0 && (
                  <>
                    <span className="err-txt-mod">
                      {MOD_TEXT_FIELD_SETUP.CONT_ADD_FLDS}:
                    </span>
                    {Object.keys(props?.data?.contact_extra_fields)?.map(
                      (i) => {
                        return (
                          <div className="">
                            <span className="err-txt mx-2">
                              Check In "{MOD_TEXT_FIELD_SETUP.ODR_ID}: {i}"{" "}
                            </span>
                            <ul className="mb-0">
                              {props?.data?.contact_extra_fields[i]?.map(
                                (j) => {
                                  return (
                                    <li className="err-mesge-small">
                                      '{j}' is missing
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        );
                      }
                    )}
                  </>
                )}
            </span>
            {/* wo_extra_interface */}
            {/*
            <span className="ml-1">
              {(props?.isDup?.wo_extra_interface?.length > 0 ||
                ((props.isFinalSave ||
                  props?.errorKey === "wo_extra_interface") &&
                  Object.keys(props?.data?.wo_extra_interface)?.length >
                    0)) && (
                <>
                  <span className="err-txt-mod">Work Order fields:</span>
                  <div>
                    {props?.isDup?.wo_extra_interface?.length > 0 &&
                      props?.isDup?.wo_extra_interface?.map((i) => (
                        <span className="err-mesge-small">{`${i?.name} is already exist`}</span>
                      ))}
                  </div>
                  {Object.keys(props?.data?.wo_extra_interface)?.map((i) => {
                    return (
                      <div className="">
                        <span className="err-txt mx-2">
                          Check In "{MOD_TEXT_FIELD_SETUP.ODR_ID}: {i}"{" "}
                        </span>
                        <ul className="mb-0">
                          {props?.data?.wo_extra_interface[i]?.map((j) => {
                            return (
                              <li className="err-mesge-small">
                                '{j}' is missing
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </>
              )}
            </span> */}

            <span className="ml-1">
              {(props?.isDup?.customer_extra_fields?.length > 0 ||
                ((props.isFinalSave ||
                  props?.errorKey === "customer_extra_fields") &&
                  Object.keys(props?.data?.customer_extra_fields)?.length >
                    0)) && (
                <>
                  <span className="err-txt-mod">
                    {MOD_TEXT_FIELD_SETUP.CUST_ADD_FLDS}
                  </span>
                  <div>
                    {props?.isDup?.customer_extra_fields?.length > 0 &&
                      props?.isDup?.customer_extra_fields?.map((i) => (
                        <span className="err-mesge-small">{`${i?.packcolumn} is already exist`}</span>
                      ))}
                  </div>
                  {Object.keys(props?.data?.customer_extra_fields)?.map((i) => {
                    return (
                      <div>
                        <span className="err-txt mx-2">
                          Check In "{MOD_TEXT_FIELD_SETUP.ODR_ID} {i}"{" "}
                        </span>
                        <ul className="mb-0">
                          {props?.data?.customer_extra_fields[i]?.map((j) => {
                            return (
                              <li className="err-mesge-small">
                                '{j}' is missing
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </>
              )}
            </span>

            <span className="ml-1">
              {(props?.isDup?.equipment_extra_fields?.length > 0 ||
                ((props?.isFinalSave ||
                  props?.errorKey === "equipment_extra_fields") &&
                  Object.keys(props?.data?.equipment_extra_fields)?.length >
                    0)) && (
                <>
                  <span className="err-txt-mod">
                    {MOD_TEXT_FIELD_SETUP.EQUP_ADD_FLDS}
                  </span>
                  <div>
                    {props?.isDup?.equipment_extra_fields?.length > 0 &&
                      props?.isDup?.equipment_extra_fields?.map((i) => (
                        <span className="err-mesge-small">{`${i?.packcolumn} is already exist`}</span>
                      ))}
                  </div>
                  {Object.keys(props?.data?.equipment_extra_fields)?.map(
                    (i) => {
                      return (
                        <div>
                          <span className="err-txt mx-2">
                            Check In {MOD_TEXT_FIELD_SETUP.ODR_ID} {i}{" "}
                          </span>
                          <ul className="mb-0">
                            {props?.data?.equipment_extra_fields[i]?.map(
                              (j) => {
                                return (
                                  <li className="err-mesge-small">
                                    '{j}' is missing
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AlertFldStp;
