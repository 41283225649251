import React, { useContext, useState } from "react";
import "./WorkFlow.css";
import CrtEqup from "../../../common/Assets/Images/VectorEqp.svg";
import EdtEqup from "../../../common/Assets/Images/editeqpbtn.png";
import memoryIcon from "../../../common/Assets/Icons/Memory Board Icon.svg";
import memoryIconBlue from "../../../common/Assets/Images/VectorEqpBlue.svg";
import plus from "../../../common/Assets/Icons/plusS.svg";

import AddTag from "../addtag/AddTag";
import {
  addTags,
  deleteTags,
  GetMemory,
  getWorkOrderById,
} from "../../../services/WorkOrdersService";
import {
  AppGlobalContext,
  AppGlobalContextUpdate,
} from "../../../common/context/AppGlobalContext";
import DeleteTags from "../addtag/DeleteTags";
import { WORK_ORDER_CONSTANTS } from "../../../common/components/Constants";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import { ReferenceId } from "../../../common/components/EnumRefType";
import MemoryblueModal from "../../../common/components/memorymodal/MemoryblueModal";
import MemoryModal from "../../../common/components/memorymodal/MemoryModal";

export default function Category(props) {
  const [wfTags, setWfTags] = useState(props?.data?.tags || []);
  const wflws = props?.data
  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.work_order_work_flow,
  });
  const appState = useContext(AppGlobalContext);
  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };
  const [isTagOpen, setIsTagOpen] = useState(false);
  const closeAddTags = () => {
    setIsTagOpen(false);
  };
  const [getmemory, setgetmemory] = useState("");
  const descvalue = getmemory?.note;
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [mryblue,setMryblue] = useState(false)
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [memobj,setMemobj] = useState({})
  const [deleteTagid, setDeleteTagid] = useState(null);
  const [itemkey,setItemkey] = useState("")
  const [ismemProgress, setIsMemProgress] = useState(false);
  const [mrydlt,setMrydlt] = useState(null)
  const updateMemoryBoardStatus = (workOrderId) => {
    memobj.memoryBoard = workOrderId 
};
  const handleDeleteModal = (data) => {
    setDeleteTagid(data?.tag_id);
    setShowDeleteTag(true);
  };

  let [loading, setLoading] = useState(false);

  const getUpdatedWorkOrder = async () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const response = await getWorkOrderById(
      appState?.currentWorkOrder?.id,
      userId,
      appState?.currentWorkOrder?.Customer?.id
    );
    updateAppState({ currentWorkOrder: response?.WorkOrderDetails });
  };
  const handleDeleteTag = async (deleteTagid) => {
    const response = await deleteTags(deleteTagid);
    if (response?.success) {
      setShowDeleteTag(false);
      setDeleteTagid(null);
      getUpdatedWorkOrder();
    setWfTags(wfTags?.filter((item) => item?.tag_id !== deleteTagid));
     
    }
  };
  const updateAppState = useContext(AppGlobalContextUpdate);
  const HandleTagSubmit = async () => {
    const newData = {
      referenceType: tagInpObj?.referenceType,
      tag_id: 0,
      referenceId:props?.data?.work_order_workflow_id,
      name: tagInpObj?.name,
      color: tagInpObj?.color,
      uuid_id: "",
      work_order_id: props?.woOrder,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newData);
    if (Res?.success) {
      const newTag = {
        tag_id: Res.data?.tag?.tag_id , 
        tag_data: Res.data?.tag?.tag_data,
        color: Res.data?.tag?.color,
      };
      setWfTags((prevTags) => [...prevTags, newTag]);
      setIsTagOpen(false);
      setTagInpObj({
        name: "",
        color: "#CCCCCC",
        referenceType: ReferenceId.work_order,
        uuid: "",
  });
    }
  }
  
  const AddTagModal = () => {
    setTagInpObj({
      name: "",
      color: "#CCCCCC",
      referenceType: ReferenceId.work_order_work_flow,
      uuid: "",
    });
    setIsTagOpen(true);
  };
  const [isMemory, setIsMemory] = useState(false);
  const HandleMemory = async(type,e, item) => {
    e.stopPropagation();
    if(type === "black"){
    setIsMemory(true);
    setMemobj(item)
    let items = { "viewtype": 10, "Reftype": 101, "RefId": item?.work_order_workflow_id };
    setIsMemoryObj(items);
    }
    else{
      setMryblue(true)
      setItemkey(type)
      let items = { "viewtype": 10, "Reftype": 101, "RefId": item?.work_order_workflow_id };
      setIsMemoryObj(items);
      setMemobj(item)
      setIsMemProgress(true)
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
        );
       
        if (Result?.success) {
          setIsMemProgress(false)
        }
        setgetmemory(Result?.data);
        setMrydlt(Result?.data?.recent_view_id)
    }
  };
  const HandleCheckProps = (data) => {
    if (
      data?.workflow_complete === 1 &&
      data?.Workflows_steps?.filter((it) => it?.step_complete !== "1")
        ?.length <= 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const navigation = useNavigate();
  const HandleNavToEqp = (id) => {
    let LocData = window?.location?.href?.split("/");
    navigation(
      `/EquipmentListPage/${LocData[5]}/${LocData[6]}/${LocData[7]}/${LocData[8]}/${LocData[9]}`,
       {state: id} 
    );
  };
  const navToPage = (to, params, data,wfpara) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params, data,wfpara },
      navigate: navigation,
    });
  };
  let TechPin = JSON.parse(localStorage.getItem("techpin"));
  let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));
  return (
    <div>
      <div className="line-align-bet cursor-p"
      style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
      >
        <span className="is-wf mx-2 ">
          <input
            type="checkbox"
            className="mx-1  largerCheckbox"
            style={{
              position: "relative",
              top: "3px",
              pointerEvents: HandleCheckProps(props?.data) && "none",
            }}
            checked={HandleCheckProps(props?.data)}
            onChange={(e) =>{if(props.OpenCust === false){ props?.handleCatCheck(props?.data)}}}
          />
          <span onClick={()=>{if(props.OpenCust === false){props?.Collapse()}}}>
            {" "}
            {props?.data?.workflow_name}&nbsp;(
            {` ${props?.data?.req ? "*" : ""} ${
              props?.data?.Workflows_steps?.length
            } / ${props?.data?.count}`}
            )
          </span>
        </span>
        <span style={{ display: "flex"}}>
          <span>
            {props?.data?.Equipments.length > 0 ?
            <span
              className={props.LocData ? "normalimg " : "blurimg "}
              style={{
                cursor:
                  appState?.currentWorkOrder?.status !== "Completed"
                    ? "pointer"
                    : "default",
              }}
              onClick={() =>{if(props.OpenCust === false)
                navToPage(`AddEquipments/${(props?.isWoid)}/${props?.custid}/${TechPin}/${TechPin}/${CompanyId}/0`, "Edit", props?.data?.Equipments[0]?.equipment_id,"wfeqp")}}
            >
              <img height="25px" className=" mx-1" src={EdtEqup} alt="EdtEqup" />
            </span>
            :
            <span
              className={props.LocData ? "normalimg " : "blurimg "}
              style={{
                cursor:
                  appState?.currentWorkOrder?.status !== "Completed"
                    ? "pointer"
                    : "default",
              }}
              onClick={() =>{if(props.OpenCust === false){HandleNavToEqp(props?.data?.work_order_workflow_id)}}}
            >
              <img className=" mx-1" src={CrtEqup}  alt="CrtEqup" />
            </span>
}
            <span>
              {wflws?.memoryBoard === 1 ?  <img src={memoryIconBlue} alt="memoryIconBlue" onClick={(e) =>{if(props.OpenCust === false){HandleMemory("workorderView",e,wflws)}}} className={props.LocData ? "normalimg" : "blurimg"} />
               :  
               <img src={memoryIcon} alt="memoryIcon" onClick={(e) => {if(props.OpenCust === false){HandleMemory("black",e,wflws)}}} className={props.LocData ? "normalimg" : "blurimg"}/>
              }
            </span>
          </span>
          <div
            className=" btnfw mt-1 ma-5 plr-5" onClick={()=>AddTagModal()}
            // onClick={(e) => HandleAddtag(e, eachWorkOrder)}
          >
            {WORK_ORDER_CONSTANTS.TAG} <img src={plus} alt="plus"  />
          </div>
        </span>
      </div>

      <div className="line-align-bet">
        <span className="wf-note-desc mx-2">
          {props?.data?.work_order_notes}
        </span>
      </div>

      <div className="mt-1 d-flex flex-wrap">
        {wfTags
          ?.map((it) => {
            return (
                <div className="mt-1 d-flex flex-wrap">
                  <span
                    style={{
                      pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto",
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "black",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                     className="tag-styles cursor-p"
                    onClick={() => handleDeleteModal(it)}
                  >
                    {it?.tag_data}
                    
                  </span>
                </div>
            
            );
          })}
      </div>

      <AddTag
        Open={isTagOpen}
        name={tagInpObj.name}
        color={tagInpObj.color}
        HandleTagInputs={changeTagEvent}
        workOrderId={"WorkFlow #"+appState?.currentWorkOrder?.id}
        isClose={() => closeAddTags()}
        isLoading={loading}
        isSubmit={() => HandleTagSubmit()}
      />

      <DeleteTags
        headid={deleteTagid}
        deleteTag={() => handleDeleteTag(deleteTagid)}
        handleClose={() => setShowDeleteTag(false)}
        data={deleteTagid}
        isOpen={showDeleteTag}
        name={"WorkFlow"}
      />
       <MemoryblueModal
          Open = {mryblue}
          Close= {()=>setMryblue(false)}
          ismemoryObj={ismemoryObj}
          memobj= {memobj}
          itemkey= {itemkey}
          descvalue={descvalue}
          custname={memobj?.Customer?.name}
          isProgress={ismemProgress}
          updateMemoryBoardStatus={updateMemoryBoardStatus}
          mrydlt= {mrydlt}
          openName={itemkey === "workorderView"? "workoder_workflow":""}
          headname = {itemkey === "workorderView" ? memobj?.workflow_name :""}
          headid={itemkey === "workorderView"?memobj?.work_order_workflow_id:""}
          />
          <MemoryModal
          memobj= {memobj}
            isOpen={isMemory}
            isClose={() => setIsMemory(false)}
            updateMemoryBoardStatus={updateMemoryBoardStatus}
            ismemoryObj={ismemoryObj}
            name={"workoder_workflow"}
            woId = {window.location.href.split('/')[5]}
            id={wflws?.work_order_workflow_id}
            descvalue={descvalue}
          />
    </div>
  );
}
