import React from "react";
import { Modal } from "react-bootstrap";
import Save from "../../../../common/Assets/Icons/SaveNew.svg";
import Close from "../../../../common/Assets/Icons/closeS.svg";
import Close2 from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import ProgressBar from "../../../../common/navigation/ProgressBar";
export default function WfCategoryTypeModal(props) {
  return (
    <>
      <Modal
        centered
        show={props?.isWfTypeCatModal}
        onHide={props?.IsCloseModal}
      >
        <Modal.Header className="modal-bg-color modal-header-height">
          <div className="line-align-bet w-100 ">
            <span className="wftags-sub-header2">
              {props?.isEqupId === 0 ? "Create" : "Edit"} {props?.isWfTypeCat}
            </span>
            <span className="cursor-p" onClick={props?.IsCloseModal}>
              <img className="modal-header-icon" src={Close2} alt="Close2" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props?.isFetch && (
              <div className=" FetchDataStyes">
                {props?.isFetch && "Fetching your Data..."}
              </div>
            )}
            {/* <input
              className="w-100 wfs-type-input"
              placeholder={`Input Name`}
              name="inpWftypeCat"
              value={props?.inpWftypeCat}
              onChange={props?.HandleWfsInputs}
            /> */}
            {props?.isProgress === true ? (
              <div 
               style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "110px",
               }}>
                <ProgressBar/>
              </div>
            ):(
            <div>
            <span>Workflow Category Code:</span>
            <input
              className="w-100 wfs-type-input mt-1"
              placeholder={`Input Code`}
              name="inpWftypeCode"
              value={props?.inpWftypeCode}
              onChange={props?.HandleWfsInputs2}
            />
            <span>Workflow Category Description:</span>
            <input
              className="w-100 wfs-type-input mt-1"
              placeholder={`Input Description`}
              name="inpWftypeDesc"
              value={props?.inpWftypeDesc}
              onChange={props?.HandleWfsInputs3}
            />
            </div>
             )}
            <div className="err-Wf-empty">
              {props?.WfEqpErr && "Please Enter all input fields"}
            </div>
          </div>
          
        </Modal.Body>
        <div className="mt-2 d-flex gap-3 wfs-save-cancel model_footer">
            <div
              className="wfs-cancel-save line-align-bet gap-2 cursor-p"
              onClick={props?.IsCloseModal}
            >
              <span className="sub-titles-names">Cancel</span>
              <img src={Close} alt="Close" />
            </div>
            <div
              className="wfs-cancel-save line-align-bet gap-2 cursor-p"
              onClick={props?.HandelSaveWfs}
            >
              <span className="sub-titles-names">Save</span>
              <img src={Save} alt="Save" />
            </div>
          </div>
      </Modal>
    </>
  );
}
