import React from "react";
import { Modal } from "react-bootstrap";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { TEAMS_MODAL } from "../../components/Constants";
export default function DeleteModal(props) {
  return (
    <div>
      {" "}
      <Modal show={props?.Open} onHide={props?.Close} centered>
        <Modal.Header className=" modal-bg-color modal-header-height ">
          <span style={{ color: "white" }}>{TEAMS_MODAL.DEL}</span>
          <img
            src={Close}
            alt="close"
            onClick={props?.Close}
            className="modal-header-icon "
          />
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              position: "relative",
              bottom: "10px",
              fontSize: "13px",
              fontFamily: "SofiaProRegular",
            }}
          >
            {props.DelDiscription}
          </div>
          <div
            className="mt-2"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <div
              className="modal-footer-save-cancel-btns"
              onClick={props?.Close}
            >
              {TEAMS_MODAL.CANCEL}
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              style={{ margin: 0 }}
              onClick={(e) =>props?.HandleDelete(e)}
            >
              {TEAMS_MODAL.DEL}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
