import React, {
  forwardRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import "./WorkFlow.css";
import { Card, Modal } from "react-bootstrap";
import add from "../../../common/Assets/Icons/add.svg";
import InsrtuctBlue from "../../../common/Assets/Images/Instructions Icon.svg";
import disable from "../../../common/Assets/Icons/Disable.svg";
import DisableGray from "../../../common/Assets/Icons/DisableGray.svg";
import DisableRed from "../../../common/Assets/Icons/DisableBlue.svg";
import InstructionWhite from "../../../common/Assets/Images/InstructionWhite.svg";
import workflow from "../../../common/Assets/Icons/Work Flow Wrench.svg";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import CloseRound from "../../../common/Assets/Icons/Close_round_main.svg";
import SaveNew from "../../../common/Assets/Icons/SaveNew.svg";

import ProgressBar from "../../../common/navigation/ProgressBar";
import {
  WORK_ORDER_CONSTANTS,
  TAGS_MODAL_VALUES,
  Wo_Workflow,
} from "../../../common/components/Constants";
import { AppGlobalContext } from "../../../common/context/AppGlobalContext";
import Steps from "./Steps";
import Category from "./Category";
import {
  WorkFlowRes,
  WorkFlowAdd,
  DropDownData,
  DeleteCategory,
  deleteTags,
  updateWorkOrderStatus,
  WorkFlowNoteUpdate,
  HandleWorkFlowComplete,
} from "../../../services/WorkOrdersService";
import AddTag from "../addtag/AddTag";
import DeleteTags from "../addtag/DeleteTags";
import { CiSearch } from "react-icons/ci";
import WorkflowModalData from "./WorkflowModalData";
import { WorkFlowCheck } from "./WorkFlowCheck";
import { WorkOrderComplete } from "./WokOrderComplete";
import ReqWfAndStepModal from "./ReqWfAndStepModal";
import { ReferenceId } from "../../../common/components/EnumRefType";
const WorkFlow = forwardRef(function WorkFlow(props, ref) {
  const [isWorkNo, setIsWorkNo] = useState(0);
  const [LocStrId, setLocStrId] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const appState = useContext(AppGlobalContext);
  const [ResWorkFlow, setResWorkFlow] = useState([]);
  const [ResWorkFlow2, setResWorkFlow2] = useState([]);
  const [WorkFlowArr, setWorkFlowArr] = useState([]);
  const [WorkFlowArr2, setWorkFlowArr2] = useState([]);
  const [filterWorkFlow, setFilterWorkFlow] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
const woOrder = props?.woOrder
  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };

  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.work_flow,
    workorder: null,
  });
  const setCurrentTagEqp = useState({});
  const [isTagOpen, setIsTagOpen] = useState(false);
  const closeAddTags = () => {
    setCurrentTagEqp({});
    setIsTagOpen(false);
  };
  const [setAddedNewTag] = useState(false);
  const [WoNo, setWoNo] = useState(0);
  const [background, setBackground] = useState("");
  const [lastSavedId, setLastSavedId] = useState(null);
  useEffect(() => {
    if (ResWorkFlow.length > 1 && ResWorkFlow[ResWorkFlow.length - 1]) {
      setLastSavedId(
        ResWorkFlow[ResWorkFlow.length - 1].work_order_workflow_id
      );
      setBackground("#f0f5f5");
      const timer = setTimeout(() => {
        setBackground("");
        setLastSavedId(null);
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [ResWorkFlow]);
  const WOrkFlowData = useCallback(async () => {
    
    let TOKEN =
      JSON.parse(localStorage.getItem("authToken")) === null
        ? props.wftoken
        : JSON.parse(localStorage.getItem("authToken"));

    let WorkNo = props?.isWoid;
    setWoNo(WorkNo);
    setIsProgress(true);
    let response = await WorkFlowRes(TOKEN, WorkNo);
    if( response?.success === undefined ||  response?.success === true ||  response?.success === false)
    {
      setIsProgress(false);
    }
    if (
      response?.data?.workorder_workflow?.data?.workorderWorkflowResponse
        ?.length >= 0
    ) {
      setIsProgress(false);
      setResWorkFlow(
        response?.data?.workorder_workflow?.data?.workorderWorkflowResponse?.map(
          (it) => ({
            ...it,
            count: it.Workflows_steps?.length,
            req: false,
          })
        )
      );
      setResWorkFlow2(
        response?.data?.workorder_workflow?.data?.workorderWorkflowResponse?.map(
          (it) => ({
            ...it,
            count: it.Workflows_steps?.length,
            req: false,
          })
        )
      );
    }
  }, [props?.isWoid, props.wftoken]);

  useEffect(() => {
    props?.WfData(ResWorkFlow2);
  }, [ResWorkFlow2, props]);

  useEffect(() => {
    props?.isLoadData && setResWorkFlow(props?.RequiredWf);
  }, [props]);
  useEffect(() => {
    setLocStrId(localStorage.getItem("userId"));
    let WorkPath = window?.location?.href?.split("/");
    let workNo = +WorkPath[5];
    setIsWorkNo(workNo);
    WOrkFlowData();
    WorkFlowDataDrop();
  }, [WOrkFlowData]);

  const WorkFlowDataDrop = async () => {
    let Res = await DropDownData();
    setWorkFlowArr(Res?.data?.workflow);
    setWorkFlowArr2(Res?.data?.workflow);
    setFilterWorkFlow(Res?.data?.workflow);
  };

  const [isWorkflow, setIsWorkFlow] = useState(false);
  const [isWfdata, setIsWfData] = useState([]);
  const HandleAddWorkFlow = (WFlowData) => {
    setResWorkFlow(ResWorkFlow2);
    setIsWorkFlow(true);
    setIsWfData(WFlowData);
    setWfArr([]);
  };
  const HandleCloseAddWfModal = () => {
    setWorkFlowArr(WorkFlowArr2);
    setIsWorkFlow(false);
    setIsWfData([]);
    setWfArr([]);
  };
  const [isCollapse, setIsCollapse] = useState([]);
  const HandleCollapse = (it) => {
    let CollapseData = [...isCollapse];
    if (CollapseData.includes(it.work_order_workflow_id)) {
      CollapseData = isCollapse.filter(
        (item) => item !== it.work_order_workflow_id
      );
    } else {
      CollapseData = [...CollapseData, it.work_order_workflow_id];
    }
    setIsCollapse(CollapseData);
  };
  const [isDupModal, setisDupModal] = useState("");
  const [wfArr, setWfArr] = useState([]);
  const [isValObj, setIsValObj] = useState({});
  const HandleInitial = (value, data) => {
    setIsValObj(value);
    let Filterdata = data?.filter((i) => i?.workflow_id === value?.workflow_id);
    if (Filterdata?.length > 0) {
      setisDupModal("duplicate");
      setWfModal(true);
    } else {
      setWfModal(false);
      setisDupModal("");
      let NewObjVal = {
        temp_id: isWfdata.some((item) => item.temp_id)
          ? isWfdata.reduce((prev, current) =>
            prev && prev.temp_id > current.temp_id
              ? prev?.temp_id + -1
              : current?.temp_id + -1
          )
          : -1,
        sponsor_id: value?.sponsor_id,
        workflow_id: value?.workflow_id,
        workflow_name: value?.workflow_name,
        workflow_notification_email_list:
          value?.workflow_notification_email_list,
        workflow_team_id_list: value?.workflow_team_id_list,
      };

      let selectedArr = [...wfArr];
      selectedArr = [...selectedArr, NewObjVal];
      setIsWfData([...ResWorkFlow2, ...selectedArr]);
      setWfArr(selectedArr);
    }
  };
  const HandleDupli = () => {
    let NewArr = [];
    HandleInitial(isValObj, NewArr);
  };
  const HandleSave = async () => {
    setIsLoad(true);
    const mappedArr = wfArr.map((it) => ({
      workflow: it.workflow_id,
      label: it.workflow_name,
      note: it.work_order_notes,
    }));
    let inpObj = {
      workorder_id: isWorkNo,
      workflows: mappedArr,
    };
    let res = await WorkFlowAdd(inpObj);
    if (res.success) {
      setIsLoad(false);
      WOrkFlowData();
      setIsWorkFlow(false);
      setWorkFlowArr(WorkFlowArr2);
    }
  };
  const [isUpdatenote, setIsUpdateNote] = useState("");
  const [isWfObj, setIsWfObj] = useState({});
  const handleNoteUpdate = (e, wfItem) => {
    const isValidWorkOrderNote = e.target.value;
    if (wfItem) {
      wfItem.work_order_notes = isValidWorkOrderNote;
    }
    setIsUpdateNote(e.target.value);
    setIsWfObj(wfItem);
  };

  const HandleNotePut = async () => {
    const workId = window.location.href.split("/")[5];
    let newObj = {
      workOrderId: workId,
      woWorkflowId: isWfObj?.work_order_workflow_id,
      note: isUpdatenote,
    };
    await WorkFlowNoteUpdate(newObj);
  };
  const [DeleteItem, setDeleteItem] = useState({});

  const HAndleDeleteWfAddModal = (wfItem) => {
    setDeleteItem(wfItem);
    if (wfItem?.work_order_workflow_id) {
      let RealData = ResWorkFlow?.filter(
        (i) => i?.workflow_id === wfItem?.workflow_id
      );
      if (
        RealData[0]?.Workflows_steps?.filter(
          (i) =>
            i?.attachment_list?.length > 0 ||
            i?.choice_result !== null ||
            // i?.choice_result !== "" ||
            i?.notes?.length > 0
          // i?.content_note !== ""
        )?.length > 0
      ) {
        setWfModal(true);
        setisDupModal("delwf");
      } else {
        HAndleDeleteWorkFlow(wfItem);
      }
    } else {
      setIsWfData(isWfdata?.filter((i) => i?.temp_id !== wfItem?.temp_id));
      setWfArr(wfArr?.filter((i) => i?.temp_id !== wfItem?.temp_id));
    }
  };
  const HAndleDeleteWorkFlow = async (it) => {
    let urlData = window.location.href.split("/");
    let WorkNo = urlData[5];
    await DeleteCategory(
      it ? it?.workflow_id : DeleteItem.workflow_id,
      it ? it?.work_order_workflow_id : DeleteItem.work_order_workflow_id,
      WorkNo
    );

    setWfModal(false);
    setResWorkFlow2(
      ResWorkFlow2.filter(
        (workflow) =>
          workflow.work_order_workflow_id !== it.work_order_workflow_id
      )
    );

    let FilterData = isWfdata?.filter(
      (delWfData) =>
        delWfData?.work_order_workflow_id !== it?.work_order_workflow_id
    );
    setIsWfData(FilterData);

    setDeleteItem({});
  };

  const HandleSearch = (e) => {
    let FilterData = filterWorkFlow.filter((it) =>
      it.workflow_name.toUpperCase().startsWith(e.target.value.toUpperCase())
    );
    setWorkFlowArr(FilterData);
  };
  /*const HandleSort = (val) => {
    let SortedArr = filterWorkFlow.sort(function (a, b) {
      if (val === false) {
        setIsSort(true);
        return b.workflow_name.localeCompare(a.workflow_name);
      } else {
        setIsSort(false);
        return a.workflow_name.localeCompare(b.workflow_name);
      }
    });
    setWorkFlowArr(SortedArr);
  };*/

  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(null);

  const handleDeleteTag = async () => {
    const response = await deleteTags(deleteTags.id);
    if (response?.success) {
      setShowDeleteTag(false);
      setDeleteTag(null);
    }
  };

  const [WfModal, setWfModal] = useState(false);

  const HandleDelete = async (it) => {
    setDeleteItem(it);
    setWfModal(true);
  };

  const HAndleDeleteWorkFlow2 = async () => {
    let urlData = window.location.href.split("/");
    let WorkNo = urlData[5];
    await DeleteCategory(
      DeleteItem.workflow_id,
      DeleteItem.work_order_workflow_id,
      WorkNo
    );
    setisDupModal("");
    setWfModal(false);
    setResWorkFlow2(
      ResWorkFlow2.filter(
        (workflow) =>
          workflow.work_order_workflow_id !== DeleteItem.work_order_workflow_id
      )
    );
    setIsWfData(
      isWfdata?.filter(
        (i) => i?.work_order_workflow_id !== DeleteItem.work_order_workflow_id
      )
    );
  };
  /* const HandleSelectAll = () => {
    setIsCheck(!isCheck);
    if (!isCheck) {
      setWfArr(WorkFlowArr);
    } else {
      setWfArr([]);
    }
  };*/

  const [RequiredWf, setRequiredWf] = useState([]);
  const [isreqWf, setIsreqWf] = useState("");
  const HandleBtnVal = async () => {
    setIsLoading(true);
    // setResWorkFlow(ResWorkFlow2);
    const result = WorkOrderComplete(ResWorkFlow2);
    if (result?.NewData?.length > 0) {
      setIsreqWf("WfValidation");
      setIsStepval(true);
      setIsLoading(false);
      setRequiredWf(result?.NewData);
      if (result?.NewData[0]?.type === "required") {
        setIsReq(Wo_Workflow?.Req_step);
      } else {
        setIsReq(Wo_Workflow?.OpenReq_steps);
      }
    } else {
      const res = await updateWorkOrderStatus(WoNo, "Completed");
      if (res?.status === 200) {
        alert("Workorder completed successfully");
        window.location.reload();
      }
    }
  };

  const HandleRemainWf = () => {
    setResWorkFlow(RequiredWf);
    setIsreqWf("");
    setIsStepval(false);
  };
  const HandleWfConfirm = async () => {
    let newApiArr = ResWorkFlow?.map((i) => ({
      WoWfId: i?.work_order_workflow_id,
      WfStepId: 0,
      Type: "1",
    }));
    (async () => {
      await Promise.all(
        newApiArr?.map(async (it) => {
          await HandleWorkFlowComplete(it?.WoWfId, it?.WfStepId, it?.Type);
        })
      );
    })();

    const res = await updateWorkOrderStatus(WoNo, "Completed");
    if (res?.status === 200) {
      alert("Workorder completed successfully");
      window.location.reload();
    }
    setIsStepval(false);
    WOrkFlowData();
  };

  const [isWfToggle, setIswfToggle] = useState(true);
  const handleWorkFlowToggle = () => {
    setIswfToggle(!isWfToggle);
  };
  const [isInstruct, setIsInstruct] = useState("instructionOff");
  const HandleWFinstruct = (val) => {
    setIsInstruct(val);
  };
  const [isStepVal, setIsStepval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReq, setIsReq] = useState("");
  const [remainData, setRemainData] = useState([]);
  const [isReqSteps, setIsReqSteps] = useState([]);
  const [isNotReqSteps, setIsNotReqSteps] = useState([]);

  const handleCatCheck = async (data) => {
    setIsreqWf("");
    setIsStepval(true);
    setIsLoading(true);
    let NewData = await WorkFlowCheck(data);
    setIsReqSteps(NewData?.ReqSteps);
    setIsNotReqSteps(NewData?.NotReqSteps);
    if (NewData?.ReqSteps[0]?.Workflows_steps?.length > 0) {
      setIsLoading(false);
      setIsReq(Wo_Workflow?.Req_step);
      setRemainData(data);
    } else if (NewData?.NotReqSteps[0]?.Workflows_steps?.length > 0) {
      setIsLoading(false);
      setIsReq(Wo_Workflow?.OpenReq_steps);
      setRemainData(data);
    } else {
      setIsStepval(false);
      let WoWfId = data?.work_order_workflow_id;
      let WfStepId = 0;
      let Type = "1";
      let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
      if (Res?.success) {
        data.workflow_complete = 1;
        setResWorkFlow([data]);
        WOrkFlowData();
      }
    }
  };
  const HandleRemainSteps = async () => {
    setIsreqWf("");
    if (isReqSteps[0]?.Workflows_steps?.length > 0) {
      setResWorkFlow(isReqSteps);
      setIsStepval(false);
    }
    if (isNotReqSteps[0]?.Workflows_steps?.length > 0) {
      setResWorkFlow(isNotReqSteps);
      setIsStepval(false);
    }
  };
  const handleConfirm = async () => {
    let WoWfId = remainData?.work_order_workflow_id;
    let WfStepId = 0;
    let Type = "1";
    let Res = await HandleWorkFlowComplete(WoWfId, WfStepId, Type);
    if (Res?.success) {
      const updatedWorkSteps = remainData?.Workflows_steps?.map((i) => ({
        ...i,
        step_complete: "1",
      }));
      remainData.Workflows_steps = updatedWorkSteps;
      remainData.workflow_complete = 1;
      setResWorkFlow([remainData]);
      WOrkFlowData();
    }
    setIsStepval(false);
  };
  const HandleWfCheck = async (val) => {
    let FilterCheckArr = ResWorkFlow?.filter(
      (i) => i?.work_order_workflow_id === val?.work_order_workflow_id
    )[0];
    FilterCheckArr.workflow_complete = "0";
    let FilterCheckArr2 = ResWorkFlow?.filter(
      (i) => i?.work_order_workflow_id !== val?.work_order_workflow_id
    );

    if (
      FilterCheckArr?.Workflows_steps?.filter((it) => it?.step_complete !== 1)
        ?.length > 0
    ) {
      setResWorkFlow([...FilterCheckArr2, FilterCheckArr]);
    }
  };
  const [isOpen, setIsOpen] = useState("");
  const [isValid, setIsValid] = useState("");

  const HandleRemainigSteps = (value) => {
    let FilterData = [];
    setIsOpen(value);
    if (value === "Open" && isValid !== "data") {
      FilterData = ResWorkFlow2?.map((it) => ({
        ...it,
        Workflows_steps: it.Workflows_steps.filter(
          (i) => i.step_complete !== "1"
        ),
        count: it.Workflows_steps?.length,
        req: false,
      }));
      setResWorkFlow(FilterData);
    } else if (value === "Req" && isValid !== "data") {
      FilterData = ResWorkFlow2?.map((it) => ({
        ...it,
        Workflows_steps: it.Workflows_steps.filter(
          (i) => i.step_complete !== "1" && i?.step_required
        ),
        count: it.Workflows_steps?.length,
        req: true,
      }));
      setResWorkFlow(FilterData);
      setIsValid("data");
    } else {
      FilterData = ResWorkFlow2?.map((it) => ({
        ...it,
        count: it.Workflows_steps?.length,
        req: false,
      }));
      setResWorkFlow(FilterData);
      setIsValid("nodata");
    }
  };
  return (
    <div ref={ref}>
      <Card className="m-0 det-txt-media ">
        <div className="wf-card-header clp_header">
          <div className="myDiv">
            <img
              onClick={() => handleWorkFlowToggle()}
              src={workflow}
              className="flt-l ml-2 mb-1"
              width={22}
              alt="details"
            />
            <span className="cursor-p" onClick={() => handleWorkFlowToggle()}>
              {WORK_ORDER_CONSTANTS.WORK_FLOWS}
            </span>
          </div>
          {props?.isVal !== "create" && (
            <div className="myDiv2 "
              style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
            >
              <span>
                {isOpen === "Open" ? (
                  <img
                    src={DisableGray}
                    alt="DisableGray"
                    onClick={() => { if (props.OpenCust === false) { HandleRemainigSteps("Req") } }}
                  />
                ) : isOpen === "Req" ? (
                  <img
                    src={DisableRed}
                    alt="DisableRed"
                    onClick={() => { if (props.OpenCust === false) { HandleRemainigSteps("all") } }}
                  />
                ) : (
                  <img
                    src={disable}
                    alt="disable"
                    onClick={() => { if (props.OpenCust === false) { HandleRemainigSteps("Open") } }}
                  />
                )}
              </span>
              {!isProgress && (
                <span>
                  <img
                    src={add}
                    width={14}
                    className={
                      LocStrId
                        ? "  cursor-p"
                        : "  cursor-p pointr-events blurimg"
                    }
                    style={{
                      pointerEvents:
                        appState?.currentWorkOrder?.status !== "Completed"
                          ? ""
                          : "none",
                    }}
                    alt="add workflow"
                    onClick={() => {
                      if (props.OpenCust === false) {
                        HandleAddWorkFlow(ResWorkFlow2)
                      }
                    }}
                  />
                </span>
              )}
              {isInstruct === "instructionOn" ? (
                <span>
                  <img
                    className=" cursor-p"
                    src={InstructionWhite}
                    width={18}
                    alt="InsrtuctBlue"
                    onClick={() => { if (props.OpenCust === false) { HandleWFinstruct("instructionOff") } }}
                  />
                </span>
              ) : (
                <img
                  className=" cursor-p"
                  src={InsrtuctBlue}
                  width={18}
                  alt="InstructionWhite"
                  onClick={() => { if (props.OpenCust === false) { HandleWFinstruct("instructionOn") } }}
                />
              )}
            </div>
          )}
        </div>
        <Card.Body className="p-0">
          {isWfToggle && (
            isProgress === true ? (
              <div className="progress-bar"
                style={{ height: "100%" }}>
                <ProgressBar />
              </div>
            ) : (
              <div className="clp_body">
                <div>
                  {ResWorkFlow?.length > 0 ? (
                    ResWorkFlow.sort(
                      (a, b) =>
                        a.work_order_workflow_id - b.work_order_workflow_id
                    ).map((item, index) => (
                      <div
                        key={index}
                        className="p-1 mt-1"
                      
                      >
                        <div>
                          <Category
                             woOrder={woOrder}
                            setAddedNewTag={setAddedNewTag}
                            Collapse={() => HandleCollapse(item)}
                            data={item}
                            DelCategory={() => HandleDelete(item)}
                            deleteTags={() => handleDeleteTag()}
                            LocData={LocStrId}
                            isWoid={props?.isWoid}
                            custid={props?.custid}
                            handleCatCheck={handleCatCheck}
                            OpenCust={props.OpenCust}
                          />
                        </div>
                        {isCollapse.includes(item.work_order_workflow_id) ? (
                          <span className={!LocStrId && "pointr-events"}>
                            <Steps
                              data={item}
                              WorkNo={isWorkNo}
                              HandleWfCheck={HandleWfCheck}
                              isInstruct={isInstruct}
                            />
                          </span>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <div>
                      <span id="edit-inp-text" className="wf-body-decrptn">
                        {WORK_ORDER_CONSTANTS.WRKFLWS_BODY}
                      </span>
                    </div>
                  )}
                </div>
                {ResWorkFlow?.length > 0 && (
                  <div className="UpdateWf-status mt-2">
                    <div
                      style={{
                        pointerEvents:
                          (appState?.currentWorkOrder?.status === "Completed" || window?.location?.href?.split("/")[10] === "1")
                            ? "none"
                            : "auto",
                      }}
                      onClick={() => {
                        if (appState?.currentWorkOrder?.status !== "Completed" && props.OpenCust === false) {
                          HandleBtnVal()
                        }
                      }}
                    >
                      Complete Work order
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </Card.Body>
      </Card>

      <Modal show={isWorkflow} onHide={() => HandleCloseAddWfModal()} centered>
        <Modal.Body className="px-0 py-0 m-0">
          <div className="w-100 d-flex  px-2 model-header modal-bg-color align-items-center">
            <div className="attach-media-note">
              {" "}
              {WORK_ORDER_CONSTANTS.WORK_FLOW_ADD} -{" "}
              {WORK_ORDER_CONSTANTS.WORK_ORDER}
              {isWorkNo}
            </div>
            <img
              src={Close}
              alt="Close"
              className="modal-header-icon"
              onClick={() => HandleCloseAddWfModal()}
            />
          </div>
          {isLoad ? (
            <div className="progress-styles">
              <ProgressBar />
            </div>
          ) : (
            <>
              <div className="modelbody">
                <div>
                  <div className="px-1 wf-add-mdl-txt mb-2">
                    Please select which work you would like to see. The workflow
                    will display below
                  </div>
                  <div className="">
                    <div className="w-100">
                      <div className="mx-0">
                        <CiSearch className="inp-inside-icon" />
                        <input
                          type="search"
                          className="w-100 seaech-inp"
                          placeholder="Enter Search"
                          onChange={(e) => HandleSearch(e)}
                        />
                      </div>
                      {/* <div
                        className="mt-1  workflow-display-text search-w2"
                        onClick={() => HandleSort(isSort)}
                      >
                        {
                          <span className="sort-text">
                            {isSort === false ? (
                              <BsSortUpAlt size={15} className="mb-1 mx-1" />
                            ) : (
                              <BsSortDownAlt size={15} className="mb-1 mx-1" />
                            )}
                            {isSort === false ? "Sort Z to A" : "Sort A to Z"}
                          </span>
                        }
                      </div> */}
                    </div>
                    <div>
                      {/* {WorkFlowArr?.length > 0 && (
                        <div className="p-1 line-align-bet">
                          <span className="workflow-lable">Select All</span>
                          <input
                            className="mx-4 checkbox-style"
                            type="checkbox"
                            checked={
                              WorkFlowArr.length === wfArr.length ? true : false
                            }
                            onClick={() => HandleSelectAll()}
                          />
                        </div>
                      )} */}
                      <div className="wf-add-modal-scroll ">
                        {WorkFlowArr?.length > 0 ? (
                          WorkFlowArr?.map((item) => (
                            <div
                              className="cursor-p"
                              onClick={() => HandleInitial(item, isWfdata)}
                            >
                              <span className="plus-styles"> +</span>
                              <span className="workflow-lable mx-1">
                                {item.workflow_name}
                              </span>

                              {/* <input
                              className="mx-4 checkbox-style"
                              type="checkbox"
                              checked={wfArr.includes(item) ? true : false}
                              onClick={() => HandleInitial(item)}
                            /> */}
                            </div>
                          ))
                        ) : (
                          <div className="nodata-style" id="err-styles">
                            {WORK_ORDER_CONSTANTS.NO_WORK_ORDERS}
                          </div>
                        )}
                      </div>
                      <div className="mt-3">
                        {isWfdata?.length > 0 && (
                          <div className="line-align-even w-100">
                            <span className="Wf-filter-titles w-50">
                              Workflow Name
                            </span>
                            <span className="Wf-filter-titles w-50">Note</span>
                          </div>
                        )}
                        <div className="mt-2 wf-add-modal-scroll">
                          {isWfdata?.map((i, index) => (
                            <WorkflowModalData
                              key={index}
                              data={i}
                              // isUpdatenote={isUpdatenote}
                              HAndleDeleteWfAddModal={HAndleDeleteWfAddModal}
                              handleNoteUpdate={handleNoteUpdate}
                              HandleNotePut={HandleNotePut}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Wf-add-mdl-footer model_footer  mt-2">
                <button className="" onClick={() => HandleCloseAddWfModal()}>
                  <span>{TAGS_MODAL_VALUES.CANCEL}</span>
                  <span>
                    <img src={CloseRound} alt="CloseRound" width={14} />
                  </span>
                </button>
                <button className="mx-2" onClick={() => HandleSave()}>
                  {" "}
                  <span>{TAGS_MODAL_VALUES.SAVE}</span>
                  <span>
                    <img src={SaveNew} alt="SaveNew" width={14} />
                  </span>
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={WfModal} onHide={() => setWfModal(false)} centered>
        <Modal.Header className="modal-bg-color modal-header-height">
          {isDupModal === "duplicate" ? (
            <div className="attach-media-note">Workflow is on Work Order</div>
          ) : (
            <div className="attach-media-note">
              {WORK_ORDER_CONSTANTS.DELETE_WORKFLOW} -{" "}
              {WORK_ORDER_CONSTANTS.WORK_ORDER}
              {isWorkNo}
            </div>
          )}
          <img
            src={Close}
            alt="Close"
            className="modal-header-icon"
            onClick={() => setWfModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          {isDupModal === "duplicate" ? (
            <div className="delete-Workflow">
              This workflow is already on the work order.Are you sure you would
              like to add this work flow again
            </div>
          ) : isDupModal === "delwf" ? (
            <div className="delete-Workflow">
              In Order to delete this workflow all data that is saved with in
              the workflow will need to be deleted. This includes the step
              updates, media, notes or any data currently associated with in the
              workflow. Are you sure you would like to proceed?
            </div>
          ) : (
            <div className="delete-Workflow">
              {" "}
              {TAGS_MODAL_VALUES.TXT_POP_UP} Delete {DeleteItem.workflow_name}?
            </div>
          )}
          <div className="gap-1 mt-2 modal-footer-parent model_footer">
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() => {
                setWfModal(false);
                setisDupModal("");
              }}
            >
              {TAGS_MODAL_VALUES.CANCEL}
            </button>
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() =>
                isDupModal === "duplicate"
                  ? HandleDupli()
                  : isDupModal === "delwf"
                    ? HAndleDeleteWorkFlow2()
                    : HAndleDeleteWorkFlow()
              }
            >
              {isDupModal === "duplicate"
                ? "Confirm"
                : TAGS_MODAL_VALUES.DELETE}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <AddTag
        Open={isTagOpen}
        name={tagInpObj.name}
        color={tagInpObj.color}
        HandleTagInputs={changeTagEvent}
        workOrderId={appState?.currentWorkOrder?.id}
        isClose={() => closeAddTags()}
      />

      <DeleteTags
        headid={appState?.currentWorkOrder?.id}
        deleteTag={() => handleDeleteTag()}
        handleClose={() => setShowDeleteTag(false)}
        data={deleteTag}
        name={"Workflow"}
        isOpen={showDeleteTag}
      />

      <ReqWfAndStepModal
        isStepVal={isStepVal}
        isClose={() => setIsStepval(false)}
        isLoading={isLoading}
        isReq={isReq}
        isreqWf={isreqWf}
        HandleRemainWf={HandleRemainWf}
        HandleRemainSteps={HandleRemainSteps}
        HandleWfConfirm={HandleWfConfirm}
        handleConfirm={handleConfirm}
      />
    </div>
  );
});

export default WorkFlow;
