import axios from "axios";
import { NINEPACK_URL } from "./Url";
import { APP_JSON } from "../common/components/Constants";
export const AddCusEqService = async (data) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    const response = await axios.post(`${NINEPACK_URL}/customer`, data, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response;
  } catch (err) {}
};
export const AddFieldsCust = async (data) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    const response = await axios.get(
      `${NINEPACK_URL}/customer/23/1806/0/10/info`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response;
  } catch (err) {}
};

export const GetCustById = async (id, techId, startIndex, endIndex) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    const response = await axios.get(
      `${NINEPACK_URL}/customer/${id}/${techId}/${startIndex}/${endIndex}/info`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON, 
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const GetCustExtraDetailsById = async (id) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    const response = await axios.get(`${NINEPACK_URL}/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data;
  } catch (err) {}
};
