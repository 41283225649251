import React, { useCallback, useEffect, useState } from "react";
import { WorkFlowPDf } from "../../../services/WorkOrdersService";
import { PDFDocument, rgb } from "pdf-lib";
import { useLocation, useNavigate } from "react-router-dom";
import "./WorkFlow.css";
import SignaturePad from "./SignaturePad";
import { PDFTextField, PDFCheckBox } from "pdf-lib";

export default function PDFView() {
  const [pdfurl, setPdfUrl] = useState(null);
  const [updatebase64, setUpdateBase64] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSign, setIsSign] = useState(false);

  const convertBlobUrlToBase64 = async (pdfBlob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(pdfBlob);
    });
  };

  const HandleUpdateFields = useCallback((exobj, exname, data) => {
    let newfilterOne = data?.filter((i) => i?.uuid === exname);
    if (newfilterOne?.length) {
      if (
        exobj.constructor.name === "t" ||
        exobj.constructor.name === "PDFTextField"
      ) {
        exobj.setText(newfilterOne[0]?.value?.toString());
      }
    }
  }, []);
  const HandleUpdateCheckBox = useCallback((exobj, exname, data) => {
    let newfilterOne = data?.filter((i) => i?.uuid === exname);
    if (newfilterOne?.length >= 0) {
      if (
        exobj.constructor.name === "t" ||
        exobj.constructor.name === "PDFCheckBox"
      ) {
        newfilterOne[0]?.value === "Checked" && exobj.check();
      }
    }
  }, []);

  const HandleSignatureInput = useCallback(async (pdfDoc, widget) => {
    const pages = pdfDoc.getPages();
    const rect = widget.getRectangle();
    const page = pages[0];

    page.drawText("", {
      x: rect.x,
      y: rect.y,
      size: 22,
      color: rgb(1, 0, 0),
    });
  }, []);

  const location = useLocation();
  const [isNodata, setIsNoData] = useState("");
  const loadPdf = useCallback(async () => {
    setIsLoading(true);
    setIsNoData("");
    let WoNo = window.location.href.split("/")[5];
    let StepId = window.location.href.split("/")[6];
    let WoWfStep = window.location.href.split("/")[7];
    let workFlowId = window.location.href.split("/")[8];
    let Response = await WorkFlowPDf(WoNo, StepId, WoWfStep, workFlowId);
    if (Response?.success) {
      try {
        const mergedPdfDoc = await PDFDocument.create();

        for (const item of Response?.data?.data || []) {
          const pdfDoc = await PDFDocument.load(item?.pdf);
          const form = pdfDoc.getForm();

          const fields = form.getFields();

          fields.forEach((field) => {
            const fieldName = field.getName();
            try {
              if (field instanceof PDFTextField) {
                const textField = form.getTextField(fieldName);
                if (textField) {
                  HandleUpdateFields(textField, fieldName, item?.field_values);
                  // textField.enableReadOnly();
                  const signatureWidgets = field.acroField.getWidgets();
                  const widget = signatureWidgets[0];
                  HandleSignatureInput(pdfDoc, widget);
                  return;
                }
              } else if (field instanceof PDFCheckBox) {
                const isCheckBox = form.getCheckBox(fieldName);
                if (isCheckBox) {
                  HandleUpdateCheckBox(
                    isCheckBox,
                    fieldName,
                    item?.field_values
                  );
                  // isCheckBox.enableReadOnly();

                  // const isChecked = isCheckBox.isChecked();
                  // isChecked && isCheckBox.check();
                  return;
                }
              }
            } catch (error) {
              console.error(`Error updating field "${fieldName}":`, error);
            }
          });

          const updatedPdfBytes = await pdfDoc.save();
          const updatedPdfDoc = await PDFDocument.load(updatedPdfBytes);

          const copiedPages = await mergedPdfDoc.copyPages(
            updatedPdfDoc,
            updatedPdfDoc.getPageIndices()
          );
          copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
        }

        const mergedPdfBytes = await mergedPdfDoc.save();
        const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        const base64data = await convertBlobUrlToBase64(pdfBlob);

        setUpdateBase64(base64data);
        setPdfUrl(pdfBlobUrl);
      } catch (error) {
        console.error("Error merging PDFs:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setIsNoData("There is No Pdf Found");
    }
  }, [HandleSignatureInput, HandleUpdateCheckBox, HandleUpdateFields]);

  useEffect(() => {
    loadPdf();
  }, [HandleUpdateFields, HandleSignatureInput, location, loadPdf]);

  const navigation = useNavigate();
  const HandlenavBack = () => {
    navigation(-1);
  };

  const HandleSignPdf = () => {
    setIsSign(!isSign);
  };

  const handleSignatureSave = async (signatureDataURL) => {
    const pdfDoc = await PDFDocument.load(updatebase64);
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    let signatureFieldFound = false;

    fields.forEach((field) => {
      const fieldName = field.getName();
      if (fieldName.toLowerCase() === "signature") {
        signatureFieldFound = true;
      }
    });

    if (signatureFieldFound) {
      const page = pdfDoc.getPage(0);
      const { height: pageHeight } = page.getSize();
      const pngImage = await pdfDoc.embedPng(signatureDataURL);
      const { width: imageWidth, height: imageHeight } = pngImage.size();
      const x = 80;
      const y = pageHeight - imageHeight - 440;

      page.drawImage(pngImage, {
        x,
        y,
        width: imageWidth,
        height: imageHeight,
      });

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfBlobUrl);
    } else {
    }
  };

  return (
    <div>
      <div className="HandleRefButton">
        {/* <div onClick={() => HandleSignPdf()}>Sign</div> */}
        <div className="HandleRefButton" onClick={() => loadPdf()}>
          Refresh
        </div>
      </div>
      {isSign && <SignaturePad onSave={handleSignatureSave} />}
      <div style={{ height: "90vh" }}>
        {isLoading ? (
          <div className="align-rt">
            Your PDFs are being merged and loaded...
          </div>
        ) : (
          pdfurl && (
            <iframe title="PDF" src={pdfurl} width="100%" height="100%" />
          )
        )}
        <div className="align-rt" style={{ color: "red" }}>
          {isNodata}
        </div>
      </div>
    </div>
  );
}
