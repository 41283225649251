import React, {useState } from 'react';
import {useNavigate } from 'react-router-dom';
import "./ResetPassword.css";
import LoginHeader from "../../../framework/header/HeaderLogin/LoginHeader";
import LoginFooter from "../../../framework/footer/LoginFooter";
// import { putResetpaswd } from '../../../../services/AuthService';
// import { BsEyeSlash, BsEye } from "react-icons/bs";
import { LOGIN_CONST } from "../../../components/Constants";
// import { userSetUp } from '../../../../services/SettingsServices';
import { ChangePass, } from '../../../../services/AuthService';
import AlertModal from './AlertModal';


function ResetPassword() {
  const navigate = useNavigate();
  // const location = useLocation()
  // const loggedemail = location?.state?.emailOrPhone
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState("");
  // const [buttonText, setButtonText] = useState("Reset Password");
  // const [confirmPassVisible, setConfirmPassVisible] = useState(false);
  

  const [rstinputs,setRstinputs] = useState({
    Email:"",
    userName:"",
    // password:"",
    // confirmPassword:""
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRstinputs((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setResetError("");
  };
  const [errorMessage, setErrorMessage] = useState('');
  const validateEmailOrPhone = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{10}$/; 
    if (rstinputs?.Email.trim().length === 0) {
      setErrorMessage(''); 
      return true; 
    } else if (emailPattern.test(rstinputs?.Email) || phonePattern.test(rstinputs?.Email)) {
      setErrorMessage('');
      return true;
    } else {
      setErrorMessage(LOGIN_CONST.ERROR);
      return false; 
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const userdata = await userSetUp()
    // const Resetmail = userdata?.data?.find((user) => user?.user_name === rstinputs?.userName);
    if (!rstinputs?.userName || !rstinputs?.Email) {
      setResetError(LOGIN_CONST.RESET_ALL);
      return;
    }
    // if (rstinputs?.userName !== Resetmail?.user_name) {
    //   setResetError(LOGIN_CONST.RST_USER);
    //   return;
    // }
    // if (rstinputs?.Email !== Resetmail?.email_id) {
    //   setResetError(LOGIN_CONST.RST_MAIL);
    //   return;
    // }
    // if (rstinputs?.password !== rstinputs?.confirmPassword) {
    //   setResetError(LOGIN_CONST.RESET1);
    //   return;
    // }
    if (rstinputs?.Email && rstinputs?.userName && validateEmailOrPhone()) {
      HandleInvite(rstinputs?.Email,rstinputs?.userName)
      setModal(true);
      setIsProgress(true)
  }
  };
  const [showOverlay, setShowOverlay] = useState(false);
  const handleCloseOverlay = () => {
    setShowOverlay(false);
    navigate('/'); 
  };
const[isProgress,setIsProgress] = useState(false)  
const [modal,setModal] = useState(false);
const [modalMessage,setModalMessage] = useState("")
  const HandleInvite = async(Email,userName) => {
    const encryptedEmail = btoa(Email); 
    const encryptedName = btoa(userName); 
    let obj = {
    
      path: `${window?.location?.origin}/#/resetPassword/${encryptedName}/${encryptedEmail}`
    } 
    try{
      let newObj = {
        content: `<a href="${obj?.path}" target="_blank">${obj?.path}</a>`
     }
      const response = await ChangePass(Email,userName,newObj);
       if(response){
        setIsProgress(false)
        setModalMessage(LOGIN_CONST.ALERT_MSG)
       }
       else{
        setIsProgress(false)
        setModalMessage("some error occured")
         
      }
    }catch(err){
      setModalMessage(`${err}`)
    }
    
  }
  // const handleNavigation =()=>{
  //   navigate('/'); 
  // }
  return (
    <div className="page-container">
      <div className="content-wrap">
        <LoginHeader />
        <div className="App">
          <div className="fgtpaswd-form mt-1">
          <div className="login_box">
            <div className="fgtpaswd-hd-main">{LOGIN_CONST.RESET_PWD}</div>
            <form onSubmit={handleSubmit}>
              <div className="fgtpaswd-hd-sub-main mt-4">
                {/* {LOGIN_CONST.RESET_PWD1} */}
              </div>
              <div style={{ marginTop: '1em' }} />
              <div className='sectn1 input-with-icon'>
                <input
                  className="fgtpaswd-input mt-2"
                  type="text"
                  name="userName"
                  value={rstinputs?.userName}
                  onChange={handleInputChange}
                  placeholder="Enter User Name"
                />
              </div>
              <div className='sectn1 input-with-icon'>
                <input
                  className="fgtpaswd-input mt-2"
                  type="email"
                  name="Email"
                  value={rstinputs?.Email}
                  onChange={handleInputChange}
                  placeholder="Enter Email"
                />
              </div>
             
              {resetError && <div className="error-message">{resetError}</div>}
              {/* {resetSuccess && (
                <div className="success-message">
                  {LOGIN_CONST.NEW_PWD}
                </div>
              )} */}
               <div className="d-flex flex-column mt-2">
              <div className="button-container1">
                <button type="submit"
                > <span className="button" >Submit</span></button>
              </div>
              <div onClick={()=>navigate(-1)} style={{ alignSelf: 'flex-end',marginRight:"8px" }} className=" align-content-end justify-content-end align-items-end cursor-p">Cancel</div>
              </div>
            </form>
          </div>
          </div>
        </div>
        {showOverlay && (
          <div className="overlay">
            <div className="popup">
              <div className="heading-pop">{LOGIN_CONST.RST_PSSWD}</div>
              <div className="text-fit">{LOGIN_CONST.RST_PWD_SCSS}</div>
              <div className="close-button-container">
                <button onClick={handleCloseOverlay}>Ok</button>
              </div>
            </div>
          </div>
        )}     
      </div>
      <LoginFooter />
      <AlertModal 
      isOpen={modal}
      message={modalMessage}
      message_head={LOGIN_CONST.ALERT_HEAD}
      onClose={()=>{setModal(false)}}
      isProgress={isProgress}
      />
    </div>
  );
}

export default ResetPassword;
