import React, { useEffect, useState } from "react";
import NotesIcon from "../../../common/Assets/Icons/Pen.svg";
import add from "../../../common/Assets/Icons/add.svg";
import acc from "../../../common/Assets/Icons/acct.svg";
import del from "../../../common/Assets/Icons/del.svg";
import { WORK_ORDER_CONSTANTS } from "../../../common/components/Constants";
import NoteAddModal from "../../Notes/NoteAddModal";
import ReferenceType from "../../../common/components/EnumRefType";
import { CUSTOMER, TABLE_ÍCON_SIZES } from "../../../common/components/Values";
import {
  NoteCreatFunction,
  NoteDeleteFunction,
} from "../../../services/WorkOrdersService";
import moment from "moment";
import "./CustNotes.css";
import DeleteWOModal from "../../workorders/WorkOrderDetails/DeleteWOModal";
export default function CustNotes(props) {
  const newcustid = props?.custId;
  const [custNotes, setCustNotes] = useState([]);
  useEffect(() => {
    setCustNotes(props?.data);
  }, [props?.data]);
  const [isNotesToggle, setIsNoteToggle] = useState(true);
  const [isattach, setIsAttach] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [delWOmodal, setDelWOmodal] = useState(false);
  const [noteInpObj, setNoteInpObj] = useState({
    note: "",
    title: "",
  });
  const [noteId, setNoteId] = useState(0);

  const handleNotesToggle = () => {
    setIsNoteToggle(!isNotesToggle);
  };
  const ChangeEvents = (e) => {
    const { name, value } = e.target;
    setNoteInpObj({ ...noteInpObj, [name]: value });
  };
  const HandleNoteModal = (val) => {
    setNoteInpObj({ note: "", title: "" });
    setIsBtn(val);
    setNoteId(0);
    setIsAttach(true);
  };
  const [Err, setErrr] = useState("");
  const HandleSubmit = async () => {
    let InpObj = {
      content: noteInpObj.note,
      title: noteInpObj.title,
    };
    if (InpObj.content.length > 0) {
      setIsLoad(true);
      let Response = await NoteCreatFunction(
        0,
        noteId ?? 0,
        ReferenceType?.CUSTOMER,
        "test",
        InpObj,
        newcustid
      );
      if (Response?.success) {
        setIsLoad(false);
        setIsAttach(false);
        props?.setNotesArray( noteId ? [
                ...custNotes?.map((note) =>

                  note?.note_id === noteId ? Response?.data : note
                ),
              ]
            : [...custNotes, Response?.data]
        );
      }
     
    } else {
      setErrr("Note Should not be Empty");
    }
  };
  const [isBtn, setIsBtn] = useState("");
  const [isNoteId, setIsNoteId] = useState(0);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const HandleNotesEdit = (val, item) => {
    setNoteInpObj({ ...noteInpObj, title: item?.title, note: item?.notes });
    setIsBtn(val);
    setNoteId(item?.note_id ?? 0);
    setIsAttach(true);
  };
  const HandleNoteDel = async (id) => {
    setIsHighlighted(true);
    setIsNoteId(id?.note_id);
    setDelWOmodal(true);
  };
  const HandleNoteDel2 = async () => {
    const Resp = await NoteDeleteFunction(isNoteId);
    if (Resp?.success) {
      let custFilterData = custNotes?.filter((i) => i?.note_id !== isNoteId);
      setCustNotes(custFilterData);
      setDelWOmodal(false);
    }
  };
  let techname = localStorage.getItem("userName").replace(/"/g, "");
  return (
    <>
      <div className="cust-note-sidediv ml-2 ">
        <img
          src={NotesIcon}
          className="flt-l  cursor-p"
          width={16}
          alt="details"
          onClick={() => handleNotesToggle()}
        />
        <div className="cust-note-sidediv w-100">
          <div className="mx-2 notes-span" onClick={() => handleNotesToggle()}>
            {WORK_ORDER_CONSTANTS.NOTES} ({custNotes?.length})
          </div>
        </div>
        {props?.custVal !== "create" && props?.custId && (
          <div className="myDiv1"
          style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
          >
            <img
              src={add}
              width={14}
              alt="add note"
              className="mx-1 cursor-p"
              onClick={() => HandleNoteModal("Create")}
            />
          </div>
        )}
      </div>

      {isNotesToggle ? (
        <div className=" nts-div"
        style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
        >
          {custNotes?.length > 0 ? (
            custNotes?.map((item, index) => (
              <div
                onDoubleClick={() => HandleNotesEdit("Edit", item)}
                key={index}
                className={`nts-card mb-1 ${
                  isHighlighted ? "highlightbg" : ""
                }`}
              >
                <div className="note-item-header ">
                  <div className="d-flex ">
                    <span className="user-span">
                      <img
                        src={acc}
                        className="acc-img mb-1 cursor-p"
                        alt="account note"
                      />
                      <span className="user-span">
                        {techname?.toLowerCase()?.split(" ")?.map(
                                  (word) =>
                                    word?.charAt(0)?.toUpperCase() + word?.slice(1)
                                )?.join(" ")}
                      </span>
                    </span>
                  </div>
                  <div className=" notes-txt-date">
                    {moment(item.date_created).format(
                      TABLE_ÍCON_SIZES.NOTES_DATE_FORMAT
                    )}
                    <img
                      src={del}
                      alt="del"
                      className="del-notes mx-1"
                      width={TABLE_ÍCON_SIZES.NOTES_PEN_SIZE}
                      onClick={() => HandleNoteDel(item)}
                    />
                  </div>
                  {/* <div className=" cursor-p ml-2">
                   <img
                    src={NotesIcon}
                    className="note-cust-icon cursor-p"
                    width={12}
                    alt="details"
                    onClick={() => HandleNotesEdit("Edit", item)}
                  /> 
                </div> */}
                </div>

                <span className="text-style">{item?.notes}</span>
              </div>
            ))
          ) : (
            <div className="card-descrptn">
              {WORK_ORDER_CONSTANTS.CUST_NOTES_DESC}
            </div>
          )}
        </div>
      ) : null}

      <NoteAddModal
        isOpen={isattach}
        isClose={() => setIsAttach(false)}
        WorkId={props?.custId}
        name={CUSTOMER.CUSTOMER}
        noteTitle={noteInpObj.title}
        noteDescription={noteInpObj.note}
        isLoading={isLoad}
        HandleInputs={ChangeEvents}
        isSubmit={() => HandleSubmit()}
        isBtn={isBtn}
        Err={Err}
      />
      <DeleteWOModal
        data={WORK_ORDER_CONSTANTS.DEL}
        custId={"Customer #" + props?.custId}
        Open={delWOmodal}
        Close={() => {
          setDelWOmodal(false);
          setIsHighlighted(false);
        }}
        HandleDeleteCustomer={HandleNoteDel2}
      />
    </>
  );
}
