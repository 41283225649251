import React, { useCallback, useEffect, useState } from "react";
import "./CompanyDetails.css";
import del from "../../../../common/Assets/Icons/del.svg"
import divisionIcon from "../../../../common/Assets/Icons/company_main.svg";
import { Modal } from "react-bootstrap";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import compprefe from "../../../../common/Assets/Icons/compprefe.svg";
import Close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import info from "../../../../common/Assets/Icons/Subtract.svg";
// import down from "../../../../common/Assets/Icons/DownDir.svg";
// import rite from "../../../../common/Assets/Icons/riteDir.svg";
// import downD from "../../../../common/Assets/Icons/DownDir.svg";
import plus from "../../../../common/Assets/Icons/plusW.svg";
import save from "../../../../common/Assets/Icons/save.svg";
import delw from "../../../../common/Assets/Icons/delW.svg";
// import integr from "../../../../common/Assets/Icons/integr.svg";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import {
  COMPANY_DETAILS,
  COMPANY_DETAILS_SETTINGS,
  TAGS_MODAL_VALUES,

} from "../../../../common/components/Constants";
import { SketchPicker } from "react-color";
import {
  AddPackages,
  AddThrdprtys,
  CustSettCompDet,
  DelPackages,
  DelThrdprtys,
  getCustSettCompDet,
  GetPackages,
  GetThirdprty,

} from "../../../../services/SettingsServices";
import Alerts from "../../../../common/components/Alerts/Alerts";
import ProgressBar from "../../../../common/navigation/ProgressBar";
import Division from "../Division/Division";
import plusIcon from "../../../../common/Assets/Icons/plus.svg";
import DivisionModal from "../Division/DivisionModal";
import { CompanyDetailsGet } from "../../../../services/WorkOrdersService";
import DeleteModal from "../../../../common/components/Alerts/DeleteModal";
function CompanyDetails(props) {
  useEffect(() => {
    DivisionFunGet();
  }, []);
  const [isDivision, setIsDivision] = useState(false);
  const [DivisionList, setDivisionList] = useState([]);
  const [isEdit, setIsEdit] = useState({});
  const [isVal, setIsVal] = useState("");
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);

  const HandleEditDivision = (key, item) => {
    setSelectedDivisionId(item?.division_id);
    setIsEdit(item);
    setIsDivision(true);
    setIsVal(key);
  };
  const HandleDivisionList = useCallback(
    (x) => {
      if (x) {
        setDivisionList((prevDivisionList) => [
          ...prevDivisionList?.filter((i) => i?.division_id !== x?.division_id),
          x,
        ]);
      }
    },
    [setDivisionList] // Dependencies, ensuring the function is memoized only when setDivisionList changes
  );
  useEffect(() => {
    if (isDivision) {
      HandleDivisionList();
    }
  }, [HandleDivisionList, isDivision]);
  const initialCompanyDetails = {
    sponsor_id: 0,
    name: "",
    address: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    zip_code: null,
    telephone: null,
    email: "",
    retain_communication: "",
    integration_create_equipment: "",
    customer_extra_mapping: "",
    wo_extra_mapping: "",
    equipment_extra_mapping: "",
    card_catelog_order: "",
    setting: {},
    logo: "",
    latitude: "",
    longitude: "",
    history_day: "",
    create_work_order: true,
    create_web_work_order: true,
    recent_view_count: 0,
    equipment_manual_interface: true,
    customer_extra_interface: true,
    wo_extra_interface: true,
    equipment_extra_interface: true,
    knowledge_base_url: "",
    integration_details: {},
    sync_time: "",
    third_party_id: "",

  };
  const [colorSelector, setColorSelector] = useState({});
  const [colPckr, setColPckr] = useState(false);
  const [compDet, setCompDet] = useState("");
  const HandleCompDet = (item) => {
    setCompDet(item);
  };
  const DivisionFunGet = async () => {
    let Res = await CompanyDetailsGet();

    if (Res?.success) {
      setDivisionList(Res?.data);
    }
  };
  const [compPref, setCompPref] = useState("");
  const HandleCompPref = (item) => {
    setCompPref(item);
  };

  // const [integCompDet, setIntegCompDet] = useState("");
  // const HandleIntegCompDet = (item) => {
  //   setIntegCompDet(item);
  // };

  const [spnsrError, setSpnsrError] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [prevSavedData, setPrevSavedData] = useState(null);
  const [spnsrData, setSpnsrData] = useState(initialCompanyDetails);
  const [isModified, setIsModified] = useState(false);

  const HandleSaveCompDet = async () => {
    let eqptCDerrors = [];
    let equipmentKeys = Object.keys(spnsrData);
    const skipValidation = [
      "retain_communication",
      "integration_create_equipment",
      "customer_extra_mapping",
      "wo_extra_mapping",
      "equipment_extra_mapping",
      "card_catelog_order",
      "logo",
      "latitude",
      "longitude",
      "history_day",
      "knowledge_base_url",
      "sync_time",
      "third_party_id",
      "create_web_work_order",
      "create_work_order",
      "customer_extra_interface",
      "division_id",
      "equipment_extra_interface",
      "equipment_manual_interface",
      "integration_details",
      "recent_view_count",
      "setting",
      "wo_extra_interface",
    ];
    const isDataModified =
      JSON.stringify(spnsrData) !== JSON.stringify(prevSavedData);
    if (!isDataModified) {
      return;
    }
    if (equipmentKeys.length > 0) {
      equipmentKeys.forEach((key) => {
        if (!skipValidation.includes(key)) {
          if (typeof spnsrData[key] === "string") {
            if (spnsrData[key].length === 0 || spnsrData[key] === null) {
              const existingError = eqptCDerrors.find(
                (err) => err.error === key
              );
              if (!existingError) {
                eqptCDerrors.push({
                  error: key,
                  message: `${key}`,
                });
              }
            }
            if (key === "telephone" && spnsrData[key].length > 0) {
              if (!/^\d{1}-\d{3}-\d{3}-\d{4}$/.test(spnsrData[key])) {
                const existingError = eqptCDerrors.find(
                  (err) => err.error === key
                );
                if (!existingError) {
                  eqptCDerrors.push({
                    error: key,
                    message: `${key}`,
                  });
                }
              }
            }

            if (key === "email" && spnsrData[key].length > 0) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(spnsrData[key])) {
                const existingError = eqptCDerrors.find(
                  (err) => err.error === key
                );
                if (!existingError) {
                  eqptCDerrors.push({
                    error: key,
                    message: `${key}`,
                  });
                }
              }
            }
          }
          if (typeof spnsrData[key] === "object") {
            if (spnsrData[key] === null) {
              eqptCDerrors.push({
                error: key,
                message: `${key}`,
              });
            }
          }
        }
      });
    }
    if (eqptCDerrors?.length === 0) {
      let InpObj = {
        sponsor_id: spnsrData?.sponsor_id,
        name: spnsrData?.name,
        address: spnsrData?.address,
        address_2: spnsrData?.address_2,
        city: spnsrData?.city,
        zip_code: spnsrData?.zip_code,
        state: spnsrData?.state,
        country: spnsrData?.country,
        telephone: spnsrData?.telephone,
        email: spnsrData?.email,
      };
      const response = await CustSettCompDet(InpObj);
      if (response) {
        alert("saved");
        setPrevSavedData(spnsrData);
      }
    } else {
      setErrorModal(true);
    }
    setSpnsrError(eqptCDerrors);
  };

  const onDiscard = () => {
    if (!isModified) return;
    setSpnsrData({ ...prevSavedData });
    setIsModified(false);
    setSpnsrError([]);
  };

  const [copmspsrdata, setCompspsrData] = useState(null);
  const [copmspsrId, setCompspsrid] = useState(null)
  const [isProcess, setIsProcess] = useState(false);
  const getCompanyDetails = async () => {
    setIsProcess(true);
    const response = await getCustSettCompDet();
    setCompspsrid(response?.data?.sponsor_id)
    if (response?.success) {
      setCompspsrid(response?.data?.sponsor_id);
      setIsProcess(false);
      const responseData = response?.data;
      const newData = {
        sponsor_id: responseData.sponsor_id,
        name: responseData?.name || "",
        address: responseData.address || "",
        address_2: responseData.address_2 || "",
        city: responseData.city || "",
        state: responseData.state || "",
        country: responseData.country || "",
        zip_code: responseData.zip_code || null,
        telephone: responseData.telephone,
        email: responseData.email,
        retain_communication: "",
        integration_create_equipment: "",
        customer_extra_mapping: "",
        wo_extra_mapping: "",
        equipment_extra_mapping: "",
        card_catelog_order: "",
        setting: {},
        logo: "",
        latitude: "",
        longitude: "",
        history_day: "",
        create_work_order: true,
        create_web_work_order: true,
        recent_view_count: 0,
        equipment_manual_interface: true,
        customer_extra_interface: true,
        wo_extra_interface: true,
        equipment_extra_interface: true,
        knowledge_base_url: "",
        integration_details: {},
        sync_time: "",
        third_party_id: "",
        division_id: responseData.division_id || 0,
      };
      setSpnsrData(newData);
      setPrevSavedData(newData);
      setIsModified(false);
      setIsProcess(false);
    }
  };
  useEffect(() => {
    getCompanyDetails();
  }, []);

  const updateCompanyDetails = (key, value) => {
    setSpnsrData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setIsModified(value !== prevSavedData?.[key]);
  };

  const handlePhoneChange = (value) => {
    let onlyNumbers = value.replace(/[^\d]/g, "");
    if (onlyNumbers.length <= 1) {
      updateCompanyDetails("telephone", onlyNumbers);
      return;
    }
    let formattedValue = `${onlyNumbers.slice(0, 1)}`;
    if (onlyNumbers.length > 1) {
      formattedValue += `-${onlyNumbers.slice(1, 4)}`;
    }
    if (onlyNumbers.length > 4) {
      formattedValue += `-${onlyNumbers.slice(4, 7)}`;
    }
    if (onlyNumbers.length > 7) {
      formattedValue += `-${onlyNumbers.slice(7, 11)}`;
    }
    updateCompanyDetails("telephone", formattedValue);
  };

  const handleEmailChange = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    updateCompanyDetails("email", value);
    if (!emailRegex.test(value) && value.trim() !== "") {
      setSpnsrError((prevErrors) => [
        ...prevErrors.filter((err) => err.error !== "email"),
        { error: "email", message: "Invalid email format" },
      ]);
    } else {
      setSpnsrError((prevErrors) =>
        prevErrors.filter((err) => err.error !== "email")
      );
    }
  };


  const getSpnsrErr = (errorSkey) => {
    return spnsrError?.some((err) => err.error === errorSkey);
  };

  const [addCompLogo, setAddCompLogo] = useState(false);

  const HandleDivisionModal = (key, item) => {
    setIsDivision(true);
    setIsEdit(item);
    setIsVal(key);
  };

  const closeDivisionModal = () => {
    setIsDivision(false);

  };

  const getthirdprty = async () => {
    const response = await GetThirdprty();
    setIsThirdprtys(response?.data?.packageData);
  };

  useEffect(() => {
    getthirdprty();
  }, []);
  const getpackage = async () => {
    const response = await GetPackages();
    setIsPackages(response?.data?.packageData);
  };
  useEffect(() => {
    getpackage();
  }, []);
  const [FldStpSts, setFldStpSts] = useState(false);
  const [thdprty, setThdprty] = useState(false);
  const [isthirdprtys, setIsThirdprtys] = useState([])
  const [ispackages, setIsPackages] = useState([])
  const [thrdprtymodal, setThrdprtymodal] = useState(false)
  const [editWoStatusMod, setEditWoStatusMod] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [hoveredpckgIndex, setPckgHoveredIndex] = useState(null);
  const [hoveredthrdIndex, setThrdHoveredIndex] = useState(null);
  const [thrdclickedIndex, setThrdClickedIndex] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [thrddeleteModal, seTthrdDeleteModal] = useState(false)
  const [modname, setmodname] = useState(false)
  const [thrdmodname, setThrdmodname] = useState(false)
  // const [thrditemToDelete, setthrdItemToDelete] = useState({});
  // const [itemToDelete, setItemToDelete] = useState({});
  const HandleFldStpSts = () => setFldStpSts(!FldStpSts);
  const HandleThdprty = () => setThdprty(!thdprty)
  // const [selectedPackageIndex, setSelectedPackageIndex] = useState(null);

  const [packages, setPackages] = useState({
    description: "",
    sponsor_id: "",
    package_id: "",
  })
  const [thrdprty, setThrdprty] = useState({
    third_party_id: "",
    third_party_name: ""
  })
  const handleThrdInputChange = (e) => {
    const { name, value } = e.target;
    setThrdprty((prevthrdprty) => ({
      ...prevthrdprty,
      [name]: value,
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPackages((prevPackages) => ({
      ...prevPackages,
      [name]: value,
    }));
  };

  const HandleOpenpckgmod = () => {
    setClickedIndex(null)
    setmodname(true)
    setPackages({
      description: "",
      sponsor_id: "",
      package_id: "",
    });
    setEditWoStatusMod(true);
  };
  const HandleOpenthrdprty = () => {
    setThrdClickedIndex(null)
    setThrdmodname(true)
    setThrdprty({
      third_party_id: "",
      third_party_name: "",
    });
    setThrdprtymodal(true);
  };
  // const [pckgId, setPckgid] = useState(null)
  // const [thrdid, setThirdid] = useState(null)
  const handlePackageClick = (item, index) => {
    // setPckgid(item?.package_id)
    setmodname(false)
    setClickedIndex(item?.package_id)
    setPackages({
      description: item.description,
      sponsor_id: item.sponsor_id,
      package_id: item.package_id,
    });
    // setSelectedPackageIndex(index);
    setEditWoStatusMod(true);
  };
  const handleThrdPackageClick = (item, index) => {
    // setThirdid(item?.third_party_id)
    setThrdprtymodal(false)
    setThrdClickedIndex(item?.third_party_id)
    setThrdprty({
      third_party_id: item.third_party_id,
      third_party_name: item.third_party_name,
    });
    // setSelectedPackageIndex(index);
    setThrdprtymodal(true);
  };
  const addNewPckg = async () => {
    let Obj =
    {
      description: packages?.description,
      sponsor_id: copmspsrId,
      package_id: packages?.package_id,
    }
    try {
      let response;
      response = await AddPackages(Obj)
      if (response.success) {
        const newObj = {
          description: response?.data?.description,
          sponsor_id: response?.data?.sponsor_id,
          package_id: response?.data?.package_id
        };
        if (newObj?.package_id > 0) {
          setIsPackages([...ispackages?.filter(
            (i) => i?.package_id !== response?.data?.package_id
          ),
            newObj,
          ]);
        } else {
          setIsPackages([...ispackages, newObj]);
        }
      }
      setPackages({
        description: "",
        sponsor_id: "",
        package_id: "",
      });
      // setSelectedPackageIndex(null);
      setEditWoStatusMod(false);
    } catch (error) {
    }
  }
  const addThrdprty = async () => {
    let Obj = {
      third_party_id: thrdprty.third_party_id,
      third_party_name: thrdprty.third_party_name,
    };
    try {
      let response;
      response = await AddThrdprtys(Obj);
      if (response.success) {
        const newObj = {
          third_party_id: response?.data?.integration?.third_party_id,
          third_party_name: response?.data?.integration?.third_party_name,
          customer_address: response?.data?.integration?.customer_address,
          customer_mapping: response?.data?.integration?.customer_mapping,
          wo_mapping: response?.data?.integration?.wo_mapping,
          contact_mapping: response?.data?.integration?.contact_mapping,
          equipment_mapping: response?.data?.integration?.equipment_mapping,
          step_field: response?.data?.integration?.step_field,
          integration_details: response?.data?.integration?.integration_details,
        };
        if (newObj?.third_party_id > 0) {
          setIsThirdprtys([
            ...isthirdprtys?.filter(
              (i) => i?.third_party_id !== response?.data?.integration?.third_party_id
            ),
            newObj,
          ]);
        } else {
          setIsThirdprtys([...isthirdprtys, newObj]);
        }
      }
      setThrdprty({
        third_party_id: "",
        third_party_name: "",
      });
      // setSelectedPackageIndex(null);
      setThrdprtymodal(false);
    } catch (error) { }
  };


  const [delitem, setDelitem] = useState([])
  const HandleDeletepckg = async (type, item, index, e) => {
    // setItemToDelete(item)
    // setSelectedPackageIndex(index);
    setDeleteModal(true);
    setDelitem(item)
  };
  const [delthrditem, setThrdDelitem] = useState([])
  const HandlethrdDeletepckg = async (type, item, index, e) => {
    // setthrdItemToDelete(item)
    // setSelectedPackageIndex(index);
    seTthrdDeleteModal(true);
    setThrdDelitem(item)
  };
  const handlemoddelete = async () => {
    let pckgId = delitem?.package_id;
    const Responce = await DelPackages(pckgId);
    if (Responce?.data?.success) {
      let FilteredData = ispackages?.filter((dlitem) => dlitem !== delitem);
      setIsPackages(FilteredData)
      setDeleteModal(false);
    }
  };
  const handlethrdmoddelete = async () => {
    let thrdId = delthrditem?.third_party_id;
    const Responce = await DelThrdprtys(thrdId);
    if (Responce?.data?.success) {
      let FilteredData = isthirdprtys?.filter(
        (dlitem) => dlitem !== delthrditem
      );
      setIsThirdprtys(FilteredData);
      seTthrdDeleteModal(false);
    }
  };
  const handlemodclose = () => {
    seTthrdDeleteModal(false)
    // setSelectedPackageIndex(null);
    setThrdprtymodal(false)
    setEditWoStatusMod(false)
    setDeleteModal(false);
    setClickedIndex(null)
    setThrdClickedIndex(null)
  }


  return (
    <>
      <div style={{ height: "40px" }}>
        <div
          style={
            {
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
            }
          }
          className="line-align-bet  flx-wrp mt-1 top_header"
        >
          <span className="sub-hdr-sett ml-1">
            {COMPANY_DETAILS_SETTINGS.COMPANY_DETAILS}{" "}
          </span>
          <span className="d-flex ">
            <button className="sett-save-btn" onClick={() => onDiscard()} disabled={!isModified}>
              <span className=" ">{TAGS_MODAL_VALUES.DISCARD} </span>{" "}
              <img
                alt=""
                className="ml-2"
                src={delw}
                width={12.57}
                height={14.67}
              />
            </button>
            <button
              id="dltbtn"
              className="sett-save-btn mx-2"
              onClick={() => HandleSaveCompDet()}
            >
              <span className=" ">{TAGS_MODAL_VALUES.SAVE} </span>{" "}
              <img
                alt=""
                className="ml-2 "
                src={save}
                width={12.8}
                height={12.8}
              />
            </button>
          </span>
        </div>
      </div>
      {/* <div className="pagescroll " > */}
      {/* <div className="head-stick" > */}
      {/* <CommonHeader theme={spnsrData} /> */}
      {/* <SettingsHeader data={"details"} /> */}
      {/* <hr style={{ margin: "0px" }} /> */}

      {/* </div> */}
      <div className="pagescroll">
        <>
          {isProcess ? (
            <div className="pro-bar-style">
              <ProgressBar />
            </div>
          ) : (
            <div>
              <div
                className="m-2 cursor-p"
                style={{ border: "1px solid white", backgroundColor: "white" }}
              >
                <div
                  id="details-section" className="clp_header"
                  style={{
                    border: "1px solid white",
                    backgroundColor: "white",
                  }}
                  onClick={() =>
                    HandleCompDet(
                      compDet === "thmPrfOn" ? "thmPrfOff" : "thmPrfOn"
                    )
                  }
                >
                  <span className="cursor-p">
                    {/* {compDet === "thmPrfOn" ? (
                    <img
                      alt="open"
                      src={rite}
                      onClick={() => HandleCompDet("thmPrfOff")}
                    />
                  ) : (
                    <img
                      alt="open"
                      className="mx-1"
                      src={down}
                      onClick={() => HandleCompDet("thmPrfOn")}
                    />
                  )} */}
                    <span className="sett-crd-hdr-txt">
                      {" "}
                      <img src={info} alt="compdet" />{" "}
                      {COMPANY_DETAILS_SETTINGS.DETAILS}{" "}
                    </span>
                  </span>
                </div>

                {compDet === "thmPrfOn" ? (
                  ""
                ) : (

                  <div className="clp_body">
                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.COMPANY_NAME}:
                      </span>

                      <input
                        placeholder="Company Name"
                        className={`inpt-CD-det ${getSpnsrErr("name") ? "border-danger" : ""}`}
                        value={spnsrData?.name || ""}
                        onChange={(e) =>
                          updateCompanyDetails("name", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("name", "Company Name")}{" "}
                      </span> */}
                    </div>

                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.ADDRESS}:
                      </span>
                      <input
                        placeholder="Address"
                        className={`inpt-CD-det ${getSpnsrErr("address") ? "border-danger" : ""}`}
                        value={spnsrData?.address || ""}
                        onChange={(e) =>
                          updateCompanyDetails("address", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("address", "Address")}{" "}
                      </span> */}
                    </div>

                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.ADDRESS_2}:
                      </span>
                      <input
                        placeholder="Addres 2"
                        className={`inpt-CD-det ${getSpnsrErr("address_2") ? "border-danger" : ""}`}
                        value={spnsrData?.address_2 || ""}
                        onChange={(e) =>
                          updateCompanyDetails("address_2", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("address_2", "Address 2")}{" "}
                      </span> */}
                    </div>

                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.CITY}:
                      </span>
                      <input
                        placeholder="City"
                        className={`inpt-CD-det ${getSpnsrErr("city") ? "border-danger" : ""}`}
                        value={spnsrData?.city || ""}
                        onChange={(e) =>
                          updateCompanyDetails("city", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("city", "City")}{" "}
                      </span> */}
                    </div>

                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">{COMPANY_DETAILS.ZIP}:</span>
                      <input
                        placeholder="zip_code"
                        className={`inpt-CD-det ${getSpnsrErr("zip_code") ? "border-danger" : ""}`}
                        value={spnsrData?.zip_code || ""}
                        onChange={(e) =>
                          updateCompanyDetails("zip_code", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("zip_code", "Zip Code")}{" "}
                      </span> */}
                    </div>

                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.STATE}:
                      </span>
                      <input
                        placeholder="State"
                        className={`inpt-CD-det ${getSpnsrErr("state") ? "border-danger" : ""}`}
                        value={spnsrData?.state || ""}
                        onChange={(e) =>
                          updateCompanyDetails("state", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("state", "State")}{" "}
                      </span> */}
                    </div>
                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.COUNTRY}:
                      </span>
                      <input
                        placeholder="Country"
                        className={`inpt-CD-det ${getSpnsrErr("country") ? "border-danger" : ""}`}
                        value={spnsrData?.country || ""}
                        onChange={(e) =>
                          updateCompanyDetails("country", e.target.value)
                        }
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("country", "Country")}{" "}
                      </span> */}
                    </div>
                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.CONTACT_NUMBER}:
                      </span>
                      <input
                        placeholder="1-555-555-5555"
                        className={`inpt-CD-det ${getSpnsrErr("telephone") ? "border-danger" : ""
                          }`}
                        value={spnsrData?.telephone || ""}
                        onChange={(e) => handlePhoneChange(e.target.value)}
                        pattern="\d{1}-\d{3}-\d{3}-\d{4}"
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("telephone", "Telephone")}{" "}
                      </span> */}
                    </div>

                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.EMAIL_ADD}:
                      </span>
                      <input
                        placeholder="abc@9pk.com"
                        className={`inpt-CD-det ${getSpnsrErr("email") ? "border-danger" : ""}`}
                        value={spnsrData?.email || ""}
                        onChange={(e) => handleEmailChange(e.target.value)}
                      />
                      {/* <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("email", "Email")}{" "}
                      </span> */}
                    </div>
                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.PRIMY_CONTACT}:
                      </span>
                      <input
                        placeholder="Address"
                        className="inpt-CD-det"
                        value={"John Hancock"}
                        onChange={(e) =>
                          updateCompanyDetails("", e.target.value)
                        }
                      />
                      <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("address", "Address")}{" "}
                      </span>
                    </div>
                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.NOTE_S}:
                      </span>
                      <input
                        placeholder="Enter Notes"
                        className="inpt-CD-det"
                        // value={"United States of America"}
                        onChange={(e) =>
                          updateCompanyDetails("notes", e.target.value)
                        }
                      />
                      <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("notes", "Notes")}{" "}
                      </span>
                    </div>
                    <div className="d-flex mt-1 line-align-bet flx-wrp">
                      <span className="lbl-ipt-CD">
                        {COMPANY_DETAILS.LICENSE_CNT}:
                      </span>
                      <div className="inpt-CD-adm d-flex" style={{marginRight:"22%"}}>
                        <span className="lbl-inpt-LIC">
                          {COMPANY_DETAILS.ADMINISTRATION}
                        </span>
                        :&nbsp;
                        <input
                          className="inpt-CD-det"
                          style={{
                            width: "10%",
                            height: "28px",
                            borderColor: "#E9EBEC",
                          }}
                          placeholder=""
                          value={1}
                        />{" "}
                        &nbsp; &nbsp;
                        <span className="lbl-inpt-LIC">
                          {COMPANY_DETAILS.TECHNICIAN}:&nbsp;
                        </span>
                        <input
                          className="inpt-CD-det"
                          style={{
                            width: "10%",
                            height: "28px",
                            borderColor: "#E9EBEC",
                          }}
                          placeholder=""
                          value={25}
                        />
                      </div>
                      <span style={{ marginLeft: "20%" }}>
                        {" "}
                        {getSpnsrErr("license_count", "LicenseCount")}{" "}
                      </span>
                    </div>

                  </div>
                )}
              </div>
              <div className="cursor-p m-2">
                <div
                  id="company-preferences-section"
                  className="company-preferences clp_header"
                >
                  <div className="d-flex align-items-center" onClick={() => HandleFldStpSts()}>
                    <img
                      src={divisionIcon}
                      alt="comppref"
                      className="icon-left"
                    />
                    <span className="sett-crd-hdr-txt mx-2 mt-2">division-card
                      {COMPANY_DETAILS_SETTINGS.DIVISION}
                    </span>
                  </div>

                  <img
                    src={plusIcon}
                    alt="plus icon"
                    className="plus-icon"
                    onClick={() => HandleDivisionModal("create", {})}
                  />
                </div>
                {FldStpSts ? (
                  ""
                ) : (
                  <Division 
                    DivisionList={DivisionList}
                    HandleEditDivision={HandleEditDivision}
                    selectedDivisionId={selectedDivisionId}

                  />
                )}
              </div>
              <div className="m-2"
              //  ref={(ref) => (contentRefs.current[2] = ref)}
              >
                <div className="p-1  d-flex line-align-bet clp_header">
                  <div className="cursor-p" onClick={() => HandleFldStpSts()}>
                    <img alt="adj" className="mb-1" src={divisionIcon}  />
                    <span className="sett-crd-hdr-txt ml-2">
                      {/* {WORK_ORDER_SETUP.WORK_ORDER_STATUS} */}
                      Packages
                    </span>
                  </div>
                  <div className="cursor-p">
                    <img
                      src={Add_Main}
                      alt="Add_Main"
                      onClick={() => HandleOpenpckgmod()}
                    />
                  </div>
                </div>

                {FldStpSts ? (
                  ""
                ) : (
                  <div className="p-1">
                    <div className="card">
                      {ispackages?.length > 0 ? (
                        ispackages
                          ?.sort((a, b) =>
                            a.package_id > b.package_id ? 1 : -1
                          )
                          ?.map((item, index) => (
                            <div
                              onMouseEnter={() =>
                                setPckgHoveredIndex(item?.package_id)
                              }
                              onMouseLeave={() => setPckgHoveredIndex(null)}
                              key={index}
                              className="line-align-bet w-100 p-2 cursor-p"
                              style={{
                                borderBottom: "1px solid  rgb(209, 202, 202)",
                                backgroundColor:
                                  clickedIndex === item?.package_id
                                    ? "#D1CACA"
                                    : hoveredpckgIndex === item?.package_id
                                      ? "var(--neutral-200, #edeff2)"
                                      : "initial",
                              }}
                            >
                              <span
                                onDoubleClick={() =>
                                  handlePackageClick(item, index)
                                }
                                className="w-100 sett-fs-wo-inp center-line-adj cursor-p "
                              >
                                {item?.description}
                              </span>
                              <img
                                src={del}
                                alt=""
                                onClick={(e) =>
                                  HandleDeletepckg("Status", item, index, e)
                                }
                                className="icon-sz cursor-p"
                              />
                            </div>
                          ))
                      ) : (
                        <div className="align-rt w-100 p-2">
                          <span className="err-data-wfsetup">
                            There is no data{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="m-2"
              //  ref={(ref) => (contentRefs.current[2] = ref)}
              >
                <div className="p-1  d-flex line-align-bet clp_header">
                  <div className="cursor-p" onClick={() => HandleThdprty()}>
                    <img alt="adj" className="mb-1" src={divisionIcon} />
                    <span className="sett-crd-hdr-txt ml-2 ">
                      {/* {WORK_ORDER_SETUP.WORK_ORDER_STATUS} */}
                      Third Party
                    </span>
                  </div>
                  <div className="cursor-p">
                    <img
                      src={Add_Main}
                      alt="Add_Main"
                      onClick={() => HandleOpenthrdprty()}
                    />
                  </div>
                </div>

                {thdprty ? (
                  ""
                ) : (
                  <div className="p-1">
                    <div className="card">
                      {isthirdprtys?.length > 0 ? (
                        isthirdprtys?.sort((a, b) => (a.third_party_id > b.third_party_id ? 1 : -1))?.map((item, index) => (
                          <div
                            onMouseEnter={() => setThrdHoveredIndex(item?.third_party_id)}
                            onMouseLeave={() => setThrdHoveredIndex(null)}
                            key={index}
                            className="line-align-bet w-100 p-2 cursor-p"

                            style={{
                              borderBottom: "1px solid  rgb(209, 202, 202)",
                              backgroundColor: thrdclickedIndex === item?.third_party_id ? "#D1CACA" : hoveredthrdIndex === item?.third_party_id
                                ? "var(--neutral-200, #edeff2)"
                                : "initial"
                            }}
                          >
                            <span
                              onDoubleClick={() => handleThrdPackageClick(item, index)}
                              className="w-100 sett-fs-wo-inp center-line-adj cursor-p "
                            >
                              {item?.third_party_name}
                            </span>
                            <img
                              src={del}
                              alt=""
                              onClick={(e) => HandlethrdDeletepckg("Status", item, index, e)}
                              className="icon-sz cursor-p"
                            />
                          </div>
                        ))
                      ) : (
                        <div className="align-rt w-100 p-2">
                          <span className="err-data-wfsetup">There is no data </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="m-2 cursor-p"
                style={{ border: "1px solid white", backgroundColor: "white" }}
              >
                <div
                  id="company-preferences-section" className="clp_header"
                  style={{
                    border: "1px solid white",
                    backgroundColor: "white",
                  }}
                  onClick={() =>
                    HandleCompPref(
                      compPref === "thmPrfOn" ? "thmPrfOff" : "thmPrfOn"
                    )
                  }
                >
                  <span className="cursor-p">
                    <span className="sett-crd-hdr-txt">
                      {" "}
                      <img src={compprefe} alt="comppref" />{" "}
                      {COMPANY_DETAILS_SETTINGS.COMPANY_PREFERENCES}
                    </span>
                  </span>{" "}
                </div>
                {compPref === "thmPrfOn" ? (
                  ""
                ) : (
                  <div className="line-align-bet0 flx-wrp clp_body">
                    <div className="container ml-3">
                      <br />{" "}
                      <div className="row comp-Pref-txt g-2">
                        <div>
                          <label className="lbl-ipt-3">
                            Number of Work Orders Displayed on Mobile:
                          </label>
                          <input
                            className="comp-ipt cursor-p ml-2"
                            type="number"
                            placeholder=""
                          />{" "}
                          &nbsp;&nbsp;
                          <label className="lbl-ipt-3">
                            Total Amount Of Time (days):
                          </label>
                          <input
                            className="comp-ipt  cursor-p  ml-2"
                            type="number"
                            placeholder=""
                          />
                        </div>
                        <div>
                          <label className="lbl-ipt-3">
                            Recently Viewed Control:
                          </label>
                          <input
                            className="comp-ipt  cursor-p ml-2 "
                            type="number"
                            placeholder=""
                          />{" "}
                          <span>Items displayed</span>
                        </div>
                        <div className="d-flex">
                          <div>
                            <span className="lbl-ipt-3">
                              Enable Knowledge Base:
                            </span>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            {" "}
                            <input
                              className="WoFldsCheckbox "
                              type="checkbox"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>

        <Modal
          centered
          show={colPckr}
          onHide={() => setColPckr(false)}
          size="sm"
        >
          <Modal.Header className="attach-media-note modal-bg-color modal-header-height px-2">
            <span> </span>
            <img
              className="modal-header-icon flt-r"
              onClick={() => {
                setColPckr(false);
                setColorSelector({});
              }}
              alt=""
              src={close}
            />
          </Modal.Header>
          <Modal.Body>
            <SketchPicker
              value={colorSelector.value}
              className="ml-4"
              onChange={(updatedColor) =>
                setSpnsrData({
                  ...spnsrData,
                  [colorSelector.key]: updatedColor.hex,
                })
              }
            />
            <hr className="m-2" />
            <div>
              <div className="row line-align-even flx-wrp">
                <button
                  className="col-5  modal-footer-save-cancel-btns"
                  style={{}}
                  onClick={() => {
                    setColPckr(false);
                    setColorSelector({});
                  }}
                >
                  <img src={plus} className="skw-45" alt="cancel" /> Cancel
                </button>
                <button
                  className="col-5  modal-footer-save-cancel-btns"
                  onClick={() => {
                    setColPckr(false);
                    setColorSelector({});
                  }}
                >
                  {" "}
                  <img src={save} alt="save" width={12} /> Save
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={addCompLogo}
          onHide={() => setAddCompLogo(false)}
          size="sm"
        >
          <Modal.Header className=" modal-bg-color modal-header-height attach-media-note px-2">
            <span className="center-line-adj">Add Logo</span>
            <img
              src={close}
              alt="close"
              onClick={() => setAddCompLogo(false)}
              className="modal-header-icon"
            />
          </Modal.Header>
          <Modal.Body className="txt-align-c centerings">
            <div className="add-logo-space">
              <div className="centerings">11</div>
            </div>
            <button className="chose-logo-btn  mt-2"> Choose File</button>
          </Modal.Body>
        </Modal>

        <Alerts
          isOpen={errorModal}
          isClose={() => setErrorModal(false)}
          data={spnsrError}
        />
        <DivisionModal
          setSelectedDivisionId={setSelectedDivisionId}
          isOpen={isDivision}
          isClose={() =>
            closeDivisionModal()
          }
          data={isEdit}

          isVal={isVal}
          HandleDivisionList={HandleDivisionList}
          copmspsrId={copmspsrId}
        />
        {/* </div> */}
        <Modal
          show={editWoStatusMod}
          onHide={() => {
            setEditWoStatusMod(false);
          }}
          centered
        >
          <Modal.Header className="modal-bg-color modal-header-height">
            <span style={{ color: "white" }}>
              {modname !== true ? "Edit Package" : "Create New Package"}
            </span>
            <img
              src={Close}
              alt="close"
              onClick={() => {
                setEditWoStatusMod(false);
              }}
              className="modal-header-icon"
            />
          </Modal.Header>
          <Modal.Body className="p-2">
            <div>
              <label>Description</label>
              <input
                placeholder="Input name"
                className="w-100 crt-wo-status  "

                name="description"
                value={packages.description}
                onChange={handleInputChange}
              />
            </div>
          
          </Modal.Body>
          <div
              className="mt-2 model_footer"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div
                className="modal-footer-save-cancel-btns"
                onClick={() => addNewPckg()}
              >
                Save
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                style={{ margin: 0 }}
                onClick={() => {
                  handlemodclose()
                }}
              >
                Cancel
              </div>
            </div>
        </Modal>
        <Modal
          show={thrdprtymodal}
          onHide={() => {
            setThrdprtymodal(false);
          }}
          centered
        >
          <Modal.Header className="modal-bg-color modal-header-height">
            <span style={{ color: "white" }}>
              {thrdmodname !== true ? "Edit third party" : "Create New third party"}
            </span>
            <img
              src={Close}
              alt="close"
              onClick={() => {
                setThrdprtymodal(false);
              }}
              className="modal-header-icon"
            />
          </Modal.Header>
          <Modal.Body className="p-2">
            <div>
              <label>Description</label>
              <input
                placeholder="Input name"
                className="w-100 crt-wo-status  "

                name="third_party_name"
                value={thrdprty.third_party_name}
                onChange={handleThrdInputChange}
              />
            </div>
            <div
              className="mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div
                className="modal-footer-save-cancel-btns"
                onClick={() => addThrdprty()}
              >
                Save
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                style={{ margin: 0 }}
                onClick={() => {
                  handlemodclose()
                }}
              >
                Cancel
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <DeleteModal
          Open={deleteModal}
          DelDiscription={"are you sure to delete " + delitem?.description}
          Close={() => handlemodclose()}
          HandleDelete={() => handlemoddelete()}
        />
        <DeleteModal
          Open={thrddeleteModal}
          DelDiscription={"are you sure to delete " + delthrditem?.third_party_name}
          Close={() => handlemodclose()}
          HandleDelete={() => handlethrdmoddelete()}
        />

      </div>
    </>
  );
}

export default CompanyDetails;
