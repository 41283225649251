import "./styles/GlobalStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Navigation from "./common/navigation/Navigation";
import { useState } from "react";
import {
  AppGlobalContext,
  AppGlobalContextUpdate,
} from "./common/context/AppGlobalContext";

function App() {
  const [appContext, setAppContext] = useState({
    currentWorkOrder: {},
    currentEquipments: [],
    currentnotes: [],
  });

  const updateAppState = (data) => {
    setAppContext({ ...appContext, ...data });
  };

  return (
    <div className="App">
      <AppGlobalContext.Provider value={appContext}>
        <AppGlobalContextUpdate.Provider value={updateAppState}>
          <Navigation />
        </AppGlobalContextUpdate.Provider>
      </AppGlobalContext.Provider>
    </div>
  );
}

export default App;
