import React from "react";
import { Modal } from "react-bootstrap";
import {
  TAGS_MODAL_VALUES,
  Wo_Workflow,
} from "../../../common/components/Constants";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";

export default function ReqWfAndStepModal(props) {
  return (
    <Modal
      show={props?.isStepVal}
      onHide={() => props?.isClose()}
      centered
      size="sm"
    >
      <Modal.Header className="modal-bg-color modal-header-height">
        <div className="attach-media-note">
          {!props?.isLoading ? props?.isReq : Wo_Workflow?.Load}
        </div>

        <img
          src={Close}
          alt="Close"
          className="modal-header-icon"
          onClick={() => props?.isClose()}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="centerings">
          {!props?.isLoading ? (
            <div
              className="steps-view-btn"
              onClick={() =>
                props?.isreqWf === "WfValidation"
                  ? props?.HandleRemainWf()
                  : props?.HandleRemainSteps()
              }
            >
              <span>{Wo_Workflow?.View_remain_steps}</span>
            </div>
          ) : (
            Wo_Workflow?.Load
          )}
        </div>
        {!props?.isLoading && (
          <div className="gap-1 mt-2 modal-footer-parent model_footer">
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() => props?.isClose()}
            >
              {TAGS_MODAL_VALUES.CANCEL}
            </button>
            <button
              onClick={() =>
                props?.isreqWf === "WfValidation"
                  ? props?.HandleWfConfirm()
                  : props?.handleConfirm()
              }
              className="modal-footer-save-cancel-btns"
              style={{
                pointerEvents:
                  props?.isReq === "Required Steps Exist" && "none",
                backgroundColor:
                  props?.isReq === "Required Steps Exist" && "lightgray",
                color: props?.isReq === "Required Steps Exist" && "gray",
                border:
                  props?.isReq === "Required Steps Exist" &&
                  "1px solid lightgray",
              }}
            >
              {TAGS_MODAL_VALUES.CONFIRM}
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
