import React from "react";
import "./EstimateCard.css";
import MemoryIcon from "../../common/Assets/Icons/Memory Board Icon.svg";
import ShareImg from "../../common/Assets/Images/Vectorshare.png";
import PersonImg from "../../common/Assets/Images/Buttonsperson.png";
import LocationImg from "../../common/Assets/Images/Vectorlocation.png";
import Image_Overlay_Icon from "../../common/Assets/Icons/Image_Overlay_Icon.png";
import { WORK_ORDER_CONSTANTS} from "../../common/components/Constants";
import clipboard_outline1 from "../../common/Assets/Icons/clipboard_outline1.svg";
import book_outline1 from "../../common/Assets/Icons/book_outline1.svg";
import Add_round from "../../common/Assets/Icons/Add_round.svg";
import status_filled1 from "../../common/Assets/Icons/status_filled1.svg";

const EstimateCard = ({ estimate }) => {
  const tags = Array.from({ length: 25 }, (_, index) => index + 1);

  return (
    <>
      <div className="estimate-card m-1">
        <div className="estimate-header">
          <span>
            <strong className="estimate-number">
              {estimate.estimateNumber}
            </strong>
            Michael Chan Requested {estimate.requestedTime}
          </span>
          <div className="mr-3 d-flex">
            <span className="status-font status-style mx-1">Ready to send</span>

            <button className="mx-2 mt-1 tag-btn">
              {WORK_ORDER_CONSTANTS.TAG} <img src={Add_round} width={13} alt="Add Round" />
            </button>
            <span>
              <img className="mx-2 cursor-pointer" src={MemoryIcon} alt="img" />
              <img className="mx-2 cursor-pointer" src={PersonImg} alt="img" />
              <img className="mx-2 cursor-pointer" src={LocationImg} alt="img" />
              <img className="mx-2 cursor-pointer" src={ShareImg} alt="ShareImg" />
            </span>
          </div>
        </div>

        <div className="estimate-body">
          <div className="estimate-info d-flex flex-column">
            <span className=" d-flex mx-1">
              {" "}
              <p className="address-link">
                {estimate.address}
              </p>
              <p className="est-value mx-2"> Value: {estimate.value}</p>
            </span>
            <span>
              <div>
                {tags.map((tag) => (
                  <span
                    className="tag-styles"
                    style={{
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "black",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    {" "}
                    {WORK_ORDER_CONSTANTS.TAG}
                    {`${tag}`}{" "}
                  </span>
                ))}
              </div>
              <div>
              <span className="d-flex">
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={clipboard_outline1} width={18} alt="Work Orders" style={{ marginRight: "6px" }} />
                    Attached Workorders(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={book_outline1} width={18} alt="Media Center" style={{ marginRight: "6px" }} />
                    Media center(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={status_filled1} width={18} alt="Status Filled" style={{ marginRight: "6px" }} />
                    status duration(4 hours 32 minutes)
                  </span>
                </span>
              </div>
              <span className="decs-est-card">
                {estimate.description}
              </span>
            </span>
            <div className="estimate-images">
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            </div>
          </div>
        </div>
      </div>
      <div className="estimate-card m-1">
        <div className="estimate-header">
          <span>
            <strong className="estimate-number">
              {estimate.estimateNumber}
            </strong>
            Michael Chan Requested {estimate.requestedTime}
          </span>
          <div className="mr-3 d-flex">
            <span className="status-font status-style mx-1">Ready to send</span>

            <button className="mx-2 mt-1 tag-btn">
              {WORK_ORDER_CONSTANTS.TAG}  <img src={Add_round} width={13} alt="Add Round" />
            </button>
            <span>
              <img className="mx-2" src={MemoryIcon} alt="img" />
              <img className="mx-2" src={PersonImg} alt="img" />
              <img className="mx-2" src={LocationImg} alt="img" />
              <img className="mx-2" src={ShareImg} alt="ShareImg" />
            </span>
          </div>
        </div>
        <div className="estimate-body">
          <div className="estimate-info d-flex flex-column">
          <span className=" d-flex mx-1">
              {" "}
              <p className="address-link">
                {estimate.address}
              </p>
              <p className="est-value mx-2"> Value: {estimate.value}</p>
            </span>
            <span>
              <div>
                {tags.map((tag) => (
                  <span
                    className="tag-styles"
                    style={{
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "black",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    {" "}
                    {WORK_ORDER_CONSTANTS.TAG}
                    {`${tag}`}{" "}
                  </span>
                ))}
              </div>
              <div>
              <span className="d-flex">
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={clipboard_outline1} width={18} alt="Work Orders" style={{ marginRight: "6px" }} />
                    Attached Workorders(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={book_outline1} width={18} alt="Media Center" style={{ marginRight: "6px" }} />
                    Media center(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={status_filled1} width={18} alt="Status Filled" style={{ marginRight: "6px" }} />
                    status duration(4 hours 32 minutes)
                  </span>
                </span>
              </div>
              <span className="decs-est-card">
                {estimate.description}
              </span>
            </span>
            <div  className="estimate-images">
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            </div>
          </div>
        </div>
      </div>
      <div className="estimate-card m-1">
        <div className="estimate-header">
          <span>
            <strong className="estimate-number">
              {estimate.estimateNumber}
            </strong>
            Michael Chan Requested {estimate.requestedTime}
          </span>
          <div className="mr-3 d-flex">
            <span className="status-font status-style mx-1">Ready to send</span>

            <button className="mx-2 mt-1 tag-btn">
              {WORK_ORDER_CONSTANTS.TAG} <img src={Add_round} width={13} alt="Add Round" />
            </button>
            <span>
              <img className="mx-2" src={MemoryIcon} alt="img" />
              <img className="mx-2" src={PersonImg} alt="img" />
              <img className="mx-2" src={LocationImg} alt="img" />
              <img className="mx-2" src={ShareImg} alt="ShareImg" />
            </span>
          </div>
        </div>
        <div className="estimate-body">
          <div className="estimate-info d-flex flex-column">
          <span className=" d-flex mx-1">
              {" "}
              <p className="address-link">
                {estimate.address}
              </p>
              <p className="est-value mx-2"> Value: {estimate.value}</p>
            </span>
            <span>
              <div>
                {tags.map((tag) => (
                  <span
                    className="tag-styles"
                    style={{
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "black",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    {" "}
                    {WORK_ORDER_CONSTANTS.TAG}
                    {`${tag}`}{" "}
                  </span>
                ))}
              </div>
              <div>
              <span className="d-flex">
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={clipboard_outline1} width={18} alt="Work Orders" style={{ marginRight: "6px" }} />
                    Attached Workorders(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={book_outline1} width={18} alt="Media Center" style={{ marginRight: "6px" }} />
                    Media center(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={status_filled1} width={18} alt="Status Filled" style={{ marginRight: "6px" }} />
                    status duration(4 hours 32 minutes)
                  </span>
                </span>
              </div>
              <span className="decs-est-card">
                {estimate.description}
              </span>
            </span>
            <div  className="estimate-images">
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            </div>
          </div>
        </div>
      </div>
      <div className="estimate-card m-1">
        <div className="estimate-header">
          <span>
            <strong className="estimate-number">
              {estimate.estimateNumber}
            </strong>
            Michael Chan Requested {estimate.requestedTime}
          </span>
          <div className="mr-3 d-flex">
            <span className="status-font status-style mx-1">Ready to send</span>

            <button className="mx-2 mt-1 tag-btn">
              {WORK_ORDER_CONSTANTS.TAG} <img src={Add_round} width={13} alt="Add Round" />
            </button>
            <span>
              <img className="mx-2" src={MemoryIcon} alt="img" />
              <img className="mx-2" src={PersonImg} alt="img" />
              <img className="mx-2" src={LocationImg} alt="img" />
              <img className="mx-2" src={ShareImg} alt="ShareImg" />
            </span>
          </div>
        </div>
        <div className="estimate-body">
          <div className="estimate-info d-flex flex-column">
          <span className=" d-flex mx-1">
              {" "}
              <p className="address-link">
                {estimate.address}
              </p>
              <p className="est-value mx-2"> Value: {estimate.value}</p>
            </span>
            <span>
              <div>
                {tags.map((tag) => (
                  <span
                    className="tag-styles"
                    style={{
                      backgroundColor: "#CCCCCC",
                      fontSize: "12px",
                      color: "black",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    {" "}
                    {WORK_ORDER_CONSTANTS.TAG}
                    {`${tag}`}{" "}
                  </span>
                ))}
              </div>
              <div>
              <span className="d-flex">
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={clipboard_outline1} width={18} alt="Work Orders" style={{ marginRight: "6px" }} />
                    Attached Workorders(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={book_outline1} width={18} alt="Media Center" style={{ marginRight: "6px" }} />
                    Media center(1)
                  </span>
                  <span className="files-link mx-3" style={{ cursor: 'pointer' }}>
                    <img className="mb-1" src={status_filled1} width={18} alt="Status Filled" style={{ marginRight: "6px" }} />
                    status duration(4 hours 32 minutes)
                  </span>
                </span>
              </div>
              <span className="decs-est-card">
                {estimate.description}
              </span>
            </span>
            <div className="estimate-images">
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            <img src={Image_Overlay_Icon}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimateCard;