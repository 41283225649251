import React from "react";
import AndersonImg from "../Assets/Images/anderson.png";
export default function ImageShare() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
      }}
    >
      <img
        src={AndersonImg}
        alt="AndersonImg"

        // width={"100%"}
      />
    </div>
  );
}
