import React, { useState } from "react";
import "./NoteAddModal.css";
import { Modal } from "react-bootstrap";
import Close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { NOTES_ADD_CONSTANTS } from "../../common/components/Constants";
import ProgressBar from "../../common/navigation/ProgressBar";
export default function NoteAddModal(props) {
const [error, setError] = useState("");
const handleSave = () => {
  if(!props.noteDescription){
    setError("Notes can't be saved empty");
  }else{
    setError("");
    props.isSubmit();
  }
};

const handleClose = () => {
  setError("");
  props.isClose();
}

  return (
    <>
      <Modal
        show={props.isOpen}
        onHide={props.isClose}
        onExited={() => setError("")}
        style={{
          opacity: props.validationModal === true ? 0.5 : 1,
        }}
        backdrop={true}
        centered
      >
        <Modal.Header className=" modal-bg-color modal-header-height ">
          <span className="note-add-text" style={{ textAlign: "center" }}>
            {props?.Notehedr ?
              <span> {props?.Notehedr}</span>
              :
              <span>{props?.isBtn} Note to {props?.name} #{props.WorkId}</span>
            }
          </span>
          <img
            src={Close}
            alt="close"
            className="modal-header-icon "
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {props?.isLoading ? (
            <div className="progress-position">
              <ProgressBar />
            </div>
          ) : (
            <>
              
              <div>
                <span className="note-description-text">
                  {NOTES_ADD_CONSTANTS.NOTE_DESCRIPTION}
                </span>
                <textarea
                  placeholder="Please enter description."
                  className="text-area-style"
                  value={props.noteDescription}
                  name="note"
                  onChange={(e) =>{
                    props.HandleInputs(e);
                    setError("");
                  }}
                />
              </div>
              {error && (
                <div className="err-discr-clr">{error}</div>
              )}
              {props?.ErrorNote?.length > 0 && (
                <div className="err-discr-clr">{props?.ErrorNote}</div>
              )}
            </>
          )}
          <div className="modal-footer-styles model_footer">
           <div
            className="modal-footer-save-cancel-btns"
            onClick={handleSave}
            style={{ margin: 0 }}
          >
            {NOTES_ADD_CONSTANTS.NOTE_SUBMIT}
          </div>
          <div
            className="modal-footer-save-cancel-btns mx-1"
            onClick={handleClose}
          >
            {NOTES_ADD_CONSTANTS.NOTE_CANCEL}
          </div>
          </div>
        </Modal.Body>
        
      </Modal>
    </>
  );
}
