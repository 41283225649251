import React, { useState, useEffect } from "react";
import {
  GetAttchMentByID,
  GetPdfById,
} from "../../../services/WorkOrdersService";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { Modal } from "react-bootstrap";

export default function StepsAttachments(props) {
  const [isPreview, setPreview] = useState(false);
  const [atchData, setAtchData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [wono, setWoNo] = useState(0);
  const [reattData, setResAttData] = useState(props?.data);
  useEffect(() => {
    setResAttData(props?.data);
  }, [props?.data]);
  const HandleViewImg = async (value) => {
    setPreview(true);
    let WorkPath = window.location.href.split("/");
    setWoNo(WorkPath[5]);
    let attchId = value.attachment_id;
    let step = value.reference_id;
    let workflow = value.reference_type;
    let eqId = 0;
    setIsLoad(false);
    const response = await GetAttchMentByID(
      attchId,
      step,
      workflow,
      WorkPath[6],
      WorkPath[5],
      eqId,
      WorkPath[7]
    );

    if (response?.success) {
      setAtchData(value);
      setIsLoad(true);
    }
  };

  return (
    <div>
      {reattData?.map((it) => (
        <img
          className="mx-1"
          src={
            it?.thumbnail_content?.includes("base64")
              ? it?.thumbnail_content
              : `data:${it?.file_type};base64,${it?.thumbnail_content}`
          }
          alt="attachment"
          style={{ width: "40px", height: "40px" }}
          onClick={() => HandleViewImg(it)}
        />
      ))}
      {/* <AttachmentModal
        showModal={isPreview}
        handleCloseModal={() => setPreview(false)}
        isloading={isLoad}
        isloadingClose={() => setIsLoad(false)}
        data={atchData}
        WorkId={wono}
      /> */}
      <Modal
        show={isPreview}
        onHide={() => setPreview(false)}
        centered
        size="lg"
      >
        <Modal.Header className="modal-bg-color  ">
          <span className="note-add-text">{atchData?.filename}</span>
          <img
            className="modal-header-icon"
            onClick={() => setPreview(false)}
            src={Close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body
        // style={{
        //   height: "49vh",
        // }}
        >
          <img
            className="d-block w-100"
            // src={it?.thumbnail_content}
            src={
              atchData?.thumbnail_content?.includes("base64")
                ? atchData?.thumbnail_content
                : `data:${atchData.file_type};base64,${atchData.thumbnail_content}`
            }
            alt="attachment"
            style={{
              height: "45vh",
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
