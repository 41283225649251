import React, { useState } from "react";
import WfTag from "../../../../common/Assets/Icons/Vectorwfs.svg";
import del from "../../../../common/Assets/Icons/del.svg";
import ColorWheel from "../../../../common/Assets/Icons/Colorwheelwfs.svg";
import { BiSearch } from "react-icons/bi";

export default function Tagmanager(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

 
  // let newData = TagData?.filter(
  //   (i) => i?.tag_id !== Res?.data?.tag?.tag_id
  // );
  // setTagData([...newData, Res?.data?.tag]);
  // setIsTagModal(false);
  
  const filteredData = props?.TagData?.filter((item) =>
    item?.tag_data?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  return (
    <div>
      <div className="line-align-bet w-100 clp_header">
        <div className="mx-2" style={{ fontFamily: "SofiaProBold" }}>
          <img src={WfTag} alt="WfWrench" width={20} />
          <span
            className="mx-1 wftags-sub-header cursor-p"
            onClick={() => props?.HandleToggle("Tag manager")}
          >
            Tag manager
          </span>
        </div>
      </div>
      <div className="clp_body">
      <div className="lbl-fltr-adj p-1 rela">
        {searchQuery === "" && (
          <div className="search-icon-set">
            <BiSearch height={16} width={16} style={{ marginTop: 5 }} />
          </div>
        )}
        <input
          className="wftagsipt-fltr-adj-itm"
          placeholder="Enter Name"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      {!props?.isTglTagMan && (
        <>
          <div
            className="line-align-bet w-100 mt-1"
            style={{ padding: "0px 30px 0px 10px" }}
          >
            <div className="sub-titles-names">Name</div>
            <div className="d-flex gap-4">
              <div className="sub-titles-names">Color:</div>
              <div className="sub-titles-names">Delete:</div>
            </div>
          </div>
          <div style={{ padding: "0px 10px 0px 10px" }}>
            <div className="card">
              {filteredData?.length > 0 ? (
                filteredData?.map((it) => (
                  <div
                    key={it?.id}
                    onClick={() => props?.HandleAddModal(it)}
                    className="line-align-bet w-100 p-2 cursor-p"
                    style={{ borderBottom: "1px solid rgb(209, 202, 202)",backgroundColor:`${it.color}`}}
                  >
                    <span className="sub-w-items-text">{it?.tag_data}</span>
                    <span className="d-flex gap-4">
                      <img
                        src={ColorWheel}
                        alt="ColorWheel"
                        width={18}
                        onClick={(e) => {
                          e.stopPropagation();
                          props?.HandleColPckr(it?.color, it?.id,it);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src={del}
                        alt="del"
                        width={15}
                        className="cursor-p mx-3"
                        onClick={(e) => props?.HandleCat(it, "Tag", e)}
                      />
                    </span>
                  </div>
                ))
              ) : (
                <div className="no-tags-found">
                  No tag found
                </div>
              )}
            </div>
          </div>
        </>
      )}
      </div>  
      
    </div>
  );
}
