import React, { forwardRef } from "react";
import { Modal } from "react-bootstrap"
import DatePicker from "react-datepicker";
import close from "../../../Assets/Icons/Close_round_main.svg";
import save from "../../../Assets/Icons/saveblack.svg";
import npad from "../../../Assets/Icons/notepad.svg";
import users_fill from "../../../Assets/Icons/Users_fill.svg";
import w_s_f from "../../../Assets/Icons/wrench_Screwdriver_filled.svg";
import persons from "../../../Assets/Icons/persons_main.svg";

const SearchFilter = (props) => {
    const [startDate, setStartDate] = React.useState();
    const [completedDate, setCompletedDate] = React.useState();

    const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="example-custom-input mt-1"
            placeholder="Select Date"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));

    const closeModal = () => {
        props.isClose();
        setStartDate('');
        setCompletedDate('');
    }
    return (
        <Modal
            centered
            onHide={props.isClose} show={props.isOpen}>

            {/* <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}> */}
                <div style={{ fontFamily: 'SofiaProBold', fontSize: 18,alignSelf:'center',marginTop:6 }}>Tag Search Filter</div>
                {/* <div style={{ height: 20, width: 20, backgroundColor: '#CCCCCC', borderRadius: 10, cursor: 'pointer' }}><img src={close} alt="" width={16} style={{ paddingBottom: 8, paddingLeft: 4 }} onClick={() => closeModal()} /></div> */}
            {/* </div> */}
            <div style={{ textAlign: 'center', fontFamily: 'SofiaProRegular' }}>What would you like to search by?</div>
            <div className="line-align-bet mt-2 ml-2">
                <div className=" lbl-fltr-adj">
                    <span className="txt-fltr-adj">Initial Date</span>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        customInput={<ExampleCustomInput />}
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                    />
                </div>
                <div className=" lbl-fltr-adj ">
                    <span className="txt-fltr-adj">Finish Date</span>
                    <DatePicker
                        selected={completedDate}
                        onChange={(date) => setCompletedDate(date)}
                        customInput={<ExampleCustomInput />}
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                    />
                </div>
            </div>
            <div className="d-flex line-align-even mt-3">
                <button style={{ borderRadius: 10, border: '1px solid #CCCCCC', backgroundColor: 'white',fontSize:12,paddingTop:4 }}><img src={users_fill} alt="" width={18} className="mb-1 mr-1"/>Contacts</button>
                <button style={{ borderRadius: 10, border: '1px solid #CCCCCC', backgroundColor: 'white',fontSize:12,paddingTop:4 }}><img src={persons} alt="" width={18} className="mb-1 mr-1"/>Customers</button>
                <button style={{ borderRadius: 10, border: '1px solid #CCCCCC', backgroundColor: 'white',fontSize:12,paddingTop:4 }}><img alt="" src={npad} width={14} className="mb-1 mr-1"/>WorkOrders</button>
                <button style={{ borderRadius: 10, border: '1px solid #CCCCCC', backgroundColor: 'white',fontSize:12,paddingTop:4 }}><img src={w_s_f} alt="" width={14} className="mb-1 mr-1"/>Equipment</button>
            </div>
            <div className='line-align-even mb-2 mt-3'>
                <div className="d-flex btn-save-cnl" onClick={() => closeModal()}>
                    <img src={close} alt="" width={18} className="img-cnl-save" />
                    <div className="text-save-cnl">Cancel
                    </div>
                </div>
                <div className="d-flex btn-save-cnl" onClick={() => closeModal()}>
                    <img src={save} alt="disc" width={18} className="img-cnl-save" />
                    <div className="text-save-cnl">Save
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default SearchFilter;