import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { WORK_ORDER_SETUP } from "../../common/components/Constants";
import {
  WorkFlowRes,
  EquipmentDetails,
  addNewEquipment,
} from "../../services/WorkOrdersService";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
function EQPupModal(props) {
  const { data } = props;
  const [tech, setTech] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [workflows, setWorkFlows] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [EqcheckedData, setEqcheckedData] = useState([]);
  const fetchEqpDetails = useCallback(async (workId, CustId) => {
    const authToken = localStorage.getItem("authToken");
    const EqpResult = await EquipmentDetails(data?.equipment_id);
    setEquipments(EqpResult?.data?.equipment);
    setTech(EqpResult?.WorkOrderDetails?.Technician);
    const workflowRes = await WorkFlowRes(authToken, workId);
    const workflows =
      workflowRes?.data?.workorder_workflow?.data?.workorderWorkflowResponse;
    setWorkFlows(workflows);
    // const DuplicateWorkOrders = await DuplicateWO(workId,workflows?.workflow_id)
  },[data?.equipment_id]);
  useEffect(() => {
    if (data?.equipment_id && data?.customer_id)
        fetchEqpDetails(data.equipment_id, data?.customer_id);
  }, [data, fetchEqpDetails]);
  const HandleInputChange = (item, e) => {
    if (e.target.checked) {
      setCheckedData([...checkedData, item?.workflow_id]);
    } else {
      setCheckedData(checkedData.filter((id) => id !== item?.workflow_id));
    }
  };
  const HandleEquipChckbox = (item, e) => {
    if (e.target.checked) {
      setEqcheckedData([...EqcheckedData, item?.equipment_id]);
    } else {
      setEqcheckedData(EqcheckedData.filter((id) => id !== item?.equipment_id));
    }
  };
  const navigation = useNavigate();
  const navToPage = (to, params, data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params,data  },
      navigate: navigation,
    });
  };
  const handleDupWO = async (e) => {
    let TechId = JSON.parse(localStorage.getItem("userId"));
    let neweqpdata = 
    {
  equipment_id: 0,
  customer_id: data?.customer_id, 
  equipment_type: data?.equipment_type,
  location: data?.location,
  manufacturer_code: data?.manufacturer_id,
  model_number: data?.model_number,
  note: data?.note,
  sequence_id:data?.sequence_id,
  serial_number: data?.serial_number,
  install_date:data?.install_date,
  warranty_date: data?.warranty_date,
  active: data?.active,
  unit_reference:data?.unit_reference,
  extra_field_1: data?.equipmentAdditionalField?.length > 0 ? data?.equipmentAdditionalField[0].value ? data?.equipmentAdditionalField[0].value : '' : '',
  extra_field_2: data?.equipmentAdditionalField?.length > 1 ? data?.equipmentAdditionalField[1].value ? data?.equipmentAdditionalField[1].value : '' : '',
  extra_field_3: data?.equipmentAdditionalField?.length > 2 ? data?.equipmentAdditionalField[2].value ? data?.equipmentAdditionalField[2].value : '' : '',
  extra_field_4: data?.equipmentAdditionalField?.length > 3 ? data?.equipmentAdditionalField[3].value ? data?.equipmentAdditionalField[3].value : '' : '',
  extra_field_5: data?.equipmentAdditionalField?.length > 4 ? data?.equipmentAdditionalField[4].value ? data?.equipmentAdditionalField[4].value : '' : '',
  extra_field_6: data?.equipmentAdditionalField?.length > 5 ? data?.equipmentAdditionalField[5].value ? data?.equipmentAdditionalField[5].value : '' : '',
  extra_field_7: data?.equipmentAdditionalField?.length > 6 ? data?.equipmentAdditionalField[6].value ? data?.equipmentAdditionalField[6].value : '' : '',
  extra_field_8: data?.equipmentAdditionalField?.length > 7 ? data?.equipmentAdditionalField[7].value ? data?.equipmentAdditionalField[7].value : '' : '',
  extra_field_9: data?.equipmentAdditionalField?.length > 8 ? data?.equipmentAdditionalField[8].value ? data?.equipmentAdditionalField[8].value : '' : '',
  extra_field_10: data?.equipmentAdditionalField?.length > 9 ? data?.equipmentAdditionalField[9].value ? data?.equipmentAdditionalField[9].value : '' : '',
  extra_field_11: data?.equipmentAdditionalField?.length > 10 ? data?.equipmentAdditionalField[10].value ? data?.equipmentAdditionalField[10].value : '' : '',
  extra_field_12: data?.equipmentAdditionalField?.length > 11 ? data?.equipmentAdditionalField[11].value ? data?.equipmentAdditionalField[11].value : '' : '',
  extra_field_13: data?.equipmentAdditionalField?.length > 12 ? data?.equipmentAdditionalField[12].value ? data?.equipmentAdditionalField[12].value : '' : '',
  extra_field_14: data?.equipmentAdditionalField?.length > 13 ? data?.equipmentAdditionalField[13].value ? data?.equipmentAdditionalField[13].value : '' : '',
  extra_field_15: data?.equipmentAdditionalField?.length > 14 ? data?.equipmentAdditionalField[14].value ? data?.equipmentAdditionalField[14].value : '' : '',
  extra_field_16: data?.equipmentAdditionalField?.length > 15 ? data?.equipmentAdditionalField[15].value ? data?.equipmentAdditionalField[15].value : '' : '',
  extra_field_17: data?.equipmentAdditionalField?.length > 16 ? data?.equipmentAdditionalField[16].value ? data?.equipmentAdditionalField[16].value : '' : '',
  extra_field_18: data?.equipmentAdditionalField?.length > 17 ? data?.equipmentAdditionalField[17].value ? data?.equipmentAdditionalField[17].value : '' : '',
  extra_field_19: data?.equipmentAdditionalField?.length > 18 ? data?.equipmentAdditionalField[18].value ? data?.equipmentAdditionalField[18].value : '' : '',
  extra_field_20: data?.equipmentAdditionalField?.length > 19 ? data?.equipmentAdditionalField[19].value ? data?.equipmentAdditionalField[19].value : '' : '',
  extra_field_21: data?.equipmentAdditionalField?.length > 20 ? data?.equipmentAdditionalField[20].value ? data?.equipmentAdditionalField[20].value : '' : '',
  extra_field_22: data?.equipmentAdditionalField?.length > 21 ? data?.equipmentAdditionalField[21].value ? data?.equipmentAdditionalField[21].value : '' : '',
  extra_field_23: data?.equipmentAdditionalField?.length > 22 ? data?.equipmentAdditionalField[22].value ? data?.equipmentAdditionalField[22].value : '' : '',
  extra_field_24: data?.equipmentAdditionalField?.length > 23 ? data?.equipmentAdditionalField[23].value ? data?.equipmentAdditionalField[23].value : '' : '',
  extra_field_25: data?.equipmentAdditionalField?.length > 24 ? data?.equipmentAdditionalField[24].value ? data?.equipmentAdditionalField[24].value : '' : '',
  extra_field_26: data?.equipmentAdditionalField?.length > 25 ? data?.equipmentAdditionalField[25].value ? data?.equipmentAdditionalField[25].value : '' : '',
  extra_field_27: data?.equipmentAdditionalField?.length > 26 ? data?.equipmentAdditionalField[26].value ? data?.equipmentAdditionalField[26].value : '' : '',
  extra_field_28: data?.equipmentAdditionalField?.length > 27 ? data?.equipmentAdditionalField[27].value ? data?.equipmentAdditionalField[27].value : '' : '',
  extra_field_29: data?.equipmentAdditionalField?.length > 28 ? data?.equipmentAdditionalField[28].value ? data?.equipmentAdditionalField[28].value : '' : '',
  extra_field_30: data?.equipmentAdditionalField?.length > 29 ? data?.equipmentAdditionalField[29].value ? data?.equipmentAdditionalField[29].value : '' : '',
 
}
let wowfId = props?.work_flow_id?props?.work_flow_id:0
let isWoid = window?.location?.href?.split("/")[5]
    let Res = await addNewEquipment(124, isWoid, wowfId,neweqpdata);
    if (Res.success) {
      let custId = Res?.data?.customer_id
      let ccode = window?.location?.href?.split("/")[9]
      props.Close();
      navToPage(`AddEquipments/${isWoid}/${custId}/${TechId}/${TechId}/${ccode}/0`, "Edit", Res?.data?.equipment_id)
    }
  };
  return (
    (
      <div>
        <Modal show={props.Open} onHide={props.Close} centered size="m">
          <Modal.Header className="modal-bg-color modal-header-height ">
            <span className="modal-header-txt">
              {WORK_ORDER_SETUP.DUPLICATE_EQP}
              {data?.equipment_id}{" "}
            </span>
            <img
              src={Close}
              className="modal-header-icon"
              onClick={props?.Close}
              alt="Close"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="wf-hdr-wfname-span">
              {WORK_ORDER_SETUP.EQP_DESCRPTN}#{data?.equipment_id}
            </div>

            {workflows?.length > 0 && (
              <div className="">
                <input
                  type="checkbox"
                  checked={workflows?.length === checkedData?.length}
                  // className="chck-box "
                />
                <span className="ml-1 wrk-flws">
                  {WORK_ORDER_SETUP.WRKFLWS}
                </span>
                {workflows?.map((workflow, index) => (
                  <div className="wfs-name-div ">
                    <input
                      type="checkbox"
                      // className="chck-box"
                      onChange={(e) => HandleInputChange(workflow, e)}
                    />
                    <span className="wf-wfname-span">
                      {workflow?.workflow_name}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {tech && (
              <div className="">
                <input type="checkbox" className="" />
                <span className=" wrk-flws">
                  {" "}
                  {WORK_ORDER_SETUP.TECHNICIANS}
                </span>
                {Array?.isArray(tech) ? (
                  tech?.map((tech) => (
                    <div className="ml-4">
                      <input type="checkbox" className="chck-box" />
                      <span className="wf-wfname-span">{tech?.name}</span>
                    </div>
                  ))
                ) : (
                  <div className="ml-4 ">
                    <input type="checkbox" className="" />
                    <span className="wf-wfname-span">{tech?.name}</span>
                  </div>
                )}
              </div>
            )}
            {equipments?.length > 0 && (
              <div className="">
                <input type="checkbox" className="" />
                <span className=" wrk-flws"> {WORK_ORDER_SETUP.EQPMNT}</span>
                {equipments?.map((equipment) => (
                  <div className="ml-4">
                    <input
                      type="checkbox"
                      className=""
                      onChange={(e) => HandleEquipChckbox(equipment, e)}
                    />
                    <span className="wf-wfname-span">
                      {equipment?.equipment_type}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Body>
            <div className="modal-footer-styles mt-2 gap-2 model_footer">
              <div
                className="modal-footer-save-cancel-btns"
                onClick={props?.Close}
              >
                cancel{" "}
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                onClick={handleDupWO}
              >
                Create{" "}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  );
}
export default EQPupModal;
