import React, { useState, useEffect } from "react";
import "./Login.css";
import LoginHeader from "../framework/header/HeaderLogin/LoginHeader";
import LoginFooter from "../framework/footer/LoginFooter";
import { useNavigate } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import lock from "../Assets/Icons/Lock.svg";
import bld from "../Assets/Icons/Building.svg";
import man from "../Assets/Icons/man.svg";
import { authLogin } from "../../services/AuthService";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import { LOGIN_CONST } from "../components/Constants";
import { LOGIN_PG_TEXTS } from "../components/Values";
import logo from '../Assets/Icons/Logo.svg'

function Login() {
  const navigation = useNavigate();
  const [eyePass, setEyePass] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const HandleEye = () => {
    setEyePass(!eyePass);
  };
  const isSubmitted = false;
  const initialValues = { companycode: "", techcode: "", techpin: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formerrors, setformerrors] = useState({});
  const [ErrMsge, setErrMsg] = useState("");

  useEffect(() => {
    const rememberedValues = JSON.parse(localStorage.getItem("rememberedValues"));
    if (rememberedValues) {
      setFormValues(rememberedValues);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {

    e.preventDefault();
    setformerrors(validate(formValues));
  };
  
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "rememberMe") { 
      setRememberMe(checked);
      if (!checked) {
        localStorage.removeItem("rememberedValues");
        setFormValues(initialValues); 
      }
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      setformerrors({});
      setErrMsg("");
    }
  };
  
  const validate = (values) => {
    const errors = {};
    if (!values.companycode || !values.techcode || !values.techpin) {
      setErrMsg(LOGIN_CONST.ERR_MSG);
    }
    if (
      values.companycode.length > 0 &&
      values.techpin.length > 0 &&
      values.techcode.length > 0
    ) {
      const myRequest = async (username, password, company) => {
        try {
          const companycode = values.companycode;
          const techcode = values.techcode;
          const techpin = values.techpin;
          const sponsor_id = 1;
          const ip_address = 1;
          const os = LOGIN_CONST.OS;
          const device = 1;
          const app_type = LOGIN_CONST.APP_TYPE;
          const data = await authLogin(
            techcode,
            techpin,
            companycode,
            sponsor_id,
            ip_address,
            os,
            device,
            app_type
          );
          if (data?.success === false || data === undefined) {
            setErrMsg(LOGIN_CONST.INV_CRED);
          }
          if (data?.success) {
          localStorage.setItem("technicianid",JSON.stringify(data.data.techid))
          localStorage.setItem("companyLogo",data?.data?.logo);
          localStorage.removeItem("previoussearch");
          const companyDetails = {
            address: data?.data?.address,
            address2: data?.data?.address2,
            company_name: data?.data?.company_name,
            email: data?.data?.email,
          };
          if (rememberMe) {
            localStorage.setItem(
              "rememberedValues",
              JSON.stringify(formValues)
            );
          }

          localStorage.setItem(
            "CompanyDetails",
            JSON.stringify(companyDetails)
          );
          localStorage.setItem("userId", data.data.techcode);
          localStorage.setItem("authToken", JSON.stringify(data.data.token));
          localStorage.setItem("CompanyCode", JSON.stringify(companycode));
          localStorage.setItem("techpin", JSON.stringify(techpin));
          localStorage.setItem("color_primary", JSON.stringify(data?.data?.color_primary));
          localStorage.setItem(
            "userName",
            JSON.stringify(data.data.technician_name)
            
          );
          
            NavigateFunction({
              to: "Dashboard",
              params: "login",
              navigate: navigation,
            });
          }
        } catch (e) {}
      };

      myRequest();
    }
    return errors;
  };

  const navToFgtPwd = () => {
    navigation("/fgtPassword");
  };
  const renderForm = (  
    <div className="form ">
      <form onSubmit={handleSubmit} className="mt-1">
        <div className="input-container">
          <div className="positins">
            <div className="icon inpt-txt-algn">
              <img src={bld} alt="bld" />
            </div>
            <div className="inpt-algn">
              <p className="error-txt">{formerrors.companycode}</p>
            </div>
            <input
              className="login-input-placeholder-top login-input-username  username-input"
              type="text"
              name="companycode"
              placeholder="Input Company ID"
              value={formValues.companycode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="positins">
            <div class="icon inpt-txt-algn">
            <img src={man} alt="man" />
            </div>
            <div className="inpt-algn">
              <p className="error-txt">{formerrors.techcode}</p>
            </div>
            <input
              className="login-input-placeholder-top-u usernme-input"
              // type="email"
                 type="text"
              name="techcode"
              placeholder="Input Username/Email"
              value={formValues.techcode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="positins">
            <div className="icon inpt-txt-algn">
              <img src={lock} alt="lock" />
            </div>
            <div className="inpt-algn">
              <p className="error-txt">{formerrors.techpin}</p>
            </div>
            <input
              className="login-input-placeholder-top-p login-input-password  username-input"
              type={eyePass ? "text" : "password"}
              name="techpin"
              placeholder="Input Password"
              value={formValues.techpin}
              onChange={handleChange}
            />
            <div className="eye-comp">
              {eyePass ? (
                <BsEye onClick={HandleEye} />
              ) : (
                <BsEyeSlash onClick={HandleEye} />
              )}
            </div>
          </div>
        </div>
        <div className="button-container mt-3  mx-1">
          <button type="Submit">{LOGIN_CONST.SIGN_IN}</button>
        </div>
        <div className="login-lower-text-main mt-3">
          <div className="d-flex">
            <div className="login-Register cursor">
              {LOGIN_CONST.REMIMBER}
              </div>
              <input
              type="checkbox"
              name="rememberMe"
              checked={rememberMe}
              onChange={handleChange}
              className="mc-2"
            />
            </div>
          <div className="login-Register mb-2" onClick={() => navToFgtPwd()}>
            {LOGIN_CONST.FORGOT}
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div className="page-container ">
      <div className="content-wrap" >
        <LoginHeader />
        <div className="App">
          <div className="login-form ">
            <div className="login_box">
            <div className="title title_new">{LOGIN_PG_TEXTS.WLCM}</div>
            <div className="login_img"><img className="comp-icon" src={logo} alt='logo' /></div>           
            {/* <div className="title2 mt-0">{LOGIN_PG_TEXTS.SIGN_TXT}</div> */}
            <span className="error-msg mt-1">{ErrMsge}</span>
            {isSubmitted ? <div>{LOGIN_PG_TEXTS.LGIN_MSG}</div> : renderForm}
          </div>  </div>
        </div>
      </div>
      <LoginFooter/>
    </div>
  );
}

export default Login;
