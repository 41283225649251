import React, { forwardRef, useState } from "react";
import "./Logs.css";
import { Card, Container,} from "react-bootstrap";
import Log from "../../common/Assets/Icons/Logs.svg";
import LogsData from "../../../src/features/customers/CreateNewcustomer/LogsData";
import { LOGS } from "../../common/components/Values";
import { BiSearch } from "react-icons/bi";
const Logs = forwardRef(function Logs(props, ref) {
  const [islogs, setIslogs] = useState(true);

  const handlelog = () => {
    setIslogs(!islogs);
  };
  return (
    <Card className="det-txt-logs " ref={ref}>
    <div className="log-card-header clp_header">
      <div className="myDiv mx-2">
        <img
          src={Log}
          onClick={handlelog}
          className="flt-l cursor-p "
          width={22}
          alt="open Logs"
        />
        <span className=" cursor-p " onClick={handlelog}>
          Logs
        </span>
      </div>
      {/* <div className="myDiv1 ">
        {logTgl === "logtgl" || props.isVal === "create" ? (
          <img
            className="plusIcon-modal"
            alt=""
            src={add}
            width={12}
            onClick={() => HandleLibTgl("logTgloff")}
          />
        ) : (
          ""
        )}
      </div> */}
    </div>
    {islogs && (
      
      <div className="clp_body">
        {props?.isVal === "create" && props?.isVal === "edit" ?
        <Card.Body className="p-0 clp_body">
          <Container fluid className="m-2">
                <div className="log-date-input-container">
                  <div className="log-input-container ">
                    <span className="inp-txt-sett-el">{LOGS.FROM_DATE}</span>
                    <input className="inp-sett-log" type="date" />
                  </div>
                  <div className="log-input-container">
                    <span className="inp-txt-sett-el">{LOGS.TO_DATE}</span>
                    <input className="inp-sett-log" type="date" />
                  </div>
                  <div className="log-input-container">
                          <span className="inp-txt-sett-el">
                            {LOGS.GEN_ACTION}
                          </span>
                          <div
                            className=" inp-txt-sett-el  "
                            style={{ position: "relative" }}
                          >
                            <div className="close-icon-set">
                              <BiSearch
                                className="mx-2 mt-2 "
                                style={{ color: "#A1A2A4" }}
                              />
                            </div>
                            <input
                              className="ipt-fltr-adj  mx-1 "
                              placeholder="Name"
                              style={{ height: "28px" }}
                            />
                          </div>
                        </div>
                        <div className="log-input-container">
                          <span className="inp-txt-sett-el">
                            {LOGS.AUTHOR}
                          </span>
                          <div
                            className=" inp-txt-sett-el  "
                            style={{ position: "relative" }}
                          >
                            <div className="close-icon-set">
                              <BiSearch
                                className="mx-2 mt-2 "
                                style={{ color: "#A1A2A4" }}
                              />
                            </div>
                            <input
                              className="ipt-fltr-adj mx-1 "
                              placeholder="Name"
                              style={{ height: "28px" }}
                            />
                          </div>
                        </div>
                </div>
          </Container>
          <LogsData />
        </Card.Body>
        :<div className="notes-card-dscrptn">
        <p>Logs are created upon creation of the work order</p>
          </div>
        }
      </div>     
)}
  </Card>

  );
});

export default Logs;
