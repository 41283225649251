import React from "react";
import { useState, } from "react";
import { useParams, useNavigate } from "react-router-dom";
import info from "../../common/Assets/Icons/Info.svg";
import MemoryModal from "../../common/components/memorymodal/MemoryModal";
import MemoryIcon from "../../common/Assets/Icons/Memory Board Icon.svg";
import VectorEqpBlue from "../../common/Assets/Images/VectorEqpBlue.svg";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import Add_round from "../../common/Assets/Icons/Add_round.svg";
import {
  EQUIPMENT_DETAILS,
  WORK_ORDER_CONSTANTS,
} from "../../common/components/Constants";
import MemoryblueModal from "../../common/components/memorymodal/MemoryblueModal";
import { GetMemory } from "../../services/WorkOrdersService";
export default function EquipmentCard(props) {
  const params = useParams();
  const carddata = props?.cardEquipments;
  const navigation = useNavigate();
  const navToPage = (to, params, data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params, data },
      navigate: navigation,
    });
  };

  // const [ismemory, setIsMemory] = useState("white");
  // const handleMemory = (e) => {
  //   e.stopPropagation();
  //   setIsMemory((prev) => (prev === "white" ? "blue" : "white"));

  // };
  const [isMemory, setIsMemory] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [mryblue,setMryblue] = useState(false)
  const [memobj,setMemobj] = useState({})
  const [itemkey,setItemkey] = useState("")
  const [ismemProgress, setIsMemProgress] = useState(false);
  const HandleMemory = async(type,e, item) => {
    e.stopPropagation();
    if(type === "black"){
    setIsMemory(true);
    setMemobj(item)
    let items = { "viewtype": 10, "Reftype": 11, "RefId": item?.equipment_id };
    setIsMemoryObj(items);
    }
    else{
      setMryblue(true)
      setItemkey(type)
      let items = { "viewtype": 10, "Reftype": 11, "RefId": item?.equipment_id };
      setIsMemoryObj(items);
      setMemobj(item)
      setIsMemProgress(true)
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
        );
        if (Result?.success) {
          setIsMemProgress(false)
        }
        setgetmemory(Result?.data);
        setMrydlt(Result?.data?.recent_view_id)
    }
  };
  const [getmemory, setgetmemory] = useState("");
  const [mrydlt,setMrydlt] = useState(null)
  const descvalue = getmemory?.note;
  const updateMemoryBoardStatus = (workOrderId) => {
    memobj.memoryBoard = workOrderId 
};
const locpath = window.location.href.split("/")[4]
  return (
    <>
      {props?.isLoading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div
          className="" >
           {(locpath === "WoDetails" && carddata ? carddata?.slice(0, 3) : carddata)?.map(equipment => (
              <div className="line-align-bet shw-eft p-2 steps-hov d-flex flex-column "
              style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
              onClick={() =>{if(props.OpenCust === false || props.OpenCust ===undefined) navToPage(`AddEquipments/${(params.serviceid)}/${params.workid}/${params.teckid}/${params.techpin}/${params.company}/0`, "equipdetails", equipment?.equipment_id)}}>
                <div className="line-align-bet flex-wrap " >
                  <div className="d-flex flex-wrap">
                    <input type="radio" style={{display:"grid",gridTemplateColumns:"1em auto",gap:"0.1em",marginBottom:"2px"}}/>
                    <span className="txt-eqp mx-1" style={{marginTop:"1px"}}>{equipment.equipment_type} </span>
                    <span className="txt-eqp-man mx-1">
                      by {equipment.manufacturer_id}
                    </span>
                    <span className="txt-eqp-man mx-1">
                      {EQUIPMENT_DETAILS?.EQUIPMODAL}
                      {equipment.model_number.slice(0, 10)}
                    </span>
                    <span className="txt-eqp-man mx-1">
                      {EQUIPMENT_DETAILS?.EQUP_SERIALNUM}
                      {equipment.serial_number.slice(0, 10)}
                    </span>
                  </div>
                  <div className="d-flex">
                    <span
                      className="mx-1 cursor-p woCard-icons-align"
                     
                    >
                      { equipment?.memoryBoard === 1 ?
                        <img  onClick={(e) =>{if(props.OpenCust === false || props.OpenCust ===undefined) HandleMemory("eqps",e, equipment)}} src={VectorEqpBlue} alt="memoryIconBlue" />
                        :
                        <img  onClick={(e) =>{if(props.OpenCust === false || props.OpenCust ===undefined) HandleMemory("black",e, equipment)}} width={22} src={MemoryIcon} alt="MemoryIcon" />
                      }
                    </span>
                    <img
                      alt=""
                      width={22}
                      className="mx-1"
                      onClick={(e) =>{if(props.OpenCust === false || props.OpenCust ===undefined) props.HandleInfoModal(equipment, e)}}
                      src={info}
                    />
                    {equipment?.active > 0 ?
                      <span className="act-btn-eqp mx-1">
                        {" "}
                        {EQUIPMENT_DETAILS?.Active}
                      </span>
                      :
                      <span className="act-btn-eqp-inactive mx-1">
                        {EQUIPMENT_DETAILS?.InActive}
                      </span>

                    }
                    <span
                      className={
                        props?.LocStrId
                          ? "tag-styles-wf mx-1"
                          : "tag-styles-wf mx-1 blurimg"
                      }
                      style={{
                        backgroundColor: "#CCCCCC",
                        color:"black"
                      }}
                      onClick={(e) =>{if(props.OpenCust === false || props.OpenCust ===undefined) props?.addEquTag(equipment, e)}}>
                      {WORK_ORDER_CONSTANTS?.TAG} <img src={Add_round} alt="tag" />
                    </span>

                  </div>
                </div>
                <div className=" ml-4">
                  {/* <ButtonToolbar className="d-flex flx-wrp">
          {equipment?.tags
            ?.sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((tags, key) => {
              return (
                <OverlayTrigger
                  placement="bottom"
                  className="tag-styles"
                  overlay={tooltip(tags?.name)}
                >
                  <span
                    key={key}
                    className="tag-data-style-eqp mx-1 mt-2  "
                    style={{
                      background: tags?.color,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {tags?.name?.length > 7
                      ? `${tags.name.slice(0, 6)}..`
                      : `${tags.name}`}
                    <img
                      src={del}
                      alt=""
                      className="ml-1"
                      onClick={(e) =>
                        props?.handleDeleteModal(tags, e,equipment)
                      }
                    />
                  </span>
                </OverlayTrigger>
              );
            })}
          </ButtonToolbar> */}
                  <div className="wo-edit-tag">
                    <div className="tech-name-style ">
                      <div className=" d-flex flex-wrap">
                        {equipment?.tags?.map((item) => (
                          <div key={item} onClick={(e) =>
                            props?.handleDeleteModal(item, e, equipment)
                          }>
                            <span
                              className="tag-styles"
                              style={{
                                backgroundColor: "#CCCCCC",
                                fontSize: "12px",
                                color: "black",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                                display: "inline-block",
                                maxWidth: '100%',
                              }}
                            >
                              {" "}
                              {item?.name}
                            </span>
                          </div>
                        ))}
                      </div>
                      {/* {workOrder?.Customer?.name} */}
                    </div>
                  </div>
                </div>

              </div>
            ))}
          <p className="eqp-desciption mt-1 ">{props?.workOrder?.description}</p>
          <div className="mx-4">
            {props?.data?.attachments?.map((it) => (
              <img
                alt=""
                className="pics-dem"
                src={it.thumbnail}
                onClick={() => props?.HandleViewImg(it)}
              />
            ))}
          </div>
          <MemoryModal
            isOpen={isMemory}
            isClose={() => setIsMemory(false)}
            updateMemoryBoardStatus={updateMemoryBoardStatus}
            ismemoryObj={ismemoryObj}
            name={"Equipment"}
            woId = {window.location.href.split('/')[5]}
            id={memobj?.equipment_id}
            descvalue={descvalue}
          />
         <MemoryblueModal
          Open = {mryblue}
          Close= {()=>setMryblue(false)}
          ismemoryObj={ismemoryObj}
          memobj= {memobj}
          itemkey= {itemkey}
          descvalue={descvalue}
          custname={memobj?.Customer?.name}
          isProgress={ismemProgress}
          updateMemoryBoardStatus={updateMemoryBoardStatus}
          mrydlt= {mrydlt}
          openName={itemkey === "eqps"? "Equipment":""}
          headname = {itemkey === "eqps" 
          ? `${memobj?.equipment_type || ''} / ${memobj?.manufacturer_id || ''}`.trim() 
          : ""}
          headid={itemkey === "eqps"?memobj?.equipment_id:""}
          />
        </div>
      )}
    </>
  );
}
