import React, { useState } from "react";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import CommonFooter from "../../common/framework/footer/CommonFooter";
import "./Dashboard.css";
import prfl from "../../common/Assets/Icons/user_circle_outline_main.svg";
import Date_range_fill from "../../common/Assets/Icons/Date_range_fill.svg";
import Date_fill from "../../common/Assets/Icons/Date_fill.svg";
import chevron_rounded_main2 from "../../common/Assets/Icons/chevron_rounded_main2.svg";
import chevron_rounded_main1 from "../../common/Assets/Icons/chevron_rounded_main1.svg";
import Calendar_fill from "../../common/Assets/Icons/Calendar_fill.svg";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
} from "chart.js";
import {
  Paper,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { Doughnut } from "react-chartjs-2";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
ChartJS.register(ArcElement, ChartTooltip, ChartLegend);

const events = {
  "2024-10-12": [
    {
      title: "Event 1",
      time: "10:00 AM",
      description: "7 Plymouth Streer Plkesville, MD 21208",
      duration: "2 hrs",
    },
    {
      title: "Event 2",
      time: "6:00 PM",
      description: "8948 Hanover Road Gaithersburg, MD 20877",
      duration: "3 hrs",
    },
  ],
  "2024-10-13": [
    {
      title: "Event 3",
      time: "6:00 AM",
      description: "9823 Rock Maple Lane Skyesville, MD 21784",
      duration: "1 hr",
    },
  ],
  
};

const Dashboard = () => {
  const [view, setView] = useState("monthly");
  const [date, setDate] = useState(new Date());

  const data = [
    { name: "May 02", sales: 40000 },
    { name: "May 09", sales: 60000 },
    { name: "May 16", sales: 90000 },
    { name: "May 23", sales: 120000 },
    { name: "May 30", sales: 160000 },
  ];

  const pieData = {
    datasets: [
      {
        data: [2500, 2500, 2500, 2500],
        backgroundColor: ["#0045E8", "#F6A609", "#26A688", "#E43333"],
        hoverBackgroundColor: ["#0045E8", "#F6A609", "#26A688", "#E43333"],
        borderWidth: 0,
      },
    ],
  };

  const data1 = [
    {
      techName: "Tech Name",
      revenue: 6000,
      avgEstimate: 5712,
      closePercentage: "80%",
      jobs: 178,
      sales: 154,
      reviews: 5,
    },
    {
      techName: "Tech Name",
      revenue: 6000,
      avgEstimate: 5712,
      closePercentage: "80%",
      jobs: 178,
      sales: 154,
      reviews: 5,
    },
    {
      techName: "Tech Name",
      revenue: 6000,
      avgEstimate: 5712,
      closePercentage: "80%",
      jobs: 178,
      sales: 154,
      reviews: 5,
    },
    {
      techName: "Tech Name",
      revenue: 6000,
      avgEstimate: 5712,
      closePercentage: "80%",
      jobs: 178,
      sales: 154,
      reviews: 5,
    },
  ];

  const renderMonthlyView = () => (
    <div className="calendar-container">
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileContent={({ date }) => {
          const dateString = date.toISOString().split("T")[0];
          const dayEvents = events[dateString] || [];
          return (
            <ul className="event-list">
              {dayEvents.map((event, index) => (
                <li key={index}>{event.title}</li>
              ))}
            </ul>
          );
        }}
        tileClassName={({ date }) => {
          return events[date.toISOString().split("T")[0]] ? "has-event" : "";
        }}
      />
    </div>
  );

  const renderWeeklyView = () => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const daysOfWeek = [...Array(7)].map((_, i) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      return day;
    });

    const timeSlots = [
      "12:00AM",
      "1:00 AM",
      "2:00 AM",
      "3:00 AM",
      "4:00 AM",
      "5:00 AM",
      "6:00 AM",
      "7:00 AM",
      "8:00 AM",
      "9:00 AM",
      "10:00AM",
      "11:00AM",
      "12:00PM",
      "1:00 PM",
      "2:00 PM",
      "3:00 PM",
      "4:00 PM",
      "5:00 PM",
      "6:00 PM",
      "7:00 PM",
      "8:00 PM",
      "9:00 PM",
      "10:00PM",
      "11:00PM",
    ];

    return (
      <div className="weekly-view ">
        <div className="navigation-buttons d-flex justify-content-between">
          <img
            src={chevron_rounded_main2}
            alt="Previous Week"
            className="icon-button"
            onClick={() => setDate(new Date(date.setDate(date.getDate() - 7)))}
          />
          <Typography variant="h6" className="week-title">
            {`${daysOfWeek[0].toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            })} - ${daysOfWeek[6].toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            })}`}
          </Typography>
          <img
            src={chevron_rounded_main1}
            alt="Next Week"
            className="icon-button"
            onClick={() => setDate(new Date(date.setDate(date.getDate() + 7)))}
          />
        </div>
        <div className="week-header">
          <div className="date-header">Time</div>
          {daysOfWeek.map((day, index) => (
            <div key={index} className="day-header">
              {day.toLocaleDateString("en-US", {
                weekday: "long",
                month: "short",
                day: "numeric",
              })}
            </div>
          ))}
        </div>
        <div className="week-body">
          {timeSlots.map((timeSlot, index) => (
            <div key={index} className="time-row">
              <div className="time-label">{timeSlot}</div>
              {daysOfWeek.map((day) => {
                const dateString = day.toISOString().split("T")[0];
                const dayEvents = events[dateString] || [];
                const eventsAtThisTime = dayEvents.filter(
                  (event) => event.time === timeSlot
                );
                return (
                  <div key={day} className="day-cell">
                    {eventsAtThisTime.map((event, idx) => (
                      <span
                        key={idx}
                        style={{ display: "block", marginBottom: 2 }}
                      >
                        {event.title}
                      </span>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDailyView = () => {
    const dateString = date.toISOString().split("T")[0];
    const dayEvents = events[dateString] || [];

    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return (
      <div className="daily-view ">
        <div
          className="navigation-buttons"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          <img
            src={chevron_rounded_main2}
            alt="Previous Week"
            className="icon-button"
            onClick={() => setDate(new Date(date.setDate(date.getDate() - 1)))}
          />
          <h4 style={{ display: "inline", margin: "0 10px" }}>
            Today: {formattedDate}
          </h4>
          <img
            src={chevron_rounded_main1}
            alt="Next Week"
            className="icon-button"
            onClick={() => setDate(new Date(date.setDate(date.getDate() + 1)))}
          />
        </div>
        {dayEvents.length === 0 ? (
          <p>No events scheduled for today.</p>
        ) : (
          dayEvents.map((event, index) => (
            <div key={index} className="event-container">
              <div className="event-time">
                <span>{event.time}</span>
                <span className="event-duration">{event.duration}</span>
              </div>
              <div className="event-card">
                <h5>{event.title}</h5>
                <p>{event.description}</p>
              </div>
            </div>
          ))
        )}
      </div>
    );
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <div>
      <CommonHeader />
      <div className="pagescroll-dash" style={{ padding: 10 }}> 
        <Typography variant="h4" gutterBottom>
          Welcome Tech 24!
        </Typography>
        <Grid container spacing={2} className="">
          {/* First Card */}
          <Grid item xs={12} md={4} className="card-1"> 
            <Paper className="invoice-card ">
              {" "}
              <div className="card-content">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Total Outstanding Invoices
                </Typography>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  $2,405,195
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <div style={{ marginRight: "8%" }}>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                      On Time:
                    </Typography>
                    <Typography
                      style={{
                        color: "#26A688",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      124,569
                    </Typography>
                  </div>
                  <div style={{ marginRight: "8%" }}>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                    {'<'} 30 Days Late:
                    </Typography>
                    <Typography
                      style={{
                        color: "#F6A609",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      321
                    </Typography>
                  </div>
                  <div style={{ marginRight: "8%" }}>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                      30-90 Days Late:
                    </Typography>
                    <Typography
                      style={{
                        color: "#0045E8",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      55
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                      90+ Days Late:
                    </Typography>
                    <Typography
                      style={{
                        color: "#E43333",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      12
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={2.7}>
            <Paper className="invoice-card">
              {" "}
              <div className="card-content">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Invoices on time
                </Typography>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  $1,822,685
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "9px",
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                      On Time:
                    </Typography>
                    <Typography
                      style={{
                        color: "#26A688",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      124,569
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          {/* Third Card */}
          <Grid item xs={12} md={2.7}>
            <Paper className="invoice-card">
              {" "}
              <div className="card-content">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Invoices 30-90 days Late
                </Typography>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  $465,144
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "9px",
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                      30-90 Days Late:
                    </Typography>
                    <Typography
                      style={{
                        color: "#0045E8",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      55
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          {/* Fourth Card */}
          <Grid item xs={12} md={2.6}>
            <Paper className="invoice-card">
              {" "}
              <div className="card-content">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Invoices 90+ Days Late
                </Typography>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  $12,386
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "9px",
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: "0.8rem", color: "#CCCCCC" }}>
                      90+ Days Late:
                    </Typography>
                    <Typography
                      style={{
                        color: "#E43333",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      12
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid
            className=" mx-3 mt-3"
            container
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={4}>
              <Paper
                style={{
                  padding: 20,
                  height: "400px",
                  borderRadius: 10,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "center",
                    marginBottom: 16,
                    fontWeight: "bold",
                  }}
                >
                  Invoice Metrics
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  style={{ marginBottom: 16 }}
                >
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      color="black"
                      style={{ fontWeight: "bold" }}
                    >
                      Invoices Outstanding
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      24
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      color="black"
                      style={{ fontWeight: "bold" }}
                    >
                      Accounts Receivable
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      $12000.43
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  spacing={1}
                  style={{ textAlign: "center", marginBottom: 16 }}
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="#CCCCCC">
                      To be billed:
                    </Typography>
                    <Typography variant="body2" style={{ color: "#E43333" }}>
                      10000
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="#CCCCCC">
                      1-7 days late on payment:
                    </Typography>
                    <Typography variant="body2" style={{ color: "#0045E8" }}>
                      24
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="#CCCCCC">
                      Payment Received:
                    </Typography>
                    <Typography variant="body2" style={{ color: "#F6A609" }}>
                      12345
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="#CCCCCC">
                      Invoice Complete:
                    </Typography>
                    <Typography variant="body2" style={{ color: "#26A688" }}>
                      12345
                    </Typography>
                  </Grid>
                </Grid>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "70%" }}>
                    <Doughnut
                      data={pieData}
                      options={{ maintainAspectRatio: false, cutout: '70%', }}
                    />
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
  <Paper
    className="map-container"
    style={{
      padding: 20,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      position: 'relative',
      marginBottom: '20px',
    }}
  >
    <Typography
      variant="h6"
      style={{
        textAlign: "center",
        height: "60px",
        fontWeight: "bold",
        color: "black",
      }}
    >
      Heat Map
    </Typography>
    <MapContainer
      center={[40.71, -74.01]}
      zoom={7}
      style={{ height: "300px", zIndex: 1 }} // Ensure zIndex is set appropriately
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[40.71, -74.01]}>
        <Popup>👁️</Popup>
      </Marker>
    </MapContainer>
  </Paper>
</Grid>

            <Grid item xs={12} md={4}>
              <Paper style={{ padding: 20,boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"}}>
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: 5,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Total Monthly Sales
                </Typography>
                <div className="dash-sales">
                  <span>$441,337.59 in sales from the last Month to date.</span>
                </div>

                {/* Responsive Container wrapping the LineChart */}
                <div style={{ width: "100%", height: 310 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data}>
                      <CartesianGrid stroke="#ccc" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ChartTooltip />
                      <ChartLegend />
                      <Line type="monotone" dataKey="sales" stroke="#0045E8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Paper>
            </Grid>
          </Grid>

          {/* Scheduling Section */}
          <Grid container spacing={2} mx={1}>
            <Grid item xs={12}>
              <Paper  style={{ padding: 20, marginTop: 20, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"}}>
                <Typography className="scheduling-header">
                  Scheduling
                </Typography>
                <div className="view-options">
                  <span
                    className="monthly-btn"
                    onClick={() => handleViewChange("monthly")}
                  >
                    <img width={20} className="mb-1" src={Date_range_fill} />{" "}
                    Monthly
                  </span>
                  <span
                    className="weekly-btn"
                    onClick={() => handleViewChange("weekly")}
                  >
                    <img width={20} className="mb-1" src={Date_fill} /> Weekly
                  </span>
                  <span
                    className="daily-btn"
                    onClick={() => handleViewChange("daily")}
                  >
                    <img width={20} className="mb-1" src={Calendar_fill} />{" "}
                    Daily
                  </span>
                </div>
                {view === "monthly"
                  ? renderMonthlyView()
                  : view === "weekly"
                  ? renderWeeklyView()
                  : renderDailyView()}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <div className="table-container card">
          <span className="overview-header">OverView</span>
          <table className=" mb-3 table-header">
            <thead>
              <tr>
                <th>Tech Name</th>
                <th>Revenue</th>
                <th>Avg Estimate</th>
                <th>Close %</th>
                <th># of Jobs</th>
                <th># of Sales</th>
                <th>Customer Reviews</th>
              </tr>
            </thead>
            <tbody>
              {data1.map((item, index) => (
                <tr key={index}>
                  <td className="data-label" data-label="Tech Name">
                    <img src={prfl} /> {item.techName}
                  </td>
                  <td data-label="Revenue">{item.revenue}</td>
                  <td data-label="Avg Estimate">{item.avgEstimate}</td>
                  <td data-label="Close %">{item.closePercentage}</td>
                  <td data-label="# of Jobs">{item.jobs}</td>
                  <td data-label="# of Sales">{item.sales}</td>
                  <td data-label="Customer Reviews">
                    {"★".repeat(item.reviews)}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="data-label" data-label="Tech Name">Total</td>
                <td data-label="Revenue">63600</td>
                <td data-label="Avg Estimate">54660</td>
                <td data-label="Close %">96%</td>
                <td data-label="# of Jobs">5168</td>
                <td data-label="# of Sales">1512</td>
                <td data-label="Customer Reviews">{"★".repeat(5)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
     <div>
       <CommonFooter />
     </div>
    </div>
  );
};

export default Dashboard;
