import React, { useState } from "react";
import infoIcon from "../../../common/Assets/Icons/Desk_alt.svg";
import workflow from "../../../common/Assets/Icons/Work Flow Wrench_outline.svg";
import notesIcon from "../../../common/Assets/Icons/pencil_outline.svg";
import communicateIcon from "../../../common/Assets/Icons/chatbubble_Outline_Main.svg";
import eqpmntIcon from "../../../common/Assets/Icons/Equipment_outline_Step.svg";
import mediaCenterIcon from "../../../common/Assets/Icons/book_outline.svg";
import logsIcon from "../../../common/Assets/Icons/Logs_outline.svg";
import iconizerSubtract from "../../../common/Assets/Icons/iconizerSubtract.svg";
import filledworkflow from "../../../common/Assets/Icons/filledworkflow.svg";
import filledpen from "../../../common/Assets/Icons/filledpen.svg";
import conversation from "../../../common/Assets/Icons/conversation.svg";
import equipmentfilled from "../../../common/Assets/Icons/equipmentfilled.svg";
import filledbook from "../../../common/Assets/Icons/filledbook.svg";
import filledlogs from "../../../common/Assets/Icons/filledlogs.svg";
import "./WODetailsSideBar.css";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

function WODetailsSideBar(props) {  
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const items = [
    { prevIcon: infoIcon, HoverIcon: iconizerSubtract, text: 'Info' },
    { prevIcon: workflow, HoverIcon: filledworkflow, text: 'Workflows' },
    { prevIcon: communicateIcon, HoverIcon: conversation, text: 'Communications' },
    { prevIcon: eqpmntIcon, HoverIcon: equipmentfilled, text: 'Equipment' },
    { prevIcon: mediaCenterIcon, HoverIcon: filledbook, text: 'Media Center' },
    { prevIcon: logsIcon, HoverIcon: filledlogs, text: 'Logs' },
  ];
  const handleItemClick = (index) => {
    props.scrollCallback(index); 
  };
  return (
    <div className="sidebar">
    {items?.map((item, index) => 
      <span
        className="sidebar sdbar d-flex cursor-p"
        onClick={() => handleItemClick(index)}
        key={index}
        button
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <span >
          <img 
            src={hoveredIndex === index ? item?.HoverIcon : item?.prevIcon} 
            alt="icon"
            style={{ width: '24px', height: '24px' }}
          />
        </span>
        <span className="sidebarText">{item?.text}</span>
        {/* <ListItemText  primary={item?.text} /> */}
      </span>
    )}
    </div>
  );
}

export default WODetailsSideBar;
