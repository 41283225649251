export const SampleArray = [
  {
    name: "service Type",
  },
  {
    name: "serial number",
  },
  {
    name: "completed",
  },
  {
    name: "inprogress",
  },
  {
    name: "service Type",
  },
];
export const Discription =
  " Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining ssentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

export const Error = "There is no data found";

export  const tagNames= [
  {name:"Tag 1"},
  {name:"Tag 2"},
  {name:"Tag 3"},
  {name:"Tag 4"},
  {name:"Tag 5"},
  {name:"Tag 6"},
  {name:"Tag 7"},
]




