const ReferenceType = {
  CUSTOMER: 10,
  WORK_ORDER: 20,
  EQUIPMENT: 30,
  WORK_FLOW: 40,
};
export default ReferenceType;
export const ReferenceId = {
  company: 1,
  technician: 2,
  contact: 3,
  customer: 10,
  equipment: 11,
  vendor: 20,
  item: 30,
  work_flow: 50,
  work_flow_step: 51,
  work_order: 100,
  work_order_work_flow: 101,
  work_order_step: 102,
  work_order_workflow_step: 102,
};