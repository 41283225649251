import React, { useState, useEffect } from "react";
import { Container, Modal, ModalBody, Row, Col } from "react-bootstrap";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import "./CreateTeamModal.css";
import { TEAMS_MODAL } from "../../../../common/components/Constants";
import {
  CreateUserTeams,
  UsersByID,
} from "../../../../services/SettingsServices";
import ProgressBar from "../../../../common/navigation/ProgressBar";
function CreateTeamsModal(props) {
  const [isArray, setIsArray] = useState([]);
  const [filteredUsersById, setFilteredUsersById] = useState([]);
  const [userbyid, setUserById] = useState([]);
  const [FinalArr, setFinalArr] = useState([]);
  const [searchOrgArr, setSearchOrgArr] = useState([]);
  useEffect(() => {
    setIsArray(props?.data);
    HandleItemId(props?.userId);
  }, [props?.data, props?.userId]);
  useEffect(() => {
    setFinalArr(
      isArray?.filter(
        (person) => !userbyid?.some((x) => x.user_id === person.user_id)
      )
    );
    setSearchOrgArr(
      isArray?.filter(
        (person) => !userbyid?.some((x) => x.user_id === person.user_id)
      )
    );
    setFilteredUsersById(userbyid);
  }, [isArray, userbyid]);
  const HandleItemId = async (id) => {
    let Resp = await UsersByID(id);
    if (Resp?.success) {
      setUserById(Resp?.data);
    }
  };
  
  const HandlePassData = (item, index, val) => {
    if (val === "downlevel") {
      setUserById([...userbyid, item]);
    } else {
      let x = userbyid.filter((it) => it !== item);
      setUserById(x);
      setFinalArr([...FinalArr, item]);
    }
  };
  const [isErr, setIsErr] = useState(false);
  const [existName, setExistName] = useState("");
  const [isProgress, setIsProgress] = useState(false);
  const HandleSave = async () => {
    let x =
      props.userId === 0
        ? props?.isTeams?.filter((i) => i.name === props.inpName)
        : props?.isTeams
            ?.filter((i) => i.id !== props.userId)
            ?.filter((it) => it?.name === props.inpName);

    if (x?.length > 0) {
      setExistName(x[0]?.name);
    } else {
      setExistName("");
      let y = userbyid.map((item) => ({ id: item?.user_id }));
      if (props?.inpName?.length > 0 && userbyid?.length > 0) {
        let Obj = {
          team_id: props?.userId,
          name: props?.inpName,
          description: props?.inpdesc,
          users_id: y,
        };
        setIsProgress(true)
        let Res = await CreateUserTeams(Obj);
        setIsProgress(false)
        if (Res?.success) {
          props.close();
          setIsErr(false);
          props?.TeamsCreate();
        }
      }
       else {
        setIsErr(true);
      }
      
    }
  };
  const HandleDiscard = () => {
    setUserById([]);
    props.close();
    setExistName("");
  };
  const HandleCurrentTeamSearch = (e) => {
    let searchCTeam = e.target.value?.toUpperCase();
    let filterArr = userbyid.filter(
      (it) =>
        it.first_name?.toUpperCase().includes(searchCTeam) ||
        it.last_name?.toUpperCase().includes(searchCTeam)
    );
    searchCTeam?.length > 0
      ? setFilteredUsersById(filterArr)
      : setFilteredUsersById(userbyid);
  };
  const HandleSearchOrg = (e) => {
    let searchOrg = e.target.value?.toUpperCase();
    let filterOrgArr = searchOrgArr.filter(
      (it) =>
        it.first_name?.toUpperCase().includes(searchOrg) ||
        it.last_name?.toUpperCase().includes(searchOrg)
    );
    searchOrg?.length > 0
      ? setFinalArr(filterOrgArr)
      : setFinalArr(filterOrgArr);
  };
  return (
    <div>
      <Modal
        size="lg"
        centered
        show={props?.open}
        onHide={props?.close}
        
      >
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="attach-media-note">
            {props?.userId ? (
              <span className="header-span"> {TEAMS_MODAL.MANAGE}</span>
            ) : (
              <span className="header-span">{TEAMS_MODAL.CREATE_TEAM}</span>
            )}
            {/* <span>Create New</span> */}
          </div>
          <img
            src={close}
            alt="Close"
            className="modal-header-icon"
            onClick={() => {
              HandleDiscard();
              setIsErr(false);
              setExistName("");
            }}
          />
        </Modal.Header>
        {isProgress !== false ? (
        <div className="progress-bar">
          <ProgressBar />
        </div>
      ) : (
        <ModalBody className="p-2 ">
          <div className="">
            <div className="cnc-modal-gap ">
              <span className="ctm-curnt-team">{TEAMS_MODAL.T_NAME}</span>
            </div>

            <input
              type="text"
              name="name"
              value={props.inpName}
              className="teamsinp-place"
              onChange={props.HandleTeamChange}
            />
            <div>
              {existName?.length > 0 && (
                <span className="exist-name">{`${existName} Already Exist`}</span>
              )}
            </div>
            <div className="cnc-modal-gap ">
              <span className="ctm-curnt-team">{TEAMS_MODAL.DESCRIPTION}</span>
            </div>
            <input
              type="text"
              name="name"
              className="teamsinp-descrptn"
               value={props?.inpdesc}
              onChange={props?.HandleTeamdesc}
            />
            {isErr && props.inpName.length <= 0 ? (
              <div style={{ color: "red" }}>{TEAMS_MODAL.TEAM_NAME}</div>
            ) : (
              ""
            )}

            <div className="ctm-curnt-team mt-2">
              <span className="ctm-curnt-team ">
                {TEAMS_MODAL.CURRENT_USER}
              </span>
              <div>
                {" "}
                <input
                  onChange={(e) => HandleCurrentTeamSearch(e)}
                  type="text"
                  placeholder="Search Name ?"
                  className="teamsinp-place"
                />{" "}
              </div>
              <Row className="width-100% p-1">
                <Col xs={1} className="usr-ctm-hdr-us ">
                  {" "}
                  <span>{TEAMS_MODAL.NAME}</span>
                </Col>
                <Col xs={9} className="usr-ctm-hdr-us ">
                  <span style={{ position: "relative", left: "17px" }}>
                    {TEAMS_MODAL.TEAM}
                  </span>
                </Col>
                <Col xs={2} className="usr-ctm-hdr-us ">
                  <span style={{ position: "relative", left: "5px" }}>
                    {TEAMS_MODAL.SELECT}
                  </span>
                </Col>
              </Row>
              {isErr && userbyid?.length <= 0 && (
                <div style={{ color: "red" }}>{TEAMS_MODAL.CURRENT_USER}</div>
              )}
              <div className="seleted-teams">
                {filteredUsersById?.length > 0 &&
                  filteredUsersById?.map((it, index) => (
                    <Container key={index} fluid className="">
                      <Row
                        style={{ height: "max-content" }}
                        className={
                          props.isId === it.user_id
                            ? "bgcolor-change u-setup-main"
                            : "u-setup-main "
                        }
                      >
                        <Col className=" u-sngl-teams" xs={5}>
                          <input
                            className="inp-sett-usr-stp w-100 p-1 "
                            value={`${it.first_name}  ${it.last_name}`}
                          />
                        </Col>
                        <Col className="u-sngl-teams " xs={6}>
                          <div className=" w-100 teams-multpl">
                            {it?.team?.map((i) => i?.name)?.join(",")}
                          </div>
                        </Col>

                        <Col className=" u-sngl-teams " xs={1}>
                          <input
                            type="checkbox"
                            onClick={() =>
                              HandlePassData(it, index, "toplevel")
                            }
                            className="ml-2"
                            checked
                          />
                        </Col>
                      </Row>
                    </Container>
                  ))}
              </div>
            </div>
          </div>

          <div className="mt-1">
            <span className="ctm-mem-org">{TEAMS_MODAL.MEMBERS_ORG}</span>
            <div className="ctm-mem-sub mt-2">
              {" "}
              <span>{TEAMS_MODAL.SEL_USER}</span>{" "}
            </div>
            <div>
              {" "}
              <input
                placeholder="Search Name ?"
                type="text"
                className="teamsinp-place mt-2"
                onChange={(e) => HandleSearchOrg(e)}
              />{" "}
            </div>
            <Container className="mt-2 container_box">
              <Row>
                <Col xs={1} className="usr-ctm-hdr-us ">
                  {" "}
                  {TEAMS_MODAL.NAME}
                </Col>
                <Col
                  xs={9}
                  className="usr-ctm-hdr-us"
                  style={{ position: "relative", left: "17px" }}
                >
                  {TEAMS_MODAL.TEAM}
                </Col>
                <Col
                  xs={2}
                  className="usr-ctm-hdr-us"
                  style={{ position: "relative", left: "10px" }}
                >
                  {TEAMS_MODAL.SELECT}
                </Col>
              </Row>
              <div className="container-ctm">
                {FinalArr?.map((it, index) => (
                  <div key={index} className="container-ctm2">
                    <Row
                      style={{ height: "max-content" }}
                      className={
                        props.isId === it.user_id
                          ? "bgcolor-change "
                          : "u-setup-main "
                      }
                    >
                      <Col className=" u-sngl-teams   " xs={5}>
                        <input
                          className="inp-sett-usr-stp  p-1"
                          placeholder={`${it.first_name}  ${it.last_name}`}
                          value={`${it.first_name}  ${it.last_name}`}
                        />
                      </Col>
                      <Col className=" u-sngl-teams " xs={6}>
                        <div className="w-100 teams-multpl">
                          {it?.team?.map((i) => i?.name)?.join(",")}
                        </div>
                      </Col>

                      <Col className=" u-sngl-teams" xs={1}>
                        <input
                          type="checkbox"
                          onClick={() => HandlePassData(it, index, "downlevel")}
                          className="ml-4"
                          checked={false}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </ModalBody>
      )}
        <div className="gap-1 modal-footer-parent mt-2 p-2 model_footer" style={{ top: 0 }}>
          <div className="">
            {" "}
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() => HandleDiscard()}
            >
              {props?.userId ? (
                <span className="header-span"> {TEAMS_MODAL.CANCEL}</span>
              ) : (
                <span className="header-span">{TEAMS_MODAL.DISCORD}</span>
              )}
            </button>{" "}
          </div>
          <div className="">
            {" "}
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() => HandleSave()}
            >
              {props?.userId ? (
                <span className="header-span"> {TEAMS_MODAL.SAVE}</span>
              ) : (
                <span className="header-span">{TEAMS_MODAL.CREATE}</span>
              )}
            </button>{" "}
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default CreateTeamsModal;
