
import React, {useRef} from 'react'
import {  Modal } from "react-bootstrap";
import closeModal from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { CUST_CONTACT, TAGS_MODAL_VALUES } from '../../common/components/Constants';
import prfl from "../../common/Assets/Icons/user_circle_outline_main.svg";
import plus from "../../common/Assets/Icons/Add_round.svg";
import pen from "../../common/Assets/Icons/penb.svg";
import del from "../../common/Assets/Icons/del.svg";

export default function ContatModal(props) {
  const fileInputRef = useRef(null);

  return (
    <div>
      <Modal
        centered
        size="md"
        show={props.isContactModal}
        onHide={() => props.HandleCloseContactModal()}
      >
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="attach-media-usersetup">
            {" "}
            {props?.isBtn === "Save" ? "Edit" : "Create"} Contact
            {/* {props?.name}  */}
            {/* #{props?.Customer} */}
          </div>
          <img
            src={closeModal}
            alt="Close"
            className="modal-header-icon"
            onClick={() => props.HandleCloseContactModal()}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="prfl-modal">
            {/* {props?.profileImage ? (
              <img alt="Profile" src={props?.CustContact?.picture} style={{borderRadius: "50%"}} className="img-styles" />
            ) : (
              <img alt="Default Profile" src={props?.isImgObj?.picture ? props?.isImgObj?.picture :prfl} className="img-styles" />
            )} */}
             <img alt="Default Profile" style={{borderRadius:'50%'}} src={props?.isImgObj?.picture ? props?.isImgObj?.picture :prfl} className="img-styles" />
          </div>
          <div className="prfl-modal"
            style={{
              marginLeft: "50px",
              cursor: "pointer",
            }}
          >
           
           {!props?.profileImage ? (
              <img
                src={plus}
                alt="Add"
                onClick={() => fileInputRef.current?.click()}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                <img
                  src={pen}
                  alt="Edit"
                  style={{ width: "15px", height: "20px" }}
                  onClick={() => fileInputRef.current?.click()}
                />
                <img
                  src={del}
                  alt="Delete"
                  style={{ width: "15px", height: "20px" }}
                  onClick={props?.handleDeleteCntctImg}
                />
              </div>
            )}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => props?.handleImageUpload(e, props?.CustContact)}
            />
           </div>
           <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="contact-modal-span">{CUST_CONTACT.F_NAME}</span>
            <input
              className="contact-modal-inp"
              value={props.CustContact.first_name}
              onChange={(e) => props.HandleInputChange(e)}
              name="first_name"
              placeholder={CUST_CONTACT.F_NAME_PL_HLDR}
            />
            <div>
              {props.isErr?.map((err) => (
                <div className="err-warnings">
                  {err?.message === "first_name" &&
                    props?.CustContact?.first_name.length <= 0 &&
                    `Please Enter First Name`}
                </div>
              ))}
            </div>
            <span className="contact-modal-span">{CUST_CONTACT.L_NAME}</span>
            <input
              className="contact-modal-inp"
              value={props?.CustContact?.last_name}
              name="last_name"
              onChange={(e) => props.HandleInputChange(e)}
              placeholder={CUST_CONTACT.L_NAME_PL_HLDR}
            />
            <div>
              {props.isErr?.map((err) => (
                <div className="err-warnings">
                  {err?.message === "last_name" &&
                    props.CustContact?.last_name.length <= 0 &&
                    `Please Enter Last Name`}
                </div>
              ))}
            </div>
            <span className="contact-modal-span">{CUST_CONTACT.PH_NUMBER}</span>
            <input
              className="contact-modal-inp"
              type="input"
              value={props.CustContact.telephone}
              name="telephone"
              onChange={(e) => props.HandleInputChange(e)}
              placeholder={CUST_CONTACT.PH_NMBR_PL_HLDR}
              pattern="\d{3}-\d{3}-\d{4}"
            />
            <div>
              {props?.phoneexisterr?.length > 0 && (
                <span className="exist-name">{props?.phoneexisterr}</span>
              )}
            </div>
            <div>
              {props.isErr?.map((err) => (
                <div className="err-warnings">
                  {err?.message === "telephone" &&
                    props.CustContact?.telephone.length <= 0 &&
                    `Please Enter Phone Number`}
                  {err?.message === "Telephone" &&
                    `Please Enter valid Phone Number`}
                </div>
              ))}
            </div>
            <span className="contact-modal-span">{CUST_CONTACT.E_MAIL}</span>
            <input
              className="contact-modal-inp"
              name="email_id"
              value={props.CustContact.email_id}
              onChange={(e) => props.HandleInputChange(e)}
              placeholder={CUST_CONTACT.E_MAIL_PL_HLDR}
            />
            <div>
              {props?.emailexisterr?.length > 0 && (
                <span className="exist-name">{props?.emailexisterr}</span>
              )}
            </div>
            <div>
              {props.isErr?.map((err) => (
                <div className="err-warnings">
                  {err?.message === "Email" &&
                    (props.CustContact?.email_id.length === 0
                      ? "Please Enter Email"
                      : props?.validateEmail(props.CustContact?.email_id)
                        ? "Please Enter a valid email"
                        : "")}
                </div>
              ))}
            </div>
            <span className="contact-modal-span">{CUST_CONTACT.DECSCRIPTION}</span>
            <input
              className="contact-modal-inp"
              value={props.CustContact?.note}
              onChange={(e) => props.HandleInputChange(e)}
              name="note"
              placeholder={CUST_CONTACT.DESCRP_PL_HLDR}
            />
            <div>
              {props.isErr?.map((err) => (
                <div className="err-warnings">
                  {err?.message === "note" &&
                    props.CustContact?.note.length <= 0 &&
                    `Please Enter Description`}
                </div>
              ))}
             </div>
            </div>
          <   div className="gap-1 modal-footer-parent mt-2" style={{ top: 0 }}>
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() => props.HandleCloseContactModal()}
            >
              {TAGS_MODAL_VALUES.CANCEL}
            </button>
            <button
              className="modal-footer-save-cancel-btns"
              onClick={() => props.handleSave("props.isEdit", props?.item)}
            >
              {props.isBtn}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

