import { WorkflowSteps } from "../../../services/WorkOrdersService";

export const WorkFlowCheck = async (data) => {
  let workflow = data?.work_order_workflow_id;
  let id = window?.location?.href?.split("/")[5];
  let Step_require = true;
  let ReqStepsRes = await WorkflowSteps(workflow, id, Step_require);
  let ReqSteps = [];
  let NotReqSteps = [];
  if (ReqStepsRes[0]?.Workflows_steps?.length <= 0) {
    let workflow = data?.work_order_workflow_id;
    let id = window?.location?.href?.split("/")[5];
    let Step_require = false;
    let NotReqStepsRes = await WorkflowSteps(workflow, id, Step_require);
    NotReqSteps = NotReqStepsRes;
  } else {
    ReqSteps = ReqStepsRes;
  }
  return { ReqSteps, NotReqSteps };
};
