import React from 'react'
import "./FileRoom.css";
import CommonHeader from '../../common/framework/header/HeaderCommon/CommonHeader';
import CommonFooter from '../../common/framework/footer/CommonFooter';
import { Card } from 'react-bootstrap';

function FileRoom() {
  return (
    <div className="page-container" >
        <div  className='content-wrap' style={{ padding: "0px 0px 0px 0px" }}>
            <div className="sticky-headers">
                <CommonHeader />
            </div>
            <div>
               <Card className='m-2'>
                 <Card.Header>eded</Card.Header>
                 <Card.Body>eded</Card.Body>
               </Card>
            </div>
        </div>   
        <CommonFooter/> 
    </div>
  )
}

export default FileRoom