
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import addfieldsIcon from "../../common/Assets/Icons/Left Icon.svg";
import "./CustomerRightSideBar.css";
import contactsIcon from "../../common/Assets/Icons/persons_main.svg";
import {
  CUST_CONTACT,
  WORK_ORDER_CONSTANTS,
} from "../../common/components/Constants";
import contactprfl from "../../common/Assets/Icons/user_circle_outline_main.svg";
import dail from "../../common/Assets/Icons/dailicon.svg";
import messageIcon from "../../common/Assets/Icons/messageIcon.svg";
import dltIcon from "../../common/Assets/Icons/del.svg";
import add from "../../common/Assets/Icons/add.svg";
import {
  CustmoerContacts,
  CustomerPrimaryContact,
  DeleteContact,
} from "../../services/WorkOrdersService";
import Alerts from "../../common/components/Alerts/Alerts";
import ContactModal from "./ContactModal";
import { CUSTOMER } from "../../common/components/Values";
import CustNotes from "./CreateNewcustomer/CustNotes";
import { GetCustExtraDetailsById } from "../../services/AddCusEqService";
import DeleteModal from "../../common/components/Alerts/DeleteModal";
import { create } from "@mui/material/styles/createTransitions";

export default function CustomerRightSideBar(props) {
  const location = useLocation();
  const custId = props?.custId;
  const custVal = props?.isCustVal;
  const [isToggle, setIsToggle] = useState("Extrafield");
  const [isContactsToggle, setIsContactsToggle] = useState(true);
  const [isExtaFieldsToggle, setIsExtraFieldsToggle] = useState(true);
  const [isContactModal, setIsContactModal] = useState(false);
  const [isEdit, setIsEdit] = useState(0);
  const [isBtn, setIsBtn] = useState("");
  const [isErr, setIsErr] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [custNotes, setCustNotes] = useState([]);
  let TechId = JSON.parse(localStorage.getItem("userId"));
  const HandleToggle = (value) => {
    setIsToggle(value);
  };
  const [isCustomerId, setIsCustomerId] = useState(0);
  const [isContacts, setIsContacts] = useState([]);
  const [refreshContacts, setRefreshContacts] = useState(false);
  const [isContactslist, setIsContactsList] = useState([]);
  const [prmryContactname, setPrmryContactname] = useState([]);
  const [primaryContact, setPrimaryContact] = useState([]);
  const handleSelectChange = useCallback(
    async (e) => {
      const contactData = isContacts?.find(
        (contact) => contact?.name === e?.target?.value
      );
      setPrimaryContact(contactData);
      if (contactData) {
        const newObj = {
          customer_id: contactData?.customer_id,
          contact_id: contactData?.contact_id,
        };

        try {
          await CustomerPrimaryContact(newObj);

        } catch (error) { }
      }
    },
    [isContacts]
  );
  useEffect(() => {
    setIsCustomerId(props?.custId);
    async function HandleContacts() {
      if (+props?.custId > 0) {
        const prmyconty = await GetCustExtraDetailsById(props?.custId);
        if (prmyconty?.success) {
          const contactList = prmyconty?.data?.[0]?.contact;
          setIsContactsList(contactList);
          setIsContacts(contactList);
          const primaryContactData = contactList?.find(
            (data) => data?.contact_id === prmyconty?.data?.[0]?.contact_id
          );
          setPrmryContactname(primaryContactData);
        } else {
          setIsContacts([]);
        }
      }
    }
    if (+props?.custId > 0) {
      HandleContacts();
    }

    const customerId = props?.custId
      ? props?.custId
      : location?.state?.item?.customer_id ||
      location?.state?.item?.Customer?.id;
    getCustExtraDetails(customerId);
  }, [
    location?.state?.item?.Customer?.id,
    location?.state?.item?.customer_id,
    props?.custId,
    refreshContacts
  ]);
  const [profileImage, setProfileImage] = useState("");

  const handleImageUpload = (e, item) => {
    const file = e?.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setProfileImage(base64String);
        item.picture=base64String
        setCustContact({
          ...CustContact, picture: base64String,
        })
      };
      reader.readAsDataURL(file);
      setIsImgObj(item)
      setProfileImage("")
    
    }
  };
  const handleDeleteCntctImg = () => {
  setIsImgObj({})
  setCustContact({
    ...CustContact, picture: "",
   })
  };
  const [CustContact, setCustContact] = useState({
    picture: "",
    first_name: "",
    last_name: "",
    email_id: "",
    telephone: "",
    note: "",
  });
  const getCustExtraDetails = async (id) => {
    let Res = await GetCustExtraDetailsById(id);
    if (Res?.success) {
      setCustNotes(Res?.data[0]?.notes ?? []);
    }
  };
  const [delContact, setDelContact] = useState(false);
  const [contdltid, setContDltid] = useState(null);
  const [ContactcustId, setContactcustId] = useState(null)
  const HandleDeleteContact = async (e, item) => {
    setContDltid(item?.contact_id)
    setContactcustId(item?.customer_id)
    e.stopPropagation();
    setDelContact(true);
  };
  const HandleDeleteContact2 = async (item) => {
    const Resp = await DeleteContact(ContactcustId, contdltid);
    if (Resp?.success) {
      setDelContact(false);
      setIsContacts((prevContacts) =>
        prevContacts?.filter((contact) => contact?.contact_id !== contdltid)
      );
    }
  };
  const HandleCloseContactModal = () => {
    setemailexisterr("")
    setphoneexisterr("")
    setIsContactModal(false);
    setIsErr([]);
  };
  const validateTelephone = (number) => {
    const phoneRegex =
      /^\d{3}-\d{3}-\d{4}$/;
    return !phoneRegex.test(number);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !emailRegex.test(email);
  };
  const HandleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "telephone") {
      const cleaned = value.replace(/\D/g, "");
      const formatted = cleaned
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        .substr(0, 12); 
      setCustContact({
        ...CustContact,
        [name]: formatted,
      })}
    else{setCustContact({ ...CustContact, [name]: value });}
    
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const filteredContacts =
    searchQuery.trim() === ""
      ? isContacts
      : isContacts?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
  // useEffect(() => {
  //   const savedContact =  isContacts.find((c) => c.contact_id === contactId);;
  //   if (savedContact) {
  //     setPrimaryContact(savedContact);
  //   }
  // }, []);

  // useEffect(() => {
  //   const fetchCustomerDetails = async () => {
  //     try {
  //       const prmyconty = await getCustExtraDetails(props?.CustData?.customer_id);
  //     } catch (error) {
  //       console.error('Failed to fetch customer details:', error);
  //     }
  //   };
  //   fetchCustomerDetails();
  // }, []);
  const [cntctId, setcntctId] = useState()
  const [isImgObj, setIsImgObj] = useState({})
  const HandleAddContact = (key, item) => {
    setcntctId(item?.contact_id)

    setIsImgObj(item <= 0 ? {} : item)
    setIsEdit(item?.contact_id);
    setIsBtn(key);
    setIsContactModal(true);
    item
      ? setCustContact({
        picture: item?.picture,
        first_name: item?.first_name,
        last_name: item?.last_name,
        email_id: item?.email,
        telephone: item?.telephone,
        note: item?.note,
      })
      : setCustContact({
        picture: "",
        first_name: "",
        last_name: "",
        email_id: "",
        telephone: "",
        note: "",
      });
  };
  const [emailexisterr, setemailexisterr] = useState("")
  const [phoneexisterr, setphoneexisterr] = useState("")
  const handleSave = async () => {

    let filterData = []

    if (isBtn === "Create") {
      filterData = isContacts
    }
    else if (isBtn === "Save") {
      filterData = isContacts.filter(
        (item) =>
          item?.contact_id !== cntctId
      )
    }
    let existingphone = filterData.filter(
      (item) => item?.telephone === CustContact?.telephone
    );
    if (existingphone?.length > 0) {
      setphoneexisterr("Telephone already exists")
    } else {
      setphoneexisterr('')
    }
    let existingEmail = filterData.filter((item) =>
      item?.email === CustContact?.email_id

    )
    if (existingEmail?.length > 0) {
      setemailexisterr("Email already exists")
    } else {
      setemailexisterr('')
    }

    let errArr = [];
    let filterObj = Object.keys(CustContact);
    filterObj.map((it) => {
      if (it === "picture") {
        return "";
      }
      if (
        (CustContact[it]?.length <= 0 && it !== "email_id") ||
        (CustContact[it]?.length <= 0 && it !== "note")
      ) {
        errArr.push({
          message: it,
        });
      }

      if (it === "telephone" && CustContact[it]?.length > 0) {
        if (validateTelephone(CustContact[it])) {
          errArr.push({
            message: `Telephone`,
          });
        }
      }
      if (it === "email_id") {
        if (validateEmail(CustContact[it])) {
          errArr.push({
            message: `Email`,
          });
        }
      }
      errArr = errArr.filter(
        (err) => err.message !== "email_id"
      );
      return errArr;
    });

    if (errArr?.length > 0) {
      setIsOpen(true);
      setIsErr(errArr);
    } else {
      setIsErr([]);
      let custdata = {
        // customer_id: `${cidNo}`,
        customer_id: isCustomerId,
        contact_id: isEdit !== undefined ? isEdit : 0,
        name: "",
        first_name: CustContact?.first_name,
        last_name: CustContact?.last_name,
        address: "",
        address_2: "",
        city: "",
        state: "",
        zip_code: "",
        telephone: CustContact?.telephone,
        email: CustContact?.email_id,
        note: CustContact?.note,
        telephone_2: "",
        country: "",
        picture: CustContact?.picture,
        contacts_additional_fields: {},
      };
      if (existingphone?.length <= 0 && existingEmail?.length <= 0) {
        let Res = await CustmoerContacts(custdata);
        if (Res.success) {
          setRefreshContacts(true);
          let Obj =
          {
            ...Res?.data, picture: CustContact?.picture
          }
          isEdit !== 0
            ? setIsContacts([
              ...isContacts?.filter((i) => i?.contact_id !== isEdit),
              Obj,
            ])
            : setIsContacts([...isContacts, Obj]);
          setIsContactModal(false);
        }
      }
    }
  };

  return (
    <div className="mt-2 " style={{ position: "fixed", width: "24%" }}>
      <div className=" sidebar-rightside-cust">
        <span
          className={`txt-style-sidebar cursor-p ${isToggle === "Notes" ? "bold-text" : ""
            }`}
          onClick={() => HandleToggle("Notes")}
        >
          {WORK_ORDER_CONSTANTS.NOTES}
        </span>
        <span
          className={`txt-style-sidebar cursor-p ${isToggle === "Contacts" ? "bold-text" : ""
            }`}
          onClick={() => HandleToggle("Contacts")}
        >
          {WORK_ORDER_CONSTANTS.CONTACTS}
        </span>
        <span
          className={`txt-style-sidebar cursor-p ${isToggle === "Extrafield" ? "bold-text" : ""
            }`}
          onClick={() => HandleToggle("Extrafield")}
        >
          {WORK_ORDER_CONSTANTS.EXTRA_FILED}
        </span>
      </div>
      {isToggle === "Notes" ? (
        <div className="mt-1 rightside_box">
                 <div
            style={{
              padding: "0px 0px 0px 0px",
              overflowY: "auto",
              overflowX: "hidden",
              height: "80vh",
              maxHeight: "calc(100vh - 80px)",
            }}
          >
            <CustNotes
              data={custNotes}
              getCustExtraDetails={getCustExtraDetails}
              custVal={custVal}
              setNotesArray={setCustNotes}
              custId={custId}
              apiData={{
                id: 0,
                TechId: TechId,
              }}
            />
          </div>
        </div>
      ) : isToggle === "Contacts" ? (
        <div className="mt-1 rightside_box">
          <div className="contacts-container">
            <div className="contacts-info ">
              <img src={contactsIcon} alt="contacts" className="" />
              <span
                className="cust-contacts-span mx-1 cursor-p"
                onClick={() => setIsContactsToggle(!isContactsToggle)}
              >
                Contacts (
                {isContacts?.length === undefined
                  ? "0"
                  : `${isContacts?.length}`}
                )
              </span>
            </div>

            {props?.isCustVal !== "create" && (
              <div
                className="add-contact"
                style={{
                  pointerEvents:
                    window?.location?.href?.split("/")[10] === "1"
                      ? "none"
                      : "auto",
                }}
              >
                <img
                  src={add}
                  alt="add"
                  className="add-cntct-icon"
                  onClick={() => {
                    setRefreshContacts(false);
                    HandleAddContact("Create", 0);
                  }}
                />
              </div>
            )}
          </div>
          <div className="d-flex  flex-column">
            <label className="prim-contact">Primary Contact</label>
            <select
              className="prim-contact-drpdwn "
              style={{
                pointerEvents:
                  window?.location?.href?.split("/")[10] === "1"
                    ? "none"
                    : "auto",
              }}
              name="Primary Contact"
              value={primaryContact?.name || prmryContactname?.name}
              onChange={(e) => handleSelectChange(e, isContacts)}
            >
              <option className="prim-contact-drpdwn " value="">
                Select Contact
              </option>
              <input placeholder="Search Contact" className="bg-warning" />
              {isContactslist?.map((data) => (
                <option value={data?.name} key={data?.name}>
                  {data?.name}
                </option>
              ))}
            </select>
          </div>
          {isContactsToggle && isContacts?.length > 8 && (
            <div style={{ marginTop: '5px' }}>
              <input
                type="text"
                placeholder="Search contacts"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                  width: "95%",
                  padding: "8px",
                  boxSizing: "border-box",
                  height: '35px'
                }}
              />
            </div>
          )}
          <div
            style={{
              padding: "0px 0px 0px 0px",
              overflowY: "auto",
              overflowX: "hidden",
              height: "70vh",
              maxHeight: "calc(100vh - 80px)",
            }}
          >

            {isContactsToggle && (
              <>
                {filteredContacts?.length > 0 ? (
                  filteredContacts.map((item, index) => (
                    <div
                      className="contact-card"
                      style={{
                        height: "80px",
                        pointerEvents:
                          window?.location?.href?.split("/")[10] === "1"
                            ? "none"
                            : "auto",
                      }}
                      onClick={() => HandleAddContact(CUST_CONTACT.SAVE, item)}
                      key={index}
                    >
                      <div className="dail-icon">
                        <div>
                          <img src={item?.picture ? item?.picture : contactprfl} alt="cntctprfl" style={{ height: '22px', width: '22px', borderRadius: '50px' }} />
                          <span className="text-contct">{item?.name}</span>
                        </div>
                        <div className="bg-suc">
                          <img className="dail-img" src={dail} alt="dailIcon" />
                          <img
                            className="message-icon"
                            src={messageIcon}
                            alt="messageIcon"
                          />
                          <img
                            className="message-icon"
                            src={dltIcon}
                            alt="dltIcon"
                            onClick={(e) => HandleDeleteContact(e, item)}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <span className="cust-text-telephone">
                          {item?.telephone}
                        </span>
                      </div>
                      <div className="mt-1">
                        <span className="cust-text-email">{item?.email}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <span>No contacts found</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="mt-1 add-fields-wrsb rightside_box">
          <img src={addfieldsIcon} alt="addfields" className="addflds-img" />
          <span
            className="notes-header-txt-cust  cursor-p"
            onClick={() => setIsExtraFieldsToggle(!isExtaFieldsToggle)}
          >
            {WORK_ORDER_CONSTANTS.EXTRA_FILED}
          </span>{" "}
          {/* {props.additionalFields?.map((i) => (
            <div className="">
              <div className="d-flex ">
                <span className="wo-sb-txt">{i?.name} </span>
                <span className="wo-sb-colon">:</span>
                <span className="wo-sb-txt-val">{i?.value}</span>
              </div>
            </div>
          ))} */}
          {isExtaFieldsToggle && props.extraFieldComponent}
        </div>
      )}
      <ContactModal
        Customer={isCustomerId}
        name={CUSTOMER.CUSTOMER}
        isBtn={isBtn}
        isImgObj={isImgObj}
        woid={0}
        CustContact={CustContact}
        isErr={isErr}
        isEdit={isEdit}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        isContactModal={isContactModal}
        HandleCloseContactModal={HandleCloseContactModal}
        HandleInputChange={HandleInputChange}
        handleSave={handleSave}
        handleImageUpload={handleImageUpload}
        handleDeleteCntctImg={handleDeleteCntctImg}
        emailexisterr={emailexisterr}
        phoneexisterr={phoneexisterr}
        validateEmail={validateEmail}
      />
      <Alerts isOpen={isOpen} isClose={() => setIsOpen(false)} data={isErr} />
      <DeleteModal
        Open={delContact}
        Close={() => setDelContact(false)}
        DelDiscription={"Delete?"}
        HandleDelete={() => HandleDeleteContact2()}
      />
    </div>
  );
}
