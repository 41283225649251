
import moment from "moment";
export const ExtraFieldsTypeValids = (key,e,field_id,controlType, value,additionalFields1) => {
    const tempData = [...additionalFields1]?.map((i) => {
      let tempObj = { ...i };
      if (i.field_id === field_id) {
        tempObj.value = value;
      }
      if (i.field_id === field_id && controlType === "integer") {
        const regex1 = /[^0-9]/g;
        let intValue = e.target.value;
        let filteredintvalue = intValue.replace(regex1, "");
        tempObj[key] = filteredintvalue;
      } else if (i.field_id === field_id && controlType === "string") {
        const regex2 = /^[a-zA-Z0-9]*$/;
        let stringValue = e.target.value;
        if (regex2.test(stringValue)) {
          tempObj[key] = stringValue;
        }
      } else if (i.field_id === field_id && controlType === "numeric") {
        const regex2 =  /[^0-9.]+/g;
        let numericValue = e?.target?.value;
        let filterednumericValue = numericValue?.replace(regex2, "");
        const decimalCount = (filterednumericValue?.match(/\./g) || [])?.length;
        if (decimalCount > 1) {
          // If more than one decimal, keep only the first one
          filterednumericValue = filterednumericValue?.split('.')?.slice(0, 2)?.join('.');
      }
        tempObj[key] = filterednumericValue;
      } else if (i.field_id === field_id && controlType === "date") {
        let dateValue = e;
        tempObj[key] = moment(dateValue).format("DD-MM-YYYY");
      }
      return tempObj;
    });
    return tempData
  };