import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import blackfile from "../../Assets/Icons/blackfile.svg"
import wcross from "../../Assets/Icons/closeS.svg";
import fileroomcust from "../../Assets/Icons/fileroomcust.svg";
import workflowfr from "../../Assets/Icons/workflowfr.svg"
import equipmentfilled from "../../Assets/Icons/equipmentfilled.svg"
import Close from "../../Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import memeoryIcon from "../../Assets/Icons/Memory Board Icon.svg"
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import { AppGlobalContextUpdate } from "../../context/AppGlobalContext";
import { MemoryDelete } from "../../../services/WorkOrdersService";
export default function Filemodal(props) {
  const navigation = useNavigate();
  const updateAppState = useContext(AppGlobalContextUpdate);
  const navToPage = (to, params, data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params, data },
      navigate: navigation,
    });
  };
  const navToWoDetls = (e, to, wdObj, isVal,woid,custid) => {

    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: { wdObj, isVal,woid:wdObj?.work_order_id,custid:wdObj?.customer_id},
      navigate: navigation,
    });
    e.stopPropagation();
  };
  const checkBeforeNavigation = (e, value) => {
    const workOrderId = props?.moddata?.work_order_id;
    const customerId = props?.moddata?.customer_id;
    const userId = JSON.parse(localStorage.getItem("userId"));
    const techPin = JSON.parse(localStorage.getItem("techpin"));
    const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
    let flag = value;
    if (flag === 1 && (props?.itemkey === "workorders" || props?.itemkey === "workflws")) {
      navToWoDetls(
        e,
        `WoDetails/${workOrderId}/${customerId}/${userId}/${techPin}/${companyCode}/0`,
        props?.moddata,
        "details",
        props?.moddata?.work_order_id,
        props?.moddata?.customer_id
      );
    }
    if (flag === 1 && props?.itemkey === "eqps") {

      navToPage(`AddEquipments/${workOrderId}/${customerId}/${userId}/${techPin}/${companyCode}/0`,
        "equipdetails",
        props?.moddata?.equipment_id
        )
    }
    if (flag === 1 && props?.itemkey === "customers") {
      // NavigateFunction({
      //   to: "CreateNewcustomer",
      //   params: { key: 'edit', item: props?.moddata },
      //   navigate: navigation,
      // });
      NavigateFunction({
        to:`CreateNewcustomer/${0}/${0}/${userId}/${techPin}/${companyCode}/${0}`,
        params: {
          key:props?.moddata?.customer_id,
        },
        navigate: navigation,
      });
    }
  };
  let recentId = props?.moddata?.recent_view_id;
  let Id = props?.woId;
  
  const memoryDelete = async () => {
    const res = await MemoryDelete(recentId, Id);
  
    if (res?.success === true) { 
      props?.setDeleteitem(recentId);    
    }
    props?.isClose()
  }
  return (
    <>
      <Modal show={props?.isOpen} onHide={props?.isClose} centered>
        <Modal.Header className="modal-bg-color  " style={{ height: "40px" }}>
          <span className=" text-white">{props?.woName} #{props?.woId ? props?.woId : props?.eqpid}</span>
          <img
            className="modal-header-icon"
            onClick={props?.isClose}
            src={Close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            minHeight: "200px",
          }}
        >
          <div className=" d-flex flex-column">
            {
              props?.itemkey === "eqps" ? (
                <>
                  <span >Model # <span className="fltxt">{props?.moddata?.model_number}</span></span>
                  <span>Serial # <span className="fltxt">{props?.moddata?.serial_number}</span></span>
                </>
              ) : props?.itemkey === "customers" ? (
                <>
                  <span>Customer # <span className="fltxt">{props?.custId}</span></span>
                  <span>Address: <span className="fltxt">{props?.custaddrs}</span></span>
                </>
              ) : (
                <span>Customer Name: <span className="fltxt">{props?.moddata?.customerName}</span></span>
              )
            }
            <span>Note:</span>
            <textarea
              readOnly
              placeholder="Please Enter Discription"
              className="w-100 cursor-p inpt-add-eqp fltxt"
              name="memoryDiscription"
              value={props?.note}
              style={{ height: "250px", outline: "none" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-styles mr-4">
          <div className="d-flex flex-column align-items-center">
            <div
              style={{ width: "300px", margin: "0 auto" }}
              className="modal-footer-save-cancel-btns m-2 text-center  p-3"
              onClick={(e) => memoryDelete(e)}
            >
              <span>
              <img className=" " src={memeoryIcon} width={18} alt="" />
                <span style={{ fontSize: "16px" }}> Remove from memory Board</span></span>
                
            </div>
            <div className="d-flex justify-content-between" style={{ width: "100%" }}>
              <div
                className="modal-footer-save-cancel-btns mx-1  p-3"
                onClick={props?.isClose}
                style={{ width: "200px" }}
              >
                <span>
                <img className=" " src={wcross} width={16} alt="" />
                  <span style={{ fontSize: "16px" }}> Close </span></span>
                
              </div>
              <div
                className="modal-footer-save-cancel-btns mx-1 p-3"
                onClick={props?.isClose}
                style={{ width: "200px" }}
              >
                <span onClick={(e) => checkBeforeNavigation(e, 1)}>
                <img className=" mx-2 mb-1" src={props?.openName === "workorders"?blackfile:props?.openName === "Equipment"?equipmentfilled:props?.openName === "customer"?fileroomcust:props?.openName === "Workflow"?workflowfr:blackfile} alt="open-icon" width={16} />
                <span style={{ fontSize: "16px" }}> Open {props?.openName}</span></span>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
