import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, ModalFooter } from "react-bootstrap";
import close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import send_main from "../../common/Assets/Icons/send_main.svg";
import personIcon from "../../common/Assets/Images/personIcon.svg";
import "./CustShareModal.css";
import Scannar from "../../common/components/sharemodal/Scannar";
import OverlayClose from "../../common/Assets/Icons/Close_round_main.svg"
import QRCode from "../../common/Assets/Icons/QRCode.svg"
import link_light from "../../common/Assets/Icons/link_light.svg"
import mail from "../../common/Assets/Icons/mail.svg"
const CustShareModal = (props) => {
  const [isCustList, setIsCustList] = useState([]); // Holds the customer list
  const [searchTerm, setSearchTerm] = useState(""); // Holds the search query
  const [filteredCustList, setFilteredCustList] = useState([]); // Holds the filtered customer list
  const [showQR, setShowQR] = useState(false); 
  const [TechList , setTechList] = useState([])
  useEffect(() => {
    setTechList(props?.techncian?.data)
    setIsCustList(props?.techncian?.data );
  }, [props?.techncian?.data]);

  useEffect(() => {
    // Filters the customer list based on the search term (both name and email)
    const filteredList = isCustList?.filter(
      (customer) =>
        customer?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustList(filteredList);
  }, [searchTerm, isCustList]);

  const clickHandler = (e, customer) => {
    e?.stopPropagation();
  
  };
const [gnrtqr,setgnrtqr] = useState(null)
  const handleGenerateQR = () => {
      setShowQR(true); // Open QR modal
      let qrId = filteredCustList?.find((item)=> item?.customer_id === props?.qrcustid)
      setgnrtqr(qrId?.customer_id? qrId?.customer_id : props?.qrcustid )
  };

  const handleCloseQR = () => {
    setShowQR(false); 
  };
  let userId = localStorage.getItem("userId");
  let TechPin = JSON.parse(localStorage.getItem("techpin"));
  let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));
  const [locpath ,setLocPath ] = useState(0)
  useEffect(() => {
    if (gnrtqr || props?.qrcustid) {
      setLocPath(
        `${window.location.protocol}//${window.location.host}/#/CreateNewcustomer/${0}/${ props?.qrcustid}/${userId}/${TechPin}/${CompanyId}/${1}`
      );
    } else {
      setLocPath(
        `${window.location.protocol}//${window.location.host}/#/CreateNewcustomer`
      );
    }
  }, [CompanyId, TechPin, gnrtqr, props?.qrcustid, userId]);
  const [isCopy, setIsCopy] = useState(false);
  const HandleCopyText = (e,textValue) => {
    e.stopPropagation()
    navigator?.clipboard?.writeText(textValue);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
      
    }, 1000);
  };
  const qrRef = useRef();
  const downloadQR = () => {
    const canvas = qrRef?.current?.querySelector('canvas');
    const url = canvas?.toDataURL("image/png");
    const a = document?.createElement('a');
    a.href = url;
    a.download = 'qr-code.png';
    a.click();
  };
 
  return (
    <>
    <Modal size="md" centered show={props.isOpen} onHide={props.isClose}>
    <Modal.Header className="modal-bg-color " style={{ height: "40px" }}>
    <div className="csm-modal-header-text line-align-bet ">
          Share
          <img
            src={close}
            className="csm-modal-header-icon"
            onClick={() => props.isClose()}
            alt="close"
          />
        </div>
        </Modal.Header>
      <Modal.Body className="modal-body-csm">
       
        <div className="invite-job mt-2">Invite to job</div>

        <div className="line-align-bet mt-2">
          <input
            className="inpt-csm"
            placeholder="Email/Name"
            value={searchTerm} // Bind searchTerm to input value
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          />

          <Button
            className="inpt-csm-btn"
            color="red"
            appearance="primary"
            size="sm"
          >
            Send invite
            <img
                src={send_main}
                className="send-image mb-1 ml-2"
                alt="send"
                height={18}
                width={22}
              />
          </Button>
        </div>
        <div className="recommand-people mt-3">
          <div className="recommand-people-heading">Recommended People</div>
          <div className="recommand-people-list">
            {TechList?.length > 0 ? (
              TechList?.map((customer) => (
                <div className="recommand-customer" key={customer?.id}>
                  <img
                    src={personIcon}
                    alt="person icon"
                    height={32}
                    width={32}
                  />
                  <div className="recommand-customer-details">
                    <span className="customer-name">{customer?.name}</span>
                    <span className="customer-email">{customer?.contact?.email}</span>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => clickHandler(e, customer)}
                    className="invite-button"
                  >
                    Invite
                  </button>
                </div>
              ))
            ) : (
              <div className="nodata-text">No contacts found</div>
            )}
          </div>
          
    
        </div>
      </Modal.Body>
      <ModalFooter className="modal-footer-styles">
      <div className="btn-section">
            <span className="sharecust-btn-qr cursor-p"  onClick={()=>handleGenerateQR()}>Generate QR Code
            <img src={QRCode} alt=""  className="mx-1"/>
              </span>  
            <span className="sharecust-btn-copy cursor-p" onClick={(e)=>HandleCopyText(e,locpath)}>{isCopy ? "Copied Link" : "Copy Link"}
            <img src={link_light} alt=""  className="mx-1"/>
            </span>
            <span className=" sharecust-btn-copy cursor-p">Email
            <img className=" mb-1 mx-1" src={mail} alt="" />
            </span>
          </div>
      </ModalFooter>
    </Modal>
    <Modal size="sm" centered show={showQR} onHide={handleCloseQR}>
    <Modal.Header>
      <Modal.Title>QR Code</Modal.Title>
      <img
        onClick={handleCloseQR}
        className="cursor-p"
        src={OverlayClose}
        alt="close"
      />
    </Modal.Header>
    {/* <Modal.Body className="text-center">
    {gnrtqr ? (
             <QRCodeCanvas  ScanData={locPath}  size={256} />
            <Scannar ScanData={locpath} />
          ) : (
            <div>No Customer Selected</div>
          )}
    </Modal.Body> */}
    <Modal.Body className="text-center qr-mod-body">
          <Scannar ScanData={locpath} />
          <input readOnly className=" mt-1 ml-1 shrinpthov inpt-det cursor-p" style={{width:"260px"}} value={locpath} />
          <div className=" d-flex justify-content-end mt-1">
        <span onClick={(e) => HandleCopyText(e,locpath)}  className=" mx-2 share-btn-qrcode cursor-p">
            {isCopy ? "Copied" : "Copy"}
          </span>
          <span onClick={downloadQR} className="share-btn-qrcode cursor-p">
            Download 
            {/* <img src={Download_step} alt="" /> */}
          </span>
        </div>
        </Modal.Body>
  </Modal>
  </>
  );
};

export default CustShareModal;
