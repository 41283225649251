import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import "./WODupModal.css";
import { WORK_ORDER_SETUP } from "../../common/components/Constants";
import {
  getWorkOrderById,
  WorkFlowRes,
  DuplicateWO,
} from "../../services/WorkOrdersService";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import { AppGlobalContextUpdate } from "../../common/context/AppGlobalContext";
import ProgressBar from "../../common/navigation/ProgressBar";

function WODupModal(props) {
  const { data } = props;
  const [tech, setTech] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [workflows, setWorkFlows] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [EqcheckedData, setEqcheckedData] = useState([]);
  const [isTechId, setIsTechId] = useState([]);
  const [techSelected, setTechSelected] = useState(false);
  const [validationMessage, setValidationMessage] = useState(""); // New state for validation message
  const [isProcess, setIsProcess] = useState(false);
  const fetchWODetails = useCallback(
    async (workId, CustId) => {
      let TOKEN =
        JSON.parse(localStorage.getItem("authToken")) === null
          ? props.wftoken
          : JSON.parse(localStorage.getItem("authToken"));
      setIsProcess(true);
      let TechId = JSON.parse(localStorage.getItem("userId"));
      const authToken = localStorage.getItem("authToken");
      const workOrderResult = await getWorkOrderById(
        workId,
        TechId,
        CustId,
        authToken
      );
      setEquipments(workOrderResult?.WorkOrderDetails?.equipments);
      setTech(workOrderResult?.WorkOrderDetails?.TechniciansData);
      const workflowRes = await WorkFlowRes(TOKEN, workId);
      const workflowsData =
        workflowRes?.data?.workorder_workflow?.data?.workorderWorkflowResponse;
      setWorkFlows(workflowsData);
      setIsProcess(false);
    },
    [props.wftoken]
  );

  useEffect(() => {
    if (data?.WorkOrderId && data?.Customer?.id)
      fetchWODetails(data?.WorkOrderId, data?.Customer?.id);
  }, [data, fetchWODetails]);
  const HandleInputChange = (item, e) => {
    if (checkedData.includes(item?.work_order_workflow_id)) {
      setCheckedData(
        checkedData.filter((id) => id !== item?.work_order_workflow_id)
      );
    } else {
      setCheckedData([...checkedData, item?.work_order_workflow_id]);
    }
    // if (e.target.checked) {

    // } else {

    // }
  };
  const handleTotalcheck = (item) => {
    if (item.length === checkedData.length) {
      setCheckedData([]);
    } else {
      let x = item?.map((i) => i.work_order_workflow_id);
      setCheckedData(x);
    }
  };
  const HandlechekedAllEp = (item) => {
    if (item?.length === EqcheckedData.length) {
      setEqcheckedData([]);
    } else {
      let x = item?.map((i) => i.equipment_id);
      setEqcheckedData(x);
    }
  };

  const HandleTechChecked = (item) => {
    if (item?.length === isTechId?.length) {
      setIsTechId([]);
    } else {
      let x = item?.map((i) => i.technician_id);
      setIsTechId(x);
    }
  };
  const HandleEquipChckbox = (item) => {
    if (EqcheckedData?.includes(item?.equipment_id)) {
      setEqcheckedData(EqcheckedData.filter((id) => id !== item?.equipment_id));
    } else {
      setEqcheckedData([...EqcheckedData, item?.equipment_id]);
    }
  };
  
  const handleTechnicianSelect = ( it) => {
    if (isTechId?.includes(it?.technician_id)) {
      setIsTechId(isTechId.filter((id) => id !== it?.technician_id));
    } else {
      setIsTechId([...isTechId, it?.technician_id]);
    }

    setTechSelected(true);
    setValidationMessage("");
  };

  const navigation = useNavigate();
  const updateAppState = useContext(AppGlobalContextUpdate);

  const navToWoDetls = (e, to, wdObj, path, woid, custid) => {
    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: {
        wdObj,
        path,
        woid: wdObj?.WorkOrderId,
        custid: wdObj?.Customer?.id,
      },
      navigate: navigation,
    });
    e.stopPropagation();
  };
  // woid:wdObj?.WorkOrderId,custid:wdObj?.Customer?.id
  const handleDupWO = async (e) => {
    // Show validation message only if no technician is selected when "Create" is clicked
    if (isTechId?.length <= 0) {
      setValidationMessage("Please select a technician.");
      return;
    } else {
      let TechId = JSON.parse(localStorage.getItem("userId"));
      let Ccode = JSON.parse(localStorage.getItem("CompanyCode"));
      const DupData = {
        workorder_id: data?.WorkOrderId,
        workorders_workflow: checkedData,
        technician_code: isTechId,
        equipments: EqcheckedData,
      };
      let Res = await DuplicateWO(DupData);
      let WoId = Res?.data?.WorkOrderData?.data?.work_order_id;
      let CustId = Res?.data?.WorkOrderData?.data?.customer_id;

      if (Res.success) {
        props.Close();
        navToWoDetls(
          e,
          `WoDetails/${WoId}/${CustId}/${TechId}/${TechId}/${Ccode}/0`,
          data,
          "DuplicateWorkOrder"
        );
      }
    }
  };
  useEffect(() => {
    setValidationMessage("");
    setCheckedData([]);
    setEqcheckedData([])
    setIsTechId([])
  }, [props.Close]);
  return (
    (
      <div>
        <Modal show={props.Open} onHide={props.Close} centered size="m">
          <Modal.Header className="modal-bg-color modal-header-height ">
            <span className="modal-header-txt">
              {WORK_ORDER_SETUP.DUPLICATE_WO}
              {data?.WorkOrderId}{" "}
            </span>
            <img
              src={Close}
              className="modal-header-icon"
              onClick={props?.Close}
              alt="Close"
            />
          </Modal.Header>
          {isProcess ? (
            <div className="pro-bar-style" style={{ height: "400px" }}>
              <ProgressBar />
            </div>
          ) : (
            <>
              <Modal.Body>
                <div className="wo-dup-content">
                  <div className="wf-hdr-wfname-span">
                    {WORK_ORDER_SETUP.DESCRPTN}
                  </div>
                  {workflows?.length > 0 ? (
                    <div className="">
                      <input
                        type="checkbox"
                        onChange={() => handleTotalcheck(workflows)}
                        checked={workflows?.length === checkedData?.length}
                      />
                      <span className="ml-1 wrk-flws">
                        {WORK_ORDER_SETUP.WRKFLWS}
                      </span>
                      {workflows?.map(
                        (item, index) => (
                          
                          (
                            <div className="wfs-name-div" key={index}>
                              <input
                                type="checkbox"
                                checked={checkedData.includes(
                                  item.work_order_workflow_id
                                )}
                                onChange={(e) => HandleInputChange(item, e)}
                              />
                              <span className="wf-wfname-span">
                                {item?.workflow_name}
                              </span>
                            </div>
                          )
                        )
                      )}
                    </div>
                  ) : (
                    <div className="no-wrkflws">No Workflows</div>
                  )}
                   {tech?.length > 0 ? (
                    <div className="">
                      <input
                        type="checkbox"
                        className=""
                        onChange={(e) => HandleTechChecked(tech, e)}
                        checked={tech?.length === isTechId?.length}
                      />
                      <span className="wrk-flws">
                        {WORK_ORDER_SETUP.TECHNICIANS}
                      </span>
                      {tech?.map(
                        (item, index) => (
                          (
                            <div className="wfs-name-div" key={index}>
                              <input
                              type="checkbox"
                              checked={isTechId?.includes(item.technician_id)}
                              onChange={() => handleTechnicianSelect(item)
                              }
                            />
                              <span className="wf-wfname-span">
                                {item?.name}
                              </span>
                            </div>
                          )
                        )
                      )}
                    </div>
                  ) : (
                    <div className="no-wrkflws">No Technicians</div>
                  )}
                 
                 
                  {validationMessage && (
                    <div className="validation-message">
                      {validationMessage}
                    </div>
                  )}
                  {equipments?.length > 0 && (
                    <div className="">
                      <input
                        type="checkbox"
                        className=""
                        onChange={() => HandlechekedAllEp(equipments)}
                        checked={equipments.length === EqcheckedData.length}
                      />
                      <span className="wrk-flws">
                        {" "}
                        {WORK_ORDER_SETUP.EQPMNT}
                      </span>
                      {equipments.map((item, index) => (
                        <div className="ml-4" key={index}>
                          <input
                            type="checkbox"
                            checked={EqcheckedData.includes(item.equipment_id)}
                            onChange={(e) => HandleEquipChckbox(item, e)}
                          />
                          <span className="wf-wfname-span">
                            {item?.equipment_type}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Body>
                <div className="modal-footer-styles mt-2 gap-2 model_footer">
                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={props?.Close}
                  >
                    Cancel
                  </div>
                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={(e) => handleDupWO(e)}
                  >
                    Create
                  </div>
                </div>
              </Modal.Body>
            </>
          )}
        </Modal>
      </div>
    )
  );
}

export default WODupModal;
