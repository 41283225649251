import React from "react";
import "./LogsData.css";
import { Table, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "react-bootstrap/Pagination";
import {
    LOGS_DATA,
    LOGS
} from "../../../common/components/Values";


let active=1;
let Items=[];

for (let number = 1; number <= 5; number++) {
    Items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
function LogsData() {

  const paginationBasic = (
    <div>
      <Pagination size="sm" >{Items}</Pagination>
      </div>)
  return (
    <>
      <Container fluid>
        <Table  className="customers">
          <thead>
            <tr>
              <th className="tbl-err-log-txt-hdr" >{LOGS.LOG_NO}</th>
              <th className="tbl-err-log-txt-hdr">{LOGS.DATE}</th>
              <th className="tbl-err-log-txt-hdr">{LOGS.ACTION}</th>
              <th className="tbl-err-log-txt-hdr">{LOGS.AUTHOR}</th>
              <th className="tbl-err-log-txt-hdr">{LOGS.DETAILS}</th>
            </tr>
          </thead>
          <tbody>
            {LOGS_DATA.map((item, index) => (
              <tr>
                <td className="tbl-err-log-txt">{item.LOG_NO} </td>
                <td className="tbl-err-log-txt">{item.DATE}</td>
                <td className="tbl-err-log-txt">{item.ACTION}</td>
                <td className="tbl-err-log-txt">{item.AUTHOR}</td>
                <td className="tbl-err-log-txt">{item.DETAILS}</td>                                                  
              </tr>
            ))}
          </tbody>
        </Table>
        {paginationBasic}
      {  [1,2,3,4,5].map((i)=>{
        <button>{i}</button>
        return i
      })}
      </Container>
    </>
  );
}

export default LogsData;
