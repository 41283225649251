import React from "react";
import { Modal } from "react-bootstrap";
import Close2 from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { WORKFLOW_VALUES } from "../../../../common/components/Values";
export default function WfCategoryTypeDelete(props) {
  return (
    <>
      <Modal
        show={props?.isWfTypeCatModalDel}
        onHide={props?.CloseDelModal}
        centered
      >
        <Modal.Header className="modal-bg-color modal-header-height">
          <div className="line-align-bet w-100 ">
            <span className="wftags-sub-header2">
              Remove {props?.isWfTypeCat}
            </span>
            <span className="cursor-p" onClick={props?.CloseDelModal}>
              <img className="modal-header-icon" src={Close2} alt="Close2" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="sub-titles-names">
            {props?.isWfTypeCat === "Tag" ? (
              <span> {WORKFLOW_VALUES.TAG_DEL_DESC} {props?.name}</span>
             
            ) : (
              <>
                Once you remove the{" "}
                {props?.isWfTypeCat === "Category"
                  ? "workflow category"
                  : "equipment type"}
                &nbsp; you cannot restore the type. All work orders that are
                currently using this type will still be using this type. Are you
                sure you want to proceed?
              </>
            )}
          </div>

          {/* <div
            className="mt-2"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <div
              className="modal-footer-save-cancel-btns"
              onClick={() => HandleDel()}
            >
              {isStatus === "file" ? "Undo" : "Delete"}
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              style={{ margin: 0 }}
              onClick={() => setIsFiles(false)}
            >
              {WORKFLOW_VALUES.CANCEL}
            </div>
          </div> */}
          <div
            className="mt-2"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <div
              className="modal-footer-save-cancel-btns"
              onClick={props?.HandleCatDel}
            >
              <span className="sub-titles-ftr">Delete</span>
              {/* <img src={del} alt="del" width={13} /> */}
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              onClick={props?.CloseDelModal}
            >
              <span className="sub-titles-ftr">Cancel</span>
              {/* <img src={Close} alt="Close" /> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
