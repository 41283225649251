import React, { useState, useEffect, useCallback, useRef } from "react";
import "./UserSetup.css";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import CreateTeamsModal from "../User Setup/CreateTeamsModal";
import DeleteModal from "../../../../common/components/Alerts/DeleteModal";
import {
  // ADD_EQUIPMENT_SCROLL,
  TAGS_MODAL_VALUES,
  USERS_SETUP,
  viewLimitOptions,
} from "../../../../common/components/Constants";
import Select, { components } from "react-select";
import phone from "../../../../common/Assets/Images/phones_call_Filled_main.jpg";
import msgo from "../../../../common/Assets/Icons/msgO.svg";
// import loc from "../../../../common/Assets/Icons/locO.svg";
import pen from "../../../../common/Assets/Icons/penb.svg";
import del from "../../../../common/Assets/Icons/del.svg";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import prflIcon from "../../../../common/Assets/Images/personIcon.svg";
import teams from "../../../../common/Assets/Icons/TeamsBold.svg";
import plusIcon from "../../../../common/Assets/Icons/add_main.svg";
import usersetup from "../../../../common/Assets/Icons/userPrfl.svg";

import {
  UsersPost,
  CreateTeams,
  DelTeamID,
  userSetUp,
  GetTechnicians,
  PackageGet,
  GetDivision,
  GetUserTechnicians,
} from "../../../../services/SettingsServices";
import Alerts from "../../../../common/components/Alerts/Alerts";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import ProgressBar from "../../../../common/navigation/ProgressBar";
function UserSetup(props) {
  const [SalesData, setSalesData] = useState([]);
  const [PackageData, setPackageData] = useState([]);
  const [DivisionData, setDivisionData] = useState([]);
  const [FilteredTechs, setFilteredTechs] = useState([]);
  const [Users, setUser] = useState([]);
  // const [selectedTechnician, setSelectedTechnician] = useState("");
  useEffect(() => {
    GetTechs();
    GetPackages();
    GetDivisionData();
  }, []);

  useEffect(()=>
  {
    setFilteredTechs( SalesData?.filter(item => 
      !Users?.some(user => user?.technician_code === item?.technician_code)))
  },[SalesData, Users])

  const GetTechs = async () => {
    let res = await GetTechnicians();
    setSalesData(res?.data);
  };
  const GetPackages = async () => {
    let res = await PackageGet();
    setPackageData(res?.data);
  };
  const GetDivisionData = async () => {
    let res = await GetDivision();
    setDivisionData(res?.data);
  };

  const TeamsCreate = useCallback(async () => {
    let res = await CreateTeams();
    if (res?.success) {
      setIsTeams(res?.data);
      UserSetupFetch();
    }
  }, []);
  useEffect(() => {
    TeamsCreate();
  }, [TeamsCreate]);
  const [isTeams, setIsTeams] = useState([]);
  const [isDelId, setisDelId] = useState(0);
  const [isDelName, setIsDelName] = useState("");
  // const [dropdownOptions, setDropdownOptions] = useState( []);
  const DeleteTeams = async (it) => {
    setIsDelModal(true);
    setisDelId(it?.id);
    setIsDelName(it?.name);
  };
  const DeleteTeamsById = async () => {
    await DelTeamID(isDelId);
    setIsDelModal(false);
    TeamsCreate();
  };
  
  const [Tech,setTech] = useState([])
  const [filterUsers, setFilterUsers] = useState([]);
  const [filterTechs,setFilterTechs] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState("");
  const [isErr, setIsErr] = useState([]);
  const [name, setName] = useState("");
  const [descpn,setdescpn] = useState("")
  const [desc, setDesc] = useState("");
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const [isDelModal, setIsDelModal] = useState(false);
  const [editTeams, setEditTeams] = useState("");
  const [isTeamsToggle, setIsTeamsToggle] = useState(true);
  const [isUserToggle, setIsUserToggle] = useState(true);
  const [isTechToggle, setIsTechToggle] = useState(true);
  const [userNameError, setUserNameError] = useState("");

  const [delProp] = useState(
    "Once you remove the team you cannot restore the team. Are you sure you want to proceed? This will remove all current and previous mailing lists."
  );
  const handleTeamsToggle = () => {
    setIsTeamsToggle(!isTeamsToggle);
  };

  const handleUserToggle = () => {
    setIsUserToggle(!isUserToggle);
  };
  const handleTechToggle = () => {
    setIsTechToggle(!isTechToggle);
  };
  const [isuserId, setIsUserId] = useState(0);
  const HandleTeamsModal = (it) => {
    if (it === "add") {
      setIsUserId(0);
      setDesc("");
      setName("");
      setdescpn("")
    } else {
      setIsUserId(it?.id);
      setName(it.name);
      setdescpn(it.description)
      setDesc(it?.description);
    }
    setCreateTeamModal(true);
  };
  const UserSetupFetch = async () => {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    setToken(TOKEN);
    let userResp = await userSetUp(TOKEN);
    if (userResp?.success) {
      userResp?.success && setUser(userResp?.data);
      setFilterUsers(userResp?.data);
    }
  };
  const UserTechFetch = async () => {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    setToken(TOKEN);
    let techResp = await GetUserTechnicians(TOKEN);
    if (techResp?.success) {
      techResp?.success && setTech(techResp?.data?.techniciansWithDetails);
      setFilterTechs(techResp?.data?.techniciansWithDetails);
    }
  };
useEffect(()=>
{
  UserTechFetch()
},[])
  const [editUsrStp, setEditUsrStp] = useState(false);
  const [userSetup, setUserSetup] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    active: "",
    telephone: "",
    password: "",
    create_equipment: 0,
    communication_enable: 0,
    create_work_order: 0,
    role: [],
    user_name: "",
    view_limit: viewLimitOptions[0]?.viewLimit_id,
    technician_code: "",
    package: [],
    division: "",
  });
  const HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

  // Handle technician selection if the input is a technician select box
  if (name === "technician_code") {
    const techCode = value;
    const technician = SalesData.find((tech) => tech.technician_code === techCode);
    const nameParts = technician?.name?.trim()?.split(' ');
    setUserSetup({
      ...userSetup,
      first_name: nameParts?.[0] || "",
      last_name:  nameParts?.[nameParts?.length - 1] || "",
      email_id: technician?.email || "",
      telephone: technician?.phone || "",
      technician_code: techCode
    });
    // setSelectedTechnician(techCode);
  } else {
    // Handle regular input change for other fields
    setUserSetup({
      ...userSetup,
      [name]: type === "checkbox" ? checked : value,
    });
  }
    // const { name, value, type, checked } = e.target;
    // setUserSetup({
    //   ...userSetup,
    //   [name]: type === "checkbox" ? checked : value,
    // });
  };
  const [isEdit, setIsEdit] = useState("");
  const [isId, setIsId] = useState(0);
  const HandleEditUsrStp = (key, item) => {
    let pack = item?.description;
    setIsEdit(key);
    setIsId(item?.user_id !== undefined ? item?.user_id : 0);
    setEditUsrStp(true);
    item
      ? setUserSetup({
          // ...item,
          // view_limit: viewLimitOptions[+item.view_limit + 1],
          // active: item.active === "Active" ? true : false,
          // create_equipment: Boolean(+item.create_equipment),
          // communication_enable: Boolean(+item.communication_enable),
          // create_work_order: Boolean(+item.create_work_order),
          // role: item?.team.map((i) => ({ label: i?.name, value: i?.team_id })),
          first_name: item?.first_name,
          last_name: item?.last_name,
          email_id: item?.email_id,
          active: item.active === "Active" ? true : false,
          telephone: item?.telephone,
          password: item?.password,
          create_equipment: Boolean(+item.create_equipment),
          communication_enable: Boolean(+item.communication_enable),
          create_work_order: Boolean(+item.create_work_order),
          view_limit: item.view_limit,
          user_name: item?.user_name,
          technician_code: item?.technician_code,
          role: item?.team?.map((i) => ({ label: i?.name, value: i?.team_id })),
          package: PackageData?.packageData
            ?.filter((i) => i?.description === pack)
            ?.map((option) => ({
              label: option.description,
              value: option.description,
            })),
          division: DivisionData?.find(
            (i) => i?.division_id === +item?.division_id
          )?.name
            ? DivisionData?.find((i) => i?.division_id === +item?.division_id)
                ?.name
            : "",
        })
      : setUserSetup({
          first_name: "",
          last_name: "",
          email_id: "",
          active: "",
          telephone: "",
          password: "",
          create_equipment: 0,
          communication_enable: 0,
          create_work_order: 0,
          view_limit: viewLimitOptions[0]?.viewLimit_id,
          technician_code: "",
          user_name: "",
          role: [],
          package: [],
          division: "",
        });
  };
  const HandleCloseUserModal = () => {
    setEditUsrStp(false);
    setIsErr([]);
    setUserNameError("");
    setIsId(0);
  };
  const validateTelephone = (number) => {
    // const phoneRegex1 =
    //   /^(?:\(?\d{3}\)?[\s\-]?\d{3}[\s\-]?\d{4}|\d{9})$/;
      const phoneRegex1 = /^(\(\d{3}\)\s?|\d{3})\d{3}[-\s]?\d{4}$/;
    return phoneRegex1.test(number);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !emailRegex.test(email);
  };

  const [emailExist, setEmailExist] = useState("");
  const [isProgress,setIsProgress] = useState(false);
  const handleSave = async (item) => {
    let x = [];
    if (isEdit === "Edit") {
      x = [];
    } else {
      x = Users?.filter((it) => it.email_id === userSetup?.email_id);
    }
    if (x.length > 0) {
      setEmailExist(x[0]?.email_id);
    } else {
      setEmailExist("");
      let errArr = [];
      let filterObj = Object.keys(userSetup);
      filterObj.map((it) => {
        if (it !== "active" && it !== "role") {
          if (userSetup[it]?.length <= 0 || userSetup[it] === null) {
            errArr.push({
              message: ` ${it}`,
            });
          } else {
            if (it === "telephone") {
              if (!validateTelephone(userSetup[it])) {
                errArr.push({
                  message: `Telephone`,
                });
              }
            }
            if (it === "email_id") {
              if (validateEmail(userSetup[it])) {
                errArr.push({
                  message: `Email`,
                });
              }
            }
          }
        }
        return errArr;
      });
      if (errArr?.length > 0) {
        setIsOpen(true);
        setIsErr(errArr);
      } else {
        setIsErr([]);
        setUserNameError("");
        const selectedDivision = DivisionData.find(
          (division) => division.name === userSetup.division
        );
        let inpObj = {
          first_name: userSetup.first_name,
          last_name: userSetup.last_name,
          email_id: userSetup.email_id,
          active: userSetup.active === true ? "Active" : "InActive",
          telephone: userSetup.telephone,
          password: userSetup.password,
          user_id: `${isId}`,
          teams_id: multiusers,
          create_equipment: userSetup.create_equipment === true ? "1" : "0",
          communication_enable:
            userSetup.communication_enable === true ? "1" : "0",
          create_work_order: userSetup.create_work_order === true ? "1" : "0",
          view_limit: userSetup.view_limit,
          user_name: userSetup.user_name,
          technician_code: userSetup.technician_code,
          division_id: selectedDivision?.division_id || "",
          description: userSetup?.package,
        };
        setIsProgress(true);
        let Res = await UsersPost(token, inpObj);
        if (Res) {
          UserSetupFetch();
          setIsProgress(false);
          setEditUsrStp(false);
        }
      }
    }
  };

  const [isEye, setIsEye] = useState(false);
  const HandleEye = () => {
    setIsEye(!isEye);
  };
  const [isSearch, setIsSearch] = useState("");
  const HandleSearch = (e) => {
    setIsSearch(e.target.value);
    let searchinp = e.target.value?.toUpperCase();
    let SearchArr = filterUsers.filter(
      (it) =>
        it.first_name?.toUpperCase()?.includes(searchinp) ||
        it.last_name?.toUpperCase()?.includes(searchinp)
    );
    searchinp.length > 0 ? setUser(SearchArr) : setUser(filterUsers);
  };
  const [isTechsearch,setIsTechSearch] = useState("")
  const HandleTechSearch = (e) => {
    setIsTechSearch(e.target.value);
    let searchinp = e.target.value?.toUpperCase();
    let SearchArr = filterTechs.filter(
      (it) =>
        it?.technician_data?.technician_name?.toUpperCase()?.includes(searchinp)
    );
    searchinp?.length > 0 ? setTech(SearchArr) : setTech(filterTechs);
  };
  
  const HandleTeamChaneClick = (e) => {
    setName(e.target.value);
    
  };
  const HandleTeamdesc = (e)=>
  {
    setdescpn(e.target.value)
  }

  const [colorId, setColorId] = useState(0);

  const HandleColorByID = (i) => {
    setColorId(i);
  };

  const contentRefs = useRef([]);

  useEffect(() => {
    scrollToDiv(props?.isUserField ? props?.isUserField : 0);
  }, [props.isUserField]);
  const scrollToDiv = (index) => {
    const contentDiv = contentRefs.current[index];
    contentDiv.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  // const handleSelect = (user) => {
  //   setSelectedUser(`${user.first_name} ${user.last_name}`);
  // };
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };
  const GetTeams = isTeams?.map((it) => {
    return {
      value: it?.id,
      label: it?.name,
    };
  });
  const [multiusers, setmultiUsers] = useState([]);
  const HandleSelctChange = (it) => {
    let x = it.map((i) => ({
      id: i?.value,
    }));
    setmultiUsers(x);
    setUserSetup((userSetup) => ({ ...userSetup, role: it }));
  };
  const teamsStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "gray",
        fontSize: "11px",
        fontFamily: "SofiaProBold",
      };
    },
    option: (base) => ({
      ...base,

      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
    control: (base) => ({
      ...base,
      "&:hover": {
        backgroundColor: "#d8d9db",
        cursor: "pointer",
        border: 0,
        // backgroundColor:var(--neutral-200, #d8d9db),
      },
      maxHeight: 500,
      minHeight: 32,
      boxShadow: "none",
      border: "1px solid gray",
      alignContent: "center",
    }),

    menu: (base) => ({
      ...base,
      width: "100%",
      backgroundColor: "white",
    }),
    singleValue: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
    // valueContainer: (provided, state) => ({
    //   ...provided,
    //   display: "flex",
    //   flexWrap: "wrap",
    //   alignItems: "center",
    //   height: "32px", // Adjust this value to set minimum height
    //   padding: "0px",
    // }),
  };
  const packageSelectStyles = {
    option: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
    control: (base) => ({
      ...base,
      "&:hover": {
        backgroundColor: "#d8d9db",
        cursor: "pointer",
        border: "0",
      },
      maxHeight: 500,
      minHeight: 32,
      boxShadow: "none",
      border: "1px solid gray",
      alignContent: "center",
    }),
    menu: (base) => ({
      ...base,
      width: "100%",
      backgroundColor: "white",
    }),
    singleValue: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
  };

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#cfcbca",
    },
  });
  const HandleSelectChng = (options) => {
     options.map((i) => ({
      id: i?.value,
    }));
    setUserSetup((userSetup) => ({ ...userSetup, package: options }));
  };
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ''; // handle null or undefined
    const str = phoneNumber?.toString()?.replace(/\D/g, ''); // remove any non-digit characters
    return str?.replace(/(\d)(\d{3})(\d{3})(\d{3})/, '$1-$2-$3-$4');
  };
  
  return (
    <>
      <div className="top_header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: "solid 1px #dedede"
        }}
      >
        {" "}
        <span className="usr-stp-hdr-span ml-2 ">User Setup</span>
        <span className=" ">
          {/* <button
            style={{
              border: "none",
              backgroundColor: "#1a60a3",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              textAlign: "center",
              borderRadius: "10px",
              padding: "4px 6px",
            }}
          >
            <span
              style={{
                fontSize: "10px",
                color: "white",
                fontFamily: "SofiaProRegular",
              }}
              onClick={CheckArrayFunc}
            >
              {" "}
              Download
            </span>
          </button> */}
        </span>
      </div>
      <div className="mt-1 height-73">
        <div className="head-stick">
          {/* <CommonHeader />
          <SettingsHeader data={"UserSetup"} />
          <hr style={{ margin: "0px" }} /> */}
        </div>
        <div className="bottom-space card">
          <div className="mt-1" ref={(ref) => (contentRefs.current[0] = ref)}>
            {" "}
            <div className="line-align-bet clp_header">
              <div onClick={() => handleTeamsToggle()}>
                <img src={teams} alt="" className="teams-image-styles" />
                <span className="usr-stp-hdr-span ml-2 ">Teams</span>
              </div>

              <div>
                <div>
                  {" "}
                  <img
                    src={plusIcon}
                    alt="addTeams"
                    size={20}
                    className="team-add"
                    onClick={() => HandleTeamsModal("add")}
                  />
                </div>
              </div>
            </div>
          </div>
          {isTeamsToggle ? (
            <div>
              {editTeams === "teamsOn" ? (
                ""
              ) : (
                <div className="m-1">
                  {/* <div className="span-styles-teams ">
                <img src={plusd} alt="plus" style={{ height: "20px" }} />
                <span
                  className="cursor-p teams-text"
                  onClick={() => HandleTeamsModal("add")}
                >
                  Create Team
                </span>
              </div> */}
                  <div className="card">
                    {isTeams?.map((it) => (
                      <div
                        style={{
                          background:
                            it.id === colorId ? "rgb(237, 234, 234)" : "white",
                        }}
                        className={
                          name === it.name
                            ? "bgcolor-change "
                            : "u-setup-main-teams "
                        }
                      >
                        <div className="user-setup-name line-align-bet mt-1  ">
                          {it?.name === "Create Team"}
                          <div
                            className="cursor-p ml-2 teams-text w-100"
                            onClick={() => HandleTeamsModal(it)}
                          >
                            {it?.name}
                          </div>

                          <div className="d-flex p-2">
                            <img
                              src={del}
                              alt="delete "
                              onClick={() => DeleteTeams(it)}
                              className="icon-sz ml-2"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            " "
          )}
          {/* {user data} */}
          <div className="" ref={(ref) => (contentRefs.current[1] = ref)}>
            <div
              className="line-align-bet cursor-p clp_header"
              style={{ position: "relative", top: "2px" }}
            >
              <div className="" onClick={() => handleUserToggle()}>
                <img
                  src={usersetup}
                  alt="usersetup"
                  className="teams-image-styles"
                />
                <span className="usr-stp-hdr-span ">User Setup</span>
              </div>

              <div>
                <img
                  src={plusIcon}
                  alt="addTeams"
                  size={20}
                  className="team-add"
                  onClick={() => HandleEditUsrStp("create")}
                />
              </div>
            </div>

            {isUserToggle ? (
              <div className="m-1">
                <BiSearch size={18} className=" search-icon" />
                <input
                  type="search"
                  placeholder="Search User"
                  className="search-users "
                  onChange={(e) => HandleSearch(e)}
                  value={isSearch}
                />{" "}
                <Row className="mt-2">
                  <Col xs={4} className="usr-stp-hdr-us-name ">
                    {USERS_SETUP.NAME_US}
                  </Col>
                  <Col xs={4} className="usr-stp-hdr-us-name">
                    {USERS_SETUP.TEAMS}
                  </Col>
                  <Col xs={3} className="usr-stp-hdr-us">
                    {USERS_SETUP.PH_NMBR}
                  </Col>
                  <Col xs={1} className="usr-stp-hdr-us ">
                    {USERS_SETUP.EDIT}
                  </Col>
                </Row>
                <div className="card m-1 ">
                  {Users?.length > 0 ? (
                    Users?.map((it, index) => (
                      <Container key={index} fluid className="row-container">
                        <Row
                          style={{ height: "max-content" }}
                          className={
                            isId === it.user_id
                              ? "bgcolor-change u-setup-main"
                              : "u-setup-main"
                          }
                        >
                          <Col className=" u-sngl" xs={4}>
                            <input
                              className="inp-sett-usr-stp w-100 p-1"
                              placeholder={`${it.first_name}  ${it.last_name}`}
                              value={`${it.first_name}  ${it.last_name}`}
                              readOnly
                            />
                          </Col>
                          <Col className=" u-sngl" xs={4}>
                            {/*<select
                              className=" inpt-add-eqp  lbl-ipt-add-eqp w-100"
                              name="condition"
                              disabled
                            >
                              <option selected>
                                {" "}
                                {it?.role ? it?.role : "Please Select"}
                              </option>
                              <option value="Technician">Technician</option>
                              <option value="Back Office">Back Office</option>
                              <option value="Scheduling">Scheduling</option>
                              <option value="Admins">Admins</option>
                              <option value="Crew">Crew</option>
                            </select>{" "}*/}
                            <div className="inp-sett-usr-stp w-100 p-1">
                              {it?.team
                                ?.map((eachTeam) => eachTeam?.name)
                                ?.join(", ")}
                            </div>
                          </Col>
                          <Col className=" telephn-ustp" xs={3}>
                            {/* {it?.telephone} */}
                            {formatPhoneNumber(it?.telephone)}
                          </Col>
                          <Col className=" u-sngl" xs={1}>
                            <img
                              src={pen}
                              alt=""
                              className="u-single-icon-edit  cursor-p "
                              onClick={() =>
                                HandleEditUsrStp(USERS_SETUP.EDIT, it)
                              }
                            />
                          </Col>
                        </Row>
                      </Container>
                    ))
                  ) : (
                    <div className="err-data-found">There is no Data Found</div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {tech data} */}
          <div className="" ref={(ref) => (contentRefs.current[2] = ref)}>
            <div
              className="line-align-bet cursor-p clp_header"
              style={{ position: "relative", top: "4px" }}
            >
              <div className="" onClick={() => handleTechToggle()}>
                <img
                  src={usersetup}
                  
                  alt="usersetup"
                  className="teams-image-styles"
                />
                <span className="usr-stp-hdr-span  ">Technician Setup</span>
              </div>
            </div>

            {isTechToggle ? (
              <div className="m-1">
                <BiSearch size={18} className=" search-icon" />
                <input
                  type="search"
                  placeholder="Search User"
                  className="search-users "
                  onChange={(e) => HandleTechSearch(e)}
                  value={isTechsearch}
                />{" "}
                <Row className="mt-2">
                  <Col xs={4} className="usr-stp-hdr-us-name ">
                    {USERS_SETUP.NAME_US}
                  </Col>
                  <Col xs={3} className="usr-stp-hdr-us-name">
                    {USERS_SETUP.TEAMS}
                  </Col>
                  <Col xs={3} className="usr-stp-hdr-us">
                    {USERS_SETUP.PH_NMBR}
                  </Col>
                  <Col xs={2} className="usr-stp-hdr-us ">
                    {USERS_SETUP.USER_TIED}
                  </Col>
                </Row>
                <div className="card m-1 ">
                  {Tech?.length > 0 ? (
                    Tech?.map((it, index) => (
                      <Container key={index} fluid className="row-container">
                        <Row
                          style={{ height: "max-content" }}
                          className={
                               "u-setup-main"
                          }
                        >
                          <Col className=" u-sngl" xs={4}>
                            <input
                              className="inp-sett-usr-stp w-100 p-1"
                              placeholder={it?.name}
                              value={it?.technician_data?.technician_name} 
                              readOnly
                            />
                          </Col>
                          <Col className=" u-sngl" xs={3}>
                            {/*<select
                              className=" inpt-add-eqp  lbl-ipt-add-eqp w-100"
                              name="condition"
                              disabled
                            >
                              <option selected>
                                {" "}
                                {it?.role ? it?.role : "Please Select"}
                              </option>
                              <option value="Technician">Technician</option>
                              <option value="Back Office">Back Office</option>
                              <option value="Scheduling">Scheduling</option>
                              <option value="Admins">Admins</option>
                              <option value="Crew">Crew</option>
                            </select>{" "}*/}
                            <div className="inp-sett-usr-stp w-100 p-1">
                            {it?.teams
                                ?.map((eachTeam) => eachTeam?.team_name)
                                ?.join(",")}
                            </div>
                          </Col>
                          <Col className=" telephn-ustp" xs={3}>
                            {it?.technician_data?.phone}
                          </Col>
                          <Col className=" u-sngl" xs={2}>
                          <input
                              className="inp-sett-usr-stp w-100 p-1"
                              placeholder={it?.name}
                              value={it?.user_data
                                ?.map((eachTeam) => eachTeam?.user_name)
                                ?.join(",")}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </Container>
                    ))
                  ) : (
                    <div className="err-data-found">There is no Data Found</div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <Modal
            centered
            size="md"
            show={editUsrStp}
            onHide={() => HandleCloseUserModal()}
          >
            <Modal.Header className="modal-bg-color modal-header-height px-2">
              <div className="attach-media-usersetup">
                {" "}
                {isEdit === USERS_SETUP.EDIT
                  ? USERS_SETUP.EDIT_USR
                  : USERS_SETUP.ADD_USER}
              </div>
              <img
                src={close}
                alt="Close"
                className="modal-header-icon"
                onClick={() => HandleCloseUserModal()}
              />
            </Modal.Header>
            {isProgress === true ? (
              <div 
               style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "410px",
               }}>
                <ProgressBar/>
              </div>
            ):(
            <Modal.Body className="p-2 ">
              <div>
                <Card className="">
                  <div className=" line-align-bet">
                    <div className="d-flex">
                      <img
                        className="img-profile-usr-stp mt-2 mx-2"
                        alt="face"
                        src={prflIcon}
                      />
                      <span className="d-flex flex-column">
                      <span className="mdl-name-bld-usr-stp mt-3">
                        {`${
                          userSetup?.first_name ? userSetup?.first_name : ""
                        } ${userSetup?.last_name ? userSetup?.last_name : ""}`}
                      </span>
                      <span className="d-flex flex-column"
                      style={{fontSize:"12px"}}>
                        <span>{`${userSetup?.telephone ? userSetup?.telephone : ""}`}</span>
                        <span>{`${userSetup?.email_id ? userSetup?.email_id : ""}`}</span>
                      </span>
                      </span>
                    </div>
                    <span className="m-3 d-flex">
                      <img
                        src={phone}
                        alt=""
                        className="u-single-icon phone-icon cursor-p ml-2 "
                      />
                      <img
                        alt=""
                        src={msgo}
                        className="u-single-msg-icon cursor-p ml-1"
                      />
                      {/* <img alt="" src={loc} className="cursor-p " /> */}
                    </span>
                  </div>
                  {/* <div className="work-order-text mx-2">
                    {ADD_EQUIPMENT_SCROLL.DESCRIPTION}
                  </div> */}
                </Card>
                <div className="user-modal-body">
                
                <div>
                    <span className="frm-mdl-usr-stp-edt-nm">Technician:</span>
                    {isEdit === "create" ?
                    (<select
                      className="inpt-add-eqp cursor-p lbl-ipt-add-eqp w-100"
                      name="technician_code"
                      onChange={(e) => HandleInputChange(e)}
                      value={userSetup?.technician_code}
                    >
                      <option value={""}>Please Select</option>
                      {FilteredTechs?.length > 0 ?
                      FilteredTechs?.map((option, index) => (
                          <option key={index} value={option?.technician_code}>
                            {option?.name}
                          </option>
                        )) : <option value={""}>No Technician to select</option>}
                    </select>)
                    : (<select
                      className="inpt-add-eqp cursor-p lbl-ipt-add-eqp w-100"
                      name="technician_code"
                      // onChange={(e) => HandleInputChange(e)}
                      value={userSetup?.technician_code}
                      disabled
                    >
                      <option value={""}>{SalesData?.find((item) => item?.technician_code === userSetup?.technician_code)?.name}</option>
                    </select>)}
                    <div>
                      {isErr.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " technician_code" &&
                          userSetup?.technician_code?.length <= 0 &&
                          `please select Technician`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                  <div className="">
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {USERS_SETUP.F_NAME}
                    </span>
                    <input
                      className="frm-mdl-usr-stp-edt-ipt w-100"
                      // placeholder="First Name"
                      name="first_name"
                      value={userSetup.first_name}
                      onChange={(e) => HandleInputChange(e)}
                    />
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " first_name" &&
                            userSetup?.first_name.length <= 0 &&
                            `Please Enter First Name`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {USERS_SETUP.L_NAME}
                    </span>
                    <input
                      className="frm-mdl-usr-stp-edt-ipt w-100"
                      // placeholder="Last Name"
                      name="last_name"
                      value={userSetup.last_name}
                      onChange={(e) => HandleInputChange(e)}
                    />
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " last_name" &&
                            userSetup?.last_name.length <= 0 &&
                            `Please Enter Last Name`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {" "}
                      {USERS_SETUP.USER_NAME}
                    </span>
                    <input
                      className="frm-mdl-usr-stp-edt-ipt w-100"
                      // placeholder="user_name"
                      autoComplete="new-username"
                      // disabled={istechId !== 0 && true}
                      name="user_name"
                      value={userSetup.user_name}
                      onChange={(e) => HandleInputChange(e)}
                    />
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " user_name" &&
                            userSetup?.user_name.length <= 0 &&
                            `Please Enter User Name`}
                        </div>
                      ))}
                      <div className="err-warnings">{userNameError}</div>
                    </div>
                  </div>
                  <div className="">
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {" "}
                      {USERS_SETUP.CX_PTL_EML}
                    </span>
                    <input
                      className="frm-mdl-usr-stp-edt-ipt w-100"
                      // placeholder="abc@abc123.com"
                      name="email_id"
                      value={userSetup.email_id}
                      onChange={(e) => HandleInputChange(e)}
                    />
                    <div>
                      {emailExist?.length > 0 && (
                        <span className="exist-name">{`${emailExist} Already Exist`}</span>
                      )}
                    </div>
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " email_id" &&
                            userSetup?.email_id.length <= 0 &&
                            `Please Enter User Email`}
                          {err?.message === "Email" &&
                            `Please Enter valid ${err.message}`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {" "}
                      {USERS_SETUP.PASSWORD}
                    </span>
                    <div>
                      <input
                        className="frm-mdl-usr-stp-edt-ipt w-100"
                        autoComplete="new-password"
                        disabled={isId !== 0 && true}
                        name="password"
                        type={!isEye ? "password" : "text"}
                        value={userSetup.password}
                        onChange={(e) => HandleInputChange(e)}
                      />
                      <div style={{ position: "relative" }}>
                        {isErr?.map((err) => (
                          <div className="err-warnings">
                            {err?.message === " password" &&
                              userSetup?.password.length <= 0 &&
                              `Please Enter User Password`}
                          </div>
                        ))}
                        {!isEye ? (
                          <AiFillEyeInvisible
                            onClick={() => HandleEye()}
                            className="Eye-style"
                          />
                        ) : (
                          <AiFillEye
                            onClick={() => HandleEye()}
                            className="Eye-style"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {" "}
                      {USERS_SETUP.USER_PH_NO}
                    </span>
                    <input
                      className="frm-mdl-usr-stp-edt-ipt w-100"
                      // placeholder="1-555-555-555"
                      name="telephone"
                      value={userSetup.telephone}
                      onChange={(e) => HandleInputChange(e)}
                    />
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " telephone" &&
                            userSetup?.telephone.length <= 0 &&
                            `Please Enter Customer Phone Number`}
                          {err?.message === "Telephone" &&
                            `Please Enter valid telephone`}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {" "}
                      {USERS_SETUP.TEAM}
                    </span>
                    {/* <select
                        className="inpt-add-eqp cursor-p lbl-ipt-add-eqp w-100"
                        name="role"
                        onChange={(e) => HandleInputChange(e)}
                      > */}
                    {/* {userSetup.role
                            ? userSetup.role
                            : "Please Select Team"}{" "} */}

                    {/* {isTeams?.map((team) => (
                          <option key={team?.name} className="dropdown-item">
                            {team?.name}
                          </option>
                        ))}
                      </select> */}
                    <Select
                      className="w-100 multi-select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      styles={teamsStyles}
                      theme={theme}
                      onChange={(options) => {
                        HandleSelctChange(options);
                      }}
                      maxMenuHeight={100}
                      value={userSetup.role}
                      options={GetTeams}
                      isMulti
                      placeholder={"Select Teams"}
                      components={{
                        Option: InputOption,
                      }}
                    />
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " role" &&
                            userSetup?.role.length <= 0 &&
                            `Please Enter ${err?.message}`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <span className="frm-mdl-usr-stp-edt-nm">
                      {" "}
                      {USERS_SETUP.LIMIT_WO}
                    </span>
                    <select
                      className="inpt-add-eqp cursor-p lbl-ipt-add-eqp w-100"
                      name="view_limit"
                      onChange={(e) => HandleInputChange(e)}
                      value={userSetup.view_limit}
                    >
                      <option value="">Plase Select</option>
                      {viewLimitOptions?.map((option, index) => (
                        <option key={index} value={option?.viewLimit_id}>
                          {option?.name}
                        </option>
                      ))}
                    </select>

                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " view_limit" &&
                            userSetup?.view_limit.length <= 0 &&
                            `Please Enter ${err?.message}`}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* <div>
                    <span className="frm-mdl-usr-stp-edt-nm">Technician</span>
                    <select
                      className="inpt-add-eqp cursor-p lbl-ipt-add-eqp w-100"
                      name="technician_code"
                      onChange={(e) => HandleInputChange(e)}
                      value={userSetup.technician_code}
                    >
                      <option value={""}>Please Select</option>
                      {SalesData?.length > 0 &&
                        SalesData?.map((option, index) => (
                          <option key={index} value={option?.technician_code}>
                            {option?.name}
                          </option>
                        ))}
                    </select>
                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " technician_code" &&
                            userSetup?.technician_code.length <= 0 &&
                            `Please Enter Technician`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div> */}
                    <span className="frm-mdl-usr-stp-edt-nm">Divisions</span>
                    <select
                      className="inpt-add-eqp cursor-p lbl-ipt-add-eqp w-100"
                      name="division" // Change this to 'division'
                      onChange={(e) => HandleInputChange(e)}
                      value={userSetup.division} // This should work correctly now
                    >
                      <option value={""}>Please Select</option>
                      {DivisionData?.length > 0 &&
                        DivisionData?.map((option, index) => (
                          <option key={index} value={option?.name}>
                            {option?.name}
                          </option>
                        ))}
                    </select>

                    <div>
                      {isErr?.map((err) => (
                        <div className="err-warnings">
                          {err?.message === " division" &&
                            (userSetup?.division === null ||
                              userSetup?.division.length <= 0) &&
                            `Please Enter Divisions`}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="custom-dropdown"
                    style={{ pointerEvents: "auto" }}
                  >
                    <span className="frm-mdl-usr-stp-edt-nm">Packages:</span>

                    <Select
                      className="w-100 multi-select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      styles={packageSelectStyles}
                      theme={theme}
                      onChange={(options) => {
                        HandleSelectChng(options);
                      }}
                      maxMenuHeight={100}
                      value={userSetup.package} // Display selected values
                      options={PackageData?.packageData?.map((option) => ({
                        label: option.description,
                        value: option.description,
                      }))} // Map to react-select options
                      isMulti
                      placeholder={"Select Packages"}
                      components={{ Option: InputOption }} // Custom Option with checkbox
                    />

                    <div>
                      {isErr?.map((err, index) => (
                        <div key={index} className="err-warnings">
                          {err?.message === " package" &&
                            (!userSetup?.package ||
                              userSetup?.package.length <= 0) &&
                            `Please Enter Packages`}
                        </div>
                      ))}
                    </div>
                  </div>

                  <span className="frm-mdl-usr-stp-edt-nm">
                    {" "}
                    {USERS_SETUP.RULES}
                  </span>
                  <div className="d-flex flex-wrap">
                    <div className="mt-1 w-50 user-setup-status">
                      <span className="frm-mdl-usr-stp-edt-nm">
                        {" "}
                        {USERS_SETUP.ACTIVE}
                      </span>
                      <input
                        className="mx-2 mt-1"
                        type="checkbox"
                        name="active"
                        value={userSetup.active}
                        checked={
                          userSetup.active === "Active" ||
                          userSetup.active === true
                            ? "checked"
                            : ""
                        }
                        onChange={(e) => HandleInputChange(e)}
                      />
                    </div>
                    <div className="mt-1 w-50 user-setup-status">
                      <span className="frm-mdl-usr-stp-edt-nm">
                        {" "}
                        {USERS_SETUP.ABILITY_TO_CREATE_EQIUPMENT}
                      </span>
                      <input
                        className="mx-2 mt-1"
                        type="checkbox"
                        name="create_equipment"
                        value={userSetup.create_equipment}
                        checked={+userSetup.create_equipment ? "checked" : ""}
                        onChange={(e) => HandleInputChange(e)}
                      />
                    </div>
                    <div className="mt-1 w-50 user-setup-status">
                      <span className="frm-mdl-usr-stp-edt-nm">
                        {" "}
                        {USERS_SETUP.COMUNNICATIONS_ENABLED}
                      </span>
                      <input
                        className="mx-2 mt-1"
                        type="checkbox"
                        name="communication_enable"
                        value={userSetup.communication_enable}
                        checked={
                          +userSetup.communication_enable ? "checked" : ""
                        }
                        onChange={(e) => HandleInputChange(e)}
                      />
                    </div>
                    <div className="mt-1 w-50 user-setup-status">
                      <span className="frm-mdl-usr-stp-edt-nm">
                        {" "}
                        {USERS_SETUP.ABILITY_TO_CREATE_WORK_ORDERS}
                      </span>
                      <input
                        className="mx-2 mt-1"
                        type="checkbox"
                        name="create_work_order"
                        value={userSetup.create_work_order}
                        checked={+userSetup.create_work_order ? "checked" : ""}
                        onChange={(e) => HandleInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="m-1" />
             
            </Modal.Body>
            )}
            <div
                className="gap-1 modal-footer-parent model_footer"
                style={{ top: 0 }}
              >
                <button
                  className="modal-footer-save-cancel-btns"
                  onClick={() => HandleCloseUserModal()}
                >
                  {TAGS_MODAL_VALUES.CANCEL}
                </button>
                <button
                  className="modal-footer-save-cancel-btns"
                  onClick={() => handleSave(isEdit)}
                >
                  {isEdit === USERS_SETUP.EDIT
                    ? USERS_SETUP.UPDATE
                    : TAGS_MODAL_VALUES.CREATE}
                </button>
              </div>
          </Modal>
          <Alerts
            isOpen={isOpen}
            isClose={() => setIsOpen(false)}
            data={isErr}
          />
        </div>
        {/* <CommonFooter /> */}
        <CreateTeamsModal
          open={createTeamModal}
          close={() => {
            setCreateTeamModal(false);
          }}
          data={filterUsers}
          inpName={name}
          inpdesc= {descpn}
          HandleTeamChange={HandleTeamChaneClick}
          desc={desc}
          HandleTeamdesc={HandleTeamdesc}
          setDesc={setDesc}
          userId={isuserId}
          colorFun={HandleColorByID}
          TeamsCreate={TeamsCreate}
          isTeams={isTeams}
        />

        <DeleteModal
          Open={isDelModal}
          HandleDelete={() => DeleteTeamsById()}
          Close={() => setIsDelModal(false)}
          delHeader={isDelName}
          DelDiscription={delProp}
          Data={{ step_name: isDelName }}
        />
      </div>{" "}
    </>
  );
}

export default UserSetup;
