import React, { useEffect, useState, useRef } from "react";
import "./WorkFlowSetUp.css";
import { BiSearch } from "react-icons/bi";
import Duplicate from "../../../../common/Assets/Images/Duplicate.png";
import WfWrench from "../../../../common/Assets/Icons/Work Flow Wrench.svg";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import del from "../../../../common/Assets/Icons/del.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { NavigateFunction } from "../../../../common/navigation/NavigationChild";
import Close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { WORKFLOW_VALUES } from "../../../../common/components/Values";
import { SketchPicker } from "react-color";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import plus from "../../../../common/Assets/Icons/plusW.svg";
import save from "../../../../common/Assets/Icons/save.svg";
import {
  CallTypesFetch,
  CustomerListGet,
  DelEqupTypes,
  DelWfCat,
  DeleteWoflow,
  DuplicateWorkFlow,
  EqpTypesAdd,
  EqpTypesFetch,
  GetWfAllTags,
  GetWfCatFetch,
  HandlePostWfAuto,
  WfCatAdd,
  WorkflowFetch,
  getWorkflowAutoData,
  woGtFldsTypeSett,
} from "../../../../services/SettingsServices";
import WorkflowCategory from "./WorkflowCategory";
import Tagmanager from "./Tagmanager";
import WfCategoryTypeModal from "./WfCategoryTypeModal";
import WfCategoryTypeDelete from "./WfCategoryTypeDelete";
import { addTags, deleteTags } from "../../../../services/WorkOrdersService";
import AddTag from "../../../workorders/addtag/AddTag";
import WorkFlowAtomation from "./WorkFlowAtomation";
import { workFlow_Auto, Workflow_Table_Category } from "../../../../common/components/Constants";
import ProgressBar from "../../../../common/navigation/ProgressBar";

function WorkFlowSetUp(props) {
  useEffect(() => {
    scrollToDiv(props.isSubhead);
  }, [props.isSubhead]);
  const scrollToDiv = (index) => {
    const contentDiv = contentRefs.current[index];
    contentDiv.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const [WfArr, setWfArr] = useState([]);
  const [WfFilterArr, setWfFilterArr] = useState([]);
  const [EqupTpes, setEqpTypes] = useState([]);
  const [isWfCat, setIsWfCat] = useState([]);
  const [TagData, setTagData] = useState([]);
  const [workFlowAutoData, setWorkflowAutoData] = useState([]);
  useEffect(() => {
    GetWorkFlow();
    GetEqpTypes();
    GetWfCategory();
    getAllTags();
    getWFAutoData();
  }, []);
  const GetWorkFlow = async () => {
    let Token = JSON.parse(localStorage.getItem("authToken"));
    let Res = await WorkflowFetch(Token);
    setWfArr(Res?.data);
    setWfFilterArr(Res?.data);
  };
  const GetEqpTypes = async () => {
    let Res = await EqpTypesFetch();
    setEqpTypes(Res?.data);
  };
  const GetWfCategory = async () => {
    let Res = await GetWfCatFetch();
    setIsWfCat(Res?.data);
  };
  const getAllTags = async () => {
    let Res = await GetWfAllTags();
    setTagData(Res?.data);
  };

  const getWFAutoData = async () => {
    let Res = await getWorkflowAutoData();
    setWorkflowAutoData(Res?.data?.workOrderType);
  };
  const location = useLocation();
  const navigation = useNavigate();
  const HandleWorkFlow = (to, props) => {
    NavigateFunction({
      to: to,
      params: { props, to },
      navigate: navigation,
    });
  };
  const [isFiles, setIsFiles] = useState(false);
  const [isStatus, setIsStatus] = useState("");
  const [isId, setIsId] = useState("");
  // const [inpObj, setInpObj] = useState({
  //   name: "",
  //   team: "",
  //   notification: "",
  //   category: "",
  // });
  const HandleFiles = async (item, val, to, e) => {
    if (item === "file") {
      e.stopPropagation();
      let obj = {
        workflow: val?.workflow_id,
      };
      let Res = await DuplicateWorkFlow(obj);

      let props = Res?.data?.workflow;
      NavigateFunction({
        to: to,
        params: { props, item },
        navigate: navigation,
      });
    } else {
      to.stopPropagation();
      setIsFiles(true);
    }

    setIsId(val?.workflow_id);
    // setInpObj(val);
    setIsStatus(item);
  };

  const HandleDel = async () => {
    if (isStatus === "file") {
      const Res = await DeleteWoflow(isId);
      alert(Res?.reason !== "" ? Res?.reason : "Deleted Successfully");
      setIsId("");
      setIsFiles(false);
      GetWorkFlow();
    } else {
      const Res = await DeleteWoflow(isId);
      alert(Res?.reason !== "" ? Res?.reason : "Deleted Successfully");
      setIsId("");
      setIsFiles(false);
      GetWorkFlow();
    }
  };
  const HandleWfSearch = (e) => {
    let searchItem = e.target.value.toUpperCase();
    let filterArr = WfFilterArr.filter((it) =>
      it.name.toUpperCase().includes(searchItem)
    );
    searchItem?.length > 0 ? setWfArr(filterArr) : setWfArr(WfFilterArr);
  };
  const [isWfTypeCatModal, setIsWfTypeCatModal] = useState(false);
  const [isWfTypeCatModalDel, setIsWfTypeCatModalDel] = useState(false);
  const [isWfTyCatId, setIsWfCatId] = useState(null);
  const [isWfTypeCatId, setIsWfTypeCatId] = useState(null);
  const [isWfTagId, setIsWfTagId] = useState(null);
  const [isWfTypeCat, setIsWfTypeCat] = useState("");
  const [inpWftypeCat, setInpWftypeCat] = useState("");
  const [inpWftypeCode, setInpWftypeCode] = useState("");
  const [inpWftypeDesc, setInpWftypeDesc] = useState("");
  const [isEqupId, setIsEqpId] = useState(0);
  const [WfEqpErr, setWfEqpErr] = useState(false);
  const HandleCategories = (value, itemId) => {
    if (value === "Category") {
      setIsEqpId(
        itemId?.workflow_category_id !== undefined
          ? itemId?.workflow_category_id
          : 0
      );
      setInpWftypeDesc(
        itemId?.category_description !== undefined
          ? itemId?.category_description
          : ""
      );
      setInpWftypeCode(
        itemId?.category_code !== undefined ? itemId?.category_code : ""
      );
      setInpWftypeCat(
        itemId?.category_code !== undefined ? itemId?.category_code : ""
      );
    }
    // if(isWfTypeCat === "Tag"){

    // }
    else {
      setIsEqpId(
        itemId?.equipment_type_id !== undefined ? itemId?.equipment_type_id : 0
      );
      setInpWftypeCat(
        itemId?.equipment_description !== undefined
          ? itemId?.equipment_description
          : ""
      );
    }
    setIsWfTypeCat(value);
    setIsWfTypeCatModal(true);
  };
  const IsCloseModal = () => {
    setIsWfTypeCat("");
    setInpWftypeCat("");
    setIsWfTypeCatModal(false);
    setWfEqpErr(false);
    setisFetch(false);
    setIsEqpId(0);
  };

  const HandleWfsInputs = (event) => {
    setInpWftypeCat(event.target.value);
  };
  const HandleWfsInputs2 = (event) => {
    setInpWftypeCode(event.target.value);
  };
  const HandleWfsInputs3 = (event) => {
    setInpWftypeDesc(event.target.value);
  };
  const [isFetch, setisFetch] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const HandelSaveWfs = async () => {
    setIsProgress(true);
    setisFetch(true);
    if (isWfTypeCat === "Category") {
      if (inpWftypeCode?.length > 0 && inpWftypeDesc?.length > 0) {
        let inpObj = {
          work_flow_category_id: isEqupId,
          // workflow_category_name: inpWftypeCat,
          work_flow_category_description: inpWftypeDesc,
          work_flow_category_code: inpWftypeCode,
        };
        if (
          // isWfCat?.filter((it) =>
          //   (it?.workflow_category_id !== isEqupId)?.filter(
          //     (i) => i?.category_code === inpWftypeCode
          //   )
          // )?.length > 0
          isWfCat
            ?.filter((it) => it?.workflow_category_id !== isEqupId)
            ?.filter((i) => i?.category_code === inpWftypeCode)?.length > 0
        ) {
          alert("Workflow Category already exist");
          setisFetch(false);
        } else {
          let Res = await WfCatAdd(inpObj);
          if (Res?.success) {
            setisFetch(false);
            setIsProgress(false);
            setIsWfTypeCatModal(false);
            setInpWftypeCat("");
            if (isEqupId === 0) {
              setIsWfCat([...isWfCat, Res?.data]);
            } else {
              setIsWfCat([
                ...isWfCat?.filter(
                  (it) => it?.workflow_category_id !== isEqupId
                ),
                Res?.data,
              ]);
              setIsEqpId(0);
            }
          }
        }
        setWfEqpErr(false);
      } else {
        setWfEqpErr(true);
        setisFetch(false);
      }
    } else {
      if (inpWftypeCat?.length > 0) {
        let inpObj = {
          equipment_type_id: isEqupId,
          equipment_code: "13",
          equipment_description: inpWftypeCat,
        };
        if (
          EqupTpes?.filter((it) => it?.equipment_description === inpWftypeCat)
            ?.length > 0
        ) {
          alert("Equipment Type already exist");
        } else {
          let Res = await EqpTypesAdd(inpObj);
          if (Res?.success) {
            setIsWfTypeCatModal(false);
            setInpWftypeCat("");
            if (isEqupId === 0) {
              setEqpTypes([...EqupTpes, Res?.data]);
            } else {
              setEqpTypes([
                ...EqupTpes?.filter((it) => it?.equipment_type_id !== isEqupId),
                Res?.data,
              ]);
              setIsEqpId(0);
            }
          }
        }
        //
        setWfEqpErr(false);
      } else {
        setWfEqpErr(true);
      }
    }
  };
  const contentRefs = useRef([]);
  const [isTglWfCat, setIsTglWfCat] = useState(false);
  const [isTglWfEqp, setIsTglWfEqp] = useState(false);
  const [isTglWf, setIsTglWf] = useState(false);
  const [isTglWfAuto, setIsTglWfAuto] = useState(false);
  const [isTglTagMan, setIsTglTagMan] = useState(false);
  const HandleToggle = (value) => {
    if (value === "Workflows Categories") {
      setIsTglWfCat(!isTglWfCat);
    }
    if (value === "Equipment Types") {
      setIsTglWfEqp(!isTglWfEqp);
    }
    if (value === "Workflows") {
      setIsTglWf(!isTglWf);
    }
    if (value === "Workflows automation") {
      setIsTglWfAuto(!isTglWfAuto);
    }
    if (value === "Tag manager") {
      setIsTglTagMan(!isTglTagMan);
    }
  };
  const HandleCat = (item, value, event) => {
    alert("hkjk");
    event?.stopPropagation();
    setIsWfTypeCatModalDel(true);
    setIsWfTypeCatId(item?.equipment_type_id);
    setIsWfCatId(item?.workflow_category_id);
    setIsWfTagId(item?.tag_id);
    setIsWfTypeCat(value);
  };
  const CloseDelModal = () => {
    setIsWfCatId(null);
    setIsWfTypeCatId(null);
    setIsWfTypeCatModalDel(false);
  };
  const HandleCatDel = async () => {
    if (isWfTypeCat === "Category") {
      let Res = await DelWfCat(isWfTyCatId);
      if (Res?.success) {
        setIsWfTypeCatModalDel(false);
        setIsWfCat(
          isWfCat?.filter((i) => i?.workflow_category_id !== isWfTyCatId)
        );
      }
    }
    if (isWfTypeCat === "Tag") {
      let Res = await deleteTags(isWfTagId);
      if (Res?.success) {
        setTagData(TagData?.filter((i) => i?.tag_id !== isWfTagId));
        setIsWfTypeCatModalDel(false);
      }
    } else {
      let Res = await DelEqupTypes(isWfTypeCatId);
      if (Res?.success) {
        setIsWfTypeCatModalDel(false);
        setEqpTypes(
          EqupTpes?.filter((i) => i?.equipment_type_id !== isWfTypeCatId)
        );
      }
    }
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };
  const teamsStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "gray",
        fontSize: "11px",
        fontFamily: "SofiaProBold",
      };
    },
    option: (base) => ({
      ...base,

      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
    control: (base) => ({
      ...base,
      "&:hover": {
        backgroundColor: "#d8d9db",
        cursor: "pointer",
        border: 0,
        // backgroundColor:var(--neutral-200, #d8d9db),
      },
      maxHeight: 500,
      minHeight: 32,
      boxShadow: "none",
      border: "1px solid gray",
      alignContent: "center",
    }),

    menu: (base) => ({
      ...base,
      width: "400px",
      backgroundColor: "white",
    }),
    singleValue: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      gap: "10px",
      fontSize: "10px",
      fontWeight: "bold",
    }),
  };
  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#cfcbca",
    },
  });
  const [isTagModal, setIsTagModal] = useState(false);
  const [wflwtag, setWflwTag] = useState({});
  const [tagItnpObjWo, setTagItnpObjWo] = useState({
    name: wflwtag?.tag_data,
    color: wflwtag?.color,
    referenceType: wflwtag?.reference_type,
    referenceId: wflwtag?.reference_id,
    uuid_id: wflwtag?.uuid_id,
    tag_id: wflwtag?.tag_id,
    work_order_id: wflwtag?.work_order_id,
  });

  const HandleAddModal = async (item) => {
    setTagItnpObjWo({
      ...tagItnpObjWo,
      name: item?.tag_data,
      color: item?.color,
      referenceType: item?.reference_type,
      referenceId: item?.reference_id,
      uuid_id: item?.uuid_id,
      tag_id: item?.tag_id,
      edit_allowed: 0,
      sponsor_id: 0,
    });
    setIsTagModal(true);
    setWflwTag(item);
  };

  const changeTagEventWo = (key, value) => {
    setTagItnpObjWo({ ...tagItnpObjWo, [key]: value });
  };
  const handleTagsubmit = async () => {
    let newObj = {
      name: tagItnpObjWo?.name,
      tag_id: tagItnpObjWo?.tag_id,
      color: tagItnpObjWo?.color,
      referenceType: tagItnpObjWo?.referenceType,
      referenceId: tagItnpObjWo?.referenceId,
      work_order_id: tagItnpObjWo?.work_order_id,
      uuid_id: tagItnpObjWo?.uuid_id,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newObj);
    if (Res?.success) {
      let newData = TagData?.filter(
        (i) => i?.tag_id !== Res?.data?.tag?.tag_id
      );
      setTagData([...newData, Res?.data?.tag]);
      setIsTagModal(false);
    }
  };
  const [tagitem, setTagItem] = useState({});
  const [currentColor, setCurrentColor] = useState("#ffffff");
  const [colorSelector, setColorSelector] = useState({});
  const [showPicker, setShowPicker] = useState(false);
  const handleSave = async () => {
    const selectedColor = colorSelector?.value || currentColor;
    let newobj = {
      name: tagitem?.tag_data,
      tag_id: tagitem?.tag_id,
      color: selectedColor,
      referenceType: tagitem?.reference_type,
      referenceId: tagitem?.reference_id,
      work_order_id: tagitem?.work_order_id,
      uuid_id: tagitem?.uuid_id,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newobj);
    if (Res?.success) {
      let newData = TagData?.filter(
        (i) => i?.tag_id !== Res?.data?.tag?.tag_id
      );
      setTagData([...newData, Res?.data?.tag]);
      setShowPicker(false);
    }
    setColorSelector({});
  };
  const HandleColPckr = (value, key, item) => {
    setShowPicker(true);
    setColorSelector({ value });
    setTagItem(item);
  };
  const handleColorChange = (color) => {
    setColorSelector((prev) => ({
      ...prev,
      value: color.hex,
    }));
  };
  const [isWfAutoOpen, setIsWfAutoOpen] = useState(false);
  const [isWfAutoType, setIsWfAutoType] = useState("");
  const HandleWfAuto = (type, item, event) => {
    event?.stopPropagation();
    setIsAutoType(
      typeof item?.auto_type === "string"
        ? item?.auto_type
        : JSON?.stringify(item?.auto_type)
    );
    HandleApiData(
      typeof item?.auto_type === "string"
        ? item?.auto_type
        : JSON?.stringify(item?.auto_type)
    );
    setIsAutoRef(item?.reference);
    setIsWfAutoWfId(item?.workflow_auto_id);
    setSelectedOptions(
      item?.workflow_list?.map((it) => {
        return {
          value: it?.workflow_id,
          label: it?.workflow_name,
        };
      })
    );
    setIsWfAutoOpen(true);
    setIsWfAutoType(type);
  };

  const [isCustData, setISCustData] = useState([]);
  const HandleApiData = async (type) => {
    if (type === "1") {
      let Res = await CustomerListGet();
      setISCustData(Res?.data[0]?.objects);
    } else if (type === "3") {
      let Res = await woGtFldsTypeSett();
      setISCustData(Res?.data?.workOrderType);
    } else if (type === "4") {
      let Res = await CallTypesFetch();
      setISCustData(Res?.data);
    } else {
      setISCustData([]);
    }
  };
  const [isAutoType, setIsAutoType] = useState("");
  const [isAutoRef, setIsAutoRef] = useState("");
  const [isWfAutoWfId, setIsWfAutoWfId] = useState(0);
  const HandleWfAutoInputs1 = (e) => {
    const typeVal = e.target.value;
    setIsAutoType(typeVal);
    HandleApiData(typeVal);
  };
  const HandleWfAutoInputs2 = (e) => {
    const typeRef = e.target.value;
    setIsAutoRef(typeRef);
  };
  const [isErr, setIsErr] = useState([]);
  const HandleAfAutoAddUpdate = async () => {
    let ErrArr = [];
    if (!selectedOptions || selectedOptions?.length === 0) {
      ErrArr.push("selectedOptions");
    }
    if (!isAutoType || isAutoType?.length === 0) {
      ErrArr.push("isAutoType");
    }
    if (!isAutoRef || isAutoRef?.length === 0) {
      ErrArr.push("isAutoRef");
    }

    if (
      isWfAutoType === "IN" &&
      workFlowAutoData?.filter(
        (i) => i?.reference === isAutoRef && i?.auto_type === +isAutoType
      )?.length > 0
    ) {
      alert(
        `${
          workFlow_Auto?.find((i) => i?.id === isAutoType)?.name
        } already Exist`
      );
    } else {
      if (ErrArr?.length <= 0) {
        let InpObj = {
          workflow_auto_id: isWfAutoType === "IN" ? 0 : isWfAutoWfId,
          work_order_id: "0",
          auto_type: isAutoType,
          reference: isAutoRef,
          sub_reference: "test",
          workflow_list: selectedOptions?.map((i) => {
            return {
              workflow_id: i?.value,
              workflow_name: i?.label,
            };
          }),
          type: isWfAutoType,
        };
        setIsProgress(true);
        let Res = await HandlePostWfAuto(InpObj);
        if (Res?.success) {
          setIsWfAutoOpen(false);
          setIsProgress(false);
          if (isWfAutoType === "IN") {
            setWorkflowAutoData([
              ...workFlowAutoData,
              Res?.data?.workflow?.data,
            ]);
          } else {
            setWorkflowAutoData([
              ...workFlowAutoData?.filter(
                (i) => i?.workflow_auto_id !== isWfAutoWfId
              ),
              Res?.data?.workflow?.data,
            ]);
          }
        }
      } else {
        setIsErr(ErrArr);
      }
    }
  };
  const HandleCloseAutoModal = () => {
    setIsWfAutoOpen(false);
    setIsErr([]);
  };
  const HandleWfAutoDel = async () => {
    let newObj = {
      workflow_auto_id: isWfAutoWfId,
      type: isWfAutoType,
    };
    let Res = await HandlePostWfAuto(newObj);
    if (Res?.success) {
      setIsWfAutoOpen(false);
      setWorkflowAutoData(
        workFlowAutoData?.filter((i) => i?.workflow_auto_id !== isWfAutoWfId)
      );
    }
  };

  const GetTeams = WfArr?.map((it) => {
    return {
      value: it?.workflow_id,
      label: it?.name,
    };
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const HandleSelctChange = (it) => {
    setSelectedOptions(it);
  };
  return (
    <>
      <div
        className="pagescroll"
        style={{ padding: "10px 10px 100px 10px", zIndex: "1" }}
      >
        <div ref={(ref) => (contentRefs.current[0] = ref)}>
          <WorkflowCategory
            HandleCategories={HandleCategories}
            data={isWfCat}
            isEqupId={isEqupId}
            isWfTyCatId={isWfTyCatId}
            HandleCat={HandleCat}
            HandleToggle={HandleToggle}
            isTglWfCat={isTglWfCat}
          />
        </div>

        <div className="mt-2" ref={(ref) => (contentRefs.current[2] = ref)}>
          <div className="head-stick">
            <div className="line-align-bet w-100 clp_header">
              <div className="mx-2">
                <img src={WfWrench} alt="WfWrench" className="mb-1" />
                <span
                  className="wftags-sub-header mx-1 cursor-p"
                  onClick={() => HandleToggle("Workflows")}
                >
                  Workflows
                </span>
              </div>
              <div
                className="wf-plus"
                onClick={() => HandleWorkFlow("CreateWorkflow")}
              >
                <div>
                  <img src={Add_Main} alt="Add-Main" width={18} />
                </div>
              </div>
            </div>
            {!isTglWf && (
              <>
                <div
                  className="clp_body"
                  style={{
                    paddingBottom: 0,
                    borderBottom: 0,
                  }}
                >
                  <div className="w-100 wf-set-search ">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Search workflows?"
                      onChange={(e) => HandleWfSearch(e)}
                    />
                    <BiSearch
                      style={{
                        position: "relative",
                        left: "10px",
                        bottom: "27px",
                      }}
                    />
                  </div>
                  <div className="sub-titles ">
                    <div className="w-100  d-flex">
                      <div className="sub-titles-names w-50 ">
                        {WORKFLOW_VALUES.WF_NAME}
                      </div>
                      
                      <div
                        className="sub-titles-names w-50 "
                        style={{
                          position: "relative",
                          left: "30px",
                        }}
                      >
                        {WORKFLOW_VALUES.WS_TEAMS}
                      </div>
                    </div>
                    <div className="sub-titles-names w-50 ">
                        {WORKFLOW_VALUES.NS_List}
                      </div>
                    <div
                      className="sub-titles-names "
                      style={{
                        position: "relative",
                        right: "1px",
                        width: "30%",
                      }}
                    >
                      Category:
                    </div>
                    <div className="d-flex">
                      <div
                        className="sub-titles-names"
                        style={{
                          whiteSpace: "nowrap",
                          right: "30px",
                          position: "relative",
                        }}
                      >
                        {WORKFLOW_VALUES.STEP_COUNT}
                      </div>
                      <div className="sub-titles-names mx-2">
                        {WORKFLOW_VALUES.DUPLICATE}
                      </div>
                      <div className="sub-titles-names  ">
                        {WORKFLOW_VALUES.DELETE}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!isTglWf && (
            <div className="clp_body" style={{ paddingTop: 0 }}>
              <div className="card mx-2 positin">
                {WfArr?.length > 0 ? (
                  WfArr.map((it) => (
                    <div
                      className={`${
                        location?.state && it.workflow_id === location?.state
                          ? `item-bgc line-align-bet  p-2 sub-titles-names cursor-p`
                          : `line-align-bet  p-2 sub-titles-names cursor-p`
                      }`}
                      style={{
                        borderBottom: "1px solid rgb(184, 181, 181)",
                        overflow: "auto",
                      }}
                      onClick={() => HandleWorkFlow("CreateWorkflow", it)}
                    >
                      <div className="w-100 ">
                        <span className="sub-items-text">{it.name}</span>
                      </div>
                      <div className=" w-100">
                        {it?.team_id_list.map((item) => (
                          <div className="sub-w-items">
                            <span className="sub-w-items-text">
                              {item?.name}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className=" w-100">
                        {it?.notification_email_list.map((item) => (
                          <div className="sub-w-items">
                            <span className="sub-w-items-text">
                              {item?.email}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="sub-items-text w-50">
                      {Workflow_Table_Category.find((item) => item?.id === it?.category)?.name || ""}
                      </div>
                      <div className="d-flex"
                      >
                        <div className="sub-items-text "
                         style={{width:"30px"}}>{it.step_count}</div>
                        <div>
                        <img
                          src={Duplicate}
                          alt="Duplicate"
                          style={{
                            margin: "0px 0px 0px 80px ",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            HandleFiles("file", it, "CreateWorkflow", e)
                          }
                          />
                        </div>
                        <div>
                          <img
                            src={del}
                            alt="delete "
                            style={{
                            margin: "0px 10px 0px 50px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => HandleFiles("del", it, e)}
                        />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="err-data-wfsetup">There is no data</div>
                )}
              </div>
            </div>
          )}

          {/* <CommonFooter /> */}
        </div>

        {/* //workflowAuto start */}
        <div className="mt-2" ref={(ref) => (contentRefs.current[3] = ref)}>
          <WorkFlowAtomation
            data={workFlowAutoData}
            HandleToggle={HandleToggle}
            isTglWfAuto={isTglWfAuto}
            HandleCat={HandleCat}
            HandleWfAuto={HandleWfAuto}
          />
        </div>
        {/* //workflowAuto end */}
        <div className="mt-2" ref={(ref) => (contentRefs.current[4] = ref)}>
          <Tagmanager
            TagData={TagData}
            HandleToggle={HandleToggle}
            isTglTagMan={isTglTagMan}
            HandleCat={HandleCat}
            name={tagItnpObjWo?.tag_data}
            HandleAddModal={HandleAddModal}
            handleSave={handleSave}
            HandleColPckr={HandleColPckr}
            handleColorChange={handleColorChange}
          />
        </div>
      </div>
      <WfCategoryTypeModal
        isWfTypeCatModal={isWfTypeCatModal}
        isWfTypeCat={isWfTypeCat}
        inpWftypeCode={inpWftypeCode}
        inpWftypeDesc={inpWftypeDesc}
        inpWftypeCat={inpWftypeCat}
        WfEqpErr={WfEqpErr}
        isEqupId={isEqupId}
        HandleWfsInputs={HandleWfsInputs}
        HandleWfsInputs2={HandleWfsInputs2}
        HandleWfsInputs3={HandleWfsInputs3}
        IsCloseModal={IsCloseModal}
        HandelSaveWfs={HandelSaveWfs}
        isFetch={isFetch}
        isProgress={isProgress}
      />
      <WfCategoryTypeDelete
        isWfTypeCatModalDel={isWfTypeCatModalDel}
        isWfTypeCatId={isWfTypeCatId}
        CloseDelModal={CloseDelModal}
        isWfTypeCat={isWfTypeCat}
        HandleCatDel={HandleCatDel}
        name={tagItnpObjWo.name}
      />
      <AddTag
        Open={isTagModal}
        name={tagItnpObjWo.name}
        color={tagItnpObjWo.color}
        isClose={() => setIsTagModal(false)}
        isSubmit={handleTagsubmit}
        HandleTagInputs={changeTagEventWo}
      />
      <Modal show={isFiles} onHide={() => setIsFiles(false)} centered size="md">
        <Modal.Header className=" modal-bg-color modal-header-height ">
          <span style={{ color: "white" }}>
            {" "}
            {isStatus === "file" ? "Workflow Duplicated" : "Delete Workflow"}
          </span>
          <img
            src={Close}
            alt="close"
            onClick={() => setIsFiles(false)}
            className="modal-header-icon "
          />
        </Modal.Header>
        <Modal.Body>
          <div
            className="sub-items-text"
            style={{ position: "relative", bottom: "10px" }}
          >
            {isStatus === "file"
              ? WORKFLOW_VALUES.STATUS_FLIE_DELETE1
              : WORKFLOW_VALUES.STATUS_FLIE_DELETE2}
          </div>
        </Modal.Body>
        <div
          className="mt-2 model_footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() => HandleDel()}
          >
            {isStatus === "file" ? "Undo" : "Delete"}
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            style={{ margin: 0 }}
            onClick={() => setIsFiles(false)}
          >
            {WORKFLOW_VALUES.CANCEL}
          </div>
        </div>
      </Modal>

      <Modal show={isWfAutoOpen} onHide={() => HandleCloseAutoModal()} centered>
        <Modal.Header className=" modal-bg-color modal-header-height ">
          <span style={{ color: "white" }}>
            {isWfAutoType === "D"
              ? "Delete Automation"
              : isWfAutoType === "IN"
              ? "Create Automation"
              : "Edit Automation"}
          </span>
          <img
            src={Close}
            alt="close"
            onClick={() => HandleCloseAutoModal()}
            className="modal-header-icon "
          />
        </Modal.Header>
        <Modal.Body>
          {isWfAutoType === "D" ? (
            "Are You Sure You want to delete"
          ) : (
            <div>
              {isProgress !== false ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "235px",
                  }}
                >
                  <ProgressBar />
                </div>
              ) : (
                <div>
                  <div className="txt-align-c">
                    <span>Automation Type:</span>
                    <select
                      className="w-100 wfs-type-input mt-1"
                      name="isAutoType"
                      value={isAutoType}
                      onChange={(e) => HandleWfAutoInputs1(e)}
                    >
                      <option selected> Crete automation</option>

                      {workFlow_Auto?.map((i) => (
                        <option value={i?.id}>{i?.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {isErr?.filter((i) => i === "isAutoType")?.length > 0 && (
                      <div className="err-Wf-empty">{`Please fill Automation Type`}</div>
                    )}
                  </div>

                  <div className="txt-align-c">
                    <span>Automation Reference:</span>
                    {/* isCustData */}

                    <select
                      className="w-100 wfs-type-input mt-1"
                      name="isAutoRef"
                      value={isAutoRef}
                      onChange={(e) => HandleWfAutoInputs2(e)}
                    >
                      <option selected>
                        Please select &nbsp;
                        {workFlow_Auto?.find((i) => i?.id === isAutoType)?.name}
                      </option>
                      {isCustData?.map((i) => (
                        <option
                          value={
                            i?.interface_number
                              ? i?.interface_number
                              : i?.call_type_code
                              ? i?.call_type_code
                              : i?.work_order_type_code
                          }
                        >
                          {i?.name
                            ? i?.name
                            : i?.call_type_code
                            ? i?.call_type_code
                            : i?.work_order_type_code}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {isErr?.filter((i) => i === "isAutoRef")?.length > 0 && (
                      <div className="err-Wf-empty">{`Please fill Automation Reference}`}</div>
                    )}
                  </div>
                  <div className="txt-align-c">
                    <span>Workflow List:</span>
                    <Select
                      className="w-100 mt-2"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      styles={teamsStyles}
                      theme={theme}
                      onChange={(options) => {
                        HandleSelctChange(options);
                      }}
                      value={selectedOptions}
                      options={GetTeams}
                      isMulti
                      placeholder={
                        " select whitch workflows you want the Automation on."
                      }
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </div>
                  <div>
                    {isErr?.filter((i) => i === "selectedOptions")?.length >
                      0 && (
                      <div className="err-Wf-empty">{`Please fill selectedOptions`}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <div
          className="mt-2 model_footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "1px",
          }}
        >
          <div
            className="modal-footer-save-cancel-btns"
            onClick={() =>
              isWfAutoType === "D" ? HandleWfAutoDel() : HandleAfAutoAddUpdate()
            }
          >
            {isWfAutoType === "D"
              ? "Delete"
              : isWfAutoType === "IN"
              ? "Save"
              : "Update"}
          </div>
          <div
            className="modal-footer-save-cancel-btns"
            style={{ margin: 0 }}
            onClick={() => HandleCloseAutoModal()}
          >
            {WORKFLOW_VALUES.CANCEL}
          </div>
        </div>
      </Modal>
      <Modal
        centered
        show={showPicker}
        onHide={() => setShowPicker(false)}
        size="sm"
      >
        <Modal.Header className="attach-media-note modal-bg-color modal-header-height px-2">
          <span> </span>
          <img
            className="modal-header-icon flt-r"
            onClick={() => {
              setShowPicker(false);
              setColorSelector({});
            }}
            alt="close"
            src={close}
          />
        </Modal.Header>
        <Modal.Body>
          <SketchPicker
            color={colorSelector.value}
            onChange={handleColorChange}
            className="ml-4"
          />
          <hr className="m-2" />
          <div>
            <div className="row line-align-even flx-wrp">
              <button
                className="col-5 modal-footer-save-cancel-btns"
                onClick={() => {
                  setShowPicker(false);
                  setColorSelector({});
                }}
              >
                <img src={plus} className="skw-45" alt="cancel" /> Cancel
              </button>
              <button
                className="col-5 modal-footer-save-cancel-btns"
                onClick={() => {
                  handleSave();
                }}
              >
                <img src={save} alt="save" width={12} /> Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WorkFlowSetUp;
