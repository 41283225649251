import React from "react";
import { Modal } from "react-bootstrap";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import "./DeleteWOModal.css";
import {
  DEL_W_O,
  TAGS_MODAL_VALUES,
} from "../../../common/components/Constants";
export default function DeleteWOModal(props) {
  return (
    <div>
      <Modal centered size="md" show={props.Open} onHide={props.Close}>
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <span className="modal-header-txt">{props?.name?props?.name:DEL_W_O.DEL_CUST} {props?.custId}</span>
          <img
            src={Close}
            className="modal-header-icon"
            alt="Close"
            onClick={() => {
              props.Close();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="del-mod-body">
            {props?.data}
            <div className="mt-2 modal-bottom-styles-edit-notes model_footer">
              <div
                className="modal-footer-save-cancel-btns"
                onClick={() => props?.HandleDeleteCustomer()}
              >
                {TAGS_MODAL_VALUES.DELETE}
              </div>
              <div
                className="modal-footer-save-cancel-btns"
                onClick={props.Close}
              >
                {TAGS_MODAL_VALUES.CANCEL}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
