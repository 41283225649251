import React from "react";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import WfCategory from "../../../../common/Assets/Icons/Vectorwf.svg";
import del from "../../../../common/Assets/Icons/del.svg";

export default function WorkflowCategory(props) {
  return (
    <div>
      <div className="line-align-bet w-100 clp_header">
        <div className="mx-2" style={{ fontFamily: "SofiaProBold" }}>
          <img src={WfCategory} alt="WfCategory" width={20} className="mb-1" />
          <span
            className="mx-1 wftags-sub-header cursor-p"
            onClick={() => props?.HandleToggle("Workflows Categories")}
          >
            Workflows Categories
          </span>
        </div>
        <div className="wf-plus">
          <div onClick={() => props?.HandleCategories("Category")}>
            <img src={Add_Main} alt="Add-Main" width={18} />
          </div>
        </div>
      </div>
      {!props?.isTglWfCat && (
        <div style={{ padding: "10px 10px 10px 10px" }} className="clp_body">
          <div className="card">
            {props?.data?.length > 0 ? (
              props?.data
                ?.sort(
                  (a, b) =>
                    new Date(b.modified_date) - new Date(a.modified_date)
                )
                ?.map((it) => (
                  <div
                    onClick={() => props?.HandleCategories("Category", it)}
                    className="line-align-bet w-100 p-2 cursor-p"
                    style={{
                      borderBottom: "1px solid  rgb(209, 202, 202)",
                      background:
                        (it?.workflow_category_id === props?.isEqupId &&
                          "#d5d8d8") ||
                        (it?.workflow_category_id === props?.isWfTyCatId &&
                          "#d5d8d8"),
                    }}
                  >
                    <span className="sub-w-items-text cursor-p">
                      {it?.category_description}
                    </span>
                    <img
                      src={del}
                      alt="del"
                      className="cursor-p"
                      onClick={(e) => props?.HandleCat(it, "Category", e)}
                    />
                  </div>
                ))
            ) : (
              <div className="align-rt w-100 p-2">
                <span className="err-data-wfsetup">There is no data </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
