export const TABLE_CUSTOMER_CUSTOMSTYLES = {
    header: {
      style: {
        minHeight:'56px',
        
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "white",
        borderBottomColor: "Black",
        minHeight:"30px",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "gray",
        },
        padding: "0",
      margin: "0",
      },
    },
    rows: {
      style: {
        minHeight: "40px",
        // "&:not(:last-of-type)": {
        //   borderBottomStyle: "none",
        //   borderBottomWidth: "0",
        //   borderBottomColor: "Black",
        // },
      },
    },
    cells: {
      style: {
        display: "flex",
        padding: "0",
        margin: "0",
        textAlign: "center",
        justifyContent: "center",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "gray",
        },
      },
    },
  };