import { NINEPACK_URL } from "./Url";
import axios from "axios";
import { APP_JSON, } from "../common/components/Constants";

export const authTokenAsynk = async (inpdata) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.post(
      `${NINEPACK_URL}/communication/chat/auth`,
      inpdata,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );

    return res;
  } catch (err) {}
};

export const getEmails = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.post(
      `${NINEPACK_URL}/technician/read/emails`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res;
  } catch (err) {}
};
