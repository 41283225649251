import React, { useEffect, useState } from "react";
import bsb from "../../../../common/Assets/Icons/bsb.svg";
import equilibpadd from "../../../../common/Assets/Icons/equiplibadd.svg"
import {
  GetAttchMentByID,
  LibraryData,
} from "../../../../services/WorkOrdersService";
import "./LibraryEquipments.css"
import AttachmentModal from "../../../workorders/AttachmentModal";
import { useLocation } from "react-router-dom";

function LibraryEquipments() {
  const location = useLocation()
  const eqpId = location?.state?.data

  useEffect(() => {
    HandleLibrary();
  }, []);
  const [Librarydata, setLibraryData] = useState([]);
  const HandleLibrary = async () => {
    let res = await LibraryData(localStorage.getItem("WoNo"));
    if (res?.success) {
      // setLibraryData(res?.data?.attachments);
    }
  };
  // const [isAttach, setIsAttach] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const HandleSpecificImg = async (value) => {
    // setIsAttach(true);
    setIsLoad(false);
    let attchId = value.id;
    let custId = value.customer_id;
    let WorkId = value.workorder_id;
    let eqId = value.equipment_id;
    let techId = value.technician_id;
    let step = value.wostepid;
    let workflow = value.woworkflowid;
    const response = await GetAttchMentByID(
      attchId,
      step,
      workflow,
      custId,
      WorkId,
      eqId,
      techId
    );
    if (response?.success) {
      setIsLoad(true);
      setAttachmentData(response.data);
    }
  };
  const [isToggle, setIsToggle] = useState(true);
  const HandleToggle = () => {
    setIsToggle(!isToggle);
  };
  // const [modalImage, setModalImage] = useState("");
const [carouselIndex, setCarouselIndex] = useState(0); 
const [carouselImages, setCarouselImages] = useState([]);
const [isZoomed, setIsZoomed] = useState(false);
const [zoomLevel, setZoomLevel] = useState(1);
const [isOpen2, setIsOpen2] = useState(false);
const [zoomMessage, setZoomMessage] = useState("");
  const handleModalShow = (imageSrc,filename,source) => {
    // setModalImage(imageSrc);
    setIsOpen2(true);
    setCarouselIndex(0);
    setIsZoomed(false);
    setZoomLevel(1);
    setZoomMessage("");
     setCarouselImages([
      { src: imageSrc, type: "image" },
  
    ]);
  
    setCarouselIndex(0);
     const images = [
      ...Librarydata?.map((image) => ({
        src: image.thumbnail.includes('base64') ? image.thumbnail : `data:${image.file_type};base64,${image.thumbnail}`,
        FileName : image.attachment_name,
        type: 'image',
      })),
    ];
    let attachments= []
    setCarouselImages(attachments[images]);
    setCarouselIndex(images.findIndex((img) => img.src === imageSrc));
    setIsZoomed(false);
    setZoomLevel(1);
  };
  useEffect(() => {
    setZoomLevel(1); 
    setIsZoomed(false);
      setZoomMessage("");
  }, [carouselIndex]); 
    const handleCloseModal = () => {
      setIsOpen2(false);
      setZoomLevel(1);
      setCarouselIndex(0);
  };
  return (
   
    <div>
      <div className="">
        <div className="clp_header">
          <img className=" cursor-p" onClick={() => HandleToggle()} src={bsb} alt="library" />
          <span onClick={() => HandleToggle()} className="sett-crd-hdr-txt cursor-p"> Library({eqpId ? <span>{Librarydata.length}</span>:0}
          )</span>

          <div className="myDiv1"
          style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}>
            <span>
              {/* <img className=" cursor-p equiplus"  src={equilibpadd} alt="library" /> */}
            </span>
          </div>
        </div>
        <div>
        </div>
          </div>
          <span className="flt-r cursor-p">
            {/* {isToggle === "on" ? (
              <img alt="down" src={down} onClick={() => HandleToggle("off")} />
            ) : (
              <img
                alt="vector"
                src={vector}
                height={9}
                onClick={() => HandleToggle("on")}
              />
            )} */}
          </span>
          
        {/* {isToggle === "on" ? (
          ""
        ) : (
          
          isToggle && (
            <div className="d-flex flex-wrap">
              {Librarydata?.map((item) => (
                <div className="mx-2">
                  <img
                    style={{ cursor: "pointer" }}
                    src={`data:${item?.file_type};base64,${item?.thumbnail}`}
                    alt="thumbnail"
                    width={100}
                    height={100}
                    onClick={() => HandleSpecificImg(item)}
                  />
                </div>
              ))}
              
            </div>
          )
          
        )} */}
    {isToggle &&  (
        <>
          <div className="d-flex flex-wrap clp_body">
           
            { eqpId && Librarydata.length > 0 ? (
              Librarydata.map((item) => (
                 <div className="">
                  <img
                  className="img-sty"
                    style={{ cursor: "pointer" }}
                    src={
                      item.thumbnail.includes("base64")
                        ? item.thumbnail
                        : `data:${item.file_type};base64,${item.thumbnail}`
                    }
                    alt="thumbnail"
                  
                    onClick={() =>
                      handleModalShow(
                        item.thumbnail.includes("base64")
                          ? item.thumbnail
                          : `data:${item.file_type};base64,${item.thumbnail}`
                      )
                    }
                  />
                </div>  
              ))
            ) : (
              <div className="ml-2">
                Previous Work Orders will show up here once they have been tied up.
              </div>
            )}
          </div>
        </>
      )}
      <AttachmentModal
        showModal={isOpen2}
        handleCloseModal={()=>handleCloseModal()}
        isloading={isLoad}
        selectedImageIndex={carouselIndex}
        handleCarouselSelect={(index) => setCarouselIndex(index)}
        isloadingClose={() => setIsLoad(false)}
        // data={attachmentData}
        eachWorkOrder={Librarydata}
        WorkId={localStorage.getItem("WoNo")}
      />
    </div>
    
  );
}

export default LibraryEquipments;
