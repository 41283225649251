import React from "react";
import { CiSearch } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import search_main from "../../common/Assets/Icons/search_main.svg"
import moment from "moment";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
export default function WorkOrderSideBar(props) {
  const navigate = useNavigate();
  return (
    <div className=" sidebody woleftpanel_body">
      <div className="wo-sidebar-lft">
      {(props?.newData?.length > 0 || ["isTags", "isTechs", "isWoName","isCity", "isCustName"]?.some(key => props?.SearchInputs?.[key]) || props?.startDate || props?.endDate ) && (
        <div className="line-align-bet mx-2 newdata" style={{ width: "80%" }}>
          <span className="side-filter-text">Selected Filter(s)</span>
          <span
            className="side-filter-text cursor-p"
            onClick={() => props?.HandleClearInputs()}
          >
            Clear All
          </span>
        </div>
      )}
        <div  className="d-flex gap-1 p-2 flex-wrap searhgap">
          {props?.newData?.map((i) => (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{i?.status}</span>
              <span
                className="closeicon"
                onClick={() => props.HandleClearText(i, "status")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          ))}
          {props?.SearchInputs?.isWoName?.length > 0 && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{props?.SearchInputs?.isWoName}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("isWoName")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
          {props?.SearchInputs?.isCustName?.length > 0 && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{props?.SearchInputs?.isCustName}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("isCustName")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
          {props?.SearchInputs?.isCity?.length > 0 && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{props?.SearchInputs?.isCity}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("isCity")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
          {props?.SearchInputs?.isTags?.length > 0 && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{props?.SearchInputs?.isTags}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("isTags")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
           {props?.SearchInputs?.isTechs?.length > 0 && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{props?.SearchInputs?.isTechs}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("isTechs")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
          {props?.startDate !== null && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{moment(props?.startDate).format("DD-MM-YYYY")}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("date")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
          {props?.endDate !== null && (
            <div className="leftside-search-item gap-1 d-flex">
              <span>{moment(props?.endDate).format("DD-MM-YYYY")}</span>
              <span
                className="closeicon"
                onClick={() => props?.HandleClearText("date")}
              >
                <MdOutlineClose size={15} />
              </span>
            </div>
          )}
        </div>
      </div>
      {props?.woStatus2?.length !== 0 &&
      <div className="pl-10 pr-10">
      <div  className="status-backround wo_leftpanel txt-align-c p-2">
        <span className="Work-Order-Status-text">Work Order Status</span>
        {props?.woStatus2?.map((item) => (
          <span key={item.status} onClick={() => props?.HandleBtnStatus(item)}>
            {item?.status}
          </span>
        ))}
      </div>
      </div>
      
    }
      <div className="p-2">
        <div>
          <span className="side-filter-text">Work Order Name/Number</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search"
              type="text"
              placeholder="Name"
              onChange={(e) => props?.HandleInputs(e, "Name")}
              onBlur={props?.HandleBlur}
              name="isWoName"
              value={props?.SearchInputs?.isWoName}
            />
          </div>
        </div>
        <div>
          <span className="side-filter-text">Customer</span>
          <div>
            
            <CiSearch  className="inp-inside-icon" />
            {/* <img src={search_main} alt="" /> */}
            <input
              className="w-100 sidebar-search"
              type="text"
              placeholder="Customer Name"
              onChange={(e) => props?.HandleInputs(e, "Customer")}
              onBlur={props?.HandleBlur}
              name="isCustName"
              value={props?.SearchInputs?.isCustName}
            />
          </div>
        </div>
        <div>
          <span className="side-filter-text">City</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search"
              type="text"
              placeholder="City"
              onChange={(e) => props?.HandleInputs(e, "City")}
              onBlur={props?.HandleBlur}
              name="isCity"
              value={props?.SearchInputs?.isCity}
            />
          </div>
        </div>
        <div>
          <span className="side-filter-text">Tags</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search"
              type="text"
              placeholder="Tags"
              onChange={(e) => props?.HandleInputs(e, "Tags")}
              onBlur={props?.HandleBlur}
              name="isTags"
              value={props?.SearchInputs?.isTags}
            />
          </div>
        </div>
        <div>
          <span className="side-filter-text">Technicians</span>
          <div >
            <CiSearch  className="inp-inside-icon" />
             <input
              className="w-100 sidebar-search"
              type="text"
              placeholder="Technicians"
              onChange={(e) => props?.HandleInputs(e, "Technicians")}
              onBlur={props?.HandleBlur}
              name="isTechs"
              value={props?.SearchInputs?.isTechs}
            />
          </div>
        </div>
        <span className="side-filter-text" >Selecte Date range</span>
        <div className="datepic-sty mt-1">
          <ReactDatePicker
            className="rdcal"
            selected={props?.startDate}
            onChange={props?.onChange}
            selectsStart
            startDate={props?.startDate}
            endDate={props?.endDate}
            selectsRange
            inline
            calendarClassName="custom-calendar-width"
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
      </div>
    </div>
  );
}
