import React from "react";
// import { CustAddfieldsGet } from "../../services/SettingsServices";
import "./CustExtraFields.css";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
export default function CustExtraFields(props) {
  return (
    (
      <div className="wrapData mx-2  ">
        {props?.addFields?.map((data, index) => (
          <div key={index} className="pt-1">
            {data?.packcolumn !== "" && (
              //  WORK_ORDER_CONSTANTS.EXTRA_FILED

              <div className="d-flex ml-4 center-line-adj  ">
                <div className="d-flex ">
                  {data?.required === true ? (
                    <span className="star-styles ">*</span>
                  ) : null}

                  <span className="lbl-xtr-fld ">{data?.packcolumn}:</span>

                  {data?.control_type === "date" ? (
                    <ReactDatePicker
                    className={
                      props.isCustVal === "edit" && props.isCustVal !== "create"
                          ? "custom-edit-datepicker ef-inpt-hover cursor-p" 
                          :
                         props?.error?.length > 0 &&
                         data?.value?.length<= 0
                        ? "custom-wo-datepicker2 cursor-p ef-inpt-hover"
                        : "custom-wo-datepicker cursor-p ef-inpt-hover"
                    }
                    // className={
                    //   data?.value?.length <= 0 &&
                    //   props?.ErrrAdd?.length > 0 &&
                    //   props?.ErrrAdd?.filter(
                    //     (i) => i?.field_id === data?.field_id
                    //   )[0]?.field_id === data?.field_id
                    //   ? "custom-datepicker2 cursor-p"
                    //   : "custom-datepicker cursor-p"
                    // } 
                      // placeholder={data?.control_type}
                      placeholderText="Select Date"
                      selected={
                        data?.value &&
                        moment(data.value, "DD-MM-YYYY", true).isValid()
                          ? moment(data.value, "DD-MM-YYYY").toDate()
                          : null
                      }
                      onChange={(e) => {
                        props.UpdateList(
                          "value",
                          e,
                          data?.field_id,
                          data?.control_type,
                          e?.target?.value,
                          props?.addFields,
                          // props?.isView
                        );
                      }}
                      onFocus={(e) => (e.target.readOnly = true)}
                      onKeyDown={(e) => e.preventDefault()}
                      style={{
                        border:
                          props?.isCustVal === "create" ||
                          props?.isCustVal === "AddEdit"
                            ? "1px solid rgba(148, 142, 142, 0.242);"
                            : "none",
                        borderRadius: "4px",
                        padding: "5px",
                      }}
                      disabled={props?.isCustVal === "edit"}
                    />
                  ) : (
                    <div>
                      <input
                        style={{
                          border: props?.isCustVal === "edit" && "none",
                          // Red border for required fields
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        className={
                               props?.error?.length > 0 &&
                                data?.value?.length<= 0 
                                  ? "error-inpt-xtr-fld pace-hold ef-inpt-hover" 
                                  : "inpt-xtr-fld pace-hold ef-inpt-hover"
                        }
                        // className={
                        //   data?.value?.length <= 0 &&
                        //   props?.ErrorData?.length > 0 &&
                        //   props?.ErrorData?.filter(
                        //     (i) => i?.field_id === data?.field_id
                        //   )[0]?.field_id === data?.field_id
                        //     ? "error-inpt-xtr-fld pace-hold ef-inpt-hover"
                        //     : "inpt-xtr-fld pace-hold ef-inpt-hover"
                        // }
                        value={data?.value}
                        type={data.control_type}
                        placeholder={
                          data?.control_type === "numeric"
                            ? "Enter Numeric Value"
                            : data?.control_type === "integer"
                            ? "Enter Numbers"
                            : "Enter Text"
                        }
                        onChange={(e) =>
                          props.UpdateList(
                            "value",
                            e,
                            data?.field_id,
                            data?.control_type,
                            e?.target?.value,
                            props?.addFields,
                            // props?.isCustVal
                          )
                        }
                        readOnly={props?.isCustVal === "edit"}
                        maxLength={data?.characterlength}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    )
  );
}
