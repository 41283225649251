export const LOGIN_PG_TEXTS = {
  WLCM: "Welcome",
  SIGN_TXT: "Sign in to continue!",
  LGIN_MSG: "User is successfully logged in",
};

export const EQP_FLDS_ADD = [
  {
    name: "Additional Fields 1",
    Val: "1",
  },
  {
    name: "Additional Fields 2",
    Val: "2",
  },
  {
    name: "Additional Fields 3",
    Val: "3",
  },
  {
    name: "Additional Fields 4",
    Val: "4",
  },
  {
    name: "Additional Fields 5",
    Val: "5",
  },
  {
    name: "Additional Fields 6",
    Val: "6",
  },
  {
    name: "Additional Fields 7",
    Val: "7",
  },
  {
    name: "Additional Fields 8",
    Val: "8",
  },
  {
    name: "Additional Fields 9",
    Val: "9",
  },
  {
    name: "Additional Fields 10",
    Val: "10",
  },
  {
    name: "Additional Fields 11",
    Val: "11",
  },
];

export const FIELD_SETUP_STATUS = {
  CRT_N_FSS: "Create work order status",
  EDT_STS: "Edit Status",
  EDT_TYP: "Edit Work Order Type",
  CRT_FSS: "Create Work Status",
  CRT_N_FST: "Create  Work Order  Type",
  CRT_FST: "Create Work Type",
};

export const TABLE_ÍCON_SIZES = {
  SORT_ICON_SIZE: "17",
  NOTES_PENP_SIZE: "14",
  NOTES_PEN_SIZE: "13",
  NOTES_ADD_SIZE: "16",
  STAR_ICON_SIZE: "20",
  NOTES_DATE_FORMAT: "DD/MM/yyyy-hh:mm A",
};
export const TABLE_ITEMS_COLORS = {
  TABLE_STAR_COLOR: "yellow",
};
export const LOCATION_CONSTANTS_STYLES = {
  WIDTH: "100%",
  HEIGHT: "300",
};
export const ATTACH_MODAL_STYLES = {
  WIDTH: "460px",
  HEIGHT: "380px",
};
export const SHARE_MODAL_VAL = {
  secretKey: "1234",
};
export const WORKFLOW_VALUES = {
  WORK_FLOWS: "Workflows:",
  CREATE_WORKFLOW: "Create Workflow",
  DISCARD: "Discard",
  SAVE: "Save",
  CANCEL: "Cancel",
  WF_NAME: "Workflow Name:",
  WF_TEAMS: "Workflow Teams:",
  WF_TEAMS_DISC: "What Teams can use this Workflow ?",
  WF_NOTIFY: "Completion Notifications:",
  WF_NOTIFY_DISC: "who Would you like to notify?",
  WF_CATEGORY: "Workflow Category:",
  WF_CATEGORY_DISC: "What Category is this workflow?",
  STEP_RULES: "Step Rules:",
  ADD_STEP: "Add Step",
  ADD_STEP_TEMPLATE: "Add Step from Template",
  CREATE_STEP: "Create Step",
  STEP_DISC: "What would you like this step to do?",
  STEP_SELECT_ACTION: "Select Step Action",
  STEP_NAME: "Step Name:",
  STEP_SELECT_ICON: "Select Step Icon",
  NOTIFY_CONTENT: "Notification Content:",
  STEP_INSTRUCTION: "Step Instructions:",
  ADD_CONTENT: "Add Content",
  TAG_DEL_DESC:"Once you remove this it is gone forever this type. All items that are correctly using this will no longer have ",
  STATUS_FLIE_DELETE1:
    "workFlow has been duplicated and will appear at the bottom of the     workflow table",
  STATUS_FLIE_DELETE2:
    "In order to Delete this workflow all current and past work orders will keep this workflow until it has been removed or updated manually. This will keep work orders from being corrupted or losing any data. Are you sure you would like to proceed?",
  WS_TEAMS: "Teams:",
  NS_List:"Notification Email(s):",
  STEP_COUNT: "Step Count",
  DUPLICATE: "Duplicate",
  EDIT: "Edit",
  DELETE: "Delete",
};
export const INTEGRATIONS = {
  NAME: "9 Pack",
  ERP: "ERP Field Name",
  ACTIVE: "Active",
};
export const SAM_DATA_WRK_ODR_FLD_SETT = [
  { name: "Micheal" },
  { name: "A/C Repair" },
  { name: "Roof Flooring" },
  { name: "Leakage Repair " },
  { name: "Water Distallation" },
  { name: "Tank Installation" },
  { name: "Rain Water Harvesting" },
  { name: "Fencing" },
  { name: "Wire Barbing" },
  { name: "Lawn Cleaner Repair" },
  { name: "Water Plumbing" },
];

export const WO_FS_DROPDWN = [
  { type: "Sponsor" },
  { type: "Work Order Number" },
  { type: "Work Order Name" },
  { type: "Status" },
  { type: "Note" },
  { type: "Created Date" },
  { type: "Work Order Date" },
  { type: "Telephone" },
];
export const Control_types = [
  { type: "String" },
  { type: "Boolean" },
  { type: "Date" },
  { type: "Numeric" },
  { type: "Integer" },
];
export const NEW_CUSTOMER = {
  CUSTOMER_DETAILS: "Info",
  LOGS: "Logs:",
  CUST_NAME: "Customer Name:",
  CUST_ADDRESS: "Address:",
  CUST_ADDRESS2: "Address2:",
  CUST_PORT_EMAIL: "CUSTOMER PORTAL EMAIL:",
  CUST_PH_NUM: "CUSTOMER PHONE NUMBER:",
  CUST_CITY: "City",
  CUST_STATE: "State",
  CUST_COUNTRY: "Country:",
  CUST_ZIP: "Zip",
  CUST_EX_CO_ORDINATES: "Exact Coordinates:",
  CUST_ID: "Customer ID:",
  CUST_TELEPHONE: "Phone Number:",
  CUST_TELEPHONE2: "Telephone 2:",
  CUST_EMAIL: "Email:",
  SPON_ID: "SPONSER ID:",
  OWN_EMAIL: "OWNER EMAIL:",
  EMAIL_CUS_PORT: "Send Email For Customer Portal:",
  ADD_CUS_EQ: "Add Customer Equipment:",
  ADD_CUS_BR: "Add Customer Branch:",
  ADD_CUS_CNTCT: "Add Customer Contact:",
  NOTE: "Note:",
  ADD_FIELDS: "Additional Fields",
  INPUT_CUST_NAME: "Input Customer Name",
  CONTACTS: "Contacts",
  COMMUNICATIONS: "Communications",
  W_O: "Work Orders",
  EQUIPMENT: "Equipments",
  DISCARD: "Discard",
  SAVE: "Save",
  LIBRARY: "Library",
  EDIT: "Edit",
  W_O_TOGGLE_TEXT:
    "Previous Workorders will show up here once they have been tied up.",
  LIBRARY_DESCPN: "Content will show up here once added",
};
export const CUSTOMER = {
  CUSTOPEN_W_O: "Open Work Orders",
  CUST_CMPLT_W_O: "Complete Work Orders ",
  CUST_EQPMNTS: "Equipments",
  SORT_BY: "Sort By:",
  CUSTOMER: "Customer",
  WO_ORDER:"Work Order",
  DATE_CREATED: "Date Created",
  PRMRY_CONTACT: "Primary Contact",
  TOT_W_O: "Total Work Orders",
  CREATE_NEW_CUST: "Create New Customer",
  SEL_FILTER: "Selected Filter(s)",
  CLEAR_ALL: "Clear All",
  NAME: "Name:",
  CITY: "City:",
  TAGS: "Tags:",
  CONTACT: "Contact:",
  INFO: "Info",
  NOTES: "Notes",
  COMMUNICATIONS: "Communications",
  W_ORDERS: "Work Orders",
  EQPMNT: "Equipment",
  LIBRARY: "Library",
  LOGS: "Logs",
};
export const LOGS = {
  FROM_DATE: "From Date",
  TO_DATE: "To Date",
  GEN_ACTION: "General Action",
  AUTHOR: "Author",
  EXP_TO_XL: "Export To Exel",
  LOG_NO: "Log Number",
  DATE: "Date",
  ACTION: "Action",
  DETAILS: "Details",
};
export const WORK_ORDER_CONSTANTS = {
  SORT_BY: "Sort By",
  WORK_ORDER_NUMBER: "WorkOrder Number",
  DATE_ASSIGNED: "Date Assigned",
  CUSTOMER: "Customer",
  TECH_ASSIGNED: "Tech Assigned",
  RECENTLY_VIWD: "Recently Viewed",
  CREATE_WORK_ORDER: "Create Work Order",
  WORK_ORDER: "Work Order #",
  WORK_FLOWS: "Workflow",
  EQUIPMENTS: "Equipment",
  LIBRARY: "Library",
  WORK_DETAILS_BTN: "Adjust Work Order status",
  DETAILS: "Details",
  WORK_FLOW2: "Workflow",
  NOTES: "Notes",
  EXTRA_FILED: "Extra Fields",
  COMMUNICATION: "Communications",
  LOGS: "Logs",
  MODEL_NO: "Model No",
  SERIAL_NO: "Serial No",
  MANUFACTURER: "Manufacturer",
  ACTIVE_INACTIVE: "Active/Inactive",
  EDIT: "Edit",
  ACTIVE: "Active",
  INACTIVE: "InActive",
  COMPLETE_WORK_ORDER: "complete work order",
  TAG_NAME: "Tag 1234",
  DOG_PRESENT: "Dog Present",
  GOLD_MEMBER: "Gold Member",
  ADD_TAG: "Add Tag",
  WORK_ORDER_NAME: "Work order Name",
  WORK_ORDER_TYPE: "Work Order Type:",
  HVAC_REPAIR: "HVAC repair",
  WORK_ORDER_ADDRESS: "Work order address",
  ASSIGN_WORK_ORDER: "Assign work order:",
  WORK_ORDER_DATE: "Work Order date:",
  DISCRIPTION: "Description",
  WORK: "Work",
  ADDRESS: "Address:",
  TAG: "Tag",
  NO_WORK_ORDERS: "There are no WorkFlows",
  WORK_FLOW_ADD: "Add WorkFLow",
  PLEASE_SELECT_WORKFLOW_SEE: "Please select which workflow you would like use",
  DELETE_WORKFLOW: "Delete WorkFlow",
};
export const LOGS_DATA = [
  {
    LOG_NO: "0085",
    DATE: "10 july 2022 07:40:15 AM",
    ACTION: "Equipment Steps",
    AUTHOR: "Micheal Chan",
    DETAILS: "Completed The Step",
  },
  {
    LOG_NO: "0084",
    DATE: "09 july 2022 07:40:15 AM",
    ACTION: "Phone Call",
    AUTHOR: "Micheal Chan",
    DETAILS: "Called Customer",
  },
  {
    LOG_NO: "0083",
    DATE: "08 july 2022 07:40:15 AM",
    ACTION: "Call",
    AUTHOR: "Micheal Chan",
    DETAILS: "Messaged Customer",
  },
  {
    LOG_NO: "0082",
    DATE: "07 july 2022 07:40:15 AM",
    ACTION: "Email",
    AUTHOR: "Micheal Chan",
    DETAILS: "Emailed Customer",
  },
  {
    LOG_NO: "0081",
    DATE: "06 july 2022 07:40:15 AM",
    ACTION: "File Upload",
    AUTHOR: "Micheal Chan",
    DETAILS: "Attached to a PDF file to general Steps",
  },
  {
    LOG_NO: "0080",
    DATE: "05 july 2022 07:40:15 AM",
    ACTION: "Document Upload",
    AUTHOR: "Micheal Chan",
    DETAILS: "Uploaded a document to Equipment Steps",
  },
  {
    LOG_NO: "0079",
    DATE: "04 july 2022 07:40:15 AM",
    ACTION: "Photo Upload",
    AUTHOR: "Micheal Chan",
    DETAILS: "Completed The Step",
  },
  {
    LOG_NO: "0078",
    DATE: "03 july 2022 07:40:15 AM",
    ACTION: "Document Upload",
    AUTHOR: "Micheal Chan",
    DETAILS: "Completed The Step",
  },
];
export const WORKORDER_CONSTANTS = {
  DETAILS: "Details",
  COMMUNICATION: "Communications",
  EQUIPMENTS: "Equipment",
  LOGS: "Logs",
  LIBRARY: "Library",
};
export const CUSTOMER_TABLE = {
  DATE: "Date",
  CUSTOMER: "Customer Name",
  CUST_NUMBER: "Customer Number",
  PRIM_CONTACT: "Primary Contact",
  PH_NUMBER: "Phone Number",
  PR_BRANCH: "Primary Branch",
  CITY: "Address",
  ZIP: "Zip",
  LAST_SERV: "Last Serviced",
  TOT_REVENUE: "Total Revenue",
  TOT_WO: "Total Work Orders",
};
export const DISCORD_MODAL = {
  DISCORD_BODY:
    "Are you sure you would like to discord all changes mode on this page?All current changes will be lost.",
};

export const ADDITIONAL_FIELDS = {
  ADD_TEXT:
    "Additional Fields That will be displayed if the customer has entered these into their system as possible dields",
  EXTRA_FILED: "Extra Field",
};

export const MOD_TEXT_FIELD_SETUP = {
  WRK_ODR_ADD_FLDS: "Work Order Additional Fields",
  CONT_ADD_FLDS: "Contact Additional Fields",
  CUST_ADD_FLDS: "Customer Additional Fields",
  EQUP_ADD_FLDS: "Equipment Additional Fields",
  ODR_ID: "Order Id",
  FLD_ID: "Field Id",
  ETR: "Please Enter Below Missing Fields",
  ERR_MSG: "Error Message",
  TXT_DESC: "You cannot add field, without filling these Details",
};
