export const STATUS_TODO = "To Do";
export const STATUS_INPROGRESS = "In Progress";
export const STATUS_COMPLETED = "Completed";
export const STATUS_HOLD = "Hold";
export const STATUS_OFFI = "OFFI";
export const STATUS_Lnch = "Lnch";
export const STATUS_VEN = "*VEN";
export const STATUS_SO = "*SO";
export const STATUS_NSO = "*NSO";
export const STATUS_HOM = "*HOM";
export const STATUS_ARRV = "ARRV";
export const STATUS_COMPL = "COMPL";
export const STATUS_CONT = "CONT";
export const STATUS_HOME = "HOME";
export const STATUS_LUN = "LUN";
export const STATUS_MTG = "MTG";
export const STATUS_OFF = "OFF";
export const STATUS_RTRN = "RTRN";
export const STATUS_SHOP = "SHOP";
export const TABLE_WORKORDER = "Work Orders";
export const TABLE_BUTTON = "Create Work Order";
export const CUSTOMER_CALL = "Call";
export const CUSTOMER_MESSAGE = "Message";
export const CUSTOMER_DIRECTION = "See Customer";
export const SUCCESS_STATUS = 200;
export const APP_JSON = "application/json";
export const LOGIN_CONST = {
  ERR_MSG: "Please, enter valid credentials.",
  INV_CRED: "Invalid login credentials.",
  OS: "Android",
  APP_TYPE: "visual tech web",
  SIGN_IN: "Login",
  REMIMBER: "Remember Me",
  RESET_PWD: "1.Reset Password",
  RESET_PWD3: "2.Reset Password",
  ALERT_MSG : "Reset link sent to your Email",
  ALERT_HEAD : "Email Instructions",
  RESET_PWD2: "Reset Password?",
  RESET_PWD1: "Enter new password below.",
  FORGOT: "Forgot Password?",
  ERROR0: "Please enter all fields",
  ERROR: "Please enter a valid email address",
  ERROR1: "Please enter valid email id",
  ERROR2: "Please enter valid username",
  ALERT: "Email not found in database. Please enter a registered email.",
  POP: "Email Instructions Sent",
  RST_PSSWD: "Reset Password",
  RST_PWD_SCSS: "Password Reset Successfully",
  TEXT_FIT:
    "Check your junk or spam for the email. The verification was sent to",
    SHR_TEXT_FIT:
    "Check your junk or spam for the email. The invitation was sent succesfully",
  RESET: "Please fill out both password fields",
  RESET_ALL: "Please fill the fields",
  RESET1: "Passwords do not match",
  RST_USER: "Please Enter Registered User",
  RST_MAIL: "Please Enter Registered Email",
  RE: "Password Reset",
  RE1: "Failed to reset password",
  RE2: "Error resetting password:",
  NEW_PWD: "Password reset successfully!",
};
export const TABLE_COLUMNS_NAME = {
  TABLE_WORK_ORDER: "Work Order",
  TABLE_WO: "Work Order Number",
  TABLE_WONAME: "Work Order Name",
  TABLE_DATE: "Date",
  TABLE_STATUS: "Status",
  TABLE_CUSTOMER: "Customer",
  TABLE_PRIMARY_CONTACT: "Primary Contact",
  TABLE_PHONE_NUMBER: "Phone Number",
  TABLE_PRIMARY_BRANCH: "Primary Branch",
  TABLE_LEAD_TECH: "Lead Tech",
  TABLE_ADDIONAL_TECHS: "Additional Techs ",
  TABLE_ADDERSS: "Address",
  TABLE_CITY: "City",
  TABLE_STATE: "State",
  TABLE_TAGS: "Tags",
};

export const COMPANY_DETAILS_SETTINGS = {
  THEME_PREFERENCE: "Theme Preferences",
  COMPANY_DETAILS: "Company Details",
  COMPANY_PREFERENCES: "Company Preferences",
  MASTER_FORM_CATALOG: "Master Form Catalog",
  INTEGRATIONS: "Integrations",
  LOGO: "Logo",
  DEFAULT: "Default",
  DETAILS: "Details",
  DIVISION: "Division",
  DARK_MODE: "Dark Mode",
  BANNER_COLOR: "Banner Color:",
  PRIMARY_COLOR: "Primary Color:",
  SECONDARY_COLOR: "Secondary Color:",
  TERTIARY_COLOR: "Tertiary Color:",
  ADD_FILE: "Add File",
  ADD_FLD: "Add Field",
  AC_UNIT: "Ac Unit Refill",
  HVAC_SETUP: "HVAC Setup",
  INSTALL_SETUP: "Installation Setup",
  EQP_SETUP_MAN: "Equipment Setup Manual",
  GENRAL_REPAIR_MAN: "Genral Repair Manual",
  CUSTOMER_HISTORY_AMOUNT: "Customer History Amount",
  PREVIOUS_WOHA: "Previous Work Order History Amount",
  CUSTOMER_CHA: "Customer Communication History Amount",
  COMPANYWISE_MC: "Company Wide use of the Media Center",
  COMPANYWISE_C: "Company Wide use of the Communication",
  COMPANYWISE_GV: "Company Wide use of the Grid View for Work Orders",
  MOBILE_SETTINGS: "Mobile Settings",
  MOBILE_SETTINGS_DES: "Number of Work Orders displayed   ",
  MOBILE_SETTINGS_DES2: "Total Amount of Time (Months):   ",
};
export const logData = [
  {
    logNo: "0087",
    date: "10 Jul 2022 07:40:15 AM",
    action: "Equipment steps",
    author: "Michael Chan",
    details: "Completed the Step",
  },
  {
    logNo: "0086",
    date: "09 Jul 2022 07:40:15 AM",
    action: "Phone Call",
    author: "Michael Chan",
    details: "Called Customer",
  },
  {
    logNo: "0085",
    date: "08 Jul 2022 07:40:15 AM",
    action: "Chat",
    author: "Michael Chan",
    details: "Messaged Customer",
  },
  {
    logNo: "0084",
    date: "08 Jul 2022 07:20:21 AM",
    action: "Email",
    author: "Michael Chan",
    details: "Emailed Customer",
  },
  {
    logNo: "0083",
    date: "07 Jul 2022 10:40:15 AM",
    action: "File Upload",
    author: "Michael Chan",
    details: "Attached a PDF file to General Steps",
  },
];
export const COMPANY_DET_COLORS = [
  {
    NAME: "Primary Color",
    color: "#4189CD",
  },
  {
    NAME: "Secondary Color",
    color: "#FFFFFF",
  },
  {
    NAME: "Tertiary Color",
    color: "#1A60A3",
  },
];
export const MEDIA_CENTER = {
  MEDIA_CENTER: "Media Center",
  CARD_BODY: "Content will show up here once added",
};
export const LOGIN_TEXTS = {
  RESET_PASSWORD: "Reset Password",
  NEW_PASSWORD: "New Password",
  CNFM_PASSWORD: "Confirm Password",
  RESET: "Reset",
  PLSHDR_TXT_PSWD: "Please enter password here",
  PLSHDR_TXT_REPSWD: "Please re-enter password here",
};

export const TABLE_VIEW_EQUIPMENTS_KEYS = {
  EQUIPMENT_NAME: "Unit Name:",
  EQUIPMENT_MODEL_NO: "Model Number:",
  SERIAL_NO: "Serial Number:",
  MANUFACTURER: "Manufacturer:",
  YEAR_OF_CREATION: "Year Built:",
  PART_NUMBER: "Part Number:",
  EQUIPMENT_TYPE: "Equipment Type:",
  SAFETY_ISSUE: "Safety Issue",
  WARRENTY: "Warrenty Date",
  DO_NOT_OPERATE: "Do Not Operate",
  NEED_ADDITIONAL_VISITS: "Additional Visits",
  MAINTAINENCE_ISSUE: "Manintainance Issue",
  QUANTITY: "Quantity: ",
  COST: "Cost: ",
  PAID: "Paid: ",
  PRIM_SERV_REP: "Representative:",
  END_OF_WARRENTY: "Warrenty Date:",
  NOTE: "Note:",
  INSTALLED_DATE: "Install Date:",
  LAST_SERVICED: "Last Serviced:",
  BUILDING_NAME: "Building Name:",
  EQUIPMENT_LOCATION: "Location:",
  ALTERNATE_EQUIPMENT: "Alternate Equipment:",
  EQUIPMENT_ID: "Equipment Id:",
  REFRIDGRENT_TYPE: "Refridgerent Type:",
  AREA_SERVICED: "Area Serviced:",
  REFRIDGRENT_QUANTITY: "Refridgerent Quantity:",
  RATING_KWS_CAPS: "Rating/kws /caps:",
  STATUS: "Status:",
  CUST_EQUIPMENT_NO: "Equipment Number:",
  EQUIPMENT_CONDITION: "Equipment Condition:",
  INSTRUCTIONS: "Instructions:",
  BARCODE: "Barcode:",
  CONDITION: "Condition",
  EQUIPMENT_INFO_MODAL: "Equipment Info",
};
export const EQUIP_INFOMODAL_VALUES = {
  MODAL_HEADER: "Air Conditioning Unit",
};
export const EQUIP_INFOVIEW_VALUES = {
  MODEL: "Model",
  ID: "ID",
  SERIAL: "Serial",
  INSTALL: "Install",
  WARRANTY: "Warranty",
};
export const SHARE_MODAL_VALUES = {
  SHARE_VALUE: "Share",
  SAHRE_EMBED: "Embed",
  SHARE_MAIL: "Mail",
  SHARE_WHATSAPP: "Whatsapp",
  SHARE_FACEBOOK: "Facebook",
  SHARE_TWITTER: "Twitter",
  SHARE_KAKO: "Kakao Talk",
  SHARE_COPY: "Copy",
  SHARE_START_AT: "Start at",
};
export const TAGS_MODAL_VALUES = {
  SELECT_TAG: "Select Tags",
  CREATING_TAG_NEW: "Create new tag",
  NEW_TAG_COLOR: "new tag color",
  SEARCH_CREATED_TAGS: "search created tags",
  SAVE: "Save",
  CANCEL: "Cancel",
  CREATE: "Create",
  DELETE: "Delete",
  DISCARD: "Discard",
  CONFIRM: "Confirm",
  EDIT: "Edit",
  HISTORY: "History",
  ADD_TAG_BELOW: "Add Tag",
  POP_UP_TEXT:
    'Are you sure, you want to change the status from "To Do" to "In Progress" ?',
  TAG_DELETE_TEXT: "Are you sure, you want to Delete Tag ?",
  ALERT_STATUS_UPDATE_IP: 'Status updated to "In Progress" ',
  TAG_ADDED: "Tag Added Successfully",
  TAG_DELETE: "Delete Tag",
  TXT_POP_UP: "Are you sure, you want",
  NOTE_DELETE: "Note Deleted Successfully",
  NOTE_UPDATE: "Note Updated Successfully",
};
export const LOCATION_MAP =
  "https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d116862.54554679655!2d90.40409584970706!3d23.749000170125925!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1550040341458";
export const LOCATION_MODAL_CONSTANTS = {
  END: "End",
  ARRIVAL: "arrival",
  ARRIVAL_TIME: "4:21",
  HOURS_VALUE: "1:23",
  HOURS: "hrs",
  MINITUES_VALUE: "70.1",
  MINITUS: "mi",
};

export const FIELD_SETUP = {
  LABEL_NAME: " Name",
  CTRL_TYPE: "Control Type",
  RQRD: "Required",
  ODR: "Order",
  CNT_ADD_FLD: "Contact Additional Fields",
  CUST_ADD_FLD: "Customer Additional Fields",
  EQP_ADD_FLD: "Equipment Additional Fields",
  MX_FLD_ADDED: "Max Field Added",
  CONT_SUP: "Contact Support",
};

export const WORK_ORDER_CONSTANTS = {
  SORT_BY: "Sort By",
  WORK_ORDER_NOTE_DEL: "Are you sure You want to delete Workorder note",
  STATUS: "Status",
  WORKORDER: "work orders",
  WORK_ORDER_NUMBER: "Work Order Number",
  DATE_ASSIGNED: "Date Assigned",
  CUSTOMER: "Customer",
  CONTACT_ID: "Contact",
  SELECT_STATUS: "Select Status",
  TECH_ASSIGNED: "Tech Assigned",
  RECENTLY_VIWD: "Recently Viewed",
  CREATE_WORK_ORDER: "Create Work Order",
  WORK_ORDER: "Work Order #",
  WORK_FLOWS: "Workflows",
  EQUIPMENTS: "Equipment",
  LIBRARY: "Library",
  CALLTYPE: "Call Type",
  CALL_DEL:
    "  Once you remove this it is gone forever. All work orders that are currently using this will still be using this and will need to manually be changed.Are you sure you want to proceed?",
  CITY: "City",
  STATE: "State",
  ZIP: "Zip",
  WORK_DETAILS_BTN: "Adjust Work Order status",
  DETAILS: "Info",
  WORK_FLOW2: "Workflow",
  NOTES: "Notes",
  NOTE: "Note",
  CONTACTS: "Contacts",
  EXTRA_FILED: "Additional Fields",
  COMMUNICATION: "Communications",
  COMMU: "Communication",
  LOGS: "Logs",
  MODEL_NO: "Model No",
  SERIAL_NO: "Serial No",
  MANUFACTURER: "Manufacturer",
  ACTIVE_INACTIVE: "Active/Inactive",
  EDIT: "Edit",
  ACTIVE: "Active",
  INACTIVE: "InActive",
  COMPLETE_WORK_ORDER: "Adjust Status",
  TAG_NAME: "Tag 1234",
  DOG_PRESENT: "Dog Present",
  GOLD_MEMBER: "Gold Member",
  ADD_TAG: "Add Tag",
  WORK_ORDER_NAME: "Work Order Name",
  WORK_ORDER_TYPE: "Work Order Type:",
  HVAC_REPAIR: "HVAC repair",
  WORK_ORDER_ADDRESS: "Address",
  ASSIGN_WORK_ORDER: "Assign work order:",
  WORK_ORDER_DATE: " Date:",
  WORK_ORDER_TIME: "Time:",
  DISCRIPTION: "Description",
  REASON: "Reason",
  WORK: "Work",
  ADDRESS: "Address:",
  TAG: "Tag",
  NO_WORK_ORDERS: "There are no WorkFlows",
  WORK_FLOW_ADD: "Adjust Workflow(s)",
  PLEASE_SELECT_WORKFLOW_SEE: "Please select which workflow you would like use",
  DELETE_WORKFLOW: "Delete WorkFlow",
  CUST_NAME: "Customer Name:",
  NOTES_CARD:
    "No notes have been added. Please attach content to display in this section",
  COMMUNCTN_CARD:
    "No communication can be added until creation has been completed.Please complete creation to continue",
  EQPMNT_CARD:
    "No items have been added. Please attach content to display in this section",
  WRKFLWS_BODY: "Please select a workflow to continue",
  DEL: "Are you sure you want to delete note ?",
  CUST_NOTES_DESC:
    "No Notes have been added. Please add a note to display it here.",
};

export const LIBRARY_DET = {
  RECENTLY_VIEWED_ITEMS: "Recently Viewed Items",
  RECENTLY_VIEWED: "Recently Viewed",
  GALLARY: "Gallery",
  MEMORY_BOARD: "Memory Board",
  PARTS_MANUAL: "Parts Manual",
  DOCUMENTS: "Documents",
  MAINTAINANCE_LOG: "Maintainance Log",
  USER_MANUAL: "User Manual",
  InO_MANUAL: "Installation and Operation Manuals",
  SRVC_BULTN: "Service Bulletins",
  INT_ACT_PT_DIAG: "Interactive Parts Diagram",
  YT_VIDEOS: "YouTube Videos",

  LIB_TXT: "History of Previous Work Orders",
};

export const NOTES_ADD_CONSTANTS = {
  NOTE: "Notes",
  DELETE_NOTE: "Are you sure you want to delete ",
  CREATED_NOTE: "Created",
  EDIT_NOTE_DATE: "MMM DD,yyyy hh:mm",
  ADD_NOTE: "Add Notes",
  EDIT_NOTE: "Edit Notes",
  NOTE_TITLE: "Note title",
  NOTE_DESCRIPTION: "Note description",
  NOTE_SUBMIT: "Submit",
  NOTE_CANCEL: "Cancel",
};

export const HEADER_DETAILS = {
  LOGO: "LOGO",
  COMP_NAME: "Virtual Tech",
  COMP_INFO: "(000) 000-0000•info@company.com",
};

export const EQUIPMENT_DETAILS = {
  CREATE_EQP: "Create Equipment",
  EQUIPMENT_ID: "Equipment ID:",
  EQUIPMENTNAME: "Equipment Name:",
  EQUIPMODAL: "Model:",
  EQUP_SERIALNUM: "Serial Number:",
  Active: "Active",
  InActive: "Inactive",
};

export const ATTACH_MODAL_CONSTANTS = {
  LOADING: "Loading...",
  ZOOM_IN: "Zoom In",
  ZOOM_OUT: "Zoom Out",
  RESET: "Reset",
};

export const ADD_EQUIPMENT_SCROLL = {
  INFO: "Info",
  NOTES: "Notes",
  LIBRARY: "Library",
  REPORTS: "Reports",
  LOGS: "Logs",
  DESCRIPTION: "See all info about the equipment below.",
};

export const COMPANY_DETAILS = {
  COMPANY_NAME: "Company Name",
  COMPANY_ID: "Company Id",
  ADDRESS: "Address",
  ADDRESS_2: "Address 2",
  CITY: "City",
  ZIP: "Zip",
  NOTE_S: "Notes",
  EQUIPMENT_LABEL: "Equipment Label",
  STATE: "State",
  COUNTRY: "Country",
  CONTACT_NUMBER: "Contact Number",
  EMAIL_ADD: "Email Address",
  PRIMY_CONTACT: "Primary Contact",
  PRIMARY_ADDRESS: "Primary Address",
  PRIMARY_ZIP: "Primary Zip",
  PRIMARY_CITY: "Primary City",
  PRIMARY_STATE: "Primary State",
  SECONDARY_ADDRESS: "Secondary Address",
  SECONDARY_ZIP: "Secondary Zip",
  SECONDARY_CITY: "Secondary City",
  SECONDARY_STATE: "Secondary State",
  LOGIN_FOOTER: "Powered by 9 Pack Software (version 1.0.0)",
  LICENSE_CNT: "License Count",
  ADMINISTRATION: "Administration",
  TECHNICIAN: "Technician",
};

export const SETTINGS_PAGES = {
  COMPANYS_DETAILS: "Company Details",
  WORK_ORDERS_SETUP: "Field Setup",
  CARD_CATALOG: "Card Catalog",
  USER_SETUP: "User Setup",
  WORKFLOW_SETUP: "Workflow Setup",
  ERROR_LOGS: "Error Logs",
  CUSTOMER_PORTAL: "Customer Portal",
  MSTR: "Master Form",
  INTEG: "Intergrations",
};

export const WORK_ORDER_SETUP = {
  WORK_FLOW_SETUP: "Work Order Setup",
  WORK_ORDER_TYPES: "Work Order Types",
  WORK_ORDER_STATUS: "Work Order Status",
  WORK_ORDER_ADDITIONAL: "Work Order Additional Fields",
  CREATE_NEW_WORKFLOW: "Create New Type",
  CLOSING_WF: "Closing Workflow",
  INITIAL_WF: "Initail Workflow",
  GENERAL_WF: "General Workflow",
  ADDITIONAL_REVENEUE: "Additional Reveneue",
  DUPLICATE_WO: "Duplicate Work Order #",
  DUPLICATE_EQP: "Duplicate Equipment #",
  DESCRPTN: "Please select which sections you would like to copy.",
  EQP_DESCRPTN: " create new equipment based on this equipment.",
  WRKFLWS: "Work Flows",
  INIT_WRKFLWS: "Initial Work Flows",
  GEN_WRKFLWS: "General Work Flows",
  CLOSING_WRKFLWS: "Closing Work Flows",
  TECHNICIANS: "Technicians",
  EQPMNT: "Equipment",
};

export const CARD_CATALOG = {
  WORK_ORDER_EDITOR: "Work Order Editor",
  EQUIPMENT_EDITOR: "Equipment Editor",
  ENABLE_TAGS: "Enable Tags ?",
  CONTACT_DRAG: "Drag Items from below to Change your Contact Card",
  PREVIOUS_WOs: "Previous Work Orders",
  RECENT_WOs: "Recently Viewed Work Orders",
  RECENTLY_WOs: "Recently Viewed Work Orders",
  TECHs: "Techs",
  ADD_WO_SETT: "Additional Work Order Card Settings ?",
  CONTACT_EDITOR: "Contact Editor",
  BRANCH_EDITOR: "Branch Editor",
  CUSTOMER_EDITOR: "Customer Branch",
  CONTACT_CARD: "Contact Card",
  PHONE_NUMBER: "Phone Number",
  EMAIL_ADD: "Email Address",
  NAME: "Name",
  S_NAME: "S Name",
  CC_Des: "Drag Items form below to change your Branch Card",
};

export const STATUS_WO = [
  { name: "work order.1  " },
  { name: "work order.2  " },
  { name: "work order.3  " },
  { name: "work order.4  " },
];

export const USERS_SETUP = {
  USERS: "Users ",
  TEAMS: "Team(s):",
  NAME_US: "Name:",
  PH_NMBR: "Phone Number:",
  CALL: "Call",
  EMAIL: "Email",
  ACTIVE: "Active",
  EDIT: "Edit:",
  USER_TIED: "User Tied Up:",
  ADD: "Add",
  UPDATE: "Update",
  ADD_USER: "Create User",
  EDIT_USR: "Edit User",
  PERMISSION_TEMP: "Perssion Templates:",
  EMPLOYEE: "Employee Team",
  F_NAME: "First Name",
  L_NAME: "Last Name",
  USER_NAME: "User Name",
  PASSWORD: "User Password",
  CX_PTL_EML: "User Email",
  CX_PH_NO: "Customer Phone Number",
  USER_PH_NO: "User Phone Number",
  TEAM: "Team",
  ROLE: "Role",
  LIMIT_WO: "Limit work order view to",
  RULES: "Rules:",
  ABILITY_TO_CREATE_EQIUPMENT: "Ability to Create Equipment",
  ABILITY_TO_CREATE_WORK_ORDERS: "Ability to Create Work Orders",
  COMUNNICATIONS_ENABLED: "Communications Enabled?",
  SELECTED: "Selected:",
};
export const CUST_CONTACT = {
  EDIT: "Edit",
  EDIT_CONTACT: "Edit Contact",
  CREATE_CONTACT: "Create Contact",
  F_NAME: "First Name:",
  L_NAME: "Last Name:",
  PH_NUMBER: "Phone Number:",
  E_MAIL: "Email Address:",
  DECSCRIPTION: "Description:",
  F_NAME_PL_HLDR: "Please Enter First name",
  L_NAME_PL_HLDR: "Please enter Last name",
  PH_NMBR_PL_HLDR: "Please enter phone number",
  E_MAIL_PL_HLDR: "Please enter email",
  DESCRP_PL_HLDR: "Please Enter Description",
  SAVE: "Save",
};
export const ERROR_lOG = {
  FROM_DATE: "From Date",
  TO_DATE: "To Date",
  ERROR: "Error",
  AUTHOR: "Author",
  EXPORT_TO_EXCEL: "Export To Excel",
  LOG_No: "Log No.",
  DATE: "Date",
  EMAIL: "Email",
  GOTO: "Go To",
  DETAILS: "Details",
  EXP_TO_XL: "Export To Excel",
};
export const EQPMAN = {
  MANUFACTURER: "Manufacturer",
  DELETE: "Delete",
  EDIT: "Edit",
  ADD: "Add",
  EQPTYPE: "eqpType",
  EQPMENT_TYPE: "Equipment Type",
  REMOVE: "Remove",
  CREATE: "Create",
};
export const INTEGRATIONS_DET = {
  SERVICE_FUSION_INTEG: "Service Fusion Integration",
  WORK_ODR_FLD_SETT: "Work Order Field Settings",
  WORK_ODR_ADD_FILD: "Work Order Additional Setting",
  CONTACT_FLDS: "Contact Fields",
  CONT_ADD_FLD: "Contact Additional Fields",
  CUST_FLD_SETT: "Customer Field Settings",
  CUST_ADD_FLD: "Customer Additional Fields",
  EQP_FLD_SETT: "Equipment Field Settings",
  EQP_ADD_FLD: "Equipment Additional Fields",
  TYPE_MNGR: "Type Manager",
  CHARACTER_COUNT: "Character Count:",
  TYPE: "Type",
};
export const WO_TYPE_MODAL = {
  WO_TYPE_CODE: "Code:",
  WO_TYPE_ID: "Workorder Type Id",
  WO_TYPE_DESC: "Description:",
  WO_TYPE_NAME: "Name:",
  WO_TYPE_WFLW: "Workflows Attached:",
  WO_TYPE_ESTM: "Estimate Workflows Attached:",
  WO_TYPE_WFLWS: "Input all Workflows you want attached to this type",
};

export const CUST_PRTL = {
  CUSTOMER_LIBRARY: "Customer Library",
  CUSTOMER_DSBD: "Customer Dashbord",
  CUSTOMER_SETT: "Customer Settings",
  CUSTOMER_WRK_ODR: "Customer Work Order",
  VIEW: "View",
  VIEWBL: "Viewable",
  CUST_VIW: "See Customer View",
  CUST_ID: "Select Customer Id",
  SELECT_CUST: "Select Customer",
  CHANGE_CUST: "Change Customer",
  CNC: "CREATE NEW CUSTOMER",
  CREATING: "Creating",
  TO_DO: "To Do",
  CANCEL: "Cancel",
  IN_PROGRESS: "In Progress",
  HOLD: "Hold",
  CALL_TYPE: "Select Call Type",
  COMPLETED: "Completed",
  SELECT_WO_TYPE: "Select Work Order Type",
  SELECT_STATUS: "Select Status",
  TAG: "Tag",
  ADD_TECH: "Add Technician",
  CUST_DESC:
    "Director of the chain of zaxbys. Please contact for any billimg issues. if there are any isuues please reach out.",
};

export const TEAMS_MODAL = {
  DEL: "Delete",
  MANAGE: "Manage Team",
  CREATE_TEAM: "Create Team",
  CANCEL: "Cancel",
  DISCORD: "Discard",
  SAVE: " Save",
  NAME: " Name:",
  TEAM: "Team(s):",
  SELECT: "Selected:",
  SEL_USER: "Please Select User(s) you would like to include in the team",
  MEMBERS_ORG: "Members Of Your Organization",
  CURRENT_USER: "Please Select Current Team Member",
  CURRENT_TEAM: "Current Team",
  TEAM_NAME: "Please Enter Team Name",
  DESCRIPTION: "Description:",
  T_NAME: "Team Name:",
  CREATE: "Create",
};
export const EQUIPMENT_VALUES = {
  INVITE: "Send Invite",
  INVITE1: "Invite to Job",
  PEOPELE: "Recommended People",
};
export const DEL_W_O = {
  DELETE: "Delete",
  DELETE_NOTES: "Delete Notes",
  DEL_CUST: "Delete Note",
  DEL_CUSTR:"Delete Customer",
  DEL_EQ :"Delete Equipment",
  DEL_MODAL_BODY:
    "Are you sure you want to delete this? It will remove this from all mobile users and will only be recoverable in the web ",
};
export const SHARE_W_O = {
  SHARE: "Share",
  INV_JOB: "Invite to Job",
  SEND_INV: "Send Invite",
  RCMND_PPL: "Recommended People",
  INV: "Invite",
};
export const TECH = {
  ADD_TECH: "Add Technicians",
  MAN_TECH: "Manage Technicians",
  SELECT_TECH: "Please Select Technicians to add to the Work order",
};
export const ExampleArray = [
  {
    cat: "Group 1",
    key: "selection 1",
  },
  {
    cat: "Group 1",
    key: "selection 2",
  },
  {
    cat: "Group 1",
    key: "selection 3",
  },
  {
    cat: "Group 2",
    key: "selection 4",
  },
  {
    cat: "Group 2",
    key: "selection 5",
  },
  {
    cat: "Group 2",
    key: "selection 6",
  },
  {
    cat: "Group 2",
    key: "selection 7",
  },
  {
    cat: "Group 2",
    key: "selection 8",
  },
  {
    cat: "Group 2",
    key: "selection 9",
  },
  {
    cat: "Group 2",
    key: "selection 10",
  },
];

export const CompDetailsIntegrations = [
  {
    name: "Integration To Service Fusion",
    selection: "Select Work Order Type Field Location",
  },
  {
    name: "Integration To Field Edge",
    selection: "Select Tech Docs Field Location",
  },
  {
    name: "Integration To G2",
    selection: "Select Work Order Status Field Location",
  },
  {
    name: "Integration To Service Titan",
    selection: "Select Workflows Field Location",
  },
  {
    name: "Integration To RFS",
    selection: "Select Details Field Location",
  },
  {
    name: "Integration To Google",
    selection: "Select Equipment Field Location",
  },
];

export const ERROR_LOGS_TABLE = [
  {
    LOG_NO: "001",
    DATE_EL: "10 Jul 2022",
    ERROR_EL: "Invalid Address",
    EMAIL_EL: "Chang@usa.com",
    DETAILS_EL: "Email failed to Show",
  },
  {
    LOG_NO: "002",
    DATE_EL: "12 Jul 2022",
    ERROR_EL: "Invalid Email Address",
    EMAIL_EL: "Yesu@usa.com",
    DETAILS_EL: "Email failed to Show",
  },
  {
    LOG_NO: "003",
    DATE_EL: "15 Jul 2022",
    ERROR_EL: "Invalid Zip",
    EMAIL_EL: "Dan@usa.com",
    DETAILS_EL: "Zip failed to Show",
  },
  {
    LOG_NO: "004",
    DATE_EL: "19 Jul 2022",
    ERROR_EL: "",
    EMAIL_EL: "Adam@usa.com",
    DETAILS_EL: "N/A",
  },
  {
    LOG_NO: "005",
    DATE_EL: "24 Jul 2022",
    ERROR_EL: "Invalid Company",
    EMAIL_EL: "john@usa.com",
    DETAILS_EL: "Company looks Invalid",
  },
  {
    LOG_NO: "006",
    DATE_EL: "27 Jul 2022",
    ERROR_EL: "Invalid State",
    EMAIL_EL: "sean@usa.com",
    DETAILS_EL: "State is Invalid",
  },
  {
    LOG_NO: "007",
    DATE_EL: "30 Jul 2022",
    ERROR_EL: "",
    EMAIL_EL: "WillSmith@usa.com",
    DETAILS_EL: "N/A",
  },
  {
    LOG_NO: "008",
    DATE_EL: "01 Aug 2022",
    ERROR_EL: "",
    EMAIL_EL: "Wilson@usa.com",
    DETAILS_EL: "N/A",
  },
  {
    LOG_NO: "009",
    DATE_EL: "03 Aug 2022",
    ERROR_EL: "",
    EMAIL_EL: "biden@usa.com",
    DETAILS_EL: "N/A",
  },
  {
    LOG_NO: "010",
    DATE_EL: "06 Aug 2022",
    ERROR_EL: "",
    EMAIL_EL: "katie@usa.com",
    DETAILS_EL: "N/A",
  },
  {
    LOG_NO: "011",
    DATE_EL: "09 Aug 2022",
    ERROR_EL: "",
    EMAIL_EL: "Salena@usa.com",
    DETAILS_EL: "N/A",
  },
];

export const SAM_DATA_WRK_ODR_FLD_SETT = [
  {
    name: "spansor",
  },
  {
    name: "work Order number",
  },
  {
    name: "work Order Name",
  },
  {
    name: "Status ",
  },
  {
    name: "Note",
  },
];
export const Equipment_Type = [
  { value: "AC Unit", key: 1 },
  { value: "Heaters", key: 2 },
  { value: "Humedifer", key: 3 },
  { value: "Evaporative Coolers", key: 4 },
  { value: "Thermostats", key: 5 },
];
export const FilterData = [
  {
    name: "Work Order Name",
  },
  {
    name: "Date",
  },
  {
    name: "Customer",
  },
  {
    name: "Status",
  },
  {
    name: "Address",
  },
  {
    name: "Primary Contact",
  },
  {
    name: "Phone Number",
  },
  {
    name: "Primary Branch",
  },
  {
    name: "Lead Tech",
  },
  {
    name: "Additional Techs",
  },
  {
    name: "City",
  },
  {
    name: "State",
  },
  {
    name: "Tags",
  },
];
export const FilterCustData = [
 
  {
    name: "Customer Number",
  },
  {
    name: "Primary Contact",
  },
  {
    name: "Phone Number",
  },
  {
    name: "Address",
  },
  {
    name: "Zip",
  },
  {
    name: "Total Work Orders",
  },
];
export const UniType_Data = [
  {
    type: "Distance",
    Values: [
      { name: "Inches" },
      { name: "Feet" },
      { name: "Miles" },
      { name: "Millimeters" },
      { name: "Meters" },
      { name: "Kilometers" },
    ],
  },
  {
    type: "Area",
    Values: [
      { name: "Square Inches" },
      { name: "Square Feet" },
      { name: "Square Mile" },
      { name: "Square Millimeter" },
      { name: "Square Meter" },
      { name: "Square Kilometer" },
    ],
  },
  {
    type: "Volume",
    Values: [
      { name: "Cubic Inch" },
      { name: "Cubic Foot" },
      { name: "Cubic Meter" },
      { name: "Cubic Millimeter" },
      { name: "Milliliter" },
      { name: "Liter" },
    ],
  },
  {
    type: "Power",
    Values: [
      { name: "Volts" },
      { name: "Watts" },
      { name: "Ohms" },
      { name: "Amps" },
      { name: "Phase" },
      { name: "Microfarads" },
    ],
  },
  {
    type: "Sound",

    Values: [{ name: "Decibels" }, { name: "Frequency" }],
  },
  {
    type: "Speed",
    Values: [
      { name: "Revolutions per Minute" },
      { name: "Revolutions per Second" },
      { name: "Feet per Second" },
      { name: "Feet per Minute" },
      { name: "Miles per Hour" },
    ],
  },
  {
    type: "Flow Rate",
    Values: [{ name: "Cubic Feet per Minute" }, { name: "Gallons per Minute" }],
  },
  {
    type: "Particulate",
    Values: [{ name: "Parts per Million" }, { name: "Parts per Thousand" }],
  },
  {
    type: "Pressure",
    Values: [
      { name: "Pounds per Square Inch" },
      { name: "Inches of Water Column" },
      { name: "Microns" },
      { name: "Atmospheres" },
      { name: "Newtons" },
      { name: "Pascals" },
    ],
  },
  {
    type: "Force",
    Values: [{ name: "Pounds " }, { name: "Kilograms" }, { name: "Newtons" }],
  },
  {
    type: "Capacity",
    Values: [{ name: "Tonnage" }, { name: "British Thermal Unit Per Hour" }],
  },
  {
    type: "Humidity",
    Values: [{ name: "Percent" }],
  },
  {
    type: "Temperature",
    Values: [
      { name: "Degrees Fahrenheit" },
      { name: "Degrees Celsius" },
      { name: "Wet Bulb" },
      { name: "Dry Bulb" },
      { name: "British Thermal Unit" },
    ],
  },
  {
    type: "Time",
    Values: [
      { name: "Milliseconds" },
      { name: "Seconds" },
      { name: "Minutes" },
      { name: "Hours" },
      { name: "Hours: Minutes: Seconds AM/PM" },
      { name: "Hours: Minutes AM/PM" },
      { name: "Timeframe" },
    ],
  },
  {
    type: "Weight",
    Values: [{ name: "Pounds" }, { name: "Kilograms" }, { name: "Newtons" }],
  },

  {
    type: "Currency",
    Values: [
      { name: "Dollars" },
      { name: "Euros" },
      { name: "Pesos" },
      { name: "Rupees" },
    ],
  },
  {
    type: "Date",
    Values: [
      { name: "Month/Day/Year" },
      { name: "Day/Month/Year" },
      { name: "Month/Year" },
      { name: "Day" },
      { name: "Month" },
      { name: "Year" },
      { name: "Day of the week selection" },
      { name: "Timeframe" },
    ],
  },
  {
    type: "Date/Time",
    Values: [
      { name: "Month/Day/Year" },
      { name: "Day/Month/Year" },
      { name: "Month/Year" },
      { name: "Day" },
      { name: "Month" },
      { name: "Year" },
      { name: "Day of the week selection" },
      { name: "Date Range" },
    ],
  },
  {
    type: "Quantity",
    Values: [{ name: "Items" }, { name: "Units" }],
  },

  {
    type: "General Fields",
    Values: [
      { name: "Text Input" },
      { name: "Numeric Input" },
      { name: "Email input" },
      { name: "Phone number input" },
    ],
  },
];
export const Workflow_Category = [
  {
    name: "spansor",
    id: 1,
  },
  {
    name: "work Order number",
    id: 2,
  },
  {
    name: "work Order Name",
    id: 3,
  },
  {
    name: "Status ",
    id: 4,
  },
  {
    name: "Note",
    id: 5,
  },
];
export const Workflow_Table_Category = [
  {
    name: "Intial",
    id: 10,
  },
  {
    name: "General",
    id: 20,
  },
  {
    name: "Completion",
    id: 30,
  },
];
export const viewLimitOptions = [
  {
    name: "Today",
    viewLimit_id: 1,
  },
  {
    name: "Today and Tomorrow",
    viewLimit_id: 2,
  },
  {
    name: "Today and Future",
    viewLimit_id: 3,
  },
  {
    name: "Today and Next Call",
    viewLimit_id: 4,
  },
  {
    name: "Today and Next Two Upcoming Calls",
    viewLimit_id: 5,
  },
  {
    name: "Today and Next Three Upcoming Calls",
    viewLimit_id: 6,
  },
];
export const Wo_Workflow = {
  Req_step: "Required Steps Exist",
  OpenReq_steps: "Open Steps Remaining",
  Load: "Loading...",
  View_remain_steps: "View Remaining Open Steps",
};

export const workFlow_Auto = [
  {
    id: "1",
    name: "Customer",
  },
  {
    id: "2",
    name: "Work Order Charge Customer",
  },
  {
    id: "3",
    name: "Work Order Type",
  },
  {
    id: "4",
    name: "Call Type",
  },
  {
    id: "5",
    name: "Contract",
  },
  {
    id: "6",
    name: "Contract/Call Type",
  },
  {
    id: "7",
    name: "Work Order Charge Customer/Warranty",
  },
];
