import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Carousel } from "react-bootstrap";
import "./AttachmentModal.css";
import Close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { MediaAttachments } from "../../services/WorkOrdersService";
import ProgressBar from "../../common/navigation/ProgressBar";
const AttachmentModal = ({
  showModal,
  handleCarouselSelect,
  handleCloseModal,
  selectedImageIndex,
  eachWorkOrder,
  openImageInNewTab,
}) => {

  const [zoomMessage, setZoomMessage] = useState("");
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    if (zoomLevel < 3) {
      setZoomLevel(zoomLevel + 1);
      setZoomMessage("");
    } else {
      setZoomMessage("Can't zoom in further.");
      setIsZoomed(true);
    }
  };
  const handleZoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel(zoomLevel - 1);
      setZoomMessage("");
    } else {
      setZoomMessage("");
      setIsZoomed(false);
    }
  };
  useEffect(() => {
    setZoomLevel(1);
    setZoomMessage("");
    setIsZoomed(false);
  }, [selectedImageIndex, showModal]);
  const [imgthumbnail, setImgThumbnail] = useState("");
  const [isProgress, setIsProgress] = useState(true);

  useEffect(() => {
    const fetchMediaAttachments = async () => {
      setIsProgress(true);
      try {
        let id = eachWorkOrder?.[selectedImageIndex]?.id
          ? eachWorkOrder?.[selectedImageIndex]?.id
          : eachWorkOrder?.[selectedImageIndex]?.attachment_id;
        if (id) {
          const res = await MediaAttachments(id);
          if (res?.success) {
            setIsProgress(false);
          }
          setImgThumbnail(res?.data?.content);
        }
      } catch (error) {
        console.error("Error fetching media attachments:", error);
      }
    };
    fetchMediaAttachments();
  }, [eachWorkOrder, selectedImageIndex]);
  // useEffect(() => {
  //   const prefetchAttachments = async () => {
  //     try {
  //       const ids = eachWorkOrder.map(
  //         (item) => item.id || item.attachment_id
  //       );
  //       const fetches = ids.map((id) => MediaAttachments(id));
  //       const responses = await Promise.all(fetches);
  //       // Cache responses if needed
  //     } catch (error) {
  //       console.error("Error prefetching attachments:", error);
  //     }
  //   };
  
  //   if (showModal) prefetchAttachments();
  // }, [showModal, eachWorkOrder]);
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header style={{ height: "30px" }} className="modal-bg-color  ">
          {eachWorkOrder && eachWorkOrder?.length > 0 && (
            <span className="note-add-text">
              {eachWorkOrder?.[selectedImageIndex]?.filename ||
                eachWorkOrder?.[selectedImageIndex]?.attachment_name}
            </span>
          )}
          <img
            className="modal-header-icon"
            onClick={handleCloseModal}
            src={Close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            height: "49vh",
          }}
        >
          <Carousel
            style={{
              height: "45vh",
            }}
            activeIndex={selectedImageIndex}
            onSelect={handleCarouselSelect}
            interval={null}
            indicators={false}
          >
            {eachWorkOrder?.map((attachment, index) => (
              <Carousel.Item key={index}>
                <div>
                  {isProgress === true ? (
                    <div className="progress-bar" style={{ height: "250px" }}>
                      <ProgressBar />
                    </div>
                  ) : (
                    <img
                      onDoubleClick={() => {
                        if (!attachment?.file_type?.startsWith("image/")) {
                          openImageInNewTab(attachment);
                        }
                      }}
                      className="d-block w-100"
                      style={{
                        height: "45vh",
                        transform: `scale(${zoomLevel})`,
                      }}
                      src={
                        // attachment?.thumbnail?.includes("base64")
                        // ? attachment?.thumbnail
                        // :
                        attachment?.file_type?.startsWith("image/")
                          ? `data:${attachment?.file_type};base64,${imgthumbnail}`
                          : `data:${attachment?.file_type};base64,${attachment?.thumbnail}`
                      }
                      alt={`Slide ${index}`}
                    />
                  )}
                  <div className="zoo-controls">
                    <button className="zoo-button" onClick={handleZoomIn}>
                      +
                    </button>
                    <button className="zoo-button" onClick={handleZoomOut}>
                      -
                    </button>
                  </div>
                  {zoomMessage && (
                    <span className="zoo-msg">{zoomMessage}</span>
                  )}
                </div>
                {/* <style>
                {`
                .carousel-control-prev-icon,
                .carousel-control-next-icon {
                    width: 60px;
                    height: 60px;
                   
                }`}
            </style> */}
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AttachmentModal;
