import React, { forwardRef } from "react";
import close from "../../../common/Assets/Icons/Close_round_main.svg";
import wheel from "../../../common/Assets/Icons/DownDir.svg";
import { Dropdown, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import sortA from "../../../common/Assets/Icons/sortAz.svg";
import sortZ from "../../../common/Assets/Icons/sortZa.svg";
import DatePicker from "react-datepicker";
import "./WorkOrderTableStyles.css";

const FilterModal = (props) => {
  const [startDate, setStartDate] = React.useState();
  const [completedDate, setCompletedDate] = React.useState();

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input mt-1"
      placeholder="Select Date"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [array] = React.useState([
    {
      name: "Tech 1",
      type: "checkbox",
    },
    {
      name: "Tech 2",
      type: "checkbox",
    },
    {
      name: "Tech 3",
      type: "checkbox",
    },
    {
      name: "Tech 4",
      type: "checkbox",
    },
    {
      name: "Tech 5",
      type: "checkbox",
    },
  ]);

  const closeModal = () => {
    props.isClose();
    setStartDate("");
    setCompletedDate("");
  };

  return (
    <Modal centered show={props.isOpen} onHide={props.isClose}>
      <Modal.Header className="px-2 modal-bg-color modal-header-height note-edit-text ">
        Select Items You Wish to See{" "}
        <img
          src={close}
          className="modal-header-icon "
          onClick={() => closeModal()}
          alt="open"
        />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="line-align-bet">
          <div className=" lbl-fltr-adj">
            <span className="txt-fltr-adj">Assigned Date</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<ExampleCustomInput />}
              dayClassName={() => "example-datepicker-day-class"}
              popperClassName="example-datepicker-class"
            />
          </div>
          <div className=" lbl-fltr-adj ">
            <span className="txt-fltr-adj">Completed Date</span>
            <DatePicker
              selected={completedDate}
              onChange={(date) => setCompletedDate(date)}
              customInput={<ExampleCustomInput />}
              dayClassName={() => "example-datepicker-day-class"}
              popperClassName="example-datepicker-class"
            />
          </div>
        </div>
        <div className="line-align-bet mt-2">
          <div className="lbl-fltr-adj">
            <div>
              <Dropdown>
                <div className="txt-fltr-adj">Assigned Tech(s):</div>
                <Dropdown.Toggle
                  className="my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                  style={{ width: "97%" }}
                >
                  SelectTech(s)
                  <img
                    alt=""
                    width={16}
                    height={16}
                    style={{ float: "right" }}
                    src={wheel}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortA} />{" "}
                    <span className="mx-2">Sort A to Z</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortZ} />{" "}
                    <span className="mx-2">Sort Z to A</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <div
                      className=" lbl-fltr-adj w-100"
                      style={{ position: "relative" }}
                    >
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm "
                        placeholder="Enter Tech"
                      />
                    </div>
                  </Dropdown.Item>
                  {array.map((data, index) => (
                    <Dropdown.Item key={index}>
                      <input type={data.type} />
                      <span style={{ paddingLeft: 10 }}>{data.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="lbl-fltr-adj">
            <div>
              <Dropdown>
                <div className="txt-fltr-adj">Customer on Work Order:</div>
                <Dropdown.Toggle
                  className=" my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                  style={{ width: "97%" }}
                >
                  Select Customer(s)
                  <img
                    alt=""
                    width={16}
                    height={16}
                    style={{ float: "right" }}
                    src={wheel}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortA} />{" "}
                    <span className="mx-2">Sort A to Z</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortZ} />{" "}
                    <span className="mx-2">Sort Z to A</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <div
                      className=" lbl-fltr-adj w-100"
                      style={{ position: "relative" }}
                    >
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm "
                        placeholder="Enter Tech"
                      />
                    </div>
                  </Dropdown.Item>
                  {array.map((data, index) => (
                    <Dropdown.Item key={index}>
                      <input type={data.type} />
                      <span style={{ paddingLeft: 10 }}>{data.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="line-align-bet mt-2">
          <div className=" lbl-fltr-adj ">
            <div>
              <Dropdown>
                <div className="txt-fltr-adj">City:</div>
                <Dropdown.Toggle
                  className=" my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                  style={{ width: "97%" }}
                >
                  Select City(s)
                  <img
                    alt=""
                    width={16}
                    height={16}
                    style={{ float: "right" }}
                    src={wheel}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortA} />{" "}
                    <span className="mx-2">Sort A to Z</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortZ} />{" "}
                    <span className="mx-2">Sort Z to A</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <div
                      className=" lbl-fltr-adj w-100"
                      style={{ position: "relative" }}
                    >
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm "
                        placeholder="Enter Tech"
                      />
                    </div>
                  </Dropdown.Item>
                  {array.map((data, index) => (
                    <Dropdown.Item key={index}>
                      <input type={data.type} />
                      <span style={{ paddingLeft: 10 }}>{data.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className=" lbl-fltr-adj ">
            <div>
              <Dropdown>
                <div className="txt-fltr-adj">Tags:</div>
                <Dropdown.Toggle
                  className=" my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                  style={{ width: "97%" }}
                >
                  Select Tag(s)
                  <img
                    alt=""
                    width={16}
                    height={16}
                    style={{ float: "right" }}
                    src={wheel}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortA} />{" "}
                    <span className="mx-2">Sort A to Z</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortZ} />{" "}
                    <span className="mx-2">Sort Z to A</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <div
                      className=" lbl-fltr-adj w-100"
                      style={{ position: "relative" }}
                    >
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm "
                        placeholder="Enter Tech"
                      />
                    </div>
                  </Dropdown.Item>
                  {array.map((data, index) => (
                    <Dropdown.Item key={index}>
                      <input type={data.type} />
                      <span style={{ paddingLeft: 10 }}>{data.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="line-align-bet mt-2">
          <div className=" lbl-fltr-adj ">
            <div>
              <Dropdown>
                <div className="txt-fltr-adj">Equipment Model:</div>
                <Dropdown.Toggle
                  className=" my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                  style={{ width: "97%" }}
                >
                  Select Model(s)
                  <img
                    alt=""
                    width={16}
                    height={16}
                    style={{ float: "right" }}
                    src={wheel}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortA} />{" "}
                    <span className="mx-2">Sort A to Z</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortZ} />{" "}
                    <span className="mx-2">Sort Z to A</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <div
                      className=" lbl-fltr-adj w-100"
                      style={{ position: "relative" }}
                    >
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm "
                        placeholder="Enter Tech"
                      />
                    </div>
                  </Dropdown.Item>
                  {array.map((data, index) => (
                    <Dropdown.Item key={index}>
                      <input type={data.type} />
                      <span style={{ paddingLeft: 10 }}>{data.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="lbl-fltr-adj">
            <div>
              <Dropdown>
                <div className="txt-fltr-adj">Equipment Manufacturer:</div>
                <Dropdown.Toggle
                  className=" my-dropdown-toggle ipt-fltr-adj dpd-btn line-align-bet mt-1"
                  style={{ width: "97%" }}
                >
                  Select Manufacturer(s)
                  <img
                    alt=""
                    width={16}
                    height={16}
                    style={{ float: "right" }}
                    src={wheel}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortA} />{" "}
                    <span className="mx-2">Sort A to Z</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <img alt="" src={sortZ} />{" "}
                    <span className="mx-2">Sort Z to A</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-1">
                    <div
                      className=" lbl-fltr-adj w-100"
                      style={{ position: "relative" }}
                    >
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm "
                        placeholder="Enter Tech"
                      />
                    </div>
                  </Dropdown.Item>
                  {array.map((data, index) => (
                    <Dropdown.Item key={index}>
                      <input type={data.type} />
                      <span style={{ paddingLeft: 10 }}>{data.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Body>
        <div className="modal-footer-styles mt-2 gap-2 model_footer">
          <div className="modal-footer-save-cancel-btns">Save</div>
          <div
            onClick={() => closeModal()}
            className="modal-footer-save-cancel-btns"
          >
            Cancel
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FilterModal;
