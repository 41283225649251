import { NINEPACK_URL } from "./Url";
import axios from "axios";
import { APP_JSON } from "../common/components/Constants";

export const workOrdrList = async (user, status) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(
      status !== undefined
        ? `${NINEPACK_URL}/workorder/list/summary/${user}?status=${status}`
        : `${NINEPACK_URL}/workorder/list/summary/${user}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const workOrdrListSummary = async (user) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/workorder/summary`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res;
  } catch (err) {}
};

export const getWorkOrderById = async (
  workOrderId,
  techinanId,
  customerId,
  Authtoken
) => {
  try {
    let TOKEN =
      localStorage.getItem("authToken") === null
        ? Authtoken
        : JSON.parse(localStorage.getItem("authToken"));

    const response = await axios.get(
      `${NINEPACK_URL}/workorder/details/${workOrderId}/${techinanId}/${customerId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const NoteDeleteFunction = async (workId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/technician/notes/${workId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const EquipmentDelete = async (equipment_id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/equipment/delete/${equipment_id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const NoteEditFunction = async (techId, serviceId, noteId, Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.put(
      `${NINEPACK_URL}/technician/${techId}/service_orders/${serviceId}/notes/${noteId}`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const WoConntactUpdate = async (woid,cust_id,Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.put(
      `${NINEPACK_URL}/workorder/primarycontactupdate/${woid}/${cust_id}`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const NoteCreatFunction = async (
  woid,
  noteId,
  refType,
  Uid,
  Obj,
  refId = null
) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/notes?woId=${woid}&id=${noteId}&referenceId=${
        refId ?? woid
      }&referenceType=${refType}&Uuid=${Uid}`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const EquipmentDetails = async (id,Authtoken) => {
  try {
    // const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let TOKEN =
    localStorage.getItem("authToken") === null
      ? Authtoken
      : JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/equipment/details/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const EquipmentTypelist = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/maintenance/getEquipmentTypes`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const EquipManufacturerlist = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/maintenance/getmanufacturer`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const AddNEquipment = async ({ technicianId, workOrderId, data }) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/equipment/add/${technicianId}/${workOrderId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const getAllEquipmentlist = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/equipment/list/by/customer/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const addTags = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(`${NINEPACK_URL}/tag`, data, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data;
  } catch (error) {}
};
export const DuplicateWO = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/workorder/duplicate/workorder`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const deleteTags = async (tagId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(`${NINEPACK_URL}/tag/${tagId}`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response.data;
  } catch (error) {}
};

export const GetAttchMentByID = async (
  attchId,
  step,
  workflow,
  custId,
  WorkId,
  eqId,
  techId
) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/attachment/${attchId}?workorder_step=${step}&workorder_workflow=${workflow}&customer=${custId}&workorder=${WorkId}&equipment=${eqId}&technician=${techId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const addNewEquipment = async (
  technicianId,
  workOrderId,
  workOrderWorkflowid,
  data
) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    const res = await axios({
      method: "POST",
      url:
        NINEPACK_URL +
        `/equipment/add/${technicianId}/${workOrderId}/${workOrderWorkflowid}`,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
      data: data,
    });
    return res.data;
  } catch (err) {}
};
export const WorkFlowRes = async (token, workno) => {
  try {
    const TOKEN =
      JSON.parse(localStorage.getItem("authToken")) === null
        ? token
        : JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.get(
      `${NINEPACK_URL}/workorder_workflow/workorder/${workno}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res.data;
  } catch (err) {}
};
export const HandleWorkFlowComplete = async (wowfid, wowfstepId, type) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.get(
      `${NINEPACK_URL}/workorder_workflow/upDateStep/${wowfid}/${wowfstepId}/${type}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res.data;
  } catch (err) {}
};
export const WorkflowSteps = async (Wo_Wf_Id, WoId, Status) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.get(
      `${NINEPACK_URL}/workorder_workflow/${Wo_Wf_Id}/steps/by/workOrder/${WoId}/step/required/${Status}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res?.data?.data?.workorder_workflow?.data?.workorderWorkflowResponse;
  } catch (err) {}
};
export const getFiles = async (workno, technicianId, customerId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.get(
      `${NINEPACK_URL}/workorder/files/${workno}/${technicianId}/${customerId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res.data;
  } catch (err) {}
};
export const WorkFlowAdd = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.post(`${NINEPACK_URL}/workorder_workflow`, Obj, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res.data;
  } catch (err) {}
};
export const WorkFlowNoteUpdate = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.post(
      `${NINEPACK_URL}/workorder_workflow/upDateWorkflowNote`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res.data;
  } catch (err) {}
};
export const DropDownData = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.get(`${NINEPACK_URL}/workorder_workflow/workflow`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res.data;
  } catch (err) {}
};
export const WorkflowAttachment = async (Object) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.post(`${NINEPACK_URL}/attachment`, Object, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res.data;
  } catch (err) {}
};
export const GetAttachment = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const res = await axios.get(`${NINEPACK_URL}/attachment`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return res;
  } catch (err) {}
};
export const DeleteCategory = async (workflow, wowf, workOrder) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let res = await axios.delete(
      `${NINEPACK_URL}/workorder_workflow/workflow/${workflow}/workorder_workflow/${wowf}/workorder_id/${workOrder}`,

      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return res.data;
  } catch (err) {}
};

export const updateWorkOrderStatus = async (workOrderId, workOrderStatus) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.put(
      `${NINEPACK_URL}/workorder/update/${workOrderId}/${workOrderStatus}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response;
  } catch (error) {}
};

export const getLibraryRecentItems = async (techinanId, date) => {
  try {
    let TOKEN = JSON.parse(localStorage.getItem("authToken"));

    const response = await axios.get(
      `${NINEPACK_URL}/attachment/library/${techinanId}/date/${date}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const AddNotesSteps = async (
  workid,
  woworkflowid,
  workorderstepid,
  inpObj
) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    let Res = await axios.post(
      `${NINEPACK_URL}/notes?workorder=${workid}&workorder_workflow=${woworkflowid}&workorder_step=${workorderstepid}`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Res?.data;
  } catch (error) {}
};
export const AddNotesSteps2 = async (
  workid,
  noteId,
  RefID,
  RefType,
  UUID,
  inpObj
) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    let Res = await axios.post(
      `${NINEPACK_URL}/notes?woId=${workid}&id=${noteId}&referenceId=${RefID}&referenceType=${RefType}&Uuid=${UUID}`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Res?.data;
  } catch (error) {}
};
export const UpdateWorkFLow = async (inpObj) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  try {
    let Res = await axios.put(
      `${NINEPACK_URL}/workorder_workflow/update/steps`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return Res?.data;
  } catch (error) {}
};

export const createWorkOrder = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/workorder/add/workorder`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const getCustomers = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/customer`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data;
  } catch (err) {}
};

export const getCreateAdditionalFields = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder/additional/fields`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const getTechList = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/technician/list`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data;
  } catch (err) {}
};

export const WOListGet = async (techid) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder/list/summary/${techid}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const getSearchResult = async (target) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder/filterWorkOrder/${target}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const WorkflowStepupdate = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(
      `${NINEPACK_URL}/workorder_workflow/workorder_step/update`,
      Obj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const RollECard = async (fromDate, toDate) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder/{rollEcards}/${fromDate}/${toDate}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const EquipById = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/equipment/list/by/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const EquipmentsListData = async (id) => {
  const TOKEN = JSON.parse(localStorage.getItem("authToken"));
  const response = await axios.get(
    `${NINEPACK_URL}/workorder/equipment/${id}`,
    {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    }
  );
  return response?.data;
};
export const AdditionalFieldEquipments = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/equipment/Equipmentadditional/fields`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const LibraryData = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/workorder/attachment/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const MediaAttachments = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/attachment/getbyattachment_id/${id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const Getmemorydata = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/customer/memoryboard/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const GetMemory = async (viewtype, reftype, woid) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder/fetchMemoryboard/${viewtype}/${reftype}/${woid}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const PostMemory = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.post(
      `${NINEPACK_URL}/customer/recent/view`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const CustmoerContacts = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.post(
      `${NINEPACK_URL}/customer/contact/add`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const CustomerPrimaryContact = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.post(
      `${NINEPACK_URL}/customer/primaryContactUpdate`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const WoContacts = async (CustId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/customer/contact/${CustId}/fetch`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const TechList = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(`${NINEPACK_URL}/technician/list`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data;
  } catch (err) {}
};
export const AttchmentsByTech = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/technician/${id}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const CustOpenWO = async (CustId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/customer/${CustId}/open/workorder`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const CustClosedWO = async (CustId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/customer/${CustId}/completed/workorder`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const CompanyDetailsGet = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/company/companyDetails/fetch`,

      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const AddDivisionPost = async (data) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.post(
      `${NINEPACK_URL}/company/add/division`,
      data,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const Memoryadd = async (inpObj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.post(
      `${NINEPACK_URL}/customer/recent/view`,
      inpObj,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};
export const MemoryDelete = async (rcntid, Id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/customer/delete/recent/view/${rcntid}?workorder=${Id}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const CustomerDelete = async (CustId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/customer/delete/customer${CustId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export const DeleteContact = async (Custid, ContId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.delete(
      `${NINEPACK_URL}/customer/delete/customer/${Custid}/contact/${ContId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};
export const GetPdfById = async (id) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    let response = await axios.get(
      `${NINEPACK_URL}/pdf/field/mapping/workorder/${id}`,

      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const WorkFlowPDf = async (workId, stepId, WoWfID, workFlowId) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      // pdf/pdfmapping_fields/${woId}/${stepId}/${woWorkflowId}/${workFlowId}
      `${NINEPACK_URL}/pdf/pdfmapping_fields/${workId}/${stepId}/${WoWfID}/${workFlowId}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response?.data;
  } catch (err) {}
};

export const GlobalSearch = async (test) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(
      `${NINEPACK_URL}/workorder/GlobalSearch/{query}?query=${test}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          Accept: APP_JSON,
          "Content-Type": APP_JSON,
        },
      }
    );
    return response;
  } catch (err) {}
};
