import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './EstimateModal.css'; 
import OverlayClose from "../../common/Assets/Icons/OverlayClose.svg"

const EstimateModal = ({ show, handleClose }) => {
  const navigate = useNavigate(); 

  const handleCreateNewEstTemp = () => {
   
    navigate('/CreateEstTemp'); 
  };

  const handleCreateNewEst = () =>{
    navigate('/CreateNewEst');
  }

  if (!show) return null;

  return (
    <div className="modal-backdrop" onClick={handleClose}>
      <div className="modal-container" onClick={e => e.stopPropagation()}>
        <div className="modal-header modal-bg-color">
          <h3>Add Estimate</h3>
          <button onClick={handleClose} className="close-btn">
            <img src={OverlayClose} alt="Close" />
          </button>
        </div>
        
        <div className="modal-body">
          {/* Main button for creating a new estimate template */}
          <button className="main-btn hover-effect" onClick={handleCreateNewEstTemp}>
            Create New Estimate Template
          </button>

          {/* Two secondary buttons side by side */}
          <div className="action-buttons model_footer">
            <button className="secondary-btn hover-effect">Create from Template</button>
            <button className="secondary-btn hover-effect" onClick={handleCreateNewEst}>Create New Estimate</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateModal;
