import React, { useEffect, useRef, useState } from "react";

import { Modal, Button, ModalFooter } from "react-bootstrap";
import QRCode from "../../../common/Assets/Icons/QRCode.svg";
import CryptoJS from "crypto-js";
import link_light from "../../../common/Assets/Icons/link_light.svg";
import send_main from "../../../common/Assets/Icons/send_main.svg";
import OverlayClose from "../../../common/Assets/Icons/OverlayClose.svg";
import Download_step from "../../../common/Assets/Icons/Download_step.svg";
import qrcopy from "../../../common/Assets/Icons/qrcopy.svg";
import mail from "../../../common/Assets/Icons/mail.svg";
import { TechList } from "../../../services/WorkOrdersService";
import { SHARE_MODAL_VAL } from "../Values";
import Scannar from "./Scannar";
import { inviteshare } from "../../../services/AuthService";
import { LOGIN_CONST } from "../Constants";
import ProgressBar from "../../navigation/ProgressBar";

const ShareWoModal = (props) => {
  const [istechList, setIsTechList] = useState([]);
  const [filteredtechList, setFilteredTechList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [LocPath, setLocPath] = useState(0);
  const [isCopy, setIsCopy] = useState(false);
  const [showQR, setShowQR] = useState(false); 
  useEffect(() => {
    let userId = localStorage.getItem("userId");
    const TechPin = JSON.parse(localStorage.getItem("techpin"));
    let CompanyId = JSON.parse(localStorage.getItem("CompanyCode"));
   
    const secretKeyforPin = SHARE_MODAL_VAL.secretKey;
    const encryptedPin = CryptoJS.AES.encrypt(
      TechPin,
      secretKeyforPin
    ).toString();
    const encodedPass = encryptedPin.replace(/\//g, "_").replace(/\+/g, "-");
    // setLocPath(
    //   `${window.location.protocol + "//" + window.location.host}/#/${props?.path}
    //   /${props?.woid}/${props?.custid}
    //   /${userId}/${encodedPass}/${CompanyId}/1`
    // );
    setLocPath(
      `${window.location.protocol}//${window.location.host}/#/${props?.path}/${props?.woid}/${props?.custid}/${userId}/${encodedPass}/${CompanyId}/1`
    ); 
  }, [props]);

  const AllTech = async () => {
    const technicianid = JSON.parse(localStorage.getItem("technicianid"))
    setIsProgress(true)
    const res = await TechList();
    if(res?.success){
      let removedData =  res?.data?.filter((i)=>i?.id  !== technicianid)
      setIsTechList(removedData);
      setIsProgress(false)
  }
   
  };
  useEffect(() => {
    AllTech();
  }, []);

  useEffect(() => {
    const filteredList = istechList?.filter(
      (technician) => 
        technician?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        technician?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTechList(filteredList);
    if (!props?.Open) {
      setSearchTerm(""); 
    }
  }, [searchTerm, istechList, props?.Open]);
const [showOverlay, setShowOverlay] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [isProgress, setIsProgress] = useState(false);
  const clickHandler = async(textValue,e, customer) => {
    let Name = JSON.parse(localStorage.getItem("userName"))
    e.stopPropagation();
    const formattedLink = `<a href="${textValue}" target="_blank">${textValue}</a>`
    let obj =
    {
      email:"test@9packsoftware.com",
      content:`
      Hello ${customer?.name},
      <br /><br />
      We would like to invite you to participate Work Order #${props?.woid}. Please review the details of the work order at your earliest convenience.
      <br /><br />
      Best regards,<br />
      ${Name},<br /><br />
      ${formattedLink}`
    }
    if (!obj?.content || !obj?.email) {
      setErrorMessage(LOGIN_CONST.ERROR0);
      return;
    }
    try {
      setIsProgress(true)
      const response = await inviteshare({ 
        email_id: obj?.email,
        content:obj?.content
       });
      
      if (response?.success) {
         setShowOverlay(true);
         setIsProgress(false)
         props.Close();

      }
       else {
        setErrorMessage(LOGIN_CONST.ALERT);
        setShowOverlay(false);
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred.'); 
      setShowOverlay(false); 
    }  
  };
  const HandleCopyText = (textValue) => {
    navigator?.clipboard?.writeText(textValue);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  };
  const handleGenerateQR = () => {
    setShowQR(true); 
    props.Close();
  };
  const handleCloseQR = () => {
    setShowQR(false); 
  };
  const qrRef = useRef();

  const downloadQR = () => {
    const canvas = qrRef?.current?.querySelector('canvas');
    const url = canvas?.toDataURL("image/png");
    const a = document?.createElement('a');
    a.href = url;
    a.download = 'qr-code.png';
    a.click();
  };
  const handleClick = () => {
    const formattedLink = LocPath.startsWith("http") ? LocPath : `https://${LocPath}`;
    const mailtoLink = `mailto:example@domain.com?subject=InviteLink&body=${formattedLink}`;
    window.location.href = mailtoLink;
  };
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  return (
    <div>
      <Modal size="md" centered show={props.Open} onHide={props.Close}>
      <Modal.Header className="modal-bg-color " style={{ height: "40px" }}>
      <div className="csm-modal-header-text line-align-bet">
            Share
            <img
              onClick={props.Close}
              className=" cursor-p"
              src={OverlayClose}
              alt=""
            />
          </div>
          </Modal.Header>
          {isProgress === true ? (
          <div className="progress-bar "
          style={{height:"430px"}}>
            <ProgressBar />
          </div>
        ) : (
        <Modal.Body className="modal-body-csm">
         
          <div className="invite-job mt-2">Invite to job</div>

          <div className="line-align-bet mt-2">
            <input
              className="inpt-csm"
              placeholder="Email/Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Button
              className="inpt-csm-btn"
              color="red"
              appearance="primary"
              size="sm"
            >
              Send invite
              <img
                src={send_main}
                className="send-image mb-1 ml-2"
                alt="send"
                height={18}
                width={22}
              />
            </Button>
          </div>
          <div className="recommand-people mt-3">
            <div className="recommand-people-heading">Recommended People</div>
            <div className="recommand-people-list">
              {filteredtechList?.length > 0 ? (
                filteredtechList?.map((technician) => (
                  <div className="recommand-customer" key={technician?.id}>
                    <img
                      src={technician?.avatar}
                      alt="person icon"
                      height={32}
                      width={32}
                    />
                    <div className="recommand-customer-details">
                      <span className="customer-name ">{technician?.name}</span>
                      <span className="customer-email">
                        {technician?.email}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={(e) => clickHandler(LocPath,e, technician)}
                      className="invite-button"
                    >
                      Invite
                    </button>
                  </div>
                ))
              ) : (
                <div className="nodata-text">No contacts found</div>
              )}
            </div>
            
          </div>
        </Modal.Body>
        )}
        <ModalFooter className="modal-footer-styles">
        <div className="btn-section">
              <span ref={qrRef} onClick={handleGenerateQR} className="share-btn-qr cursor-p">
                Generate QR Code <img src={QRCode} alt="" />
              </span>
              <span onClick={() => HandleCopyText(LocPath)}  className="share-btn cursor-p">
               {isCopy ? "link Copied" : "Copy Link"} <img src={link_light} alt="" />
              </span>
              <span onClick={handleClick} className="share-btn cursor-p">
                Email <img className=" mb-1" src={mail} alt="" />
              </span>
            </div>
            </ModalFooter>
      </Modal>
      <Modal   size="sm" centered show={showQR} onHide={handleCloseQR}>
        <Modal.Header className="qr-mod-head">
          Generte QR Code
          <img
            onClick={handleCloseQR}
            className="cursor-p"
            src={OverlayClose}
            alt="close"
          />
        </Modal.Header>
        <Modal.Body className="text-center qr-mod-body">
          <Scannar ScanData={LocPath} />
          <input readOnly className=" mt-1 ml-1 shrinpthov inpt-det cursor-p" style={{width:"260px"}} value={LocPath} />
          <div className=" d-flex justify-content-end mt-1">
        <span onClick={() => HandleCopyText(LocPath)}  className=" mx-2 share-btn-qrcode cursor-p">
            {isCopy ? "Copied" : "Copy"} <img src={qrcopy} alt="" />
          </span>
          <span onClick={downloadQR} className="share-btn-qrcode cursor-p">
            Download <img src={Download_step} alt="" />
          </span>
        </div>
        </Modal.Body>
      </Modal>
      {showOverlay && (
          <div className="overlay">
            <div className="popup">
              <div className="heading-pop">{LOGIN_CONST.POP}</div>
               <div className="text-fit">{LOGIN_CONST.SHR_TEXT_FIT}</div>
              <div className="close-button-container">
                <button onClick={handleCloseOverlay}>Close</button>
              </div>
            </div>
          </div>
        )}    
    </div>
  );
};

export default ShareWoModal;
