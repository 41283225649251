// import { RiSortDesc } from "react-icons/ri";
// import { AiOutlineStar } from "react-icons/ai";
// import moment from "moment/moment";
// import { TABLE_ÍCON_SIZES, TABLE_ITEMS_COLORS } from "../Values";
// import { TABLE_COLUMNS_NAME } from "../Constants";

// export const TABLE_COLUMNS = [
//   {
//     name: (
//       <div>
//         <RiSortDesc size={TABLE_ÍCON_SIZES.SORT_ICON_SIZE} />
//       </div>
//     ),
//     selector: (row) => (
//       <AiOutlineStar
//         size={TABLE_ÍCON_SIZES.STAR_ICON_SIZE}
//         style={{ color: TABLE_ITEMS_COLORS.TABLE_STAR_COLOR }}
//       />
//     ),
//     width: "50px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_WORK_ORDER} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => (
//       <div className="table-woid">
//         {row.id}
//       </div>
//     ),
//     width: "150px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_DATE} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => (
//       <div className="table-date">
//         {moment(row.request_date).format("MM/DD/YYYY")}
//       </div>
//     ),
//     width: "109px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_STATUS}
//         <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => <div className="table-date">{row.status}</div>,
//     width: "109px",
//   },
//   {
//     name: (
//       <div className="table-head-cells2">
//         {TABLE_COLUMNS_NAME.TABLE_CUSTOMER}
//         <RiSortDesc className="m-1" />
//       </div>
//     ),
//     selector: (row) => <div className="table-date">{row.Customer.name}</div>,
//     width: "176px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_PRIMARY_CONTACT} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => <div className="table-date">{row.Customer.phone}</div>,
//     width: "171px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_PHONE_NUMBER} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => <div className="table-date">{row.Customer.phone}</div>,
//     width: "160px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_PRIMARY_BRANCH} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => row.name,
//     width: "165px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_LEAD_TECH} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => row.year,
//     width: "125px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_ADDIONAL_TECHS} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => row.title,
//     width: "171px",
//   },
//   {
//     name: (
//       <div className="table-head-cells2">
//         {TABLE_COLUMNS_NAME.TABLE_ADDERSS} <RiSortDesc className="m-1" />
//       </div>
//     ),
//     selector: (row) => (
//       <div className="table-date">{row.Customer.address.street_1}</div>
//     ),
//     width: "230px",
//   },
//   {
//     name: (
//       <div className="table-head-cells2">
//         {TABLE_COLUMNS_NAME.TABLE_CITY} <RiSortDesc className="m-1" />
//       </div>
//     ),
//     selector: (row) => (
//       <div className="table-date">{row.Customer.address.city}</div>
//     ),
//     width: "123px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_STATE} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => (
//       <div className="table-date">{row.Customer.address.state}</div>
//     ),
//     width: "102px",
//   },
//   {
//     name: (
//       <div className="table-head-cells">
//         {TABLE_COLUMNS_NAME.TABLE_TAGS} <RiSortDesc />
//       </div>
//     ),
//     selector: (row) => row.title,
//     width: "85px",
//   },
// ];
export const TABLE_CUSTOMSTYLES = {
  header: {
    style: {
      maxHeight:'56px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "white",
      borderBottomColor: "black",
      minHeight:"30px",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "gray",
      },
      padding: "0",
      margin: "0", 
    },
  },
  // rows: {
  //   style: {
  //     minHeight: "30px",
  //     "&:not(:last-of-type)": {
  //       borderBottomStyle: "none",
  //       borderBottomWidth: "0",
  //       borderBottomColor: "none",
  //     },
  //   },
  // },
  rows: {
    style: {
      minHeight: "30px",
    },
  },
  cells: {
    style: {
      display: "flex",
      padding: "0",
      margin: "0",
      justifyContent: "center",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "gray",
      },
    },
  },
};
