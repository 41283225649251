import React from "react";
import { Modal } from "react-bootstrap";
import "./NoteEditModal.css";
import Close from "../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import moment from "moment";
import {
  NOTES_ADD_CONSTANTS,
  TAGS_MODAL_VALUES,
} from "../../common/components/Constants";
import ProgressBar from "../../common/navigation/ProgressBar";

export default function NoteEditModal(props) {
  return (
    <>
      <Modal show={props.isOpen} onHide={props.isClose} centered>
        <Modal.Header className="line-align-even  w-100 modal-bg-color modal-header-height">
          <div className="note-edit-text ">
            {props.isEdit === `${TAGS_MODAL_VALUES.DELETE}`
              ? `${TAGS_MODAL_VALUES.DELETE}`
              : props.isEdit === `${TAGS_MODAL_VALUES.EDIT}`
              ? `${TAGS_MODAL_VALUES.EDIT}`
              : `${TAGS_MODAL_VALUES.HISTORY}`}{" "}
            {NOTES_ADD_CONSTANTS.NOTE} - Work Order#{props.WorkId}
          </div>
          <div className="img-cursor" onClick={props.isClose}>
            <img src={Close} alt="close" className="modal-header-icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="p-1">
          {props?.isLoading ? (
            <div className="progress-position">
              <ProgressBar />
            </div>
          ) : (
            <>
              {props.isEdit === `${TAGS_MODAL_VALUES.DELETE}` ? (
                <div className="">
                  <div className="delete-area-styles mx-2">
                    {" "}
                    {NOTES_ADD_CONSTANTS.DELETE_NOTE}'{`${props?.noteTitle}`}'
                    ?
                  </div>
                </div>
              ) : props.isEdit === "Edit" ? (
                <div className="mt-3 mx-3" style={{ height: "340px" }}>
                  {props?.isKey === "steps" ? (
                    ""
                  ) : (
                    <div>
                      <span className="note-title-text">
                        {NOTES_ADD_CONSTANTS.NOTE_TITLE}
                      </span>
                      <input
                        className="Note-edit-inp-styles"
                        value={props?.noteTitle}
                        name="title"
                        onChange={(e) => props.data2(e)}
                      />
                    </div>
                  )}
                  <div className="mt-1">
                    <span className="note-title-text">
                      {NOTES_ADD_CONSTANTS.NOTE_DESCRIPTION}
                    </span>
                    <textarea
                      style={{ outline: "none" }}
                      className=" text-area-styles mt-1"
                      value={props?.noteValue}
                      name="content"
                      onChange={(e) => props.data2(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="mt-2" style={{ overflow: "auto" }}>
                  <div className="history-note">
                    <div className="history-owner">{props?.data?.title}</div>
                    <div className="">
                      <span className="history-created">
                        {NOTES_ADD_CONSTANTS.CREATED_NOTE}
                      </span>
                      <span className="history-date">
                        {" "}
                        {moment(props?.data?.date_created).format(
                          NOTES_ADD_CONSTANTS.EDIT_NOTE_DATE
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="delete-area-styles mt-2">
                    {" "}
                    {props?.data?.content}
                  </div>
                </div>
              )}

              {props.isEdit === "History" ? (
                ""
              ) : (
                <div className="mt-2 modal-bottom-styles-edit-notes model_footer">
                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={
                      props.isEdit === "Delete"
                        ? props.isDelete
                        : props.isEditVal
                    }
                  >
                    {props.isEdit === "Delete" ? "Delete" : "Save"}
                  </div>

                  <div
                    className="modal-footer-save-cancel-btns"
                    onClick={props.isClose}
                  >
                    {NOTES_ADD_CONSTANTS.NOTE_CANCEL}
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
