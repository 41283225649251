import React, { useState } from "react";
import "./AdditionalFieldsEquipments.css";
import equipadd from "../../../../common/Assets/Icons/equipadd.svg"
import moment from "moment";
import ReactDatePicker from "react-datepicker";
function AdditionalFieldsEquipments({
  isErr,
  isProgress,
  additionalFields,
  additionalFields1,
  eqpaddflds,
  isCondition,
  onAdditionalFieldChange,
  isAdditionalFieldsEmpty,
  additionalFieldsErrors,
}) {
  const getErrorInfo = (id) => {
    if (isAdditionalFieldsEmpty) {
      const isExisted = additionalFields.find((i) => i.field_id === id);

      if (
        isExisted.required &&
        (isExisted.value === null || isExisted.value.length === 0)
      ) {
        return "abc";
      }
    }
  };
  const [isequipadd, setisequipadd] = useState(true)
  const handleequipadd = () => {
    setisequipadd(!isequipadd)
  }

  return (
    <div className="mt-1 rightside_box">
      <span onClick={() => handleequipadd()} className="sett-crd-hdr-txt cursor-p">
        <img className=" cursor-p " onClick={() => handleequipadd()} alt="" src={equipadd} />{" "}
        <span className=" cursor-p mt-1">Additional Fields</span>
      </span>
      <span className="flt-r cursor-p">
      </span>
      {isProgress === true ? (
        <div className="progress-bar">
        </div>
      ) : (
        isequipadd && (
          <div className="">
            {additionalFields1?.map((it) => (
              <div className="txt-align-c">
                <div className="d-flex mt-2">
                  {it?.required ? (
                    <span
                      className="lbl-star"
                      style={{ color: "red", fontSize: "20px" }}
                    >
                      *
                    </span>
                  ) : null}
                  <span className="lbl-xtr-fld-view ">{it?.packcolumn}:</span>
                  {it?.control_type === "date" ? (
                    <ReactDatePicker
                      className={
                        isCondition === "equipdetails"
                          ? "custom-edit-datepicker ef-inpt-hover cursor-p"
                          :
                          isErr.length > 0 &&
                            it?.value?.length <= 0 ? "custom-wo-datepicker2 cursor-p " : "custom-wo-datepicker cursor-p"
                      }
                      placeholderText="Select Date"
                      selected={
                        it?.value &&
                          moment(it.value, "DD-MM-YYYY", true).isValid()
                          ? moment(it.value, "DD-MM-YYYY").toDate()
                          : null
                      }
                      onChange={(e) =>
                        isCondition !== "equipdetails" && onAdditionalFieldChange("value",
                          e, it?.field_id, it?.control_type, e?.target?.value, additionalFields1)
                      }
                      onFocus={(e) => (e.target.readOnly = true)}
                      onKeyDown={(e) => e.preventDefault()}
                      disabled={isCondition === "equipdetails" || window?.location?.href?.split("/")[10] === "1"}
                    />
                  ) : (
                    <div>
                      <input
                        // style={{
                        //   border:
                        //     isCondition === "equipdetails" &&
                        //     "", pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"
                        // }}
                        style={{
                          border:
                          isCondition !== "equipdetails" &&
                             "1px solid #CCC"
                        }}
                        type={
                          // it?.control_type === "numeric" ||
                          // it?.control_type === "integer"
                          //   ? "number"
                          //   : it?.control_type
                          it?.control_type
                        }
                        className={`${(isErr?.length > 0 && it?.value?.length <= 0)
                            ? "inpt-xtr-fld-add-2 pace-hold ef-inpt-hover"
                            : "inpt-xtr-fld-add pace-hold ef-inpt-hover"
                          } ${it?.control_type === "string" || it?.control_type === "numeric" || it?.control_type === "integer" ? "" : ""} ${getErrorInfo(it?.field_id)}`}
                        value={it?.value}
                        placeholder={
                          it?.control_type === "string"
                            ? "Enter Text"
                            : it?.control_type === "numeric"
                              ? "Enter Numeric Value"
                              : "Enter Numbers"
                        }
                        onChange={(e) =>
                          isCondition !== "equipdetails" && onAdditionalFieldChange("value",
                            e, it?.field_id, it?.control_type, e?.target?.value, additionalFields1)
                        }
                        maxLength={it?.characterlength}
                      />
                    </div>
                  )}
                  <span className="mx-2"></span>
                </div>
                {getErrorInfo(it.field_id) === "abc" ? (
                  <span className="error-msg-ipt-add-eqp2">
                    enter {it?.packcolumn}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        )

      )}
    </div>
  );
}

export default AdditionalFieldsEquipments;
