import React, { useState,} from "react";
import "./FgtPassword.css"
import LoginHeader from '../../framework/header/HeaderLogin/LoginHeader'
import LoginFooter from '../../framework/footer/LoginFooter'
import { ChangePass, putForgetpaswd,} from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom'; 
import { LOGIN_CONST } from "../../components/Constants";
// import { userSetUp } from "../../../services/SettingsServices";
import AlertModal from "./Reset Password/AlertModal";



function FgtPassword() {
  const navigate = useNavigate(); 
  const [showOverlay, setShowOverlay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fgtinpts,setFgtinpts] = useState(
    {
      emailOrPhone:"",
      username:""
    }
  );
  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setFgtinpts((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setErrorMessage("")
  };

  const validateEmailOrPhone = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{10}$/; 
    if (fgtinpts?.emailOrPhone.trim().length === 0) {
      setErrorMessage(''); 
      return false; 
    } if (emailPattern.test(fgtinpts?.emailOrPhone) || phonePattern.test(fgtinpts?.emailOrPhone)) {
      setErrorMessage('');
      return true;
    }
    setErrorMessage('Invalid email or phone number format.');
    return false;
  };
  const handleSendClick = async (action) => {
      // const userdata = await userSetUp()
      // const fgtmail = userdata?.data?.find((user) => user?.user_name === fgtinpts?.username);
      if (!fgtinpts?.username || !fgtinpts?.emailOrPhone) {
        setErrorMessage(LOGIN_CONST.ERROR0);
        return;
      }
      // if (fgtinpts?.username !== fgtmail?.user_name) {
      //   setErrorMessage(LOGIN_CONST.ERROR2);
      //   return;
      // }
      // if (fgtinpts?.emailOrPhone !== fgtmail?.email_id) {
      //   setErrorMessage(LOGIN_CONST.ERROR1);
      //   return;
      // }
    if (fgtinpts?.emailOrPhone && fgtinpts?.username && validateEmailOrPhone()) {
      if(action === "Reset"){
      HandleInvite(fgtinpts?.emailOrPhone,fgtinpts?.username)
      setModal(true);
      setIsProgress(true)
      }
      else if(action === "Send"){
      try {
        const response = await putForgetpaswd({ user_email: fgtinpts?.emailOrPhone });
        if (response) {
           setShowOverlay(true);
        }
         else {
          setErrorMessage(LOGIN_CONST.ALERT);
          setShowOverlay(false);
        }
      } catch (error) {
        setErrorMessage(error.message || 'An error occurred.'); 
        setShowOverlay(false); 
      }}
    }
  };

  const[isProgress,setIsProgress] = useState(false)  
const [modal,setModal] = useState(false);
const [modalMessage,setModalMessage] = useState("")

  const HandleInvite = async(Email,userName)=>{
    const encryptedEmail = btoa(Email); 
    const encryptedName = btoa(userName); 
    let obj = {
    
      path: `${window?.location?.origin}/#/resetPassword/${encryptedName}/${encryptedEmail}`
    } 
    try{
      let newObj = {
        content: `<a href="${obj?.path}" target="_blank">${obj?.path}</a>`
     }
      const response = await ChangePass(Email,userName,newObj);
       if(response){
        setIsProgress(false)
        setModalMessage(LOGIN_CONST.ALERT_MSG)
       }
       else{
        setModalMessage("some error occured")
        setIsProgress(false)
       }
    }
    catch (error){
      setModalMessage(`${error}`)
        setIsProgress(false)
    }
  }
  
  const handleCloseOverlay = () => {
    setShowOverlay(false);
    navigate('/'); 
  };
  return (
    <div className="page-container">
      {showOverlay && <div className="dimmed-background"></div>}
      <div className="content-wrap">
        <LoginHeader/>
        <div className="App">
        
          <div className="fgtpaswd-form mt-1">
          <div className="login_box fgtpwd_box">
            <div className="fgtpaswd-hd-main">Forgot Password</div>
            {/* <div className="fgtpaswd-hd-sub-main mt-4">Registered Email*</div> */}
            <input 
              className="fgtpaswd-input mt-2"
              type="text"
              // id={inputId} 
              name="username"
              placeholder={LOGIN_CONST.ERROR2}
              value={fgtinpts?.username} 
              onChange={handleInputChanges}  
            />
            <input 
              className="fgtpaswd-input mt-2"
              type="text"
              // id={inputId} 
              name="emailOrPhone"
              placeholder={LOGIN_CONST.ERROR1}
              value={fgtinpts?.emailOrPhone} 
              onChange={handleInputChanges}  
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="d-flex flex-column mt-2">
            <div className="button-container1">
              <button type="button" onClick={()=>{
                 handleSendClick("Send");
              }}>Send</button>
              <button type="button" onClick={()=>{handleSendClick("Reset")}}>Reset Password</button>
            </div>
            <div onClick={()=>navigate(-1)} style={{ alignSelf: 'flex-end',marginRight:"8px" }} className=" align-content-end justify-content-end align-items-end cursor-p">Cancel</div>
            </div>
          </div>
          </div>
        </div>
        {showOverlay && (
          <div className="overlay">
            <div className="popup">
              <div className="heading-pop">{LOGIN_CONST.POP}</div>
              <div className="text-fit">{LOGIN_CONST.TEXT_FIT} {fgtinpts?.emailOrPhone}</div>
              <div className="close-button-container">
                <button onClick={handleCloseOverlay}>Close</button>
              </div>
            </div>
          </div>
        )}      
      </div>   
      <LoginFooter/>
      <AlertModal 
      isOpen={modal}
      message={modalMessage}
      message_head={LOGIN_CONST.ALERT_HEAD}
      onClose={()=>{setModal(false)}}
      isProgress={isProgress}
      />
    </div>    
  );
}

export default FgtPassword;
