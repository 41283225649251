import { PDFDocument } from "pdf-lib";

export const HandlePdfJson = async (data) => {
  try {
    const pdfDoc = await PDFDocument.load(data);
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    const formFields = []; // Declare formFields array here
    const pages = pdfDoc.getPages(); // Retrieve all pages of the PDF
    // Iterate over each field to extract information
    const fieldData = fields.map((field) => {
      const fieldName = field.getName();
      const fieldType = field.constructor.name;
      let fieldValue = null;
      let coordinates = {};
      let pageIndex = "Unknown"; // Start with "Unknown"

      try {
        const widgets = field.acroField.getWidgets();

        if (widgets.length > 0) {
          const widget = widgets[0]; // Work with the first widget

          // Get the widget's rectangle (coordinates)
          const rect = widget.getRectangle();
          coordinates = {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          };


          // Now we try to find the page that contains this widget
          for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            const mediaBox = page.getMediaBox();


            // Check if the widget rectangle is within the bounds of the page's media box
            if (
              rect.x >= mediaBox.x &&
              rect.y >= mediaBox.y &&
              rect.x + rect.width <= mediaBox.x + mediaBox.width &&
              rect.y <= mediaBox.y + mediaBox.height // Make sure this is correct
            ) {
              pageIndex = i; // Set the page index to the current page
              break; // No need to check further pages
            } else {
            
            }
          }
        }

        // Handle different field types
        if (fieldType === "PDFTextField") {
          fieldValue = field.getText(); // For text fields
        } else if (fieldType === "PDFCheckBox") {
          fieldValue = field.isChecked() ? "Checked" : "Unchecked"; // For checkboxes
        } else if (fieldType === "PDFRadioGroup") {
          fieldValue = field.getSelected(); // For radio groups
        } else if (fieldType === "PDFDropdown") {
          fieldValue = field.getSelected(); // For dropdowns
        }

        // Push the parsed field into formFields
        formFields.push({
          uuid: fieldName,
          name: "",
          value: fieldValue,
          type: fieldType,
          coordinates,
          page: pageIndex, // Store the determined page index
        });
      } catch (error) {
        // Handle rich text fields or other unsupported field types
        if (
          error.message.includes("Reading rich text fields is not supported")
        ) {
          console.warn(`Skipping rich text field: ${fieldName}`);
          formFields.push({
            uuid: fieldName,
            name: "",
            value: "Rich text field (unsupported)",
            type: fieldType,
            coordinates,
            page: pageIndex, // Include page number even for unsupported fields
          });
        } else {
          console.error(`Error reading field: ${fieldName}`, error);
        }
      }

      // Return the parsed field (for fieldData array)
      return {
        uuid: fieldName,
        name: "",
        value: fieldValue,
        type: fieldType,
        coordinates,
        page: pageIndex, // Include page information in the final returned object
      };
    });

    // Log fields for debugging purposes

    return fieldData; // Return all the field data
  } catch (error) {
    console.error("Error loading PDF or processing fields:", error);
    return [];
  }
};
