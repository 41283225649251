import React, { useState } from "react";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import CommonFooter from '../../common/framework/footer/CommonFooter';
import delw from "../../common/Assets/Icons/delW.svg";
import save from "../../common/Assets/Icons/save.svg";
import CreateEstCard from "./CreateEstCard";
import './CreateEstTemp.css';
import CreateEstSidebar from "./CreateEstSidebar";

const CreateEstTemp = () => {
  const [isExpand, setIsExpand] = useState("");
  const [isProgress, setIsProgress] = useState(true);
  const [over, setOver] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleExpand = (val) => {
    setIsExpand(val);
  };

  return (
    <div>
      <CommonHeader />
      <div className="d-flex justify-content-between line-align-bet mt-2 w-100 ">
        <div className="d-flex gap-5">
          <span className="TableText">Create Estimate Template</span>{" "}
          <span className="status-font button-style mt-1">Creating</span>
        </div>
        <div className="d-flex  align-items-center">
        <div className="header-right  d-flex gap-3 text-right mr-2">
          <div className="cost-item">
            <span>Total Cost</span>
            <div>$3980.08</div>
          </div>
          <div className="cost-item">
            <span>Subtotal</span>
            <div>$3980.08</div>
          </div>
        </div>
          <button className="temp-btn">
            Discard
            <img src={delw} alt="" style={{ marginLeft: '4px' }}/>
          </button>
          <button
            className="temp-btn mx-2 p-4"
            onClick={() => setShowModal(true)}
          >
            Save
            <img src={save} width={12} style={{ marginLeft: '4px' }}/>
          </button>
        </div>
      </div>

      <Row className="w-100" >
        {isExpand === "expand" ? null : (
          <Col
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            style={{ pointerEvents: isProgress === true ? "none" : "auto" }}
            xs={isExpand === "expand" ? 12 : 2}
            md={isExpand === "expand" ? 12 : 2}
            lg={isExpand === "expand" ? 0 : 2}
            className="workOrder-col1"
          >
           <CreateEstSidebar/>
          </Col>
        )}

        <Col
          xs={isExpand === "expand" ? 12 : 10}
          md={isExpand === "expand" ? 12 : 10}
          lg={isExpand === "expand" ? 12 : 10}
          className="workOrder-col2"
        >
          <>
            <span
              className={
                isExpand === "expand" || over
                  ? "backbtn-hvr2 p-0 m-0"
                  : "backbtn-hvr p-0 m-0"
              }
            >
              {isExpand === "expand" ? (
                <MdChevronRight size={25} onClick={() => handleExpand("")} />
              ) : (
                <MdChevronLeft
                  size={25}
                  onClick={() => handleExpand("expand")}
                />
              )}
            </span>
            <div className="mx-2 mt-2 card p-1 mb-5 w-100" >
              <CreateEstCard/>
            </div>
          </>
        </Col>
      </Row>
      <div  style={{position:'sticky',bottom:0}}>  
       <CommonFooter/>
      </div>
   
    </div>
  );
};

export default CreateEstTemp;
