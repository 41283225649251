import moment from "moment/moment";

export const WorkOrderSearch = (
  item,
  data,
  startDate,
  endDate,
  type,
  statusObj
) => {
  let resdata = data;
  if (type === "Table") {
    if (statusObj?.length > 0) {
      resdata = resdata?.filter((i) =>
        statusObj?.some((val) =>
          i?.status?.toUpperCase()?.includes(val?.status?.toUpperCase())
        )
      );
    }
    if (item?.isWoName?.length > 0) {
      resdata = resdata.filter(
        (i) =>
          i?.name?.toUpperCase().includes(item?.isWoName?.toUpperCase()) ||
          i?.id?.includes(item?.isWoName)
      );
    }
    if (item?.isCustName?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.customer_name
          ?.toUpperCase()
          .includes(item?.isCustName?.toUpperCase())
      );
    }

    if (item?.isCity?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.city?.toUpperCase().includes(item?.isCity?.toUpperCase())
      );
    }

    if (item?.isTags?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.tags?.some((tag) =>
          tag?.tag_data?.toUpperCase().includes(item?.isTags?.toUpperCase())
        )
      );
    }
    if (startDate !== null && endDate !== "Invalid date") {
      resdata = resdata?.filter((item) => {
        const eventDate = moment(item?.date);
        return (
          eventDate.isSameOrAfter(startDate, "day") &&
          eventDate.isSameOrBefore(endDate, "day")
        );
      });
    }
    return resdata;
  } else {
    if (type?.length > 0) {
      resdata = resdata.filter((i) =>
        type.some((t) =>
          i?.status?.toUpperCase().includes(t?.status?.toUpperCase())
        )
      );
    }
    if (item?.isWoName?.length > 0) {
      resdata = resdata.filter(
        (i) =>
          i?.name?.toUpperCase().includes(item?.isWoName?.toUpperCase()) ||
          i?.id?.includes(item?.isWoName)
      );
    }

    if (item?.isCustName?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.Customer?.name
          ?.toUpperCase()
          .includes(item?.isCustName?.toUpperCase())
      );
    }

    if (item?.isCity?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.Customer?.address?.city
          ?.toUpperCase()
          .includes(item?.isCity?.toUpperCase())
      );
    }

    if (item?.isTags?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.tags?.some((tag) =>
          tag?.name?.toUpperCase().includes(item?.isTags?.toUpperCase())
        )
      );
    }
    if (item?.isCustName?.length > 0) {
      resdata = resdata.filter((i) =>
        i?.Customer?.name
          ?.toUpperCase()
          .includes(item?.isCustName?.toUpperCase())
      );
    }
    if (startDate !== null && endDate !== "Invalid date") {
      resdata = resdata?.filter((item) => {
        const eventDate = moment(item?.request_date);
        return (
          eventDate.isSameOrAfter(startDate, "day") &&
          eventDate.isSameOrBefore(endDate, "day")
        );
      });
    }
    return resdata;
  }
};
