import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Spinners() {
    return (
        <>
            <Spinner animation="border"   variant="primary" />
            <div>Adding Tags...</div>
        </>
      )
}



