
import React from "react";
import "./Division.css"; // Import the CSS file for styling

function Division(props) {
  return (
    <>
      <div className="division-card clp_body">
        {props?.DivisionList?.map((division) => (
          <div  style={{
           
            backgroundColor:
              props?.selectedDivisionId === division?.division_id ? "#D1CACA" : "white", // Change color if selected
            transition: "background-color 0.3s ease", // Smooth transition
          }}
            key={division.id}
            className="division-row "
            onDoubleClick={() => props?.HandleEditDivision("edit", division)}
          >
            <div className="division-details">
              <div className="divisn-name-style">{division.name}</div>
              <div className="division-info">
                <div>
                  <span className="email-span">Email:</span> {division?.email}
                </div>
                <div>
                  <span className="email-span">Phone:</span>{" "}
                  {division?.telephone}
                </div>
                <div>
                  <span className="email-span">Address:</span>{" "}
                  {division?.address}
                </div>
                {/* <div>
                  <span className="email-span">Primary Contact:</span>{" "}
                  {division?.primaryContact}
                </div> */}
              </div>
            </div>
            <div className="status-badge">{division?.status}</div>
          </div>
        ))}
      </div>
     
    </>
  );
}

export default Division;
