import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReferenceId } from "../../common/components/EnumRefType";
import "./WorkOrdersStyles.css";
// import filterimg from "../../common/Assets/Icons/Adjt.svg";
import Table from "../../common/Assets/Images/Table_lighttable.png";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import {
  workOrdrList,
  GetAttchMentByID,
  addTags,
  updateWorkOrderStatus,
  deleteTags,
  getWorkOrderById,
 
} from "../../services/WorkOrdersService";
import ProgressBar from "../../common/navigation/ProgressBar";
import { Error } from "../../common/components/Tags";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import CustomerModal from "../../common/components/customermodal/CustomerModal";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import AttachmentModal from "./AttachmentModal";
import PopUp from "./WorkOrderDetails/Pop-Up/PopUp";
import {
  STATUS_TODO,
  SUCCESS_STATUS,
  STATUS_INPROGRESS,
  WORK_ORDER_CONSTANTS,
  TAGS_MODAL_VALUES,
} from "../../common/components/Constants";
import { AppGlobalContextUpdate } from "../../common/context/AppGlobalContext";
import AddTag from "./addtag/AddTag";
import DeleteTags from "./addtag/DeleteTags";
import WorkOrderCard from "./WorkOrderCard";
import FilterModal from "../../common/components/workordertable/FilterModal";
import { Col, Row } from "react-bootstrap";
import WorkOrderSideBar from "./WorkOrderSideBar";
import { WorkOrderSearch } from "./WorkOrderSearch";
import moment from "moment/moment";
import { woStsSettFldStpGt } from "../../services/SettingsServices";
import { CustOpenWO } from "../../services/WorkOrdersService";
import { CustClosedWO } from "../../services/WorkOrdersService";



function WorkOrders(props) {
  const [workOrderList, setWorkOrderList] = useState([]);
  const [workOrderListCopy, setWorkOrderListCopy] = useState([]);
  const [isProgress, setIsProgress] = useState(true);
  const [isCustomer, setIsCustomer] = useState(false);
  const [customerObj, setCustomerObj] = useState({});
  const [attachmentData, setAttachmentData] = useState([]);
  const [isAttach, setIsAttach] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isWoid, setIsWoId] = useState(0);
  const [open, setOpen] = useState(false);
  let [loading, setLoading] = useState(false);
  const locationprams = useLocation();
  const [filterAdj, setFilterAdj] = React.useState(false);
  const [backgtrans, setbackgtrans] = useState(false);
  const [idToMoveInProgress, setidToMoveInProgress] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let techId = JSON.parse(localStorage.getItem("techpin"))
  const techPin =  JSON.parse(localStorage.getItem("techpin"))
  const companyId = JSON.parse(localStorage.getItem("CompanyCode"))
  const saveRecentlyViewedItems = (workId, itemDetails) => {
    const storedItems =
      JSON.parse(localStorage.getItem("recentlyViewed")) || {};
    if (storedItems && storedItems[workId]) {
      const newList = [...storedItems[workId]]?.filter(
        (i) => i.id !== itemDetails.id
      );
      storedItems[workId] = [itemDetails, ...newList];
    } else {
      storedItems[workId] = [itemDetails];
    }
    localStorage.setItem("recentlyViewed", JSON.stringify(storedItems));
  };
  const navigation = useNavigate();
  const updateAppState = useContext(AppGlobalContextUpdate);
  const [woStatus, setWoStatus] = useState([]);

  const getWorkOrders = useCallback(async () => {
    const userId = localStorage.getItem("userId");
    const response = await workOrdrList(userId);
    if (response?.status === SUCCESS_STATUS) {
      setIsProgress(false);
      setIsTopen(false);
      setTempWorkOrder(null);

      const workOrderList = response?.data?.WorkOrderList;
      setWorkOrderList(workOrderList);
      setWorkOrderListCopy(workOrderList);
     
    }
  }, []);
  const GetWorkOrdersbyCust = useCallback(async (custId, WoType) => {
    if (custId) {
      try {
        if (WoType === "open") {
          let openWO = await CustOpenWO(custId);
          if (openWO?.success) {
            setIsProgress(false);
            setIsTopen(false);
            setWorkOrderList(openWO?.data?.workOrders);
            setWorkOrderListCopy(openWO?.data?.workOrders);
          }
        } else {
          let openWO2 = await CustClosedWO(custId);
          if (openWO2?.success) {
            setIsProgress(false);
            setIsTopen(false);
            setWorkOrderList(openWO2?.data?.workOrders);
            setWorkOrderListCopy(openWO2?.data?.workOrders);
          }
        }
      } catch (error) {}
    }
  }, []);
  
  useEffect(() => {
    if (locationprams.state !== null) {
      const { state } = locationprams;
      const custId = state?.params?.item?.customer_id;
      const WoType = state?.params?.woType ? state?.params?.woType : "Closed";
      
      GetWorkOrdersbyCust(custId, WoType);
    } else {
      getWorkOrders();
      
    }
    // eslint-disable-next-line no-use-before-define
  }, [GetWorkOrdersbyCust, getWorkOrders, locationprams]);
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState({});
  const [delTagData, setDelTagData] = useState({});
  const handleDeleteModal = (e, data, item) => {
    setTempWorkOrder(item?.WorkOrderId)
    e.stopPropagation();
    setDeleteTag(item);
    setDelTagData(data);
    setShowDeleteTag(true);
  };

  const [updateStatusWork, setUpdateStatusWork] = useState(null);
  const HandleShow = (e, workOrd) => {
    setidToMoveInProgress(workOrd.id);
    e.stopPropagation();
    if (workOrd?.status === STATUS_TODO) {
      setUpdateStatusWork(workOrd);
      setOpen(true);
    }
  };

  const handleDeleteTag = async (it, item) => {
   let dltid = it?.id?it?.id:it?.tag_id
    const response = await deleteTags(dltid);
    if (response?.success) {
      // getWorkOrders();
      if(it?.id)
      {
        let tagdata = item?.tags?.filter((i) => i?.id !== it?.id);
        item.tags = tagdata;
      }
      else if(it?.tag_id)
      {
        let tagdata = item?.tags?.filter((i) => i?.tag_id !== it?.tag_id);
        item.tags = tagdata;
      }
      // it.workOrderId.tags = it?.workOrderId?.tags?.filter(
      //   (i) => i?.id !== it?.id
      // );

      setShowDeleteTag(false);
      setDeleteTag({});
    }
  };
  const navToWoDetls = (e, to, wdObj, isVal,woid,custid) => {
    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: { wdObj, isVal,woid:wdObj?.WorkOrderId,custid:wdObj?.Customer?.id },
      navigate: navigation,
    });
    e.stopPropagation();
  };

  const [newstatus, setNewstatus] = useState("");

 

  const handleClose = () => {
    setOpen(false);
  };

  const [iscontactname,setContactName] = useState({})
  const HandleCutomer = async (e, value) => {
    e.stopPropagation();
    setCustomerObj(value);
    const techPin = JSON.parse(localStorage.getItem("techpin"));
    const contactdet = await getWorkOrderById(
      value?.WorkOrderId, 
      techPin,
      value?.Customer?.id
    );
    if (contactdet?.success === true) {
      setContactName(contactdet?.WorkOrderDetails?.contact);
      setIsCustomer(true);
    } else {
    }
  };
  const HandleDataTable = () => {
    NavigateFunction({
      to: "WorkOrderTable",
      navigate: navigation,
    });
  };

  const [tagItnpObjWo, setTagItnpObjWo] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId?.work_order,
    uuid: "",
  });

  const [tempWorkOrder, setTempWorkOrder] = useState(null);
  const [isTopen, setIsTopen] = useState(false);
  const [isTagData, setIsTagData] = useState({});
  const HandleAddtag = (e, workorder) => {
    setLoading(false);
    e.stopPropagation();
    setTempWorkOrder(workorder?.WorkOrderId);
    setIsTagData(workorder);
    setTagItnpObjWo({ ...tagItnpObjWo });
    setIsTopen(true);
    setTagItnpObjWo({
      name: "",
      color: "#CCCCCC",
      referenceType: ReferenceId.work_order,
      uuid: "",
});
  };

  const [setForDots] = useState();

  const HandleForDots = () => {
    setForDots();
  };
  const changeTagEventWo = (key, value) => {
    setTagItnpObjWo({ ...tagItnpObjWo, [key]: value });
  };

  const HandleWoTagSubmit = async (it) => {
    let newData = {
      referenceType: ReferenceId?.work_order,
      tag_id: 0,
      referenceId: it?.WorkOrderId,
      name: tagItnpObjWo?.name,
      color: tagItnpObjWo?.color,
      uuid_id: "",
      work_order_id: it?.WorkOrderId,
      sponsor_id: 0,
      edit_allowed: 0,
      ...tagItnpObjWo,
    };
    setLoading(true);
    let Res = await addTags(newData);
    if (Res.success) {
      setLoading(false);
      it?.tags?.push({
        name: Res?.data?.tag?.tag_data,
        color: Res?.data?.tag?.color,
        tag_id: Res?.data?.tag?.tag_id,
      });
      setIsTopen(false);
      setTagItnpObjWo({ name: "", color: "#60666c" });
    }
  };
  useEffect(() => {
    localStorage?.removeItem("reswoid");
    localStorage?.removeItem("rescustid");
  },[])
  const HandleAttch = async (e, value) => {
    saveRecentlyViewedItems(value?.workorder_id, value);
    e.stopPropagation();
    setIsWoId(value.workorder_id);
    setIsAttach(true);
    setIsLoad(false);
    let attchId = value.id;
    let custId = value.customer_id;
    let WorkId = value.workorder_id;
    let eqId = value.equipment_id;
    let techId = value.technician_id;
    let step = value.workorder_step_id;
    let workflow = value.workorder_workflow_id;
    const response = await GetAttchMentByID(
      attchId,
      step,
      workflow,
      custId,
      WorkId,
      eqId,
      techId
    );

    if (response.success) {
      setIsLoad(true);
      setAttachmentData(response.data);
    }
  };
  const [isvalue, setIsValue] = useState([]);
  const HandleToggleTags = (e, value) => {
    e.stopPropagation();
    e.preventDefault();
    let Data = [...isvalue];
    if (Data.includes(value.id)) {
      Data = isvalue.filter((it) => it !== value.id);
    } else {
      Data = [...Data, value.id];
    }
    setIsValue(Data);
  };

  const handleSubmit = async () => {
    let workOrderStatus = "";
    switch (updateStatusWork?.status) {
      case STATUS_TODO:
        workOrderStatus = STATUS_INPROGRESS;
        break;
      default:
        workOrderStatus = STATUS_TODO;
        break;
    }
    const workOrderId = +updateStatusWork.id;
    const res = await updateWorkOrderStatus(workOrderId, workOrderStatus);
    if (res?.data?.success) {
      setOpen(false);
      alert(`${TAGS_MODAL_VALUES.ALERT_STATUS_UPDATE_IP}`);
      setWorkOrderList(
        workOrderList.filter((it) => it.id !== idToMoveInProgress)
      );
    }
  };

  const HandleFilterModal = () => {
    if (filterAdj === false) {
      setFilterAdj(true);
    } else if (filterAdj === true) {
      setFilterAdj(false);
    }
  };

  const navigateToCreateWO = () => {
    updateAppState({ workOrderList });
    NavigateFunction({
      params: { workOrderList },
      to: "WOrdersList",
      navigate: navigation,
    });
  };
  const [isExpand, setIsExpand] = useState("");
  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  // const onChangeDate = (dates) => {
  //   setIsClear(false);
  //   const [start, end] = dates;
  //   let fromDate = moment(start).format("MM/DD/YYYY");
  //   let todate = moment(end).format("MM/DD/YYYY");
  //   setStartDate(start);
  //   setEndDate(end);
  //   let Res = WorkOrderSearch(
  //     SearchInputs,
  //     workOrderListCopy,
  //     fromDate,
  //     todate
  //   );
  //   setWorkOrderList(Res);
  // };


  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);

  const onChangeDate = (dates, event) => {
    setIsClear(false);
    const [start, end] = dates;
  
    if (event?.shiftKey && start) {
      // Get the selected weekday
      const selectedDay = moment(start).day();
      setSelectedDayOfWeek(selectedDay);
  
      let matchedDates = [start];
      let currentDate = moment(start).add(1, 'days');
  
      // Find the next matching weekday
      while (currentDate.day() !== selectedDay) {
        currentDate.add(1, 'days');
      }
      matchedDates.push(currentDate.toDate());
  
      setStartDate(matchedDates[0]);
      setEndDate(matchedDates[1]);
  
      const fromDate = moment(matchedDates[0]).format("MM/DD/YYYY");
      const toDate = moment(matchedDates[1]).format("MM/DD/YYYY");
  
      const Res = WorkOrderSearch(SearchInputs, workOrderListCopy, fromDate, toDate);
      setWorkOrderList(Res);
    } else {
      setSelectedDayOfWeek(null); // Reset the selected weekday when Shift is not held
  
      const fromDate = moment(start).format("MM/DD/YYYY");
      const toDate = moment(end).format("MM/DD/YYYY");
  
      setStartDate(start);
      setEndDate(end);
  
      const Res = WorkOrderSearch(SearchInputs, workOrderListCopy, fromDate, toDate);
      setWorkOrderList(Res);
    }
  };
  
  
  
  const [SearchInputs, setSearchInputs] = useState({
    isWoName: "",
    isCustName: "",
    isTags: "",
    isCity: "",
    isTechs:"",
  });

  const HandleInputs = (e) => {
    setIsClear(false);
    const { name, value } = e.target;
    setSearchInputs({
      ...SearchInputs,
      [name]: value,
    });
  };
  const HandleBlur = () => {
    let fromDate = moment(startDate).format("MM/DD/YYYY");
    let todate = moment(endDate).format("MM/DD/YYYY");
    let Res = WorkOrderSearch(
      SearchInputs,
      workOrderListCopy,
      fromDate,
      todate,
      newData
    );
    setWorkOrderList(Res);
  };
  const [woStatus2, setWoStatus2] = useState([]);
  const [woStatus3, setWoStatus3] = useState([]);
  useEffect(() => {
    // for status
    const getWoStatus = async () => {
      const response = await woStsSettFldStpGt();
      if (response && response.data && response.data.length > 0) {
        setWoStatus2(response.data);
        setWoStatus3(response.data);
        setWoStatus(response.data);
      }
    };
    getWoStatus();
  }, []);
  const [newData, setNewData] = useState([]);
  const HandleBtnStatus = (value) => {
    let fromDate = moment(startDate).format("MM/DD/YYYY");
    let todate = moment(endDate).format("MM/DD/YYYY");
    let Res = WorkOrderSearch(
      SearchInputs,
      workOrderListCopy,
      fromDate,
      todate,
      [...newData, value]
    );
    setNewData((prevData) => [...prevData, value]);
    setWoStatus2(woStatus2?.filter((i) => i?.status !== value?.status));
    setWorkOrderList(Res);
  };
  const [isClear, setIsClear] = useState(false);

  const HandleClearText = (key, type) => {
    setNewstatus("");
    let fromDate = moment(startDate).format("MM/DD/YYYY");
    let todate = moment(endDate).format("MM/DD/YYYY");
    if (key === "date") {
      setSearchInputs({
        isWoName: SearchInputs?.isWoName,
        isCustName: SearchInputs?.isCustName,
        isTags: SearchInputs?.isTags,
        isCity: SearchInputs?.isCity,
        isTechs:SearchInputs?.isTechs
      });
      setStartDate(null);
      setEndDate(null);
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs:SearchInputs.isTechs,
        },
        workOrderListCopy,
        null,
        "Invalid date",
        newData
      );
      setWorkOrderList(Res);
    }
    if (key === "isWoName") {
      setSearchInputs({
        isWoName: "",
        isCustName: SearchInputs?.isCustName,
        isTags: SearchInputs?.isTags,
        isCity: SearchInputs?.isCity,
        isTechs:SearchInputs?.isTechs
      });
      let Res = WorkOrderSearch(
        {
          isWoName: "",
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs:SearchInputs.isTechs,
        },
        workOrderListCopy,
        fromDate,
        todate,
        newData
      );
      setWorkOrderList(Res);
    }
    if (key === "isCustName") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: "",
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs:SearchInputs?.isTechs
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: "",
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs:SearchInputs.isTechs,
        },
        workOrderListCopy,
        fromDate,
        todate,
        newData
      );
      setWorkOrderList(Res);
    }
    if (key === "isCity") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: "",
        isTechs:SearchInputs?.isTechs
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: "",
          isTechs:SearchInputs.isTechs,
        },
        workOrderListCopy,
        fromDate,
        todate,
        newData
      );
      setWorkOrderList(Res);
    }
    if (key === "isTags") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: "",
        isCity: SearchInputs.isCity,
        isTechs:SearchInputs?.isTechs
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: "",
          isCity: SearchInputs.isCity,
          isTechs:SearchInputs.isTechs,
        },
        workOrderListCopy,
        fromDate,
        todate,
        newData
      );
      setWorkOrderList(Res);
    }
    if (key === "isTechs") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs:""
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs:""
        },
        workOrderListCopy,
        fromDate,
        todate,
        newData
      );
      setWorkOrderList(Res);
    }
    if (type === "status") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs:SearchInputs?.isTechs
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
        },
        workOrderListCopy,
        fromDate,
        todate,
        newData?.filter((i) => i?.status !== key?.status)
      );

      setNewData(newData?.filter((i) => i?.status !== key?.status));
      setWoStatus2([...woStatus2, key]);
      setWorkOrderList(Res);
    }
  };
  const HandleClearInputs = () => {
    setSearchInputs({ isWoName: "", isCustName: "", isTags: "", isCity: "", isTechs:"" });
    setNewData([]);
    setWoStatus2(woStatus3);
    setStartDate(null);
    setEndDate(null);
    setWorkOrderList(workOrderListCopy);
  };
  const [over, setOver] = useState(false);
  return (
    <div>
      <div>
        <div className="top-header">
          <div className="w-100">
            <CommonHeader
              setbackgtrans={setbackgtrans}
              backgtrans={backgtrans}
            />
          </div>
          </div>
          {isProgress === true ? (
              <div className="progress-bar">
                <ProgressBar />
              </div>
            ) : (
              <>
          <div>
            <div
              className="subheader-sticky"
              style={{ pointerEvents: isProgress === true && "none" }}
            >
              <div className="line-align-bet mt-2 w-100">
                <div className="d-flex">
                  <img
                    src={Table}
                    alt="table"
                    className="img-style"
                    onClick={() => HandleDataTable()}
                  />

                  &nbsp;
                  <span className="TableText">Work Orders</span>
                </div>

                <div>
                  <button
                    className="crt-wrk-order"
                    onClick={() => navigateToCreateWO()}
                  >
                    {WORK_ORDER_CONSTANTS.CREATE_WORK_ORDER}&nbsp;&nbsp;
                    <AiOutlinePlus size={12} style={{ marginBottom: 2 }} />
                  </button>
                </div>
              </div>
            </div>
          </div> 
        <Row className="w-100">
          {isExpand === "expand" ? (
            ""
          ) : (
            <Col
              onMouseOver={() => setOver(true)}
              onMouseOut={() => setOver(false)}
              style={{ pointerEvents: isProgress === true && "none" }}
              xs={isExpand === "expand" ? 12 : 2}
              md={isExpand === "expand" ? 12 : 2}
              lg={isExpand === "expand" ? 0 : 2}
              className="workOrder-col1"
            >
              <WorkOrderSideBar
                HandleBtnStatus={HandleBtnStatus}
                startDate={startDate}
                onChange={onChangeDate}
                newstatus={newstatus}
                endDate={endDate}
                HandleInputs={HandleInputs}
                HandleBlur={HandleBlur}
                SearchInputs={SearchInputs}
                HandleClearInputs={HandleClearInputs}
                isClear={isClear}
                HandleClearText={HandleClearText}
                woStatus={woStatus}
                newData={newData}
                woStatus2={woStatus2}
                selectedDayOfWeek={selectedDayOfWeek}
              />
            </Col>
          )}
          <Col
            xs={isExpand === "expand" ? 12 : 10}
            md={isExpand === "expand" ? 12 : 10}
            lg={isExpand === "expand" ? 12 : 10}
            className="workOrder-col2"
          >
            
              <>
                <span
                  className={
                    isExpand === "expand" || over
                      ? "backbtn-hvr2  p-0 m-0"
                      : "backbtn-hvr p-0 m-0"
                  }
                >
                  {isExpand === "expand" ? (
                    <MdChevronRight
                      size={25}
                      onClick={() => HandleExpand("")}
                    />
                  ) : (
                    <MdChevronLeft
                      size={25}
                      onClick={() => HandleExpand("expand")}
                    />
                  )}
                </span>
                <div
                  className="mx-2 card p-1 w-100 main_card"
                  style={{
                    padding:
                      workOrderList?.length < 10 ? "0px 0px 80px 0px" : "0px",
                  }}
                >
                  {workOrderList?.length > 0 ? (
                    workOrderList?.map((item, index) => {
                      return (
                        <WorkOrderCard
                          key={index}
                          eachWorkOrder={item}
                          techId={techId}
                          techPin={techPin}
                          companyId={companyId}
                          navToWoDetls={navToWoDetls}
                          HandleCutomer={HandleCutomer}
                          // HandleMemory={HandleMemory}
                          HandleAddtag={HandleAddtag}
                        
                          HandleShow={HandleShow}
                          handleDeleteModal={handleDeleteModal}
                          HandleToggleTags={HandleToggleTags}
                          isvalue={isvalue}
                          HandleAttch={HandleAttch}
                          HandleForDots={HandleForDots}
                        />
                      );
                    })
                  ) : (
                    <div className="Error-status">{Error}</div>
                  )}
                </div>
              </>
            
          </Col>
        </Row>
        </>
            )}
        
        <CustomerModal
          Open={isCustomer}
          Close={() => setIsCustomer(false)}
          data={customerObj}
          iscontactname={iscontactname}
          custId ={customerObj?.Customer?.id}
          contactname={iscontactname?.name}
          note ={iscontactname?.note}
          // cusId={custId}
        />
        <AttachmentModal
          Open={isAttach}
          Close={() => setIsAttach(false)}
          isloading={isLoad}
          isloadingClose={() => setIsLoad(false)}
          data={attachmentData}
          WorkId={isWoid}
        />
        <AddTag
          Open={isTopen}
          name={tagItnpObjWo.name}
          color={tagItnpObjWo.color}
          workOrderId={"WorkOrder #" + tempWorkOrder}
          HandleTagInputs={changeTagEventWo}
          isClose={() => setIsTopen(false)}
          isLoading={loading}
          isSubmit={() => HandleWoTagSubmit(isTagData)}
        />
        <DeleteTags
          deleteTag={() => handleDeleteTag(delTagData, deleteTag)}
          handleClose={() => setShowDeleteTag(false)}
          data={delTagData}
          tagData={deleteTag}
          name={"WorkOrder"}
          headid={tempWorkOrder}
          isOpen={showDeleteTag}
        />
        <PopUp
          open={open}
          workOrder={updateStatusWork}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          HandleShow={HandleShow}
        />
        <FilterModal isOpen={filterAdj} isClose={() => HandleFilterModal()} />
      </div>
    </div>
  );
}

export default WorkOrders;
