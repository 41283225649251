import React from "react";
import QRCode from "react-qr-code";

export default function Scannar(props) {
  return (
    <div
      className="mt-2"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <QRCode
        size={256}
        style={{ border: "3px solid black" }}
        value={props?.ScanData}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
}
