import React, { useEffect, useState } from 'react';
import './LoginHeader.css'
import logo from '../../../Assets/Icons/Logo.svg'
// import compIcon from "../../../Assets/Icons/favicon.ico";

function LoginHeader() {
  const [colorPrimary, setColorPrimary] = useState("");
  // const [companylogo, setcompanyLogo] = useState("");
  useEffect(() => {
    const storedColor = JSON.parse(localStorage.getItem("color_primary"));
    // const storedImage = localStorage.getItem("logo")
    // setcompanyLogo(storedImage)
    const defaultColor = "#4189CD";
    const colorToSet = storedColor?storedColor : defaultColor;
    setColorPrimary(colorToSet);
  }, []);
  return (
    <div>
      <nav
        style={{
          backgroundColor: colorPrimary,
        }}>
        <div className="nav-main-L">
          {/* <img className='login-1' src={logo} alt='logo' /> */}
          <img className="comp-icon" src={logo} alt='logo' />
          <div className="navText-L">
          </div>
        </div>
      </nav>
    </div>
  )
}

export default LoginHeader