import React from "react";
import { Modal } from "react-bootstrap";
import { MOD_TEXT_FIELD_SETUP } from "../../../common/components/Values";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";

function AlrtAddnlFieldsVal(props) {
  const additionalFields = props.data
    ? props.data.filter((data) => data.packcolumn)
    : [];
 const workorderdetails = props.data
    ? props.data.filter((data) => !data.packcolumn)
    : []; 

  return (
    <div>
      <Modal size="sm" centered show={props?.open}>
        <Modal.Header className="modal-bg-color modal-header-height attach-media-note px-2 text-white">
          <span>{MOD_TEXT_FIELD_SETUP.ERR_MSG}</span>
          <img
            src={Close}
            alt="close"
            onClick={() => props.isClose(false)}
            className="modal-header-icon"
          />
        </Modal.Header>
        <Modal.Body className="p-1">
          <span className="err-mesge m-0 cntr-hr-line">
            These Fields can't be Empty
          </span>
          <div className="ml-1 err-mod-htnr">
            {workorderdetails.length > 0 && (
              <>
                <h5 className="err-mesge mt-1 cntr-hr-line">
                  Work Order Details:
                </h5>
                {workorderdetails.map((data, index) => {
                  const fieldLabel = 
                    data.name === "custId"
                     ? "Customer Name"
                    : data.name === "won"
                     ? "Work Order Name"
                    : data.name === "wot"
                     ? "Work Order Type"
                    : data.name === "wos" 
                    ? "Status"
                    : data.name === "calltype" 
                     ? "Call Type" 
                    : data.name === "reason"
                     ? "Reason"
                    : data.name === "descption" 
                     ? "Description"
                    : data.name === "contid" 
                     ? "Contact"
                    : data.name === "crmdate"
                     ? "Date"
                    : data.name === "time"
                     ? "Time"
                    : data.name === "techie"
                     ? "Technician" 
                    : null;
                  
                  return fieldLabel ? (
                    <div className="txt-ctr-line" key={index}>
                      <span className="err-txt mx-2">{fieldLabel}</span>
                    </div>
                  ) : null;
                })}
              </>
            )}
            {additionalFields.length > 0 && (
              <>
                <h5 className="err-mesge mt-2 mb-0 cntr-hr-line">
                  Additional Fields:
                </h5>
                {additionalFields.map((data, index) => (
                  <div className="txt-ctr-line" key={index}>
                    <span className="err-txt mx-2">{data.packcolumn}</span>
                  </div>
                ))}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AlrtAddnlFieldsVal;