import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import DeleteTags from "../../../workorders/addtag/DeleteTags.js";
import "../AddEquipment.css";
import { EQUIP_INFOVIEW_VALUES } from "../../../../common/components/Constants.js";
import Add_round from "../../../../common/Assets/Icons/Add_round.svg";
import AddTag from "../../../workorders/addtag/AddTag.js";
import moment from "moment/moment";
import {
  ADD_EQUIPMENT_SCROLL,
  TABLE_VIEW_EQUIPMENTS_KEYS,
} from "../../../../common/components/Constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSearch } from "react-icons/bi";
import sortA from "../../../../common/Assets/Icons/sortAz.svg";
import sortZ from "../../../../common/Assets/Icons/sortZa.svg";
import { WORK_ORDER_CONSTANTS } from "../../../../common/components/Constants";
import { ReferenceId } from "../../../../common/components/EnumRefType.js";
import {
  addTags,
  EquipmentTypelist,
  EquipManufacturerlist,
  deleteTags,
} from "../../../../services/WorkOrdersService.js";
import equipinfo from "../../../../common/Assets/Icons/equipinfo.svg";
import { format } from "date-fns";
const EquipmentInfo = ({
  props,
  HandleSortTgl,
  sortTgl,
  displayDate,
  HandleInfoAddEqpTgl,
  infoAeTgl,
  HandleAddTag,
  newEquipmentData,
  upDateNewEquipment,
  getErrorMessage,
  isCondition,
  equiptags,
  eqpinfo,
  isErr,
  onSelectChange,
  updateEquiptags,
  setEqptags,
  eqpnotes,
  initialError,
}) => {
  const location = useLocation();
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [eqptags, seteqptags] = useState([]);
  const [tagToDelete, setTagToDelete] = useState(null);
  const AddTagModal = () => {
    setIsTagOpen(true);
  };
  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.equipment,
    uuid: "",
  });
  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };
  const HandleTagSubmit = async () => {
    let Work_order_id = window.location.href.split("/");
    let equip_id =
      newEquipmentData?.equipment_id > 0
        ? newEquipmentData?.equipment_id
        : location?.state?.data?.equipment_id;
    let newTag = {
      // name: tagInpObj.name,
      // color: tagInpObj.color,
      // referenceType: tagInpObj.referenceType,
      // referenceId: equip_id,
      // work_order_id: Work_order_id[5],
      // uuid: tagInpObj.uuid,
      referenceType: tagInpObj?.referenceType || ReferenceId.equipment,
      tag_id: 0,
      referenceId: equip_id,
      name: tagInpObj?.name || "",
      color: tagInpObj?.color || "#CCCCCC",
      uuid_id: "",
      work_order_id: Work_order_id[5],
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newTag);
    seteqptags(Res?.data);
    if (Res?.success) {
      let newOne = {
        referenceType: Res?.data?.tag?.reference_type,
        color: Res?.data?.tag?.color,
        id: Res?.data?.tag?.tag_id,
        name: Res?.data?.tag?.tag_data,
      };
      updateEquiptags(newOne);
      setIsTagOpen(false);
      setTagInpObj({
        name: "",
        color: "#60666C",
        referenceType: ReferenceId.equipment,
        uuid: "",
      });
    }
  };
  useEffect(() => {
    EquipTypesGet();
  }, []);
  useEffect(() => {
    EquipManfGet();
  }, []);

  const handleequinfoToggle = () => {
    setinfoVisible(!infoVisible);
  };
  const [infoVisible, setinfoVisible] = useState(true);
  const [eqptypes, setEqptypes] = useState([]);
  const [eqpmanfr, setEqpmanfr] = useState([]);
  const EquipTypesGet = async () => {
    let Res = await EquipmentTypelist();
    setEqptypes(Res?.data);
  };
  const EquipManfGet = async () => {
    let Res = await EquipManufacturerlist();
    setEqpmanfr(Res?.data);
  };
  // const handleChange = (event) => {
  //   const selectedValue = event?.target?.value;
  //   onSelectChange(selectedValue);
  // };
  const [showDltTag, setshowDltTag] = useState(false);
  const handleTagdlt = (e, tags) => {
    e.stopPropagation();
    setTagToDelete(tags);
    setshowDltTag(true);
  };

  const handleTagClose = () => {
    setIsTagOpen(false);
    setTagInpObj({ name: "" });
  };

  const tagdltID = newEquipmentData?.equipment_id;
  const handleDeleteTag = async (tagToDelete) => {
    const response = await deleteTags(tagToDelete?.id);
    if (response.success) {
      setshowDltTag(false);
      setTagToDelete(null);
      setEqptags((prevTags) => {
        const updatedTags = prevTags.filter(
          (tag) => tag?.id !== tagToDelete?.id
        );
        return updatedTags;
      });
    }
  };

  const [selectDate, setSelectDate] = useState(null);
  const [installDate, setInstallDate] = useState(null);
  // const [error, setError] = useState(
  //   Array.isArray(initialError) ? initialError : []
  // );

  const handleStartDateSelect = (date, newEquipmentData) => {
    if (date && moment(date).isValid()) {
      const formattedDate = date
    ? `${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`
    : "";
      setSelectDate(formattedDate);
      if (newEquipmentData) {
        newEquipmentData.warranty_date = formattedDate;
      }
    }
    //  else {
    //   setSelectDate(null);
    //   if (newEquipmentData) {
    //     newEquipmentData.install_date = null;
    //   }
    // }
  };

  const handleEndDateSelect = (date, newEquipmentData) => {
    if (date && moment(date).isValid()) {
      const formattedDate = date
    ? `${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`
    : "";
      setInstallDate(formattedDate);
      if (newEquipmentData) {
        newEquipmentData.install_date = formattedDate;
      }
      // } else {
      //   setInstallDate(null);
      //   if (newEquipmentData) {
      //     newEquipmentData.install_date = null;
      //   }
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <div className="">
        <span className="sett-crd-hdr-txt ">
          <img
            src={equipinfo}
            className=" mb-1 cursor-p"
            alt=""
            onClick={handleequinfoToggle}
          />{" "}
          <span className=" cursor-p equiptitle" onClick={handleequinfoToggle}>
            {ADD_EQUIPMENT_SCROLL.INFO}
          </span>
        </span>
        {infoVisible && (
          <>
            {isCondition === "equipcreate" || isCondition === "Edit" ? (
              <div className="">
                <div className="equipinfo">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row w-100 gap-2">
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles ">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_ID}
                          </div>
                          <input
                            disabled
                            autoComplete="off"
                            className="inpt-add-eqp cursor-p"
                            value={newEquipmentData?.equipment_id}
                            name="equipment_id"
                          />
                        </span>
                      </div>
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles ">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_TYPE}
                          </div>
                          <select
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.equipment_type?.length <= 0
                                ? "cond-style-inpt-det inpt-hover fs-7"
                                : "inpt-add-eqp cursor-p"
                            }
                            style={{ fontSize: "13px" }}
                            // className="inpt-add-eqp cursor-p"
                            name="equipment_type"
                            value={newEquipmentData?.equipment_type || ""}
                            onChange={(e) =>
                              upDateNewEquipment(
                                "equipment_type",
                                e.target.value
                              )
                            }
                          >
                            <option>Please Select equipment_type</option>
                            {eqptypes?.map((it) => (
                              <option
                                key={it?.equipment_code}
                                value={it?.equipment_code}
                              >
                                {it?.equipment_description}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row w-100 gap-2">
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles ">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_LOCATION}
                          </div>

                          <input
                            autoComplete="off"
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.location.length <= 0
                                ? "cond-style-inpt-det inpt-hover"
                                : "inpt-add-eqp cursor-p"
                            }
                            // className="inpt-add-eqp cursor-p"
                            value={newEquipmentData?.location}
                            onChange={(e) =>
                              upDateNewEquipment("location", e.target.value)
                            }
                            name="location"
                          />
                        </span>
                      </div>
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles ">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.MANUFACTURER}
                          </div>
                          <select
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.manufacturer_code.length <= 0
                                ? "cond-style-inpt-det inpt-hover"
                                : "inpt-add-eqp cursor-p"
                            }
                            style={{ fontSize: "13px" }}
                            // className="inpt-add-eqp cursor-p"
                            name="manufacturer_code"
                            value={newEquipmentData?.manufacturer_code || ""}
                            onChange={(e) => {
                              upDateNewEquipment(
                                "manufacturer_code",
                                e.target.value
                              );
                            }}
                          >
                            <option value="">Please Select manufacturer</option>
                            {eqpmanfr?.length > 0 &&
                              eqpmanfr?.map((it) => (
                                <option
                                  key={it?.manufacturer_code}
                                  value={it?.manufacturer_code}
                                >
                                  {it?.manufacturer_description}
                                </option>
                              ))}
                          </select>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row w-100 gap-2">
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles ">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_MODEL_NO}
                          </div>
                          <input
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.model_number.length <= 0
                                ? "cond-style-inpt-det inpt-hover"
                                : "inpt-add-eqp cursor-p"
                            }
                            autoComplete="off"
                            // className="inpt-add-eqp cursor-p"
                            name="model_number"
                            value={newEquipmentData?.model_number || ""}
                            onChange={(e) =>
                              upDateNewEquipment("model_number", e.target.value)
                            }
                          />
                        </span>
                      </div>
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles ">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.SERIAL_NO}
                          </div>
                          <input
                            autoComplete="off"
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.serial_number.length <= 0
                                ? "cond-style-inpt-det inpt-hover"
                                : "inpt-add-eqp cursor-p"
                            }
                            // className="inpt-add-eqp cursor-p"
                            name="serial_number"
                            value={newEquipmentData?.serial_number || ""}
                            onChange={(e) =>
                              upDateNewEquipment(
                                "serial_number",
                                e.target.value
                              )
                            }
                          />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row w-100 gap-2">
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles">*</span>
                            {TABLE_VIEW_EQUIPMENTS_KEYS.STATUS}
                          </div>
                          <select
                            className="inpt-add-eqp cursor-p"
                            name="active"
                            value={newEquipmentData?.active || ""}
                            onChange={(e) =>
                              upDateNewEquipment("active", e.target.value)
                            }
                          >
                            <option className="inpt-add-eqp " key="1" value="1">
                              Active
                            </option>
                            <option className="inpt-add-eqp " key="0" value="0">
                              Inactive
                            </option>
                          </select>
                        </span>
                      </div>
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles">*</span>
                            Warranty Date:
                          </div>
                          <ReactDatePicker
                            id="warranty-date-picker"
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.warranty_date.length <= 0
                                ? "cond-style-inpt-det inpt-hover eqp-datepicker2 cursor-p"
                                : "inpt-add-eqp eqp-datepicker cursor-p"
                            }
                            // className={
                            //   error?.length > 0 &&
                            //   error?.filter(
                            //     (err) => err?.name === "warranty_date"
                            //   )?.length > 0
                            //     ? "eqp-datepicker2 cursor-p"
                            //     : "eqp-datepicker cursor-p"
                            // }
                            selected={selectDate ? new Date(selectDate) : null}
                            value={
                              newEquipmentData?.warranty_date || selectDate
                            }
                            onChange={(date) =>
                              handleStartDateSelect(date, newEquipmentData)
                            }
                            placeholderText="    Please select date."
                            autoComplete="off"
                            minDate={new Date()}
                            isClearable={false}
                            showPopperOnFocus
                            dateFormat="MM-dd-yyyy" 
                            onFocus={(e) => (e.target.readOnly = true)}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row w-100 gap-2 align-items-center">
                      <div className="w-50">
                        <span className="d-flex flex-column">
                          <div className="infocont">
                            <span className="eqp-star-styles">*</span>
                            Install Date:
                          </div>
                          <ReactDatePicker
                            id="install-date-picker"
                            className={
                              isErr.length > 0 &&
                              newEquipmentData?.install_date.length <= 0
                                ? "cond-style-inpt-det inpt-hover eqp-datepicker2 cursor-p"
                                : "inpt-add-eqp eqp-datepicker cursor-p"
                            }
                            // className={
                            //   error?.length > 0 &&
                            //   error?.filter(
                            //     (err) => err?.name === "install_date"
                            //   )?.length > 0
                            //     ? "eqp-datepicker2 cursor-p"
                            //     : "eqp-datepicker cursor-p"
                            // }
                            selected={
                              installDate ? new Date(installDate) : null
                            }
                            value={
                              newEquipmentData?.install_date || installDate
                            }
                            onChange={(date) =>
                              handleEndDateSelect(date, newEquipmentData)
                            }
                            placeholderText="    Please select date."
                            autoComplete="off"
                            // minDate={new Date()}
                            isClearable={false}
                            showPopperOnFocus
                            dateFormat="MM-dd-yyyy" 
                            onFocus={(e) => (e.target.readOnly = true)}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </span>
                      </div>

                      {isCondition === "Edit" && (
                        <div className="ml-auto">
                          <span className="">
                            <div
                              className="btn-T"
                              onClick={() => AddTagModal()}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {WORK_ORDER_CONSTANTS.TAG}
                              <img src={Add_round} alt="btn" />
                            </div>
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="wo-edit-tag">
                      <div className="tech-name-style ">
                        <div className="d-flex flex-wrap">
                          {equiptags?.map((item) => (
                            <div
                              key={item}
                              onClick={(e) => handleTagdlt(e, item)}
                            >
                              <span
                                className="tag-styles"
                                style={{
                                  backgroundColor: "#CCCCCC",
                                  fontSize: "12px",
                                  color: "black",
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                  display: "inline-block",
                                  maxWidth: "100%",
                                }}
                              >
                                {" "}
                                {item?.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <span className="d-flex flex-column">
                        <div className="infocont">
                          <span className="eqp-star-styles">*</span>
                          {TABLE_VIEW_EQUIPMENTS_KEYS.NOTE}
                        </div>
                        <input
                          autoComplete="off"
                          className={
                            isErr.length > 0 &&
                            newEquipmentData?.note.length <= 0
                              ? "cond-style-inpt-det inpt-hover"
                              : "inpt-add-eqp cursor-p"
                          }
                          // className="inpt-add-eqp-note cursor-p"
                          name="note"
                          value={newEquipmentData?.note || ""}
                          onChange={(e) =>
                            upDateNewEquipment("note", e.target.value)
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>

                {sortTgl === "srtTgl" ? (
                  <div className="txt-align-c dd-add-eqp-stl add-eqp-dd">
                    <span className="sort-add-eqp m-1 ">
                      {" "}
                      <img src={sortA} alt="" /> Sort A-Z{" "}
                    </span>
                    <span className="sort-add-eqp m-1">
                      {" "}
                      <img src={sortZ} alt="" /> Sort Z-A{" "}
                    </span>

                    <div className="lbl-fltr-adj p-1 rela">
                      <div className="close-icon-set">
                        <BiSearch
                          height={16}
                          width={16}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <input
                        className="ipt-fltr-adj-itm ipt-20ht form-control "
                        placeholder="Enter Tech"
                      />
                    </div>
                    <div className="txt-align-c scrl scrl-new">
                      <span className="px-1">
                        <input className="px-2" type="checkbox" /> {""} 11
                      </span>
                      <span>
                        <input className="px-2" type="checkbox" /> {""} 11
                      </span>
                    </div>
                    <div className="d-flex align-rt line-align-even mb-1">
                      <button className="modal-footer-save-cancel-btns">
                        Cancel
                      </button>
                      <button className="modal-footer-save-cancel-btns">
                        {" "}
                        Save
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                <div className=" d-flex justify-content-between ">
                  <div>
                    <div className=" d-flex eqp-edit-descrptn ">
                      {EQUIP_INFOVIEW_VALUES.ID}:
                      <span>{newEquipmentData?.equipment_id}</span>
                    </div>
                    <div className=" d-flex eqp-edit-descrptn">
                      {EQUIP_INFOVIEW_VALUES.MODEL}:
                      <span>{newEquipmentData?.model_number}</span>
                    </div>
                    <div className=" d-flex eqp-edit-descrptn">
                      {EQUIP_INFOVIEW_VALUES.SERIAL}:
                      <span>{newEquipmentData?.serial_number}</span>
                    </div>
                    <div className=" d-flex eqp-edit-descrptn">
                      <span>{EQUIP_INFOVIEW_VALUES.INSTALL}:</span>
                      <span>
                        {newEquipmentData?.install_date
                          ? format(
                              new Date(newEquipmentData.install_date),
                              "MM-dd-yyyy"
                            )
                          : ""}
                      </span>
                    </div>
                    <div className=" d-flex eqp-edit-descrptn">
                      <span>{EQUIP_INFOVIEW_VALUES.WARRANTY}:</span>
                      <span>
                        {newEquipmentData?.warranty_date
                          ? format(
                              new Date(newEquipmentData.warranty_date),
                              "MM-dd-yyyy"
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="equiptag d-flex flex-column justify-content-between">
                    <div className=" d-flex eqp-edit-descrptn">
                      <span>{newEquipmentData?.equipment_type}</span>
                    </div>
                    <div className=" d-flex eqp-edit-descrptn">
                      <span>
                        {newEquipmentData?.manufacturer_id
                          ? newEquipmentData?.manufacturer_id
                          : newEquipmentData?.manufacturer_code}
                      </span>
                    </div>
                    <div className=" d-flex eqp-edit-descrptn">
                      <span>{newEquipmentData?.location}</span>
                    </div>

                    <div>
                      {" "}
                      <button
                        className=" btn-T"
                        style={{
                          pointerEvents:
                            window?.location?.href?.split("/")[10] === "1"
                              ? "none"
                              : "auto",
                        }}
                        onClick={() => AddTagModal()}
                      >
                        {WORK_ORDER_CONSTANTS.TAG}{" "}
                        <img src={Add_round} alt="btn" />{" "}
                      </button>
                    </div>
                  </div>
                </div>
                {/* <ButtonToolbar className="d-flex flx-wrp">
                  {equiptags
                    ?.sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((tags, key) => {
                      return (
                        <OverlayTrigger
                          placement="bottom"
                          className="tag-styles"
                          overlay={tooltip(tags?.name)}
                        >
                          <span
                            key={key}
                            className="tag-data-style-eqp mx-1 mt-2  "
                            style={{
                              background: tags?.color,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {tags?.name?.length > 7
                              ? `${tags.name.slice(0, 6)}..`
                              : `${tags.name}`}
                            <img
                              src={delW}
                              alt=""
                              className="ml-1 cursor-p"
                              onClick={(e) => handleTagdlt(e, tags)}
                            />
                          </span>
                        </OverlayTrigger>
                      );
                    })}
                </ButtonToolbar> */}

                <div className="wo-edit-tag">
                  <div className="tech-name-style ">
                    <div className=" d-flex flex-wrap">
                      {equiptags?.map((item) => (
                        <div key={item} onClick={(e) => handleTagdlt(e, item)}>
                          <span
                            className="tag-styles"
                            style={{
                              backgroundColor: "#CCCCCC",
                              fontSize: "12px",
                              color: "",
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                              display: "inline-block",
                              maxWidth: "100%",
                            }}
                          >
                            {" "}
                            {item?.name}
                          </span>
                        </div>
                      ))}
                    </div>
                    {/* {workOrder?.Customer?.name} */}
                  </div>
                </div>

                <div className="d-flex eqp-edit-descrptn">
                  {WORK_ORDER_CONSTANTS.NOTE}:{" "}
                  <span>{newEquipmentData?.note}</span>
                  <br />
                </div>
                {/* <div className="eqp-note-cont">
                  <span className=" justify-content-end d-flex ">
                    Created by {newEquipmentData?.created_by}{" "}
                    {newEquipmentData?.created_date}{" "}
                  </span>
                </div> */}
              </>
            )}
          </>
        )}
        <AddTag
          workOrderId={"Equipment #" + tagdltID}
          Open={isTagOpen}
          isClose={() => handleTagClose()}
          isSubmit={HandleTagSubmit}
          HandleTagInputs={changeTagEvent}
          name={tagInpObj.name}
          color={tagInpObj.color}
        />
        <DeleteTags
          headid={tagdltID}
          name={"Equipment"}
          deleteTag={() => handleDeleteTag(tagToDelete)}
          handleClose={() => setshowDltTag(false)}
          data={tagToDelete}
          // tagData={deleteTag}
          isOpen={showDltTag}
        />
      </div>
    </>
  );
};

export default EquipmentInfo;
