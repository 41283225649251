import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Client } from "@twilio/conversations";
import { useState } from "react";
import UnionDots from "../../common/Assets/Images/Uniondots.svg";
import VCallImg from "../../common/Assets/Images/Vectorvcall.svg";
import CallImg from "../../common/Assets/Images/Vectorcallblack.svg";
import Picker from "../../common/Assets/Images/Vector (Stroke)pick.svg";
import Send from  "../../common/Assets/Images/Vectorsend.svg";

import ProgressBar from "../../common/navigation/ProgressBar";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import { authTokenAsynk } from "../../services/CommunicationsService";
import { useCallback } from "react";
import { useRef } from "react";
import Message from "./Message";

export default function ChatScreen() {
  const Location = useLocation();
  const [Messages, setMessages] = useState([]);
  const [FilterMsges, setFilterMsges] = useState([]);
  const [Conversation, setConversation] = useState({});
  useEffect(() => {
    async function GetAuthToken() {
      let user = JSON.parse(localStorage.getItem("userName"));
      let serviceSid = "ISc3df9f655e83415e9bf7ef48599575bd";
      let inpdata = {
        serviceSid: serviceSid,
        identity: user,
      };
      await authTokenAsynk(inpdata);
    }
    async function TwilioGetconver() {
      let UserToken = JSON.parse(localStorage.getItem("token"));
      const client = new Client(UserToken);
      let Conversation = await client.getConversationBySid(Location.state);
      setConversation(Conversation);
      Conversation.getMessagesCount().then((count) => {
        Conversation.getMessages(count).then((messagePaginator) => {
          setMessages(messagePaginator.items);
          setFilterMsges(messagePaginator.items);
        });
      });
    }
    TwilioGetconver();
    GetAuthToken();
  }, [Location.state]);
  const HandlePicker = () => {
    document.getElementById("input-file").click();
  };
  const messageAdded = useCallback((msg) => {
    setMessages((Messages) => [...Messages, msg]);
  }, []);
  const UpdateConersation = useCallback(async () => {
    let UserToken = JSON.parse(localStorage.getItem("token"));
    const client = new Client(UserToken);
    client.on("messageAdded", (msg) => messageAdded(msg));
  }, [messageAdded]);
  useEffect(() => {
    UpdateConersation();
  }, [UpdateConersation]);

  const [messages, setmessages] = useState("");
  const HandleInputChange = (e) => {
    setmessages(e.target.value);
  };
  const HandleSendMessage = () => {
    if (!isFile && !messages) {
      alert("Empty Input");
    } else if (!isFile && messages) {
      Conversation.sendMessage(messages);
      setmessages("");
    } else if (isFile && !messages) {
      Conversation.sendMessage(isFile);
      setIsFile("");
    }
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [Messages]);
  const [filterText, setFilterText] = useState("");
  const HandleSearch = (e) => {
    setFilterText(e.target.value);
    let res = FilterMsges.filter((it) =>
      it?.body?.toUpperCase().includes(e.target.value.toUpperCase())
    );

    setMessages(res);
  };
  const [isFile, setIsFile] = useState("");
  const [baseImg, setBaseImg] = useState("");
  const handleFileChange = (e) => {
    e.preventDefault();
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = async function () {
      setBaseImg(reader.result);
    };
    let formData = new FormData();
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      formData.append("img", e.target.files[0]);
      setIsFile(formData);
    } else if (e.target.files[0].type === "video/mp4") {
      formData.append("vid", e.target.files[0]);
      setIsFile(formData);
    } else if (e.target.files[0].type === "application/pdf") {
      formData.append("pdf", e.target.files[0]);
      setIsFile(formData);
    } else {
      setIsFile(formData);
    }
  };
  return (
    <div>
      <div className="chat-main-header">
        <div>
          <CommonHeader />
        </div>
        <div className="chat-child-two mx-2 mt-2">
          <div className="mx-3 ">
            <div className="support-chat-text mt-2">
              {Conversation?.friendlyName}
            </div>
          </div>
          <div className="w-50">
            <input
              type="text"
              className="inp-search-chat w-100"
              placeholder="Search Here"
              onChange={(e) => HandleSearch(e)}
            />
          </div>
          <div className="d-flex gap-4">
            <div>
              <img
                src={CallImg}
                alt="CallImg"
                className="cursor-pointer"
                width={13}
              />
            </div>
            <div>
              {" "}
              <img
                src={VCallImg}
                alt="VCallImg"
                className="cursor-pointer"
                width={15}
              />
            </div>
            <div>
              {" "}
              <img
                src={UnionDots}
                alt="UnionDots"
                className="cursor-pointer"
                width={4}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="mt-2"
        style={{ overflow: "auto", padding: "0px 0px 75px 0px" }}
      >
        {Messages.length > 0 ? (
          Messages.map((it, index) => (
            <div key={index}>
              <Message data={it} />
            </div>
          ))
        ) : Messages.length > 0 || filterText.length > 0 ? (
          <div className="No-data">No Results Found</div>
        ) : (
          <div className="progress-bar-style">
            <ProgressBar />
          </div>
        )}
      </div>
      <div ref={messagesEndRef} />
      <div
        className="d-flex w-100"
        style={{
          position: "fixed",
          bottom: "0px",
          background: "white",
          padding: "0px 14px 10px 14px",
        }}
      >
        <div className="w-100">
          {!isFile ? (
            <input
              type="text"
              placeholder="Enter your text here"
              className="inp-chat-text w-100"
              value={messages}
              onChange={(e) => HandleInputChange(e)}
            />
          ) : (
            <div className="inp-chat-text w-100">
              <img
                src={baseImg}
                alt="img"
                width={50}
                style={{ position: "absolute", bottom: "14px" }}
              />
            </div>
          )}
        </div>
        <div className="inp-picker  mx-2">
          <input
            type="file"
            className="form-control"
            id="input-file"
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e)}
          />
          <img
            src={Picker}
            alt="Picker"
            className="cursor-pointer"
            onClick={() => HandlePicker()}
          />
        </div>
        <div className="inp-picker">
          <img src={Send} alt="Send" onClick={() => HandleSendMessage()} />
        </div>
      </div>
    </div>
  );
}
