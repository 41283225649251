import React from 'react'
import { Modal } from 'react-bootstrap';
import ProgressBar from '../../../navigation/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { LOGIN_CONST } from '../../../components/Constants';

const AlertModal = ({ isOpen, message,message_head, onClose,isProgress,}) => {
    const navigate = useNavigate();
    if (!isOpen) return null;

    const handleNavigation =()=>{
        if(message === LOGIN_CONST.ALERT_MSG || message === "Password Reset Succesful")
        navigate('/'); 
      }
  return (
    <Modal show={isOpen} centered size="sm">
        <Modal.Header className="modal-bg-color modal-header-height px-2">
            {message_head}
        </Modal.Header>
        {isProgress ? (
            <div className="progress-bar" style={{ height: "170px" }}>
            <ProgressBar />
            </div>
        ) : (
        <Modal.Body>
            <div>{message}</div>
            <div className='cursor-p modal-bg-color'
             style={{
                position: 'absolute',
                bottom: '7px',
                right: '10px',
                
                
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '2px 15px',
                cursor: 'pointer'
            }}
            onClick={()=>{onClose();
                handleNavigation();
            }}>Close</div>
        </Modal.Body>)}
    </Modal>   
  )
}

export default AlertModal
