import React from "react";
import delBlack from "../../../common/Assets/Icons/del.svg";
import "./WorkFlow.css";
export default function WorkflowModalData(props) {
  return (
    <div className="line-align-bet w-100 ">
      <div className="w-50 ">
        <img
          src={delBlack}
          alt="delBlack"
          className={
            props?.data?.Workflows_steps?.filter(
              (i) =>
                i?.attachment_list?.length > 0 ||
                i?.choice_result !== null ||
                // i?.choice_result !== "" ||
                i?.notes?.length > 0
            )?.length > 0
              ? "img-gray-wf-mdl"
              : "img-white-wf-mdl"
          }
          width={10}
          onClick={() => props?.HAndleDeleteWfAddModal(props?.data)}
        />
        <span
          className={
            props?.data?.Workflows_steps?.filter(
              (i) =>
                i?.attachment_list?.length > 0 ||
                i?.choice_result !== null ||
                // i?.choice_result !== "" ||
                i?.notes?.length > 0
            )?.length > 0
              ? "wf-Name-styles2 mx-1"
              : "wf-Name-styles mx-1"
          }
        >
          {props?.data?.workflow_name}
        </span>
      </div>
      <input
        type="text"
        placeholder="Input Note"
        className="wf-add-modal-input-styles mt-1 w-50"
        name="work_order_notes"
        value={props?.data?.work_order_notes}
        onChange={(e) => props?.handleNoteUpdate(e, props?.data)}
        onBlur={props?.HandleNotePut}
      />
    </div>
  );
}
