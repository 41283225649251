import React from "react";
import "./EquipmentInfoModal.css";
import { Modal } from "react-bootstrap";
import eqpinfomodalsave from "../../../common/Assets/Icons/eqpinfomodalsave.svg"
import wcross from "../../../common/Assets/Icons/wcross.svg";
import { TABLE_VIEW_EQUIPMENTS_KEYS , } from "../../../common/components/Constants";
import closeC from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import drill from "../../../common/Assets/Icons/Equipment_outline_Step.svg";
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment/moment";

function EquipmentInfoModal(props) { 
  const { equipment } = props;
  const params = useParams();
  const navigation = useNavigate();
const navToPage = (to, params, data) => {
  let WoNo = window.location.href.split("/")[5];
  localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
  if (to === "ViewEquipments") {
    localStorage.setItem("isWoid", JSON.stringify(params));
  }

  NavigateFunction({
    to: to,
    params: { params: params, data, },
    navigate: navigation,
  });
};
  return (
    <Modal centered onHide={props.close} show={props.open}>
      <Modal.Header className="modal-bg-color modal-header-height">
        <span className=" text-white">
          <img src={drill} alt="equipment name" /> &nbsp;{" "}
          {equipment?.equipment_type}/{equipment?.manufacturer_id}
          {/* {EQUIP_INFOMODAL_VALUES.MODAL_HEADER} */}
        </span>

        <span>
        <span className={equipment?.active === "1" ? "act-btn-eqp-mdl mx-3" : "act-btn-eqp-mdl-active mx-3"}>
  {equipment?.active === "1" ? "Active" : "Inactive"}
</span>

          <img
            src={closeC}
            alt="close"
            className="modal-header-icon"
            onClick={props.close}
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_ID}
          </label>
          <div className="w-100 inpt-eqp-mod">{equipment?.equipment_id}</div>
        </div>
        <div className="mt-2  line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_TYPE}
          </label>
          <div className=" w-100 inpt-eqp-mod">{equipment?.equipment_type}</div>
        </div>
        <div className="mt-2 line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_LOCATION}
          </label>
          <div className="w-100 inpt-eqp-mod">{equipment?.location}</div>
        </div>
        <div className="mt-2 line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.MANUFACTURER}
          </label>
          <div className=" w-100 inpt-eqp-mod">
            {equipment?.manufacturer_id}
          </div>
        </div>
        <div className="mt-2 line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_MODEL_NO}
          </label>
          <div className=" w-100 inpt-eqp-mod">{equipment?.model_number}</div>
        </div>
        <div className="mt-2 line-align-bet ">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.SERIAL_NO}
          </label>
          <div className="w-100 inpt-eqp-mod">{equipment?.serial_number}</div>
        </div>
        <div className="w-100 line-align-bet  mt-2 d-flex flex-column ">
          <div className="w-50  line-align-bet ">
            <label className="w-50 lbl-ipt-eqp-mod">
              {" "}
              {TABLE_VIEW_EQUIPMENTS_KEYS.END_OF_WARRENTY}
            </label>
            <div className=" inpt-eqp-mod-dt">
              {" "}
              {moment(equipment?.warranty_date).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div className="w-100 line-align-bet  mt-2 d-flex flex-column">
        <div className=" w-50  line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {TABLE_VIEW_EQUIPMENTS_KEYS.INSTALLED_DATE}
          </label>
          <div className=" inpt-eqp-mod-dt">
            {moment(equipment?.install_date).format("DD/MM/YYYY")}
          </div>
          </div>
          </div>
        <div className="w-100 mt-2 line-align-bet">
          <label className="w-50 lbl-ipt-eqp-mod">
            {" "}
            {TABLE_VIEW_EQUIPMENTS_KEYS.NOTE}
          </label>
          <div className="w-100 inpt-eqp-mod">{equipment?.note}</div>
        </div>
        <div className="created-equ mt-2">
          Created By {equipment?.created_by} at &nbsp;
          {moment(equipment?.created_date).format("HH:mm:ss A On DD/MM/YYYY")}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-styles ">
        <div className="modal-footer-save-cancel-btns" onClick={props.close}>
          {" "}
          Close{" "} <img src={wcross} alt="Close" className="closeic1 cursor-p" />
        </div>
       
        <div
          className="modal-footer-save-cancel-btns w-50"
          // onClick={() => props?.navToPage("AddEquipments", equipment)}
          onClick={() => navToPage(`AddEquipments/${params.serviceid}/${params.workid}/${params.teckid}/${params.techpin}/${params.company}/0`, "equipdetails", equipment)}
        >
          Open Equipment Page{" "} <img src={eqpinfomodalsave} alt="" />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EquipmentInfoModal;
