import React, { useState, useEffect } from "react";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import Table from "../../common/Assets/Images/Table_lighttable.png";
import AddMain from "../../common/Assets/Images/add_main.svg";
import { useNavigate } from "react-router-dom";
import FilterModal from "../../common/components/workordertable/FilterModal";
// import MemoryIcon from "../../common/Assets/Icons/Memory Board Icon.svg";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import "./CustomersStyles.css";
import {  CustomerListGet } from "../../services/SettingsServices";
import CustShareModal from "../customers/CustShareModal";
import CustomerCard from "./CustomerCard";
import { CUSTOMER } from "../../common/components/Values";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import ProgressBar from "../../common/navigation/ProgressBar";
import CustomerSideBar from "./CustomerSideBar";
import { CustomerSearch } from "./CustomerSearch";
import { CustOpenWO,getTechList } from "../../services/WorkOrdersService";

function Customers() {
  const [filterAdj, setFilterAdj] = React.useState(false);
  const [over, setOver] = useState(false);
  const [isExpand, setIsExpand] = useState("");
  const [isProgress, setIsProgress] = useState(false);
  const [CustomerListCopy, setCustomerList] = useState([]);
  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  useEffect(() => {
    localStorage?.removeItem("rescustid");
    localStorage.removeItem("customer_id");
    CustomerFunGet();
    GetTech()
  }, []);
  const [isCustomer, setIsCustomer] = useState([]);
  const [isCustomer2, setIsCustomer2] = useState([]);
  const [, setCustopenWO] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [techncian , setTechnician] = useState([])
  const GetTech = async () => {
    let Resp = await getTechList ()
    setTechnician(Resp)
  }
  const CustomerFunGet = async () => {
    setIsLoading(true);
    setIsProgress(true)
    let Res = await CustomerListGet();
    setIsProgress(false)
    
    if (Res?.success) {
      setIsCustomer(Res?.data);
      setIsLoading(false);
      const objects = Res?.data[0].objects;
      objects.sort((a, b) => a?.name.localeCompare(b?.name));
      setIsCustomer2(objects);
      setCustomerList(Res?.data[0].objects);
    }
  };
  // const HandleCloseModal = () => {
  //   setIsOpen(false);
  // };
  useEffect(() => {
    async function HandleCustOpenWO() {
      let openWO = await CustOpenWO(isCustomer2);
      if (openWO?.success) {
        setCustopenWO(openWO?.data);
      }
    }
    HandleCustOpenWO();
  }, [isCustomer2]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const techPin = JSON.parse(localStorage.getItem("techpin"));
  const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
  const HandleNav = ( key) => { 
    NavigateFunction({
      to:`CreateNewcustomer/${0}/${0}/${userId}/${techPin}/${companyCode}/${0}`,
      params: {
        key,
      },
      navigate: navigation,
    });
  };

  const HandleFilterModal = () => {
    if (filterAdj === false) {
      setFilterAdj(true);
    } else if (filterAdj === true) {
      setFilterAdj(false);
    }
  };
const[qrcustid,setqrCustId] =useState(null)
  const [isOpen, setIsOpen] = useState(false);
  const handleCustShare = (e,item) => {
    setqrCustId(item?.customer_id)
    e.stopPropagation();
    setIsOpen(true);
  };
  const navigation = useNavigate();
  const HandleDataTable = (items) => {
    NavigateFunction({
      to: "CustomerTable",
      navigate: navigation,
      params: items,
    });
  };
  const [SearchInputs, setSearchInputs] = useState({
    isCustName: "",
    isTags: "",
    isCity: "",
    isContacts: "",
  });
  const HandleBtnStatus = (value) => {
    setSearchInputs({
      isCustName: "",
      isTags: "",
      isCity: "",
      isContacts: "",
    });
    CustomerListGet(value);
  };
  const [isClear, setIsClear] = useState(false);
  const HandleInputs = (e) => {
    setIsClear(false);
    const { name, value } = e?.target;
    setSearchInputs({
      ...SearchInputs,
      [name]: value,
    });
  };
  const HandleBlur = () => {
    let Res = CustomerSearch(SearchInputs, CustomerListCopy, "CustPage");
    setIsCustomer2(Res);
  };
  const HandleClearInputs = () => {
    setSearchInputs({ isCustName: "", isTags: "", isCity: "", isContacts: "" });
    setIsCustomer2(CustomerListCopy);
  };
  const HandleClearText = (key) => {
    if (key === "isCustName") {
      setSearchInputs({
        isCustName: "",
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isContacts: SearchInputs.isContacts,
      });
      let Res = CustomerSearch(
        {
          isCustName: "",
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isContacts: SearchInputs.isContacts,
        },
        CustomerListCopy,
        "CustPage"
      );
      setIsCustomer2(Res);
    }

    if (key === "isCity") {
      setSearchInputs({
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: "",
        isContacts: SearchInputs.isContacts,
      });
      let Res = CustomerSearch(
        {
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: "",
          isContacts: SearchInputs.isContacts,
        },
        CustomerListCopy,
        "CustPage"
      );
      setIsCustomer2(Res);
    }
    if (key === "isTags") {
      setSearchInputs({
        isCustName: SearchInputs.isCustName,
        isTags: "",
        isCity: SearchInputs.isCity,
        isContacts: SearchInputs.isContacts,
      });
      let Res = CustomerSearch(
        {
          isCustName: SearchInputs.isCustName,
          isTags: "",
          isCity: SearchInputs.isCity,
          isContacts: SearchInputs.isContacts,
        },
        CustomerListCopy,
        "CustPage"
      );
      setIsCustomer2(Res);
    }
    if (key === "isContacts") {
      setSearchInputs({
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isContacts: "",
      });
      let Res = CustomerSearch(
        {
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isContacts: "",
        },
        CustomerListCopy,
        "CustPage"
      );
      setIsCustomer2(Res);
    }
  };
  const HandleOpenWO = (item, woType = "open") => {
    NavigateFunction({
      to: "WorkOrders",
      params: {
        params: {
          woType,
          item,
        },
      },
      navigate: navigation,
    });
  };
  const HandleClosedWO = (item) => {
    NavigateFunction({
      to: "WorkOrders",
      params: {
        params: {
          close: "close",
          item,
        },
      },
      navigate: navigation,
    });
  };
  
  return (
    <div>
      <div className="sticky-headers" style={{zIndex:'99900'}}>
        <CommonHeader />
        </div>
        {isProgress === true ? (
        <div className="progress-bar">
          <ProgressBar />
        </div>
      ) : (
        <>
        <div className="cust-head d-flex justify-content-between align-items-center sticky-header-content">
          <div>
            <img
              src={Table}
              alt="Table"
              className="cust-img-style"
              onClick={() => HandleDataTable(isCustomer)}
              
            />
            <span className="customer-text">Customers</span>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div
              className="create-cust"
              onClick={() => HandleNav(0)}
              style={{ cursor: "pointer" }}
            >
              {CUSTOMER.CREATE_NEW_CUST}
              <img src={AddMain} alt="AddMain" width={12} />
            </div>
          </div>
        </div>
      <Row className="w-100">
        {isExpand === "expand" ? (
          ""
        ) : (
          <Col 
            onMouseOver={() => setOver(false) }
            onMouseOut={() => setOver(true)}
            xs={12}
            md={2}
            className="workOrder-col1 mt-2"
          >
            <CustomerSideBar
              HandleBtnStatus={HandleBtnStatus}
              HandleInputs={HandleInputs}
              HandleBlur={HandleBlur}
              SearchInputs={SearchInputs}
              isClear={isClear}
              HandleClearText={HandleClearText}
              HandleClearInputs={HandleClearInputs}
            />
          </Col>
        )}
  
        <Col
          xs={12}
          md={isExpand === "expand" ? 12 : 10}
          className="cust-col2"
        >
          {isCustomer?.length === 0 ? (
            <div className="progress-bar">
              <ProgressBar />
            </div>
          ) : (
            <>
              <span
                className={ 
                  isExpand === "expand" || over
                    ? "backbtn-hvr2  p-0 m-0"
                    : "backbtn-hvr p-0 m-0"
                }
              >
                {isExpand === "expand" ? (
                  <MdChevronRight size={25} onClick={() => HandleExpand("")} />
                ) : (
                  <MdChevronLeft size={25} onClick={() => HandleExpand("expand")} />
                )}
              </span>
              <div
                className="mx-2 mt-2 card p-1 w-100 cust-main-div"
                style={{
                  padding: isCustomer?.length < 10 ? "0px 0px 80px 0px" : "0px",
                  overflowY: 'auto'
                }}
                
              >
                {isLoading ? (
                  <div className="pro-bar-style">
                    <ProgressBar />
                  </div>
                ) : isCustomer2?.length > 0 ? (
                  isCustomer2?.map((item) => {
                    return (
                      <div className="mt-1" >
                        <CustomerCard
                          data={item}
                          handleCustShare={handleCustShare}
                          HandleNav={HandleNav}
                          HandleOpenWO={HandleOpenWO}
                          HandleClosedWO={HandleClosedWO}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="nodata-text">No Data found</div>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
      </>
      )}
      <CustShareModal
        qrcustid={qrcustid}
        isOpen={isOpen}
        isClose={() => setIsOpen(false)}
        techncian={techncian}
        customerList={isCustomer}
      />
      <FilterModal isOpen={filterAdj} isClose={() => HandleFilterModal()} />
    </div>
  );
  
}

export default Customers;
