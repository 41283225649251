import React, {useState} from 'react'
import { putResetpaswd } from '../../../../services/AuthService';
import {useParams } from 'react-router-dom';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
// import { userSetUp } from '../../../../services/SettingsServices';
import LoginHeader from '../../../framework/header/HeaderLogin/LoginHeader';
import { LOGIN_CONST } from '../../../components/Constants';
import LoginFooter from '../../../framework/footer/LoginFooter';
import AlertModal from './AlertModal';

export default function SecondResetPassword() {
    const [resetInputs, setResetInputs] = useState({
        password: "",
        confirmPassword: "",
      });
      const [errors, setErrors] = useState({
        password: "",
        confirmPassword: "",
      });
  
    const [confirmPassVisible, setConfirmPassVisible] = useState(false);

    const { email, id } = useParams();
    // const [user,setUser] = useState([])
    const decodedEmail = atob(email);
    const decodedUserId = atob(id);
    // const UserData = useCallback(async () => {
    //     try {
    //       const userdata = await userSetUp();
    //       const User = userdata?.find((item) => item?.user_name === decodedUserId);
    //       setUser(User);
    //     } catch (error) {
    //       console.error("Error fetching user data:", error);
    //     }
    //   // eslint-disable-next-line no-use-before-define
    //   }, [decodedUserId, setUser]);
    //   useEffect(()=>{
    //     UserData()
    // },[UserData])
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
      
        setResetInputs((prev) => ({
          ...prev,
          [name]: value,
        }));
    
        validateField(name, value);
      };
    
      const validateField = (name, value) => {
        let errorMessage = "";
    
        if (name === "password") {
          if (value.length < 3) {
            errorMessage = "Password must be at least 3 characters long.";
          }
        }
    
        if (name === "confirmPassword") {
          if (value !== resetInputs.password) {
            errorMessage = "Passwords do not match.";
          }
        }
    
        setErrors((prev) => ({
          ...prev,
          [name]: errorMessage,
        }));
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
   
        if (!resetInputs.password) {
          newErrors.password = "Password is required.";
        } else if (resetInputs.password.length < 3) {
          newErrors.password = "Password must be at least 3 characters long.";
        }
    
        if (!resetInputs.confirmPassword) {
          newErrors.confirmPassword = "Confirm Password is required.";
        } else if (resetInputs.confirmPassword !== resetInputs.password) {
          newErrors.confirmPassword = "Passwords do not match.";
        } else if (resetInputs.confirmPassword === resetInputs.password && resetInputs.password.length >=3 ) {
          setModal(true);
          setIsProgress(true)
          try{
            let newObj = {
                user_email:decodedEmail,
                user_name:decodedUserId,
                new_pass: resetInputs.password
            }
              const response = await putResetpaswd(newObj);
                    if(response){
                        setModalMessage("Password Reset Succesful")
                        setIsProgress(false)
                    }
                    else{setModalMessage("Failed to reset the password")
                        setIsProgress(false)
                    }
          }
          catch (error) {
                
              }
        }
    
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }
      };
 const [modal,setModal] = useState(false);
 const[isProgress,setIsProgress] = useState(false)  
 const [modalMessage,setModalMessage] = useState("")
    
  return (
    <div className="page-container">
    <div className="content-wrap">
    <LoginHeader />
    <div className="App">
        <div className="fgtpaswd-form mt-1">
            <div className="login_box">
                <div className="fgtpaswd-hd-main">{LOGIN_CONST.RESET_PWD3}</div>
    
      <form onSubmit={handleSubmit}>
        <div className="sectn1 input-with-icon">
          <input
            className="fgtpaswd-input mt-2"
            type={confirmPassVisible ? "text" : "password"}
            name="password"
            value={resetInputs.password}
            onChange={handleInputChange}
            placeholder="Enter Password"
          />
          <div
            className="eye-icon"
            onClick={() => setConfirmPassVisible(!confirmPassVisible)}
          >
            {confirmPassVisible ? <BsEye /> : <BsEyeSlash />}
          </div>
          {errors.password && <div className="error-message">{errors.password}</div>}
        </div>
  
        <div className="sectn2 input-with-icon">
          <input
            className="fgtpaswd-input mt-2"
            type="password"
            name="confirmPassword"
            value={resetInputs.confirmPassword}
            onChange={handleInputChange}
            placeholder="Confirm Password"
          />
          
          {errors.confirmPassword && (
            <div className="error-message">{errors.confirmPassword}</div>
          )}
        </div>
        <div className="button-container1">
        <button type="submit">Reset</button></div>
      </form>

    
    
    </div>
    </div>
    </div>
    </div>
    <LoginFooter />
    <AlertModal 
    isOpen={modal}
    message={modalMessage}
    message_head={LOGIN_CONST.ALERT_HEAD}
    onClose={()=>{setModal(false)}}
    isProgress={isProgress}
    />
    </div>
  );
};
