import React, { forwardRef, useState } from "react";
import "./ExtraFields.css";
import { Card } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const ExtraFields = forwardRef(function ExtraFields(props, ref) {
  const [xtra, setXtra] = useState("");
  // const isEmpty = (data) => {
  //   let flag = "";

  //   if (
  //     props.isFinalSave &&
  //     data.required &&
  //     (data?.control_type === "integer" || data?.control_type === "numeric")
  //   ) {
  //     flag =
  //       data.value !== "" && data?.value !== null && isFinite(data.value)
  //         ? ""
  //         : "abc";
  //   } else if (
  //     props.isFinalSave &&
  //     data.required &&
  //     data?.control_type === "date"
  //   ) {
  //     flag = data?.value === null || data?.value?.length === 0 ? "abc" : "";
  //   } else if (
  //     props.isFinalSave &&
  //     data.required &&
  //     data.control_type === "string"
  //   ) {
  //     flag = data?.value === null || data?.value?.length === 0 ? "abc" : "";
  //   }
  //   return flag;
  // };
  // const wodAddtionalFieldsHandler = (key, e, field_id, controlType,value,additionalFields1) => {
  
  //   let Res = ExtraFieldsTypeValids(key,e,field_id,controlType, value,additionalFields1)
  
  //   props?.setobjectValues(Res)
  //   props?.setcreatingFields(Res); 
  // };
  // useEffect(() => {
  //   props?.setobjectValues(props?.additionalFields);
  // }, [props]);
  return (
    <Card className=" mt-2 det-txt-ef" ref={ref}>
      <div className="myDiv1">
      </div>
      <div>
        {xtra === "xtr" ? (
          ""
        ) : (
          <div className="wrapData mx-2  ">
            {props?.additionalFields?.map( 
              (data, index) =>
                (data?.value ||
                  props.isVal === "create" ||
                  props.isVal === "edit" || props.isVal === "") && (
                  <div key={index} className="pt-1">
                    {data?.packcolumn !== "" && (
                      <div className="d-flex ml-4 center-line-adj  ">
                        <div className="d-flex ">
                          {data?.required === true ? (
                            <span className="star-styles ">*</span>
                          ) : null}
                          <span className="lbl-xtr-fld"> {data?.packcolumn}:</span>
                          {data?.control_type === "date" ? (
                            <ReactDatePicker
                            // className={
                            //   data?.value?.length <= 0 &&
                            //   props?.ErrrAdd?.length > 0 &&
                            //   props?.ErrrAdd?.filter(
                            //     (i) => i?.field_id === data?.field_id
                            //   )[0]?.field_id === data?.field_id
                            //   ? "custom-datepicker2 cursor-p"
                            //   : "custom-datepicker cursor-p"
                            // } 
                            className={
                              props.isVal === ""
                                ? "custom-edit-datepicker ef-inpt-hover cursor-p" 
                                : data?.value?.length <= 0 &&
                                  props?.ErrrAdd?.length > 0 &&
                                  props?.ErrrAdd?.filter((i) => i?.field_id === data?.field_id)[0]
                                    ?.field_id === data?.field_id
                                ? "custom-wo-datepicker2 cursor-p"
                                : "custom-wo-datepicker cursor-p"
                            }
                              placeholderText="Select Date"
                              autoComplete="off"
                              selected={
                                data?.value &&
                                moment(data.value, "DD-MM-YYYY", true).isValid()
                                  ? moment(data.value, "DD-MM-YYYY").toDate()
                                  : null
                              }
                              onChange={(e) => {
                                props?.wodAddtionalFieldsHandler(
                                  "value",
                                  e,
                                  data?.field_id,
                                  data?.control_type,
                                  e?.target?.value,
                                  props?.additionalFields,
                                );
                              }}
                            disabled={props?.isVal === ""}
                            onFocus={(e) => (e.target.readOnly = true)}
                            onKeyDown={(e) => e.preventDefault()}
                            />
                          ) : (
                            <div>
                              <input
                                style={{
                                  border:
                                    props.isVal !== "edit" &&
                                    props.isVal !== "create" &&
                                    "none",
                                }}
                                className={
                                  data?.value?.length <= 0 &&
                                  props?.ErrrAdd?.length > 0 &&
                                  props?.ErrrAdd?.filter(
                                    (i) => i?.field_id === data?.field_id
                                  )[0]?.field_id === data?.field_id
                                    ? "error-inpt-xtr-fld pace-hold ef-inpt-hover"
                                    : "inpt-xtr-fld pace-hold ef-inpt-hover"
                                }
                              
                                value={data?.value}
                                type={data?.control_type}
                                placeholder={
                                  data?.control_type === "numeric"
                                    ? "Enter Numeric Value"
                                    : data?.control_type === "integer"
                                    ? "Enter Numbers"
                                    : "Enter Text"
                                }
                                onChange={(e) =>
                                  props?.wodAddtionalFieldsHandler(
                                    "value",
                                    e,
                                    data?.field_id,
                                    data?.control_type,
                                    e?.target?.value,
                                    props?.additionalFields,
                                   
                                  )
                                }
                                readOnly={
                                  props.isVal !== "edit" &&
                                  props.isVal !== "create" 
                                }
                                maxLength={data?.characterlength}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        )}
      </div>
    </Card>
  );
});

export default ExtraFields;
