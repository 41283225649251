import React, { useState } from "react";
import infoIcon from "../../common/Assets/Icons/Desk_alt.svg";
import WorkOrderIcon from "../../common/Assets/Icons/briefcase_outline_main_step.svg";
import communicateIcon from "../../common/Assets/Icons/chatbubble_Outline_Main.svg";
import eqpmntIcon from "../../common/Assets/Icons/Equipment_outline_Step.svg";
import mediaCenterIcon from "../../common/Assets/Icons/bookshelf.svg";
import logsIcon from "../../common/Assets/Icons/Logs_outline.svg";
import BlueLog from "../../common/Assets/Icons/Logs-1.svg";
import BlueLibrary from "../../common/Assets/Icons/Three rectangles.svg";
import BlueEqpmnt from "../../common/Assets/Icons/Equipment-1.svg";
import BlueWO from "../../common/Assets/Icons/briefcase_main_step.svg";
import BlueInfo from "../../common/Assets/Icons/Desk_alt_fill.svg";
import BlueCommunication from "../../common/Assets/Icons/chatbubble_Filled_main-1.svg";
import "./CNCSideBar.css";
function WODetailsSideBar(props) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const items = [
    { prevIcon: infoIcon, HoverIcon: BlueInfo, text: 'Info' },
    { prevIcon: communicateIcon, HoverIcon: BlueCommunication, text: 'Communications' },
    { prevIcon: WorkOrderIcon, HoverIcon: BlueWO, text: 'Work Orders' },
    { prevIcon: eqpmntIcon, HoverIcon: BlueEqpmnt, text: 'Equipment' },
    { prevIcon: mediaCenterIcon, HoverIcon: BlueLibrary, text: 'Media Center' },
    { prevIcon: logsIcon, HoverIcon: BlueLog, text: 'Logs' },
  ];
  const handleItemClick = (index) => {
    props.scrollCallback(index);
  };
  return (
    // <div className="side-bar-left ">
    //   <div
      
    //     className="wod-sidebar-div1"
    //     onClick={() => {
    //       props.scrollCallback(0);
    //     }}
    //   >
    //     <img src={infoIcon} alt="infoIcon"  />
    //     <span className="WoD-text-sidebar">{CUSTOMER.INFO}</span>
    //   </div>
     
    //   {/* <div
    //   id="notes"
    //     className="wod-sidebar-div3"
    //     onClick={() => {
    //       props.scrollCallback(2);
    //     }}
    //   >
    //     <img src={notesIcon} alt="notesIcon" />
    //     <span className="WoD-text-sidebar">{CUSTOMER.NOTES}</span>
    //   </div> */}
    //   <div
    //   id="communications"
    //     className="wod-sidebar-div4"
    //     onClick={() => {
    //       props.scrollCallback(2);
    //     }}
    //   >
    //     <img src={communicateIcon} alt="Communiation" />
    //     <span className="WoD-text-sidebar">{CUSTOMER.COMMUNICATIONS}</span>
    //   </div>
    //    <div
    //   id="workflow"
    //     className="eqp-sidebar-div2"
    //     onClick={() => {
    //       props.scrollCallback(3);
    //     }}
    //   >
    //     <img src={WorkOrderIcon} alt="workflowIcon"  />
    //     <span className="WoD-text-sidebar">{CUSTOMER.W_ORDERS}</span>
    //   </div> 
    //   <div
    //   id="equipment"
    //     className="wod-sidebar-div5"
    //     onClick={() => {
    //       props.scrollCallback(4);
    //     }}
    //   >
    //     <img src={eqpmntIcon} alt="Eqpmnt" />
    //     <span className="WoD-text-sidebar">{CUSTOMER.EQPMNT}</span>
    //   </div>

    //   <div
    //   id="mediacenter"
    //     className="cust-sidebar-div6"
    //     onClick={() => {
    //       props.scrollCallback(5);
    //     }}
    //   >
    //     <img src={mediaCenterIcon} alt="MediaCenter" />
    //     <span className="WoD-text-sidebar"> {CUSTOMER.LIBRARY}</span>
    //   </div>
    //   <div 
    //   id="logs"
    //     className="wod-sidebar-div7"
    //     onClick={() => {
    //       props.scrollCallback(6);
    //     }}
    //   >
    //     <img src={logsIcon} alt="Logs" />
    //     <span className="WoD-text-sidebar">{CUSTOMER.LOGS}</span>
    //   </div>
    // </div>
    <div className="sidebar mt-3 ">
    {items?.map((item, index) =>
      <span
        className="sidebar sdbar d-flex cursor-p "
        onClick={() => handleItemClick(index)}
        key={index}
        button
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <span >
          <img
            src={hoveredIndex === index ? item?.HoverIcon : item?.prevIcon}
            alt="icon"
            style={{ width: '24px', height: '24px' }}
          />
        </span>
        <span className="sidebarText">{item?.text}</span>
        {/* <ListItemText  primary={item?.text} /> */}
      </span>
    )}
    </div>
  );
}

export default WODetailsSideBar;
