import React from 'react'
import './PopUp.css'
import { Modal } from 'react-bootstrap' 
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg"
import { TAGS_MODAL_VALUES, WORK_ORDER_CONSTANTS } from '../../../../common/components/Constants'

function PopUp({ open, handleClose,handleSubmit,workOrder }) {
  return (
    <Modal 
    show={open}
   onHide={handleClose}
    centered >
      <Modal.Header className=" modal-bg-color modal-header-height px-2 "  >
             <span className="note-add-text" >
           {WORK_ORDER_CONSTANTS.WORK_ORDER}{workOrder?.id}
          </span>

          <img
            src={close}
            alt="close"
            className="modal-header-icon "
            onClick={() => handleClose()}
          />
        </Modal.Header>
    <Modal.Body className="mdl-open p-2 ">
       <span>{TAGS_MODAL_VALUES.POP_UP_TEXT}</span>
    </Modal.Body>
      <div className="md-flex gap-1 mt-2 m-1 model_footer">
          <button className="modal-footer-save-cancel-btns m-0 mx-1" onClick={()=>handleSubmit()}>Yes</button>
          <button className="modal-footer-save-cancel-btns m-0" onClick={() => handleClose()}>No</button>
      </div>
    </Modal>
  )
}

export default PopUp