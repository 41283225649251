export const StepRulesCheck = (item, status) => {
  const isComplete = item?.step_complete === "1";
  const hasAttachments = item?.attachment_list?.length > 0;
  const hasNotes = item?.notes?.length > 0;
  const hasContentNote = item?.content_note?.length > 0;
  const hasChoiceResult =
    item?.choice_result != null && item?.choice_result !== "";
  if (status === 1) {
    return true;
  }

  if (!hasAttachments || !hasNotes) {
    return false;
  }

  const typeCheck =
    (item.type === "Add Input Field" && hasContentNote) ||
    (item.type === "Create Equipment" && hasContentNote) ||
    item.type === "Attach Content" ||
    (item.type === "Complete Form" && hasAttachments && hasNotes) ||
    (item.type === "Fill out Table" && hasAttachments && hasNotes) ||
    (item.type === "Create Drop Down" && hasChoiceResult) ||
    (item.type === "Yes/No Selection" && hasChoiceResult);

  if (!item.step_required && isComplete) {
    return true;
  }

  if (
    (item?.step_required && typeCheck) ||
    (!item?.step_required &&
      (item?.note_required ? hasNotes : true) &&
      (item?.image_required ? hasAttachments : true) &&
      typeCheck)
  ) {
    return true;
  }

  return false;
};
