import React, { useState } from "react";
import { Card } from "react-bootstrap";
import notesIcon from "../../common/Assets/Icons/Pen.svg";
import prfl from "../../common/Assets/Icons/user_circle_outline_main.svg";
import addfieldsIcon from "../../common/Assets/Icons/Left Icon.svg";
import Add_Main from "../../common/Assets/Icons/add_main.svg";
import "./CreateRightSideBar.css";

const notesData = [
  {
    owner: "User Name (You)",
    date: "10/24/20 - 8:15 AM",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, velit aliquam pellentesque curabitur duis pellentesque luctus enim orci.",
  },
  {
    owner: "User Name (You)",
    date: "10/25/20 - 9:30 AM",
    content: "One line of text thats it.",
  },
  {
    owner: "User Name (You)",
    date: "10/26/20 - 10:45 AM",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, velit aliquam pellentesque curabitur duis pellentesque luctus enim orci.",
  },
];

const CreateRightSideBar = () => {
  const [isToggle, setIsToggle] = useState("Notes");

  const HandleToggle = (value) => {
    setIsToggle(value);
  };

  return (
    <div>
      <div className="sidebar-rightside-cust">
        <span
          className={`txt-style-sidebar cursor-p ${
            isToggle === "Notes" ? "bold-text" : ""
          }`}
          onClick={() => HandleToggle("Notes")}
        >
          Notes
        </span>
        <span
          className={`txt-style-sidebar cursor-p ${
            isToggle === "Extrafield" ? "bold-text" : ""
          }`}
          onClick={() => HandleToggle("Extrafield")}
        >
          Additional Fields
        </span>
      </div>
      {isToggle === "Notes" ? (
        <div className="notes-section">
          <div className="notes-img-styles mt-1 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={notesIcon} width={18} alt="notes" />
              <span className="notes-header-txt-cust mx-1 cursor-p">
                Notes (3)
              </span>
            </div>
            <img
              src={Add_Main}
              width={24}
              alt="Add Main"
              className="img-notes"
            />
          </div>
          <div className="notes-content-section">
            {/* Note Cards */}
            {notesData.map((note, index) => (
              <Card className="cust-rs-card-style" key={index}>
                <div className="note-card-header">
                  <div className="note-user-info">
                    <img src={prfl} alt="user" className="note-img-styles" />
                    <span className="cust-note-owner">{note.owner}</span>
                  </div>
                  <span className="cust-note-date">{note.date}</span>
                </div>
                <div className="cust-content">
                  <span className="cust-note-content">{note.content}</span>
                </div>
              </Card>
            ))}
          </div>
        </div>
      ) : (
        <div className="additional-fields-section">
          <div className="d-flex align-items-center mb-2">
            <img src={addfieldsIcon} alt="addfields" width={20} />
            <span className="notes-header-txt-cust cursor-p ms-2">
              Additional Fields
            </span>
          </div>
          <div className="additional-fields-content">
            <div className="field-row">
              <span className="wo-sb-txt">Long Word Example</span>
              <input
                type="text"
                className="wo-sb-input"
                placeholder="Input Data"
                style={{ height: "35px", width: "70%" }}
              />
            </div>
            <div className="field-row">
              <span className="wo-sb-txt">Additional Field</span>
              <input
                type="text"
                className="wo-sb-input"
                placeholder="Input Data"
                style={{ height: "35px", width: "70%" }}
              />
            </div>
            <div className="field-row">
              <span className="wo-sb-txt">Additional Field</span>
              <input
                type="text"
                className="wo-sb-input"
                placeholder="Input Data"
                style={{ height: "35px", width: "70%" }}
              />
            </div>
            <div className="field-row">
              <span className="wo-sb-txt">Additional Field</span>
              <input
                type="text"
                className="wo-sb-input"
                placeholder="Input Data"
                style={{ height: "35px", width: "70%" }}
              />
            </div>
            <div className="field-row">
              <span className="wo-sb-txt">Additional Field</span>
              <input
                type="text"
                className="wo-sb-input"
                placeholder="Input Data"
                style={{ height: "35px", width: "70%" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateRightSideBar;
