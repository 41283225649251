import React from "react";
import { CiSearch } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";
import {CUSTOMER} from "../../common/components/Values"
import './CustomerSideBar.css';
export default function CustomerSideBar(props) {
  return (
    <div>
         {(props?.SearchInputs?.isCustName?.length > 0 ||
      props?.SearchInputs?.isCity?.length > 0 ||
      props?.SearchInputs?.isTags?.length > 0 ||
      props?.SearchInputs?.isContacts?.length > 0) && (
      <div className="wo-sidebar-lft-stickybar">
        <div className="line-align-bet mx-2" style={{ width: "80%" }}>
          <span className="cust-side-filter-text">{CUSTOMER.SEL_FILTER}</span>
          <span
            className="side-filter-text cursor-p"
            onClick={() => props?.HandleClearInputs("clearAll")}
          >
            {CUSTOMER.CLEAR_ALL}
          </span>
        </div>
      </div>
    )}

      <div className="d-flex gap-1 flex-wrap">
        {props?.SearchInputs?.isCustName?.length > 0 && (
          <div className="leftside-search-item gap-1 d-flex">
            <span>{props?.SearchInputs?.isCustName}</span>
            <span
              className="closeicon"
              onClick={() => props?.HandleClearText("isCustName")}
            >
              <MdOutlineClose size={15} />
            </span>
          </div>
        )}
        {props?.SearchInputs?.isCity?.length > 0 && (
          <div className="leftside-search-item gap-1 d-flex">
            <span>{props?.SearchInputs?.isCity}</span>
            <span
              className="closeicon"
              onClick={() => props?.HandleClearText("isCity")}
            >
              <MdOutlineClose size={15} />
            </span>
          </div>
        )}
        {props?.SearchInputs?.isTags?.length > 0 && (
          <div className="leftside-search-item gap-1 d-flex">
            <span>{props?.SearchInputs?.isTags}</span>
            <span
              className="closeicon"
              onClick={() => props?.HandleClearText("isTags")}
            >
              <MdOutlineClose size={15} />
            </span>
          </div>
        )}
         {props?.SearchInputs?.isContacts?.length > 0 && (
          <div className="leftside-search-item gap-1 d-flex">
            <span>{props?.SearchInputs?.isContacts}</span>
            <span
              className="closeicon"
              onClick={() => props?.HandleClearText("isContacts")}
            >
              <MdOutlineClose size={15} />
            </span>
          </div>
        )}
      </div>

      <div className="p-2">
        <div>
          <span className="side-filter-text mx-2">{CUSTOMER.NAME}</span>
          <div>
            <CiSearch className="inp-inside-icon-cust" />
            <input
              className="w-100 sidebar-search-cust "
              type="text"
              placeholder="Name"
              onChange={(e) => props?.HandleInputs(e, "Customer")}
              onBlur={props?.HandleBlur}
              name="isCustName"
              value={props?.SearchInputs?.isCustName}
            />
          </div>
        </div>
        <div>
          <span className="side-filter-text mx-2">{CUSTOMER.CITY}</span>
          <div>
            <CiSearch className="inp-inside-icon-cust" />
            <input
              className="w-100 sidebar-search-cust"
              type="text"
              placeholder="City"
              onChange={(e) => props?.HandleInputs(e, "City")}
              onBlur={props?.HandleBlur}
              name="isCity"
              value={props?.SearchInputs?.isCity}
            />
          </div>
        </div>
        <div>
          <span className="side-filter-text mx-2">{CUSTOMER.TAGS}</span>
          <div>
            <CiSearch className="inp-inside-icon-cust" />
            <input
              className="w-100 sidebar-search-cust"
              type="text"
              placeholder="Tags"
              onChange={(e) => props?.HandleInputs(e, "Tags")}
              onBlur={props?.HandleBlur}
              name="isTags"
              value={props?.SearchInputs?.isTags}
            />
          </div>
        </div>

        <div>
          <span className="side-filter-text mx-2">{CUSTOMER.CONTACT}</span>
          <div>
            <CiSearch className="inp-inside-icon-cust" />
            <input
              className="w-100 sidebar-search-cust"
              type="text"
              placeholder="Contact"
              onChange={(e) => props?.HandleInputs(e, "Contacts")}
              onBlur={props?.HandleBlur}
              name="isContacts"
              value={props?.SearchInputs?.isContacts}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
