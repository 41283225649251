import React, { useState }  from 'react'
import delw from "../../common/Assets/Icons/delW.svg";
import save from "../../common/Assets/Icons/save.svg";
import { Col, Row } from "react-bootstrap";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import CommonHeader from '../../common/framework/header/HeaderCommon/CommonHeader';
import CrtNewEstCard from './CrtNewEstCard';
import CommonFooter from '../../common/framework/footer/CommonFooter';
import CrtNewSidebar from './CrtNewSidebar';
import { AiOutlinePlus } from 'react-icons/ai';
import image_filled1 from "../../common/Assets/Icons/image_filled1.svg";
import CreateRghtSideBar from './CreateRightSideBar';
import "./CreateNewEst.css";

export default function CreateNewEst() {
  const [isProgress, setIsProgress] = useState(true);
  const [over, setOver] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleInfo = () => { };
  const [isExpand, setIsExpand] = useState("");
  const [rtExpand, setRtExpand] = useState("");

  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  const HandleRtExpand = (val) => {
    setRtExpand(val);
  };

  return (
    <div>
      <CommonHeader />
      <div className="d-flex justify-content-between line-align-bet mt-2 w-100 ">
      <div className="d-flex gap-5">
           <span className="TableText ml-1">Create Estimate</span>{" "}
          <span className="status-font button-style mt-1">Creating</span>
    </div>
    <div className="d-flex  align-items-center">
           <div className="header-right  d-flex gap-3 text-right mr-2">
             <div className="cost-item">
               <span>Total Cost</span>
               <div>$3980.08</div>
             </div>
             <div className="cost-item">
              <span>Subtotal</span>
              <div>$3980.08</div>
           </div>
           </div>
           <button className="temp-btn">
             Add Personal
             <AiOutlinePlus size={12} style={{ marginLeft: 5, marginBottom: 2 }} />
           </button>
           <button className="temp-btn">
            Customer View
             <img src={image_filled1} width={13} alt="image_filled1" style={{ marginLeft: '4px' }} />
           </button>
          <button className="temp-btn">
            Discard
             <img src={delw} alt="" style={{ marginLeft: '4px' }} />
           </button>
           <button
            className="temp-btn mx-2 p-4"
            onClick={() => setShowModal(true)}
           >
            Save
           <img src={save} width={12} style={{ marginLeft: '4px' }} />
          </button>
       </div>
       </div>
       <div className="content-area">
       <Row className="w-100 mx-2">
                 {isExpand === "expand" ? (
            " "
          ) : (
            <Col
              lg={
                isExpand === "expand" && rtExpand === "rt-expand"
                  ? 0
                  : rtExpand === "rt-expand"
                    ? 2
                    : isExpand === "expand"
                      ? 0
                      : 2
              }
            >
              <CrtNewSidebar
                handleInfo={handleInfo}
              />
              </Col>
          )}

        <Col
            lg={
              isExpand === "expand" && rtExpand === "rt-expand"
                ? 12
                : isExpand === "expand"
                  ? 9
                  : rtExpand === "rt-expand"
                    ? 10
                    : 7
            }
          >
            {isExpand === "expand" ? (
              <span
                className={
                  isExpand === "expand" ? "est-backbtn-hvr2 " : "est-backbtn-hvr  p-0 m-0"
                }
              >
                <MdChevronRight
                  className="arro-right-icon1"
                  size={25}
                  onClick={() => HandleExpand("")}
                />
              </span>
            ) : (
              <span>
                <MdChevronLeft
                  size={25}
                  onClick={() => HandleExpand("expand")}
                  className="leftbar-leftarrow-est"
                />
              </span>
            )}
            {rtExpand === "rt-expand" ? (
              <MdChevronLeft
                size={25}
                className="est-expand-icon-right mx-2 "
                onClick={() => HandleRtExpand("")}
              />
            ) : (
              <MdChevronRight
                size={25}
                className="est-expand-icon-img-right"
                onClick={() => HandleRtExpand("rt-expand")}
              />
            )}
            <div className='p-1 card  pagescroll-est'>
            <CrtNewEstCard
                handleInfo={handleInfo}
              />
            </div>
           
            </Col>
            {rtExpand === "rt-expand" ? (
            ""
          ) : (
            <Col
              style={{ width: "25%" }}
              lg={
                rtExpand === "rt-expand" && isExpand === "expand"
                  ? 0
                  : rtExpand === "rt-expand"
                    ? 0
                    : isExpand === "expand"
                      ? 2
                      : 0
              }
            >
              <CreateRghtSideBar/>
            </Col>
          )}
       </Row>
       </div>
        <CommonFooter/>
    </div>
  )
}
