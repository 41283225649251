import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function ProgressBar() {
  return (
    <>
      <Spinner animation="border" variant="primary" />
      <div>Just a moment we are loading your content</div>
    </>
  );
}
