import React, { useCallback, useEffect, useState } from "react";
import "./ViewEquipments.css";
import CommonHeader from "../../../common/framework/header/HeaderCommon/CommonHeader";
import left from "../../../common/Assets/Icons/leftAr.svg";
import tableImg from "../../../common/Assets/Icons/table.svg";
import { ReferenceId } from "../../../common/components/EnumRefType";
import { Navbar, Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import plus from "../../../common/Assets/Icons/plusvector.svg"
import {
  GetAttchMentByID,
  addTags,
  deleteTags,
  getAllEquipmentlist,
  getWorkOrderById,
} from "../../../services/WorkOrdersService";
import AttachmentModal from "../../workorders/AttachmentModal";
import EquipmentInfoModal from "../Equipment Info Modal/EquipmentInfoModal";
import ProgressBar from "../../../common/navigation/ProgressBar";
import { NavigateFunction } from "../../../common/navigation/NavigationChild";
import AddTag from "../../workorders/addtag/AddTag";
import DeleteTags from "../../workorders/addtag/DeleteTags";
import { useLocation } from "react-router-dom";
import EquipmentCard from "../EquipmentCard";
import { useParams } from "react-router-dom";

function ViewEquipments(props) {
  const params = useParams();
  const location = useLocation()
  const [sortedEqp, setSortedEqp] = useState(location?.state);
  const [isSpin, setIsSpin] = useState(false);
  const navigate = useNavigate();
  const HandleNavToWoD = () => {
    navigate(-1);
  };
  const [infoModal, setInfoModal] = useState(false);
  const [eqpObj, setEqpObj] = useState(null);
  const HandleInfoModal = (data, e) => {
    e.stopPropagation();
    setEqpObj(data);
    setInfoModal(true);
  };
  const navigation = useNavigate();

  const HandleViewTable = () => {
    const url = `ViewEquipmentsTable/${params.isWoid}/${params.custid}/${params.techid}/${params.techid1}/${params.compid}`;
    NavigateFunction({
      to: url,
      params: sortedEqp,
      navigate: navigation,
    });
  };
  const [isAttach, setIsAttach] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isData, setIsData] = useState([]);
  const HandleViewImg = async (value, item) => {
    setIsAttach(true);
    setIsLoad(false);
    let attchId = value.id;
    let custId = item.customer_id;
    let WorkId = +localStorage.getItem("WorkId");
    let eqId = +item.equipment_id;
    let techId = +localStorage.getItem("userId");
    let companyId = localStorage.getItem("CompanyCode");

    const response = await GetAttchMentByID(
      attchId,
      custId,
      WorkId,
      eqId,
      techId,
      companyId
    );

    if (response.success) {
      setIsLoad(true);
      setIsData(response.data);
    }
  };
  const navToPage = (to, params,data) => {
    let WoNo = params.isWoid;
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("WorkId", JSON.stringify(params));
    }

    NavigateFunction({
      to: to,
      params: {params: params, data },
      navigate: navigation,
    });
  };
  const [isTagModal, setIsTagModal] = useState(false);
  const [isCustId, setCustId] = useState(0);
  const HandleAddTag = (val, e) => {
    setCustId(val?.equipment_id);
    e.stopPropagation();
    setIsTagModal(true);
  };
  const [tagInpObj, setTagInpObj] = useState({
    name: "",
    color: "#60666C",
    referenceType: ReferenceId.equipment,
    uuid: "",
  });
  const changeTagEvent = (key, value) => {
    setTagInpObj({ ...tagInpObj, [key]: value });
  };
  const HandleTagSubmit = async () => {
    // let Work_order_id = window.location.href.split('/')
    let equip_id = isCustId
    let newTag = {
      referenceType: tagInpObj?.referenceType,
      tag_id: 0,
      referenceId:equip_id,
      name: tagInpObj.name,
      color: tagInpObj?.color,
      uuid_id: "",
      work_order_id: params.isWoid,
      sponsor_id: 0,
      edit_allowed: 0,
    };
    let Res = await addTags(newTag);
    if (Res?.success) {
      let newOne = {
        referenceType: Res?.data?.tag?.reference_type,
        color: Res?.data?.tag?.color,
        id: Res?.data?.tag?.tag_id,
        name: Res?.data?.tag?.tag_data,
      }
      HandleTagUpdat(newOne)
      setIsTagModal(false);
    }
  }
  const HandleTagUpdat = (newOne) => {
    let newArr = alleqpdata?.filter((eqpItem) =>
      eqpItem?.equipment_id === isCustId
    );
    newArr[0]?.tags?.push(newOne)

  }
  const [deleteTag, setDeleteTag] = useState(null);
  const [showDltTag, setshowDltTag] = useState(false)
  const [dlteqpid, setDeleqpid] = useState(null)
  const handleTagdlt = (tags, e, data) => {
    e.stopPropagation();
    setDeleqpid(data)
    setDeleteTag(tags);
    setshowDltTag(true)
  }
  const tagdltID = isCustId
  const handleDeleteTag = async (tagToDelete) => {
    const response = await deleteTags(tagToDelete?.id);
    if (response.success) {
      HandleTagDel()
      setshowDltTag(false)
    }
  };
  const HandleTagDel = () => {
    let newArr = alleqpdata?.filter((eqpItem) =>
      eqpItem?.equipment_id === dlteqpid?.equipment_id
    );
    let newTagData = newArr[0]?.tags.filter((i) => i?.id !== deleteTag?.id)
    newArr[0].tags = newTagData


  }
  const getEquipmentStatus = (status) => {
    if (status === "1" || status === "true" || status === true) {
      return "active";
    } else {
      return "inactive";
    }
  };
  const [alleqpdata, setAlleqpdata] = useState([])
  const [isExpand, setIsExpand] = useState("");
  const wrkId = params.isWoid
  const techId = params.techid
  const custId = params.custid
  const Viewworkorder = useCallback(async () => {
    setIsSpin(true)
    try {
      if(wrkId > 0)
      {
        const Result = await getWorkOrderById(wrkId, techId, custId);
        setIsSpin(false)
        setAlleqpdata(Result?.WorkOrderDetails?.equipments)
      }
      else{
        const Result = await getAllEquipmentlist(custId);
        setIsSpin(false)
        setAlleqpdata(Result?.data)
      }
     
     
    } catch (error) { }
  },[custId, techId, wrkId])
  useEffect(() => {
    Viewworkorder();
  }, [Viewworkorder]);

  const [over, setOver] = useState(false);

  return (
    <>
      <div className="head-stick">
        <CommonHeader />
        {!isSpin && (
          <Navbar bg="" expand="lg">
            <Container fluid>
              <img src={left} alt="back button" onClick={() => HandleNavToWoD()} style={{ cursor: "pointer" }} />
              <Navbar.Brand className="txt-fnt mr-2">
                <img
                  className=" cursor-p"
                  onClick={() => HandleViewTable()}
                  src={tableImg}
                  alt="table for view Equipments"
                />
              </Navbar.Brand>
              <Navbar.Brand className="hd1-ve mr-2">Equipment</Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
              </Navbar.Collapse>
              <button className="cancl-btn" onClick={() => navToPage(`AddEquipments/${params.isWoid}/${params.custid}/${params.techid}/${params.techid1}/${params.compid}/0`, "equipcreate",0)}>
                <span>Create New Equipment</span>&nbsp;
                <img src={plus} alt="Create" className="closeic1" />
              </button>
            </Container>
          </Navbar>
        )}
      </div>
      <Row className="w-100">
        {isExpand !== "expand" ? (
          ""
        ) : (
          <Col
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            style={{ pointerEvents: isSpin === true && "none" }}
            xs={isExpand === "expand" ? 12 : 2}
            md={isExpand === "expand" ? 12 : 2}
            lg={isExpand === "expand" ? 0 : 2}
            className="workOrder-col1">
          </Col>
        )}
        <Col
          xs={isExpand === "expand" ? 12 : 10}
          md={isExpand === "expand" ? 12 : 10}
          lg={isExpand === "expand" ? 12 : 10}
          className="workOrder-col2 w-100"
        >
          {isSpin ? (
            <div className="progress-bar">
              <ProgressBar />
            </div>
          ) : (
            <>
              {alleqpdata?.length > 0 ?
                <div className="mx-2 mt-2 card p-1 w-100">
                  <EquipmentCard
                    cardEquipments={alleqpdata}
                    navToPage={navToPage}
                    HandleInfoModal={HandleInfoModal}
                    getEquipmentStatus={getEquipmentStatus}
                    addEquTag={HandleAddTag}
                    handleDeleteModal={handleTagdlt}
                    HandleViewImg={HandleViewImg}
                    LocStrId={localStorage.getItem("userId")}
                  />
                </div>
                :
                <div className="mx-2 mt-2 card p-1 w-100 text-center">
                  <span>There are no records to display</span>
                </div>
              }
            </>
          )}
        </Col>
      </Row>
      <AttachmentModal
        Open={isAttach}
        Close={() => setIsAttach(false)}
        isloading={isLoad}
        isloadingClose={() => setIsLoad(false)}
        data={isData}
      />
      <AddTag
        Open={isTagModal}
        name={tagInpObj.name}
        color={tagInpObj.color}
        HandleTagInputs={changeTagEvent}
        // workOrderId={JSON.parse(localStorage.getItem("WorkId"))}
        isClose={() => setIsTagModal()}
        isSubmit={() => HandleTagSubmit()}
      />
      <EquipmentInfoModal
        open={infoModal}
        close={() => setInfoModal(false)}
        equipment={eqpObj}
        navToPage={navToPage}
      />
      <DeleteTags
        eqid={tagdltID}
        name={"Equipment"}
        // workorderId={JSON.parse(localStorage.getItem("WorkId"))}
        deleteTag={() => handleDeleteTag(deleteTag)}
        handleClose={() => setshowDltTag(false)}
        data={deleteTag}
        isOpen={showDltTag}
      />
    </>
  );
}

export default ViewEquipments;
