import { PDFDocument } from "pdf-lib";
// import fs from "fs";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { FaCheck } from "react-icons/fa6";
import {
  GetFieldsData,
  GetMasterForm,
  UpdateMappings,
} from "../../../../services/SettingsServices";
import save from "../../../../common/Assets/Icons/save.svg";
import delw from "../../../../common/Assets/Icons/delW.svg";
import "./PDFViewer.css";
import { NavigateFunction } from "../../../../common/navigation/NavigationChild";
export default function PDFViewer() {
  const location = useLocation();
  const navigation = useNavigate();
  const [pdfData, setPdfData] = useState(null);
  const [isMapData, setIsMapData] = useState([]);
  const [inpObjItem, setInpObjItem] = useState({});

  const HandleUpdateFields = useCallback((exobj, exname, data) => {
    let newfilterOne = data?.filter((i) => i?.uuid === exname);
    if (newfilterOne?.length) {
      if (
        exobj.constructor.name === "t" ||
        exobj.constructor.name === "PDFTextField"
      ) {
        exobj.setText(newfilterOne[0]?.name?.toString());
      }
    }
  }, []);
  const HandleUpdateCheckBox = useCallback((exobj, exname, data) => {
    let newfilterOne = data?.filter((i) => i?.name === exname);
    if (newfilterOne?.length >= 0) {
      if (
        exobj.constructor.name === "PDFCheckBox" ||
        exobj.constructor.name === "t"
      ) {
        newfilterOne[0]?.value !== null &&
          newfilterOne[0]?.value === "Checked" &&
          exobj.check();
      }
    }
  }, []);
  const HandlePdfReload = useCallback(
    async (pdf, data) => {
      const pdfDoc = await PDFDocument.load(pdf);
      const form = pdfDoc.getForm();
      const fields = form.getFields();

      fields.forEach((field) => {
        const fieldName = field.getName();
        try {
          const fieldType = field.constructor.name;
          if (fieldType === "PDFTextField" || fieldType === "t") {
            const textField = form.getTextField(fieldName);
            if (textField) {
              HandleUpdateFields(textField, fieldName, data);

              return;
            }
          } else if (fieldType === "PDFCheckBox" || fieldType === "t") {
            const isCheckBox = form.getCheckBox(fieldName);
            if (isCheckBox) {
              HandleUpdateCheckBox(isCheckBox, fieldName, data);

              // const isChecked = isCheckBox.isChecked();
              // isChecked && isCheckBox.check();
              return;
            }
          }
        } catch (error) {
          console.error(`Error updating field "${fieldName}":`, error);
        }
      });

      const updatedPdfBytes = await pdfDoc.save();
      const updatedPdfDoc = await PDFDocument.load(updatedPdfBytes);

      const mergedPdfBytes = await updatedPdfDoc.save();
      const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const base64data = await convertBlobUrlToBase64(pdfBlob);

      setPdfData(base64data);
      // setPdfData(pdfBlobUrl);
    },
    [HandleUpdateCheckBox, HandleUpdateFields]
  );
  const [OriginalFields, setOriginalFields] = useState([]);
  const MasteFormById = useCallback(
    async (id) => {
      let Res = await GetMasterForm(id);
      let Response = Res?.data;

      const pdfDataUrl = Response?.pdf;
      setPdfData(pdfDataUrl);

      const mappingFields =
        Response?.pdf_mapping_fields?.length > 0
          ? Response?.pdf_mapping_fields
          : Response?.pdf_original_fields;
      HandlePdfReload(Response?.pdf, mappingFields);
      setOriginalFields(Response?.pdf_original_fields);
      setIsMapData(mappingFields);
      setInpObjItem(Response);
    },
    [HandlePdfReload]
  );
  useEffect(() => {
    MasteFormById(location?.state?.item?.master_form_id);
  }, [MasteFormById, location?.state?.item, location?.state?.item.pdf]);

  const newArr = [
    { name: "work_order" },
    { name: "contact" },
    { name: "customer" },
    { name: "workflow" },
    { name: "equipment" },
    { name: "division" },
    { name: "assignment" },
    { name: "sponsor" },
  ];
  const StepTypes = [
    { name: "Attach Content" },
    { name: "Complete Form" },
    { name: "Add Input Field" },
    { name: "Yes/No Selection" },
    { name: "Create Drop Down" },
    { name: "Fill out Table" },
    { name: "Create Equipment" },
  ];

  const [isLoad, setIsLoad] = useState(false);
  const [isField, setIsField] = useState("");
  const [getFields, setGetFields] = useState([]);

  const HandlGetFields = async (event) => {
    setIsField(event?.target?.value);
    if (event?.target?.value === "workflow") {
      setIsStepField("");
      setIsStepType("");
    } else {
      setIsLoad(true);
      let Res = await GetFieldsData(event?.target?.value);
      if (Res?.success) {
        setIsLoad(false);
        setGetFields(Res?.data);
      }
    }
  };
  const [fieldname, setFieldName] = useState("");
  const HandleSubField = async (e) => {
    setFieldName(e.target.value);
    if (isleftClr?.uuid?.length > 0) {
      let UpdatedObj = {
        ...isleftClr,
        name: ismasterField,
        table_name: isField,
        field_name: e.target.value,
      };
      let FinalData = [
        ...isMapData?.filter((it) => it?.uuid !== isleftClr?.uuid),
        UpdatedObj,
      ];
      setIsMapData(FinalData);
    }
  };
  const HandleWfStep = async (it) => {};
  const [isstepType, setIsStepType] = useState();
  const HandleStepData = async (e) => {
    setIsStepType(e.target.value);
    HandleWfStep(e.target.value);
  };
  const [isStepField, setIsStepField] = useState("");
  const HandleSelectField = (e) => {
    const selectedValue = e.target.value;
    setIsStepField(selectedValue);

    let newObj = {
      ...isleftClr,
      name: ismasterField,
      table_name: "workflow",
      step_type: isstepType,
      step_type_field: e.target.value,
    };

    let FinalData = [
      ...isMapData?.filter((it) => it?.uuid !== isleftClr?.uuid),
      newObj,
    ];
    setIsMapData(FinalData);
  };
  const [ismasterField, setIsMasterField] = useState();
  const HandleMasterField = (e) => {
    // ...isleftClr,
    setIsMasterField(e.target.value);
  };
  const HandleMasterBlur = () => {
    let UpdatedObj = {};
    isField === "workflow"
      ? (UpdatedObj = {
          ...isleftClr,
          name: ismasterField,
          table_name: isField,
          step_type: isstepType,
          step_type_field: isStepField,
        })
      : (UpdatedObj = {
          ...isleftClr,
          name: ismasterField,
          table_name: isField,
          field_name: fieldname,
        });

    let FinalData = [
      ...isMapData?.filter((it) => it?.uuid !== isleftClr?.uuid),
      UpdatedObj,
    ];
    setIsMapData(FinalData);
  };

  const HandleNavBack = (it) => {
    NavigateFunction({
      to: "Settings",
      params: it,
      navigate: navigation,
    });
  };

  const [isleftClr, setIsLeftClr] = useState("");

  const HandleLeftData = async (it) => {
    let newObj = isMapData?.find((i) => i?.uuid === it?.uuid);
    // let Response = await getDataByName(it?.name);
    setIsMasterField(newObj?.name ? newObj?.name : "");
    if (newObj?.table_name === "workflow") {
      setIsStepType(newObj?.step_type);
      setIsStepField(newObj?.step_type_field);
    }

    setIsLeftClr(newObj);
    setIsField(
      it?.table_name && it?.table_name !== "workflow" ? it?.table_name : ""
    );
    setFieldName(newObj?.field_name ? newObj?.field_name : "");

    setIsField(newObj?.table_name ? newObj?.table_name : "");
    let Res = await GetFieldsData(
      newObj?.table_name ? newObj?.table_name : it?.table_name
    );
    if (Res?.success) {
      setIsLoad(false);
      setGetFields(Res?.data);
    }
    // if (Response?.success) {
    //   setIsField(Response?.findMaster_Fields?.table_name);
    //   setFieldName(Response?.findMaster_Fields?.field_name);
    //   let Res = await GetFieldsData(Response?.findMaster_Fields?.table_name);
    //   if (Res?.success) {
    //     setIsLoad(false);
    //     setGetFields(Res?.data);
    //   }
    // } else {
    //   setIsField("");
    //   setFieldName("");
    // }
  };
  const [isAlert, setIsAlert] = useState(false);

  const [isSave, setIsSave] = useState(false);
  const [isSave2, setIsSave2] = useState(false);

  const convertBlobUrlToBase64 = async (pdfBlob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(pdfBlob);
    });
  };

  const HandleFinalSave = async () => {
    setIsAlert(true);

    let inpObj = {
      pdf_mapping_fields: isMapData,
    };

    const result = await UpdateMappings(inpObjItem?.master_form_id, inpObj);
    if (result?.success) {
      setTimeout(() => {
        setIsAlert(false);
      }, 1000);

      HandlePdfReload(pdfData, isMapData);
    }

    //   setIsSave(true);
    //   setTimeout(() => {
    //     setIsSave(false);
    //   }, 1000);
    //   setIsSave2(true);
    //   setTimeout(() => {
    //     setIsSave2(false);
    //   }, 2000);
    //   let inpObj = {
    //     wfid: +isWfId,
    //     setpid: +isStepId,
    //     master_form_id: location?.state?.item?.master_form_id,
    //   };
    // };
    // const StopEvents = () => {
    //   if (+isWfId > 0 && +isStepId > 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
  };

  return (
    <>
      <div className="px-2">
        {isAlert && (
          <div className="center-align">
            <div className="sett-save-btn2 ">saved successfully</div>
          </div>
        )}

        <div className="mt-2">
          <Row>
            <Col xs={2}>
              <div className="left-fields-scroll">
                <div className="FieldTitle">Fields</div>
                <div className="">
                  {OriginalFields?.map((i) => (
                    <div
                      className="left-fields mt-3"
                      style={{
                        background: i?.uuid === isleftClr?.uuid && "#1A60A3",
                        color: i?.uuid === isleftClr?.uuid && "white",
                        padding: "5px",
                        borderRadius: "10px",
                      }}
                      onClick={() => HandleLeftData(i)}
                    >
                      {i?.uuid}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col className="" xs={7}>
              <div>
                <input
                  type="text"
                  placeholder="search page"
                  className="inp-pdf-search"
                />
              </div>
              <div className="pdf-border mt-1">
                <iframe
                  title="PDF"
                  src={pdfData}
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </Col>
            <Col xs={3}>
              <div className="right-col-btns">
                <div
                  className="sett-save-btn2"
                  onClick={() => HandleNavBack("MasterForm")}
                >
                  <span>Discard</span>
                  <img
                    alt=""
                    className="ml-2"
                    src={delw}
                    width={12.57}
                    height={14.67}
                  />
                </div>

                <div
                  className="sett-save-btn2"
                  // style={{ pointerEvents: !StopEvents() && "none" }}
                  onClick={() => HandleFinalSave()}
                >
                  <span>
                    {isSave
                      ? "SavingChanges"
                      : isSave2
                      ? "Changes Saved"
                      : "Save"}
                  </span>
                  {isSave ? (
                    <CgSpinner className="ml-2 " width={50} />
                  ) : isSave2 ? (
                    <FaCheck className="ml-2 " width={50} />
                  ) : (
                    <img
                      alt=""
                      className="ml-2 "
                      src={save}
                      width={12.8}
                      height={12.8}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className="FieldTitle">Field Name</div>
                <input
                  type=""
                  className="selectinpstyles"
                  name="ismasterField"
                  value={ismasterField}
                  onChange={(e) => HandleMasterField(e)}
                  onBlur={() => HandleMasterBlur()}
                />
                {ismasterField?.length > 0 && (
                  <>
                    <div className="FieldTitle">Form Name</div>
                    <select
                      className="selectinpstyles mt-1"
                      onChange={(e) => HandlGetFields(e)}
                      value={isField}
                      name="isField"
                    >
                      <option className="cursor-p" hidden>
                        Please Select
                      </option>
                      {newArr?.map((i) => (
                        <option className="cursor-p">{i?.name}</option>
                      ))}
                    </select>
                  </>
                )}
                {isLoad ? (
                  <div className="rightColum-load">Loading ....</div>
                ) : (
                  <div className="rifht-fields-scroll mt-2">
                    {isField?.length > 0 && isField !== "workflow" && (
                      <div>
                        <div className="FieldTitle">
                          Static {isField} Fields
                        </div>
                        <select
                          className="selectinpstyles mt-1"
                          onChange={(e) => HandleSubField(e)}
                          name="fieldname"
                          value={fieldname}
                        >
                          <option>please select</option>
                          {getFields?.map((i) => (
                            <option value={i}>{i}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    {isField === "workflow" && (
                      <>
                        <div className="FieldTitle">Select Step Type : </div>
                        <select
                          className="selectinpstyles mt-1"
                          onChange={(e) => HandleStepData(e)}
                          name="isstepType"
                          value={isstepType}
                        >
                          <option className="cursor-p" hidden>
                            Select Step Type
                          </option>
                          {StepTypes?.map((i) => (
                            <option className="cursor-p" value={i?.name}>
                              {i?.name}
                            </option>
                          ))}
                        </select>

                        <div className="FieldTitle">Select Field: </div>
                        <select
                          className="selectinpstyles mt-1"
                          onChange={(e) => HandleSelectField(e)}
                          name="isStepField"
                          value={isStepField}
                        >
                          <option className="cursor-p" hidden>
                            Select Field
                          </option>
                          <option>Comment</option>
                          <option>Image</option>
                          {isstepType === "Yes/No Selection" ? (
                            <>
                              <option>Yes</option>
                              <option>No</option>
                              <option>Other</option>
                            </>
                          ) : isstepType === "Create Drop Down" ? (
                            <>
                              <option>Selections Only</option>
                            </>
                          ) : (
                            isstepType === "Add Input Field" && (
                              <option>Input Value</option>
                            )
                          )}
                        </select>
                      </>
                    )}

                    {/* <div
                      className="mt-1"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      {fieldname?.length > 0 && isleftClr?.name?.length && (
                        <div
                          className="sett-save-btn2"
                          onClick={() => SaveMappedField()}
                        >
                          {" "}
                          Save
                        </div>
                      )}
                    </div> */}
                    <div
                    // onClick={() => HandleCreatePDF()}
                    // className="sett-save-btn2"
                    >
                      {/* create PDF */}
                      {/* <DempPdf data={isFieldsData} pdfData={pdfData} /> */}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
