import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProgressBar from "../../../../common/navigation/ProgressBar";
import { NavigateFunction } from "../../../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import plus from "../../../../common/Assets/Icons/plusvector.svg"
import DataTable from "react-data-table-component";
import CommonHeader from "../../../../common/framework/header/HeaderCommon/CommonHeader";
import MemoryIcon from "../../../../common/Assets/Icons/Memory Board Icon.svg";
import {  Navbar, Container, Col, Row } from "react-bootstrap";
import { TABLE_CUSTOMSTYLES } from "../../../../common/components/workordertable/Table";
import { TABLE_VIEW_EQUIPMENTS_KEYS } from "../../../../common/components/Constants";
import sorts from "../../../../common/Assets/Icons/sort.svg";
import tblcol from "../../../../common/Assets/Icons/TblCol.svg";
import VectorEqpBlue from "../../../../common/Assets/Images/VectorEqpBlue.svg";
import left from "../../../../common/Assets/Icons/leftAr.svg";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { getAllEquipmentlist, GetMemory, getWorkOrderById } from "../../../../services/WorkOrdersService";
import MemoryblueModal from "../../../../common/components/memorymodal/MemoryblueModal";
import MemoryModal from "../../../../common/components/memorymodal/MemoryModal";

function ViewEquipmentsTable(props) {
  const params = useParams();
  const location = useLocation();
  // const equipments = useState(location?.state?.equipments || []);
  const [sortedEqpTab, setSortedEqpTab] = useState(location?.state);
  const wrkId = window.location.href.split("/")[5]
  const techId = window.location.href.split("/")[7]
  const custId = window.location.href.split("/")[6]
  const Viewworkorder = useCallback(async () => {
    setIsSpin(true)
    try {
      if(wrkId > 0)
      {
        const Result = await getWorkOrderById(wrkId, techId, custId);
        setIsSpin(false)
        setSortedEqpTab(Result?.WorkOrderDetails?.equipments)
      }
      else{
        const Result = await getAllEquipmentlist(custId);
        setIsSpin(false)
        setSortedEqpTab(Result?.data)
      }
    } catch (error) { }
  },[custId, techId, wrkId])
  useEffect(() => {
    Viewworkorder();
  }, [Viewworkorder]);

  const navToPage1 = (to, params, data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("isWoid", JSON.stringify(params));
    }
    NavigateFunction({
      to: to,
      params: { params: params, data },
      navigate: navigation,
    });
  };
  const [mryobj, setMryObj] = useState(null);
  const updateMemoryBoardStatus = (id, data) => {
    const updatedTab = sortedEqpTab?.map((item) => {
            if (item?.equipment_id === womemoryopt?.equipment_id) {
              return {
                ...item,
                memoryBoard: id === "delete" ? 0 : 1,
              };
            }
            return item;
          });
          setSortedEqpTab(updatedTab);
  };

  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [womemoryopt, setWomemoryopt] = useState({});
  const [mryblue, setMryblue] = useState(false);
  const [mrydlt, setMrydlt] = useState(null);
  const [getmemory, setgetmemory] = useState("");
  const [memobj, setMemobj] = useState({});
  const [itemkey, setItemkey] = useState("");
  const [ismemProgress, setIsMemProgress] = useState(false);
  const [isMemoryModalOpen, setIsMemoryModalOpen] = useState(false);
  const HandleMemoryIconClick = async(type, e, item) => {
    e.stopPropagation();
    if (type === "black") {
      setIsMemoryModalOpen(true);
      setWomemoryopt(item);
      // setIsMemory(true);
      let items = { viewtype: 10, Reftype: 11, RefId: +item?.equipment_id };
      setIsMemoryObj(items);
    } else {
      setWomemoryopt(item);
      setItemkey(type);
      setMryblue(true);
      let items = { viewtype: 10, Reftype: 11, RefId: +item?.equipment_id };
      setIsMemoryObj(items);
      setMemobj(item);
      setIsMemProgress(true)
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
        );
        if (Result?.success) {
          setIsMemProgress(false)
        }
        
        setgetmemory(Result?.data);
        setMrydlt(Result?.data?.recent_view_id)
  
    }
  };
  const descvalue = getmemory?.note;
  const columns = [
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_NAME}
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <div className="table-woid" style={{ cursor: "pointer" }}>
    //       {row.equipment_name}
    //     </div>
    //   ),
    //   width: "150px",
    // },
    {
      name: (
        <div className="table-head-cells">
          <img src={sorts} alt="" />
        </div>
      ),
      selector: (row) => (<span className="table-date">
      {row?.memoryBoard > 0 ? (
        // || (mryobj > 0 && +row?.id === mryobj?.id > 0)
        <img
          onClick={(e) => HandleMemoryIconClick("eqps", e, row)}
          src={VectorEqpBlue}
          alt="memoryIconBlue"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <img
          onClick={(e) => {
            HandleMemoryIconClick("black", e, row);
          }}
          width={22}
          src={MemoryIcon}
          alt="MemoryIcon"
          style={{ cursor: "pointer" }}
        />
      )}
    </span>
  ),
      width: "80px",
    },
    {
      name: (
        <div className="table-head-cells">
          {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_TYPE}
        </div>
      ),
      selector: (row) => <div className="table-woid" style={{ cursor: "pointer" }}><span onClick={() => navToPage1(`AddEquipments/${params.isWoid}/${params.custid}/${params.techid}/${params.techid1}/${params.compid}/0`, "equipdetails", row)}>{row.equipment_type}</span></div>,
      width: "135px",
    },
    {
      name: (
        <div className="table-head-cells">
          {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_MODEL_NO}
        </div>
      ),
      selector: (row) => (
        <div className="table-woid" style={{ cursor: "pointer" }}>
          <span onClick={() => navToPage1(`AddEquipments/${params.isWoid}/${params.custid}/${params.techid}/${params.techid1}/${params.compid}/0`, "equipdetails", row)}>{row.model_number}</span>
        </div>
      ),
      width: "130px",
    },
    {
      name: (
        <div className="table-head-cells">
          {TABLE_VIEW_EQUIPMENTS_KEYS.SERIAL_NO}
        </div>
      ),
      selector: (row) => <div className="table-date">{row.serial_number}</div>,
      width: "135px",
    },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.STATUS}
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <div className="table-date" style={{ textTransform: "capitalize" }}>
    //       {row.status}
    //     </div>
    //   ),
    //   width: "100px",
    // },
    {
      name: (
        <div className="table-head-cells2">
          {TABLE_VIEW_EQUIPMENTS_KEYS.MANUFACTURER}
        </div>
      ),
      selector: (row) => <div className="table-date">{row.manufacturer_id}</div>,
      width: "170px",
    },
    {
      name: (
        <div className="table-head-cells2">
          {TABLE_VIEW_EQUIPMENTS_KEYS.CONDITION}
        </div>
      ),
      selector: (row) => <div className="table-date">{row.manufacturer_code}new</div>,
      width: "170px",
    },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.PART_NUMBER}
    //     </div>
    //   ),
    //   selector: (row) => <div className="table-date">{row.equipment_id}</div>,
    //   width: "95px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.YEAR_OF_CREATION}
    //     </div>
    //   ),
    //   selector: (row) => row.year_of_creation,
    //   width: "120px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.PAID}
    //     </div>
    //   ),
    //   selector: (row) => <div className="table-date">{row.paid}</div>,
    //   width: "70px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.QUANTITY}
    //     </div>
    //   ),
    //   selector: (row) => <div className="table-date">{row.quantity}</div>,
    //   width: "100px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.COST}
    //     </div>
    //   ),
    //   selector: (row) => <div className="table-date">{row.cost}</div>,
    //   width: "70px",
    // },
    // {
    //   name: (
    //     <div className="table-head-cells">
    //       {TABLE_VIEW_EQUIPMENTS_KEYS.LAST_SERVICED}
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <div className="table-date">
    //       {moment(row.last_serviced_date).format("MM/DD/YYYY")}
    //     </div>
    //   ),
    //   width: "135px",
    // },
    {
      name: (
        <div className="table-head-cells">
          {TABLE_VIEW_EQUIPMENTS_KEYS.END_OF_WARRENTY}
        </div>
      ),
      selector: (row) => (
        <div className="table-date">
          {moment(row.end_of_warrenty).format("MM/DD/YYYY")}
        </div>
      ),
      width: "155px",
    },
    {
      name: (
        <div className="table-head-cells">
          {TABLE_VIEW_EQUIPMENTS_KEYS.NOTE}
        </div>
      ),
      selector: (row) => (
        <div className="table-date">{row.note}</div>
      ),
      width: "140px",
    },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.EQUIPMENT_LOCATION}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">{row.equipment_location}</div>
    //     ),
    //     width: "190px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.AREA_SERVICED}
    //       </div>
    //     ),
    //     selector: (row) => <div className="table-date">{row.area_served}</div>,
    //     width: "140px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.BUILDING_NAME}
    //       </div>
    //     ),
    //     selector: (row) => <div className="table-date">{row.building_name}</div>,
    //     width: "145px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.PRIM_SERV_REP}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">{row.primary_service_representive}</div>
    //     ),
    //     width: "145px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.RATING_KWS_CAPS}
    //       </div>
    //     ),
    //     selector: (row) => <div className="table-date">{row.rating_cap_kws}</div>,
    //     width: "165px",
    //   },

    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.MAINTAINENCE_ISSUE}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">
    //         {row.maintenance_issue ? "true" : "false"}
    //       </div>
    //     ),
    //     width: "160px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.WARRENTY}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">{row.warranty ? "true" : "false"}</div>
    //     ),
    //     width: "160px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.DO_NOT_OPERATE}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">{row.not_operate ? "true" : "false"}</div>
    //     ),
    //     width: "160px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.NEED_ADDITIONAL_VISITS}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">
    //         {row.needs_additional_visit ? "true" : "false"}
    //       </div>
    //     ),
    //     width: "160px",
    //   },
    //   {
    //     name: (
    //       <div className="table-head-cells">
    //         {TABLE_VIEW_EQUIPMENTS_KEYS.SAFETY_ISSUE}
    //       </div>
    //     ),
    //     selector: (row) => (
    //       <div className="table-date">{row.safety_issue ? "true" : "false"}</div>
    //     ),
    //     width: "160px",
    //   },
  ];
  const customStyles = TABLE_CUSTOMSTYLES;
  // const sortByAsc = (data, key, keyType = "number") => {
  //   let newData = [];
  //   if (keyType === "string") {
  //     newData = [...data].sort(function (a, b) {
  //       return a[key].localeCompare(b[key]);
  //     });
  //   } else {
  //     newData = [...data].sort(function (a, b) {
  //       return a[key] - b[key];
  //     });
  //   }
  //   return newData;
  // };

  // const sortByDesc = (data, key, keyType = "number") => {
  //   let newData = [];
  //   if (keyType === "string") {
  //     newData = [...data].sort(function (a, b) {
  //       return b[key].localeCompare(a[key]);
  //     });
  //   } else {
  //     newData = [...data].sort(function (a, b) {
  //       return b[key] - a[key];
  //     });
  //   }
  //   return newData;
  // };
  const [isSpin, setIsSpin] = useState(false);
  const [isExpand, setIsExpand] = useState("");
  const [over, setOver] = useState(false);
  const navigation = useNavigate();
  const navToPage = (to, params,data) => {
    let WoNo = window.location.href.split("/")[5];
    localStorage.setItem("WoNo", JSON.stringify(parseInt(WoNo)));
    if (to === "ViewEquipments") {
      localStorage.setItem("WorkId", JSON.stringify(params));
    }

    NavigateFunction({
      to: to,
      params: {params:params,data},
      navigate: navigation,
    });
  };
  return (
    <>
      <div>
        <CommonHeader />
        <Navbar bg="" expand="lg">
          <Container fluid>
            <img src={left} alt="back button" onClick={() => navigation(-1)} style={{ cursor: "pointer" }} />
            <Navbar.Brand>
              <img
                className="ml-2 cursor-p"
                onClick={() => navigation(-1)}
                src={tblcol}
                alt="table for view Equipments"
              />
            </Navbar.Brand>
            <Navbar.Brand className="hd1-ve mr-2">Equipment</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              {/* <Nav
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  maxHeight: "100px",
                }}
                className="me-auto my-1 my-lg-0 flx-wrp"
                navbarScroll
              >
                <span className="srt-ve ">{WORK_ORDER_CONSTANTS.SORT_BY}:</span>
                <button
                  className="sort-btn-ve "
                  onClick={() => handleSortTab("equipment_modal")}
                >
                  {WORK_ORDER_CONSTANTS.MODEL_NO}
                  <img src={sorts} alt="click to sort" className="ml-1" />
                </button>
                <button
                  className={
                    statusVt === "serial_number"
                      ? "sort-btn-ve-on ml-1"
                      : "sort-btn-ve ml-1"
                  }
                  onClick={() => handleSortTab("serial_number")}
                >
                  {WORK_ORDER_CONSTANTS.SERIAL_NO}
                  <img src={sorts} alt="click to sort" className="ml-1" />
                </button>

                <button
                  className="sort-btn-ve ml-1"
                  onClick={() => handleSortTab("manufacturer")}
                >
                  {WORK_ORDER_CONSTANTS.MANUFACTURER}

                  <img src={sorts} alt="click to sort" className="ml-1" />
                </button>

                <button type="button" disabled className="sort-btn-ve ml-1">
                  {WORK_ORDER_CONSTANTS.TECH_ASSIGNED}
                  <img src={sorts} alt="click to sort" className="ml-1" />
                </button>

                <button
                  className="sort-btn-ve ml-1"
                  onClick={() => handleSortTab("status")}
                >
                  {WORK_ORDER_CONSTANTS.ACTIVE_INACTIVE}
                  <img src={sorts} alt="click to sort" className="ml-1" />
                </button>

                <button className="crt-eqp-ve mt-1 mx-2">
                  {EQUIPMENT_DETAILS.CREATE_EQP}
                </button>
              </Nav> */}
            </Navbar.Collapse>
            {/* <img src={filterimg} alt="filter" /> */}
            <button className="cancl-btn" onClick={() => navToPage(`AddEquipments/${params.isWoid}/${params.custid}/${params.techid}/${params.techid1}/${params.compid}/0`, "equipcreate",0)}>
              <span>Create New Equipment</span>&nbsp;
              <img src={plus} alt="Create" className="closeic1" />
            </button>
          </Container>
        </Navbar>
        <Row className="w-100">
          {isExpand !== "expand" ? (
            ""
          ) : (
            <Col
              onMouseOver={() => setOver(true)}
              onMouseOut={() => setOver(false)}
              style={{ pointerEvents: isSpin === true && "none" }}
              xs={isExpand === "expand" ? 12 : 2}
              md={isExpand === "expand" ? 12 : 2}
              lg={isExpand === "expand" ? 0 : 2}
              className="workOrder-col1"
            >
            </Col>
          )}
          <Col
            xs={isExpand === "expand" ? 12 : 10}
            md={isExpand === "expand" ? 12 : 10}
            lg={isExpand === "expand" ? 12 : 10}
            className="workOrder-col2 w-100"
          >
            {isSpin ? (
              <div className="progress-bar">
                <ProgressBar />
              </div>
            ) : (
              <>
                {/* <span
                  className={
                    isExpand === "expand" || over
                      ? "backbtn-hvr2  p-0 m-0"
                      : "backbtn-hvr p-0 m-0"
                  }
                >
                  {isExpand === "expand" ? (
                    <MdChevronRight
                      size={25}
                      onClick={() => HandleExpand("")}
                    />
                  ) : (
                    <MdChevronLeft
                      size={25}
                      onClick={() => HandleExpand("expand")}
                    />
                  )}
                </span> */}
                <div className="mx-2 mt-2 card p-1 w-100">
                  <div style={{ border: "1px solid black" }}>
                    <DataTable
                      columns={columns}
                      data={sortedEqpTab}
                      // selectableRows
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
        <MemoryModal
        isOpen={isMemoryModalOpen}
        id={womemoryopt?.equipment_id}
        name={"EQP"}
        descvalue={descvalue}
        isClose={() => setIsMemoryModalOpen(false)}
        isProgress={ismemProgress}
        ismemoryObj={ismemoryObj}
        womemoryopt={womemoryopt}
        woId={0}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
      />
      <MemoryblueModal
        Open={mryblue}
        Close={() => setMryblue(false)}
        ismemoryObj={ismemoryObj}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        setMryObj={setMryObj}
        custname={memobj?.Customer?.name}
        memobj={memobj}
        mrydlt={mrydlt}
        itemkey={itemkey}
        descvalue={descvalue}
        headname = {itemkey === "eqps" 
          ? `${memobj?.equipment_type || ''} / ${memobj?.manufacturer_id || ''}`.trim() 
          : ""}
        headid={itemkey === "eqps" ? Number(memobj?.equipment_id) : ""}
        openName={itemkey === "eqps" ? "Equipment" : ""}
      />
      </div>
    </>
  );
}

export default ViewEquipmentsTable;
