import React, { useEffect, useState } from "react";
import CompanyDetails from "./Company Details/CompanyDetails";
import CommonHeader from "../../../common/framework/header/HeaderCommon/CommonHeader";
import { Col, Row } from "react-bootstrap";
import WorkOrderSetup from "./Work Order Setup/WorkOrderSetup";
import UserSetup from "./User Setup/UserSetup";
import WorkFlowSetUp from "./Work Flow setup/WorkFlowSetUp";
import Integrations from "./Integrations/Integrations";
import MasterForm from "./Master Form/MasterForm";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useLocation } from "react-router-dom";

function Settings() {
  const location = useLocation();
  useEffect(() => {
    // let locPath = window?.location?.href?.includes("Settings")
    setIsState(location?.state ? location?.state : sessionStorage.getItem('load'));
  }, [location]);
  const [isState, setIsState] = useState("Details");
  const [isSubhead, setisSubHead] = useState(0);
  const [isField, setIsField] = useState(0);
  const [isUserField, setIsUserField] = useState(0);
  const [isInt, setIsInt] = useState(0);
  const [isExpand, setIsExpand] = useState("");
  // const [over, setOver] = useState(false);
  const HandleStateUpdate = (updateState) => {
    setIsState(updateState);
    setIsInt(0);
    // localStorage.setItem('load',updateState)
    sessionStorage.setItem('load',updateState)
  };
  const HandleToggle = (toggle) => {
    setIsExpand(toggle);
  };
  const HandleSubHead = (item) => {
    setisSubHead(item);
  };
  const handleFieldSetup = (item) => {
    setIsField(item);
  };
  const handleFieldSetup2 = (item) => {
    setIsUserField(item);
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleInt = (item) => {
    setIsInt(item);
  };
  return (
    <>
      <div>
        <CommonHeader />
      </div>
      <div>
        <Row>
          {isExpand !== "expand" && (
            <Col
              // onMouseOver={() => setOver(true)}
              // onMouseOut={() => setOver(false)}
              xs={isExpand === "expand" ? 12 : 2}
              md={isExpand === "expand" ? 12 : 2}
              lg={isExpand === "expand" ? 0 : 2}
              className="" 
            >
              <div className="settings-left-menu setting_left">
                <div
                  className={
                    isState === "Details"
                      ? "settings-left-sub-menu"
                      : "mx-2 setting_ul"
                  }
                 >
                  <div
                    className={
                      isState === "Details"
                        ? "settings-left-menu-title2"
                        : "settings-left-menu-title"
                    }
                    onClick={() => HandleStateUpdate("Details")}
                  >
                    Company Details
                  </div>
                  {isState === "Details" && (
                    <div className="settings-left-sub-menu-title">
                      <span
                        onClick={() =>
                          scrollToSection("theme-preferences-section")
                        }
                      >
                        Details
                      </span>
                      <span onClick={() => scrollToSection("details-section")}>
                        Division
                      </span>
                      <span
                        onClick={() =>
                          scrollToSection("company-preferences-section")
                        }
                      >
                        Company Preferences
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isState === "FieldSetup"
                      ? "settings-left-sub-menu"
                      : "mx-2 setting_ul"
                  }
                >
                  <div
                    className={
                      isState === "FieldSetup"
                        ? "settings-left-menu-title2"
                        : "settings-left-menu-title"
                    }
                    onClick={() => HandleStateUpdate("FieldSetup")}
                  >
                    Field Setup
                  </div>
                  {isState === "FieldSetup" && (
                    <div className="settings-left-sub-menu-title">
                      <span onClick={() => handleFieldSetup(0)}>
                        Work Order Types
                      </span>
                      {/* <span onClick={() => handleFieldSetup(1)}>
                        WorkOrder Fields
                      </span> */}
                       <span onClick={() => handleFieldSetup(2)}>
                        Work Order Additional Fields
                      </span>
                      <span onClick={() => handleFieldSetup(3)}>
                        Work Order Status
                      </span>
                      <span onClick={() => handleFieldSetup(4)}>
                        Call Types
                      </span>
                      <span onClick={() => handleFieldSetup(5)}>
                        Customer Additional Fields
                      </span>
                      <span onClick={() => handleFieldSetup(6)}>
                        Equipment Types
                      </span>
                     
                      <span onClick={() => handleFieldSetup(7)}>
                        Equipment Manufacturers
                      </span>
                      {/* <span onClick={() => handleFieldSetup(6)}>
                        Contact Additional Fields
                      </span> */}
                     
                      <span onClick={() => handleFieldSetup(8)}>
                        Equipment Additional Fields
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isState === "UserSetup"
                      ? "settings-left-sub-menu"
                      : "mx-2 setting_ul"
                  }
                >
                  <div
                    className={
                      isState === "UserSetup"
                        ? "settings-left-menu-title2"
                        : "settings-left-menu-title"
                    }
                    onClick={() => HandleStateUpdate("UserSetup")}
                  >
                    User Setup
                  </div>
                  {isState === "UserSetup" && (
                    <div className="settings-left-sub-menu-title">
                      <span onClick={() => handleFieldSetup2(0)}>Teams</span>
                      {/* <span>Role</span> */}
                      <span onClick={() => handleFieldSetup2(1)}>
                        User Setup
                      </span>
                      <span onClick={() => handleFieldSetup2(2)}>
                        Technician Setup
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isState === "WorkFlowSetUp"
                      ? "settings-left-sub-menu"
                      : "mx-2 setting_ul"
                  }
                >
                  <div
                    className={
                      isState === "WorkFlowSetUp"
                        ? "settings-left-menu-title2"
                        : "settings-left-menu-title"
                    }
                    onClick={() => HandleStateUpdate("WorkFlowSetUp")}
                  >
                    WorkFlows and Tags
                  </div>
                  {isState === "WorkFlowSetUp" && (
                    <div className="settings-left-sub-menu-title">
                      <span onClick={() => HandleSubHead(0)}>
                        Workflow Categories
                      </span>

                      <span onClick={() => HandleSubHead(2)}>Workflow</span>
                      <span onClick={() => HandleSubHead(3)}>
                        {" "}
                        Workflows Automation
                      </span>

                      <span onClick={() => HandleSubHead(4)}>Tag Manager</span>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isState === "MasterForm"
                      ? "settings-left-sub-menu"
                      : "mx-2 setting_ul"
                  }
                >
                  <div
                    className={
                      isState === "MasterForm"
                        ? "settings-left-menu-title2"
                        : "settings-left-menu-title"
                    }
                    onClick={() => HandleStateUpdate("MasterForm")}
                  >
                    Master Form
                  </div>
                  {isState === "MasterForm" && (
                    <div className="settings-left-sub-menu-title">
                      <span>Form Catalog</span>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isState === "Integrations"
                      ? "settings-left-sub-menu"
                      : "mx-2 setting_ul"
                  }
                >
                  <div
                    className={
                      isState === "Integrations"
                        ? "settings-left-menu-title2"
                        : "settings-left-menu-title"
                    }
                    onClick={() => HandleStateUpdate("Integrations")}
                  >
                    Integrations
                  </div>
                  {isState === "Integrations" && (
                    <div className="settings-left-sub-menu-title">
                      {/* <span>WorkOrder Fields</span> */}
                      <span onClick={() => handleInt(0)}>WorkOrder Fields</span>
                      {/* <span>Contact Fields</span> */}
                      {/* <span>Contact Additional Fields</span> */}
                      {/* <span>Customer Fields</span> */}
                      <span onClick={() => handleInt(1)}>Contact Fields</span>
                      {/* <span>Equipment Fields</span> */}
                      <span onClick={() => handleInt(2)}>Customer Fields</span>
                      <span onClick={() => handleInt(3)}>Equipment Fields</span>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          )}
          <Col
            xs={isExpand === "expand" ? 12 : 10}
            md={isExpand === "expand" ? 12 : 10}
            lg={isExpand === "expand" ? 12 : 10}
            className="" style={{ paddingLeft: "0px"}}
          >
            <div style={{ padding: "10px 10px 0px 10px"}} className="">
              <span
                className={
                  isExpand === "expand" ? "lftSide-icon" : "rtSide-icon"
                }
              >
                {isExpand === "expand" ? (
                  <MdChevronRight size={25} onClick={() => HandleToggle("")} />
                ) : (
                  <MdChevronLeft
                    size={25}
                    onClick={() => HandleToggle("expand")}
                  />
                )}
              </span>

              {isState === "Details" ? (
                <div className="card">
                  <CompanyDetails />
                </div>
              ) : isState === "FieldSetup" ? (
                <div className="card">
                  <WorkOrderSetup isField={isField} />
                </div>
              ) : isState === "UserSetup" ? (
                <UserSetup isUserField={isUserField} />
              ) : isState === "WorkFlowSetUp" ? (
                <div className="card">
                  <WorkFlowSetUp isSubhead={isSubhead} />
                </div>
              ) : isState === "MasterForm" ? (
                <div style ={{
                  marginTop:"10px",
                  fontSize:"24px"
                 }}>
                  <span className="top_header" style={{
                    fontWeight:"bold",
                    width: "100%",
                  float: "left",
                border: "solid 1px #dedede",
                }}>Master Form</span>
                <div className="card mt-2">
                  <MasterForm />
                </div>
                </div>
              ) : (
                <div className="card">
                  <Integrations isInt={isInt} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        {/* <LoginFooter /> */}
      </div>
    </>
  );
}

export default Settings;
