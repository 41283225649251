import React from "react";
import { useState, useEffect } from "react";
import "./memorymodal.css";
import { Modal } from "react-bootstrap";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { TAGS_MODAL_VALUES } from "../Constants";
import {PostMemory } from "../../../services/WorkOrdersService";
import ViewType from "../EnumViewType";
import ProgressBar from "../../navigation/ProgressBar";
export default function MemoryModal(props) {
  const [isProgress, setIsProgress] = useState(false);
  const [memoryDescription, setMemoryDescription] = useState("");
  const [errorEmptyDescription, setErrorEmptyDescription] = useState(false);
  const [errorDescriptionMessage, setErrorDescriptionMessage] = useState("");
  const [description, setDescription] = useState("");
  const maxLength = 200;
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= maxLength) {
      setDescription(newValue);
      setMemoryDescription(newValue);
      if (errorEmptyDescription) {
        setErrorEmptyDescription(false);
      }
    }
  };
  const handleSave = async () => {
    if (description.trim() === "") {
      setErrorEmptyDescription(true);
    } else {
      const inputObj = {
        view_type: ViewType.MEMORY_BOARD,
        reference_type: props?.ismemoryObj?.Reftype,
        reference_id: props?.ismemoryObj?.RefId,
        resolve: 1,
        note: memoryDescription,
        work_order_id: props?.woId,
      };

      if (memoryDescription.length > 0) {
        setIsProgress(true)
        const response = await PostMemory(inputObj);
        setIsProgress(false)
        if (response?.success) {
          props?.isClose();
          props?.updateMemoryBoardStatus(1, response?.data);
          
        }
      } else {
        setErrorDescriptionMessage("Please enter a description");
      }
      resetFields();
    }
  };

  const resetFields = () => {
    setDescription("");
    setMemoryDescription("");
    setErrorEmptyDescription(false);
    setErrorDescriptionMessage("");
  };

  useEffect(() => {
    if (props.isOpen) {
      setDescription("");
      setMemoryDescription("");
      setErrorEmptyDescription(false);
      setErrorDescriptionMessage("");
    }
  }, [props.isOpen]);

  const handleClose = () => {
    resetFields();
    props?.isClose();
  };
  
  // const HandleCloseMemory = () => {
  //   setMemoryDiscription("");
  //   setErrDisc("");
  //   setDescription("");
  //   setgetmemory("");
  // };
  return (
    <Modal show={props.isOpen} onHide={handleClose} centered>
      <Modal.Header className="modal-bg-color modal-header-height px-2">
        <span style={{ fontSize: "13px", color: "white" }}>
          Add Memory Board Note to {props?.name} #{props?.id}
        </span>
        <img
          src={Close}
          className="modal-header-icon"
          onClick={handleClose}
          alt="Close"
        />
      </Modal.Header>
      {isProgress === true ? (
          <div className="progress-bar"  style={{ height: "300px" }}>
            <ProgressBar />
          </div>
        ) : (
      <Modal.Body className="cursor-p">
        <textarea
          placeholder="Please Enter Description"
          className="w-100 cursor-p inpt-add-eqp"
          name="memoryDescription"
          onChange={handleChange}
          value={memoryDescription} // Ensure textarea reflects the current state
          style={{ height: "300px", outline: "none" }}
        />
        {errorDescriptionMessage && (
          <span className="err-memory-field">{errorDescriptionMessage}</span>
        )}
        {errorEmptyDescription && (
          <span className="err-memory-field" style={{ color: "red", fontSize: "12px" }}>
            Empty input can't be saved
          </span>
        )}
        <div className="text-right" style={{ color: "gray", fontSize: "12px" }}>
          {`${description.length}/${maxLength}`}
        </div>
      </Modal.Body>
        )}
      <Modal.Footer className="modal-footer-styles justify-content-center">
        <div className="modal-bottom-styles-edit-notes">
          <div className="memory-add-btns" onClick={handleSave}>
            {TAGS_MODAL_VALUES.SAVE}
          </div>
          <div className="memory-add-btns" onClick={handleClose}>
            {TAGS_MODAL_VALUES.CANCEL}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}