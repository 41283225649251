import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import leftAr from "../../common/Assets/Icons/leftAr.svg";
import adj from "../../common/Assets/Icons/Adjt.svg";
import "./NotesDetails.css";
import acc from "../../common/Assets/Icons/acct.svg";
import book from "../../common/Assets/Icons/ObookB.svg";
import del from "../../common/Assets/Icons/del.svg";
import penP from "../../common/Assets/Icons/Pen.svg";
import vector from "../../common/Assets/Icons/vector.svg";
import downDir from "../../common/Assets/Icons/DownDir.svg";
import moment from "moment";
import {
  NoteDeleteFunction,
  NoteEditFunction,
  getWorkOrderById,
} from "../../services/WorkOrdersService";
import NoteEditModal from "./NoteEditModal";
import { NOTES_ADD_CONSTANTS } from "../../common/components/Constants";
import { TABLE_ÍCON_SIZES } from "../../common/components/Values";
import { useLocation, useNavigate } from "react-router-dom";
import Validation from "./Validations";

export default function NotesDetails() {
  const location = useLocation();
  const [NotesData, setNotesData] = useState([]);
  const [workorderId, setWorkorderId] = useState(0);
  const navigation = useNavigate();
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [isNoteObj, setIsNoteObj] = useState({});
  const [isVal, setIsVal] = useState("");
  const [toggledData, setToggledData] = useState([]);
  const [validationModal, setValidationModal] = useState(false);
  const HandleNavToWod = () => {
    navigation(-1);
  };
  useEffect(() => {
    let WorkNo = window.location.href.split("/");
    setWorkorderId(WorkNo[5]);
    getNotes(WorkNo[5], WorkNo[6], WorkNo[7]);
  }, [location]);
  const getNotes = async (woId, techId, cusId) => {
    let resultData = await getWorkOrderById(woId, techId, cusId);
    if (resultData) {
      setNotesData(
        resultData?.notes.sort(function (a, b) {
          return new Date(b.date_created) - new Date(a.date_created);
        })
      );
    } else {
      setNotesData(
        resultData?.notes.sort(function (a, b) {
          return new Date(b.date_created) - new Date(a.date_created);
        })
      );
    }
  };
  const HandleEditNote = (value, boolean) => {
    setIsNoteObj(value);
    setIsNoteOpen(true);
    setIsVal(boolean);
  };
  const [isupdateLoad, setIsUpdateLoad] = useState(false);
  const HandleDelteNote = async (value) => {
    setIsUpdateLoad(true);
    let NoteId = value.id;
    const Responce = await NoteDeleteFunction(NoteId);
    if (Responce.success) {
      setIsUpdateLoad(false);
      setIsNoteOpen(false);
      let FilteredData = NotesData.filter((item) => item !== value);
      setNotesData(FilteredData);
    }
  };
  const pull_data = (e) => {
    const { name, value } = e.target;
    setIsNoteObj({ ...isNoteObj, [name]: value });
  };
  const EditNotes = async (value) => {
    setIsUpdateLoad(true);
    let arrayIndex = window.location.href.split("/");
    let NoteId = value.id;
    let Obj = {
      note: value.content,
      title: value.title,
    };
    if (Obj.note && Obj.title) {
      const Responce = await NoteEditFunction(
        arrayIndex[6],
        arrayIndex[5],
        NoteId,
        Obj
      );
      let filterData = Responce.data.note;
      if (Responce.success) {
        let users = NotesData.map((item) =>
          item.id === NoteId ? filterData : item
        );
        setNotesData(users);
        setIsNoteOpen(false);
        setIsUpdateLoad(false);
      }
    } else {
      setValidationModal(true);
      setIsUpdateLoad(false);
    }
  };
  const HandleToggle = (item) => {
    let data = [...toggledData];
    if (data.includes(item.id)) {
      data = toggledData.filter((it) => it !== item.id);
    } else {
      data = [...data, item.id];
    }
    setToggledData(data);
  };
  const closeBothModals = () => {
    setValidationModal(false);
    setIsNoteOpen(false);
  };

  return (
    <>
      <div className="note-details-header">
        <div>
          <CommonHeader />
        </div>
        <div className="line-align-bet mt-2">
          <div className="note-img-style">
            <img
              src={leftAr}
              className="mx-3 cursor-p"
              onClick={() => HandleNavToWod()}
              alt="leftAr"
            />
            <div className="note-title-name">
              {NOTES_ADD_CONSTANTS.NOTE}({NotesData?.length})
            </div>
          </div>
          <img className="mx-5 " src={adj} alt="adjusting logo" />
        </div>
      </div>
      <div className="mx-4 mt-3">
        {NotesData?.length > 0 &&
          NotesData?.map((item) => (
            <div className="card note-card mt-2">
              <div
                className="line-align-bet"
                onClick={() => HandleToggle(item)}
              >
                <div className="d-flex">
                  <div className="mx-2">
                    {" "}
                    <img
                      src={acc}
                      alt="account note"
                      className="img-pointer opct-p7"
                    />
                  </div>
                  <div className="owner-note">{item.title}</div>
                </div>
                <div className="d-flex">
                  <div className="date-note">
                    {" "}
                    {moment(item.date_created).format(
                      TABLE_ÍCON_SIZES.NOTES_DATE_FORMAT
                    )}
                  </div>
                  <div className="mx-2">
                    <img
                      src={penP}
                      width={TABLE_ÍCON_SIZES.NOTES_PENP_SIZE}
                      alt="steps"
                      className="img-pointer opct-p7"
                      onClick={() => HandleEditNote(item, "Edit")}
                    />
                  </div>
                  <div className="mx-2">
                    {" "}
                    <img
                      src={del}
                      alt="del"
                      width={TABLE_ÍCON_SIZES.NOTES_PEN_SIZE}
                      className="img-pointer opct-p7"
                      onClick={() => HandleEditNote(item, "Delete")}
                    />
                  </div>
                  <div className="mx-2">
                    {" "}
                    <img
                      src={book}
                      alt="book"
                      className="opct-p7"
                      width={TABLE_ÍCON_SIZES.NOTES_PENP_SIZE}
                      onClick={() => HandleEditNote(item, "History")}
                    />
                  </div>
                  <div className="mx-2">
                    {toggledData.includes(item.id) ? (
                      <img
                        src={vector}
                        width={11}
                        alt="open details"
                        onClick={() => HandleToggle(item)}
                      />
                    ) : (
                      <img
                        src={downDir}
                        alt="account note"
                        className="leftArrow"
                        onClick={() => HandleToggle(item)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {toggledData.includes(item.id) ? (
                ""
              ) : (
                <div className="content-note mx-3">
                  {item.content || item.notes}
                </div>
              )}
            </div>
          ))}
      </div>
      <NoteEditModal
        isOpen={isNoteOpen}
        isClose={() => setIsNoteOpen(false)}
        data={isNoteObj}
        noteValue={isNoteObj.content}
        noteTitle={isNoteObj.title}
        isEdit={isVal}
        isDelete={() => HandleDelteNote(isNoteObj)}
        isEditVal={() => EditNotes(isNoteObj)}
        data2={pull_data}
        WorkId={workorderId}
        isLoading={isupdateLoad}
      />
      <Validation
        isOpen={validationModal}
        isClose={() => setValidationModal(false)}
        isDiscard={() => closeBothModals()}
      />
    </>
  );
}

/*previosuly base on tech id notes can have a feature for upate and delete
 But now, that feature has changed. That is notes array will same tech id*/

/* || (item.created_by === parseInt(localId))) */
/* {item.owner === parseInt(localId) && */
