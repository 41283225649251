import { NINEPACK_URL } from "./Url";
import axios from "axios";
import { APP_JSON } from "../common/components/Constants"; 


export const authLogin = async (
  techcode,
  techpin,
  companycode,
  sponsor_id,
  ip_address,
  os,
  device,
  app_type
) => {
  try {
    const res = await axios.get(
      `${NINEPACK_URL}/auth/${techcode}/${techpin}/company/${companycode}?ip_address=${ip_address}&os=${os}&device=${device}&app_type=${app_type}`,    
        {
        headers: {
          Accept: APP_JSON,
          CONTENT_TYPE: APP_JSON,
        },
      }
    );

    return res.data;
  } catch (err) {}
};

export const getAllTechnician = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/technician/list`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data?.result?.data;
  } catch (error) {}
};

export const getCompanyDetails = async () => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.get(`${NINEPACK_URL}/company/fetch/1001`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
    return response?.data?.result?.data;
  } catch (error) {}
}

export const putForgetpaswd = async (Obj) => {
  try {
 
    const response = await axios.put(`${NINEPACK_URL}/auth/forgetPassword`, Obj, );

    return response?.data;
  } catch (error) {
    }   
};
export const inviteshare = async (Obj) => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(`${NINEPACK_URL}/auth/invite`, Obj, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: 'application/json', 
        "Content-Type": 'application/json', 
      },
    });

    

    return response?.data;
  } catch (error) {
    }   
};


export const ChangePass = async (Email,userId,Obj) => {
  try {
    // const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.post(`${NINEPACK_URL}/auth/validateUser/${Email}/${userId}`, Obj,) 
    //   {
    //   headers: {
    //     Authorization: `Bearer ${TOKEN}`,
    //     Accept: 'application/json', 
    //     "Content-Type": 'application/json', 
    //   },
    // });
    return response?.data;
  } catch (error) {
    }   
};

export const putResetpaswd = async (Obj)  => {
  try {
    const TOKEN = JSON.parse(localStorage.getItem("authToken"));
    const response = await axios.put(`${NINEPACK_URL}/auth/reset_password`, Obj, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        Accept: APP_JSON,
        "Content-Type": APP_JSON,
      },
    });
     return response?.data;
  } catch (error) {}
};



