import React from "react";
import { Col, Row } from "react-bootstrap";
import del from "../../../../common/Assets/Icons/del.svg";

const WOAdditionalField = ({
  data,
  isDup,
  onFieldChange,
  emptyField,
  ErpFields,
  HandleDelWoAddFlds
}) => {
  return (
    <Row
      className="mx-0"
      style={{
        borderBottom:
          isDup?.filter((i) => i?.field_id === data?.field_id)?.length > 0
            ? "2px solid red"
            : "1px solid #d6d6d6",
      }}
    >
      <Col className="p-0" xs={2}>
        {" "}
        <input
          className="inp-tab-fld-sett w-100"
          placeholder={`Label Name ${data?.field_id}`}
          value={data?.name || ""}
          style={{ height: "" }}
          onChange={(e) =>
            onFieldChange(
              "name",
              e.target.value?.trimStart(),
              data?.field_id
            )
          }
        />
      </Col>
      <Col className="p-0" xs={2}>
        {" "}
        <input
          className="inp-tab-fld-sett w-100"
          placeholder={`Label Name ${data?.field_id}`}
          value={data?.packcolumn || ""}
          style={{ height: "" }}
          onChange={(e) =>
            onFieldChange(
              "packcolumn",
              e.target.value?.trimStart(),
              data?.field_id
            )
          }
        />
      </Col>
      <Col className=" p-0 " xs={2}>
        <select
          // className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 ${errorObj.includes('control_type')?"abc":""}`}
          className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 `}
          name="erpcolumn"
          value={data?.erpcolumn || ""}
          onChange={(e) =>
            onFieldChange("erpcolumn", e.target.value, data?.field_id)
          }
          style={{ display: "flex", alignItems: "center", outline: "none" }}
        >
          <option value={""}> Please Select</option>
          {data?.packcolumn?.length > 0 && ErpFields?.map((i) => (
            <option value={i?.name}>{i?.name}</option>
          ))}
        </select>
      </Col>
      <Col className=" p-0 " xs={1}>
        <select
          // className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 ${errorObj.includes('control_type')?"abc":""}`}
          className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 `}
          name="erpcolumn"
          value={data?.erpname || ""}
          onChange={(e) =>
            onFieldChange("erpname", e.target.value, data?.field_id)
          }
          style={{ display: "flex", alignItems: "center", outline: "none" }}
        >
          <option value={""}> Please Select</option>
          {ErpFields?.map((i) => (
            <option value={i?.name}>{i?.name}</option>
          ))}
        </select>
      </Col>
      <Col className=" p-0  " xs={1}>
        <select
          // className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 ${errorObj.includes('control_type')?"abc":""}`}
          className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 `}
          name="control_type"
          value={data?.control_type || ""}
          onChange={(e) =>
            onFieldChange("control_type", e.target.value, data?.field_id)
          }
          style={{ display: "flex", alignItems: "center", outline: "none" }}
        >
          <option value={""}> Please Select</option>
          <option value={"numeric"}>Numeric</option>
          <option value={"integer"}>Integer</option>
          <option value={"date"}>Date</option>
          <option value={"string"}>String</option>
        </select>
      </Col>
      <Col className=" p-0 d-flex  gap-2" xs={1}>
        <span className="Add-fields-types-char">Character Count:</span>
        <input
        disabled={data?.control_type === "date"}
          type="number"
          // className={`inpt-add-eqp lbl-ipt-add-eqp w-100  center-line-adj p-1 ${errorObj.includes('control_type')?"abc":""}`}
          className={`Add-fields-types p-1 `}
          name="characterlength"
          value={data?.characterlength || ""}
          onChange={(e) =>
            onFieldChange("characterlength", e.target.value, data?.field_id)
          }
          style={{ display: "flex", alignItems: "center", outline: "none" }}
        />
      </Col>
      {/* <Col className=" centerings p-0 " xs={2}>
        <input
          className="cursor-p WoFldsCheckbox"
          type="checkbox"
          checked={data?.visible}
          onChange={(e) =>
            onFieldChange("visible", e.target.checked, data?.field_id)
          }
        />
      </Col> */}
      <Col className=" centerings p-0 " xs={1}>
        <input
          className="cursor-p WoFldsCheckbox"
          type="checkbox"
          checked={data?.active}
          onChange={(e) =>
            onFieldChange("active", e.target.checked, data?.field_id)
          }
        />
      </Col>
      <Col className=" centerings p-0 " xs={1}>
        <input
          className="cursor-p WoFldsCheckbox"
          type="checkbox"
          checked={data?.required}
          onChange={(e) =>
            onFieldChange("required", e.target.checked, data?.field_id)
          }
        />
      </Col>
     
       <Col className="inp-tab-fld-sett centerings p-0 " xs={1}>
       <img src={del} alt="WOAddFlds" onClick={() =>HandleDelWoAddFlds(data)}/> 
      </Col>
    </Row>
  );
};

export default WOAdditionalField;
