import React, { useEffect, useState } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import "./AiModal.css";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import Save from "../../../common/Assets/Images/Unionsve.svg";
import discard from "../../../common/Assets/Icons/trash_main.svg";
import {
  RegExpMatcher,
  TextCensor,
  englishDataset,
  englishRecommendedTransformers,
} from "obscenity";

export default function AIModal(props) {
  const [options, setOptions] = useState(null);
  const [checkedState, setCheckedState] = useState([]);
  const [label, setLabel] = useState([]);
  const [value, setValue] = useState([]);
  const [aux, setAux] = useState(0);
  const [responseLabel, setResponseLabel] = useState([]);
  const [responseValue, setResponseValue] = useState([]);

  let description;
  let won;
  let reason;
  useEffect(() => {
    if (!props.Open) return;
    // "6LMOODZP93651YI09AKKC6GP3J4HA9F3"
    // "E9FBBNBMKF22GXL2YBLNCSJUF3D322ZP"
    const matcher = new RegExpMatcher({
      ...englishDataset.build(),
      ...englishRecommendedTransformers,
    });
    const censor = new TextCensor();
    const matches = matcher.getAllMatches(description);
    description = censor.applyTo(props.data.details.descption, matches);
    const fetchData = async () => {
      const matcher = new RegExpMatcher({
        ...englishDataset.build(),
        ...englishRecommendedTransformers,
      });
      const censor = new TextCensor();
      const matches = matcher.getAllMatches(props.data.details.descption);
      description = censor.applyTo(description, matches);
      try {
        const res = await axios.post(
          `https://api.sapling.ai/api/v1/rephrase`,
          {
            key: "6LMOODZP93651YI09AKKC6GP3J4HA9F3",
            text: description,
            mapping: "informal_to_formal",
          },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        );

        const options = res.data.results.map((rep) => rep.replacement);
      
        options.unshift(description);
        setOptions(options);
        setCheckedState(new Array(options.length).fill(false));

        let additionalFields = props.data.creatingFields;
        let newLabels = [];
        let newValues = [];

        if (description !== "") {
          newLabels.push("description");
          newValues.push(description);
        }

        if (props.data.details.won !== "") {
          newLabels.push("work order name");
          const matches = matcher.getAllMatches(props.data.details.won);
          won = censor.applyTo(props.data.details.won, matches);
          newValues.push(won);
        }

        if (props.data.details.reason !== "") {
          newLabels.push("reason");
          const matches = matcher.getAllMatches(props.data.details.reason);
          reason = censor.applyTo(props.data.details.reason, matches);
          newValues.push(reason);
        }

        for (let index = 0; index < additionalFields.length; index++) {
          const element = additionalFields[index];
          if (element.value !== "") {
            newLabels.push(element.name);
            newValues.push(element.value);
          }
        }

        setLabel(newLabels);
        setValue(newValues);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [props.Open, props.data.details.descption, props.data.creatingFields]);

  useEffect(() => {
    if (aux < value.length) {
      const fetchNewData = async () => {
        try {
          const res = await axios
            .post(
              `https://api.sapling.ai/api/v1/rephrase`,
              {
                key: "6LMOODZP93651YI09AKKC6GP3J4HA9F3",
                text: value[aux],
                mapping: "informal_to_formal",
              },
              {
                headers: {
                  Accept: "*/*",
                  "Content-Type": "application/json",
                },
              }
            )
          
          const options = await res.data.results.map((rep) => rep.replacement);
          setOptions(options);
          options.unshift(value[aux]);

          const selectedOption = options.find(
            (option, index) => checkedState[index]
          );
          if (selectedOption) {
            setResponseLabel((prevLabels) => [...prevLabels, label[aux]]);
            setResponseValue((prevValues) => [...prevValues, selectedOption]);
          }
        } 
        catch (err) {
          console.error("Error fetching new data:", err);
        }
      };

      fetchNewData();
    }
  }, [aux, value, label, checkedState]);

  const handleCardClick = (index) => {
    const updatedCheckedState = checkedState.map((item, idx) =>
      idx === index ? !item : false
    );
    setCheckedState(updatedCheckedState);
  };

  const handleDiscardChanges = () => {
    props.onDiscard();
  };

  const handleSaveAndExit = () => {
    const selectedOption = options.find((option, index) => checkedState[index]);
    props.onSave(responseLabel, responseValue);
    props.Close();
  };

  const handleSaveAndContinue = () => {
    if (aux === value.length) {

      props.onSave(responseLabel, responseValue);
      props.Close();
    } else {

      setAux((prevAux) => prevAux + 1);
    }


  };


  return (
    <div className="main">
      <Modal show={props.Open} onHide={props.Close} centered>
        <Modal.Header className="Sahre-modal-header modal-bg-color">
          <div className="">AI Changes: {label[aux]}</div>
          <div>
            <AiOutlineClose onClick={props.Close} className="Close-icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="main">
            {options ? (
              <div className="cards">
                {options.map((option, index) => (
                  <Card
                    className="card"
                    key={index}
                    onClick={() => handleCardClick(index)}
                  >
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Text className="headersItems">
                          {index === 0
                            ? `Keep Original: :`
                            : `Version ${index + 1}:`}
                        </Card.Text>
                        <Card.Text className="option-text">{option}</Card.Text>
                      </div>
                      <input
                        className="checks"
                        type="radio"
                        name="radioGroup"
                        checked={checkedState[index]}
                        onChange={() => handleCardClick(index)}
                      />
                    </Card.Body>
                  </Card>
                ))}
              </div>
            ) : (
              "Loading..."
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <Button className="model_footer_btn" onClick={handleDiscardChanges}>
            Discard Changes <img src={discard} alt="save" width={10} />
          </Button>
          <Button className="model_footer_btn" onClick={handleSaveAndExit}>
            Save and close <img src={Save} alt="save" width={10} />
          </Button>
          <Button className="model_footer_btn" onClick={handleSaveAndContinue}>
            Save and continue
            <img src={Save} alt="save" width={10} />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
