import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MediaCenterStyles.css";
import { Card } from "react-bootstrap";
import bookme from "../../common/Assets/Icons/bookDocs.svg";
import plusIcon from "../../common/Assets/Icons/add.svg";
import galleryicon from "../../common/Assets/Icons/galleryicon.svg";
import documenticon from "../../common/Assets/Icons/documenticon.svg";
// import PDF from "../../common/Assets/Icons/PDF.svg";
import {
  MEDIA_CENTER,
  STATUS_COMPLETED,
  LIBRARY_DET,
  SUCCESS_STATUS,
} from "../../common/components/Constants";
import {
  getFiles,
  getLibraryRecentItems,
  // getWorkOrderById,
  workOrdrList,
  WorkflowAttachment,
  MediaAttachments,
} from "../../services/WorkOrdersService";
import moment from "moment";
import ShareModal from "../../common/components/sharemodal/ShareModal";
import AttachmentModal from "../workorders/AttachmentModal";
import CustomerModal from "../../common/components/customermodal/CustomerModal";
import { useContext } from "react";
import {
  AppGlobalContext,
  AppGlobalContextUpdate,
} from "../../common/context/AppGlobalContext";
import ReferenceType, { ReferenceId } from "../../common/components/EnumRefType";
// import { NEW_CUSTOMER } from "../../common/components/Values";
// import Library from "../../common/Assets/Icons/bookSb.svg";
const MediaCenter = ({
  attachments,
  custId,
  OpenCust
}) =>{
  // const selectedDocuments= {props?.selectedDocuments}
  const appState = useContext(AppGlobalContext);
  const updateAppState = useContext(AppGlobalContextUpdate);
  const setWOFiles = useState(appState?.currentWOFiles || {});
  const setRecentItems = useState({});
  const [workOrderFiles, setWorkOrderFiles] = useState({
    attachments: [],
  });
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const getWorkOrderMedia = useCallback(async () => {
    let filesObj = {};
    attachments?.forEach((i) => {
      if (i.file_type.includes("image")) {
        filesObj["attachments"] = [...(filesObj?.attachments || []), i];
      }
      if (
        i.file_type.includes("text") ||
        i.file_type.includes("pdf") || i.file_type.includes("application")) {
        filesObj["docs"] = [...(filesObj?.docs || []), i];
      }
    });
    setWorkOrderFiles(filesObj);
    setSelectedDocuments(filesObj?.docs)
  }, [attachments]);
  const [gallMc, setGallMc] = useState("");
  const [docMc, setDocMc] = useState("");
  const setWorkOrderList = useState([]);
  const getWorkOrders = useCallback(async () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const responce = await workOrdrList(userId);
    if (responce?.status === SUCCESS_STATUS) {
      let todoData = responce?.data?.WorkOrderList.filter(
        (it) => it !== null && it?.status === STATUS_COMPLETED
      );
      setWorkOrderList(todoData);
    }
  },[setWorkOrderList])
  const getAllWOFiles = useCallback(async () => {
    let LocData = window.location.href.split("/");
 
    const WOId = LocData[5];
    const custId = LocData[6];
    const techId = LocData[7];
 
    const response = await getFiles(WOId, techId, custId);
 
    if (response && response?.responseOk && response?.responseOk?.files) {
      updateAppState({ currentWOFiles: response?.responseOk?.files });
      setWOFiles(response?.responseOk?.files || {});
    }
  },[setWOFiles, updateAppState]);
  const getLibraryRecentList = useCallback(async () => {
    const date = moment().format("MM-DD-YYYY-HH:mm").toString();
    const techId = JSON.parse(localStorage.getItem("techpin"));
    const response = await getLibraryRecentItems(techId, date);
    if (response?.data) {
      setRecentItems(response?.data);
    }
  },[setRecentItems])

  useEffect(() => {
    getWorkOrderMedia();
    // if (window.location.href.includes("CreateNewcustomer")) {
    //   return;
    // }
    // if (window?.location?.href?.split('/')[5]?.length > 0) {
    //   return;
    // }
    // getAllWOFiles();
    // getLibraryRecentList();
    // getWorkOrders();
    // workOrdrList();
  }, [getWorkOrderMedia]);

  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const workOrderItem = useState({});
  const [isCustomer, setIsCustomer] = useState(false);
  const customerObj = useState({});
  
 

 


  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const fileInputRef = useRef(null);
 

  const parseReferenceDetails = (url, props) => {
    if (url?.includes("CreateNewcustomer")) {
      return {
        workOrderId: 0,
        referenceId: custId,
        referenceType: ReferenceType.CUSTOMER,
      };
    } else {
      return {
        workOrderId: custId,
        referenceId: custId,
        referenceType: ReferenceId.work_order,
      };
    }
  };

  const handleImageUpload = async (event) => {
    const referenceDetails = parseReferenceDetails(window?.location?.href); 
    const { workOrderId, referenceId, referenceType } = referenceDetails;
    const reader = new FileReader();
    reader.onloadend = async () => {
      let base = reader.result.split(",");
      let mediaObj = {
        file_type: event.target.files[0]?.type,
        filename: event.target.files[0]?.name,
        content: base[1],
        work_order_id:workOrderId,
        reference_id:referenceId,
        reference_type: referenceType,
        uuid_uuid: "",
      };
      if (window.location.href?.includes("CreateNewcustomer")) {
        mediaObj.work_order_id = 0;
        mediaObj.reference_id = custId;
        mediaObj.reference_type = ReferenceType.CUSTOMER;
      }
      //  if(mediaObj?.file_type === "application/pdf"){
      const response = await WorkflowAttachment(mediaObj);
      const newMedObj = response?.data?.attachment?.attachResponses
      let newMediacObj =
      {
        "attachment_id": newMedObj?.attachment_id,
        "file_type": newMedObj?.file_type,
        "reference_id": newMedObj?.reference_id,
        "reference_type": newMedObj?.reference_type,
        "work_order_id": newMedObj?.work_order_id,
        "filename": newMedObj?.filename,
        "thumbnail":newMedObj?.thumbnail_content
    }
      if (response?.success) {
        if (
          mediaObj?.file_type?.startsWith("image/") ||
          mediaObj?.file_type?.startsWith("video/")
        ) {
          setWorkOrderFiles((prevFiles) => ({
            ...prevFiles,
            attachments: [...(prevFiles.attachments || []), newMediacObj],
          }));
        } if (
          mediaObj.file_type.startsWith("text/plain") ||
          mediaObj.file_type.startsWith("application/pdf")
          || mediaObj.file_type.startsWith("text/javascript")
        ) {
          // setSelectedDocuments([...selectedDocuments, newMedObj]);
          setSelectedDocuments([...(selectedDocuments || []), newMediacObj]);
          //  setSelectedDocuments((prevDocuments) => [...prevDocuments, newMediacObj]);
        }
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    const files = event.target.files;
    const newImages = [];
    const newVideos = [];
    const newDocuments = [];
 
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
 
      if (file.type.startsWith("image/")) {
        newImages.push(file);
      } if (file.type.startsWith("video/")) {
        newVideos.push(file);
      } if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        newDocuments.push(file);
      }
    }
    setSelectedImages([...selectedImages, ...newImages]);
    setSelectedVideos([...selectedVideos, ...newVideos]);
    setSelectedDocuments([...selectedDocuments, ...newDocuments]);
    newImages.forEach((image) => { });
  };
 

  const [carouselIndex, setCarouselIndex] = useState(0);

  const [mediaCenterVisible, setMediaCenterVisible] = useState(true);
 
  const handleModalShow = (imageSrc, filename, source) => {
    setIsOpen2(true);
    setCarouselIndex(0);
    setCarouselIndex(0);
    const images = [
      ...workOrderFiles?.attachments?.map((image) => ({
        src: image?.thumbnail?.includes("base64")
          ? image?.thumbnail
          : `data:${image?.file_type};base64,${image?.thumbnail}`,
        FileName: image?.filename,
        type: "image",
      })),
    ];
    setCarouselIndex(images.findIndex((img) => img?.src === imageSrc));
  };
  const handleCloseModal = () => {
    setIsOpen2(false);
    setCarouselIndex(0);
  };
  const handleGalleryToggle = () => {
    setGallMc(gallMc === "rcntmc" ? "" : "rcntmc");
  };
  const handleDocumentsToggle = () => {
    setDocMc(docMc === "rcntmc" ? "" : "rcntmc");
  };
 
  const handleMediaToggle = () => {
    setMediaCenterVisible(!mediaCenterVisible);
  };
  // const loc = window.location.href.split("/")[5]
  const openImageInNewTab = async (document) => {
    let id = document?.id ? document?.id : document?.attachment_id;
    const output = await MediaAttachments(id);
    const base64PDF = output?.data?.content;
    const fileType = output?.data?.file_type;
    const blob = base64ToBlob(base64PDF, fileType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
    URL.revokeObjectURL(blobUrl);
  };
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters?.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };
  return (
    <div>
      <Card className="det-txt-media">
        <div className="mc-card-header ">
          <div className="med-center w-100 clp_header">
            {/* {window.location.href?.includes("CreateNewcustomer") ? (
              <>
                <img src={Library} alt="library" className="contacts-img" />
 
                <span className="cust-span contacts-span">
                  {" "}
                  {NEW_CUSTOMER.LIBRARY}
                </span>
              </>
            ) : (
              <> */}
                <img
                  onClick={handleMediaToggle}
                  src={bookme}
                  className="flt-l ml-2 mr-1 cursor-p"
                  width={20}
                  alt="details"
                />
                &nbsp;
                <span className="mx-1 cursor-p " onClick={handleMediaToggle}>
                  {MEDIA_CENTER.MEDIA_CENTER} (
                  {(workOrderFiles?.attachments?.length || 0) +
                    (selectedDocuments?.length || 0)}
                  )
                </span>
              {/* </>
            )} */}
              <img
                className="plusIcon-modal"
                style={{ marginLeft: "auto", cursor: "pointer",pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                src={plusIcon}
                alt="AddMedia"
                onClick={() =>{if(OpenCust === false || OpenCust === undefined) fileInputRef.current.click()}}
              />
          </div> <div className="">
          <div>
            <input
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
         
          {mediaCenterVisible && (
            <>
              {/* Check for either attachments (images/videos) or documents */}
              {workOrderFiles?.attachments?.length > 0 || selectedDocuments?.length > 0 ? (
                <>
                  {/* Gallery section */}
                  {workOrderFiles?.attachments?.some(
                    (it) =>
                      it.file_type.startsWith("image/") ||
                      it.file_type.startsWith("video/")
                  ) && (
                      <div className="clp_body">
                        <div className="d-flex ml-3">
                          <img src={galleryicon} width={20} alt="docicon" />
                          <span className=" mt-1 " onClick={handleGalleryToggle}>
                            {LIBRARY_DET.GALLARY}({workOrderFiles?.attachments?.length || 0})
                          </span>
                        </div>
                        {gallMc === "rcntmc" ? (
                          ""
                        ) : (
                          <div className="img-grid">
                            {workOrderFiles?.attachments
                              ?.filter(
                                (it) =>
                                  it.file_type.startsWith("image/") ||
                                  it.file_type.startsWith("video/")
                              )?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                              .map((it) => (
                                <div className="cursor-p" key={it.id}>
                                  <embed
                                    className="img-sty mt-2 "
                                    style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
                                    key={it}
                                    src={
                                      it?.thumbnail?.includes("base64")
                                        ? it?.thumbnail
                                        : `data:${it?.file_type};base64,${it?.thumbnail}`
                                    }
                                    alt=""
                                    onClick={() =>{
                                      if(OpenCust === false || OpenCust === undefined){
                                      handleModalShow(
                                        it?.thumbnail?.includes("base64")
                                          ? it?.thumbnail
                                          : `data:${it?.file_type};base64,${it?.thumbnail}`,
                                      )
                                    }}}
                                  />
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    )}
 
                  {/* Documents section */}
                  {selectedDocuments?.length > 0 && (
                    <div className="m-1 cursor-p">
                      <div className=" d-flex ml-3">
                        <br /><img src={documenticon} width={18} alt="docicon" />
                        <span className=" mt-1" onClick={handleDocumentsToggle}>
                          {LIBRARY_DET.DOCUMENTS}({selectedDocuments?.length})
                        </span>
                      </div>
                      {docMc === "rcntmc" ? (
                        ""
                      ) : (
                        <div className="docu-box-container g-4 border-1 ml-5 mt-2">
                          {selectedDocuments?.map((document, index) => (
                            <div className="docu-box" key={index}>
                              <div className="docu-details"
                              style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
                              >
                                <img
                                  id="pdf"
                                  src={ `data:${document?.file_type};base64,${document?.thumbnail}`}
                                  alt="pdf"
                                  onClick={() =>{if(OpenCust === false || OpenCust === undefined){openImageInNewTab(document)}}}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="notes-card-dscrptn clp_body">
                  <p className="">{MEDIA_CENTER.CARD_BODY}</p>
                </div>
              )}
            </>
          )}</div>
        </div>
      </Card>
      <AttachmentModal
        showModal={isOpen2}
        handleCloseModal={handleCloseModal}
        selectedImageIndex={carouselIndex}
        handleCarouselSelect={(index) => setCarouselIndex(index)}
        eachWorkOrder={workOrderFiles?.attachments}
        openImageInNewTab={openImageInNewTab}
      />
      <ShareModal
        Open={isOpen}
        Close={() => setIsOpen(false)}
        data={workOrderItem}
      />
      <CustomerModal
        Open={isCustomer}
        Close={() => setIsCustomer(false)}
        data={customerObj}
      />
      {/* <MediadocModal
       isopen={isopen}
       isclose={() => setOpen(false)}
       filetype={filetype}
       content={content}
       /> */}
    </div>
  );
}
 
export default MediaCenter;