import React from "react";
import { Modal } from "react-bootstrap";
import Close2 from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { EQPMAN } from "../../../../common/components/Constants";
import ProgressBar from "../../../../common/navigation/ProgressBar";
export default function EqpManufactModal(props) {
  return (
    <div>
      <Modal centered show={props?.Open} onHide={props?.Close}>
        <Modal.Header className="modal-bg-color modal-header-height">
          <div className="line-align-bet w-100 ">
            <span className="wftags-sub-header2">
              {props?.isTypeDel === EQPMAN?.EDIT
                ? EQPMAN?.EDIT
                : props?.isTypeDel === EQPMAN?.DELETE
                ? EQPMAN?.REMOVE
                : EQPMAN?.CREATE}{" "}
              {props?.isType}
            </span>
            <span className="cursor-p" onClick={props?.Close}>
              <img className="modal-header-icon" src={Close2} alt="Close2" />
            </span>
          </div>
        </Modal.Header>
   
        <Modal.Body className="eqp_model">
          {props?.isTypeDel === EQPMAN?.DELETE ? (
            <div className="sub-titles-names">
              Once you remove the {props?.isType} you cannot restore the{" "}
              {props?.isType}. Are you sure you want to proceed?
            </div>
          ) : ( 
            <div>
          {props?.isProgress !== false ? (
          <div 
           style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "165px",
           }}
          >
            <ProgressBar/>
          </div>
        ):(
            <div>
              <input
                className="w-100 wfs-type-input"
                placeholder={`Input Name`}
                name="inpEqpManName"
                value={props?.inpEqpManName}
                onChange={props?.HandleEqpManInputs1}
              />
              <div className="mt-1">{props?.isType} Code:</div>
              <input
                className="w-100 wfs-type-input"
                placeholder={`Input Code`}
                name="manFactCode"
                value={props?.manFactCode}
                onChange={props?.HandleEqpManInputs2}
              />
              <div className="mt-1">{props?.isType} Description:</div>
              <input
                className="w-100 wfs-type-input"
                placeholder={`Input Description`}
                name="inpEqpMan"
                value={props?.inpEqpMan}
                onChange={props?.HandleEqpManInputs}
              />
              <div className="err-Wf-empty">
                {props?.isErrEqpMan &&
                  (props?.inpEqpMan?.trim()?.length <= 0 ||
                    props?.manFactCode?.trim()?.length <= 0 ||
                    props?.inpEqpManName?.trim()?.length <= 0) &&
                  "Please Enter all input fields"}
              </div>
            </div>
        )}
             </div>
          )} 
        </Modal.Body>
        <div
            className="mt-2 model_footer"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              
            }}
          >
            <div
              className="modal-footer-save-cancel-btns"
              onClick={() =>
                props?.HandelSaveEqpMan(props?.isType, props?.isTypeDel)
              }
            >
              <span>
                {props?.isTypeDel === EQPMAN?.DELETE ? `Delete ` : "Save"}
              </span>
              {/* {props?.isTypeDel === "Delete" ? (
                <img src={del} alt="del" />
              ) : (
                <img src={Save} alt="Save" />
              )} */}
            </div>
            <div
              className="modal-footer-save-cancel-btns"
              onClick={props?.Close}
            >
              <span>Cancel</span>
              {/* <img src={Close} alt="Close" /> */}
            </div>
          </div>
      </Modal>
    </div>
  );
}
