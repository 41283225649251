import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AddEquipment.css";
import { Card, Row, Col } from "react-bootstrap";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import ProgressBar from "../../../common/navigation/ProgressBar";
import CommonHeader from "../../../common/framework/header/HeaderCommon/CommonHeader";
import save from "../../../common/Assets/Icons/save.svg";
import editIcon from "../../../common/Assets/Icons/editIcon.svg";
import CryptoJS from "crypto-js";
import {
  AdditionalFieldEquipments,
  GetMemory,
  EquipmentDelete
} from "../../../services/WorkOrdersService";
import del from "../../../common/Assets/Icons/delW.svg";
import CommonFooter from "../../../common/framework/footer/CommonFooter";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import VectorEqpBlue from "../../../common/Assets/Images/VectorEqpBlue.svg";
import MemoryIcon from "../../../common/Assets/Icons/Memory Board Icon.svg";
import scancam from "../../../common/Assets/Icons/scancam.svg";
import equipupload from "../../../common/Assets/Icons/equipupload.svg";
import shareblck from "../../../common/Assets/Icons/shareblck.svg";
import Alerts from "../../../common/components/Alerts/Alerts";
import moment from "moment";
import {
  addNewEquipment,
  EquipmentDetails,
} from "../../../services/WorkOrdersService";
import { useParams } from "react-router-dom";
import EquipmentLogs from "./EquipmentsLogs/EquipmentLogs";
import EquipmentInfo from "./EquipmentInfo/EquipmentInfo";
import PreviousWorkOrdersEquipments from "./PreviousWorkOrdersEquipments/PreviousWorkOrdersEquipments";
import AdditionalFieldsEquipments from "./AdditionFieldsEquipments/AdditionalFieldsEquipments";
import AddEquipmentHeader from "./AddEquipmentHeader";
import LibraryEquipments from "./LibraryEquipments/LibraryEquipments";
import close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import AddTag from "../../workorders/addtag/AddTag";
import { Modal } from "react-bootstrap";
import { MOD_TEXT_FIELD_SETUP, SHARE_MODAL_VAL } from "../../../common/components/Values";
import DeleteModal from "../../../common/components/Alerts/DeleteModal";
import MemoryModal from "../../../common/components/memorymodal/MemoryModal";
import MemoryblueModal from "../../../common/components/memorymodal/MemoryblueModal";
import ShareWoModal from "../../../common/components/sharemodal/ShareWoModal";
import { ExtraFieldsTypeValids } from "./PreviousWorkOrdersEquipments/ExtraFieldsTypeValids";
import { DEL_W_O, LOGIN_CONST } from "../../../common/components/Constants";
import { authLogin } from "../../../services/AuthService";
import DeleteWOModal from "../../workorders/WorkOrderDetails/DeleteWOModal";
function AddEquipment(props, carddata) {
  const [isProgress, setIsProgress] = useState(false);
  const { isWoid, custid, techid } = useParams();
  const Location = useLocation();
  const [isCondition, setISCondition] = useState(
    Location?.state?.params !== undefined
      ? Location?.state?.params
      : Location?.state
  );
 
const work_flow_id = Location?.state?.wf_id;
  const [equipmentErros, setEquipmentErrors] = useState([]);
  const [newEquipmentData, setNewEquipmentData] = useState({
    equipment_id: 0,
    customer_id: custid,
    equipment_type: "",
    location: "",
    manufacturer_code: "",
    model_number: "",
    serial_number: "",
    sequence_id: 0,
    install_date: "",
    warranty_date: "",
    note: "",
    active: 1,
    unit_reference: "",
  });
  const [additionalFields1, setAdditionalFields1] = useState([]);
  const [additionalFields2, setAdditionalFields2] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const handleInfo = () => { };
  const [isExpand, setIsExpand] = useState("");
  const [rtExpand, setRtExpand] = useState("");
  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  const HandleRtExpand = (val) => {
    setRtExpand(val);
  };

  const upDateNewEquipment = (key, value) => {
    setNewEquipmentData((prevData) => {
      const updatedData = {
        ...prevData,
        [key]:
          value === "Please Select manufacturer"
            ? ""
            : value === "Please Select equipment_type"
              ? ""
              : value,
      };
      setHasUnsavedChanges(
        JSON.stringify(updatedData) !== JSON.stringify(prevData)
      );
      return updatedData;
    });
    setEquipmentErrors(equipmentErros?.filter((i) => i?.error !== key));
  };
  const discardChanges = () => {
    setdeltmodal(false);
    if (eqpinfo?.equipment_id > 0) {
      setNewEquipmentData({
        equipment_id: eqpinfo?.equipment_id,
        customer_id: eqpinfo?.customer_id,
        equipment_type: eqpinfo?.equipment_type,
        location: eqpinfo?.location,
        manufacturer_code: eqpinfo?.manufacturer_id
          ? eqpinfo?.manufacturer_id
          : eqpinfo?.manufacturer_code,
        model_number: eqpinfo?.model_number,
        note: eqpinfo?.note,
        sequence_id: 0,
        serial_number: eqpinfo?.serial_number,
        install_date: eqpinfo?.install_date,
        warranty_date: eqpinfo?.warranty_date,
        active: eqpinfo?.active,
        unit_reference: eqpinfo?.unit_reference,
      });
      setSelectDate(eqpinfo?.warranty_date);
      setSelectInstallDate(eqpinfo?.install_date);
      setAdditionalFields1(additionalFields2);
      setISCondition("equipdetails");
      setHasUnsavedChanges(false);
    } else {
      navigation(-1);
    }
  };
  const getErrorMessage = (errorKey, msg) => {
    const existingError = equipmentErros?.find((i) => i.error === errorKey);
    if (existingError) {
      return (
        <span className="error-msg-ipt-add-eqp">
          {msg ? `Enter  ${msg}` : existingError.message}
        </span>
      );
    }
  };
  const [selectDate, setSelectDate] = useState(null);
  const handleStartDateSelect = (date, newEquipmentData) => {
    if (date && moment(date).isValid()) {
      const formattedDate = date
      ? `${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`
      : "";
      setSelectDate(formattedDate);
      const updatedData = {
        ...newEquipmentData,
        warranty_date: formattedDate,
      };
      const previousData = { ...newEquipmentData };
      setNewEquipmentData(updatedData);
      setHasUnsavedChanges(
        JSON.stringify(updatedData) !== JSON.stringify(previousData)
      );
    }
  };

  const [selectInstallDate, setSelectInstallDate] = useState(null);
  const handleEndDateSelect = (date, newEquipmentData) => {
    if (date && moment(date).isValid()) {
      const formattedDate = date
      ? `${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`
      : "";
      setSelectInstallDate(formattedDate);
      const updatedData = {
        ...newEquipmentData,
        install_date: formattedDate,
      };
      const previousData = { ...newEquipmentData };
      setNewEquipmentData(updatedData);
      setHasUnsavedChanges(
        JSON.stringify(updatedData) !== JSON.stringify(previousData)
      );
    }
  };
const [deleqmodal , setDeleqmodal] = useState(false)
const HandleDelEqmodal = () => {
  setDeleqmodal(true);
};
const HandleEqpmntDelete = async () => {
try {
  let EqId = eqpinfo?.equipment_id
  if (!EqId) {
            alert("Equipment ID not found!");
             return;
          }
          const response = await EquipmentDelete(EqId);
          if (response?.success) {
            alert("Equipment Deleted Successfully")
            navigate(-1)
          }
}
catch(error) {

}
}
  const navigation = useNavigate();
  const navigate = useNavigate();
  const [infoAeTgl, setInfoAeTgl] = useState("");

  const HandleInfoAddEqpTgl = (item) => {
    setInfoAeTgl(item);
  };
  const [isEmpty, setIsEmpty] = useState(false);

  const [Isopen, setIsopen] = useState(false);
  const [isErr, setIserr] = useState([]);
  const handleEquipmentSave = async () => {
    let errArr = [];
    let filterObj = Object.keys(newEquipmentData);
    let checkfields = isCondition === "equipcreate" ? creatingFlds : additionalFields1
    const errorArray = checkfields?.filter(
      (i) => i.required === true && i?.value?.length === 0
    );
    if (errorArray?.length > 0) {
      setIserr(errorArray);
      setIsopen(true);
    }
    filterObj.forEach((it) => {
      if (
        newEquipmentData[it]?.length <= 0 &&
        it !== "unit_reference" &&
        it !== "tags" &&
        it !== "attachment"
      ) {
        errArr.push({
          message: it,
        });
      }
    });
    if (errArr?.length > 0 || errorArray?.length > 0) {
      setIserr([...errArr, ...errorArray]);
      setIsopen(true);
      return;
    }
    // additionalFields1.forEach((field) => {
    //   if (field.required && (!field.value || field.value.trim().length === 0)) {
    //     errArr.push({
    //       message :field.packcolumn
    //     });
    //   }
    // });

    // if (errArr.length > 0) {
    //   setIsopen(true);
    //   setIserr(errArr);
    //   return;
    // } 
    else {
      setIsProgress(true);
      let equipmentData = {
        equipment_id: newEquipmentData?.equipment_id,
        customer_id: custid,
        equipment_type: newEquipmentData?.equipment_type,
        location: newEquipmentData?.location,
        manufacturer_code: newEquipmentData?.manufacturer_code,
        serial_number: newEquipmentData?.serial_number,
        model_number: newEquipmentData?.model_number,
        note: newEquipmentData?.note,
        sequence_id: newEquipmentData?.sequence_id,
        install_date: newEquipmentData?.install_date,
        warranty_date: newEquipmentData?.warranty_date,
        active: newEquipmentData?.active,
        unit_reference: newEquipmentData?.unit_reference,
        extra_field_1:
          additionalFields1?.length > 0
            ? additionalFields1[0].value
              ? additionalFields1[0].value
              : ""
            : "",
        extra_field_2:
          additionalFields1?.length > 1
            ? additionalFields1[1].value
              ? additionalFields1[1].value
              : ""
            : "",
        extra_field_3:
          additionalFields1?.length > 2
            ? additionalFields1[2].value
              ? additionalFields1[2].value
              : ""
            : "",
        extra_field_4:
          additionalFields1?.length > 3
            ? additionalFields1[3].value
              ? additionalFields1[3].value
              : ""
            : "",
        extra_field_5:
          additionalFields1?.length > 4
            ? additionalFields1[4].value
              ? additionalFields1[4].value
              : ""
            : "",
        extra_field_6:
          additionalFields1?.length > 5
            ? additionalFields1[5].value
              ? additionalFields1[5].value
              : ""
            : "",
        extra_field_7:
          additionalFields1?.length > 6
            ? additionalFields1[6].value
              ? additionalFields1[6].value
              : ""
            : "",
        extra_field_8:
          additionalFields1?.length > 7
            ? additionalFields1[7].value
              ? additionalFields1[7].value
              : ""
            : "",
        extra_field_9:
          additionalFields1?.length > 8
            ? additionalFields1[8].value
              ? additionalFields1[8].value
              : ""
            : "",
        extra_field_10:
          additionalFields1?.length > 9
            ? additionalFields1[9].value
              ? additionalFields1[9].value
              : ""
            : "",
        extra_field_11:
          additionalFields1?.length > 10
            ? additionalFields1[10].value
              ? additionalFields1[10].value
              : ""
            : "",
        extra_field_12:
          additionalFields1?.length > 11
            ? additionalFields1[11].value
              ? additionalFields1[11].value
              : ""
            : "",
        extra_field_13:
          additionalFields1?.length > 12
            ? additionalFields1[12].value
              ? additionalFields1[12].value
              : ""
            : "",
        extra_field_14:
          additionalFields1?.length > 13
            ? additionalFields1[13].value
              ? additionalFields1[13].value
              : ""
            : "",
        extra_field_15:
          additionalFields1?.length > 14
            ? additionalFields1[14].value
              ? additionalFields1[14].value
              : ""
            : "",
        extra_field_16:
          additionalFields1?.length > 15
            ? additionalFields1[15].value
              ? additionalFields1[15].value
              : ""
            : "",
        extra_field_17:
          additionalFields1?.length > 16
            ? additionalFields1[16].value
              ? additionalFields1[16].value
              : ""
            : "",
        extra_field_18:
          additionalFields1?.length > 17
            ? additionalFields1[17].value
              ? additionalFields1[17].value
              : ""
            : "",
        extra_field_19:
          additionalFields1?.length > 18
            ? additionalFields1[18].value
              ? additionalFields1[18].value
              : ""
            : "",
        extra_field_20:
          additionalFields1?.length > 19
            ? additionalFields1[19].value
              ? additionalFields1[19].value
              : ""
            : "",
        extra_field_21:
          additionalFields1?.length > 20
            ? additionalFields1[20].value
              ? additionalFields1[20].value
              : ""
            : "",
        extra_field_22:
          additionalFields1?.length > 21
            ? additionalFields1[21].value
              ? additionalFields1[21].value
              : ""
            : "",
        extra_field_23:
          additionalFields1?.length > 22
            ? additionalFields1[22].value
              ? additionalFields1[22].value
              : ""
            : "",
        extra_field_24:
          additionalFields1?.length > 23
            ? additionalFields1[23].value
              ? additionalFields1[23].value
              : ""
            : "",
        extra_field_25:
          additionalFields1?.length > 24
            ? additionalFields1[24].value
              ? additionalFields1[24].value
              : ""
            : "",
        extra_field_26:
          additionalFields1?.length > 25
            ? additionalFields1[25].value
              ? additionalFields1[25].value
              : ""
            : "",
        extra_field_27:
          additionalFields1?.length > 26
            ? additionalFields1[26].value
              ? additionalFields1[26].value
              : ""
            : "",
        extra_field_28:
          additionalFields1?.length > 27
            ? additionalFields1[27].value
              ? additionalFields1[27].value
              : ""
            : "",
        extra_field_29:
          additionalFields1?.length > 28
            ? additionalFields1[28].value
              ? additionalFields1[28].value
              : ""
            : "",
        extra_field_30:
          additionalFields1?.length > 29
            ? additionalFields1[29].value
              ? additionalFields1[29].value
              : ""
            : "",
      };
      let wowfId = +work_flow_id?work_flow_id:0;
      let output = await addNewEquipment(techid, isWoid, wowfId, equipmentData);
      if (output?.success) {
        localStorage?.setItem("eqpid", output?.data?.equipment_id)
        EquipmentAlldetails(output?.data?.equipment_id);
        setIsProgress(false);
        setNewEquipmentData(output?.data);
        setISCondition("equipdetails");
      } else {
        setIsProgress(false);
      }
    }
  };
  const [sortTgl, setSortTgl] = useState("");
  const contentRefs = useRef([]);
  const scrollToDiv = (index) => {
    const contentDiv = contentRefs.current[index];
    contentDiv.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const HandleSortTgl = (item) => {
    setSortTgl(item);
  };
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.dataset.index);
          setActiveTab(index);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    contentRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [activeTab]);

  const [eqpinfo, setEqpinfo] = useState({});
  const [equiptags, setEqptags] = useState([]);
  const [eqpwoorders, setEqpwoorders] = useState([]);
  const [eqpnotes, setEqpnotes] = useState([]);
  const [eqpaddflds, setEqpaddflds] = useState([]);
  const [mryvalue, setMryvalue] = useState(null);
  const updateEquiptags = (newTag) => {
    setEqptags((prevTags) =>
      Array.isArray(prevTags) ? [...prevTags, newTag] : [newTag]
    );
  };

  const EquipmentAlldetails = useCallback(
    async (id) => {
      setIsProgress(true);
      try {
      let EqpId
      let token
      if (+window?.location?.href?.split("/")[10] === 1) {
        EqpId = window.location.href.split("/")[5]
        const secretKeyforPin = SHARE_MODAL_VAL.secretKey;
        let locadata =window?.location?.href?.split("/")
        let decryptwoslashPin = locadata[8]?.replace(/_/g, "/").replace(/-/g, "+");
        const decryptedPin = CryptoJS.AES.decrypt(
            decryptwoslashPin,
            secretKeyforPin
        ).toString(CryptoJS.enc.Utf8);
    
        const data = await authLogin(
          
          locadata[7],  
            decryptedPin,
            locadata[9],  
            1,
            1,
            1,
            1,
            LOGIN_CONST.APP_TYPE
        );
        if (data?.success) {
             token = data?.data?.token
        }
      }
      else {
        EqpId = id
          ? id
          : Location?.state?.data?.equipment_id
            ? Location?.state?.data?.equipment_id
            : Location?.state?.data
              ? Location?.state?.data
              : isWoid
                ? isWoid
                : Location?.state ? Location?.state : window?.location?.href?.split("/")[5];
        token = null;
      }
      setIsProgress(true);
        const Result = await EquipmentDetails(EqpId, token);
        if (Result?.success) {
        if (EqpId) {
          const formatDate = (dateString) => {
            if (!dateString) return null; // Handle null or undefined dates gracefully
            const date = new Date(dateString);
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            const year = date.getFullYear();
            return `${month}-${day}-${year}`;
          };
          setNewEquipmentData({
            equipment_id: Result?.data?.equipment?.equipment_id,
            customer_id: Result?.data?.equipment?.customer_id,
            equipment_type: Result?.data?.equipment?.equipment_type,
            location: Result?.data?.equipment?.location,
            manufacturer_code: Result?.data?.equipment?.manufacturer_id
              ? Result?.data?.equipment?.manufacturer_id
              : Result?.data?.equipment?.manufacturer_code,
            model_number: Result?.data?.equipment?.model_number,
            note: Result?.data?.equipment?.note,
            sequence_id: 0,
            serial_number: Result?.data?.equipment?.serial_number,
            install_date: formatDate(Result?.data?.equipment?.install_date),
            warranty_date: formatDate(Result?.data?.equipment?.warranty_date),
            active: Result?.data?.equipment?.active,
            unit_reference: Result?.data?.equipment?.unit_reference,
          });
        }
        setEqptags(Result?.data?.tags);
        setEqpwoorders(Result?.data?.workorders);
        setEqpinfo(Result?.data?.equipment);
        setEqpnotes(Result?.data?.notes?.equipment_notes);
        setEqpaddflds(Result?.data?.equipmentAdditionalField);
        setAdditionalFields1(Result?.data?.equipmentAdditionalField);
        setAdditionalFields2(Result?.data?.equipmentAdditionalField);
        setMryvalue(Result?.data?.memoryBoard);
        setIsProgress(false);
      }
      } catch (error) { }
    },
    [Location?.state, isWoid]
  );
  useEffect(() => {
    const equipmentId = localStorage.getItem("eqpid");
    if (Location?.state?.wfpara === "wfeqp") {
      EquipmentAlldetails(Location?.state?.data);
      setISCondition("Edit");
    }else if (equipmentId) {
      EquipmentAlldetails(equipmentId);
      setISCondition("equipdetails")
    }
    else if (Location?.state?.params === "equipcreate") {
      equipAdditionalFieldEquipments();
    } else {

      EquipmentAlldetails();
    }
  }, [EquipmentAlldetails, Location?.state]);
  const [creatingFlds, setCreatingFlds] = useState([])
  const equipAdditionalFieldEquipments = async () => {
    let Res = await AdditionalFieldEquipments();
    setAdditionalFields1(Res?.data);
    setCreatingFlds(Res?.data)
  };
  const onAdditionalFieldChange = (key, e, field_id, controlType, value, additionalFields1) => {
    let Res = ExtraFieldsTypeValids(key, e, field_id, controlType, value, additionalFields1)
    setAdditionalFields1(Res)
    setCreatingFlds(Res)
  }
  const [isTag, setIsTag] = useState(false);
  const HandleAddTag = () => {
    setIsTag(true);
  };
  const [eqid, seteqid] = useState(0);
  const handleEditClick = () => {
    setISCondition("Edit");
    seteqid(0);
    setNewEquipmentData({
      equipment_id: newEquipmentData?.equipment_id,
      customer_id: newEquipmentData?.customer_id,
      equipment_type: newEquipmentData?.equipment_type,
      location: newEquipmentData?.location,
      manufacturer_code: newEquipmentData?.manufacturer_id
        ? newEquipmentData?.manufacturer_id
        : newEquipmentData?.manufacturer_code,
      model_number: newEquipmentData?.model_number,
      note: newEquipmentData?.note,
      sequence_id: 0,
      serial_number: newEquipmentData?.serial_number,
      install_date: newEquipmentData?.install_date,
      warranty_date: newEquipmentData?.warranty_date,
      active: newEquipmentData?.active,
      unit_reference: newEquipmentData?.unit_reference,
    });
  };
  useEffect(() => {
    // Location?.state?.data !== undefined &&
    //   setNewEquipmentData(Location?.state?.data);

    if (window?.location?.href?.split("/")[4] !== "AddEquipments") {
      localStorage?.removeItem("eqpid");
    }
  }, [Location?.state?.data]);
  const [deltmodal, setdeltmodal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);


  const [isMemory, setIsMemory] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [mryval, setMryval] = useState(0);
  const [mrydlt, setMrydlt] = useState(null);
  const updateMemoryBoardStatus = (workOrderId) => {
    setMryval(workOrderId);
    newEquipmentData.memoryBoard = workOrderId;
  };
  const [getmemory, setgetmemory] = useState("");
  const descvalue = getmemory;
  const [memobj, setMemobj] = useState({});
  const [mryblue, setMryblue] = useState(false);
  const [itemkey, setItemkey] = useState("");
  const [ismemProgress, setIsMemProgress] = useState(false);
  const HandleMemory = async (type, e, item) => {
    if (type === "black") {
      e.stopPropagation();
      setIsMemory(true);
      let items = { viewtype: 10, Reftype: 11, RefId: item?.equipment_id };
      setIsMemoryObj(items);
    } else {
      setMryblue(true);
      setItemkey(type);
      let items = { viewtype: 10, Reftype: 11, RefId: item?.equipment_id };
      setIsMemoryObj(items);
      setIsMemProgress(true);
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
      );
      if (Result?.success) {
        setIsMemProgress(false);
      }
      setMemobj(item);
      setgetmemory(Result?.data?.note);
      setMrydlt(Result?.data?.recent_view_id);
    }
  };
  const [isOpenshare, setIsOpenshare] = useState(false);
  const handleShareopen = (item) => {

    setIsOpenshare(true);
  };

  return (
    <>
      <div className="head-stick">
        <CommonHeader />
        {isProgress !== true && (
          <div className="line-align-bet  mx-2 flx-wrp sticky-header-content">
            <span className="line-align flx-wrp">
              {/* <img
                src={left}
                className="cursor-p"
                onClick={() => HandleNavfrmAddToWod()}
                alt=""
              /> */}
              <span className="eqp-title mx-3">
                {isCondition === "equipcreate"
                  ? "Create"
                  : isCondition === "Edit"
                    ? "Edit"
                    : "View"}{" "}
                Equipment
              </span>

              <div className="equiptag mt-2">
                {/* <button
               className=" btn-T"
              style={{
               backgroundColor:"#60666C",
              }}
              >
                Creating{" "}
                <img src={del} alt="btn" />{" "}
              </button> */}
              </div>

              {/* {isCondition !== null && newEquipmentData.active === "1" ?  (

              <span className="act-btn-eqp mt-1"> */}

              {/* {isCondition?.active === "1" ? "Active" : "Inactive"} */}
              {/* <span>Active</span>
              </span>
            ) : (
             ""
            )} */}
              {isCondition !== null && (
                <span
                  className={`act-btn-eqp mt-2 ${newEquipmentData.active === "0" ? "inactive" : ""
                    }`}
                >
                  <span>
                    {newEquipmentData.active === "0" ? "Inactive" : "Active"}
                  </span>
                </span>
              )}
            </span>
            {isCondition === "Edit" || isCondition === "equipcreate" ? (
              <div className="d-flex mt-2  ">
                {/* <span className="molecule">

              <img
                src={Moleculelight}
                alt="cam"
                height={14}
                className=" "
                onClick={handleOpenModal}
              />
             </span>&nbsp; */}
                <span className="add-eqp-cnl mt-1 cursor-p">
                  Upload Data Plate
                  <img
                    src={equipupload}
                    alt="cam"
                    height={14}
                    className="add-eqp-img "
                  />
                </span>
                &nbsp;
                <span
                  className="add-eqp-cnl-1 mt-1 cursor-p"
                  onClick={() => discardChanges()}
                >
                  Discard
                  <img
                    src={del}
                    alt="del"
                    height={14}
                    className="add-eqp-img "
                  />
                </span>
                <div
                  className="add-eqp-cnl-2 mx-1 mt-1 cursor-p "
                  onClick={() => handleEquipmentSave()}
                >
                  Save
                  <img src={save} alt="" className="add-eqp-img " height={12} />
                </div>
              </div>
            ) : (
              <div className="d-flex mt-1">
                <span className="mx-1 cursor-p woCard-icons-align"
                  style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}>
                  {mryvalue === 1 || mryval === 1 ? (
                    <img
                      onClick={(e) =>
                        HandleMemory("eqpsView", e, newEquipmentData)
                      }
                      src={VectorEqpBlue}
                      alt="memoryIconBlue"
                    />
                  ) : (
                    <img
                      onClick={(e) =>
                        HandleMemory("black", e, newEquipmentData)
                      }
                      width={22}
                      src={MemoryIcon}
                      alt="MemoryIcon"
                    />
                  )}
                </span>
                <div style={{ marginTop: "3px", cursor: "pointer", pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}>
                  <img
                    onClick={() => handleShareopen()}
                    src={shareblck}
                    alt=""
                  />
                </div>
                &nbsp;
                <div style={{ marginTop: "3px", cursor: "pointer",pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}>
                  {/* <img src={dltblck} alt="" 
                  onClick={()=>HandleEqpmntDelete(newEquipmentData)}
                  onClick={()=>HandleDelEqmodal(newEquipmentData)}
                  /> */}
                </div>
                &nbsp;
                <div
                  className="add-eqp-cnl-view1 cursor-p"
                  style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                  onClick={() => handleEditClick()}
                >
                  Edit
                  <img
                    src={editIcon}
                    alt="del"
                    height={12}
                    className="add-eqp-img "
                  />
                </div>
                &nbsp;
                {isCondition === "equipcreate" ? (
                  <div className="add-eqp-cnl">
                    Scan Data Plate
                    <img
                      src={scancam}
                      alt="cam"
                      height={14}
                      className="add-eqp-img "
                    />
                  </div>
                ) : (
                  <div className="add-eqp-cnl-view cursor-p"
                    style={{ pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}>
                    <span className="add-eqp-cnl-view">
                      Upload Data Plate
                      <img
                        src={equipupload}
                        alt="cam"
                        height={14}
                        className="add-eqp-img "
                      />
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {isProgress === true ? (
        <div className="progress-bar">
          <ProgressBar />
        </div>
      ) : (
        <Row className="w-100 mx-2 Equippos  ">
          {isExpand === "expand" ? (
            " "
          ) : (
            <Col className="left_panel mt-2"
              lg={
                isExpand === "expand" && rtExpand === "rt-expand"
                  ? 0
                  : rtExpand === "rt-expand"
                    ? 2
                    : isExpand === "expand"
                      ? 0
                      : 2
              }
            >
              <AddEquipmentHeader
                handleInfo={handleInfo}
                scrollCallback={scrollToDiv}
              />
            </Col>
          )}

          <Col
            lg={
              isExpand === "expand" && rtExpand === "rt-expand"
                ? 12
                : isExpand === "expand"
                  ? 9
                  : rtExpand === "rt-expand"
                    ? 10
                    : 7
            }
          >
            {isExpand === "expand" ? (
              <span
                className={
                  isExpand === "expand"
                    ? "backbtn-hvr2 "
                    : "backbtn-hvr p-0 m-0"
                }
              >
                <MdChevronRight
                  className="arro-right-icon"
                  size={25}
                  onClick={() => HandleExpand("")}
                />
              </span>
            ) : (
              <span>
                <MdChevronLeft
                  size={25}
                  onClick={() => HandleExpand("expand")}
                  className="leftbar-leftarrow "
                />
              </span>
            )}
            {rtExpand === "rt-expand" ? (
              <MdChevronLeft
                size={25}
                className="wod-expand-icon-right mx-2 "
                onClick={() => HandleRtExpand("")}
              />
            ) : (
              <MdChevronRight
                size={25}
                className="wod-expand-icon-img-right "
                onClick={() => HandleRtExpand("rt-expand")}
              />
            )}
            <Card className="mt-2 mx-2">
              <div className=" pagescroll">
                <div className="">
                  <div
                    ref={(ref) => (contentRefs.current[0] = ref)}
                    data-index={0}
                    className="content p-2 "
                  >
                    <EquipmentInfo
                      HandleSortTgl={HandleSortTgl}
                      sortTgl={sortTgl}
                      //  displayDate={displayDate}
                      HandleInfoAddEqpTgl={HandleInfoAddEqpTgl}
                      infoAeTgl={infoAeTgl}
                      newEquipmentData={newEquipmentData}
                      HandleAddTag={HandleAddTag}
                      upDateNewEquipment={upDateNewEquipment}
                      getErrorMessage={getErrorMessage}
                      eqid={eqid}
                      handleStartDateSelect={handleStartDateSelect}
                      handleEndDateSelect={handleEndDateSelect}
                      eqpinfo={eqpinfo}
                      isCondition={isCondition}
                      isErr={isErr}
                      selectDate={selectDate}
                      selectInstallDate={selectInstallDate}
                      equiptags={equiptags}
                      setEqptags={setEqptags}
                      eqpnotes={eqpnotes}
                      updateEquiptags={updateEquiptags}
                    // onSelectChange={handleSelectChange}
                    />
                  </div>
                  <div
                    ref={(ref) => (contentRefs.current[1] = ref)}
                    data-index={1}
                    className="content p-2"
                  >
                    <PreviousWorkOrdersEquipments eqpwoorders={eqpwoorders} />
                  </div>

                  <div
                    ref={(ref) => (contentRefs.current[2] = ref)}
                    data-index={2}
                    className="content p-2"
                  >
                    <LibraryEquipments />
                  </div>

                  <div
                    ref={(ref) => (contentRefs.current[3] = ref)}
                    data-index={4}
                    className="content p-2"
                  >
                    <EquipmentLogs />
                  </div>

                  <div className="div-custom-p"></div>
                </div>
              </div>
            </Card>
          </Col>
          {rtExpand === "rt-expand" ? (
            ""
          ) : (
            <Col className="mt-2"
              style={{ width: "25%" }}
              lg={
                rtExpand === "rt-expand" && isExpand === "expand"
                  ? 0
                  : rtExpand === "rt-expand"
                    ? 0
                    : isExpand === "expand"
                      ? 2
                      : 0
              }
            >
              <AdditionalFieldsEquipments
                isProgress={isProgress}
                additionalFields1={additionalFields1}
                eqpaddflds={eqpaddflds}
                isCondition={isCondition}
                onAdditionalFieldChange={onAdditionalFieldChange}
                isErr={isErr}
              />
            </Col>
          )}
        </Row>
      )}
      <AddTag Open={isTag} isClose={() => setIsTag(false)} />
      <DeleteModal
        Open={deltmodal}
        DelDiscription={"Discard all changes"}
        Close={() => setdeltmodal(false)}
        HandleDelete={() => discardChanges()}
      />
      <Alerts isOpen={Isopen} isClose={() => setIsopen(false)} data={isErr} additionalFields1={additionalFields1} />

      <CommonFooter />

      <Modal show={isEmpty} onHide={() => setIsEmpty(false)} centered size="sm">
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="attach-media-note line-align-bet">
            <div>{MOD_TEXT_FIELD_SETUP.ERR_MSG} </div>
            <img
              src={close}
              alt="Close"
              className="modal-header-icon"
              onClick={() => setIsEmpty(false)}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="error-msg-ipt-add-eqp">
            Please fill Mandatory Additional fields
          </div>
        </Modal.Body>
      </Modal>
      <MemoryModal
        isOpen={isMemory}
        isClose={() => setIsMemory(false)}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        ismemoryObj={ismemoryObj}
        woId={isWoid}
        id={ismemoryObj?.RefId}
        name={"Equipment"}
      />
      <ShareWoModal
        Open={isOpenshare}
        Close={() => setIsOpenshare(false)}
        data={eqpinfo}
        path={"AddEquipments"}
        woid={eqpinfo?.equipment_id}
        custid={custid}

      />
      <DeleteWOModal
        Open={deleqmodal}
        data={DEL_W_O.DEL_MODAL_BODY}
        custId={"#" + eqpinfo?.equipment_id}
         name={DEL_W_O.DEL_EQ}
        HandleDeleteCustomer={HandleEqpmntDelete}
        Close={() => setDeleqmodal(false)}
      />
      <MemoryblueModal
        Open={mryblue}
        mrydlt={mrydlt}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        Close={() => setMryblue(false)}
        ismemoryObj={ismemoryObj}
        memobj={memobj}
        custname={memobj?.Customer?.name}
        itemkey={itemkey}
        descvalue={descvalue}
        isProgress={ismemProgress}
        setMryvalue={setMryvalue}
        setMryval={setMryval}
        headname={
          itemkey === "eqpsView"
            ? `${memobj?.equipment_type || ""} / ${memobj?.manufacturer_id || ""
              }${memobj?.manufacturer_code || ""
              }`.trim()
            : ""
        }
        headid={itemkey === "eqpsView" ? memobj?.equipment_id : ""}
        openName={itemkey === "eqpsView" ? "Workorder" : ""}
      />
    </>
  );
}

export default AddEquipment;
