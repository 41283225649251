import React from "react";
import { Routes, Route } from "react-router-dom";
import FgtPassword from "../Login/ForgotPassword/FgtPassword";
import Login from "../Login/Login";
import WorkOrders from "../../features/workorders/WorkOrders";
import WorkFlow from "../../features/workorders/workflow/WorkFlow";
import Equipments from "../../features/equipments/Equipments";
import Library from "../../features/library/Library";
import WorkOrderTable from "../components/workordertable/WorkOrderTable";
import ViewEquipments from "../../features/equipments/View Equipments/ViewEquipments";
import Communication from "../../features/communications/Communication";
import Customers from "../../features/customers/Customers";
import MediaCenter from "../../features/mediacenter/MediaCenter";
import WODetails from "../../features/workorders/WorkOrderDetails/WODetails";
import AddEquipment from "../../features/equipments/AddEquipment/AddEquipment";
import NotesDetails from "../../features/Notes/NotesDetails";
import ViewEquipmentsTable from "../../features/equipments/View Equipments/View Equipments Table/ViewEquipmentsTable";
import Settings from "../../features/Settings/Setting/Settings";
import CompanyDetails from "../../features/Settings/Setting/Company Details/CompanyDetails";
import WorkOrderSetup from "../../features/Settings/Setting/Work Order Setup/WorkOrderSetup";
import ChatScreen from "../../features/communications/ChatScreen";
import CreateWorkflow from "../../features/Settings/Setting/CreateWorkflow/CreateWorkflow";
import ResetPassword from "../Login/ForgotPassword/Reset Password/ResetPassword";
import SecondResetPassword from "../Login/ForgotPassword/Reset Password/SecondResetPassword";
import CreateNewcustomer from "../../features/customers/CreateNewcustomer/CreateNewcustomer";
import UserSetup from "../../features/Settings/Setting/User Setup/UserSetup";
import WorkFlowSetUp from "../../features/Settings/Setting/Work Flow setup/WorkFlowSetUp";
import MasterForm from "../../features/Settings/Setting/Master Form/MasterForm";
import Integrations from "../../features/Settings/Setting/Integrations/Integrations";
import SearchScreen from "../framework/header/HeaderCommon/Searchscreen";
import FileRoom from "../../features/File Room/FileRoom";
import CustomerTable from "../../features/customers/CustomerTable";
import CustShareModal from "../../features/customers/CustShareModal";
import WOrdersList from "../../features/workorders/WOrdersList";
import ImageShare from "../components/ImageShare";
import EquipmentListPage from "../../features/equipments/EquipmentListPage";
import PDFViewer from "../../features/Settings/Setting/Master Form/PDFViewer";
import Dashboard from "../../features/dashboard/Dashboard";
import Estimate from "../../features/estimates/Estimate";
import CreateEstTemp from "../../features/estimates/CreateEstTemp";
import PDFView from "../../features/workorders/workflow/PDFView";
import CreateNewEst from "../../features/estimates/CreateNewEst";
import EstimatesTable from "../../features/estimates/EstimatesTable";

function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/FgtPassword" element={<FgtPassword />} />
      <Route path="/changePassword" element={<ResetPassword />} />
      <Route path="/resetPassword/:id/:email" element={<SecondResetPassword />}/>
      <Route path="/WorkOrders" element={<WorkOrders />} />
      <Route path="/ImageShare" element={<ImageShare />} />

      <Route
        path="/WoDetails/:serviceid/:workid/:teckid/:techpin/:company/:id"
        element={<WODetails />}
        render={() => <WODetails />}
      />
      <Route path="/WorkFlow" element={<WorkFlow />} />
      <Route path="/Equipments" element={<Equipments />} />
      <Route
        path="/ViewEquipments/:isWoid/:custid/:techid/:techid1/:compid"
        element={<ViewEquipments />}
      />
      <Route
        path="/AddEquipments/:isWoid/:custid/:techid/:techid1/:compid/:id"
        element={<AddEquipment />}
      />
      <Route path="/Library" element={<Library />} />
      <Route path="/WorkOrderTable" element={<WorkOrderTable />} />
      <Route
        path="/ViewEquipmentsTable/:isWoid/:custid/:techid/:techid1/:compid"
        element={<ViewEquipmentsTable />}
      />
      <Route
        path="/EquipmentListPage/:isWoid/:custid/:techid/:techid1/:compid"
        element={<EquipmentListPage />}
      />
      <Route path="/Communications" element={<Communication />} />
      <Route path="/Dashboard" element={<Dashboard />} />
      <Route path="/ChatScreen" element={<ChatScreen />} />
      <Route path="/Estimate" element={<Estimate />} />
      <Route path="/CreateEstTemp" element={<CreateEstTemp />} />
      <Route path="/EstimatesTable" element={<EstimatesTable />} />
      <Route path="/CreateNewEst" element={<CreateNewEst />} />
      <Route path="/Customers" element={<Customers />} />
      <Route path="/MediaCenter" element={<MediaCenter />} />
      <Route path="/fileRoom" element={<FileRoom />} />
      <Route path="/Settings" element={<Settings />} />
      <Route path="/CompanyDetails" element={<CompanyDetails />} />
      <Route path="/WorkOrderSetup" element={<WorkOrderSetup />} />
      <Route path="/CreateWorkflow" element={<CreateWorkflow />} />
      <Route path="/UserSetup" element={<UserSetup />} />
      <Route path="/WorkFlowSetUp" element={<WorkFlowSetUp />} />
      <Route path="/MasterForm" element={<MasterForm />} />
      <Route path="/Integrations" element={<Integrations />} />
      <Route
        path="/NotesDetails/:teckid/:serviceid/:custid"
        element={<NotesDetails />}
      />
      <Route path="CreateNewCustomer/:serviceid/:workid/:teckid/:techpin/:company/:shareid" element={<CreateNewcustomer />} />
      <Route path="SearchTo" element={<SearchScreen />} />
      <Route path="CustomerTable" element={<CustomerTable />} />
      <Route path="CustShareModal" element={<CustShareModal />} />
      <Route path="WOrdersList" element={<WOrdersList />} />
      <Route path="PDFViewer" element={<PDFViewer />} />
      <Route path="PDFView/:WoNo/:wfstep/:wowfId/:wfId" element={<PDFView />} />
    </Routes>
  );
}

export default Navigation;
