import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import CommonHeader from '../../common/framework/header/HeaderCommon/CommonHeader';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Col, Row } from 'react-bootstrap';
import EstimateSidebar from './EstimateSidebar';
import Table from "../../common/Assets/Images/Table_lighttable.png";
import EstimateCard from './EstimateCard';
import { AiOutlinePlus } from 'react-icons/ai';
import EstimateModal from './EstimateModal';
import './Estimate.css';

const Estimate = () => {
    const [isExpand, setIsExpand] = useState("");
    const [over, setOver] = useState(false);
    const [showModal, setShowModal] = useState(false); 

    const handleExpand = (val) => {
        setIsExpand(val);
    };

    const estimateData = {
        estimateNumber: "Estimate #00103",
        requestedTime: "11:30 AM on 12/25/2021",
        address: "190 Harlow Street, Bangor Maine, 04401",
        addressLink: "https://maps.google.com",
        value: "$1999999",
        statusDuration: "4 hours 32 minutes",
        description: `Inspecting, analyzing, and troubleshooting systems and equipment. Reading repair manuals and liaising with other professionals. Running tests and interpreting results to make effective recommendations. Writing up reports, safety regulations, and preventative maintenance plans. Inspecting, analyzing, and troubleshooting systems and equipment. Reading repair manuals and liaising with other professionals. Running tests and interpreting results to make effective recommendations. Writing up reports, safety regulations, and preventative maintenance plans.`,
    };

    const handleCreateNewEstimate = () => {
        setShowModal(false); 
    };

    return (
        <div>
            <CommonHeader />
            <div className="line-align-bet mt-2 w-100">
                <div className="d-flex ">
                    <Link to="/EstimatesTable"> 
                        <img src={Table} alt="table" className="img-style ml-1" />
                    </Link>
                    &nbsp;
                    <span className="TableText">Estimates</span>
                </div>

                <div className='d-flex'>
                   <button className="crt-new-est mx-3 p-4" onClick={() => setShowModal(true)}>
                     Create New Estimate
                     <AiOutlinePlus size={12} style={{ marginLeft: 5, marginBottom: 2 }} />
                   </button>
                </div>
            </div>

            <Row className="w-100">
                {isExpand === "expand" ? null : (
                    <Col
                        onMouseOver={() => setOver(true)}
                        onMouseOut={() => setOver(false)}
                       
                        xs={isExpand === "expand" ? 12 : 2}
                        md={isExpand === "expand" ? 12 : 2}
                        lg={isExpand === "expand" ? 0 : 2}
                        className="estimates-col1"
                    >
                        <EstimateSidebar />
                    </Col>
                )}

                <Col
                    xs={isExpand === "expand" ? 12 : 10}
                    md={isExpand === "expand" ? 12 : 10}
                    lg={isExpand === "expand" ? 12 : 10}
                    className="workOrder-col2"
                >
                    <>
                        <span
                            className={isExpand === "expand" || over ? "backbtn-hvr2 p-0 m-0" : "backbtn-hvr p-0 m-0"}
                        >
                            {isExpand === "expand" ? (
                                <MdChevronRight size={25} onClick={() => handleExpand("")} />
                            ) : (
                                <MdChevronLeft size={25} onClick={() => handleExpand("expand")} />
                            )}
                        </span>
                        <div className="mx-2 mt-2 card p-1 w-100">
                            <EstimateCard estimate={estimateData} />
                        </div>
                    </>
                </Col>
            </Row>

            {/* Modal for creating a new estimate */}
            <EstimateModal
                show={showModal}
                handleClose={() => setShowModal(false)} 
                onCreateNewEstimate={handleCreateNewEstimate} 
            />
        </div>
    );
}

export default Estimate;
