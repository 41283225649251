import React, { useEffect, useState, } from "react";
import addfieldsIcon from "../../../common/Assets/Icons/Left Icon.svg";
import Alerts from "../../../common/components/Alerts/Alerts";
import "./WORightSideBar.css";
import { CustmoerContacts } from "../../../services/WorkOrdersService";
import ContactModal from "../../../features/customers/ContactModal";
import contactsIcon from "../../../common/Assets/Icons/persons_main.svg";
import { Card } from "react-bootstrap";
import { WORK_ORDER_CONSTANTS } from "../../../common/components/Constants";
import { WoContacts } from "../../../services/WorkOrdersService";
import contactprfl from "../../../common/Assets/Icons/user_circle_outline_main.svg";
import dail from "../../../common/Assets/Icons/dailicon.svg";
import messageIcon from "../../../common/Assets/Icons/messageIcon.svg";
import add from "../../../common/Assets/Icons/add.svg";
import ProgressBar from "../../../common/navigation/ProgressBar";
import Notes from "../../Notes/Notes";
import { CUSTOMER } from "../../../common/components/Values";

export default function WORightSideBar(props) {

  const [isToggle, setIsToggle] = useState("Extrafield");
  const [isContactsToggle, setIsContactsToggle] = useState(true);
  const [isExtaFieldsToggle, setIsExtraFieldsToggle] = useState(true);
  const [isContacts, setIsContacts] = useState([]);
  const HandleToggle = (value) => {
    setIsToggle(value);
  };
  const [contacts, setWocontacs] = useState([])
  const custmer_id = props?.custId
  let custid = window.location.href.split("/")[6]
  if (props?.custId) {
    custid = props?.custId
  }
  const woid = window.location.href.split("/")[5]
  const techId = window.location.href.split("/")[7]
  useEffect(() => {
    async function HandleContacts() {
      if (custid > 0) {
        const contactsResult = await WoContacts(custid);
        setWocontacs(contactsResult?.data);
        setIsContacts(contactsResult?.data)
      }
    }
    HandleContacts();
  }, [custid, woid, techId]);
  const [CustContact, setCustContact] = useState({
    picture: "",
    first_name: "",
    last_name: "",
    email_id: "",
    telephone: "",
    note: ""
  });
  const HandleCloseContactModal = () => {
    setemailexisterr("")
    setphoneexisterr("")
    setIsContactModal(false);
    setIsErr([]);
  };
 
  const [isContactModal, setIsContactModal] = useState(false);
  const [isBtn, setIsBtn] = useState("")
  const [isEdit, setIsEdit] = useState("");
  const [isErr, setIsErr] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const validateTelephone = (number) => {
    const phoneRegex =
    /^\d{3}-\d{3}-\d{4}$/;
    return !phoneRegex.test(number);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !emailRegex.test(email);
  };
  const HandleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "telephone") {
      const cleaned = value.replace(/\D/g, "");
      const formatted = cleaned
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        .substr(0, 12); 
      setCustContact({
        ...CustContact,
        [name]: formatted,
      })}
    else{setCustContact({ ...CustContact, [name]: value });}
  };
  const[profileImage, setProfileImage] = useState("");
  const [isImgObj, setIsImgObj] = useState({})
  const handleImageUpload = (e,item) => {
    const file = e?.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setProfileImage(base64String);
        item.picture=base64String
        setCustContact({
         ...CustContact, picture: base64String,
        })
      };
      reader.readAsDataURL(file);
      setIsImgObj(item)
      setProfileImage("")
    }
  };
  const [cntctId, setcntctId] = useState()
  const HandleAddContact = (val, key, item) => {
    setcntctId(item?.contact_id)
    setIsBtn(val)
    setIsImgObj(item <= 0 ? {} : item )
    setIsEdit(item?.contact_id || "");
    setIsContactModal(true);
    setCustContact({
      picture: item?.picture || "",
      first_name: item?.first_name || "",
      last_name: item?.last_name,
      email_id: item?.email || "",
      telephone: item?.telephone || "",
      note: item?.note || ""
    });
  };
  const handleDeleteCntctImg = () => {
    setIsImgObj({})
    setCustContact({
      ...CustContact, picture: "",
     })
    };
  const [emailexisterr, setemailexisterr] = useState("")
  const [phoneexisterr, setphoneexisterr] = useState("")
  const handleSave = async (key) => {
    let filterData = []

    if (isBtn === "Create") {
      filterData = isContacts
    }
    else if (isBtn === "Save") {
      filterData = isContacts.filter(
        (item) =>
          item?.contact_id !== cntctId
      )
    }
    let existingphone = filterData.filter(
      (item) => item?.telephone === CustContact?.telephone
    );
    if (existingphone?.length > 0) {
      setphoneexisterr("Telephone already exists")
    } else {
      setphoneexisterr('')
    }
    let existingEmail = filterData.filter((item) =>
      item?.email === CustContact?.email_id
    )
    if (existingEmail?.length > 0) {
      setemailexisterr("Email already exists")
    } else {
      setemailexisterr('')
    }

    let errArr = [];
    let filterObj = Object?.keys(CustContact);
    filterObj?.map((it) => {
      if ((CustContact[it]?.length <= 0 && it !== "last_name") || (CustContact[it]?.length <= 0 && it !== "email_id") || (CustContact[it]?.length <= 0 && it !== "note")) {
        errArr.push({
          message: it,
        });
      }
      if (it === "telephone" && CustContact[it]?.length > 0) {
        if (validateTelephone(CustContact[it])) {
          errArr.push({
            message: `Telephone`,
          });
        }
      }
      if (it === "email_id") {
        if (validateEmail(CustContact[it])) {
          errArr.push({
            message: `Email`,
          });
        }
      }
      errArr = errArr.filter(
        (err) => err.message !== "last_name" && err.message !== "email_id"
      );
      return errArr;
    });
    if (errArr?.length > 0) {
      setIsOpen(true);
      setIsErr(errArr);
    } else {
      setIsErr([]);
      let custdata = {
        // customer_id: `${cidNo}`,
        customer_id: custid ? custid : custmer_id,
        contact_id: isEdit ? isEdit : 0,
        name: "",
        first_name: CustContact?.first_name,
        last_name: CustContact?.last_name,
        address: "",
        address_2: "",
        city: "",
        state: "",
        zip_code: "",
        telephone: CustContact?.telephone,
        email: CustContact?.email_id,
        note: CustContact?.note,
        telephone_2: "",
        country: "",
        picture: CustContact?.picture,
        contacts_additional_fields: {},
      };
      if (existingphone?.length <= 0 && existingEmail?.length <= 0) {
      let Res = await CustmoerContacts(custdata);
      if (Res.success) {
        let Obj = 
        {
          ...Res?.data,picture:CustContact?.picture
        }
        setIsContactModal(false);
        if (isEdit) {
          setIsContacts(isContacts?.map(contact => contact?.contact_id === isEdit ? Obj : contact));
        } else {
          setIsContacts([...isContacts, Obj]);
        }
       
      }
    }}
  };


  const [searchQuery, setSearchQuery] = useState("");
  const filteredContacts = isContacts?.filter((contact) => {
    const lowerQuery = searchQuery?.toLowerCase();
    return (
      contact?.name?.toLowerCase()?.includes(lowerQuery) ||
      contact?.email?.toLowerCase()?.includes(lowerQuery) ||
      contact?.telephone?.toLowerCase()?.includes(lowerQuery)
    );
  });
  
  return (
    <div className="mt-2  ">
      <div className=" sidebar-rightside">
        <span
          className={`txt-style-sidebar cursor-p ${isToggle === "Notes" ? "bold-text" : ""
            }`}
          onClick={() => HandleToggle("Notes")}
        >
          {WORK_ORDER_CONSTANTS.NOTES}
        </span>
        <span
          className={`txt-style-sidebar cursor-p ${isToggle === "Contacts" ? "bold-text" : ""
            }`}
          onClick={() => HandleToggle("Contacts")}
        >
          {WORK_ORDER_CONSTANTS.CONTACTS}
        </span>
        <span
          className={`txt-style-sidebar cursor-p ${isToggle === "Extrafield" ? "bold-text" : ""
            }`}
          onClick={() => HandleToggle("Extrafield")}
        >
          {WORK_ORDER_CONSTANTS.EXTRA_FILED}
        </span>
      </div>

      {isToggle === "Notes" ? (
        <div className="mt-1 rightside_box">
          <Notes
            data={props?.data}
            setNotesArray={props?.setNotesArray}
            apiData={props?.apiData}
            isVal={props?.isVal}
          />
        </div>
      ) : isToggle === "Contacts" ? (
        <div className="mt-1 rightside_box">
          <div className="centerings-cont">
            <img src={contactsIcon} alt="contacts" className="" />
            <span
              className="contacts-span mx-1 cursor-p"
              onClick={() => setIsContactsToggle(!isContactsToggle)}
            >
              {" "}
              Contacts({contacts?.length === 0 ? "0" : `${contacts?.length}`}
              ){" "}
            </span>

            {props?.isVal !== "create" && (
              <img
                style={{ marginLeft: "auto", cursor: "pointer", pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }}
                src={add}
                alt="toggle edit notes"
                className="plusIcon-modal"
                onClick={() => HandleAddContact("Create",0, 0)}
              />
            )}
          </div>
          {filteredContacts?.length > 8 &&
          <div className="d-flex  flex-column">
            <input
            type="search"
            className="desc-ipt-wkord-reason inpt-hover"
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
            style={{ padding: "8px", width: "100%", marginBottom: "10px" }}
          />
          </div>
          }
          <div className=" pagescroll" style={{ padding: "8px 5px" }}>
            {isContactsToggle && filteredContacts?.length > 0 &&
              filteredContacts?.sort(
                (a, b) =>
                  new Date(b.modified_date) - new Date(a.modified_date)
              )?.map((item, index) => (
                <div className="mb-1"
                //  style={{
                //   border: `1px solid ${item?.contact_id === props?.contacId ? 'red' : 'transparent'}`,
                // }} 
                >

                  <Card style={{ padding: 3,pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto" }} onClick={(e) => props?.handleconctid(item)}>
                    <div className="cursor-p" onDoubleClick={() => HandleAddContact("Save", item?.contact_id, item)}>
                      <div className="dail-icon" key={index} >
                        <div className="">
                        <img src={item?.picture ? item?.picture : contactprfl} alt="cntctprfl" style={{height:'22px' ,width:'22px',borderRadius:'50px'}}/>
                          <span className="text-contct">{item?.name}</span>
                          {/* <span className="text-contct">- {item?.contact_id}</span> */}
                        </div>
                        <div className="bg-suc">
                          <img className="dail-img" src={dail} alt="dailIcon" />
                          <img
                            className="message-icon"
                            src={messageIcon}
                            alt="messageIcon"
                          />
                        </div>
                      </div>
                      <div className="">
                        <span className="text-telephone">{item?.telephone}</span>
                      </div>
                      <div className="">
                        <span className="text-email">{item?.email}</span>
                      </div>
                    </div>
                  </Card>

                </div>
              ))}
          </div>
        </div>
      ) : (

        <div className="mt-1 add-fields-wrsb rightside_box">
          <img src={addfieldsIcon} alt="addfields" width={20} />
          <span
            className="notes-header-txt cursor-p"
            onClick={() => setIsExtraFieldsToggle(!isExtaFieldsToggle)}
          >
            {WORK_ORDER_CONSTANTS.EXTRA_FILED}
          </span>{" "}
          {/* {props.additionalFields?.map((i) => (
            <div className="">
              <div className="d-flex ">
                <span className="wo-sb-txt">{i?.name} </span>
                <span className="wo-sb-colon">:</span>
                <span className="wo-sb-txt-val">{i?.value}</span>
              </div>
            </div>
          ))} */}
          {props?.isProgress ? (
            <div className="progress-bar">
              <ProgressBar />
            </div>
          ) : (
            isExtaFieldsToggle && props.extraFieldComponent
          )}

        </div>
      )}
      <ContactModal
        //  Customer= {"#"+Customer}
        woid={woid}
        Customer={custid}
        name={CUSTOMER.CUSTOMER}
        CustContact={CustContact}
        isErr={isErr}
        isEdit={isEdit}
        isBtn={isBtn}
        path = {"WoDetails"}
        isContactModal={isContactModal}
        HandleCloseContactModal={HandleCloseContactModal}
        HandleInputChange={HandleInputChange}
        handleSave={handleSave}
        profileImage={profileImage}
        isImgObj={isImgObj}
        handleDeleteCntctImg={handleDeleteCntctImg}
        handleImageUpload={handleImageUpload}
        emailexisterr={emailexisterr}
        phoneexisterr={phoneexisterr}
        validateEmail={validateEmail}
      />
      <Alerts isOpen={isOpen} isClose={() => setIsOpen(false)} data={isErr}></Alerts>
    </div>
  );
}
