import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
// import compnyIcon from "../../../../common/Assets/Icons/favicon.ico";
import close from "../../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import plus from "../../../../common/Assets/Icons/plusW.svg";
import save from "../../../../common/Assets/Icons/save.svg";
import "./DivisionModal.css";
import {
  COMPANY_DETAILS_SETTINGS,
  TAGS_MODAL_VALUES,
  USERS_SETUP,
} from "../../../../common/components/Constants";
import { AddDivisionPost } from "../../../../services/WorkOrdersService";
import { SketchPicker } from "react-color";
import { DivisionByID,GetDivById } from "../../../../services/SettingsServices";
import ProgressBar from "../../../../common/navigation/ProgressBar";
const DivisionModal = (props) => {
  const initialCompanyDetails = useMemo(() => ({
    company_id: null,
    name: "",
    address: "",
    address_2: "",
    city: "",
    state: "",
    zip_code: null,
    telephone: null,
    email: "",
    country: "",
    logo_base64: "",
    color_primary: "",
    color_secondary: "",
    color_tertiary: "",
  }), []);
  const [filterUser, setUserFilter] = useState([]);
  const [userDivbyID, setUserDivbyID] = useState([]);
  const [colorSelector, setColorSelector] = useState({});
  const [colPckr, setColPckr] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const HandleSearch = (e) => {
    setSearchQuery(e.target.value);
    let searchinp = e.target.value?.toUpperCase();
    let SearchArr = filterUser?.filter(
      (it) =>
        it?.first_name?.toUpperCase()?.includes(searchinp) ||
        it?.last_name?.toUpperCase()?.includes(searchinp)
    );
    searchinp.length > 0
      ? setUserDivbyID(SearchArr)
      : setUserDivbyID(filterUser);  
  };

  const HandleColPckr = (value, key) => {
    setColPckr(true);
    setColorSelector({ value, key });
  };


  const [spnsrData, setSpnsrData] = useState(initialCompanyDetails);
  // const [addCompLogo, setAddCompLogo] = useState(false);
  const [CompLogo, setCompLogo] = useState("");
  const GetDivisionById = useCallback(async (id) => {
    let resp = await DivisionByID(id);
  
    setUserDivbyID(resp?.data);
    setUserFilter(resp?.data);
  }, []);
  useEffect(() => {
    GetDivisionById(props?.data?.division_id);
  }, [GetDivisionById, props?.data?.division_id]);
  const HandleGetDivById = useCallback(async (id) => {
    let resp = await GetDivById(id);
    setCompLogo(resp?.data?.logo)
    setUserDivbyID(resp?.data);
    setUserFilter(resp?.data);
  }, []);
  useEffect(() => {
    HandleGetDivById(props?.data?.division_id);
  }, [HandleGetDivById, props?.data?.division_id]);
  // const [compIcon, setCompIcon] = useState(null);
  const [compbase64img, setBase64img] = useState(null);
  const HandleAddCompLogo = (e) => {
    // setAddCompLogo(true);
    const file = e?.target?.files[0]; // get the selected file
    if (file) {
      // const imageUrl = URL.createObjectURL(file); // create a temporary URL for the image
      // setCompIcon(imageUrl); // update the state with the image URL
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64img(base64String);
      };
  
      // Read the file as a Data URL (Base64 string)
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialCompanyDetails,
  });

  useEffect(() => {
    if (props?.data && Object.keys(props?.data).length > 0) {
      setSpnsrData(props?.data);
      reset(props?.data); 
    } else {
      setSpnsrData(initialCompanyDetails); 
      reset(initialCompanyDetails); 
    }
  }, [initialCompanyDetails, props?.data, reset]);

  const [isProgress, setIsProgress] = useState(false);
  const onSubmit = async (data) => {
    if (
      !spnsrData.color_primary ||
      !spnsrData.color_secondary ||
      !spnsrData.color_tertiary
    ) {
      alert("Please select all colors: Primary, Secondary, and Tertiary.");
      return;
    }

    let newData = {
      name: data?.name,
      address: data?.address,
      address2: data.address_2,
      city: data.city,
      zip_code: data.zip_code,
      state: data.state,
      country: data.country,
      color_primary: spnsrData.color_primary,
      color_secondary: spnsrData.color_secondary,
      color_tertiary: spnsrData.color_tertiary,
      color_dark_primary: "",
      color_dark_secondary: "",
      color_dark_tertiary: "",
      division_id: props?.data?.division_id || 0,
      sponsor_id: props?.copmspsrId,
      created_date: "",
      modified_date: "",
      logo: compbase64img?compbase64img : "",
      email: data.email,
      telephone: data.telephone,
      status: "Active",
      dark_mode: data.darkMode,
    };
    setIsProgress(true);
    let Res = await AddDivisionPost(newData);
    props?.HandleDivisionList(Res?.data);
    setIsProgress(false);
    props?.isClose();
    reset(initialCompanyDetails);
    
    localStorage.setItem("Divval",JSON.stringify(Res?.data?.division_id));
  };
  const handleCancel = () => {
    reset(initialCompanyDetails);
    props?.isClose();
    setBase64img('')
    props?.setSelectedDivisionId(null)
    setSearchQuery(null);
  };
  useEffect(()=>
  {
    setBase64img('')
    setSearchQuery(null)
  },[props?.isClose])
  
  const darkModeValue = watch("darkMode");
  React.useEffect(() => {}, [darkModeValue]);

  // const HandleColorChange = useCallback(() => {
  //   document.body.style.backgroundColor = darkModeValue ? "#333" : "#f0f0f0";
  //   document.body.style.color = darkModeValue ? "#fff" : "#000";

  //   // document.body.style.backgroundColor = darkModeValue ? "#333" : "#f0f0f0";
  //   // document.body.style.color = darkModeValue ? "#fff" : "#000";
  // }, [darkModeValue]);

  // useEffect(() => {
  //   HandleColorChange();
  // }, [HandleColorChange]);
  const fileInputRef = useRef(null);

  return (
    <div>
      <Modal show={props?.isOpen} onHide={props?.isClose} size="lg" centered>
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <span style={{ fontSize: "16px", color: "white" }}>
            {" "}
            {props?.isVal === "edit" ? " Edit Division" : "Create Division"}
          </span>
          <img
            src={CloseIcon}
            className="modal-header-icon"
            onClick={props?.isClose}
            alt="Close"
          />
        </Modal.Header>
        {isProgress === true ? (
          <div
            style={{
              height: "590px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressBar />
          </div>
        ) : (
          <Modal.Body>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="division-modal fixd-height"
            >
              <div className=" px-2">
                <div className="form-row form-row-spacing">
                  <div className="form-group">
                    <label>Division Name:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input Division Name"
                      {...register("name", {
                        required: "Division Name is required",
                      })}
                    />
                    {errors.name && (
                      <span className="error-message">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Address:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input Address"
                      {...register("address", {
                        required: "Address is required",
                      })}
                    />
                    {errors.address && (
                      <span className="error-message">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-row form-row-spacing">
                  <div className="form-group">
                    <label>Address 2:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input Address 2"
                      {...register("address_2")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Country:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input Country"
                      {...register("country", {
                        required: "Country is required",
                      })}
                    />
                    {errors.country && (
                      <span className="error-message">
                        {errors.country.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* Third Row */}
                <div className="form-row form-row-spacing">
                  <div className="form-group">
                    <label>City:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input City"
                      {...register("city", { required: "City is required" })}
                    />
                    {errors.city && (
                      <span className="error-message">
                        {errors.city.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Contact Number:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input Phone Number"
                      {...register("telephone", {
                        required: "Contact Number is required",
                        pattern: {
                          value: /^[0-9\s-]{10,15}$/,
                          message: "Invalid phone number",
                        },
                      })}
                    />
                    {errors.telephone && (
                      <span className="error-message">
                        {errors.telephone.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* Fourth Row */}
                <div className="form-row form-row-spacing">
                  <div className="form-group">
                    <label>State:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input State"
                      {...register("state", { required: "State is required" })}
                    />
                    {errors.state && (
                      <span className="error-message">
                        {errors.state.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Email Address:</label>
                    <input
                      type="email"
                      className="form-control small-input"
                      placeholder="Input Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="error-message">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Fifth Row */}
                <div className="form-row form-row-spacing">
                  <div className="form-group">
                    <label>Zip:</label>
                    <input
                      type="text"
                      className="form-control small-input"
                      placeholder="Input Zip"
                      {...register("zip_code", {
                        required: "Zip Code is required",
                        pattern: {
                          value: /^\d{5}$/,
                          message: "Invalid zip code",
                        },
                      })}
                    />
                    {errors.zip_code && (
                      <span className="error-message">
                        {errors.zip_code.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <span className="">Dark Mode?:</span>
                    <label className="switch">
                      <input type="checkbox" {...register("darkMode")} />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                className="m-2 cursor-p"
                style={{ border: "1px solid white", backgroundColor: "white" }}
              >
                <div>
                  <>
                    <div className="line-align-bet">
                      <div style={{ width: "9.5%" }}>
                        <label className="sett-txt">
                          {COMPANY_DETAILS_SETTINGS.LOGO}:
                        </label>
                        <input
                          type="file"
                          onChange={(e) => HandleAddCompLogo(e)}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <div
                          className="set-crd-area"
                          onClick={() => fileInputRef.current.click()}
                        >
                          {props?.isVal === "edit" ? (
                            <img
                              className="comp-icon  div-set img-thumbnail"
                              src={compbase64img ? compbase64img : CompLogo}
                              alt="company icon"
                            />
                          ) : (
                            <span className="mx-2">Upload Logo</span>
                          )}
                        </div>
                      </div>
                      <div className="" style={{ width: "89.5%" }}>
                        <label className="sett-txt">
                          {COMPANY_DETAILS_SETTINGS.BANNER_COLOR}
                        </label>
                        <div className="d-flex line-align-bet ">
                          <div
                            className="set-crd-area"
                            style={{
                              width: "32.5%",
                              backgroundColor: `${spnsrData.color_primary}`,
                            }}
                          >
                            {" "}
                            <label className="sett-txt">
                              {COMPANY_DETAILS_SETTINGS.PRIMARY_COLOR}
                            </label>
                            <div
                              className="color-txt d-flex mx-1 wdt-90 bg-white "
                              onClick={() =>
                                HandleColPckr(
                                  spnsrData.color_primary,
                                  "color_primary"
                                )
                              }
                            >
                              <div
                                className="color-wheel"
                                style={{
                                  backgroundColor: `${spnsrData.color_primary}`,
                                }}
                              ></div>
                              <span className="color-hx mx-1">
                                {spnsrData.color_primary}
                              </span>
                            </div>
                          </div>
                          <div
                            className="set-crd-area"
                            style={{
                              width: "32.5%",
                              backgroundColor: `${spnsrData.color_secondary}`,
                            }}
                          >
                            {" "}
                            <label className="sett-txt">
                              {COMPANY_DETAILS_SETTINGS.SECONDARY_COLOR}
                            </label>
                            <div
                              className="color-txt d-flex mx-1 wdt-90 bg-white"
                              onClick={() =>
                                HandleColPckr(
                                  spnsrData.color_secondary,
                                  "color_secondary"
                                )
                              }
                            >
                              <div
                                className="color-wheel"
                                style={{
                                  backgroundColor: `${spnsrData.color_secondary}`,
                                }}
                              ></div>
                              <span className="color-hx mx-1">
                                {spnsrData.color_secondary}
                              </span>
                            </div>
                          </div>
                          <div
                            className="set-crd-area"
                            style={{
                              width: "32.5%",
                              backgroundColor: `${spnsrData.color_tertiary}`,
                            }}
                          >
                            {" "}
                            <label className="sett-txt">
                              {COMPANY_DETAILS_SETTINGS.TERTIARY_COLOR}
                            </label>
                            <div
                              className="color-txt d-flex mx-1 wdt-90 bg-white"
                              onClick={() =>
                                HandleColPckr(
                                  spnsrData.color_tertiary,
                                  "color_tertiary"
                                )
                              }
                            >
                              <div
                                className="color-wheel"
                                style={{
                                  backgroundColor: `${spnsrData.color_tertiary}`,
                                }}
                              ></div>
                              <span className="color-hx mx-1">
                                {spnsrData.color_tertiary}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                <div className="line-align-bet">
                  <div className="d-flex align-items-center">
                    <span className="cur-dvsn-span ml-2 mt-1">
                      Current Division:
                    </span>
                  </div>
                </div>
                <div className="mt-1 ml-2">
                  <input
                    type="text"
                    className="form-control small-input w-50"
                    placeholder="Search users by name..."
                    value={searchQuery}
                    onChange={(e) => HandleSearch(e)}
                  />
                </div>
                <Row className="mt-2">
                  <Col xs={5} className="usr-stp-hdr-us-name ">
                    {USERS_SETUP.NAME_US}
                  </Col>
                  <Col xs={5} className="usr-stp-hdr-us-name">
                    {USERS_SETUP.TEAMS}
                  </Col>
                  <Col xs={2} className="usr-stp-hdr-us">
                    {USERS_SETUP.SELECTED}
                  </Col>
                </Row>
                {userDivbyID?.length > 0 ? (
                  <Container fluid className="row-container">
                    <Row>
                      <div className="user-list-container">
                        {userDivbyID?.map((option, index) => (
                          <div
                            key={index}
                            className={
                              userDivbyID?.length === index + 1
                                ? "user-item-container2"
                                : "user-item-container"
                            }
                          >
                            <span>
                              {option.first_name} {option.last_name}
                            </span>
                            <input
                              checked
                              type="checkbox"
                              className="user-checkbox"
                            />
                          </div>
                        ))}
                      </div>
                    </Row>
                  </Container>
                ) : (
                  <div className="no-div-avlbl">Add a user to begin</div>
                )}
              </div>
              {/* Footer */}
            </form>
          </Modal.Body>
        )}
        <div className="modal-footer-fixed model_footer">
          <div
            className="modal-footer-save-cancel-btns"
            onClick={handleSubmit(onSubmit)}
          >
            {TAGS_MODAL_VALUES.SAVE}
          </div>
          <div className="modal-footer-save-cancel-btns" onClick={handleCancel}>
            {TAGS_MODAL_VALUES.DISCARD}
          </div>
        </div>
      </Modal>
      <Modal centered show={colPckr} onHide={() => setColPckr(false)} size="sm">
        <Modal.Header className="attach-media-note modal-bg-color modal-header-height px-2">
          <span> </span>
          <img
            className="modal-header-icon flt-r"
            onClick={() => {
              setColPckr(false);
              setColorSelector({});
            }}
            alt=""
            src={close}
          />
        </Modal.Header>
        <Modal.Body>
          <SketchPicker
            value={colorSelector.value}
            className="ml-4"
            onChange={(updatedColor) =>
              setSpnsrData({
                ...spnsrData,
                [colorSelector.key]: updatedColor.hex,
              })
            }
          />
          <hr className="m-2" />
          <div>
            <div className="row line-align-even flx-wrp">
              <button
                className="col-5  modal-footer-save-cancel-btns"
                style={{}}
                onClick={() => {
                  setColPckr(false);
                  setColorSelector({});
                }}
              >
                <img src={plus} className="skw-45" alt="cancel" /> Cancel
              </button>
              <button
                className="col-5  modal-footer-save-cancel-btns"
                onClick={() => {
                  setColPckr(false);
                  setColorSelector({});
                }}
              >
                {" "}
                <img src={save} alt="save" width={12} /> Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DivisionModal;
