import React, { useEffect, useState } from "react";
import "./CommunicationStyles.css";
import CommonHeader from "../../common/framework/header/HeaderCommon/CommonHeader";
import CommonFooter from "../../common/framework/footer/CommonFooter";
import person from "../../common/Assets/Images/Unionperson.svg";
import chat from "../../common/Assets/Images/Vectorchat.svg";
import Doller from "../../common/Assets/Images/Dollarsigndol.svg";
import Right from "../../common/Assets/Images/Vector (Stroke)rt.svg";
import adj from "../../common/Assets/Icons/Adjt.svg";
import add from "../../common/Assets/Icons/add.svg";
import moment from "moment";
import ProgressBar from "../../common/navigation/ProgressBar";
import {
  authTokenAsynk,
  getEmails,
} from "../../services/CommunicationsService";
import { Client } from "@twilio/conversations";
import { NavigateFunction } from "../../common/navigation/NavigationChild";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import CreateCommuncation from "./CreateCommunication";
import CommunicationFilter from "./ComFilter";

function Communication() {
  const navigation = useNavigate();
  const [convData, setConvData] = useState([]);
  const [isEmails, setIsEmails] = useState([]);
  const [totlaData, setTotlaData] = useState([]);
  const [creatcom, setCreateCom] = useState();
  const [openFilter, setOpenFilter] = useState(false);

  const GetAuthToken = useCallback(async () => {
    let user = JSON.parse(localStorage.getItem("userName"));
    let serviceSid = "ISc3df9f655e83415e9bf7ef48599575bd";
    let inpdata = {
      serviceSid: serviceSid,
      identity: user,
    };
    let res = await authTokenAsynk(inpdata);

    if (res.status === 200) {
      localStorage.setItem("token", JSON.stringify(res.data.content.token));
      TwiloFunct(res.data.content.token);
    }
  }, []);
  useEffect(() => {
    GetAuthToken();
    EmailsGet();
  }, [GetAuthToken]);

  const TwiloFunct = (usertoken) => {
    const client = new Client(usertoken);

    client?.on("conversationJoined", async (conversation) => {
      let msges = await conversation.getMessages();
      const resMsges = msges.items;
      if (resMsges.length) {
        conversation.lastmessage = resMsges[resMsges.length - 1].body;

        conversation.author = resMsges[resMsges.length - 1].author;
        setConvData((conversaionList) => [...conversaionList, conversation]);
      }
    });
  };
  const EmailsGet = async () => {
    let inpobj = {
      user: "9packsoftware1234@gmail.com",
      password: "dpylfxkopzxdkizn",
    };
    const res = await getEmails(inpobj);
    // if (res.status === 200) {
    //   setIsEmails(res?.data?.data?.emails);
    // }
    if (res === undefined) {
      setIsEmails([]);
    }
  };
  useEffect(() => {
    var Today = new Date();
    var Yesterday = new Date(Today);
    Yesterday.setDate(Today.getDate() - 1);
    let newData = [...convData, ...isEmails];
    const RESPONSEDATA = Object.values(
      newData.reduce((acc, item) => {
        const itemDate = item.date ? item.date : item.dateCreated;
        const dateCreated = moment(itemDate).format("YYYY-MM-DD");
        if (!acc[dateCreated]) {
          acc[dateCreated] = {
            title: dateCreated,
            data: [],
          };
        }
        if (
          moment(dateCreated).format("YYYY MM DD") ===
          moment(Today).format("YYYY MM DD")
        ) {
          acc[dateCreated].title = "Today";
          acc[dateCreated].data.push(item);
        } else if (
          moment(dateCreated).format("YYYY MM DD") ===
          moment(Yesterday).format("YYYY MM DD")
        ) {
          acc[dateCreated].title = "Yesterday";
          acc[dateCreated].data.push(item);
        } else {
          acc[dateCreated].title = moment(dateCreated).format("MMMM DD, YYYY");
          acc[dateCreated].data.push(item);
        }
        return acc;
      }, {})
    );
    RESPONSEDATA.sort((a, b) => new Date(b.title) - new Date(a.title));
    setTotlaData(RESPONSEDATA);
  }, [convData, isEmails]);

  const HandleChatOpen = async (thisConversation, date) => {
    NavigateFunction({
      to: `ChatScreen`,
      params: thisConversation?.sid,
      date,

      navigate: navigation,
    });
  };
  const [isState, setIsState] = useState("History");
  const HandleTabs = (values) => {
    setIsState(values);
  };
  return (
    <div className="page-container">
      <div className="content-wrap">
        <div className="chat-main-header">
          <div>
            <CommonHeader />
          </div>
          <div className="p-2 line-align-bet">
            <div>
              <button
                className={isState === "History" && "btn-class"}
                onClick={() => HandleTabs("History")}
                style={{ background: isState === "History" ? "#4189CD" : "" }}
              >
                <span style={{ color: isState === "History" ? "white" : "" }}>
                  History
                </span>
              </button>
              <button
                onClick={() => HandleTabs("Techinician")}
                style={{
                  background: isState === "Techinician" ? "#4189CD" : "",
                }}
              >
                <span
                  style={{
                    color: isState === "Techinician" ? "white" : "",
                  }}
                >
                  Techinician
                </span>
              </button>
              <button
                onClick={() => HandleTabs("Dispatcher")}
                style={{
                  background: isState === "Dispatcher" ? "#4189CD" : "",
                }}
              >
                <span
                  style={{
                    color: isState === "Dispatcher" ? "white" : "",
                  }}
                >
                  Dispatcher
                </span>
              </button>
              <button
                onClick={() => HandleTabs("Contact")}
                style={{ background: isState === "Contact" ? "#4189CD" : "" }}
              >
                <span style={{ color: isState === "Contact" ? "white" : "" }}>
                  Contacts
                </span>
              </button>
            </div>
            <div>
              <img
                onClick={() => setCreateCom(true)}
                className="mx-2"
                src={add}
                alt="add"
                width={12}
                style={{ cursor: "pointer" }}
              />
              <img
                src={adj}
                alt="adj"
                width={15}
                onClick={() => setOpenFilter(true)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="mx-2 line-border"></div>
        </div>
        <div>
          <div style={{ padding: "2px 5px 100px 5px" }}>
            {isEmails?.length >= 0 ? (
              totlaData.map((item) => (
                <>
                  <div className="date-styles mx-1">{item.title}</div>
                  {item.data.map((it) => (
                    <>
                      <div className="communication-card">
                        <div className="d-flex mt-2 gap-2">
                          <div>
                            <img
                              src={person}
                              alt="person"
                              className="mt-2 cursor-pointer"
                            />
                          </div>
                          {it.author ? (
                            <div>
                              <div className="chat-name-text">
                                {" "}
                                {it.friendlyName.slice(17)}
                              </div>
                              <div className="text-img-chat">
                                <img src={chat} alt="chat" />
                                <span className="chat-data">
                                  {" "}
                                  {it.lastmessage
                                    ? it.lastmessage
                                    : "Media File"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="chat-name-text">{it.from}</div>

                              <div className="text-img-chat">
                                <img src={Doller} alt="Doller" />
                                <span className="chat-data">{it.subject}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        {it.author ? (
                          <div>
                            <div className="date-styles">
                              {it.lastMessage === undefined
                                ? moment(it.dateCreated).format("ddd . hh:mm A")
                                : moment(it.lastMessage.dateCreated).format(
                                    "ddd . hh:mm A"
                                  )}
                            </div>
                            <div
                              className="open-chat-text"
                              onClick={() =>
                                HandleChatOpen(it, it.lastMessage.dateCreated)
                              }
                            >
                              Open Chat
                              <img src={Right} alt="Right" />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="date-styles">
                              {moment(it.date).format("ddd . hh:mm A")}
                            </div>
                            <div
                              className="open-chat-text"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              Resend
                              <img src={Right} alt="Right" />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </>
              ))
            ) : (
              <div className="progress-bar-style">
                <ProgressBar />
              </div>
            )}
          </div>
        </div>
      </div>
      <CommonFooter />
      <CreateCommuncation
        isOpen={creatcom}
        isClose={() => setCreateCom(false)}
      />
      <CommunicationFilter
        isOpen={openFilter}
        isClose={() => setOpenFilter(false)}
      />
    </div>
  );
}

export default Communication;
