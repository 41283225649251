import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Estimatesidebar.css";

const EstimateSidebar = () => {
  const availableFilters = [
    "To Do",
    "Open",
    "Hold",
    "Completed",
    "Invoiced",
    "Name Search of Customer 1",
    "Name Search of Customer 2",
    "Estimate Name 1",
    "Estimate Name 2",
    "EST City 1",
    "EST City 2",
    "Tech 1",
    "Tech 2",
    "Red Tag",
    "Blue Tag",
    "01/06/23 - 01/13/23",
  ];

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchInputs, setSearchInputs] = useState({
    isWoName: "",
    isCustName: "",
    isCity: "",
    isTags: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterClick = (filter) => {
    if (!selectedFilters.includes(filter)) {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const handleClearFilter = (filter) => {
    setSelectedFilters(selectedFilters.filter((item) => item !== filter));
  };

  const handleClearAll = () => {
    setSelectedFilters([]);
    setSearchInputs({ isWoName: "", isCustName: "", isCity: "", isTags: "" });
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <div className="sidebar-container"> 
    <div style={{ pointerEvents: "auto" }}>
      <div>
        {/* Only show "Selected Filter(s)" if there are selected filters */}
        {selectedFilters.length > 0 && (
          <>
            <div className="line-align-bet mx-2" style={{ width: "80%" }}>
              <span className="side-filter-text">Selected Filter(s)</span>
              <span className="side-filter-text cursor-p" onClick={handleClearAll}>
                Clear All
              </span>
            </div>
            <div className="wo-sidebar-lft-stickybar">
              <div className="d-flex gap-1 p-2 flex-wrap">
                {selectedFilters.map((filter, index) => (
                  <div key={index} className="leftside-search-item gap-1 d-flex">
                    <span onClick={() => handleClearFilter(filter)}>
                      {filter}
                    </span>
                    <span
                      className="closeicon"
                      onClick={() => handleClearFilter(filter)}
                    >
                      <MdOutlineClose size={15} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="p-2">
        {/* Show Work Order Status only if there are filters not selected */}
        {availableFilters.some((filter) => !selectedFilters.includes(filter)) && (
          <div className="status-box txt-align-c m-1 p-2">
            <span className="Work-Order-Status-header">Work Order Status</span>
            {availableFilters.map(
              (filter) =>
                !selectedFilters.includes(filter) && (
                  <div
                    key={filter}
                    className="filter-item"
                    onClick={() => handleFilterClick(filter)}
                  >
                    {filter}
                  </div>
                )
            )}
          </div>
        )}

        <div>
          <span className="side-filter-text">Estimate Name</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search-est"
              type="text"
              placeholder="Name"
              name="isWoName"
              value={searchInputs.isWoName}
              onChange={(e) =>
                setSearchInputs({ ...searchInputs, isWoName: e.target.value })
              }
            />
          </div>
        </div>

        <div>
          <span className="side-filter-text">Customer</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search-est"
              type="text"
              placeholder="Customer Name"
              name="isCustName"
              value={searchInputs.isCustName}
              onChange={(e) =>
                setSearchInputs({ ...searchInputs, isCustName: e.target.value })
              }
            />
          </div>
        </div>

        <div>
          <span className="side-filter-text">City</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search-est"
              type="text"
              placeholder="City"
              name="isCity"
              value={searchInputs.isCity}
              onChange={(e) =>
                setSearchInputs({ ...searchInputs, isCity: e.target.value })
              }
            />
          </div>
        </div>

        <div>
          <span className="side-filter-text">Tags</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search-est"
              type="text"
              placeholder="Tags"
              name="isTags"
              value={searchInputs.isTags}
              onChange={(e) =>
                setSearchInputs({ ...searchInputs, isTags: e.target.value })
              }
            />
          </div>
        </div>

        <div>
          <span className="side-filter-text">Technicians</span>
          <div>
            <CiSearch className="inp-inside-icon" />
            <input
              className="w-100 sidebar-search-est"
              type="text"
              placeholder="Technicians"
            />
          </div>
        </div>

        <span className="side-filter-text">Select Date range</span>
        <div className="datepic-sty-est mt-1">
          <ReactDatePicker
            className="rdcal"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setStartDate(update[0]);
              setEndDate(update[1]);
            }}
            inline
            calendarClassName="custom-calendar-width"
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default EstimateSidebar;
