import { Modal } from "react-bootstrap";
import close2 from "../../common/Assets/Icons/Close_round_main.svg";
import mail from "../../common/Assets/Icons/mail.svg";
import phone from "../../common/Assets/Icons/phones_call_Filled_main.svg";
import chat from "../../common/Assets/Icons/chatbubble_Filled_main.svg";
import './createModalCom.css';

const CreateCommuncation = (props) => {
    return (
        <Modal
            centered
            show={props.isOpen} onHide={props.isClose}>
            <Modal.Header className="px-2 modal-bg-color modal-header-height note-edit-text">
                <div className="titleStyle">Create Communication</div>
                <img
                    src={close2}
                    alt="open"
                    onClick={props.isClose}
                    className="modal-header-icon"
                />
            </Modal.Header>
            <div className="modalBody">what type of communication would you like to make?</div>
            <div className="modalFooter">
                <button className="footerButtons"><img src={phone} alt="" width={14} className="buttonImage" />Calls</button>
                <button className="footerButtons"><img src={chat} alt="" width={14} className="buttonImage" />Messages</button>
                <button className="footerButtons"><img src={mail} alt="" width={14} className="buttonImage" />Emails</button>
            </div>
        </Modal>
    )
}

export default CreateCommuncation;