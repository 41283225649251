import React from "react";
import Add_Main from "../../../../common/Assets/Icons/add_main.svg";
import WfEquipment from "../../../../common/Assets/Icons/Equipmentwf.svg";
import del from "../../../../common/Assets/Icons/del.svg";
export default function EquipmentTypes(props) {
  return (
    <>
      <div className="line-align-bet w-100 clp_header">
        <div
          className=""
          style={{ fontFamily: "SofiaProBold" }}
          onClick={() => props?.HandleToggle("Equipment Types")}
        >
          <img
            src={WfEquipment}
            alt="WfWrench"
            width={20}
            className="cursor-p mx-1"
          />
          <span className="mx-1 wftags-sub-header cursor-p">
            Equipment Types
          </span>
        </div>
        <div className="wf-plus">
          <div
            onClick={
              props?.type === "eqpType"
                ? (e) => props?.HandleCategories(e, "Equipment Type", "Add")
                : () => props?.HandleCategories("Type")
            }
          >
            <img src={Add_Main} alt="Add-Main" />
          </div>
        </div>
      </div>
      {!props?.isTglWfEqp && (
        <div>
          <div className="card">
            {props?.data?.length > 0 ? (
              props?.data
                ?.sort(
                  (a, b) =>
                    new Date(b.modified_date) - new Date(a.modified_date)
                )
                ?.map((it) => (
                  <div
                    onClick={
                      props?.type === "eqpType"
                        ? (e) =>
                            props?.HandleCategories(
                              e,
                              "Equipment Type",
                              "Edit",
                              it
                            )
                        : () => props?.HandleCategories("Type", it)
                    }
                    className="line-align-bet w-100 p-2 cursor-p"
                    style={{
                      borderBottom: "1px solid  rgb(209, 202, 202)",
                      background:
                        (it?.equipment_type_id === props?.isEqupId &&
                          "#d5d8d8") ||
                        (it?.equipment_type_id === props?.isWfTypeCatId &&
                          "#d5d8d8"),
                    }}
                  >
                    <span className="sub-w-items-text cursor-p">
                      {it?.equipment_type_name}
                    </span>
                    <img
                      src={del}
                      alt="del"
                      className="cursor-p"
                      onClick={
                        props?.type === "eqpType"
                          ? (e) =>
                              props?.HandleCategories(
                                e,
                                "Equipment Type",
                                "Delete",
                                it
                              )
                          : (e) => props?.HandleCat(it, "Type", e)
                      }
                    />
                  </div>
                ))
            ) : (
              <div className="align-rt w-100 p-2">
                <span className="err-data-wfsetup">There is no data </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
