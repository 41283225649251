import React from "react";
import { Col, Row } from "react-bootstrap";
import { Control_types } from "../../../../common/components/Values";

function AddFieldsIntegrations({
  item,
  onFieldChangeHandler,
  emptyField,
  ErpData,
  PackColumn,
}) {
  const errorObj = (emptyField && emptyField[`${item.field_id}`]) || [];
  return (
    <Row className="mx-0 " style={{ borderBottom: "1px solid #d6d6d6" }}>
      <Col xs={2} className="px-0">
        <input
          className="inp-tab-fld-sett w-100"
          value={item?.name || ""}
          style={{ height: "" }}
          onChange={(e) =>
            onFieldChangeHandler(
              "name",
              e.target.value?.trimStart(),
              item?.field_id
            )
          }
        />

        {/* <select
          className={`inpt-add-eqp  lbl-ipt-add-eqp w-100  ${
            errorObj.includes("type") ? "abc" : ""
          }`}
          name="packcolumn"
          value={item?.packcolumn || ""}
          onChange={(e) =>
            onFieldChangeHandler("packcolumn", e.target.value, item?.field_id)
          }
        >
          <option value=""> Please Select</option>
          {PackColumn?.map((item, index) => (
            <option value={item.ERPColumn}> {item.ERPColumn}</option>
          ))}
        </select> */}
      </Col>
      <Col xs={2} className="px-0">
        <input
          className="inp-tab-fld-sett w-100"
          value={item?.packcolumn || ""}
          style={{ height: "" }}
          onChange={(e) =>
            onFieldChangeHandler(
              "packcolumn",
              e.target.value?.trimStart(),
              item?.field_id
            )
          }
        />
      </Col>
      <Col xs={2}>
        <select
          className={`inpt-add-eqp  lbl-ipt-add-eqp w-100  ${
            errorObj.includes("type") ? "abc" : ""
          }`}
          name="erpcolumn"
          value={item?.erpcolumn || ""}
          onChange={(e) =>
            onFieldChangeHandler("erpcolumn", e.target.value, item?.field_id)
          }
        >
          <option value=""> Please Select</option>
          {item?.packcolumn?.length > 0 &&
            ErpData?.map((item, index) => (
              <option value={item.name}> {item.name}</option>
            ))}
        </select>
      </Col>
      <Col xs={2}>
        <input
          className={`inpt-add-eqp  lbl-ipt-add-eqp w-100  ${
            errorObj.includes("type") ? "abc" : ""
          }`}
          name=""
          value={item?.erpname || ""}
          onChange={(e) =>
            onFieldChangeHandler("erpname", e.target.value, item?.field_id)
          }
        />
      </Col>
      <Col xs={1}>
        <select
          className={`inpt-add-eqp  lbl-ipt-add-eqp w-100  ${
            errorObj.includes("type") ? "abc" : ""
          }`}
          name="control_type"
          value={item?.control_type || ""}
          onChange={(e) =>
            onFieldChangeHandler("control_type", e.target.value, item?.field_id)
          }
        >
          <option value=""> Please Select</option>
          {item?.packcolumn?.length > 0 &&
            Control_types?.map((item, index) => (
              <option value={item.type}> {item.type}</option>
            ))}
        </select>
      </Col>
      <Col xs={1} className="d-flex  gap-1">
        <span className="Add-fields-types-char">Character Count:</span>
        <input
          type="number"
          className={`Add-fields-types p-1 `}
          // placeholder={`Label Name ${item?.packcolumn}`}
          value={item?.characterlength || ""}
          style={{ height: "" }}
          onChange={(e) =>
            onFieldChangeHandler(
              "characterlength",
              e.target.value?.trimStart(),
              item?.field_id
            )
          }
        />
      </Col>
      <Col
        xs={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <input
          type="checkbox"
          // className={`${errorObj.includes("active") ? "" : ""}`}
          className="cursor-p WoFldsCheckbox mt-1"
          checked={item?.active}
          onChange={(e) =>
            onFieldChangeHandler("active", e.target.checked, item.field_id)
          }
        />
      </Col>
      <Col
        xs={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <input
          type="checkbox"
          // className={`${errorObj.includes("active") ? "" : ""}`}
          className="cursor-p WoFldsCheckbox mt-1"
          checked={item?.required}
          onChange={(e) =>
            onFieldChangeHandler("required", e.target.checked, item.field_id)
          }
        />
      </Col>{" "}
      {/* <Col
        xs={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <input
          type="checkbox"
          // className={`${errorObj.includes("active") ? "" : ""}`}
          className="cursor-p WoFldsCheckbox mt-1"
          // checked={item?.active}
          // onChange={(e) =>
          //   onFieldChangeHandler("active", e.target.checked, item.field_id)
          // }
        />
      </Col> */}
    </Row>
  );
}

export default AddFieldsIntegrations;
