import React, { useContext, useEffect, useState } from "react";
import "./WorkOrderTableStyles.css";
import DataTable from "react-data-table-component";
import Table from "../../Assets/Images/tableStk.png";
import { AiOutlinePlus } from "react-icons/ai";
import MemoryIcon from "../../../common/Assets/Icons/Memory Board Icon.svg";
import CommonHeader from "../../framework/header/HeaderCommon/CommonHeader";
import ProgressBar from "../../navigation/ProgressBar";
import { TABLE_CUSTOMSTYLES } from "./Table";
import { FilterData, TABLE_COLUMNS_NAME } from "../Constants";
import { NavigateFunction } from "../../navigation/NavigationChild";
import moment from "moment/moment";
import FilterModal from "./FilterModal";
import Sort2 from "../../Assets/Icons/Sort2.svg";
import "react-datepicker/dist/react-datepicker.css";
import { workOrdrListSummary } from "../../../services/WorkOrdersService";
import { Col, Row } from "react-bootstrap";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import WorkOrderSideBar from "../../../features/workorders/WorkOrderSideBar";
import { WorkOrderSearch } from "../../../features/workorders/WorkOrderSearch";
import SideTable from "./SideTable";
import { woStsSettFldStpGt } from "../../../services/SettingsServices";
import VectorEqpBlue from "../../Assets/Images/VectorEqpBlue.svg";
import MemoryModal from "../memorymodal/MemoryModal";
import { GetMemory } from "../../../services/WorkOrdersService";
import MemoryblueModal from "../memorymodal/MemoryblueModal";
import Sort_down from "../../Assets/Icons/Sort_down.svg";
import Sort_up from "../../Assets/Icons/Sort_up.svg";
import { useNavigate } from "react-router-dom";
import { AppGlobalContextUpdate } from "../../context/AppGlobalContext";
import CustomerModal from "../customermodal/CustomerModal";

export default function WorkOrderTable(eachWorkOrder, initialTableData) {
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tempTableData, setTempTableData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tabledatabydate, setTableDataByDate] = useState([]);
  const [woStatus2, setWoStatus2] = useState([]);
  const [newData, setNewData] = useState([]);
  const [woStatus, setWoStatus] = useState([]);
  const navigation = useNavigate();
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [sortDateIcon, setSortDateIcon] = useState(Sort_down);
  const [SearchInputs, setSearchInputs] = useState({
    isWoName: "",
    isCustName: "",
    isTags: "",
    isCity: "",
    isTechs: "",
  });
  const handleSortDate = () => {
    const newSortOrder = !isSortedDesc;
    setIsSortedDesc(newSortOrder);
    setSortDateIcon(newSortOrder ? Sort_down : Sort_up);
  
    if (tabledatabydate.length > 0) {
      setTableDataByDate(
        [...tabledatabydate].sort((a, b) => {
          const dateA = a.date ? new Date(a.date) : null;
          const dateB = b.date ? new Date(b.date) : null;
          if (!dateA && !dateB) return 0;
          if (!dateA) return 1;
          if (!dateB) return -1; 
          return newSortOrder ? dateB - dateA : dateA - dateB;
        })
      );
    }
  };
  
  useEffect(() => {
    getWoStatus();
  }, []);
  useEffect(() => {
    GetWorkOrders();
  }, []);
  useEffect(() => {
    if (tempTableData.length > 0) {
      setTableDataByDate(tempTableData);
    }
  }, [tempTableData]);

  const getWoStatus = async () => {
    const response = await woStsSettFldStpGt();
    if (response && response.data && response.data.length > 0) {
      setWoStatus2(response.data);
      setWoStatus(response.data);
    }
  };

  const [contactData, setContactData] = useState({});
  const [navigate, setNavigate] = useState();

  const openCustomerModal = (item) => {
    setNavigate(item?.row?.primary_contact?.customer_id)
    setContactData(item?.row?.primary_contact);
    setIsModalOpen(true);
  };

  const closeCustomerModal = () => {
    setIsModalOpen(false);
  };

  const [isProgress, setIsProgress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const GetWorkOrders = async () => {
    setIsProgress(true);
    let UserId = JSON.parse(localStorage.getItem("userId"));
    const responce = await workOrdrListSummary(UserId);
    if (responce?.status === 200) {
      setIsProgress(false);
      setTempTableData(responce?.data?.data);
      setTableData(responce?.data?.data);
      setTableData2(responce?.data?.data);
    }
    if(responce?.success){
      setIsModalOpen(true);
    }
  };

  const HandleBtnStatus = (value) => {
    let fromDate = moment(startDate).format("MM/DD/YYYY");
    let todate = moment(endDate).format("MM/DD/YYYY");
    let Res = WorkOrderSearch(
      SearchInputs,
      tableData2,
      fromDate,
      todate,
      "Table",
      [...newData, value]
    );
    setNewData((prevData) => [...prevData, value]);
    setWoStatus2(woStatus2?.filter((i) => i?.status !== value?.status));
    setTempTableData(Res);
  };
  const updateAppState = useContext(AppGlobalContextUpdate);
  const navToWoDetls = (e, to, wdObj, isVal) => {
    updateAppState({ currentWorkOrder: wdObj });
    NavigateFunction({
      to: to,
      params: {wdObj, isVal,woid:wdObj?.work_order_id,custid:wdObj?.custid},
      navigate: navigation,
    });
  };
  const navToWoDetls1 = (to, params) => {
    NavigateFunction({
      to: to,
      params: params,
      navigate: navigation,
    });
  };
  const HandleNav = ( key) => { 
    NavigateFunction({
      to:`CreateNewcustomer/${0}/${key}/${userId}/${techPin}/${companyCode}/${0}`,
      params: {
        key,
      },
      navigate: navigation,
    });
  };

  const [rightData] = useState(FilterData);
  const [isTabCol, setIsTabCol] = useState(FilterData);
  const [isSelectCheck, setIsSelectCheck] = useState(true);
  const HandleCheckWoType = (e, tableCol) => {
    if (e.target.checked) {
      setIsTabCol(isTabCol?.filter((i) => i !== tableCol));
      setIsSelectCheck(e.target.checked);
    } else {
      setIsTabCol([...isTabCol, tableCol]);
    }
  };
  const HandleClearAll = () => {
    setIsTabCol(FilterData);
    setIsSelectCheck(true);
  };
  const [isMemoryModalOpen, setIsMemoryModalOpen] = useState(false);
  const [ismemoryObj, setIsMemoryObj] = useState({});
  const [womemoryopt, setWomemoryopt] = useState({});
  const [mryblue, setMryblue] = useState(false);
  const [mrydlt, setMrydlt] = useState(null);
  const [getmemory, setgetmemory] = useState("");
  const [memobj, setMemobj] = useState({});
  const [itemkey, setItemkey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [isPhoneNumber, setIsPhoneNumber] = useState("desc");
  const [isSortedCustomerAsc, setIsSortedCustomerAsc] = useState("true");
  const [isSortWoName, setIsSortWoName] = useState("true");
  const [isPrimryContact, setPrimryContact] = useState(false);
  const [isSortedStatusAsc, setIsSortedStatusAsc] = useState(true);
  const [isSortedAddressAsc, setIsSortedAddressAsc] = useState(true);
  const [isSortedLeadTechAsc, setIsSortedLeadTechAsc] = useState(true);
  const [isSortedCityAsc, setIsSortedCityAsc] = useState(true);
  const [isSortedStateAsc, setIsSortedStateAsc] = useState(true);
  const [isSortedTagsAsc, setIsSortedTagsAsc] = useState(true);
  const [sortIcon, setSortIcon] = useState(Sort_down);
  const [sortPhoneNumber, setSortPhoneNumber] =useState(Sort_down);
  const [sortCustomerIcon, setSortCustomerIcon] = useState(Sort_down);
  const [sortWoName, setSortWoName] = useState(Sort_down);
  const [sortPrimryContact, setSortPrimryContact] = useState(Sort_down);
  const [sortStatusIcon, setSortStatusIcon] = useState(Sort_down);
  const [sortAddressIcon, setSortAddressIcon] = useState(Sort_down);
  const [sortLeadTech, setSortLeadTech] = useState(Sort_down);
  const [sortCityIcon, setSortCityIcon] = useState(Sort_down);
  const [sortStateIcon, setSortStateIcon] = useState(Sort_down);
  const [sortTagsIcon, setSortTagsIcon] = useState(Sort_down);
  const [ismemProgress, setIsMemProgress] = useState(false);
  const HandleMemoryIconClick = async(type, e, item) => {
    e.stopPropagation();
    if (type === "black") {
      setIsMemoryModalOpen(true);
      setWomemoryopt(item);
      // setIsMemory(true);
      let items = { viewtype: 10, Reftype: 100, RefId: +item?.work_order_id };
      setIsMemoryObj(items);
    } else {
      setWomemoryopt(item);
      setItemkey(type);
      setMryblue(true);
      let items = { viewtype: 10, Reftype: 100, RefId: +item?.work_order_id };
      setIsMemoryObj(items);
      setMemobj(item);
      setIsMemProgress(true)
      const Result = await GetMemory(
        items?.viewtype,
        items?.Reftype,
        items?.RefId
        );
        if (Result?.success) {
          setIsMemProgress(false)
        }
        setgetmemory(Result?.data);
        setMrydlt(Result?.data?.recent_view_id)
  
    }
  };
  const descvalue = getmemory?.note;
  const updateMemoryBoardStatus = (id, data) => {
    let filterNewWo = tempTableData?.filter(
      (item) => item?.work_order_id === womemoryopt?.work_order_id
    );
    if (filterNewWo?.length > 0) {
      if (id === "delete") {
        filterNewWo[0].memoryBoard = 0;
      } else {
        filterNewWo[0].memoryBoard = 1;
      }
    } else {
    }
    // setTempTableData([...tempTableData, filterNewWo[0]]);
  };

  const handleSort = () => {
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    setSortIcon(newSortOrder === "asc" ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      if (!a.work_order_id && !b.work_order_id) return 0;
      if (!a.work_order_id) return 1;
      if (!b.work_order_id) return -1;
      return newSortOrder === "asc"
        ? a.work_order_id - b.work_order_id
        : b.work_order_id - a.work_order_id;
    });
    setTempTableData(sortedData);
  };

  const handlesortPrimaryContact = () => {
    const nextSortDirection = !isPrimryContact;
    setPrimryContact(nextSortDirection);
    setSortPrimryContact(nextSortDirection ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA = a.primary_contact?.name?.toLowerCase() || null;
      const nameB = b.primary_contact?.name?.toLowerCase() || null;
      if (nameA === null && nameB === null) return 0;
      if (nameA === null) return 1;
      if (nameB === null) return -1;
      return nextSortDirection
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };
  
  const handlesortWoName = () => {
    setIsSortWoName((prev) => !prev);
    setSortWoName(isSortWoName ? Sort_up : Sort_down);  
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : null;
      const nameB = b.name ? b.name.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return isSortWoName
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };

  const handlePhoneNumber = () => {
    const newSortOrder = isPhoneNumber === "desc" ? "asc" : "desc";
    setIsPhoneNumber(newSortOrder);
    setSortPhoneNumber(newSortOrder === "asc" ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const phoneA = a.phone_number || null;
      const phoneB = b.phone_number || null;
      if (phoneA === null && phoneB === null) return 0;
      if (phoneA === null) return 1;
      if (phoneB === null) return -1;
      return newSortOrder === "asc"
        ? phoneA.localeCompare(phoneB)
        : phoneB.localeCompare(phoneA);
    });
    setTempTableData(sortedData);
  };  
  
  const handleSortCustomer = () => {
    setIsSortedCustomerAsc((prev) => !prev);
    setSortCustomerIcon(isSortedCustomerAsc ? Sort_up : Sort_down);  
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA = a.customer_name ? a.customer_name.toLowerCase() : null;
      const nameB = b.customer_name ? b.customer_name.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return isSortedCustomerAsc
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };
  
  const sortData = (key, isSortedAsc, setIsSortedAsc) => {
    const newSortOrder = !isSortedAsc;
    setIsSortedAsc(newSortOrder);
    if (key === "status") {
      setSortStatusIcon(newSortOrder ? Sort_down : Sort_up);
    }
    const sortedData = [...tempTableData].sort((a, b) => {
      const aValue = a[key] ? a[key].toString().toLowerCase() : null;
      const bValue = b[key] ? b[key].toString().toLowerCase() : null;
      if (!aValue && !bValue) return 0;
      if (!aValue) return 1;
      if (!bValue) return -1;
      return newSortOrder
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
    setTempTableData(sortedData);
  };  

  const handlesortLead = () => {
    setIsSortedLeadTechAsc((prev) => !prev);
    setSortLeadTech(isSortedLeadTechAsc ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA = a.city ? a.city.toLowerCase() : null;
      const nameB = b.city ? b.city.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return isSortedLeadTechAsc
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };
  
  const handlesortCity = () => {
    setIsSortedCityAsc((prev) => !prev);
    setSortCityIcon(isSortedCityAsc ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA = a.city ? a.city.toLowerCase() : null;
      const nameB = b.city ? b.city.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return isSortedCityAsc
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };
  
  const handlesortState = () => {
    setIsSortedStateAsc((prev) => !prev);
    setSortStateIcon(isSortedStateAsc ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA = a.state ? a.state.toLowerCase() : null;
      const nameB = b.state ? b.state.toLowerCase() : null;
      if (!nameA && !nameB) return 0;
      if (!nameA) return 1;
      if (!nameB) return -1;
      return isSortedStateAsc
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };
  
  const handlesortTags = () => {
    setIsSortedTagsAsc((prev) => !prev);
    setSortTagsIcon(isSortedTagsAsc ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const nameA =
        a.tags && a.tags.length > 0 ? a.tags[0].tag_data.toLowerCase() : "";
      const nameB =
        b.tags && b.tags.length > 0 ? b.tags[0].tag_data.toLowerCase() : "";
      return isSortedTagsAsc
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
    setTempTableData(sortedData);
  };
 const userId = JSON.parse(localStorage.getItem("userId"));
        const techPin = JSON.parse(localStorage.getItem("techpin"));
        const companyCode = JSON.parse(localStorage.getItem("CompanyCode"));
  const handleSortAddress = () => {
    const newSortOrder = isSortedAddressAsc ? "desc" : "asc";
    setIsSortedAddressAsc(!isSortedAddressAsc);
    setSortAddressIcon(isSortedAddressAsc ? Sort_up : Sort_down);
    const sortedData = [...tempTableData].sort((a, b) => {
      const addressA = a.address ? a.address.toLowerCase() : null;
      const addressB = b.address ? b.address.toLowerCase() : null;
      if (!addressA && !addressB) return 0;
      if (!addressA) return 1;
      if (!addressB) return -1;
      if (!isNaN(addressA) && !isNaN(addressB)) {
        return newSortOrder === "asc" ? addressA - addressB : addressB - addressA;
      }
      return newSortOrder === "asc"
        ? addressA.localeCompare(addressB, undefined, { numeric: true })
        : addressB.localeCompare(addressA, undefined, { numeric: true });
    });
    setTempTableData(sortedData);
  };
  
  const columns = [
    {
      name: (
        <div  className="table-head-cells" style={{ cursor: "pointer"}}>
          <img src={Sort2} width={20} height={20} alt="open srt" />
        </div>
      ),
      selector: (row) => (
        <span className="table-date cursor-p">
          {row?.memoryBoard > 0 ? (
            <img
              onClick={(e) => HandleMemoryIconClick("workorder", e, row)}
              src={VectorEqpBlue}
              alt="memoryIconBlue"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <img
              onClick={(e) => 
                HandleMemoryIconClick("black", e, row)
              }
              width={22}
              src={MemoryIcon}
              alt="MemoryIcon"
              style={{ cursor: "pointer" }}
            />
          )}
        </span>
      ),
      width: "60px",
    },
    // {
    //   name: (
    //     <div>
    //       <img src={Sort2} width={20} height={20} alt="open sort" />
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <span className="table-date">
    //       {row?.memoryBoard > 0 || (row?.id === activeRowId && mryval > 0) ? (
    //         <img
    //           onClick={(e) => HandleMemoryIconClick("workorder", e, row)}
    //           src={VectorEqpBlue}
    //           alt="memoryIconBlue"
    //           style={{ cursor: "pointer" }}
    //         />
    //       ) : (
    //         <img
    //           onClick={(e) => HandleMemoryIconClick("black", e, row)}
    //           width={22}
    //           src={MemoryIcon}
    //           alt="MemoryIcon"
    //           style={{ cursor: "pointer" }}
    //         />
    //       )}
    //     </span>
    //   ),
    //   width: "60px",
    // },
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handleSort}>
          {TABLE_COLUMNS_NAME.TABLE_WO}
          <img src={sortIcon} width={20} height={20} alt="sort icon" />
        </div>
      ),
      cell: (row) => (
        <div 
        className="table-woid"
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) =>
            navToWoDetls(
              e,
              `WoDetails/${row?.work_order_id}/${row?.custid}/${techPin}/${techPin}/${companyCode}/0`,
              row,
              "details",
            )
          }
        >
          {row?.work_order_id}
        </div>
      ),
      omit: isTabCol?.length > 0 && isTabCol?.includes("WO Number"),
      width: "190px",
    },  
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handlesortWoName}>
          {TABLE_COLUMNS_NAME.TABLE_WONAME}{" "}
          <img src={sortWoName} width={20} height={20} alt="open sort" />
        </div>
      ),
      cell: (row) => (
        <div
        className="table-woid"
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={(e) =>
          navToWoDetls(
            e,
            `WoDetails/${row?.work_order_id}/${row?.custid}/${techPin}/${techPin}/${companyCode}/0`,
            row,
            "details",
          )
        }
        >
          {row?.name}
        </div>
      ),
      omit: isTabCol?.length > 0 && isTabCol?.includes("Work Order Name"),
      width: "240px",
    }, 
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handleSortDate}>
          {TABLE_COLUMNS_NAME.TABLE_DATE}{" "}
          <img src={sortDateIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => (
        <div className="table-date">
          {moment(row?.date).format("MM/DD/YYYY")}
        </div>
      ),
      omit: isTabCol?.length > 0 && isTabCol?.includes("Date"),
      width: "109px",
    },
    {
      name: (
        <div className="table-head-cells2 cursor-p" onClick={handleSortCustomer}>
          {TABLE_COLUMNS_NAME.TABLE_CUSTOMER}{" "}
          <img src={sortCustomerIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      cell: (row) => (
        <div
          className="table-woid"
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          // onClick={() => navToCreateNewCustomer(`CreateNewcustomer/${row?.work_order_id}/${row?.custid}/${userId}/${techPin}/${companyCode}/0`, row)}
          onClick={() => HandleNav( row?.custid)}
        >
          {row.customer_name}
        </div>
      ),
      omit: isTabCol?.length > 0 && isTabCol?.includes("Customer"),
      width: "240px",
    },
    {
      name: (
        <div
          className="table-head-cells cursor-p"
          onClick={() =>
            sortData("status", isSortedStatusAsc, setIsSortedStatusAsc)
          }
        >
          {TABLE_COLUMNS_NAME.TABLE_STATUS}{" "}
          <img src={sortStatusIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => <div className="table-date">{row?.status}</div>,
      omit: isTabCol?.length > 0 && isTabCol?.includes("Status"),
      width: "109px",
    },
    {
      name: (
        <div
          className="table-head-cells2 cursor-p"
          onClick={() => handleSortAddress(isSortedAddressAsc)}
        >
          {TABLE_COLUMNS_NAME.TABLE_ADDERSS}{" "}
          <img src={sortAddressIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.address}</div>,
      omit: isTabCol?.length > 0 && isTabCol?.includes("Address"),
      width: "230px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handlesortPrimaryContact}>
          {TABLE_COLUMNS_NAME.TABLE_PRIMARY_CONTACT}{" "}
          <img src={sortPrimryContact} width={20} height={20} alt="open sort" />
        </div>
      ),
      cell: (row) => (
        <div
          className="table-woid"
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => {openCustomerModal({row});
          }}
          >
            {row?.primary_contact?.name}
          </div>
      ),
      omit: isTabCol?.length > 0 && isTabCol?.includes("Primary Contact"),
      width: "180px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handlePhoneNumber}>
          {TABLE_COLUMNS_NAME.TABLE_PHONE_NUMBER}{" "}
          <img src={sortPhoneNumber} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => <div className="table-date">{row?.phone_number}</div>,
      omit: isTabCol?.length > 0 && isTabCol?.includes("Phone Number"),
      width: "170px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p">
          {TABLE_COLUMNS_NAME.TABLE_PRIMARY_BRANCH}{" "}
          <img src={Sort_down} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => <div></div>,
      omit: isTabCol?.length > 0 && isTabCol?.includes("Primary Branch"),
      width: "180px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handlesortLead}>
          {TABLE_COLUMNS_NAME.TABLE_LEAD_TECH}{" "}
          <img src={sortLeadTech} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => row?.additional_tech,
      omit: isTabCol?.length > 0 && isTabCol?.includes("Lead Tech"),
      width: "130px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p">
          {TABLE_COLUMNS_NAME.TABLE_ADDIONAL_TECHS}{" "}
          <img src={Sort_down} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => row.title,
      omit: isTabCol?.length > 0 && isTabCol?.includes("Additional Techs"),
      width: "185px",
    },

    {
      name: (
        <div className="table-head-cells2 cursor-p" onClick={handlesortCity}>
          {TABLE_COLUMNS_NAME.TABLE_CITY}{" "}
          <img src={sortCityIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.city}</div>,
      omit: isTabCol?.length > 0 && isTabCol?.includes("City"),
      width: "123px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handlesortState}>
          {TABLE_COLUMNS_NAME.TABLE_STATE}{" "}
          <img src={sortStateIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => <div className="table-date">{row.state}</div>,
      omit: isTabCol?.length > 0 && isTabCol?.includes("State"),
      width: "105px",
    },
    {
      name: (
        <div className="table-head-cells cursor-p" onClick={handlesortTags}>
          {TABLE_COLUMNS_NAME.TABLE_TAGS}{" "}
          <img src={sortTagsIcon} width={20} height={20} alt="open sort" />
        </div>
      ),
      selector: (row) => (
        <div className="d-flex gap-1">
          {row.tags?.map((i, index) => (
            <div key={index} className="table-tags">
              {i.tag_data}
            </div>
          ))}
        </div>
      ),
      omit: isTabCol?.length > 0 && isTabCol?.includes("Tags"),
      width: "auto",
    },
  ];

  const HandleClearInputs = () => {
    setSearchInputs({
      isWoName: "",
      isCustName: "",
      isTags: "",
      isCity: "",
      isTechs: "",
    });
    setStartDate(null);
    setEndDate(null);
    setNewData([]);
    setWoStatus2(woStatus);
    setTempTableData(tableData2);
  };

  const HandleClearText = (key, type) => {
    let fromDate = moment(startDate).format("MM/DD/YYYY");
    let todate = moment(endDate).format("MM/DD/YYYY");
    if (key === "date") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs: SearchInputs.isTechs,
      });
      setStartDate(null);
      setEndDate(null);
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs: SearchInputs.isTechs,
        },
        tableData,
        null,
        "Invalid date",
        "Table",
        newData
      );
      setTempTableData(Res);
    }
    if (key === "isWoName") {
      setSearchInputs({
        isWoName: "",
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs: SearchInputs.isTechs,
      });
      let Res = WorkOrderSearch(
        {
          isWoName: "",
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs: SearchInputs.isTechs,
        },
        tableData,
        fromDate,
        todate,
        "Table",
        newData
      );
      setTempTableData(Res);
    }
    if (key === "isCustName") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: "",
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs: SearchInputs.isTechs,
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: "",
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs: SearchInputs.isTechs,
        },
        tableData,
        fromDate,
        todate,
        "Table",
        newData
      );
      setTempTableData(Res);
    }
    if (key === "isCity") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: "",
        isTechs: SearchInputs.isTechs,
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: "",
        },
        tableData,
        fromDate,
        todate,
        "Table",
        newData
      );
      setTempTableData(Res);
    }
    if (key === "isTags") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: "",
        isCity: SearchInputs.isCity,
        isTechs: SearchInputs.isTechs,
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: "",
          isCity: SearchInputs.isCity,
          isTechs: SearchInputs.isTechs,
        },
        tableData,
        fromDate,
        todate,
        "Table",
        newData
      );
      setTempTableData(Res);
    }
    if (key === "isTechs") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs: "",
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs: "",
        },
        tableData,
        fromDate,
        todate,
        "Table",
        newData
      );
      setTempTableData(Res);
    }
    if (type === "status") {
      setSearchInputs({
        isWoName: SearchInputs.isWoName,
        isCustName: SearchInputs.isCustName,
        isTags: SearchInputs.isTags,
        isCity: SearchInputs.isCity,
        isTechs: SearchInputs.isTechs,
      });
      let Res = WorkOrderSearch(
        {
          isWoName: SearchInputs.isWoName,
          isCustName: SearchInputs.isCustName,
          isTags: SearchInputs.isTags,
          isCity: SearchInputs.isCity,
          isTechs: SearchInputs.isTechs,
        },
        tableData,
        fromDate,
        todate,
        "Table",
        newData?.filter((i) => i?.status !== key?.status)
      );

      setNewData(newData?.filter((i) => i?.status !== key?.status));
      setWoStatus2([...woStatus2, key]);
      setTempTableData(Res);
    }
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    let fromDate = moment(start).format("MM/DD/YYYY");
    let todate = moment(end).format("MM/DD/YYYY");
    setStartDate(start);
    setEndDate(end);
    let Res = WorkOrderSearch(
      SearchInputs,
      tableData,
      fromDate,
      todate,
      "Table",
      newData
    );
    setTempTableData(Res);
  };
  const HandleInputs = (e) => {
    const { name, value } = e.target;
    setSearchInputs({
      ...SearchInputs,
      [name]: value,
    });
  };
  const HandleBlur = () => {
    let fromDate = moment(startDate).format("MM/DD/YYYY");
    let todate = moment(endDate).format("MM/DD/YYYY");
    let Res = WorkOrderSearch(
      SearchInputs,
      tableData,
      fromDate,
      todate,
      "Table",
      newData
    );
    setTempTableData(Res);
  };
  const customStyles = TABLE_CUSTOMSTYLES;
  const [filterAdj, setFilterAdj] = React.useState(false);

  const HandleFilterModal = () => {
    if (filterAdj === false) {
      setFilterAdj(true);
    } else if (filterAdj === true) {
      setFilterAdj(false);
    }
  };

  const navigateToCreateWO = () => {
    NavigateFunction({
      to: `WOrdersList`,
      params: { workOrderList: tableData },
      navigate: navigation,
    });
  };

  const [isExpand, setIsExpand] = useState("");
  const [rtExpand, setRtExpand] = useState("");

  const HandleExpand = (val) => {
    setIsExpand(val);
  };
  const HandleRtExpand = (val) => {
    setRtExpand(val);
  };
  const [over, setOver] = useState(false);

  return (
    <div>
      <div className="top-header-Wo-Table">
        <CommonHeader />
      </div>
 
      <>
        {isProgress ? (
          <div className="progress-bar-Wo-Table">
            <ProgressBar />
          </div>
        ) : (
          <div className="mx-2">
            <div className=" data-table-styles flx-wrp m-2">
              <div>
                <img
                  src={Table}
                  alt="table"
                  style={{ cursor: "pointer", height: 34, width: 34 }}
                  onClick={() => navToWoDetls1("WorkOrders")}
                />
                &nbsp;
                <span className="TableText">Work Orders</span>
              </div>

              <div
                className="work-order-header-btn"
                onClick={() => navigateToCreateWO()}
              >
                <button className="crt-new-work-order-btn">
                  Create Work Order
                  <AiOutlinePlus
                    size={12}
                    style={{ marginLeft: 5, marginBottom: 2 }}
                  />
                </button>
              </div>

              {/* <div
          {isProgress ? (
            <div className="progress-bar-Wo-Table">
              <ProgressBar />
            </div>
          ) : (
            <div className=" data-table-styles flx-wrp m-2">
              <div>
                <img
                  src={Table}
                  alt="table"
                  style={{ cursor: "pointer", height: 34, width: 34 }}
                  onClick={() => navToWoDetls("WorkOrders")}
                />
                &nbsp;
                <span className="TableText">Work Orders</span>
              </div>

              <div
                className="work-order-header-btn"
                onClick={() => navigateToCreateWO()}
              >
                <button className="crt-new-work-order-btn">
                  Create Work Order
                  <AiOutlinePlus
                    size={12}
                    style={{ marginLeft: 5, marginBottom: 2 }}
                  />
                </button>
              </div>

              {/* <div
              className="work-order-btn cursor-p "
              onClick={() => navigateToCreateWO()}
            >
              <span className="buttonName">Create Work Order</span>
              <img alt="" src={add} className="add-btn mx-2" />
            </div> */}
 
            <Row className="w-100">
              {isExpand === "expand" ? (
                ""
              ) : (
                <Col
                  onMouseOver={() => setOver(true)}
                  onMouseOut={() => setOver(false)}
                  style={{
                    pointerEvents: isProgress === true && "none",
                  }}
                  sm={isExpand === "expand" ? 10 : 2}
                  xs={isExpand === "expand" ? 10 : 2}
                  md={isExpand === "expand" ? 10 : 2}
                  lg={isExpand === "expand" ? 0 : 2}
                  className="workOrderTable-col1"
                >
                  <WorkOrderSideBar
                    HandleBtnStatus={HandleBtnStatus}
                    startDate={startDate}
                    woStatus2={woStatus2}
                    onChange={onChangeDate}
                    endDate={endDate}
                    HandleInputs={HandleInputs}
                    HandleBlur={HandleBlur}
                    SearchInputs={SearchInputs}
                    HandleClearInputs={HandleClearInputs}
                    HandleClearText={HandleClearText}
                    newData={newData}
                  />
                </Col>
 
              )}
              <Col
                sm={
                  isExpand === "expand" && rtExpand === "rt-expand"
                    ? 12
                    : isExpand === "expand"
                    ? 11
                    : rtExpand === "rt-expand"
                    ? 10
                    : 9
                }
                xs={
                  isExpand === "expand" && rtExpand === "rt-expand"
                    ? 12
                    : isExpand === "expand"
                    ? 11
                    : rtExpand === "rt-expand"
                    ? 10
                    : 9
                }
                md={
                  isExpand === "expand" && rtExpand === "rt-expand"
                    ? 12
                    : isExpand === "expand"
                    ? 11
                    : rtExpand === "rt-expand"
                    ? 10
                    : 9
                }
                lg={
                  isExpand === "expand" && rtExpand === "rt-expand"
                    ? 12
                    : isExpand === "expand"
                    ? 11
                    : rtExpand === "rt-expand"
                    ? 10
                    : 9
                }
                className="workOrderTable-col2"
              >
                {isProgress ? (
                  <div className="progress-bar-Wo-Table">
                    <ProgressBar />
                  </div>
                ) : (
                  <>
                    <span
                      className={
                        isExpand === "expand" || over
                          ? "backbtn-hvr2  p-0 m-0"
                          : "backbtn-hvr  p-0 m-0"
                      }
                    >
                      {isExpand === "expand" ? (
                        <MdChevronRight
                          size={25}
                          onClick={() => HandleExpand("")}
                        />
                      ) : (
                        <MdChevronLeft
                          size={25}
                          onClick={() => HandleExpand("expand")}
                        />
                      )}
                    </span>

                    <span
                      className={
                        rtExpand === "rt-expand"
                          ? "backbtntable2"
                          : "backbtntable"
                      }
                    >
                      {rtExpand === "rt-expand" ? (
                        <MdChevronLeft
                          size={25}
                          onClick={() => HandleRtExpand("")}
                        />
                      ) : (
                        <MdChevronRight
                          size={25}
                          onClick={() => HandleRtExpand("rt-expand")}
                        />
                      )}
                    </span>
                    <div className="card p-2">
                      <div
                        style={{
                          border: "1px solid black",
                        }}
                      >
                        <DataTable
                          className="data-table-fix-height"
                          columns={columns}
                          data={tabledatabydate}
                          fixedHeader
                          selectableRows={false}
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Col>
              {rtExpand === "rt-expand" ? (
                ""
              ) : (
                <Col
                  className="workOrderTable-col3"
                  lg={rtExpand === "rt-expand" ? 0 : 1}
                  md={rtExpand === "rt-expand" ? 0 : 1}
                  sm={rtExpand === "rt-expand" ? 0 : 1}
                  xs={rtExpand === "rt-expand" ? 0 : 1}
                >
                  <SideTable
                    rightData={rightData}
                    isSelectCheck={isSelectCheck}
                    isTabCol={isTabCol}
                    HandleCheckWoType={HandleCheckWoType}
                    HandleClearAll={HandleClearAll}
                  />
                </Col>
              )}
            </Row>
          </div>
        </div>
         )}
      </>
   
      <MemoryModal
        isOpen={isMemoryModalOpen}
        id={womemoryopt?.work_order_id}
        name={"Work Order"}
        descvalue={descvalue}
        isClose={() => setIsMemoryModalOpen(false)}
        ismemoryObj={ismemoryObj}
        womemoryopt={womemoryopt}
        woId={womemoryopt?.id}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
      />
      <MemoryblueModal
        Open={mryblue}
        Close={() => setMryblue(false)}
        ismemoryObj={ismemoryObj}
        updateMemoryBoardStatus={updateMemoryBoardStatus}
        memobj={memobj}
        custname={memobj?.customer_name}
        mrydlt={mrydlt}
        itemkey={itemkey}
        isProgress={ismemProgress}
        descvalue={descvalue}
        headname={itemkey === "workorder" ? memobj?.name : ""}
        headid={itemkey === "workorder" ? Number(memobj?.work_order_id) : ""}
        openName={itemkey === "workorder" ? "Workorder" : ""}
      />
        <CustomerModal
        Open={isModalOpen}
        Close={closeCustomerModal}
        iscontactname={contactData}
        custId={navigate}
        contactname={contactData?.name}
        note={contactData?.note}
      />
      <FilterModal isOpen={filterAdj} isClose={() => HandleFilterModal()} />
    </div>
  );
}