import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Close from "../../../common/Assets/Images/close_ring_duotone_Mainclosebggray.svg";
import { TechList } from "../../../services/WorkOrdersService";
import saveblack from "../../../common/Assets/Icons/saveblack.svg";
import { TECH } from "../../../common/components/Constants";
import "./AddTechList.css";
import closeIcon from "../../../common/Assets/Icons/Close_round_main.svg";

function AddTechList(props) {
  const [techList, setTechList] = useState([]);
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTechs, setSelectedTechs] = useState([]);
  const [initialSelectedTechs, setInitialSelectedTechs] = useState([]);

  const funcTechList = async () => {
    let Res = await TechList();
    setTechList(Res?.data);
    setFilteredTechList(Res?.data);
  };

  useEffect(() => {
    const HandleCustFilter = () => {
      if (!searchQuery) {
        setFilteredTechList(techList);
        return;
      }
      const filtered = techList?.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
      setFilteredTechList(filtered);
    };
    HandleCustFilter();
  }, [searchQuery, techList]);

  useEffect(() => {
    funcTechList();
  }, []);

  useEffect(() => {
    if (props?.woid > 0 && props?.selectedwoTechs?.length) {
      let newObj = props?.selectedwoTechs?.map((it)=>({
        avatar:it?.avatar,
        email : it?.email,
        id:it?.technician_code,
        name : it?.name
      }))
      setSelectedTechs(newObj);
      setInitialSelectedTechs(props?.selectedwoTechs);
    }
  }, [props.woid, props.selectedwoTechs]);

  const handleTechSelect = (tech) => {
    setSelectedTechs((prevSelectedTechs) => {
      if (prevSelectedTechs.some((selectedTech) => selectedTech.id === tech.id)) {
        return prevSelectedTechs.filter((selectedTech) => selectedTech.id !== tech.id);
      } else {
        return [...prevSelectedTechs, tech];
      }
    });
  };

  const handleSubmit = () => {
    props.HandlCustData(selectedTechs);
    // setIsFullArr(selectedTechs)
    setInitialSelectedTechs(selectedTechs);
  };

  const HandleClose = () => {
    setSelectedTechs(initialSelectedTechs);
    props.Close();
  };

  useEffect(() => {
    if (props?.Close) {
      setSearchQuery("");
    }
  }, [props?.Close]);

  return (
    <div>
      <Modal centered size="md" show={props.Open} onHide={props.Close}>
        {/* <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="modal-header-txt ">{props?.istechkey === "create" ? TECH.ADD_TECH : TECH.MAN_TECH}
          </div>
          <img
            src={Close}
            className="modal-header-icon"
            alt="Close"
            onClick={props.Close}
          />
        </Modal.Header> */}
        <Modal.Header className="modal-bg-color modal-header-height px-2">
          <div className="csm-modal-header-text line-align-bet p-0">
            {props?.istechkey === "create" ? TECH.ADD_TECH : TECH.MAN_TECH}
            
          </div>
          <img
              onClick={() => HandleClose()}
              className=" cursor-p modal-header-icon"
              src={Close}
              alt=""
            />
        </Modal.Header>

        <Modal.Body className="" style={{ height: "420px" }}>
          <div className="modelcont_box">
            {/* <div className="manage-tech">{TECH.MAN_TECH}</div> */}
            <div className="select-tech">{TECH.SELECT_TECH}</div>
            <div className="line-align-bet mt-2">
              <div style={{ width: "75%" }}>
                <input
                  placeholder="Email/Name"
                  className="add-to-wo"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div style={{ width: "35%" }}>
                <div
                  className="inpt-add-to-btn cursor-p"
                  color="red"
                  appearance="primary"
                  size="sm"
                  // onClick={handleSubmit}
                >
                  <div style={{ justifyContent: "center" }}>
                    <span className="txt-add">Add to Work Order<span className="addplus"> + </span> </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
            {selectedTechs.length > 0 && (
              <>
                <div className="tech-name mt-2">Current Techs</div>
                <div  className={selectedTechs?.length > 0 && "tech-list-container"}>
                  {selectedTechs.map((tech) => (
                    <div className="tech-container" 
                      key={tech.id} 
                      onClick={() => handleTechSelect(tech)}
                      >
                      <input
                        type="checkbox"
                        className=" cursor-p"
                        checked={true}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleTechSelect(tech)}
                      />
                      <img src={tech.avatar} alt="avatar" className="prfl-img" />
                      <div>
                        <div className="tech-name">{tech.name}</div>
                        <div className="tech-mail">{tech.email}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* Always display All Techs section */}
            <div className="tech-name mt-2">All Techs</div>
            <div  className={selectedTechs?.length > 0? "tech-list-container": "tech-list-container2"}>
              {filteredTechList
                ?.filter((tech) => !selectedTechs.some((selected) => selected.id === tech.id))
                .map((item) => (
                  <div
                    className={`tech-container ${
                      selectedTechs?.some((tech) => tech.id === item.id) ? "selected" : ""
                    }`}
                    key={item.id}
                    onClick={() => handleTechSelect(item)}
                  >
                    <input
                      type="checkbox"
                      className=" cursor-p"
                      checked={selectedTechs?.some((tech) => tech.id === item.id)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => handleTechSelect(item)}
                    />
                    <img src={item.avatar} alt="avatar" className="prfl-img" />
                    <div>
                      <div className="tech-name">{item.name}</div>
                      <div className="tech-mail">{item.email}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className=" d-flex justify-content-end mx-1 mb-1 model_footer">
          <div
            onClick={() => HandleClose()}
            className="tech-sav  cursor-p mx-1"
          >
            <span className=" mx-3">Cancel</span>
            <span>
              <img height="20px" src={closeIcon} alt="" />
            </span>
          </div>
          <div onClick={handleSubmit} className="tech-sav cursor-p mx-1">
            <span className="save-tech mx-3 ">Save</span>
            <span>
              <img src={saveblack} height="20px" alt="" />
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddTechList;
