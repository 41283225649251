import React, { useState } from "react";
import { Card } from "react-bootstrap";
import logs from "../../../../common/Assets/Icons/Logs.svg";
import vector from "../../../../common/Assets/Icons/vector.svg";
import down from "../../../../common/Assets/Icons/DownDir.svg";
import logsdownload from "../../../../common/Assets/Icons/logsdownload.svg"
const EquipmentLogs = () => {
  const [isToggle, setIsToggle] = useState(true);
  const [logsData, setLogsData] = useState([]);
  const handleToggle = () => {
    setIsToggle(!isToggle);
  };
  const handleDownloadClick = () => {
  };

  return (
    <div>
      <div className="sett-crd-hdr-txt clp_header">
        <div>
          <img
            onClick={handleToggle}
            className="cursor-p"
            src={logs}
            alt="Logs"
          />&nbsp;
          <span>
            {" "}
            <span onClick={handleToggle} className="cursor-p">Logs</span>
          </span>
          <div className="myDiv1">
            <span>
              <img
                style={{pointerEvents: window?.location?.href?.split("/")[10] === "1" ? "none" : "auto"}}
                onClick={handleDownloadClick}
                className="cursor-p equiplus"
                src={logsdownload}
                alt="Download logs"
              />
            </span>
          </div>
        </div>

        <span className="flt-r cursor-p">
          {/* {isToggle ? (
            <img
              alt=""
              src={down}
              onClick={handleToggle}
            />
          ) : (
            <img
              alt=""
              src={vector}
              height={9}
              onClick={handleToggle}
            />
          )} */}
        </span>
      </div>

      {isToggle && (
        <div className="clp_body">
          {logsData.length > 0 ? (
            <ul>
              {logsData.map((log, index) => (
                <li key={index}>{log}</li>
              ))}
            </ul>
          ) : (
            <p className="ml-2">Logs will be created upon the creation of the work order.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EquipmentLogs;
